import app from "@/main";
import Logger from "@/logger";
import packageJson from "../../../package.json";
import axios from "axios";
import StorageManager from "@/plugins/storage-manager";
import AppConstants from "@/constants/app";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL || "localhost",
  timeout: 1000 * 30,
  headers: {
    Version: packageJson.version,
  },
});

instance.interceptors.request.use((request) => {
  request.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (app.$store.state.authentication.token.access) {
    request.headers.Authorization = `Bearer ${app.$store.state.authentication.token.access}`;
  }

  return request;
});

instance.interceptors.response.use(
  (response) => {
    app.$store.state.authentication.refreshTokenError = false;
    return response;
  },
  async (error) => {
    try {
      const responseStatus = error.status ?? error.response.status;
      const originalRequest = error.config;

      if (
        app.$store.state.authentication.refreshTokenError &&
        responseStatus === 401
      ) {
        return;
      }

      if (responseStatus === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        app.$store.state.authentication.refreshTokenError = true;
        const newAccessToken = await app.$store.dispatch(
          "authentication/refreshToken"
        );
        if (newAccessToken !== false) {
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } else {
          await StorageManager.instance.clear();
          window.reload();
        }
      }
      return Promise.reject(error);
    } catch (err) {
      Logger.print("error", error);
    }
  }
);

export default instance;
