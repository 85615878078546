<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1000"
    height="600"
    :show="show"
    :titleText="titleText"
    :globalClassName="'are-you-sure-outer'"
    :stopClickOutside="true"
    :outerLeftPx="outerLeftPx"
  >
    <div class="container are-you-sure-container" v-if="!lastConfirm">
      <div class="name" v-if="name != false">
        {{ name }}
      </div>
      <div class="heading" v-if="defaultText">
        {{ $t("deleteConfirmWindow.areYouSure") }}
      </div>
      <div class="heading" v-if="!defaultText">
        {{ $t("deleteConfirmWindow.deletefriends") }}
      </div>
      <div class="buttons">
        <div class="ui-button ui-button-small-green" @click="closeWindow">
          {{ $t("deleteConfirmWindow.cancel") }}
        </div>
        <div class="ui-button ui-button-small-red" @click="firstClick">
          {{ $t("deleteConfirmWindow.delete") }}
        </div>
      </div>
    </div>
    <div class="container" v-if="lastConfirm">
      <div class="heading" v-if="content">{{ content }}.</div>
      <div class="heading" v-else>
        {{ $t("deleteConfirmWindow.daysLimit") }}
      </div>
      <div class="buttons">
        <div class="ui-button ui-button-small-green" @click="closeWindow">
          {{ $t("deleteConfirmWindow.cancel") }}
        </div>
        <div class="ui-button ui-button-small-red" @click="confirm">
          {{ $t("deleteConfirmWindow.delete") }}
        </div>
      </div>
    </div>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";
export default {
  name: "AreYouSureWindow",
  components: {
    BaseWindow,
  },
  data() {
    return {
      lastConfirm: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: true,
    },
    title: {
      type: [String, Boolean],

      default: false,
    },
    content: {
      type: [String, Boolean],

      default: false,
    },
    titleText: {
      type: String,
      default: "",
    },
    stepCount: {
      type: Number,
      default: 2,
    },
    name: {
      type: [String, Boolean],
      default: false,
    },
    defaultText: {
      default: true,
    },
    outerLeftPx: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    firstClick() {
      if (this.stepCount === 1) {
        this.$emit("confirm");
        return;
      }
      this.lastConfirm = true;
    },
    confirm() {
      this.$emit("confirm");
    },
    closeWindow() {
      this.lastConfirm = false;
      this.$emit("closeAreYouSure");
    },
  },
};
</script>

<style scoped lang="scss">
.are-you-sure-container {
  background: #83212d;
}
.container {
  width: 99%;
  height: 87%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 21;
  .name {
    font-size: 46px;
    font-weight: bold;
    margin-bottom: 50px;
    text-shadow: 3px 5px 3px black;
    text-align: center;
  }
  .heading {
    font-size: 46px;
    font-weight: bold;
    height: 30%;
    text-shadow: 3px 5px 3px black;
    text-align: center;
    width: 98%;
  }
  .buttons {
    width: 55%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
</style>
