var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"sit-window-outer"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickedOutside),expression:"onClickedOutside"}],staticClass:"sit-window-inner"},[_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":_vm.close}},[_c('svg',{attrs:{"width":"48","height":"48","fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M6 18L18 6M6 6l12 12"}})])]),_c('div',{staticClass:"header"},[_vm._v(_vm._s(_vm.$t("blackJack.header")))]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"total-user-chip"},[_c('div',{staticClass:"lobby-sprite chip"}),_vm._v(" "+_vm._s(_vm.getChipFormat(_vm.user.chip))+" ")]),(_vm.player.availableChip >= _vm.getMinAmount)?[_c('div',{staticClass:"current-amount"},[_vm._v(_vm._s(_vm.getChipFormat(_vm.currentAmount)))]),_c('div',{staticClass:"slider"},[_c('div',{staticClass:"slider-inner"},[_c('button',{staticClass:"slider-button slider-minus",attrs:{"type":"button"},on:{"click":function($event){return _vm.onSliderButtonClicked(0)}}}),_c('VueSlider',{attrs:{"width":"100%","height":"64px","silent":true,"disabled":_vm.getMinAmount === _vm.getMaxAmount,"min":_vm.getMinAmount,"max":_vm.getMaxAmount,"included":true,"contained":true,"zoom":_vm.getTotalScaleAmount,"dotOptions":{
                style: {
                  width: '64px',
                  height: '64px',
                  marginTop: '-24px',
                  marginLeft: '-24px',
                  backgroundColor: 'white',
                  boxShadow: '0 0 0 0 transparent',
                  border: '0px',
                },
                focusStyle: {
                  boxShadow: '0 0 16px 0 #1f2025',
                  border: '1px solid #1f2025',
                },
                tooltipStyle: {
                  color: 'white',
                  backgroundColor: 'black',
                  borderColor: 'black',
                  fontSize: '12px',
                  padding: '3px 12px',
                },
              },"processStyle":{
                background: 'linear-gradient(180deg, #FFDB76, #f8bc07)',
              },"railStyle":{
                borderRadius: '20px',
                background: 'rgba(0,0,0,0.3)',
                border: '1px solid rgba(255,255,255,0.15)',
              },"adsorb":true,"drag-on-click":true,"tooltip":"none","tooltipFormatter":() => _vm.getShortChipFormat(_vm.currentAmount)},on:{"dragging":_vm.onSliderDragging,"drag-end":_vm.onSliderDragEnd},model:{value:(_vm.currentAmount),callback:function ($$v) {_vm.currentAmount=$$v},expression:"currentAmount"}}),_c('button',{staticClass:"slider-button slider-plus",attrs:{"type":"button"},on:{"click":function($event){return _vm.onSliderButtonClicked(1)}}})],1),_c('div',{staticClass:"slider-info"},[_c('div',{staticClass:"min-buy"},[_vm._v(_vm._s(_vm.getChipFormat(_vm.getMinAmount)))]),_c('div',{staticClass:"max-buy"},[_vm._v(_vm._s(_vm.getChipFormat(_vm.getMaxAmount)))])])]),_c('div',{staticClass:"options"},[_c('div',{staticClass:"option auto-purchase",class:[_vm.autoPurchase ? 'active' : ''],on:{"click":function($event){_vm.autoPurchase = !_vm.autoPurchase}}},[_c('div',{staticClass:"checkmark"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.autoPurchase),expression:"autoPurchase"}],staticClass:"inner"})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"heading"},[_vm._v(_vm._s(_vm.$t("blackJack.otoPurchase")))]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t("blackJack.otoPurchaseText"))+" ")])])])]),_c('div',{staticClass:"ok-button"},[_c('button',{staticClass:"ui-button ui-button-green",attrs:{"type":"button"},on:{"click":_vm.send}},[_vm._v(" "+_vm._s(_vm.$t("selectPotWindow.transfer"))+" ")])]),_c('p',{staticClass:"blackjack-info"},[_vm._v(_vm._s(_vm.$t("blackJack.blackjactSitInfo")))])]:_vm._e(),(_vm.player.availableChip < _vm.getMinAmount)?[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.$t("blackJack.insufficientBalance"))+" ")])]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }