export default {
  async openUserDetailWindow({ commit, dispatch }, userId) {
    const res = await dispatch("friends/getProfiles", [userId], { root: true });
    commit("friends/setSelectedUserModal", res[0], { root: true });
    commit("modals/show", "friendDetails", { root: true });
  },
  async openTableUserDetailWindow({ commit, dispatch }, userId) {
    const res = await dispatch("friends/getProfiles", [userId], { root: true });
    commit("friends/setSelectedUserModal", res[0], { root: true });
    commit("modals/show", "userDetailsTable", { root: true });
  },
};
