<template>
  <div class="one-click-bonus-outer">
    <div class="moving-chip"></div>
    <div class="moving-chip"></div>
    <div class="moving-chip"></div>
    <div class="moving-chip"></div>
    <div class="moving-chip"></div>
    <button
      class="lobby-sprite present-box lobby-bonus-button one-click-bonus-button"
      :class="[getRealPlatform === 'android' ? 'no-flick' : '']"
      v-if="$store.state.bonus.oneClickBonus.ready"
      @click="collect"
    ></button>
    <div v-else class="lobby-sprite money-box-open"></div>
    <!-- <div class="bonus-countdown" v-if="!$store.state.bonus.oneClickBonus.ready && user.id > 0">
      <Countdown
        :deadline="getAvailableTime"
        ref="countdown"
        @onEnd="updateStatus"
      />
    </div> -->
    <span
      class="green-icon"
      v-if="$store.state.bonus.oneClickBonus.ready"
    ></span>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";
import { searchElementsSetProperty } from "@/helpers";

// import Countdown from "@/components/Misc/Countdown";

export default {
  name: "OneClickBonus",
  components: {
    // Countdown,
  },
  watch: {
    "$store.state.animations.bonusChip": {
      handler(val) {
        if (val) {
          this.giftChipAnimationStart();
        } else {
          this.giftChipAnimationSetDefaultProperty();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      bonus: (state) => state.bonus.oneClickBonus,
      oneClickBonusTime: (state) => state.config.one_click_bonus_time ?? 0,
    }),
    ...mapGetters(["getRealPlatform"]),
    getAvailableTime() {
      return this.bonus.lastClaimAt + this.oneClickBonusTime;
    },
  },
  data() {
    return {
      claiming: false,
      animation: null,
      count: 0,
    };
  },
  methods: {
    updateStatus() {
      this.$store.dispatch("bonus/getOneClickBonusStatus");
    },
    async collect() {
      await this.$store.dispatch("bonus/collectOneClickBonus");
    },
    onClickBonusClaiming() {
      this.$data.claiming = true;
    },
    onClickBonusClaimed() {
      this.$data.claiming = false;
    },
    onClickBonusClaimError() {
      this.$data.claiming = false;
    },
    giftChipAnimationSetDefaultProperty() {
      searchElementsSetProperty(".moving-chip", [
        { property: "display", value: "none" },
        { property: "translateY", value: "0px" },
        { property: "translateX", value: "0px" },
        { property: "transform", value: "rotate(0deg)" },
      ]);
      this.$store.state.animations.bonusChip = false;
    },
    giftChipAnimationStart() {
      searchElementsSetProperty(".moving-chip", [
        { property: "display", value: "block" },
      ]);
      this.$animejs({
        targets: ".moving-chip",
        translateY: [
          { value: 80, duration: 600 },
          { value: 10, duration: 500 },
        ],
        translateX: [
          { value: 0, duration: 100 },
          { value: "-700%", duration: 500 },
          { value: "-1400%", duration: 500 },
        ],
        rotateY: [{ value: 360, duration: 1100 }],

        display: "block",
        delay: this.$animejs.stagger(250),
        endDelay: 100,
        easing: "linear",
        complete: this.giftChipAnimationSetDefaultProperty,
      });
      if (this.count > 0) {
        this.$plugins?.audio?.play("lobby_one_click");
      }
      this.count++;
    },
  },
  beforeDestroy() {
    EventBus.$off(EventConstants.ONE_CLICK_BONUS_CLAIMING);
    // EventBus.$off(EventConstants.ONE_CLICK_BONUS_CLAIMED);
    EventBus.$off(EventConstants.ONE_CLICK_BONUS_CLAIM_ERROR);
  },
  created() {
    EventBus.$on(
      EventConstants.ONE_CLICK_BONUS_CLAIMING,
      this.onClickBonusClaiming.bind(this)
    );
    // EventBus.$on(
    //   EventConstants.ONE_CLICK_BONUS_CLAIMED,
    //   this.onClickBonusClaimed.bind(this)
    // );
    EventBus.$on(
      EventConstants.ONE_CLICK_BONUS_CLAIM_ERROR,
      this.onClickBonusClaimError.bind(this)
    );
    this.updateStatus();
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.moving-chip {
  position: absolute;
  top: -5px;
  right: 5px;
  background: url("~@/assets/images/animations/animation-chip.png") no-repeat
      50% 50%,
    transparent;
  background-size: contain;
  height: 50px;
  width: 50px;
  display: none;
}
.one-click-bonus-outer {
  position: relative;
  will-change: transform;
  .green-icon {
    position: absolute;
    top: -10px;
    right: -15px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background: #16fa03;
    border: 3px solid #0dae01;
  }
  .one-click-bonus-button {
    background-color: transparent;
    &:disabled {
      opacity: 0.8;
    }
  }

  .bonus-countdown {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -30px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 1px black, -2px -2px 1px black;
  }
  .lobby-bonus-button {
    margin-right: -5px;
  }
  .menu-bonus-text {
    font-size: 30px;
    position: absolute;
    top: 100px;
    right: 20px;
    color: #ffffff;
  }
  .money-box-open {
    transform: scale(1.2);
  }
}
</style>
