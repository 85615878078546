<template>
  <svg v-bind="$attrs" viewBox="0 0 24 24">
    <path fill="currentColor" d="M7 21L14.9 3H17L9.1 21H7Z" />
  </svg>
</template>

<script>
export default {
  name: "CrossIcon",
};
</script>
