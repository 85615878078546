var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bet-area"},[(_vm.nGetMySeat)?_c('div',{staticClass:"bet-pot-area",class:[_vm.isBetAvailable ? 'bets-active' : '']},[_c('button',{staticClass:"bet-pot-button twenty-one-plus-one",class:[_vm.nGetMySeat.wonWithTypes.twentyOne > 0 ? 'x-glow' : ''],attrs:{"type":"button","disabled":!_vm.ableToSideBet},on:{"click":function($event){return _vm.addBet('twentyOne')}}},[(_vm.nGetMySeat.bet.twentyOne > 0)?_c('span',{staticClass:"chip",class:[
          _vm.getChipColorClass(
            _vm.nGetMySeat.bet.twentyOne *
              Math.max(_vm.nGetMySeat.wonWithTypes.twentyOne, 1)
          ),
        ]},[_vm._v(_vm._s(_vm.getShortChipFormat( _vm.nGetMySeat.bet.twentyOne * Math.max(_vm.nGetMySeat.wonWithTypes.twentyOne, 1) )))]):_vm._e(),(_vm.nGetMySeat.wonWithTypes.twentyOne > 0)?_c('span',{staticClass:"won-with"},[_vm._v(_vm._s(`${_vm.nGetMySeat.wonWithTypes.twentyOne}x`))]):_vm._e()]),_c('button',{staticClass:"bet-pot-button main",class:[
        _vm.nGetMySeat.bet.main <= 0 && _vm.getActiveRoomState.stage === 1
          ? 'glow-animation'
          : '',
        _vm.nGetMySeat.wonWithTypes.main > 0 ? 'x-glow' : '',
      ],attrs:{"type":"button","disabled":!_vm.isBetAvailable},on:{"click":function($event){return _vm.addBet('main')}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.nGetMySeat.insured),expression:"nGetMySeat.insured"}],staticClass:"insurance"}),(_vm.nGetMySeat.bet.main > 0)?_c('span',{staticClass:"chip",class:[
          _vm.getChipColorClass(
            (_vm.nGetMySeat.bet.main + _vm.nGetMySeat.split.bet) *
              Math.max(_vm.nGetMySeat.wonWithTypes.main, 1)
          ),
        ]},[_vm._v(_vm._s(_vm.getShortChipFormat( (_vm.nGetMySeat.bet.main + _vm.nGetMySeat.split.bet) * Math.max(_vm.nGetMySeat.wonWithTypes.main, 1) )))]):_vm._e(),(_vm.nGetMySeat.wonWithTypes.main > 0)?_c('span',{staticClass:"won-with"},[_vm._v(_vm._s(`${_vm.nGetMySeat.wonWithTypes.main}x`))]):_vm._e()]),_c('button',{staticClass:"bet-pot-button any-pair",class:[_vm.nGetMySeat.wonWithTypes.anyPair > 0 ? 'x-glow' : ''],attrs:{"type":"button","disabled":!_vm.ableToSideBet},on:{"click":function($event){return _vm.addBet('anyPair')}}},[(_vm.nGetMySeat.bet.anyPair > 0)?_c('span',{staticClass:"chip",class:[
          _vm.getChipColorClass(
            _vm.nGetMySeat.bet.anyPair *
              Math.max(_vm.nGetMySeat.wonWithTypes.anyPair, 1)
          ),
        ]},[_vm._v(_vm._s(_vm.getShortChipFormat( _vm.nGetMySeat.bet.anyPair * Math.max(_vm.nGetMySeat.wonWithTypes.anyPair, 1) )))]):_vm._e(),(_vm.nGetMySeat.wonWithTypes.anyPair > 0)?_c('span',{staticClass:"won-with"},[_vm._v(_vm._s(`${_vm.nGetMySeat.wonWithTypes.anyPair}x`))]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"bet-amount-indicator",style:({ visibility: _vm.totalBetAmount === 0 ? 'hidden' : 'visible' })},[_vm._v(" "+_vm._s(_vm.$t("blackJack.bet"))+" "),_c('span',[_vm._v(_vm._s(_vm.getChipFormat(_vm.totalBetAmount)))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }