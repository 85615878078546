<template>
  <div class="table-cards">
    <div class="table-cards-inner">
      <Card
        v-for="(card, cardIndex) in getActiveRoomState.cards"
        :key="'table-card-' + cardIndex"
        :suit="card.suit"
        :value="card.value"
        type="table"
      />
    </div>
  </div>
</template>

<script>
import Card from "./Cards/Card";
import { mapGetters } from "vuex";

export default {
  name: "Cards",
  components: {
    Card,
  },
  data() {
    return {
      dummyCards: [
        { index: 33, suit: "c", value: "9" },
        { index: 35, suit: "c", value: "J" },
        { index: 39, suit: "h", value: "2" },
        { index: 3, suit: "s", value: "5" },
        { index: 3, suit: "s", value: "5" },
      ],
    };
  },
  watch: {
    "getActiveRoomState.cards": function (newValue, oldValue) {
      if (!Array.isArray(newValue)) return false;
      if (newValue === oldValue || newValue.length === oldValue.length)
        return false;
      if ([1, 4, 5].includes(newValue.length)) this.$plugins.audio.play("Flip");
    },
  },
  computed: {
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
    }),
  },
};
</script>

<style scoped lang="scss">
.table-cards {
  width: 100%;
  height: 250px;
  position: absolute;
  top: 160px;
  left: -2px;
  display: flex;
  align-items: center;
  justify-content: center;

  &-inner {
    min-width: 630px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    perspective: 2500px;
    .card {
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
