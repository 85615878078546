export default {
  getFriendRequests(state) {
    return state.requestsList.list;
  },
  getSelectedUserModal(state) {
    return state.selectedUserModal;
  },
  isAnyRequest(state) {
    return state.requestsList?.total > 0;
  },
  getInviteList(state) {
    if (state.inviteList.length > 0) {
      return state.inviteList;
    }
    return [];
  },
  lastInviteRequest(state) {
    if (state.inviteList.length > 0) {
      return state.inviteList[state.inviteList.length - 1];
    }
    return false;
  },
  getFriendsById: (state) => (friendId) => {
    if (friendId > 0 && state.friendsList.list.length > 0) {
      const friendFound = state.friendsList.list.filter(
        (fri) => fri.user.id === friendId
      );
      if (friendFound.length > 0) {
        return friendFound[0];
      }
      return "";
    }
    return "";
  },
  getFriendCurrentGame: (state) => (friendId) => {
    if (friendId > 0 && state.friendsList.list.length > 0) {
      const friendFound = state.friendsList.list.filter(
        (fri) => fri.user.id === friendId
      );

      if (
        friendFound.length > 0 &&
        friendFound[0].user.game &&
        friendFound[0].user.room
      ) {
        return {
          game: friendFound[0].user.game,
          room: friendFound[0].user.room,
          avatar: friendFound[0].user.avatar,
        };
      }
      return false;
    }
    return false;
  },
};
