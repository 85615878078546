<template>
  <div class="jackpot-notification-container" v-if="animate">
    <div class="notification-inner">
      <JackpotNotificationAnimation :backgroundUrl="backgroundUrl" />
      <Avatar
        class="player-window-avatar"
        size="120"
        :url="$store.getters['user/getAvatarUrl']($data.winner.id)"
      />
      <div class="texts">
        <div class="username">
          {{ $data.winner.name }}
        </div>
        <!-- <div class="jackpot-name">{{ $data.blind }}</div> -->
      </div>
      <div class="jackpot-amount">{{ getShortChipFormat($data.prize) }}</div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/event-bus";
import { mapGetters } from "vuex";

import JackpotNotificationAnimation from "@/components/Misc/Animations/JackpotNotificationAnimation";
import Avatar from "@/components/Misc/Avatar";

const NOTIFICATION_ELAPSE_SECONDS = 10;

export default {
  name: "ProgressiveJackpotNotification",
  components: {
    JackpotNotificationAnimation,
    Avatar,
  },
  computed: {
    ...mapGetters(["getShortChipFormat"]),
  },
  data() {
    return {
      animate: false,
      prize: 0,
      blind: "",
      winner: {
        id: -1,
        name: "",
      },
      isProgressiveJackpot: true,
      resetTimeout: 0,
      backgroundUrl: `url(${require("@/assets/images/table/jackpot2.png")})`,
    };
  },
  methods: {
    onProgressiveJackpotWinnerAnnounced(data) {
      if (this.$data.resetTimeout) clearTimeout(this.$data.resetTimeout);

      this.$plugins.audio.play("JackpotWon");

      console.log("Progressive data:", data);
      this.$data.animate = true;
      this.$data.isProgressiveJackpot = true;
      this.$data.winner.id = data.id;
      this.$data.winner.name = data.name;
      this.$data.prize = Math.floor(Number(data.amount));
      this.$data.blind = `${this.getShortChipFormat(
        Number(data.bet)
      )}/${this.getShortChipFormat(Number(data.bet) * 2)}`;
      this.$data.resetTimeout = setTimeout(() => {
        this.reset();
      }, 1000 * NOTIFICATION_ELAPSE_SECONDS);
    },
    reset() {
      this.$data.animate = false;
      this.$data.resetTimeout = setTimeout(() => {
        this.$data.winner.id = -1;
        this.$data.winner.name = "";
        this.$data.isProgressiveJackpot = false;
        this.$data.prize = 0;
        this.$data.blind = "";
      }, 600);
    },
  },
  beforeDestroy() {
    EventBus.$off(
      "onProgressiveJackpotExplosion",
      this.onProgressiveJackpotWinnerAnnounced.bind(this)
    );
  },
  created() {
    EventBus.$on(
      "onProgressiveJackpotExplosion",
      this.onProgressiveJackpotWinnerAnnounced.bind(this)
    );
  },
};
</script>

<style lang="scss" scoped>
.jackpot-notification-container {
  position: relative;
  height: 330px;
  z-index: 12;

  .notification-inner {
    width: 100%;
    height: 100%;
    position: relative;

    .player-window-avatar {
      position: absolute;
      top: 130px;
      left: 20px;
      width: 100px;
      height: 100px;
    }

    .texts {
      position: absolute;
      top: 110px;
      left: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 240px;
      min-width: 240px;
      max-height: 90px;
      min-height: 90px;
      word-break: break-word;
      display: flex;
      align-items: center;
      justify-content: center;
      .username {
        font-size: 40px;
      }

      .jackpot-name {
        font-size: 40px;
        background: -webkit-linear-gradient(#ffcc66, #ffffcc);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 5px;
      }
    }

    .jackpot {
      position: absolute;
      left: 53px;
      bottom: 122px;
      width: 320px;
      font-size: 33px;
      background: -webkit-linear-gradient(#ffcc66, #ffffcc);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;
      font-weight: bold;
    }

    .jackpot-amount {
      position: absolute;
      left: 155px;
      bottom: 87px;
      color: #fee9c7;
      font-weight: bold;
      font-size: 50px;
      width: 230px;
      text-align: center;
    }
  }
}
</style>
