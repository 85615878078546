<template>
  <div
    class="hand-strength"
    :class="[$props.currentTurn ? 'current-turn' : '']"
    v-if="$props.value > 0"
  >
    <div
      class="win-lose-indicator"
      :class="[isWon ? 'won' : 'lose']"
      v-if="
        $props.showWonLoseIndicator &&
        $props.value !== getActiveRoomState.dealer.handStrength &&
        getActiveRoomState.stage === 4
      "
    >
      <div class="i-icon">
        <svg
          v-show="isWon"
          width="20"
          height="20"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <svg
          v-show="!isWon"
          width="20"
          height="20"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
    </div>
    <div>{{ getHandStrengthAsText }}</div>
    <div class="icon busted" v-if="isBusted">
      <div class="indicator">{{ $t("blackJack.busted") }}</div>
    </div>
    <div class="icon blackjack" v-if="isBlackjack">
      <div class="indicator">BLACKJACK</div>
    </div>
    <div class="icon" v-if="isPush">
      <div class="indicator">PUSH</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "HandStrength",
  props: {
    value: {
      type: Number,
      default: 0,
      required: true,
    },
    twoState: {
      type: String,
      default: "",
      required: true,
    },
    showWonLoseIndicator: {
      type: Boolean,
      default: true,
      required: false,
    },
    currentTurn: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapState(["activeRoom"]),
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
      nGetMySeat: "service/nGetMySeat",
    }),
    getHandStrengthAsText() {
      if (!this.isBusted && !this.isPush && !this.isBlackjack)
        return this.$props.twoState;
      return this.$props.value;
    },
    isWon() {
      return (
        !this.isBusted &&
        (this.$props.value > this.getActiveRoomState.dealer.handStrength ||
          this.getActiveRoomState.dealer.handStrength > 21)
      );
    },
    isValueShowable() {
      return this.$props.value > 0 && this.$props.value < 21;
    },
    isBusted() {
      return this.$props.value > 21;
    },
    isBlackjack() {
      return this.$props.value === 21;
    },
    isPush() {
      return (
        this.$props.value > 0 &&
        this.getActiveRoomState.stage === 4 &&
        !this.isBusted &&
        !this.isBlackjack &&
        this.nGetMySeat.handStrength ===
          this.getActiveRoomState.dealer.handStrength
      );
    },
  },
};
</script>

<style scoped lang="scss">
@-webkit-keyframes indicatorIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes indicatorIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes handStrengthGlowAnimation {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  50% {
    box-shadow: 0 0 0 3px rgba($yellowColor, 0.6);
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}
@keyframes handStrengthGlowAnimation {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  50% {
    box-shadow: 0 0 0 3px rgba($yellowColor, 0.6);
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}

.hand-strength {
  position: relative;
  z-index: 5;
  margin-right: 40px;
  padding: 3px 5px;
  min-width: 33px;
  min-height: 33px;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
  font-size: 20px;
  color: $yellowColor;
  background: linear-gradient(
    0deg,
    rgba(black, 0.7),
    $blackjackTransparentBlack
  );
  &.current-turn {
    animation-name: handStrengthGlowAnimation;
    animation-timing-function: ease;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
  .indicator {
    position: absolute;
    top: -25px;
    left: -35px;
    z-index: 5;
    width: 100px;
    font-size: 14px;
    padding: 3px 0;
    color: $yellowColor;
    background: linear-gradient(90deg, transparent, black 50%, transparent);
    overflow: hidden;
    animation-name: indicatorIncomingAnimation;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
  .icon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    border-radius: inherit;
    background-repeat: no-repeat;
    background-size: 100%;
    &.blackjack {
      background-image: url("~@/assets/images/blackjack/icons/bj.png");
    }
    &.busted {
      background-image: url("~@/assets/images/blackjack/icons/bust.png");
      .indicator {
        color: #ccc;
      }
    }
    &.push {
      background-image: url("~@/assets/images/blackjack/icons/push.png");
    }
  }

  .win-lose-indicator {
    position: absolute;
    top: -8px;
    left: -100px;
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $yellowColor;
    .i-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background: linear-gradient(180deg, lighten(black, 20%) 10%, black);
      margin-right: 7px;
    }
    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      text-shadow: 0 1px 3px black;
    }
    &.lose {
      color: rgb(255, 255, 255);
    }
  }
}
</style>
