import Vue from "vue";
import PokerPlayer from "@/constants/game/poker/player";
import PokerGameType from "@/constants/game/poker/gameType";
import BlackjackPlayer from "@/constants/game/blackjack/player";

export default {
  /*
   * Get texas holdem rooms
   */
  getTexasHoldemRooms(state) {
    return [...state.roomList]
      .filter((room) => room.metadata?.gameType === PokerGameType.TexasHoldem)
      .sort(
        (room1, room2) =>
          Number(room1.metadata?.bet) - Number(room2.metadata?.bet)
      );
  },

  /*
   * Get omaha rooms
   */
  getOmahaRooms(state) {
    return [...state.roomList]
      .filter((room) => room.metadata?.gameType === PokerGameType.Omaha)
      .sort(
        (room1, room2) =>
          Number(room1.metadata?.bet) - Number(room2.metadata?.bet)
      );
  },

  /*
   * Get omaha or texas holdem rooms
   */
  getOmahaOrTexasRooms(state) {
    return [...state.roomList]
      .filter(
        (room) =>
          room.metadata?.gameType === PokerGameType[state.lastOpenedTableList]
      )
      .sort(
        (room1, room2) =>
          Number(room1.metadata?.bet) - Number(room2.metadata?.bet)
      );
  },

  /*
   *  Get active room data (state)
   */
  getActiveRoomState(state) {
    return state.ACTIVE_ROOM || {};
  },

  /*
   *  Poker Seats
   */
  nGetSeats(state) {
    const arr = [];
    for (let i = 0; i < state.ACTIVE_ROOM?.maxPlayers; i++) {
      arr.push(PokerPlayer);
    }

    if (state.ACTIVE_ROOM) {
      const players = [...state.ACTIVE_ROOM?.players].filter(
        (player) => player.position > -1
      );
      for (let j = 0; j < players.length; j++) {
        Vue.set(arr, players[j].position, players[j]);
      }
    }

    return arr;
  },

  /*
   *  Bj-Poker my seat
   */
  nGetMySeat(state, getters, rootState) {
    if (rootState.scene === "Blackjack") {
      const player = state.ACTIVE_ROOM.players.find(
        (p) => p.id === rootState.user.id
      );
      if (typeof player === "undefined") return BlackjackPlayer;
      return player;
    }

    return getters.nGetSeats.find((player) => player.id === rootState.user.id);
  },

  nAmISitting(state, getters, rootState) {
    return (
      getters.nGetSeats.filter((player) => {
        if (typeof player !== "undefined" && player.id === rootState.user.id) {
          state.tableSitRequest = true;
          return true;
        }
      }).length > 0
    );
  },

  getBjActiveSeat(state) {
    if (
      state &&
      state.ACTIVE_ROOM &&
      state.ACTIVE_ROOM.players &&
      state.ACTIVE_ROOM.players.length > 0
    ) {
      const players = state.ACTIVE_ROOM.players;
      return [...players].sort((a, b) => b.cards.length - a.cards.length)[0];
    }
    return [];
  },

  isRoomJoinAndDisconnectOn(state, getters, rootState) {
    return (
      state.tableSitRequest &&
      (rootState.modals.disconnect || rootState.modals.disconnectInactivity)
    );
  },
};
