export default {
  oneClickBonus: {
    ready: false,
    lastClaimAt: 0,
    lastPrize: 0,
  },
  adBonus: {
    ready: false,
    lastClaimAt: 0,
    lastPrize: 0,
  },
  adBonusClaiming: false,
};
