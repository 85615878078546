<template>
  <div
    class="payout"
    :class="[$parent.pinned ? 'show' : '']"
    v-click-outside="onClickedOutside"
  >
    <div class="payout-header">
      <div class="payout-header-th">{{ $t("blackJack.type") }}</div>
      <div class="payout-header-th">{{ $t("blackJack.pay") }}</div>
    </div>
    <div class="payout-body">
      <div class="row">
        <div class="column">{{ $t("blackJack.winningsHands") }}</div>
        <div class="column text-right">1:1</div>
      </div>
      <div class="row">
        <div class="column">
          {{ $t("rulesWindow.expressions.bjGameRules.headingInsurance") }}
        </div>
        <div class="column text-right">2:1</div>
      </div>
      <div class="row border-bottom">
        <div class="column">Blackjack (21)</div>
        <div class="column text-right">3:2</div>
      </div>

      <div class="row heading">
        <div class="column heading bold">21+3</div>
      </div>
      <div class="row triple">
        <div class="column">Suited Trips</div>
        <div class="column">
          <span class="card">A&clubs;</span>
          <span class="card">A&clubs;</span>
          <span class="card">A&clubs;</span>
        </div>
        <div class="column text-right">100:1</div>
      </div>
      <div class="row triple">
        <div class="column">Straight Flush</div>
        <div class="column">
          <span class="card">A&clubs;</span>
          <span class="card">K&clubs;</span>
          <span class="card">Q&clubs;</span>
        </div>
        <div class="column text-right">40:1</div>
      </div>
      <div class="row triple">
        <div class="column">Three Of A Kind</div>
        <div class="column">
          <span class="card">K&clubs;</span>
          <span class="card red">K&hearts;</span>
          <span class="card red">K&#9826;</span>
        </div>
        <div class="column text-right">30:1</div>
      </div>
      <div class="row triple">
        <div class="column">Straight</div>
        <div class="column">
          <span class="card">A&clubs;</span>
          <span class="card red">K&hearts;</span>
          <span class="card red">Q&#9826;</span>
        </div>
        <div class="column text-right">10:1</div>
      </div>
      <div class="row triple border-bottom">
        <div class="column">Flush</div>
        <div class="column">
          <span class="card">Q&clubs;</span>
          <span class="card">J&clubs;</span>
          <span class="card">3&clubs;</span>
        </div>
        <div class="column text-right">5:1</div>
      </div>

      <div class="row heading">
        <div class="column heading bold">PERFECT PAIRS</div>
      </div>
      <div class="row triple">
        <div class="column">Colored Pair</div>
        <div class="column">
          <span class="card red">K&hearts;</span>
          <span class="card red">K&hearts;</span>
        </div>
        <div class="column text-right">25:1</div>
      </div>
      <div class="row triple">
        <div class="column">Mixed Pair</div>
        <div class="column">
          <span class="card">8&clubs;</span>
          <span class="card red">8&hearts;</span>
        </div>
        <div class="column text-right">8:1</div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapGetters } from "vuex";

export default {
  name: "Payout",
  directives: { ClickOutside },
  methods: {
    onClickedOutside($event) {
      if (typeof $event?.target?.className.includes === "undefined")
        return false;
      if ($event?.target?.className.includes("pin-button")) return false;
      this.$parent.pinned = false;
    },
  },
};
</script>

<style scoped lang="scss">
.payout {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 12;
  width: 800px;
  height: 1080px;
  background-color: rgba(lighten(black, 10%), 0.8);
  border-radius: 10px;
  overflow: hidden;
  transform: translateY(calc(-100% - 60px));
  opacity: 0;
  transition: all 0.25s ease;
  &.show {
    transform: translateY(0);
    opacity: 1;
  }
  &-header {
    width: 100%;
    height: 90px;
    line-height: 90px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 30px;
    font-size: 32px;
    background-color: rgba(black, 0.7);
    &-th {
      font-weight: bold;
    }
  }
  &-body {
    box-sizing: border-box;
    padding: 30px;
    height: 990px;
    overflow-x: hidden;
    overflow-y: auto;
    .row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      &.border-bottom {
        border-bottom: 1px solid rgba(rgb(255, 255, 255), 0.1);
      }
      .column {
        padding: 15px 20px;
        font-size: 24px;
        width: 50%;
        &.heading {
          width: 100%;
          font-size: 32px;
          background-color: rgba(black, 0.4);
          border-bottom: 1px solid rgba(black, 0.5);
        }
        &.bold {
          font-weight: bold;
        }
        &.text-right {
          text-align: right;
        }
        .card {
          padding-right: 5px;
          &.red {
            color: $redColor;
          }
        }
      }
      &.triple {
        .column {
          width: 33.333%;
        }
      }
    }
  }
}

.platform-mobile {
  .payout {
    position: fixed;
    top: 0;
    left: 0;
    height: 1080px;
    border-radius: 0;
    background-color: rgba(lighten(black, 10%), 0.95);
    transform: translateX(-100%);
    &.show {
      display: block;
      transform: translateX(0);
    }
    &-body {
      height: 990px;
    }
  }
}
</style>
