import axios from "@/services/api/axios";
import i18n from "@/plugins/i18n";
import toast from "@/plugins/toast";

export default {
  async sendChip(store, { amount, receiverId, description }) {
    try {
      const response = await axios.post(`/chip-transfer/send`, {
        amount: parseInt(amount),
        to: receiverId,
        description: description,
      });
      return response.data;
    } catch (error) {
      if (error.response.data.code === 400) {
        toast.instance.show({
          text: i18n.t("errors.chipTransferOneMonthWait"),
          duration: 2500,
          variant: "danger",
        });
        return false;
      } else if (error?.response?.status) {
        toast.instance.show({
          text: i18n.t("errors.insufficientBalance"),
          duration: 2500,
          variant: "danger",
        });
        return false;
      }
      toast.instance.show({
        text: i18n.t("playerActions.failedTransfer"),
        duration: 2500,
        variant: "danger",
      });
      return false;
    }
  },

  async getHistory({ commit }, offSet) {
    try {
      const response = await axios.get(
        `/chip-transfer/history?offset=${offSet}&limit=100&startDate=1563528274&endDate=${Math.round(
          (new Date().getTime() + 86400) / 1000
        )}`
      );
      if (response.data) {
        commit("setChipTransferHistory", {
          data: response.data,
          offSet: offSet,
        });
      }
    } catch (error) {
      return false;
    }
  },
  async getFilterHistory({ commit }, { offSet, startDate, endDate, userId }) {
    try {
      let reqQuery = "/chip-transfer/history?limit=100&";
      if (userId) reqQuery += "userId=" + userId + "&";
      if (startDate) reqQuery += "startDate=" + startDate + "&";
      if (endDate) reqQuery += "endDate=" + endDate + "&";
      if (typeof offSet !== "number" || offSet) reqQuery += "offSet=" + offSet;
      const response = await axios.get(reqQuery);
      if (response.data) {
        commit("setChipTransferHistory", {
          data: response.data,
          offSet: offSet,
        });
      }
    } catch (error) {
      return false;
    }
  },
};
