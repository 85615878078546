import emptyCard from './card';

export default {
    id: 0,
    vip: false,
    avatar: '',
    crown: 0,
    name: '',
    position: -1,
    stack: 0,
    minSitAmount: 0,
    in: false,
    talked: false,
    bet: 0,
    totalBet: 0,
    disconnected: false,
    leaving: false,
    lastAction: 0,
    cards: [{ ...emptyCard }, { ...emptyCard }],
    isInProgressiveJackpot: false,
    ghost: false
};
