<template>
  <div
    class="quick-menu"
    :class="[getRealPlatform === 'android' ? 'no-flick' : '']"
  >
    <AdBonus
      ref="adBonus"
      v-if="getRealPlatform !== 'web' && showAdBonus"
      class="quick-menu-item bonus-button"
    />
    <OneClickBonus class="quick-menu-item bonus-button" />
    <div class="quick-menu-area" @click="showNotificationWindow">
      <div class="notifications right-side">
        <div
          class="invite-notification flex-center"
          v-show="
            inviteNotificationValue && getInviteList && getInviteList.length > 0
          "
        >
          <span class="invite-notification-count">{{
            friendInviteNotification.inviteCount
          }}</span>
        </div>
        <span class="lobby-sprite notification-icon"></span>
      </div>
    </div>

    <div class="quick-menu-area area-settings" @click="showSettingsWindow">
      <div class="settings right-side" @click="showSettingsWindow">
        <span class="lobby-sprite settings-icon"></span>
      </div>
    </div>

    <div
      class="quick-menu-dropdown"
      :class="[
        openDropdownMenu ? 'show' : '',
        getRealPlatform === 'android' ? 'no-flick' : '',
      ]"
      v-click-outside="onClickedOutsideDropdown"
    >
      <button
        type="button"
        class="quick-menu-dropdown-item atlas-3-sprite lobby-settings-button no-click-outside"
        @click="showSettingsWindow"
      ></button>
      <button
        type="button"
        class="quick-menu-dropdown-item atlas-2-sprite lobby-hand-history-icon no-click-outside"
        @click="showHistoryWindow"
      ></button>
      <button
        type="button"
        class="quick-menu-dropdown-item atlas-2-sprite lobby-rules-button no-click-outside"
        @click="showRulesWindow"
      ></button>
      <button
        type="button"
        class="quick-menu-dropdown-item atlas-3-sprite lobby-contact-us-button no-click-outside no-flick"
        @click="showInboxWindow"
      ></button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import OneClickBonus from "./QuickMenu/OneClickBonus";
import AdBonus from "./QuickMenu/AdBonus";
import AnalyticsEvents from "@/plugins/analytics/events";

export default {
  name: "QuickMenu",
  components: {
    OneClickBonus,
    AdBonus,
    MusicIcon: () => import("@/components/Icons/Music"),
    CrossIcon: () => import("@/components/Icons/Cross"),
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vnode) {
        el.eventSetDrag = function () {
          el.setAttribute("data-dragging", "yes");
        };
        el.eventClearDrag = function () {
          el.removeAttribute("data-dragging");
        };
        el.eventOnClick = function (event) {
          var dragging = el.getAttribute("data-dragging");
          // Check that the click was outside the el and its children, and wasn't a drag
          if (!(el == event.target || el.contains(event.target)) && !dragging) {
            // call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.addEventListener("touchstart", el.eventClearDrag);
        document.addEventListener("touchmove", el.eventSetDrag);
        document.addEventListener("click", el.eventOnClick);
        document.addEventListener("touchend", el.eventOnClick);
      },
      unbind: function (el) {
        document.removeEventListener("touchstart", el.eventClearDrag);
        document.removeEventListener("touchmove", el.eventSetDrag);
        document.removeEventListener("click", el.eventOnClick);
        document.removeEventListener("touchend", el.eventOnClick);
        el.removeAttribute("data-dragging");
      },
    },
  },
  computed: {
    ...mapState({
      showAdBonus: (state) => state.config?.show_ad_bonus || false,
    }),

    ...mapGetters({
      getPreferenceValue: "preferences/getValue",
      getRealPlatform: "getRealPlatform",
      getInviteList: "friends/getInviteList",
    }),
    friendInviteNotification() {
      if (this.getInviteList && this.getInviteList.length > 0) {
        this.inviteNotificationValue = true;
        return {
          inviteCount: this.getInviteList.length,
        };
      }
      this.inviteNotificationValue = false;
      return false;
    },
  },
  data() {
    return {
      openDropdownMenu: false,
      musicValue: false,
      inviteNotificationValue: false,
    };
  },
  watch: {
    friendInviteNotification: {
      handler(val) {
        this.inviteNotificationValue = true;
      },
      deep: true,
    },
    // musicValue: {
    //   handler(val) {
    //     this.$store.state.preferences.musicValue = val;
    //     if (val) {
    //       // if (this.getRealPlatform === "android") {
    //       //   this.$plugins?.audio?.loop("Lobby1");
    //       //   this.$plugins?.audio?.play("Lobby1");
    //       // } else if (this.getRealPlatform === "ios") {
    //       //   this.$plugins?.audio?.loop("Lobby1");
    //       // } else {
    //       //   this.$plugins?.audio?.loop("Lobby1");
    //       //   this.$plugins?.audio?.play("Lobby1");
    //       // }
    //       console.log("this.musicElement", this.musicElement);
    //       this.musicElement?.play();
    //     } else {
    //       console.log("this.musicElement", this.musicElement);
    //       this.musicElement?.pause();
    //       // this.$plugins?.audio?.stop("Lobby1");
    //     }
    //   },
    //   immediate: true,
    // },
  },
  methods: {
    friendsInvite() {
      this.$store.commit("modals/show", "social");

      this.$plugins.audio.play("Button");
    },
    onSimpleMenuClicked() {
      this.$data.openDropdownMenu = !this.$data.openDropdownMenu;
      this.$plugins.audio.play("Button");
    },
    onClickedOutsideDropdown($event) {
      if (
        typeof $event?.target?.className == "object" ||
        !this.$data.openDropdownMenu ||
        $event?.target?.className?.includes("no-click-outside")
      )
        return false;
      this.$data.openDropdownMenu = false;
    },
    async toggleSoundsPreference() {
      this.$plugins.audio.play("Button");
      await this.$store.dispatch("preferences/set", {
        key: "sounds",
        value: this.getPreferenceValue("sounds") == 1 ? "0" : "1",
      });
    },
    showRulesWindow() {
      this.$store.commit("modals/show", "rules");
      this.$data.openDropdownMenu = false;
      this.$plugins.audio.play("Button");
    },
    showNotificationWindow() {
      this.$store.commit("modals/show", "notificationWindow");
      this.inviteNotificationValue = false;
      this.$plugins.audio.play("Button");
    },
    showSettingsWindow() {
      this.$store.commit("modals/show", "newSettingsWindow");
      this.$data.openDropdownMenu = false;
      this.$plugins.audio.play("Button");
      this.$adjust.trackEvent("ClickGoToSettings");
    },
    showAffiliateWindow() {
      this.$store.commit("modals/show", "affiliate");
      this.$data.openDropdownMenu = false;
      this.$plugins.audio.play("Button");
    },
    showInboxWindow() {
      this.$store.commit("modals/show", "inbox");
      this.$data.openDropdownMenu = false;
      this.$plugins.audio.play("Button");
    },
    showHistoryWindow() {
      this.$store.commit("modals/show", "history");
      this.$data.openDropdownMenu = false;
      this.$plugins.audio.play("Button");
    },
    showSearchWindow() {
      this.$store.commit("modals/show", "search");
      this.$data.openDropdownMenu = false;
      this.$plugins.audio.play("Button");
    },
  },
  mounted() {
    //   if (this.getRealPlatform !== "web")
    //     setTimeout(() => {
    //       this.musicValue = true;
    //     }, 500);
    // if (this.getRealPlatform === "android") {
    //   this.$plugins?.audio?.loop("Lobby1");
    //   this.$plugins?.audio?.play("Lobby1");
    // } else if (this.getRealPlatform === "ios") {
    //   this.$plugins?.audio?.loop("Lobby1");
    // } else {
    //   this.$plugins?.audio?.loop("Lobby1");
    //   this.$plugins?.audio?.play("Lobby1");
    // }
    // setTimeout(() => {
    //   this.musicElement = document.getElementById("lobby_music");
    //   if (this.musicElement) {
    //     this.musicElement.volume = 0.2;
    //   }
    // }, 2500);
  },
};
</script>

<style scoped lang="scss">
.quick-menu {
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
  min-width: 400px;
  width: 33%;
  .quick-menu-area {
    cursor: pointer;
    margin-right: 60px;
    .notifications {
      .invite-notification {
        position: absolute;
        height: 40px;
        width: 40px;
        bottom: 80px;
        left: 70px;
        background-color: rgba(rgb(208, 28, 28), 0.5);
        border: 2px solid #cf707f;
        border-radius: 30px;
        .invite-notification-count {
          font-size: 32px;
          color: #ffffff;
          text-shadow: 0 3px 5px #000000;
        }
      }
    }
    &.settings {
      margin-right: 25px;
    }
  }
  .area-settings {
    margin-right: 30px;
  }
  .bonus-button {
    margin-right: 70px;
  }
  &-item {
    position: relative;
    z-index: 3;
    margin-right: 40px;
    transition: all 0.25s ease;
    cursor: pointer;
    &:not(:disabled) {
      &:active {
        filter: brightness(70%);
      }
    }
    &.lobby-simple-menu {
      margin-right: 0;
    }
  }
  .slide-text {
    right: 483px;
  }
  &-dropdown {
    position: absolute;
    top: 55px;
    right: 34px;
    width: 90px;
    padding-top: 65px;
    box-sizing: border-box;
    border-radius: 0 0 50px 50px;
    background-color: rgba(#522011, 0.7);
    transform-origin: top;
    transform: scaleY(0);
    transition: all 0.25s ease;
    &.show {
      transform: scaleY(1);
    }
    .quick-menu-dropdown-item {
      padding: 0;
      background-color: transparent;
      margin-left: -3px;
      transition: filter 0.25s ease;
      &.inbox {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: red;
        margin-left: 20px;
      }
      &:active {
        filter: brightness(50%);
      }
    }
  }
  .right-side {
    width: 95px;
    height: 95px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #cf707f;
    border-radius: 100%;
    background: rgba(black, 0.5);
    margin-bottom: -15px;

    &:active {
      filter: brightness(50%);
    }
  }
}
</style>
