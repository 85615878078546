import AppConstants from "./constants/app";
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import axios from "@/services/api/axios";
import animejs from "animejs";
import VeTable, { VePagination } from "vue-easytable";
import { Capacitor } from "@capacitor/core";
const ExcelJS = require("exceljs");

import VueLazyload from "vue-lazyload";

const errorAvatar = require("@/assets/images/avatar/8.png");

const vueLazyLoadOptions = {
  preLoad: 1,
  error: errorAvatar,
  loading: errorAvatar,
  attempt: 1,
};

Vue.use(VeTable);
Vue.use(VePagination);
Vue.use(VueLazyload, vueLazyLoadOptions);
Vue.use(ExcelJS);

Vue.prototype.$service = axios;
Vue.prototype.$constant = AppConstants;
Vue.prototype.$animejs = animejs;
Vue.prototype.$excelJS = ExcelJS;

// Country Flag
import CountryFlag from "vue-country-flag";
Vue.component("country-flag", CountryFlag);

// Event Bus
import EventBus from "./event-bus";

// Logger
import Logger from "./logger";
Vue.prototype.$logger = Logger;

// i18n
import i18n from "./plugins/i18n";

// touch-events
import Vue2TouchEvents from "vue2-touch-events";
Vue.use(Vue2TouchEvents);

// import AppUpdate from "@/plugins/capacitor-app-update";
// Vue.use(AppUpdate);
// AppUpdate.install(Vue);

import AppTracking from "./plugins/capacitor-plugin-app-tracking-transparency/index";
if (Capacitor.getPlatform() === "ios") {
  const AppTrackingRequest = async () => {
    const trackRes = await AppTracking.init();
    console.log("AppTrackingRequest", trackRes);
  };
  AppTrackingRequest();
}
// Plugins
import Plugins from "./plugins";
Plugins.install(Vue, AppConstants.plugins).then(async () => {
  await mainApp.$plugins.audio.preload();
  await store.dispatch("start");
  await mainApp.$plugins.splashScreen.hide();
  store.state.authentication.showOverlay = false;
});

import adjust from "@/plugins/adjust";
adjust.init();

Vue.prototype.$adjust = adjust;

Vue.config.productionTip = true;

const mainApp = new Vue({
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default mainApp;
