import AppConstants from "@/constants/app";

const instance = () => {
  if (typeof window.store !== "undefined") {
    window.store.error((error) => {
      console.log("ERROR", error);
    });

    window.store.verbosity =
      AppConstants.mode === "development"
        ? window.store.DEBUG
        : window.store.QUIET;
    return window.store;
  }
  return {
    register: function () {},
    when: function () {},
    approved: function () {},
    cancelled: function () {},
  };
};

const InAppPurchase = {
  instance: instance(),

  install(Vue) {
    Vue.prototype.$plugins.inAppPurchase = instance();
  },
};

if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(InAppPurchase);
}

export default InAppPurchase;
