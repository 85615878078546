/*
 * English language file
 */ /*
 * English language file
 */

export default {
  loading: "Loading...",

  general: {
    no: "No",
    yes: "Yes",
    tableInformation: "Table Information",
    avatar: "Avatar",
    vipIcon: "VIP Icon",
    cancel: "Cancel",
    save: "Save",
    logout: "Logout",
    play: "Play",
    chat: "Chat",
    leaders: "Leaders",
    social: "Social",
    case: "Case",
    shop: "Shop",
    okay: "Okay",
    refresh: "Refresh",
    send: "Send",
    username: "Username",
    delete: "Delete",
    reject: "Reject",
    accept: "Accept",
    confirm: "Confirm",
    registrationDate: "Reg. Date",
    name: "Name",
    total: "Total",
    copy: "Copy",
    commission: "Commission",
    getGift: "Get Your Gift",
    you: "You",
    transfer: "Transfer",
    menu: "Menu",
    all: "All",
    update: "Update",
    invite: "Invite",
    joinNow: "Join Now",
    handHistory: "Hand History", //NEW***********
  },
  giftsWindow: {
    gift: "Gift", //NEW***********
    getYourself: "For You", //NEW***********
    getGift: "Buy Gift", //NEW***********
    getEveryone: "For Everyone", //NEW***********
    expression: "Emoji", //NEW***********
    selectGift: "Please select a gift", //NEW***********
    noPlayersAtTable: "No players at the table", //NEW***********
    sitATable: "Please sit at the table first", //NEW***********
    selectUser: "Please select a user", //NEW***********
  },
  dailyEntry: {
    dailyEntry: "Daily Bonus", //NEW***
    dailyDesc: "Log in every day and receive more chips!", //NEW***
    day: "Day", //NEW***
  },
  noficationWindow: {
    notificationInviteTable:
      "{name} invited you to the table in {gameType}{tableId} at {date}", // NEW***
    notificationInviteChip: " {name} sent you {amount} chip.", // NEW***
    sendMessage: " Please type your message here", // NEW***
    tableInvite: "Invitation", // NEW***
    system: "System", // NEW***
    messages: "Messages", // NEW***
    notifications: "Notifications", // NEW***
  },
  authentication: {
    signInToContinue: "Sign-in to continue",
    anErrorOccuredWhileLoggingIn: "An error occurred while logging in!",
    contact: "Contact",
    privacyPolicy: "Privacy Policy",
    provablyFair: " Provably Fair",
    sign_guest: "Play as Guest",
    sign_apple: "Login with Apple",
    sign_google: "Login with Google",
    sign_facebook: "Login with Facebook",
    loginBonusText:
      "<p class='login-info-text'> Get bonus chips! <span class='color-white'> {0} </span> or <span class='color-white'> {1} </span> </p>",
    loginBonusTextWeb:
      "<p class='login-info-text'> Get bonus chips! <span class='color-white'> {0} </span> </p>",
  },
  playerActions: {
    enoughChips: "Insufficient balance",
    minSendChipAmount: "Minimum 10B",
    failedTransfer: "Failed to transfer chips",
    successTransfer: "Chips transferred successfully.",
    validChip: "Please enter a valid amount of chips",
  },
  tableList: {
    table: "Table",
    tableId: "Table ID",
    tableName: "Name",
    blind: "Blind",
    minMax: "Min/Max",
    filterByBlinds: "Filter by Blinds",
    hideEmpties: "Hide Empty Tables",
    hideFullyFilled: "Hide Full Tables",
    players: "Players",
    full: "Full",
    all: "All",
    noRoomsMessage: "No rooms",
    tableList: "Table List",
    tableInfo: {
      emptyRoomMessage: "The room is empty.",
    },
  },
  message: {
    message: "Message",
    sendMessage: "Send Message",
    typeMessage: "Type here",
    sentMessage: "Sent you a message",
  },

  lobby: {
    play: "Play",
    playNow: "Play Now",
    newSlotGame: "New Slot Game",
    availableNow: "Available Now",
    specialForYou: "Special For You",
    favouriteGameButton: {
      instructionMessage: "Double Tap To Change",
    },
  },

  bonusWindow: {
    congratulations: "Congratulations!",
    accept: "Accept",
  },

  maintenance: {
    gameMaintenance: "The game is under the maintenance",
    underMaintenance: "Under the Maintenance",
    tryAgainLAter:
      "Game is currently under maintenance. Please try again later!",
  },

  confirmWindow: {
    sure: "Are you sure?",
    userNotFound: "User Not Found",
    notAffiliate: "User Not Affiliate",
    DaysVip: "You buy 30 days VIP subscription",
  },
  deleteConfirmWindow: {
    sure: "You are about to delete your account",
    content:
      "If you continue that process, your user information and the products you have purchased will be deleted",
    delete: "Delete",
    cancel: "Cancel",
    deleteSuccessfully: "Account deleted successfuly",
    notBeDeleted: "An error has occurred",
    areYouSure: "Are you sure you want to delete your account?",
    deletefriends: "Friend will be removed from the friend list.",
    daysLimit:
      "If your account is not active during 30 days, it will be permanently deleted.",
  },
  historyWindow: {
    players: "Players",
    hand: "Hand",
    preFlop: "Pre-Flop",
    flop: "Flop",
    turn: "Turn",
    river: "River",
    winner: "Winner",
    speacial: "Special",
    team: "Team",
    anyHistory: "No history to show",
  },
  socialWindow: {
    gameCardDesc: "Get bonus from the tickets you have won.",
    gameCard: "Game Card",
    friends: "Friends",
    searchFriend: "Search Friend",
    requests: "Requests",
    chipTransferHistory: "Chip Transfer History",
    socialMedia: "Social Media",
    socialMediaAndInviteCode: "Social Media & Invite Code",
    noFriendRequest: "No friend requests.",
    noFriends: "No friends.",
    noChipTransfer: "No chip transfer history",
    extraChip: "Congratulations!! Your chips have been added to your account.",
    followUs: "Follow us on Social Media!",
    inviteCode: "Invite Code",
    myCode: "My Code",
    sharecode: "Share Code", // NEW*******
    inviteCodeDescription:
      "-Share the invite code above with your friend. <br><br>- Let your friend, as a new game member, play 10 hands at poker tables. <br><br>- Both of you receive 10B chips!<br>",
    shareWithFriends: "Share with your friends!",
    shareWithFriendsCode:
      "Here is our bonus code! Get 10B bonus chips after your friends play 10 poker hands. Code: {code}",
    shareInviteCodeWithFriends: "Share your invite code",
    removeFriend: "Remove from friends",
    block: "Block",
    unBlock: "Unblock",
  },
  friendDetailsWindow: {
    failed: "Failed to send friend request",
  },
  chipHistoryDetail: {
    youSent: "You Sent",
    chips: "Chips",
    sentYou: "Sent You",
  },
  createTableWindow: {
    bets: "Bets",
    createTable: "Create Table",
    seatsNumber: "Max Seats",
    potTable: "Pot Table",
    pot: "Pot",
    teamTable: "Team Table",
    tellerEntrance: "Minimum Limit",
    entryLimit: "Minimum Limit",
    ipLimit: "IP Limit",
    create: "Create",
  },
  chipTransferWindow: {
    sendChip: "Send Chip",
    chipAmount: "Enter chip amount",
    chipMessage: "Type your message",
    warning: "Minimum 10B",
  },
  playerDetailsWindow: {
    profileNote: "Profile Note",
    statistics: "Statistics",
    level: "Level",
    recordChip: "Chips Record",
    friendsCount: "Friends",
    bestHand: "Best Hand",
    copied: "Copied!",
    failedChanges: "Failed to save changes",
    enterProfileNote: "Enter your profile note..", //NEW****
    gameStatistics: "Game Statistics", //NEW****
    sameFrameWarning:
      "Please choose a frame other than the one you are currently using.", //NEW****
    sameAvatarWarning:
      "Please choose an avatar other than the one you are currently using.", //NEW****
    fillFields: "Please fill out all the fields", //NEW****
    passwordsDoesNotMatch: "Your passwords don't match each other", //NEW****
    passwordLength: "Password should be between 6 and 30 characters",
    vipdesc: "This feature is only available for VIP players.",
    changeName:
      "Your right to change your name has expired. You can buy VIP and try again",
  },
  shopWindow: {
    bronze: "Bronze",
    silver: "Silver",
    gold: "Gold",
    redeem: "Redeem",
    keysField: "Please enter the game card keys to the fields.",
    withoutChip: " Entry The Table Without Chips",
    buyChip: "Buy Chip",
    redeemCode: "Redeem Code",
    noProduct: "No products found to display.",
    buyVip: "Buy Vip",
    privateEmojis: "VIP Emoji, Gift, Profile Frame",
    privateProfile: "Special VIP Profile Frames",
    connectionNeverLost: "Persistent Connection",
    unLimitedView: "Limitless Spectating Time",
    privateTable: "Set Up Special Table",
    unlimitedChipTransfer: "Unlimited Chip Transfer",
    usingPrivateCase: "Use Special Private Case",
    xp: "10.000XP Gift",
    freeChat: "Free Global Chat",
    day: "Day",
    daysLeft: "Days Left",
    alreadyVip: "You already have VIP subscription.",
    noChips: "You don't have enough chips to buy VIP subscription",
    successfullyPurchased: "You successfully purchased VIP subscription.",
    chipAdded: "Chips received successfully",
  },
  caseWindow: {
    onlyVip: "This feature is available only for VIP users",
    buyVip: "Buy VIP",
    chipInGame: "Chip In Game",
    chipInCase: "Chip In Case",
    theretentation:
      "Hide your chips from other players and the list of leaders.",
    fromGame: "From Game",
    fromCase: "From Case",
    amount: "Enter Amount",
    transaction: "Action completed.",
    transactionFailed: "Transaction failed",
    enoughChip: "You don't have enough chips to deposit in case.",
    withdraw: "You don't have enough chips to withdraw from the case.",
    validAmount: "Input a valid amount",
    transfer: "Transfer",
  },
  friendsRequestWindow: {
    friendsOffer: "YOU RECEIVED A FRIEND REQUEST!",
    successRequest: "Friend request has successfully sent.",
    acceptRequest: "has accepted your friend request.",
    rejectedRequest: "has rejected your friend request.",
    becomeFriends: "sent you a friend request! Do you accept?",
    requestSent: "Request already sent",
    friendRequestToYou: "sent you a friend request.", //NEW***********
  },
  settingsWindow: {
    language: "Language",
    gameSettings: "Game Settings",
    customize: "Customize",
    security: "Security",
    successfullySaved: "Changes are successfully saved.",
    pinCode: {
      pinCode: "PIN-CODE",
      newPass: "New Password was send to your email",
      createPinDesc: "Create a pin-code for account security",
      reEnterPin: "Re-enter pin-code",
      emailAddress: "Email",
      areYouSure: "Do you want to remove your pin-code?",
      emailRequired: "Required to reset your password",
      areYouSure: "Do you want to remove pin-code?",
      pinDesc:
        "Creating a pin-code is very important for account security. Turn Games will not accept any report unless you have a pin-code.",
    },
    customizeWindow: {
      tableStyle: "Table Style",
      tableColors: "Table Colors",
      roomBgColor: "Room Background Color",
    },
    gameSettingsWindow: {
      dontDistrub: "Do not Disturb",
      blockNotification: "Notifications",
      showAnimatedGifts: "Show Emotes",
    },
  },
  reportWindow: {
    report: "Report",
    reportFilter: "Report Filter",
    audiences: "Audiences",
    players: "Players",
    successMessage: "Report sent.",
    failedMessage: "Failed to report.",
    swearing: "Swearing",
    team: "Team",
    scissors: "Multiple Raise",
    noPlayersFound: "No Players Found",
  },
  inboxContact: {
    contactUs: "Contact Us",
    title: "Title",
    textHere: "Type Here",
    congratulations: "Congratulations",
    inbox: "Inbox",
    desc: "You have earned 10,000 chips for the last purchased package.",
    failedMessage: "Failed to send message",
    successfullyMessage: "Message sent successfully",
    validEmail: "Please enter a valid email",
    fillTabs: "Please fill out all tabs",
  },
  errors: {
    connectionLost: "Connection Lost",
    yourConnection: "Connection Lost",
    errorOccured: "An error has occured",
    gameServiceError: "Game Server error occurred. Please try again later",
    slotDownloadError: "An error occurred while downloading",
    loginError: "An error occurred while logging in.",
    insufficientBalance: "Insufficient balance.",
    tooManyRequests: "You cannot request that often.",
    haveToSitAtTable: "You have to sit at the table.",
    youAreBlocked: "You are blocked.",
    youAreBlockedTheUser: "You blocked the user.",
    youAreUnblockedTheUser: "You unblocked the user.",
    chipTransferOneMonthWait: "Chip transfer will be able after 30 days",
    userNotActiveGame: "The user is currently not in game",
    notEnoughLevelPlayGame: "{level} level required to join {game} table.",
    tableNotFound: "Table is not found",
    playerIdWrong: "User ID is incorrect",
    searchedUserNotFound: "Searched user not found",
  },
  searchWindow: {
    searchUser: "Search User",
    idOfUser: "Please Enter ID",
    search: "Search",
    ownId: "This is your own ID",
    validId: "Please Enter a valid ID",
  },
  adBonusWindow: {
    title: "EARN FREE CHIPS!",
    content: "Watch a short video and earn ${money} chips!",
    watchButton: "Watch Now",
  },
  affiliate: {
    affiliateDesc: "Enter your friend's reference ID.",
    affiliateOffDesc: "Your reference ID",
    affiliate: "Affiliate",
    addAffiliate: "Add An Affiliate",
    affiliateId: "Enter Affiliate ID",
    clear: "Clear",
    searchBy: "Search",
    name: "Name",
    noData: "Loading",
    startDate: "Start Date",
    endDate: "End Date",
    warning: "Become our partner and provide yourself with a steady income!",
    info: "Learn more about how to join us.",
    failedAffiliate: "Failed to add affiliate",
    successfullyAdded: "Affiliate ID was added successfully.",
    date_wrong: "The start date must be before the end date",
    affiliateIdTitle: "Affiliate Id",
    excelDownloaded: "Downloaded to Documents",
  },
  chat: {
    worldChat: "Global Chat",
    directChat: "Direct Chat",
    onlyVip: "Only VIP users can send messages on the Global Chat",
    showMore: "Show More",
    anyMessages: "No Message",
  },
  disconnectedWindow: {
    disconnected: "Disconnected",
    disconnectedServer: "Oops… You had been disconnected from the server!",
    reconnect: "Reconnect",
    inactive: "Oops... You had been inactive for too long!",
    reconnected: "RECONNECT",
    banned: "You have been banned from the game!",
    bannedDescription: "You can contact us via support@turngs.com.",
  },

  rulesWindow: {
    texasDesc:
      "Texas Hold'em Poker is the world’s most popular card game. The aim of the game is to win with a higher hand than the opponents. There are 2 cards in your hand and 5 cards on the table, the hand that makes the best combination of 5 cards out of a total of 7 cards wins. Each hand in the list below beat the following hand. For example, Straight Flush beats Four of Kind, Straight beats Pairs. Hands are valued according to their powers.",
    omahaDesc:
      "Omaha is one of the most popular kinds of poker in the world. The aim of the game is to win the pot. There are 2 main ways to earn chips: to win a poker hand higher than the opponent's hand or to wait for the opponent's pass or refuse the game. A hand contains 5 cards. There is a rule that hands must contain three of the five community cards on the table and two of the four cards dealt with by the player. All hands in a category in the list beat all hands in the categories below it. Each hand in the list below beat the following hand. For example, Straight Flush beats Four of Kind, Straight beats Pairs. Hands are valued according to their powers.",
    blackJackDesc:
      "The purpose of Turn Blackjack is to have a hand with higher points than the croupier without exceeding 21. The best hand is called Blackjack - the total value of the first two cards dealt in this hand is 21. You play just against the croupier, not against another player. Blackjack is played with eight decks. The croupier always stops at 17. Can be doubled on any two starter cards. The starting cards of the same value can be divided into two. Split can be done by each hand. One card is dealt for each double Ace split. Double cannot be done after splitting the cards. Insurance is recommended when the croupier has an Ace. Blackjack pays 3:2. Insurance pays 1:2. When the hands are equal, it's a draw.",
    handNames: {
      royalFlush: "Royal Flush",
      straightFlush: "Straight Flush",
      fourOfAKind: "Four Of A Kind",
      fullHouse: "Full House",
      flush: "Flush",
      straight: "Straight",
      threeOfAKind: "Three Of A Kind",
      twoPair: "Two Pair",
      pair: "One Pair",
      highCard: "High Card",
    },
    handDesc: {
      royalFlush: "The royal flush is the highest poker hand.",
      straightFlush:
        "Five consecutive cards of the same suit. Between 2 Straight Flush hands, the winner is the one who has the highest card.",
      fourOfAKind:
        "Four cards of the same rank. The strongest combination is the one with the higher value cards. If players have the same hands, the value of the fifth card (kicker) determines the winner.",
      fullHouse:
        "Triple and pair combination. While comparing Full House hands, the player with the best three cards wins. If they are the same, the player with the best pair wins.",
      flush:
        "Five unordered cards of the same suit. If two or more players have the same suit, the player with the highest card wins.",
      straight:
        "Five consecutive cards of two or more different suits. Between the two Straight hands, the winner is the one who has the highest value card.",
      threeOfAKind:
        "Combination of three cards of the same rank. Of the two trios, the winner is the one with the highest value cards. If threes are of the same value, the higher of the remaining two kickers determines the winner.",
      twoPair:
        "Two cards of the same rank plus two cards of another rank. When comparing doubles, the highest pair determines the winner. If the high pairs have the same value, the low pairs are compared. If the lower pairs are of the same value, the player with the higher value fifth card (kicker) wins.",
      pair: "Two cards of the same rank. If players have the same pair, the highest of the three remaining cards (kicker) determines the winner.",
      highCard:
        "A poker hand that doesn’t match the requirements of the hands described before. If players have the same high card, the second high card (etc.) determines the winner.",
    },
    expressions: {
      blinds: {
        heading: "Blinds",
        desc: "Before the cards are dealt, the player who sits to the left side on the dealer’s left must place a bet called the small blind. The next player sitting to the left of the small blind must place a so-called big blind. The amount of big blinds is double the amount of the small blind.",
      },
      ante: {
        heading: "Ante",
        desc: "If a player is joining the game, he is sitting at the table and is not in the big blind position, that player places a forced bet called the ante. It can be done once and the ante bet is equal to the big blind.",
      },
      minimumRaise: {
        heading: "Minimum Raise",
        desc: "A player cannot raise an amount less than the big blind.",
      },
      allIn: {
        heading: "All In",
        desc: "If a player doesn’t have enough chips to see a bet, the player can do 'all-in'. Player bets with everything left. If a player does all-in, the player can't win more chips than he can see. If the pot goes up after the player does all-in and wins, the pot will be split between that player and the player with the next best hand who could make raises.",
      },
      potSplit: {
        heading: "Pot Split",
        desc: "If two or more players are in a draw, the pot is split equally between them.",
      },
      bjGameRules: {
        heading: "Game Rules",
        desc: "The game is managed by a croupier and an unlimited number of players can play per hand. The game is presented by a croupier and is played with eight standard decks of 52 cards. The 21 card values in the game are as follows. Cards from 2 to 10 have the values written on them.Picture cards (Jacks, Queens and Kings) are worth 10 points. Aces are worth 1 or 11, the player can choose the appropriate value according to his hand. Note that the soft hand contains 11 value Aces. When the betting time has expired, the croupier hands out a face-up card to all players, the last to himself. After that, the croupier deals a second card face-up to all players, but the croupier's second card will be face down. The value of your first hand is shown next to your cards. Blackjack (Twenty-One) If the two cards you were dealt at the beginning of the game add up to 21, you have Blackjack!",
        descInsurance:
          "If the croupier's face-up card is an Ace, you can buy insurance against the risk of the croupier having 21, even if your total hand is 21. The insurance amount will be equal to half of your main bet and the insurance bet will be processed separately from your hand bet. After that, the croupier looks at the value of his down card to check if he made a 21.If the croupier does not make 21, the round continues. If the croupier has Blackjack and you don't, the game is over and the croupier wins. If both you and the croupier have 21, the game will end in a draw and your bet will be refunded. Note that if the croupier's face-up card is a 10 or a color card, you will have the option to buy the insurance and the croupier will not look at his down card for 21.",
        headingInsurance: "Insurance",
        headingHit: "Double, Hit or Stand",
        descHit:
          "If the croupier finds that after checking his first two cards he has not made a 21, the players are given the right to choose more cards to improve the value of their hand, by giving the croupier more cards to the players. If your first hand is not worth 21, you have a right to double the bet. In this case, your bet will be doubled and you will be given only one additional card to add to your hand. Alternatively, you can use the Hit card option to get additional cards to add to the value of your hand. You can Hit multiple cards until you are satisfied with the value of your hand and decide to Stand. Note that a player with a soft hand equal to 21 will not be given a decision option.",
        headingSplit: "Split In Two",
        descSplit:
          "If your first-hand consists of two cards of the same rank, you can split the pair to two different hands, where each one has a bet equal to your main bet. After both hands are dealt with a second card, you can increase the value of these two hands by applying the Hit card option. Here, you can choose the Stand option when you are satisfied with the value of both hands. However, when you split the first pair of Ase, both hands will automatically receive an additional card and will not have a chance to get another card.",
      },
    },
    actions: {
      desc: "During a betting round, the player can take one of the following actions.",
      call: {
        heading: "Call",
        desc: "If a bet has been made, seeing it means a player has to cover the bet. For example, if Player A bets 10 and player B sees it, Player B must cover his bet of 10 and place a 10 into the pot.",
      },
      check: {
        heading: "Check",
        desc: "A player can play a check if no bets have been made in the current round. The action will be moved to the next player on the left. The check can be interpreted as a kind of pass; the player can stay in the game but decide not to bet on the current round.",
      },
      raise: {
        heading: "Raise",
        desc: "A player can increase the current size of the bet. To do this, the bet must at least double the previous player's bet. To continue the pot fight, all following players must see this bet, raise it again, or pass.",
      },
      fold: {
        heading: "Fold",
        desc: "A fold is a refusal to struggle for the current pot. If a player passes, the player's cards are not dealt with and the player cannot win in the current round.",
      },
    },
    states: {
      winning: {
        heading: "Winning",
        desc: "One or more players with the winning hand must show their down cards.",
      },
      aggressivePlayer: {
        heading: "Aggressive Player",
        desc: "The aggressive player is the last player to raise the bet during the hand. If the bet is seen and the hand ends with a reveal, the aggressive player has to show his down cards regardless of the outcome of the hand.",
      },
      allInPlayer: {
        heading: "ALL-In Player",
        desc: "If players bet on all chips, they've all made all ins and the hand eventually ends with a revealing card. Aggressive players must show their down cards regardless of the outcome of the hand.",
      },
      desc: "If one or more players play all-in and no further bets have been placed, all other players must show their down cards. Otherwise, players who continue to raise and generate the pot must show their cards to the aggressive player and the winner will be determined by the rules.",
      blackJackResult: {
        heading: "Winning",
        desc: "If the total value of your hand exceeds 21, you burn and you will lose your bet on that hand. After all users' turn has passed, the croupier shows the value of his down card. If the croupier's hand is 16 or less, the croupier should choose the Hit card option, but if that hand is a soft 17 or higher, the croupier must stop by choosing the Stand option (note that the soft hand contains an Ase value of 11).In the end, you win when the value of your hand is closer to 21 than the croupier's hand, or when the croupier burns. If your hand is of the same value as the croupier's, the game round ends in a draw and your bet is refunded. Play with Side Bet options, win up to '100x'.",
      },
    },
  },

  updateScene: {
    downloading: "New version downloading...",
    installing: "Installing...",
    installedVersion: "New Version is installed",
    currentVersion: "Current Version",
    newVersion: "New Version", //NEW************
  },

  actionTypes: {
    call: "Call",
    check: "Check",
    fold: "Fold",
    raise: "Raise",
    allin: "All-in",
  },

  tableQuickMenu: {
    standUp: "Stand Up",
    goToLobby: "Go To Lobby",
    soundOn: "Sound On",
    soundOff: "Sound Off",
    vibrationOn: "Vibration On",
    vibrationOff: "Vibration Off",
    animatedGiftsOn: "Emotes",
    animatedGiftsOff: "Emotes",
    watchWin: "Watch Win",
  },

  actionPanel: {
    check: "Check",
    call: "Call",
    fold: "Fold",
    raise: "Raise",
    allin: "All-In",
    callAny: "Call Any",
    checkFold: "Check/Fold",
  },

  selectPotWindow: {
    autoPurchase: "Auto Fill-Out",
    autoPurchaseDescription:
      "Automatically fill out the chips when you bust-out.",
    autoCharging: "Auto Re-Charge",
    autoChargingDescription:
      "Automatically re-charge when your stack is less than 70%.",
    sit: "Sit",
    minEntry: "Min Entry",
    maxEntry: "Max Entry",
    transfer: "Transfer",
    setQuantity: "SET YOUR STACK",
  },

  quickWindow: {
    quickChat: "Quick chat.",
    readyMessage: "Ready Messages",
    messageAim: "Fast way to chat with the user.",
    quickEmoji: "Quick Emoji",
  },

  tipButton: {
    thankYou: "Thank you",
  },

  fastChatMessages: [
    "Good luck!",
    "Congrats!",
    "Thanks",
    "Bluff!",
    "Please, play faster!",
    "Newbie!",
    "Good game, bravo!",
    "What luck!",
    "Take it easy champ!",
  ],

  chatWindow: {
    tabs: {
      chat: "Chat",
      dealer: "Dealer",
      mute: "Mute",
    },
    audiences: "Audiences",
    players: "Players",
    typeHere: "Type here...",
    xSecondsLeft: "{seconds} seconds left.",
  },

  dealerChat: {
    call: "{username} called",
    check: "{username} checked",
    raise: "{username} raised {amount}",
    fold: "{username} folded",
    allin: "{username} is all-in",
    win: "{username} {amount} won",
    winWithCards: "{hand} {username},with {amount} won",
    winWithCardIcons: "{username}, {cards}({hand}) with {amount} won",
  },

  systemMessages: {
    roomClosing:
      "The server will reboot in 2 minutes. The last hands are played.",
    teamTableAlert:
      "This is a team table! Multiple accounts can sit and raise. There is no restriction (ban).",
    giftSentChatMessage: "{from} sent a {gift_name} gift to {to}.",
    inviteRequestSuccess: "Invitation sent successfully.",
  },
  blackJack: {
    hit: "Hit",
    stand: "Stand",
    InsufficientChips: "Insufficient Chips",
    undo: "UNDO",
    double: "DOUBLE",
    repeat: "REPEAT",
    xSecondsLeft: "{seconds} seconds left.",
    gameHistory: "Game History",
    croupier: "Croupier",
    you: "You",
    main: "Main",
    anyPair: "Any Pair",
    result: "Result",
    previousGame: "Previous Game",
    returnToList: "Return To List",
    nextGame: "Next Game",
    type: "Type",
    pay: "Pay",
    winningsHands: "Winning Hands",
    rules: "Rules",
    blackjactSitInfo:
      "The game is played against the dealer. Unlimited amount of players can play against the dealer.",
    bjRules: `
          * The purpose of Turn Blackjack is to have a hand with a higher points than the croupier without exceeding 21; <br>
          * The best hand is called Blackjack - the total value of the first two cards dealt in this hand is 21;<br>
          * You play just against the croupier, not against other players;<br>
          * Blackjack is played with eight decks;<br>
          * The croupier always stops at Soft 17;<br>
          * Can be doubled on any two starter cards;<br>
          * The starting cards of the same value can be divided into two;<br>
          * Split can be done by each hand;<br>
          * One card is dealt for each double Ace split;<br>
          * Double cannot be done after the splitting the cards;<br>
          * Insurance is recommended when the croupier has an Ace;<br>
          * Blackjack pays 3:2;<br>
          * Blackjack Bonus pays 3:2 at the end of the hand;<br>
          * Insurance pays 1:2;<br>
          * When the hands are equal, it's a draw.<br>
 
            <hr>
 
            <strong>Game Rules</strong><br>
 
            * The game is played against the dealer. Unlimited amount of players can play against the dealer.<br>
            * The game is presented by a croupier and is played with eight standard decks of 52 cards. The 21 card values in-game are as follows:<br>
            - Cards from 2 to 10 have the values written on them.<br>
            - Picture cards (Jacks, Queens and Kings) are worth 10 points.<br>
            - Aces are worth 1 or 11, the player can choose the appropriate value according to his hand. Note that the soft hand contains 11 value Aces;<br>
            * When the betting time has expired, the croupier hands out a face-up card to all players, the last to himself. After that, the croupier deals a second card face-up to all players, but the croupier's second card will be face down. The value of your first hand is shown next to your cards.<br>
            * Blackjack (Twenty-One) = If the two cards you were dealt at the beginning of the game add up to 21, you have Blackjack!<br>
            <br>
            <strong>Insurance</strong>
            <br>
            * If the croupier's face-up card is an Ace, you can buy insurance against the risk of the croupier having 21, even if your total hand is 21. The insurance amount will be equal to half of your main bet and the insurance bet will be processed separately from your hand bet. After that, the croupier looks at the value of his down card to check if he made a 21.<br>
            - If the croupier does not make 21, the round continues. <br>
            - If the croupier has Blackjack and you don't, the game is over and the croupier wins.<br>
            - If both you and the croupier have 21, the game will end in a draw and your bet will be refunded.<br>
            <br>
            Note that if the croupier's face-up card is a 10 or a color card, you will have the option to buy the insurance and the croupier will not look at his down card for 21.
            <br>
            <strong>Double, Hit or Stand</strong>
            <br>
            If the croupier finds that after checking his first two cards he has not made a 21, the players are given the right to choose more cards to improve the value of their hand, by giving the croupier more cards to the players.
            <br>
            If your first hand is not worth 21, you have a right to double the bet. In this case, your bet will be doubled and you will be given only one additional card to add to your hand. Alternatively, you can use the Hit card option to get additional cards to add to the value of your hand. You can Hit multiple cards until you are satisfied with the value of your hand and decide to Stand.
            <br>
            Note that a player with a soft hand equal to 21 will not be given a decision option.
            <br>
 
            <strong>Split In Two</strong><br>
            <br>
            If your first-hand consists of two cards of the same rank, you can split the pair to two different hands, where each one has a bet equal to your main bet. After both hands are dealt with a second card, you can increase the value of these two hands by applying the Hit card option. Here, you can choose the Stand option when you are satisfied with the value of both hands. However, when you split the first pair of Ase, both hands will automatically receive an additional card and will not have a chance to get another card.
            <br>
            <strong>Result</strong>
            <br>
            If the total value of your hand exceeds 21, you burn and you will lose your bet on that hand.
            <br>
            After all users' turn has passed, the croupier shows the value of his down card. If the croupier's hand is 16 or less, the croupier should choose the Hit card option, but if that hand is a soft 17 or higher, the croupier must stop by choosing the Stand option (note that the soft hand contains an Ase value of 11).
            <br>
            In the end, you win when the value of your hand is closer to 21 than the croupier's hand, or when the croupier burns. If your hand is of the same value as the croupier's, the game round ends in a draw and your bet is refunded.
            <br>Play with Side Bet options, win up to "100x".`,
    settings: "Settings",
    sounds: "Sounds",
    bet: "BET",
    waitOtherPlayers: "Please wait for other players",
    placeYourBet: "Place your bet",
    choose: "Choose",
    wantInsurance: "Need insurance?",
    draw: "DRAW",
    busted: "BUSTED",
    youLost: "You Lost",
    sit: "SIT",
    header: "Input the chips from the Main Case",
    otoPurchase: "Auto Fill-Out",
    otoPurchaseText: "If you re-login your stack will be reset",
    okay: "OKAY",
    insufficientBalance: "Insufficient Balance",
    ShufflingCards: "Shuffling the deck of cards...",
    youWon: "You Won",
    won: "WON",
    dealNow: "Deal Now",
  },
  useInviteCode: {
    heading: "Invite Code",
    description: "Please enter your friends’ invite code.",
    unset: "Remove Invite Code",
    inviteCodeNotFound: "Wrong invite code.",
  },
  progressiveJackpot: {
    join: "Join",
    desc1: "You pay chips equal to the small blind per hand.",
    desc2:
      "When you win, you will receive an equal to SMALL BLIND x COEFFICIENT.",
    desc3: "Collected chips from the same bet level.",
  },
  pinCode: {
    wrong: "Pin-code is wrong!",
    pinCode: "Pin-code",
    removePinCode: "Remove pin-code", //NEW************
    enterPin: "Please enter your pin-code",
    reset: "Reset my password!",
    logOut: "LOG OUT",
    resetPin: "RESET PIN-CODE",
    enterEmail: "Please enter your email address",
    mail: "Email",
    newPass: "New password has been sent to your email",
    wrongMail: "the email is wrong",
    failRemove: "Failed to remove pin-code",
    failAdd: "Failed to save pin-code",
  },
  redeem: {
    used: "Game Card already used!",
    expired: "Game Card expired!",
    failed: "Failed to validate Game Card.",
    validGameCard: "Please enter a valid game card", // NEW*********
  },
  level: {
    levelUpToastText:
      "Congratulations! You have reached level {levelNumber}. <strong>Reward: {reward}</strong>",
  },
  inviteFriend: {
    inviteFriendText: "You have invited your friend and both of you got 10B!",
  },
  slotWinnerToast: {
    bigWinText: "{username} won {amount} on slot!",
  },
  toast: {
    inviteRequestText: "{name} invites you to play {game}.",
  },
  updatedNotes: {
    title: "Update Notes",
    notes: [
      "<div class='center'><span class='title'>Poker</span></div>",
      "Background Sound is off by default settings",
      "50 Players on the Leaders list",
      "Access ‘Search’ and ‘Hand History’ features on the table",
      "Only players who play at the table can write in-game chat",
      "Awesome Transparent game Chat is on the left side",
      "Take an action (Call/Fold/Check/Raise) while Chat is opened;",
      "Cool countdown timer",
      "<div class='center'><span class='title'>Blackjack</span></div>",
      "Cozy enlarged cards and text",
      "Bug Fix",
    ],
  },
  forceUpdateWindow: {
    title: "A new version is available!",
    text: "Please update the app to the latest version.",
  },
  table: {
    noHaveOnlineFriends: "No online friends",
    onlineFriends: "Online Friends",
    noHaveFriends: "You don't have any friends",
  },
  profileSettings: {
    profileSettings: " Profile Settings ",
    registrationDate: "Registration Date",
    use: "USE",
    gift: "GIFT",
    buyVip: "Buy VIP",
    onlyVip: " Only VIP players can purchase this gift.",
    frame: "Frame",
    buy: "BUY",
    statistics: {
      statistics: "Statistics",
      handPlayed: "Spin counter",
      biggestPotWon: "Biggest Pot",
      biggestJackPotWon: "Biggest JackPot",
      recordChip: "Chip Record",
      brokenPotCounter: "Won Pot Counter",
      winPercentage: "Win Percentage",
      leadershipRanking: "Ranking",
      bestWinningHand: "Best Winning Hand",
      biggestWin: "Biggest Win",
    },
  },
  friends: {
    online: "ONLINE",
    goToTable: "GO TO TABLE",
    chipTransfer: "Chip Transfer",
    friendRequests: "Friend Requests",
    incoming: "INCOMING",
    sent: "SENT",
    search: "SEARCH",
    addButtonText: "Add Friend",
    removeFriendNotification: "Removed from friend list",
    userProfile: "User Profile",
    sendChipText: "Send Chips",
    deletefriends: "Delete Friend",
  },
  settings: {
    lobbyMusic: "Lobby Music",
    soundEffects: "Sound FX",
    vibration: "Vibrate",
    blockInvites: "Invitations",
    giftHide: "Gift",
    handStrengthIndicator: "Hand Strength Meter",
    graphicsPreferences: "Graphics Preferences",
    tableColor: "Table Color",
    roomBgColor: "Background",
    languagePreference: "Language",
    termsOfUse: "Terms Of Use",
    accountInfo: "Account Info",
    profileID: "Player ID",
    deviceNO: "Device",
    deleteAccount: "Delete Account",
    language: {
      turkish: "Turkish",
      english: "English",
      italian: "Italian",
      indonesian: "Indonesian",
      portuguese: "Portuguese",
      russian: "Russian",
      malesian: "Malesian",
    },
  },
  vip: {
    available: "VIP / {day} Days",
    noVip: "VIP is not available",
  },
  notification: {
    noData: "You have no notification.",
  },
  userProfile: {
    noteUpdated: "Your profile note has been updated",
  },
  others: {
    friendRemovedInfo: "Friend was removed",
    notFoundFriend: "Friend is not found",
    notFoundDisplay: "User is not found",
    friendTableNotFound: "The user has left the table",
    tableInFriendNotFound: "No player on the table",
    requiredSelectedGift: "Select Gift",
    currentLocationNotAvailableBuy:
      "Purchasing is unavailable for your country",
    requiredLevelFive: "Level 5 is required",
    blackjackStatusInactive: "Blackjack is not available",
    slotStatusInactive: "Slot is not avalable",
    muteAll: "Mute",
    chipSendAreYouSure:
      "<p><span class='yellow'>{0}</span> Id numaralı <span class='yellow'>{1}</span> adlı kullanıcıya fiş göndermek istediğinize emin misiniz? </p>",
    changeNameSuccesInfo: "Username changed You have {0} left",
    guestClose:
      "You cannot log in with guest. Please log in with another account",
  },
};
