<template>
  <BaseWindow
    @closeWindow="closeWindow"
    :show="true"
    :loading="loading"
    :stopClickOutside="true"
    :titleText="$t('general.chat')"
  >
    <template>
      <div class="chat-window">
        <div class="head-selectors">
          <div
            class="selector direct-selector"
            @click="handleChatTabs('direct')"
            :class="selectedTab === 'direct' ? 'profile-sprite tab1' : ''"
          >
            <span
              :class="selectedTab === 'direct' ? 'text-active' : ''"
              @click="handleChatTabs('direct')"
              >{{ $t("chat.directChat") }}</span
            >
            <span
              class="tab-line"
              :class="selectedTab === 'direct' ? 'profile-sprite tabline' : ''"
            ></span>
          </div>
          <div
            class="selector world-selector"
            @click="handleChatTabs('world')"
            :class="selectedTab === 'world' ? 'profile-sprite tab2' : ''"
          >
            <span
              :class="selectedTab === 'world' ? 'text-active' : ''"
              @click="handleChatTabs('world')"
              >{{ $t("chat.worldChat") }}</span
            >
            <span
              class="tab-line"
              :class="selectedTab === 'world' ? 'profile-sprite tabline' : ''"
            ></span>
          </div>
        </div>
        <div class="chat-content">
          <div class="sections-world" v-if="selectedTab === 'world'">
            <div class="chat-container global" ref="global">
              <div
                class="chat-row"
                v-for="(item, index) in getGlobalMessages"
                :key="index"
                :class="item.sender.id === user.id ? 'me' : ''"
              >
                <div class="chat-user-avatar">
                  <Avatar
                    :url="item.sender.avatar"
                    :crown="item.sender.crown"
                    size="100"
                    @click.native="
                      item.sender.id !== $store.state.user.id
                        ? openUserWindow(item.sender.id)
                        : ''
                    "
                  />
                  <div class="chat-username">{{ item.sender.name }}</div>
                </div>
                <div class="chat-info">
                  <div class="chat-bubble-text selectable-text">
                    {{ item.message }}
                  </div>
                  <div class="chat-message-date">
                    {{ getFormattedDate(item.time) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sections-direct" v-if="selectedTab === 'direct'">
            <div class="direct-messages">
              <div
                class="no-message"
                v-if="getUserConversations.length === 0"
              ></div>
              <input
                v-else
                type="text"
                v-model="searchText"
                class="search-input"
                :placeholder="$t('affiliate.searchBy')"
              />
              <DirectMessage
                v-for="(conversation, index) in innerGetUserConversations"
                :key="index"
                :conversation="conversation"
                :selected="
                  selectedConversation.id === conversation.id ? true : false
                "
                @click.native="selectConversation(conversation)"
              />
            </div>

            <div class="chat-container direct" ref="direct">
              <button
                class="ui-button ui-button-yellow show-more"
                @click="showMoreMessages"
                v-if="
                  getSelectedConversation.total > 25 &&
                  (offSet + 1) * 25 < getSelectedConversation.total
                "
              >
                {{ $t("chat.showMore") }}
              </button>
              <div
                class="chat-row"
                :class="message.sender.id === user.id ? 'me' : ''"
                v-for="(message, index) in getSelectedConversation.messages"
                :key="index"
              >
                <div class="chat-user-avatar">
                  <Avatar
                    :url="message.sender.avatar"
                    :crown="message.sender.crown"
                    size="120"
                    @click.native="
                      message.sender.id !== $store.state.user.id
                        ? openUserWindow(message.sender.id)
                        : ''
                    "
                  />
                  <div class="chat-username">{{ message.sender.name }}</div>
                </div>
                <div class="chat-info">
                  <div class="chat-bubble-text selectable-text">
                    {{ message.message }}
                  </div>
                  <div class="chat-message-date">
                    {{ getFormattedDate(message.serverTime) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="message-process" v-if="selectedTab === 'world'">
            <div class="lock" v-show="!user.vip.status">
              <p>{{ $t("chat.onlyVip") }}</p>
              <div @click="goToShop" class="ui-button ui-button-small-orange">
                {{ $t("caseWindow.buyVip") }}
              </div>
            </div>
            <input
              type="text"
              v-model.trim="globalMessage"
              maxlength="255"
              @keyup.enter="sendGlobalMessage"
            />
            <emoji-picker
              ref="emojiPicker"
              @emoji="onEmojiClickedWorld"
              class="emojis-outer no-click-outside"
            >
              <div
                slot="emoji-invoker"
                slot-scope="{ events: { click: clickEvent } }"
                @click.stop="clickEvent"
              >
                <button type="button" class="chat-button">
                  <span class="emoji"></span>
                </button>
              </div>
              <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                <div class="emojis-container no-click-outside">
                  <div
                    v-for="(emojiGroup, category) in emojis"
                    :key="category"
                    class="emoji-category"
                  >
                    <div class="category-heading">{{ category }}</div>
                    <div class="category-emojis">
                      <span
                        v-for="(emoji, emojiName) in emojiGroup"
                        :key="emojiName"
                        @click="insert(emoji)"
                        :title="emojiName"
                        >{{ emoji }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </emoji-picker>

            <div
              class="ui-button ui-button-small-orange send-button"
              @click="sendGlobalMessage"
            >
              {{ $t("general.send") }}
            </div>
          </div>
          <div class="message-process" v-if="selectedTab === 'direct'">
            <!-- <div class="lock" v-show="getUserConversations.length <= 0">
              <p>{{ $t("chat.onlyVip") }}</p>
              <div @click="goToShop" class="ui-button ui-button-small-orange">
                {{ $t("caseWindow.buyVip") }}
              </div>
            </div> -->
            <input
              type="text"
              v-model="directMessage"
              @keyup.enter="sendDirectMessage"
              maxlength="255"
              v-if="this.showChatInput === true"
            />
            <emoji-picker
              ref="emojiPicker"
              @emoji="onEmojiClicked"
              class="emojis-outer no-click-outside"
              v-if="this.showChatInput === true"
            >
              <div
                slot="emoji-invoker"
                slot-scope="{ events: { click: clickEvent } }"
                @click.stop="clickEvent"
              >
                <button type="button" class="chat-button">
                  <span class="emoji"></span>
                </button>
              </div>
              <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                <div class="emojis-container no-click-outside">
                  <div
                    v-for="(emojiGroup, category) in emojis"
                    :key="category"
                    class="emoji-category"
                  >
                    <div class="category-heading">{{ category }}</div>
                    <div class="category-emojis">
                      <span
                        v-for="(emoji, emojiName) in emojiGroup"
                        :key="emojiName"
                        @click="insert(emoji)"
                        :title="emojiName"
                        >{{ emoji }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </emoji-picker>
            <button
              class="ui-button ui-button-red delete-messages-button"
              type="button"
              @click="deleteConversation"
              v-if="selectedConversation.id"
            ></button>
            <div
              class="ui-button ui-button-small-orange send-button"
              @click="sendDirectMessage"
              v-if="this.showChatInput === true"
            >
              {{ $t("general.send") }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import EmojiPicker from "vue-emoji-picker";

import BaseWindow from "./BaseWindow";
import DirectMessage from "./ChatComponents/DirectMessage";
import Avatar from "@/components/Misc/Avatar";

export default {
  name: "ChatWindow",
  data() {
    return {
      searchText: null,
      selectedTab: "direct",
      globalMessage: "",
      directMessage: "",
      selectedConversation: {},
      loading: false,
      showChatInput: false,
      offSet: 0,
      items: [],
    };
  },
  watch: {
    getGlobalMessages(newVal) {
      this.scrollToBottom();
    },
    getSelectedConversation(newVal) {
      this.scrollToBottomDirect();
    },
    "$store.state.modals.chat": {
      handler(val) {
        this.chatWindowInitialize();
      },
    },
  },
  components: {
    BaseWindow,
    DirectMessage,
    Avatar,
    EmojiPicker,
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters("chat", [
      "getGlobalMessages",
      "getUserConversations",
      "getSelectedConversation",
    ]),
    innerGetUserConversations() {
      if (this.searchText && this.searchText.length > 0) {
        let conversations = [];
        if (!this.isNumeric(this.searchText)) {
          conversations.push(
            ...this.getUserConversations.filter((message) =>
              message.user.name
                .toLowerCase()
                .includes(this.searchText.toLowerCase())
            )
          );
          //yazı
          return conversations;
        } else {
          conversations.push(
            ...this.getUserConversations.filter(
              (message) => (message.user.id + "").indexOf(this.searchText) > -1
            )
          );
          //numara
          return conversations;
        }
      }
      return this.getUserConversations;
    },

    getFormattedDate() {
      return (value) => {
        return this.$plugins.moment.unix(value).format("YYYY/MM/DD HH:mm");
      };
    },
  },
  methods: {
    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    },
    async onShowChatWindow() {
      this.$data.loading = true;
      await this.$store.dispatch("chat/getUserConversations");
      this.$data.loading = false;
    },
    closeWindow() {
      this.$store.commit("modals/hide", "chat");
    },
    handleChatTabs(tab) {
      this.$data.selectedTab = tab;
    },
    goToShop() {
      this.closeWindow();
      this.$store.state.modals.isVipRedirected = true;
      this.$store.commit("modals/show", "shop");
    },
    onEmojiClicked(emoji) {
      this.directMessage += " " + emoji;
      this.$refs.emojiPicker.$data.display.visible = false;
    },
    onEmojiClickedWorld(emoji) {
      this.globalMessage += " " + emoji;
      this.$refs.emojiPicker.$data.display.visible = false;
    },
    scrollToBottom() {
      if (!this.$refs.global) {
        return;
      }
      const height = this.$refs.global.scrollHeight;
      const chatScrollingBox = this.$refs.global;
      chatScrollingBox.scrollTop = height;
    },
    scrollToBottomDirect() {
      if (!this.$refs.direct) {
        return;
      }
      const height = this.$refs.direct.scrollHeight;
      const chatScrollingBox = this.$refs.direct;
      chatScrollingBox.scrollTop = height;
    },
    async openUserWindow(id) {
      this.loading = true;
      await this.$store.dispatch("modals/openUserDetailWindow", id);
      this.closeWindow();
      this.loading = false;
    },
    async sendGlobalMessage() {
      if (this.$data.globalMessage !== "") {
        const response = this.$store.dispatch(
          "chat/sendGlobalMessage",
          this.$data.globalMessage
        );
        if (response) this.$data.globalMessage = "";
      }
    },
    async selectConversation(conversation) {
      this.showChatInput = true;

      this.$data.offSet = 0;
      this.$data.selectedConversation = conversation;
      this.$store.commit("chat/setSelectedChat", conversation);
      await this.$store.dispatch("chat/getUserConversation", {
        conversationId: conversation.id,
        offSet: this.$data.offSet,
      });
      if (conversation.unread) {
        this.$store.state.chat.playerConversations.find(
          (item) => item.id === conversation.id
        ).unread = false;
      }
      this.scrollToBottomDirect();
    },
    async sendDirectMessage() {
      if (this.$data.directMessage !== "") {
        await this.$store.dispatch("chat/sendDirectMessage", {
          receiverId: this.$data.selectedConversation.user.id,
          message: this.$data.directMessage,
        });
        this.$data.directMessage = "";
        await this.selectConversation(this.$data.selectedConversation);
      }
    },
    async showMoreMessages() {
      this.$data.offSet++;
      await this.$store.dispatch("chat/getUserConversation", {
        conversationId: this.$data.selectedConversation.id,
        offSet: this.$data.offSet,
      });
    },
    async deleteConversation() {
      await this.$store.dispatch("chat/deleteConversation", {
        conversationId: this.$data.selectedConversation.id,
      });
      this.selectedConversation = {};
      this.$set(this.$store.state.chat, "selectedConversation", {});
      this.onShowChatWindow();
    },
    chatWindowInitialize() {
      this.onShowChatWindow();
      this.$store.state.chat.selectedConversation = {
        messages: [],
        total: 0,
      };
      const check = this.getSelectedConversation != {} ? false : true;
      if (check != false && this.getSelectedConversation.messages.length > 0) {
        this.showChatInput = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-window {
  width: 100%;
  height: 87%;
  position: relative;
  .head-selectors {
    width: 770px;
    height: 100px;
    position: absolute;
    left: 10px;
    margin: auto;
    top: 0px;
    border-radius: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 4;
    .selector {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 250px;
      height: 99px;
      cursor: pointer;
      color: #cf707f;
      font-size: 35px;
      line-height: 40px;
      font-weight: bold;
      text-transform: uppercase;
      text-shadow: 3px 3px 3px black;
      flex-wrap: wrap;
      text-align: center;
      &:nth-child(2) {
        padding-left: 18px;
      }
      .text-active {
        color: #fff;
      }
      .tab-line {
        position: absolute;
        bottom: 0;
        left: 5px;
        transform: scale(0.85);
      }
    }
  }
  .chat-content {
    position: absolute;
    width: 99%;
    height: 87%;
    top: 100px;
    left: 10px;
    background: #83212d;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    .sections-world {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }

    .chat-container {
      position: absolute;
      width: 95%;
      height: 82%;
      background: #3b0b14;
      border-radius: 15px;
      right: 0;
      left: 0;
      top: 30px;
      margin: auto;
      overflow-x: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      &.direct {
        width: 74%;
        left: unset;
        right: 50px;
        .chat-row {
          .chat-info {
            .chat-message-date {
              left: 20px;
              top: -20px;
            }
          }
          .chat-user-avatar {
            margin-right: 30px;
          }
        }
        .show-more {
          position: absolute;
          left: 0;
          right: 0;
          margin: 0px auto;
          background: transparent;
          border: none;
          box-shadow: none;
          &::before {
            display: none;
          }
        }
      }
      .chat-row {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        padding: 35px;
        .chat-user-avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .chat-username {
            margin-top: 10px;
            font-weight: bold;
            font-size: 26px;
          }
        }
        .chat-info {
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          max-width: 70%;
          min-width: 30%;
          .chat-username {
            width: 100%;
            font-size: 32px;
          }
          .chat-bubble-text {
            position: relative;
            padding: 15px;
            width: 100%;
            min-height: 70px;
            max-width: 100%;
            line-height: 40px;
            word-break: break-all;
            box-sizing: border-box;
            background-color: #cf707f;
            color: black;
            margin-top: 10px;
            border-radius: 50px;
            font-size: 32px;
            &::after {
              content: "";
              position: absolute;
              top: 40%;
              left: -6px;
              background-color: #cf707f;
              border: 10px solid #cf707f;
              transform: rotate(45deg);
            }
          }
          .chat-message-date {
            position: absolute;
            left: 10px;
            top: -20px;
            font-size: 23px;
            color: rgba(rgb(255, 255, 255), 0.5);
          }
        }

        &.me {
          flex-direction: row-reverse;
          .chat-user-avatar {
            margin-left: 30px;
            margin-right: 0;
          }
          .chat-info {
            align-items: center;
            .chat-username {
              text-align: right;
            }
            .chat-bubble-text {
              background-color: #fcc6cf;

              &::after {
                left: auto;
                right: -4px;
                background-color: #fcc6cf;
                border: 10px solid #fcc6cf;
              }
            }
            .chat-message-date {
              left: auto;
              right: 10px;
            }
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    bottom: 30px;
    z-index: 2;
    width: 98%;
    left: 20px;
    .message-process {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .send-button {
        text-transform: uppercase;
        margin-right: 30px;
      }

      .lock {
        position: absolute;
        width: 98%;
        height: 110%;
        left: 20px;
        background: rgba(black, 1);
        z-index: 99;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ui-button {
          margin-left: 20px;
        }
        p {
          font-size: 35px;
          font-weight: bold;
          position: relative;

          color: #fff;
        }
        button {
          text-transform: uppercase;
          position: relative;
        }
      }
      input {
        position: relative;
        margin-left: 30px;
        width: 1300px;
        height: 10px;
        font-size: 36px;
        font-weight: bold;
        border-radius: 50px;
        background: #fff;
        border: none;
        color: rgba(black, 0.8);
        display: flex;
        box-shadow: inset 1px 3px 8px black;
        align-items: center;
        justify-content: center;
        padding: 30px 30px;
        padding-right: 80px;
        &::placeholder {
          font-size: 30px;
          color: rgba(black, 0.7);
          font-weight: normal;
          padding: 30px 30px;
          margin-bottom: 30px;
        }
      }
      .emoji {
        position: absolute;
        left: 1350px;
        top: -26px;
        background-image: url("~@/assets/images/lobby/emoji.png");
        width: 65px;
        height: 65px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        &:active {
          transform: scale(1.1);
          cursor: pointer;
        }
      }
      .delete-messages-button {
        position: relative;
        left: 50px;
        width: 86px;
        height: 86px;
        background: url("~@/assets/images/lobby/message-delete-button.png")
          no-repeat 50% 50%;
        background-size: contain;
      }
    }
  }
}
.sections-direct {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  .direct-messages {
    top: 30px;
    left: 20px;
    position: relative;
    width: 20%;
    height: 82%;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    background-color: #3b0b14;
    border-radius: 15px;
    &::-webkit-scrollbar {
      display: none;
    }
    .no-message {
      font-size: 35px;
      position: absolute;
      left: 30px;
      top: 250px;
    }
    .search-input {
      outline: 0;
      width: 300px;
      height: 70px;
      font-size: 30px;
      padding: 5px;
      box-sizing: border-box;
      color: rgba(rgb(255, 255, 255), 0.7);
      border-radius: 15px;
      text-indent: 5px;
      -moz-appearance: textfield;
      position: relative;
      left: 42px;
      margin-bottom: 10px;
      margin-top: 10px;

      border-radius: 35px;

      font-size: 34px;
      background: #55121b;
      box-shadow: inset 1px 3px 8px black;
      color: #ffffff;
      &:focus,
      &:active {
        border: 0;
      }
    }
    .search-input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(rgb(255, 255, 255), 0.7);
      opacity: 1; /* Firefox */
    }
    .search-input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(black, 0.7);
    }
    .search-input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(black, 0.7);
    }
  }
}
.emojis-outer {
  position: absolute;
  width: 180px;
  div {
    button {
      background: transparent;
    }
  }
  .emojis-container {
    position: absolute;
    left: 1000px;
    bottom: 45px;
    width: 520px;
    height: 380px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #83212d;
    z-index: 3;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px rgba(rgb(255, 255, 255), 0.1);
    .emoji-category {
      margin-bottom: 10px;
      .category-heading {
        height: 1px;
        overflow: hidden;
        background-color: rgba(rgb(255, 255, 255), 0.1);
      }
      .category-emojis {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        span {
          display: block;
          padding: 5px;
          font-size: 60px;
          border-radius: 5px;
          cursor: pointer;
          transition: all 0.25s ease;
          &:hover {
            background-color: rgba(rgb(255, 255, 255), 0.1);
          }
        }
      }
      &:first-child {
        .category-heading {
          display: none;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
