import packageJson from "@/../package.json";
import { Capacitor } from "@capacitor/core";
import { BannerAdSize, BannerAdPosition } from "@capacitor-community/admob";

const safeAreaInsetLeft = String(
  getComputedStyle(document.documentElement).getPropertyValue("--sal")
).replaceAll("px", "");
const safeAreaInsetRight = String(
  getComputedStyle(document.documentElement).getPropertyValue("--sar")
).replaceAll("px", "");
const safeAreaInsetTop = String(
  getComputedStyle(document.documentElement).getPropertyValue("--sat")
).replaceAll("px", "");
const safeAreaInsetBottom = String(
  getComputedStyle(document.documentElement).getPropertyValue("--sab")
).replaceAll("px", "");

const APP_MODE = process.env.NODE_ENV || "development";

export default {
  mode: APP_MODE,
  apiUrl: process.env.VUE_APP_API_URL || "localhost",
  applicationVersion: packageJson.version,
  defaultAvatarUrl: `${process.env.VUE_APP_AVATAR_URL}8.png`,
  game: {
    width: 2560,
    height: 1440,
    safe: {
      width: 1920,
      height: 1080,
    },
  },
  window: {
    safeAreaInsetLeft: Number(safeAreaInsetLeft),
    safeAreaInsetRight: Number(safeAreaInsetRight),
    safeAreaInsetTop: Number(safeAreaInsetTop),
    safeAreaInsetBottom: Number(safeAreaInsetBottom),
  },
  plugins: [
    "moment",
    "img-preloader",
    "old-browser-supporter",
    "storage-manager",
    "splash-screen",
    "in-app-purchase",
    "haptics",
    "audio",
    "keep-awake",
    "android-immersive-mode",
    "device",
    "network-manager",
    "clipboard",
    "toast",
    "invite-toast",
    "unzipper",
  ],
  externalLibrary: {
    APPLE: {
      clientId: process.env.VUE_APP_APPLE_CLIENT_ID || "com.turngs.auth",
      redirectURI: process.env.APPLE_REDIRECT_URI || "https://001turngs.com",
    },
    GOOGLE: {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID || "-",
    },
    ADMOB: {
      mode: APP_MODE === "development" ? "testing" : "production",
      testingDevices: ["fc785da2172145bf0a5405cda96df9e4"],
      bannerOptions: {
        adId:
          (Capacitor.getPlatform() === "ios"
            ? process.env.VUE_APP_ADMOB_IOS_REWARD_AD_ID
            : process.env.VUE_APP_ADMOB_ANDROID_REWARD_AD_ID) || "",
        adSize: BannerAdSize.FULL_BANNER,
        position: BannerAdPosition.CENTER,
        margin: 0,
        isTesting: false,
      },
      rewardAdOptions: {
        adId:
          (Capacitor.getPlatform() === "ios"
            ? process.env.VUE_APP_ADMOB_IOS_REWARD_AD_ID
            : process.env.VUE_APP_ADMOB_ANDROID_REWARD_AD_ID) || "",
        ssv: {
          userId: 0,
        },
      },
    },
    analytics: {
      providers: {
        firebase: {
          apiKey: "AIzaSyBIInvn22bDjUh6NPRKNHQOFnrDkS-DNJg",
          authDomain: "turn-holdem-poker.firebaseapp.com",
          databaseURL: "https://turn-holdem-poker.firebaseio.com",
          projectId: "turn-holdem-poker",
          storageBucket: "turn-holdem-poker.appspot.com",
          messagingSenderId: "68783598739",
          appId: "1:68783598739:web:9d3f08a8c3a651bbfcb166",
          measurementId: "G-PT5ZY7XFMT",
        },
      },
    },
    logRocketIdentifier: process.env.VUE_APP_LOG_ROCKET_IDENTIFIER || "",
  },
  storageKeys: {
    tokens: "tokens",
    authType: "authType",
    reconnect: "reconnectRoomId",
    slots: {
      turnLegends: "slot_turnLegendsDownloaded",
      bambooPanda: "slot_bambooPandaDownloaded",
      fafafa: "slot_fafafaDownloaded",
      olympus: "slot_olympusDownloaded",
    },
  },
  gameType: {
    LOBBY: "LOBBY",
    TEXAS: "TEXAS",
    OMAHA: "OMAHA",
    BLACKJACK: "BLACKJACK",
    TURN_LEGENDS: "TURN_LEGENDS",
    BAMBOO_PANDA: "BAMBOO_PANDA",
    FAFAFA: "FAFAFA",
    OLYMPUS: "OLYMPUS",
  },
};
