import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";

const FriendInviteToastManager = {
  show(options) {
    EventBus.$emit(EventConstants.FRIEND_INVITE_TOAST_REQUESTED, options);
  },
};

export default {
  instance: FriendInviteToastManager,

  install(Vue) {
    Vue.prototype.$plugins.friendInviteToast = FriendInviteToastManager;
  },
};
