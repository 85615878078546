<template>
  <div v-if="$props.actionCode > 0">
    <span class="action-tooltip-text"> {{ getActionCodeText }}</span>
  </div>
</template>

<script>
export default {
  name: "ActionTooltip",
  props: {
    actionCode: {
      type: Number,
      required: true,
      default: -1,
    },
    me: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    "$props.actionCode": function (actionCode) {
      const actionSounds = {
        1: "Call",
        2: "Check",
        3: "Raise",
        4: "Fold",
        5: "Allin",
      };
      if (typeof actionSounds[actionCode] === "undefined") return false;
      this.$plugins.audio.play(actionSounds[actionCode]);
    },
  },
  computed: {
    getActionCodeText() {
      const actions = {
        0: "",
        1: this.$t(`actionTypes.call`),
        2: this.$t(`actionTypes.check`),
        3: this.$t(`actionTypes.raise`),
        4: this.$t(`actionTypes.fold`),
        5: this.$t(`actionTypes.allin`),
      };

      if (typeof actions[this.$props.actionCode] === "undefined") return "";

      return actions[this.$props.actionCode];
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes actionTooltipAnimation {
  0% {
    transform: skew(30deg);
  }
  100% {
    transform: skew(0deg);
  }
}

.action-tooltip {
  position: absolute;
  z-index: 6;
  top: -40px;
  left: -40px;
  text-align: center;
  line-height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  box-sizing: border-box;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 5px 5px black, 1px 3px 0 black;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  animation-name: actionTooltipAnimation;
  animation-duration: 0.25s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  .action-tooltip-text {
    width: 120%;
  }
}

.seat-index-3,
.seat-index-4,
.seat-index-5,
.seat-index-6 {
  .action-tooltip {
    left: 0px;
    top: -45px;
    right: unset;
  }
}

.seat-index-0,
.seat-index-1,
.seat-index-2 {
  .action-tooltip {
    left: unset;
    right: 60px;
  }
}
.seat-index-8 {
  .action-tooltip {
    left: unset;
    right: 65px;
    top: -27px;
  }
}

.seat-me {
  .action-tooltip {
    top: -60px;
    left: -5px;
  }
}
</style>
