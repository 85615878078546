<template>
  <div
    class="chip-transfer-item"
    :class="index % 2 == 0 ? 'bg-black' : 'bg-red'"
  >
    <div class="chip-transfer-item-date">
      <span class="chip-transfer-item-date-value">
        {{ getFormattedDate(transferItem.createdAt - timeZone * 60) }}
      </span>
    </div>
    <div class="chip-transfer-item-avatar">
      <Avatar :url="getUserAvatar" size="90" :crown="-1" />
      <div class="name">{{ $store.state.user.name }}</div>
    </div>
    <div class="chip-transfer-item-direction">
      <div
        class="sprite-profile-popup"
        :class="
          transferItem.to == $store.state.user.id
            ? 'incoming-chip-tab'
            : 'outgoing-chip-tab'
        "
      >
        <div
          class="chip-transfer-item-direction-chip sprite-profile-popup chip"
        ></div>
        <div class="chip-transfer-item-direction-amount">
          {{ getChipFormat(transferItem.amount) }}
        </div>
      </div>
    </div>

    <div
      class="chip-transfer-item-player-avatar"
      @click="getUser(transferItem)"
    >
      <Avatar :url="transferItem.receiver.avatar" size="90" :crown="-1" />
      <div class="player-name">
        {{ transferItem.receiver.name }}
      </div>
    </div>
    <div class="description">
      <span
        class="description-text selectable-text"
        v-if="transferItem.description"
        >{{ transferItem.description }}</span
      >
      <span v-if="!transferItem.description">{{ $t("chat.anyMessages") }}</span>
    </div>
  </div>
</template>

<script>
import Helpers from "@/mixin/Helpers";

import { mapGetters } from "vuex";
export default {
  name: "ChipTransferItem",
  mixins: [Helpers],
  components: {
    Avatar: () => import("@/components/Misc/Avatar"),
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    transferItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["getChipFormat"]),
    getBaseAvatarUrl() {
      return process.env.VUE_APP_AVATAR_URL;
    },
    getFormattedDate() {
      return (value) => {
        return this.$plugins.moment.unix(value).format("YYYY/MM/DD HH:mm");
      };
    },
    getUserAvatar() {
      return this.$store.state.user.avatar;
    },
  },
  methods: {
    closeSendMessage() {
      this.showSendPopUp = false;
    },
    async getUser(transferItem) {
      if (transferItem.receiver.id) {
        if (parseInt(transferItem.receiver.id) === this.$store.state.user.id) {
          return this.$plugins.toast.show({
            text: this.$t("searchWindow.ownId"),
            duration: 2000,
            variant: "danger",
          });
        }
        this.loading = true;
        const res = await this.$store.dispatch("friends/getProfiles", [
          transferItem.receiver.id,
        ]);
        if (!res) {
          this.$plugins.toast.show({
            text: this.$t("confirmWindow.userNotFound"),
            duration: 2000,
            variant: "danger",
          });
          this.loading = false;
          return;
        }
        this.$store.commit("friends/setSelectedUserModal", res[0]);
        this.loading = false;
        this.$store.commit("modals/show", "friendDetails");
      } else {
        return this.$plugins.toast.show({
          text: this.$t("searchWindow.validId"),
          duration: 2000,
          variant: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chip-transfer-item {
  width: 99%;
  min-height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  margin-bottom: 10px;
  font-size: 34px;
  position: relative;
  .big-message-icon {
    cursor: pointer;
  }
  &-avatar {
    width: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .name {
      line-height: 40px;
      font-size: 32px;
    }
  }
  &-date {
    width: 20%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background-color: #8a242a;
    text-align: center;
    &-value {
      line-height: 44px;
    }
  }
  &-direction {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    .sprite-profile-popup {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &-amount {
      margin-left: 15px;
    }
  }
  &-player-avatar {
    width: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .player-name {
      line-height: 40px;
      font-size: 32px;
    }
  }
  .description {
    width: 30%;
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 15px;
    background-color: #853742;
    overflow-x: auto;
    &-text {
      margin-left: 20px;
    }
  }
}
</style>
