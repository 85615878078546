<template>
  <div
    class="avatar"
    :class="getClasses"
    :style="getStyles"
    @click="avatarClick"
  >
    <div
      v-if="$props.crown >= 0"
      class="vip-crown"
      :class="`vip-crown-${crown}`"
      :style="getCrownStyles"
      @click="avatarClick"
    ></div>
    <img
      v-if="lazy"
      v-lazy="getImgSrc"
      alt=""
      @error="onImgError"
      @load="onImgLoad"
      v-show="!loading && !error"
      :class="classes"
    />
    <img
      v-if="!lazy && url"
      :src="getImgSrc"
      alt=""
      @error="onImgError"
      @load="onImgLoad"
      v-show="!loading && !error"
      :class="classes"
    />
    <img
      :src="getDefaultAvatarUrl"
      alt=""
      v-if="error && !lazy && url"
      :class="classes"
    />
    <!-- <Spinner :size="getSpinnerSize" radius="0%" v-if="loading && !lazy" /> -->
  </div>
</template>

<script>
import AppConstants from "@/constants/app";
import Spinner from "@/components/Utils/Spinner";
import { mapGetters } from "vuex";
import VueLazyload from "vue-lazyload";

export default {
  name: "Avatar",
  components: {
    Spinner,
    VueLazyload,
  },
  props: {
    lazy: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Number, String],
      default: 100,
      required: false,
    },
    rounded: {
      type: Boolean,
      default: true,
      required: false,
    },
    crown: {
      type: Number,
      default: 0,
      required: false,
    },
    url: {
      type: String,
      default: "",
      required: false,
    },
    classes: {
      type: String,
      default: "",
      required: false,
    },
  },
  watch: {
    "$props.url": function () {
      this.$data.loading = true;
      this.$data.error = false;
    },
  },
  computed: {
    ...mapGetters({
      getRealPlatform: "getRealPlatform",
    }),
    getDefaultAvatarUrl() {
      return AppConstants.defaultAvatarUrl;
    },
    getClasses() {
      const classes = [];

      if (this.$props.rounded) classes.push("avatar-rounded");

      if (this.$props.crown === 0) classes.push("avatar-no-crown");

      return classes;
    },
    getStyles() {
      const styles = {};

      styles.width = `${this.$props.size}px`;
      styles.height = `${this.$props.size}px`;

      return styles;
    },
    getCrownStyles() {
      let styles = {};
      if (this.$props.crown > 12) return styles;
      styles.backgroundImage = `url(${require("@/assets/images/vip_crowns/" +
        this.$props.crown +
        ".png")}`;
      styles.width = "105%";
      styles.height = "105%";

      return styles;
    },
    getSpinnerSize() {
      return `${this.$props.size * (15 / 100)}px`;
    },
    getImgSrc() {
      if (this.url.includes("undefined")) {
        return AppConstants.defaultAvatarUrl;
      }
      return `${this.$props.url}?t=${new Date().getTime()}`;
    },
  },
  data() {
    return {
      loading: true,
      error: false,
    };
  },
  methods: {
    avatarClick($event) {
      // let isClickImage = true;
      // if (this.getRealPlatform !== "ios") {
      //   isClickImage = ($event?.path || []).some(
      //     (el) => el.localName === "img"
      //   );
      // }
      // if (isClickImage) {
      this.$plugins?.audio?.play("Button");

      this.$emit("avatarClick");
      // }
    },
    onImgError($event) {
      this.$data.loading = false;
      this.$data.error = true;
      $event.target.src = AppConstants.defaultAvatarUrl;
    },
    onImgLoad() {
      this.$data.loading = false;
      this.$data.error = false;
    },
  },
};
</script>

<style scoped lang="scss">
.avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  background-image: linear-gradient(0deg, black, lighten(black, 20%), black);
  width: 100px;
  height: 100px;
  cursor: pointer;
  img {
    position: relative;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    border-radius: 100%;
    cursor: pointer;
  }
  &-rounded {
    border-radius: 100% !important;
  }
  .vip-crown {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    &-0 {
      top: -11% !important;
      left: -6% !important;
      width: 112% !important;
      height: 112% !important;
    }
    &-3 {
      top: -5%;
      left: -11px;
      width: 112% !important;
      height: 112% !important;
    }
    &-5 {
      top: 0%;
      left: 0.5%;
    }
    &-6 {
      top: -3%;
      width: 108% !important;
      height: 108% !important;
    }
    &-7 {
      top: -8%;
      width: 113% !important;
      height: 113% !important;
    }
  }
}
</style>
