import { Capacitor, registerPlugin } from '@capacitor/core';

const UNZIPPER_ON_STARTED = 'UNZIPPER_ON_STARTED'
const UNZIPPER_ON_PROGRESS = 'UNZIPPER_ON_PROGRESS'

export default class Unzipper {
    constructor() {
        this.Unzipper = Capacitor.isNativePlatform() ? registerPlugin('Unzipper') : undefined;
    }

    unzip({ file, onCompleted, onError, onProgress, onStarted }) {
        if (typeof this.Unzipper === 'undefined')
            return;

        if (typeof file === 'undefined') {
            console.error('Missing file, localPath or extractLoc fields')
            return
        }

        const id = Date.now().toString(36) + Math.random().toString(36).substr(2);
        const localPath = `${id}.zip`
        const extractLoc = `${id}`

        if (typeof onStarted !== 'undefined')
            this.Unzipper.addListener(UNZIPPER_ON_STARTED, function (i) {
                if (i.id === id)
                    onStarted();
            });

        if (typeof onProgress !== 'undefined')
            this.Unzipper.addListener(UNZIPPER_ON_PROGRESS, function (i) {
                if (i.id === id)
                    onProgress(i.progress);
            });

        this.Unzipper.unzip({
            id: id,
            file: file,
            localPath: localPath,
            extractLoc: extractLoc
        })
            .then(result => {
                if (result.status && typeof onCompleted !== 'undefined')
                    onCompleted(result.extractLoc)

                if (!result.status && typeof onError !== 'undefined')
                    onError()
            })
            .catch(e => onError(e))

    }
}
