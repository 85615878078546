<template>
  <div class="slot-selection no-flick">
    <div class="slot-selection-inner" ref="slider">
      <div
        class="slot-button olympus"
        :class="[config['olympus_status'] == 1 ? 'active' : 'deactivated']"
      >
        <button
          type="button"
          class="download-button"
          :class="[...getDownloadButtonClasses('olympus')]"
          v-if="config['olympus_status'] == 1"
          @click="downloadOrPlay('olympus')"
        >
          <span
            class="download-percent"
            v-if="slots.olympus.status === getSlotsConstants.status.DOWNLOADING"
          >
            {{ getAsPercent(slots.olympus.progress) }}
          </span>
        </button>
      </div>
      <div
        class="slot-button fafafa"
        :class="[config['fafafa_status'] == 1 ? 'active' : 'deactivated']"
      >
        <button
          type="button"
          class="download-button"
          :class="[...getDownloadButtonClasses('fafafa')]"
          v-if="config['fafafa_status'] == 1"
          @click="downloadOrPlay('fafafa')"
        >
          <span
            class="download-percent"
            v-if="slots.fafafa.status === getSlotsConstants.status.DOWNLOADING"
          >
            {{ getAsPercent(slots.fafafa.progress) }}
          </span>
        </button>
      </div>

      <div
        class="slot-button legends"
        :class="[config['turn_legends_status'] == 1 ? 'active' : 'deactivated']"
      >
        <button
          type="button"
          class="download-button"
          :class="[...getDownloadButtonClasses('turnLegends')]"
          v-if="config['turn_legends_status'] == 1"
          @click="downloadOrPlay('turnLegends')"
        >
          <span
            class="download-percent"
            v-if="
              slots.turnLegends.status === getSlotsConstants.status.DOWNLOADING
            "
          >
            {{ getAsPercent(slots.turnLegends.progress) }}
          </span>
        </button>
      </div>
      <div
        class="slot-button bamboo"
        :class="[
          this.config['bamboo_panda_status'] == 1 ? 'active' : 'deactivated',
        ]"
      >
        <button
          type="button"
          class="download-button"
          :class="[...getDownloadButtonClasses('bambooPanda')]"
          v-if="config['bamboo_panda_status'] == 1"
          @click="downloadOrPlay('bambooPanda')"
        >
          <span
            class="download-percent"
            v-if="
              slots.bambooPanda.status === getSlotsConstants.status.DOWNLOADING
            "
          >
            {{ getAsPercent(slots.bambooPanda.progress) }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import SlotsConstants from "@/constants/slots";
import AnalyticsEvents from "@/plugins/analytics/events";

export default {
  name: "SlotSelection",
  watch: {
    "$parent.$parent.$data.showSlotSelection": {
      handler(val) {
        if (val) {
          this.slotCardAnimation(0);
        }
      },
    },
  },
  computed: {
    ...mapState(["slots", "config"]),
    getSlotsConstants() {
      return SlotsConstants;
    },
    userLevel() {
      return this.$store.getters["user/getLevel"](this.$store.state.user.xp)
        .number;
    },
  },
  data() {
    return {
      fafafa: 0,
      turnLegends: 0,
      bambooPanda: 0,
      olympus: 0,
    };
  },
  methods: {
    slotCardAnimation(degNumber) {
      this.$animejs({
        targets: ".slot-button",
        rotateY: [
          {
            value: degNumber === 0 ? 90 : 0,
            duration: 0,
          },
          {
            value: degNumber,
            duration: 250,
          },
        ],
        endDelay: 200,
        easing: "easeInQuad",
      });
    },
    close() {
      this.$plugins.audio.play("Button");
      this.$parent.$parent.$data.showSlotSelection = false;
    },
    downloadOrPlay(name) {
      // if (this.userLevel < 5) {
      //   this.$plugins.toast.show({
      //     text: this.$t("errors.notEnoughLevelPlayGame"),
      //     duration: 2500,
      //   });
      //   return;
      // }

      if (this.$data[name] > 0) {
        console.log("already downloading");
        return;
      }

      this.$plugins.audio.play("Button");
      if (this.slots[name].status === SlotsConstants.status.DOWNLOADED) {
        const gameType =
          name === "turnLegends"
            ? this.$constant.gameType.TURN_LEGENDS
            : name === "fafafa"
            ? this.$constant.gameType.FAFAFA
            : name === "bambooPanda"
            ? this.$constant.gameType.BAMBOO_PANDA
            : name === "olympus"
            ? this.$constant.gameType.OLYMPUS
            : "";

        if (name === "turnLegends") {
          this.$adjust.trackEvent("ClickTurnLegends");
        } else if (name === "fafafa") {
          this.$adjust.trackEvent("ClickFafafa");
        } else if (name === "bambooPanda") {
          this.$adjust.trackEvent("ClickBamboPanda");
        } else if (name === "olympus") {
          this.$adjust.trackEvent("ClickTurnOfOlympus");
        }
        this.$store.dispatch("slots/play", { name });
        this.$store.dispatch("user/updateGameUserInfo", {
          game: gameType,
          room: gameType === "" ? -1 : 1,
        });
        return;
      } else {
        setTimeout(() => {
          this.$data[name] = 0;
        }, 10000);
      }

      this.$store.dispatch("slots/download", { name });
      this.$data[name]++;
    },
    getDownloadButtonClasses(name) {
      const classes = [];

      if (this.slots[name].status === SlotsConstants.status.DOWNLOADING) {
        classes.push("downloading");
      }

      if (this.slots[name].status === SlotsConstants.status.DOWNLOADED) {
        this.$data[name] = 0;
        classes.push("downloaded");
      }

      if (this.slots[name].status === SlotsConstants.status.AVAILABLE) {
        this.$data[name] = 0;
        classes.push("available");
      }

      return classes;
    },
    getAsPercent(number) {
      return `${Math.floor(number)}%`;
    },
  },
};
</script>

<style scoped lang="scss">
.slot-selection {
  position: absolute;
  left: 400px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 245px;
  width: 1500px;
  height: 630px;
  &-inner {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    .slot-button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      min-height: 480px;
      min-width: 365px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      cursor: pointer;
      &.deactivated {
        opacity: 0.3 !important;
      }
      &.fafafa {
        background-image: url("~@/assets/images/lobby/fafafa.png");
      }
      &.legends {
        background-image: url("~@/assets/images/lobby/turn-legends2.png");
      }
      &.olympus {
        background-image: url("~@/assets/images/lobby/turn-of-olympus3.png");
      }
      &.bamboo {
        background-image: url("~@/assets/images/lobby/bamboopanda.png");
      }
      .download-button {
        position: relative;
        display: block;
        width: 320px;
        min-height: 490px;
        border-radius: 20px;
        background-color: rgba(black, 0.25);
        box-shadow: inset 0 0 0 5px rgb(255, 255, 255),
          0 0 0 10px rgba(black, 0.35);

        &.downloaded {
          width: 100%;
          height: 100%;
          background-color: transparent;
          box-shadow: none;
          margin-top: 0;
          border-radius: 0;
        }
        &.downloading {
          background-color: rgba(black, 0.8);
        }
        &.available {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: url("data:image/svg+xml,%3Csvg fill='none' stroke='white' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4'%3E%3C/path%3E%3C/svg%3E");
            background-size: 60%;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        .download-percent {
          color: rgb(255, 255, 255);
          font-size: 58px;
          font-weight: bold;
        }
      }
    }

    .close {
      position: absolute;
      left: 0;
      top: 33px;
      width: 128px;
      height: 128px;
      background-color: transparent;
    }
  }
}
</style>
