<template>
  <div class="empty-scene"></div>
</template>

<script>
export default {
  name: "Empty"
}
</script>

<style scoped>
.empty-scene{
  background-color:black;
}
</style>