<template>
  <!-- <div
    class="chat-window-container"
    :class="getRealPlatform === 'android' ? 'no-flick' : ''"
  > -->
  <div class="invite-window-bg" v-click-outside="onClickedOutsideWindow">
    <div class="invite-window-inner-bg">
      <div class="invite-container">
        <div class="tab-selectors">
          <div class="selector active">
            <span>{{ $t("table.onlineFriends") }}</span>
          </div>
          <div class="close-container" @click="closeWindow">
            <div class="close-button flex-center">
              <span class="sprite-table-pop-up exit-icon"></span>
            </div>
          </div>
        </div>
        <div class="invite-item-container no-click-outside">
          <div class="no-data" v-if="innerFriends.length < 1">
            {{ $t("table.noHaveOnlineFriends") }}
          </div>
          <template v-for="(friend, index) in innerFriends">
            <div
              class="invite-item"
              :key="index"
              :style="{
                minHeight: `${
                  friendSameGameAndTable(friend.user.game, friend.user.room)
                    ? '90px'
                    : '124px'
                }`,
              }"
            >
              <div class="avatar no-click-outside">
                <Avatar :size="123" :url="friend.user.avatar" />
              </div>
              <div class="player-info no-click-outside">
                <span class="player-info-name"> {{ friend.user.name }} </span>
                <span class="player-info-game">{{
                  gameTextComputed(friend.user.game)
                }}</span>
                <div
                  class="buttons no-click-outside"
                  v-if="
                    !friendSameGameAndTable(friend.user.game, friend.user.room)
                  "
                >
                  <div
                    class="join sprite-table-pop-up go-button"
                    v-if="joinIsDisable(friend.user.game)"
                    @click="joinFriendGame(friend.user.game, friend.user.room)"
                  >
                    <span class="join-text">{{
                      $t("progressiveJackpot.join")
                    }}</span>
                  </div>
                  <div
                    class="invite sprite-table-pop-up invite-button"
                    @click="
                      inviteFriend(
                        friend.user.id ? friend.user.id : friend.user.userId
                      )
                    "
                  >
                    <span class="invite-text">
                      {{ $t("general.invite") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ClickOutside from "vue-click-outside";

import ChatComponent from "./ChatWindow/Chat";
import DealerComponent from "./ChatWindow/Dealer";
import MuteComponent from "./ChatWindow/Mute";

import SlotsConstants from "@/constants/slots";
import Helpers from "@/mixin/Helpers";
import StorageManager from "@/plugins/storage-manager";
import AppConstants from "@/constants/app";

export default {
  name: "TableInviteWindow",
  mixins: [Helpers],
  components: {
    Chat: ChatComponent,
    Dealer: DealerComponent,
    Mute: MuteComponent,
    Avatar: () => import("@/components/Misc/Avatar"),
  },
  directives: { ClickOutside },
  watch: {
    nAmISitting(val) {
      if (
        !val &&
        this.waitingEnterTable.status &&
        this.waitingEnterTable.tableId > -1
      ) {
        this.$store.dispatch("service/joinPokerRoom", {
          roomId: this.waitingEnterTable.tableId,
          directedGame: this.waitingEnterTable.directedGame,
        });
        this.waitingEnterTable = {
          status: false,
          tableId: -1,
          directedGame: "",
        };
      }
    },
  },
  computed: {
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
      texasRooms: `service/getTexasHoldemRooms`,
      omahaRooms: `service/getOmahaRooms`,
      getRealPlatform: "getRealPlatform",
      nAmISitting: "service/nAmISitting",
    }),
    ...mapState(["user", "slots"]),
    ...mapState("friends", ["friendsList"]),
    userLevel() {
      return this.$store.getters["user/getLevel"](this.$store.state.user.xp)
        .number;
    },
    innerFriends() {
      return this.friendsList.list.filter((fri) => fri.user.online);
    },
    currentPokerGameType() {
      if (this.getActiveRoomState && this.getActiveRoomState.gameType) {
        return this.getActiveRoomState.gameType === "texas_holdem"
          ? this.$constant.gameType.TEXAS
          : this.getActiveRoomState.gameType === "omaha_poker"
          ? this.$constant.gameType.OMAHA
          : "";
      } else return "";
    },
    currentPokerGameId() {
      return this.getActiveRoomState.id || -1;
    },
  },
  data: () => ({
    currentTab: "Chat",
    mutedPlayers: [],
    requestSending: false,
    fafafa: 0,
    turnLegends: 0,
    bambooPanda: 0,
    waitingEnterTable: {
      status: false,
      tableId: -1,
      directedGame: "",
    },
  }),
  methods: {
    async inviteFriend(id) {
      if (id && !this.requestSending) {
        this.requestSending = true;

        const friendInviteResponse = await this.$store.dispatch(
          "user/friendInviteRoom",
          {
            game: this.currentPokerGameType,
            room: this.currentPokerGameId,
            friendId: id,
          }
        );

        if (friendInviteResponse.status === 204) {
          this.$plugins.toast.show({
            text: this.$t("systemMessages.inviteRequestSuccess"),
            duration: 2500,
            variant: "success",
          });
        }
        this.requestSending = false;
      }
    },
    joinFriendGame(game, room) {
      let roomFound = false;

      if (this.$constant.gameType.TEXAS === game) {
        roomFound = this.texasRooms.find((findRoom) => {
          return findRoom.id === room;
        });
      } else if (this.$constant.gameType.OMAHA === game) {
        roomFound = this.omahaRooms.find((findRoom) => findRoom.id === room);
      }

      if (roomFound) {
        if (
          this.user.chip < roomFound.metadata.minBuy &&
          !this.user.vip.status
        ) {
          this.$plugins.toast.show({
            text: this.$t("playerActions.enoughChips"),
            duration: 2500,
          });
          return;
        }
      } else if (
        this.$constant.gameType.TEXAS === game ||
        this.$constant.gameType.OMAHA === game
      ) {
        this.$plugins.toast.show({
          text: this.$t("errors.tableNotFound"),
          duration: 2500,
        });
        return;
      }

      if (room < 1) {
        this.$plugins.toast.show({
          text: this.$t("errors.userNotActiveGame"),
          duration: 2500,
        });
        return;
      }

      if (
        this.nAmISitting &&
        (this.$constant.gameType.TEXAS === game ||
          this.$constant.gameType.OMAHA === game)
      ) {
        StorageManager.instance.remove({
          key: AppConstants.storageKeys.reconnect,
        });
        this.$store.state.service.colyseusConnection.ACTIVE_ROOM?.send("leave");
        this.waitingEnterTable = {
          status: true,
          tableId: room,
          directedGame: game,
        };
        this.closeWindow();
        return;
      }

      if (
        game === this.$constant.gameType.TURN_LEGENDS ||
        game === this.$constant.gameType.BAMBOO_PANDA ||
        game === this.$constant.gameType.FAFAFA ||
        game === this.$constant.gameType.OLYMPUS
      ) {
        const name =
          game === this.$constant.gameType.TURN_LEGENDS
            ? "turnLegends"
            : game === this.$constant.gameType.FAFAFA
            ? "fafafa"
            : game === this.$constant.gameType.BAMBOO_PANDA
            ? "bambooPanda"
            : game === this.$constant.gameType.OLYMPUS
            ? "olympus"
            : "";

        if (this.slots[name].status !== SlotsConstants.status.DOWNLOADED) {
          // this.$plugins.toast.show({
          //   text: this.$t("errors.slotNotDownloaded"),
          //   duration: 2500,
          // });
          return;
        } else {
          const gameType =
            name === "turnLegends"
              ? this.$constant.gameType.TURN_LEGENDS
              : name === "fafafa"
              ? this.$constant.gameType.FAFAFA
              : name === "bambooPanda"
              ? this.$constant.gameType.BAMBOO_PANDA
              : name === "olympus"
              ? this.$constant.gameType.OLYMPUS
              : "";
          this.$store.dispatch("user/updateGameUserInfo", {
            game: gameType,
            room: gameType === "" ? -1 : 1,
          });
        }
      }

      this.waitingEnterTable = {
        status: false,
        tableId: -1,
        directedGame: "",
      };

      this.closeWindow();
      switch (game) {
        case this.$constant.gameType.TEXAS:
          this.$store.dispatch("service/joinPokerRoom", {
            roomId: room,
            directedGame: game,
          });
          break;
        case this.$constant.gameType.OMAHA:
          this.$store.dispatch("service/joinPokerRoom", {
            roomId: room,
            directedGame: game,
          });
          break;
        case this.$constant.gameType.BLACKJACK:
          this.$store.dispatch("service/joinToBlackjackRoom");
          break;
        case this.$constant.gameType.TURN_LEGENDS:
          this.$store.dispatch("slots/play", { name: "turnLegends" });

          break;
        case this.$constant.gameType.BAMBOO_PANDA:
          this.$store.dispatch("slots/play", { name: "bambooPanda" });

          break;
        case this.$constant.gameType.FAFAFA:
          this.$store.dispatch("slots/play", { name: "fafafa" });

          break;
        case this.$constant.gameType.OLYMPUS:
          this.$store.dispatch("slots/play", { name: "olympus" });

          break;
        default:
          console.log("Game not found !");
          break;
      }
    },
    slotValidation() {
      if (this.userLevel < 5) {
        this.$plugins.toast.show({
          text: this.$t("errors.notEnoughLevelPlayGame"),
          duration: 2500,
        });
        return false;
      }

      if (this.slots[name].status !== SlotsConstants.status.DOWNLOADED) {
        return false;
      }
    },
    friendSameGameAndTable(game, roomId) {
      const myCurrentGame =
        this.getActiveRoomState?.gameType === "texas_holdem"
          ? this.$constant.gameType.TEXAS
          : this.getActiveRoomState?.gameType === "omaha_poker"
          ? this.$constant.gameType.OMAHA
          : "";

      if (myCurrentGame === game && roomId === this.getActiveRoomState?.id) {
        return true;
      }
      return false;
    },
    joinIsDisable(game) {
      if (
        game === this.$constant.gameType.TEXAS ||
        game === this.$constant.gameType.OMAHA ||
        game === this.$constant.gameType.BLACKJACK
      ) {
        return true;
      }
      return false;
    },
    // onClickedOutsideWindow($event) {
    //   console.log("deneme 1 2");
    //   if (
    //     $event &&
    //     $event.target &&
    //     typeof $event.target.className !== "object" &&
    //     $event.target.className.includes("invite-window-bg") &&
    //     $event.target.className.includes("seat-sit-button") &&
    //     typeof $event.target == "object"
    //   ) {
    //     return false;
    //   }
    //   this.closeWindow();
    // },
    onClickedOutsideWindow($event) {
      if (typeof $event?.target?.className.includes === "undefined")
        return false;
      if ($event?.target?.className.includes("seat-sit-button")) return false;
      if ($event?.target?.className.includes("invite-icon")) return false;
      this.closeWindow();
    },
    closeWindow() {
      // this.$plugins.audio.play("Button");

      this.$store.state.modals.showTableInviteWindow = false;
    },
  },
};
</script>
<!-- <div
              class="online-indicator"
              :class="friend.user.online ? 'on' : 'off'"
        ></div> -->
<style lang="scss" scoped>
.invite-window-bg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31%;
  height: 100%;
  z-index: 99;
  right: -5px;

  .invite-window-inner-bg {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(#3c0b13, 0.95);

    .invite-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;

      .invite-item-container {
        background: rgba(#60181e, 0.5);
        border-radius: 13px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 98%;
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;
        padding-top: 10px;
        .invite-item {
          width: 98%;
          background-color: #3c0b13;
          margin-bottom: 10px;
          padding: 10px 0px;
          display: flex;
          flex-direction: row;
          align-items: center;

          border-radius: 20px;
          .avatar {
            width: 27%;
            display: flex;
            justify-content: center;
          }
          .player-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 71%;
            font-size: 30px;
            .player-info-name {
              font-weight: 510;
              color: #ffffff;
            }
            .player-info-game {
              color: rgba(203, 203, 203, 1);
              padding-top: 3px;
            }
            .buttons {
              margin-top: 15px;
              display: flex;

              justify-content: space-between;
              width: 100%;

              .join {
                font-size: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
              }
              .invite {
                font-size: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                &:active {
                  transform: scale(1.1);
                }
              }
            }
          }
          // .player {
          //   display: flex;
          //   flex-direction: row;
          //   padding: 0 0 0 50px;
          //   .player-info {
          //     display: flex;
          //     flex-direction: column;
          //     align-items: flex-start;
          //     justify-content: space-evenly;
          //     margin-left: 15px;
          //     font-size: 30px;
          //     .player-info-name {
          //       font-weight: 550;
          //       color: #ffffff;
          //     }
          //     .player-info-game {
          //       color: rgba(203, 203, 203, 1);
          //     }
          //   }
          //   .online-indicator {
          //     width: 30px;
          //     height: 30px;
          //     border-radius: 15px;
          //     position: relative;
          //     left: -30px;
          //     top: 30px;
          //     &.off {
          //       background: #ff6262;
          //     }
          //     &.on {
          //       background: #78ff5d;
          //     }
          //   }
          // }
          // .buttons {
          //   padding: 0 20px;
          //   display: flex;
          //   flex-direction: row;
          //   justify-content: space-evenly;

          //   div,
          //   button {
          //     height: 65px;
          //     width: 230px;
          //     display: flex;
          //     justify-content: center;
          //     align-items: center;
          //     color: #000000;
          //     font-size: 30px;
          //     cursor: pointer;
          //   }

          //   .disable,
          //   .join {
          //     background: url("~@/assets/images/table/table-invite-join-button.png")
          //       no-repeat 50% 50%;
          //     background-size: contain;
          //     min-width: 230px;
          //   }
          //   .invite {
          //     background: url("~@/assets/images/table/table-invite-request-button.png")
          //       no-repeat 50% 50%;
          //     background-size: contain;

          //     .invite-icon {
          //       background: url("~@/assets/images/table/table-friend-invite-icon.png")
          //           no-repeat 50% 50%,
          //         transparent;
          //       height: 60px;
          //       width: 60px;
          //       position: relative;
          //       left: 15px;
          //     }
          //   }
          // }
        }
      }

      .tab-selectors {
        // width: 430px;
        width: 100%;
        height: 85px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        border-radius: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .close-container {
          position: absolute;
          z-index: 3;
          top: -8px;
          right: 15px;
          height: 90px;
          width: 90px;
          cursor: pointer;
        }
        .close-button {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 3;
          top: 10px;
          right: 15px;
          width: 65px;
          height: 65px;
          border-radius: 100%;
          border: 3px solid #cf707f;
          cursor: pointer;

          transition: all 0.25s ease;
          &:active {
            filter: brightness(60%);
            -webkit-filter: brightness(60%);
          }
          .exit-icon {
            transform: scale(0.7);
          }
        }
        .selector {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 100px;

          span {
            color: #2e0004;
            font-size: 45px;
            text-transform: uppercase;
          }
          &.active {
            span {
              color: #cf707f;
            }
          }
        }
        &:after {
          content: " ";
          position: absolute;
          top: 80px;
          left: 0px;
          background: #761b26;
          width: 100%;
          height: 2px;
        }
      }
      .no-data {
        font-size: 34px;
        display: flex;
        height: 100%;
        align-items: center;
      }
    }
  }
}
// }
</style>
