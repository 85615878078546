<template>
  <SafeArea>
    <div class="blackjack-screen" v-if="nGetMySeat">
      <Header />
      <template>
        <Table />
        <PlayersActionsIndicator v-if="platform === 'mobile'" />
        <WinnerList v-if="platform === 'mobile'" />
        <SitWindow />
      </template>
    </div>
  </SafeArea>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import SafeArea from "../Screen/SafeArea";

import Header from "../Misc/Blackjack/Header";
import Table from "../Misc/Blackjack/Table";
import PlayersActionsIndicator from "../Misc/Blackjack/PlayersActionsIndicator";
import WinnerList from "../Misc/Blackjack/WinnerList";
import Loading from "../Misc/Blackjack/Loading";
import SitWindow from "../Misc/Blackjack/SitWindow";

export default {
  name: "Blackjack",
  components: {
    SafeArea,
    SitWindow,
    Header,
    Table,
    PlayersActionsIndicator,
    WinnerList,
    Loading,
  },
  computed: {
    ...mapState(["platform"]),
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
    }),
    loading() {
      return this.$store.state.service.isBlackjackLoading;
    },
  },
  async mounted() {
    await this.$adjust.trackEvent("ClickBlackjack");
  },
};
</script>

<style lang="scss" scoped>
.blackjack-screen {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url("~@/assets/images/blackjack/background.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
