<template>
  <SafeArea>
    <div class="table-list-scene no-flick">
      <div class="table-list-title">
        <span class="title-text">
          {{ $t("tableList.tableList") }} / {{ titleText }}</span
        >
        <span class="profile-sprite light"></span>
        <div class="close-container" @click="close">
          <div class="close">
            <span class="sprite-table-pop-up exit-icon"></span>
          </div>
        </div>
      </div>
      <div class="table-list-container">
        <div class="table-detail">
          <template v-if="!jackpotInfoShow">
            <div
              class="table-detail-inner"
              v-if="getRoomInfo && getRoomInfo.id"
            >
              <div class="table-detail-inner-first-row">
                <div class="left-side">
                  <div class="table-name">
                    <strong>{{ $t("tableList.table") }} ID:</strong>
                    {{ getRoomInfo.id }}
                  </div>
                  <div class="table-blind">
                    <strong>{{ $t("tableList.blind") }}:</strong>
                    {{
                      `${getShortChipFormat(
                        getRoomInfo.metadata.bet
                      )}/${getShortChipFormat(getRoomInfo.metadata.bet * 2)}`
                    }}
                  </div>
                </div>
                <div class="table-canak">
                  <div
                    class="table-canak-col"
                    v-if="getSpecialJackpots.jackpots.length > 0"
                  >
                    <div class="pot-text">POT</div>
                    <div class="jackpot-amount" v-if="!jackpots.fetching">
                      {{ getShortChipFormat(getSpecialJackpots.total) }}
                    </div>
                    <Spinner class="jackpot-amount" v-if="jackpots.fetching" />
                  </div>
                  <div class="table-canak-col">
                    <div class="canak-icon"></div>
                    <div class="jackpot-amount" v-if="!jackpots.fetching">
                      {{ getShortChipFormat(getSystemJackpots.total) }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="player-list">
                <template v-if="getRoomInfo.serverInfo.players.length <= 0">
                  <div class="player-list-empty">
                    {{ $t("tableList.tableInfo.emptyRoomMessage") }}
                  </div>
                </template>
                <template v-if="getRoomInfo.serverInfo.players.length > 0">
                  <div
                    class="table-player"
                    v-for="(player, playerIndex) in getRoomInfo.serverInfo
                      .players"
                    :key="'table-info-player-' + playerIndex"
                  >
                    <div class="table-player-info">
                      <div class="table-player-avatar">
                        <Avatar size="105" :url="player.avatar" :crown="-1" />
                      </div>
                      <div class="table-player-name">{{ player.name }}</div>
                    </div>
                    <div class="table-player-stack">
                      {{ getShortChipFormat(player.stack) }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="jackpots" v-if="getAllJackpots.length > 0">
              <!-- <div class="jackpot-header">
                <div class="jackpot-icon"></div>
                <div class="jackpot-amount">
                  {{ $t("createTableWindow.pot") }}
                </div>

                <div class="jackpot-table-bet">{{ $t("tableList.blind") }}</div>
              </div> -->
              <div
                class="jackpot"
                :class="
                  jackpotSelectBet === jackpot.metadata.bet
                    ? 'jackpot-active'
                    : ''
                "
                v-for="(jackpot, index) in getAllJackpots"
                :key="index"
                @click="selectJackpotItem(jackpot)"
              >
                <div class="jackpot-icon">
                  {{
                    `${getShortChipFormat(
                      parseInt(jackpot.metadata.bet)
                    )} / ${getShortChipFormat(
                      parseInt(jackpot.metadata.bet) * 2
                    )}`
                  }}
                </div>
                <div class="jackpot-amount">
                  <div class="jackpot-image"></div>
                </div>
                <div class="jackpot-table-bet">
                  {{ getShortChipFormat(jackpot.totaljackpotAmount) }}
                </div>
              </div>
            </div>
            <!-- <Spinner size="50px" v-else /> -->
          </template>
        </div>
        <div class="table-list-items-outer">
          <div class="table-list-headings">
            <span class="line-icon"></span>
            <div class="table-list-headings-item">
              {{ $t("tableList.tableId") }}
            </div>
            <div class="table-list-headings-item">
              {{ $t("tableList.blind") }}
            </div>
            <div class="table-list-headings-item">
              {{ $t("tableList.minMax") }}
            </div>
            <div class="table-list-headings-item">
              {{ $t("tableList.players") }}
            </div>
            <div class="table-list-headings-item"></div>
          </div>
          <div class="table-list-items">
            <div class="no-tables-found" v-show="getRooms.length === 0">
              {{ $t("tableList.noRoomsMessage") }}
            </div>
            <div
              v-for="room in getRooms"
              :key="'room-' + room.id"
              @click="setSelectedRoomId(room.id)"
              class="table-list-items-row"
              :class="[selectedRoomId === room.id ? 'selected' : '']"
            >
              <div
                class="table-list-items-row-item"
                :class="[
                  isSelectedTablePlayersMax(room)
                    ? 'sprite-list-pop-up full-button'
                    : selectedRoomId === room.id
                    ? 'sprite-list-pop-up selected-button'
                    : '',
                ]"
              >
                {{ room.id }}
              </div>
              <div class="table-list-items-row-item bets-column">
                {{
                  getShortChipFormat(room.metadata.bet) +
                  "/" +
                  getShortChipFormat(room.metadata.bet * 2)
                }}
              </div>
              <div class="table-list-items-row-item min-max-column">
                {{
                  getShortChipFormat(room.metadata.minBuy) +
                  "/" +
                  getShortChipFormat(room.metadata.maxBuy)
                }}
              </div>
              <div
                class="table-list-items-row-item"
                :class="
                  isSelectedTablePlayersMax(room)
                    ? 'players-red'
                    : 'players-green'
                "
              >
                <div class="bar">
                  <div
                    class="fill-part"
                    :style="
                      getTableUserBar(
                        room.serverInfo.players.length,
                        room.metadata.maxPlayers
                      )
                    "
                  ></div>
                </div>
                <div class="fill-text">
                  {{
                    `${room.serverInfo.players.length}/${room.metadata.maxPlayers}`
                  }}
                </div>
              </div>
              <div class="table-list-items-row-item">
                <div
                  type="button"
                  class="table-list-go-button"
                  :class="[
                    isSelectedTablePlayersMax(room)
                      ? 'ui-button ui-button-small-red'
                      : selectedRoomId !== room.id
                      ? 'ui-button ui-button-small-green'
                      : 'ui-button ui-button-small-green',
                    currentLang === 'ru' ? 'lang-ru' : '',
                  ]"
                  v-show="room.serverInfo.players.length < room.maxClients"
                  @click="joinPokerRoom(room.id)"
                >
                  <LoadingIcon
                    v-if="
                      !tableCanBeEntered.login &&
                      tableCanBeEntered.id === room.id
                    "
                  />
                  <span v-else-if="!isSelectedTablePlayersMax(room)">{{
                    $t("progressiveJackpot.join")
                  }}</span>
                  <span v-else class="table-full-button-text">
                    {{ $t("tableList.full").toUpperCase() }}
                  </span>
                </div>
                <div
                  class="ui-button ui-button-small-red"
                  v-show="room.serverInfo.players.length >= room.maxClients"
                >
                  {{ $t("tableList.full") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-list-filter">
        <div
          class="all-button ui-button ui-button-orange"
          @click="allButtonClick"
        >
          {{ $t("tableList.all") }}
        </div>
        <div class="state-filter-container">
          <div
            class="state-filter-option-container"
            @click="filter.hideEmpty = !filter.hideEmpty"
          >
            <div class="indicator">
              <div
                :class="[
                  filter.hideEmpty ? 'sprite-list-pop-up okey-icon' : '',
                ]"
              ></div>
            </div>
            <div class="text">{{ $t("tableList.hideEmpties") }}</div>
          </div>
          <div
            class="state-filter-option-container"
            @click="filter.hideFull = !filter.hideFull"
          >
            <div class="indicator">
              <div
                :class="[filter.hideFull ? 'sprite-list-pop-up okey-icon' : '']"
              ></div>
            </div>
            <div class="text">{{ $t("tableList.hideFullyFilled") }}</div>
          </div>
        </div>

        <div
          class="refresh-button ui-button ui-button-orange"
          @click="refreshTable"
        >
          <div
            class="sprite-list-pop-up refresh-icon"
            :class="tableRefreshing ? 'rotating' : ''"
          ></div>
          <div class="refresh-text">{{ $t("general.refresh") }}</div>
        </div>
        <!-- <div class="create-table-container">
          <button
            type="button"
            class="ui-button ui-button-green"
            @click="$store.commit('modals/show', 'createTable')"
          >
            {{ $t("createTableWindow.createTable") }}
          </button>
        </div> -->
      </div>
    </div>
  </SafeArea>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import clickOutside from "vue-click-outside";

import SafeArea from "@/components/Screen/SafeArea";
import Avatar from "@/components/Misc/Avatar";

import i18n from "@/plugins/i18n";

export default {
  name: "TexasHoldemTableList",
  directives: { clickOutside },
  components: {
    SafeArea,
    Avatar,
    Spinner: () => import("@/components/Utils/Spinner"),
    LoadingIcon: () => import("@/components/Misc/Svg/LoadingIcon"),
  },
  watch: {
    "filter.hideEmpty": function () {
      // if (this.getRooms[0]) this.setSelectedRoomId(this.getRooms[0].id);
    },
    "filter.hideFull": function () {
      // if (this.getRooms[0]) this.setSelectedRoomId(this.getRooms[0].id);
    },
  },
  computed: {
    ...mapState(["user", "jackpots"]),
    ...mapGetters({
      rooms: `service/getOmahaOrTexasRooms`,
      getShortChipFormat: "getShortChipFormat",
      getChipFormat: "getChipFormat",
      getSpecialJackpots: "jackpots/getSpecialJackpots",
      getSystemJackpots: "jackpots/getSystemJackpots",
      getAllJackpots: "jackpots/getAllJackpots",
    }),
    currentLang() {
      if (i18n && i18n.locale) {
        return i18n.locale;
      }
      return "";
    },
    titleText() {
      if (this.$store.state.service.lastOpenedTableList === "Omaha") {
        return "Omaha";
      } else if (
        this.$store.state.service.lastOpenedTableList === "TexasHoldem"
      ) {
        return "TEXAS HOLD'EM";
      }
    },
    innerRooms() {},
    getRooms() {
      return [...this.rooms]
        .filter((room) => {
          let state = true;
          if (
            this.$data.filter.hideFull &&
            room.serverInfo.players.length === room.metadata.maxPlayers
          ) {
            state = false;
          }
          if (
            this.$data.filter.hideEmpty &&
            room.serverInfo.players.length <= 0
          ) {
            state = false;
          }
          if (
            this.$data.filter.smallBlind > 0 &&
            Number(room.metadata.bet) !== this.$data.filter.smallBlind
          ) {
            state = false;
          }

          return state;
        })
        .sort((room1, room2) => room2.metadata.bet - room1.metadata.bet);
    },
    getRoomInfo() {
      const roomIndex = this.rooms.findIndex(
        (room) => Number(room.id) === Number(this.$data.selectedRoomId)
      );

      if (roomIndex < 0) return this.rooms[0] ?? false;

      return this.rooms[roomIndex];
    },
    getBlinds() {
      const smallBlinds = [];
      this.rooms.forEach((room) => {
        const sb = Number(room.metadata.bet);
        if (smallBlinds.includes(sb)) return;
        smallBlinds.push(sb);
      });
      return [...smallBlinds].sort((a, b) => b - a);
    },
    top3Jackpot() {
      return this.getAllJackpots.filter((pot, index) => {
        if (index < 3) return true;
        return false;
      });
    },
  },
  data() {
    return {
      selectedRoomId: 0,
      showBlindFilterList: false,
      filter: {
        hideEmpty: true,
        hideFull: false,
        smallBlind: 0,
      },
      jackpotInfoShow: true,
      jackpotSelectBet: null,
      tableRefreshing: false,
      tableCanBeEntered: {
        login: true,
        id: -1,
      },
    };
  },
  methods: {
    getTableUserBar(currentPlayer, maxPlayer) {
      const percent = Math.floor((currentPlayer * 100) / maxPlayer);
      if (percent == 100) {
        return { width: `${percent}%`, borderRadius: "50px" };
      }
      return { width: `${percent}%` };
    },
    isSelectedTablePlayersMax(table) {
      if (table) {
        const maxPlayers = parseInt(table.metadata.maxPlayers);
        const currentPlayers = table.serverInfo.players.length;
        if (maxPlayers <= currentPlayers) {
          return true;
        }
      }
      return false;
    },
    allButtonClick() {
      this.jackpotInfoShow = true;
      this.selectedRoomId = 0;
      this.jackpotSelectBet = null;
      this.filter.smallBlind = 0;
    },
    async refreshTable() {
      if (this.tableRefreshing) {
        return;
      }
      await this.$store.dispatch("service/getRoomListByServerId", {
        serverId: 13,
      });
      await this.$store.dispatch("service/connectToLobby");
      this.tableRefreshing = true;
      setTimeout(() => {
        this.tableRefreshing = false;
      }, 5000);
    },
    onClickedOutsideOfBlindFilterList($event) {
      if (
        typeof $event?.target?.className.includes !== "undefined" &&
        $event?.target?.className.includes("blind-filter-button") &&
        !this.$data.showBlindFilterList
      )
        return false;
      this.$data.showBlindFilterList = false;
    },
    joinPokerRoom(roomId) {
      if (!this.tableCanBeEntered.login) {
        return;
      }
      const room = this.rooms.find((r) => r.id == roomId);
      if (!room) return;
      if (this.user.chip < room.metadata.minBuy && !this.user.vip.status) {
        this.$plugins.toast.show({
          text: this.$t("errors.insufficientBalance"),
          variant: "danger",
        });
        return;
      }

      this.tableCanBeEntered = {
        login: false,
        id: roomId,
      };

      this.$store.dispatch("service/joinPokerRoom", { roomId });

      setTimeout(() => {
        this.tableCanBeEntered.login = {
          login: false,
          id: -1,
        };
      }, 4000);
    },
    setSelectedRoomId(id = 0) {
      if (this.$data.selectedRoomId === id) {
        this.$data.selectedRoomId = 0;
        this.jackpotInfoShow = true;
      } else {
        this.$data.selectedRoomId = id;
        this.jackpotInfoShow = false;
      }

      this.$store.dispatch("jackpots/fetch", {
        serverId: 13,
        roomId: String(id),
      });
    },
    close() {
      this.$plugins.audio.play("button_close_new");
      // if (this.$store.state.service.lastTableId > 0) {
      //   this.$store.dispatch('service/joinPokerRoom', { roomId: this.$store.state.service.lastTableId });
      //   return;
      // }
      console.log("changeScene lobby344");
      this.$store.commit("changeScene", "Lobby");
    },
    selectSmallBlindFilter(sb) {
      this.$data.filter.smallBlind = sb;
      this.$data.showBlindFilterList = false;
      if (this.getRooms[0]) this.setSelectedRoomId(this.getRooms[0].id);
    },
    showBlindFilterListFunc() {
      if (this.$data.showBlindFilterList) {
        this.$data.showBlindFilterList = false;
        return;
      }
      this.$data.showBlindFilterList = true;
    },
    selectJackpotItem(jackpot) {
      if (this.jackpotSelectBet !== jackpot.metadata.bet) {
        this.jackpotSelectBet = jackpot.metadata.bet;
        this.filter.smallBlind = parseInt(jackpot.metadata.bet);
      } else {
        this.jackpotSelectBet = null;
        this.filter.smallBlind = 0;
      }
    },
  },

  // mounted() {
  //   const roomIds = this.rooms.map((room) => {
  //     return { id: room.id, bet: parseInt(room.metadata.bet) };
  //   });

  //   this.$store.dispatch("jackpots/fetchTopJackpot", {
  //     limit: 5,
  //   });
  // },
  beforeDestroy() {
    this.$store.state.lastOpenedTableList = "";
  },
};
</script>

<style lang="scss" scoped>
@keyframes rotatinganimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(365deg);
  }
}
.table-list-scene {
  position: relative;
  height: 100%;
  background-image: url(~@/assets/images/backgrounds/lobby-background.png);
  background-position: top;
  .table-list-title {
    position: relative;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    .close-container {
      position: absolute;
      z-index: 3;
      top: 5px;
      right: 15px;
      height: 90px;
      width: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .close {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
        top: 5px;
        right: 15px;
        width: 65px;
        height: 65px;
        border-radius: 100%;
        border: 3px solid #cf707f;
        cursor: pointer;

        transition: all 0.25s ease;
        &:active {
          filter: brightness(60%);
          -webkit-filter: brightness(60%);
        }
        .exit-icon {
          transform: scale(0.7);
        }
      }
    }
    .light {
      position: absolute;
      top: 0;
    }
    .title-text {
      font-size: 50px;
      font-weight: bold;
      text-transform: uppercase;
      text-shadow: 3px 3px 3px black;
      z-index: 2;
    }
  }

  .table-list-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 82%;
    width: 98%;
    box-sizing: border-box;

    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-left: 20px;
    .table-detail {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 33%;
      min-height: 100%;
      max-height: 100%;
      box-sizing: border-box;
      border-radius: 30px;
      padding: 10px;
      &-inner {
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 850px;
        padding: 20px;
        box-sizing: border-box;
        background-color: #2c080d;
        border-radius: 20px;
        font-size: 30px;
        .table-detail-inner-first-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 12%;
          padding: 10px;
          .left-side {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
          }
        }

        .table-canak {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: column;
          .table-canak-col {
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            .canak-icon {
              background-image: url("~@/assets/images/jackpots/turn-canak.png");
              background-repeat: no-repeat;
              background-size: contain;
              width: 55px;
              height: 55px;
            }
          }
          .jackpot-amount {
            font-size: 32px;
          }
        }

        .player-list {
          margin-top: 20px;
          width: 100%;
          height: 90%;
          border-radius: inherit;
          overflow-x: hidden;
          overflow-y: auto;
          &-empty {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background: rgba(black, 0.5);
          }

          .table-player {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            height: 120px;
            min-height: 120px;
            font-size: 32px;
            font-weight: bold;
            border-radius: 30px;
            margin-bottom: 10px;
            background: linear-gradient(
              180deg,
              rgba(104, 24, 35, 1) 0%,
              rgba(84, 18, 29, 1) 50%
            );
            &-info {
              display: flex;
              align-items: stretch;
              justify-content: flex-start;
              max-width: 60%;
              width: 60%;
            }
            &-avatar {
              flex-shrink: 0;
            }
            &-name {
              display: flex;
              align-items: center;
              position: relative;
              margin-left: 15px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            &-stack {
              width: 30%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      .jackpots {
        height: 830px;
        width: 98%;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        padding: 10px;
        overflow-x: hidden;
        overflow-y: scroll;
        align-items: center;
        &::-webkit-scrollbar-thumb {
          background-color: transparent;
          color: rgba(black, 0.4);
        }
        .jackpot-header {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 80px;
          font-size: 34px;
          border-radius: 20px;
          background-color: rgb(rgb(54, 54, 54), 1);
          margin-bottom: 5px;
        }
        .jackpot {
          width: 590px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 120px;
          min-height: 120px;
          font-size: 32px;
          border-radius: 20px;
          margin-bottom: 10px;
          margin-left: 5px;
          background: linear-gradient(
            180deg,
            rgb(104, 24, 35) 0%,
            rgb(41 7 12) 50%
          );
          &-active {
            background: #a81422;
          }
          .table-list-canak-icon {
            transform: scale(1.5);
          }
          .jackpot-icon {
            font-weight: bold;
            font-family: "Arial", sans-serif !important;
            width: 205px;
            color: rgb(255, 255, 255);
            .table-list-canak-icon {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .jackpot-amount {
            width: 20%;
          }
          .jackpot-table-bet {
            width: 20%;
            font-weight: bold;
            font-family: "Arial", sans-serif !important;
            color: #f0e17d;
          }
          .jackpot-image {
            background-image: url("~@/assets/images/jackpots/turn-canak.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 65px;
            height: 65px;
          }
        }
        .active-line2 {
          background-position: 0px -924px;
        }
      }
      .v-spinner {
        margin-top: 100px;
      }
    }

    .table-list-items-outer {
      position: relative;
      width: 65%;
      height: 97%;
      margin-right: 20px;
      .table-list-headings {
        position: absolute;
        top: 5px;
        left: 0;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .line-icon {
          position: absolute;
          bottom: -10px;
          background-image: url("~@/assets/images/jackpots/line-icon.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 100%;
          height: 3px;
        }
        &-item {
          width: 230px;
          text-align: center;
          font-size: 32px;
          text-shadow: 0 3px 1px black;
        }
      }
    }

    .table-list-items {
      position: relative;
      top: 70px;
      width: 100%;
      min-height: 90%;
      max-height: 90%;
      overflow-x: hidden;
      overflow-y: auto;
      &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 115px;
        border-radius: 15px;
        background-color: rgba(black, 0.7);
        margin-bottom: 5px;
        min-width: 0;
        overflow: hidden;
        transition: all 0.25s linear;

        &-item:first-child {
          width: 94%;
          position: relative;
          left: 5px;
          bottom: 1px;
        }
        &-item {
          width: 100%;
          max-width: 100%;
          padding-bottom: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 36px;
          font-weight: bold;
          text-overflow: ellipsis;
          text-align: center;
          overflow: hidden;
          box-sizing: border-box;
          .loading-icon {
            margin-top: 300px;
            width: 50px;
            height: 50px;
            animation: rotatinganimation 3s linear infinite;
          }
          &.bets-column,
          &.min-max-column {
            width: 100%;
            overflow: visible;
            font-size: 36px;
          }

          .table-list-go-button {
            // width: 164px;
            // height: 73px;
            // border-radius: 10px;

            .table-full-button-text {
              font-size: 42px;
              color: #ffffff;
              font-weight: bold;
              line-height: 34px;
            }
          }
          .lang-ru {
            font-size: 26px;
          }
        }
        .players-green {
          position: relative;
          color: #ffffff;
          width: 90%;
          .bar {
            width: 150px;
            height: 40px;
            border-radius: 50px;
            background-color: #c7afb1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .fill-part {
              height: 100%;
              background-color: #0c9e41;
              border-bottom-left-radius: 50px;
              border-top-left-radius: 50px;
            }
          }
          .fill-text {
            position: absolute;
            left: 95px;
            bottom: 12px;
            font-size: 28px;
            z-index: 3;
          }
        }
        .players-red {
          width: 100%;
          color: #ffffff;
          position: relative;
          .bar {
            width: 150px;
            height: 40px;
            border-radius: 50px;
            background-color: #c7afb1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .fill-part {
              height: 100%;
              background-color: #980900;
              border-radius: 50px;
            }
          }
          .fill-text {
            position: absolute;
            left: 105px;
            bottom: 12px;
            font-size: 28px;
            z-index: 3;
          }
        }
      }
      .no-tables-found {
        position: relative;
        width: 100%;
        height: 100%;
        font-size: 36px;
        background-color: rgba(black, 0.6);
        text-shadow: 0 3px 2px black;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 10px;
        border-radius: 10px;
        box-sizing: border-box;
      }
    }
  }

  .table-list-filter {
    overflow: visible;
    position: absolute;
    left: 20px;
    bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    height: 115px;
    background: linear-gradient(180deg, rgb(104, 24, 35) 0%, rgb(41 7 12) 50%);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    .blind-filter-container {
      position: relative;
      width: 670px;
      margin-top: -5px;
      .blind-filter-items {
        position: absolute;
        left: -5px;
        bottom: 87px;
        transform: scaleY(0);
        width: 634px;
        height: 815px;
        overflow-x: hidden;
        overflow-y: auto;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        background-color: #28164a;
        box-shadow: 0 -2px 10px 0 rgba(black, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        &.show {
          transform: scaleY(1);
        }
        &-item {
          width: 595px;
          height: 75px;
          color: rgb(255, 255, 255);
          padding: 20px;
          text-shadow: 0 1px 3px black;
          text-align: center;
          font-size: 36px;
          font-weight: bold;
          border-bottom: 2px solid rgba(#614690, 0.8);
          &.selected {
            background-color: rgba(black, 0.1);
          }
          &:last-child {
            border-bottom: 0;
          }
        }
        .active-line2 {
          overflow: visible;
        }
      }
      .blind-filter-button {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        box-sizing: border-box;
        font-weight: bold;
        text-transform: uppercase;
        .dropdown-up-icon {
          margin-right: 30px;
        }
        .blind-filter-active {
          transform: rotate(180deg);
        }
      }
    }
    .state-filter-container {
      width: 62%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .state-filter-option-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .indicator {
          width: 60px;
          height: 60px;
          background-color: rgba(black, 0.5);
          border-radius: 10px;
          margin-right: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.25s ease;
        }
        .text {
          font-size: 36px;
          color: rgb(255, 255, 255);
          text-shadow: 0 2px 2px black;
        }
      }
    }
    .refresh-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 30px;
      .refresh-icon {
        margin-right: 30px;
        margin-top: 5px;
        transform: scale(1.4);
      }
      .rotating {
        animation-name: rotatinganimation;
        animation-iteration-count: 1;
        animation-duration: 3s;
        animation-delay: 0s;
        animation-fill-mode: forwards;
        animation-timing-function: ease;
      }
      .refresh-text {
        font-size: 30px;
      }
    }

    .create-table-container {
      width: 300px;
      .ui-button {
        width: 100%;
      }
    }
    .all-button {
      margin-left: 30px;
    }
  }
}
// .table-list-scene-title {
//   background-image: url("~@/assets/images/lobby/table-list-title.png");
//   width: 400px;
//   height: 220px;
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: contain;
// }
</style>
