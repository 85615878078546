<template>
  <div class="hand-strength-indicator" v-if="$props.winner > -1 && hand.name">
    <div class="hand-strength-indicator-inner">{{ $data.hand.name }}</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/event-bus";

import GameTypeConstants from "@/constants/game/poker/gameType";
import "lodash.combinations";
import _ from "lodash";
import PokerSolver from "pokersolver";

export default {
  name: "HandStrengthIndicator",
  props: {
    winner: {
      type: [Number, String],
      required: true,
      default: -1,
    },
    amount: {
      type: [Number, String],
      required: true,
      default: 0,
    },
  },
  watch: {
    "$props.winner": function (position) {
      if (position === -1) {
        this.setDefaultHandState();
        return false;
      }

      this.onWinnerChanged(position);
    },
  },
  computed: {
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
    }),
  },
  data() {
    return {
      hand: {
        name: "",
        player: {
          id: -1,
          name: "",
        },
      },
    };
  },
  methods: {
    setDefaultHandState() {
      this.hand.name = "";
      this.hand.player.id = -1;
      this.hand.player.name = "";
    },
    onWinnerChanged(position) {
      const seat = this.getActiveRoomState.players.find(
        (player) => player.position === position
      );
      if (typeof seat === "undefined") return false;

      const allCards = [...seat.cards, ...this.getActiveRoomState.cards];

      let hand = null;
      if (
        this.getActiveRoomState.stage === 5 &&
        !allCards.some((c) => c.index === -1) &&
        this.getActiveRoomState.players.filter((p) => p.in).length > 1
      ) {
        if (this.getActiveRoomState.gameType === GameTypeConstants.Omaha) {
          if (allCards.length >= 9)
            hand = this.evaluateOmahaHand(
              seat.cards,
              this.getActiveRoomState.cards
            );
        } else {
          // texas holdem
          if (allCards.length >= 7)
            hand = this.evaluateTexasHoldemHand(
              seat.cards,
              this.getActiveRoomState.cards
            );
        }
      }

      if (hand == null || typeof hand === "undefined") {
        this.emitChipsFlyingToWinner({
          position,
          seat,
          amount: this.$props.amount,
        });
        return false;
      }

      this.$emit("onHandShowing", {
        position,
        winnerCards: hand.cards.map((card) => card.value + card.suit),
      });

      this.emitChipsFlyingToWinner({
        position,
        hand,
        seat,
        amount: this.$props.amount,
      });

      this.hand.name = hand.descr.toUpperCase().replaceAll("İ", "I");
      this.hand.player.id = seat.id;
      this.hand.player.name = seat.name;
    },
    emitChipsFlyingToWinner({ position, hand, seat }) {
      this.$plugins.audio.play("Win");
      if (typeof hand === "undefined") {
        EventBus.$emit("onChipsFlyingToWinner", {
          position,
          amount: this.$props.amount,
          player: {
            id: seat.id,
            name: seat.name,
          },
        });
        return;
      }
      EventBus.$emit("onChipsFlyingToWinner", {
        position,
        hand: {
          name: hand.descr,
        },
        amount: this.$props.amount,
        winnerCards: hand.cards.map(({ suit, value }) => ({ suit, value })),
        player: {
          id: seat.id,
          name: seat.name,
        },
      });
    },
    cardStringToIndex(cardString) {
      const suits = ["s", "d", "c", "h"];
      const values = [
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "T",
        "J",
        "Q",
        "K",
        "A",
      ];

      const suit = suits.indexOf(cardString[1]);
      const value = values.indexOf(cardString[0]);

      return value + suit * 13;
    },
    evaluateOmahaHand(playerCards = [], tableCards = []) {
      tableCards = tableCards.map((card) =>
        (card.value + card.suit).replace(10, "T")
      );
      playerCards = playerCards.map((card) =>
        (card.value + card.suit).replace(10, "T")
      );

      const combinedCommunityCards = _(tableCards).combinations(3).value();
      const combinedPlayerCards = _(playerCards).combinations(2).value();

      const solvedHands = [];
      for (let i = 0; i < combinedCommunityCards.length; i++) {
        for (let j = 0; j < combinedPlayerCards.length; j++) {
          const solved = PokerSolver.Hand.solve([
            ...combinedCommunityCards[i],
            ...combinedPlayerCards[j],
          ]);
          solvedHands.push(solved);
        }
      }
      return PokerSolver.Hand.winners(solvedHands)[0];
    },
    evaluateTexasHoldemHand(playerCards = [], tableCards = []) {
      const allCards = [
        ...playerCards.map((card) => (card.value + card.suit).replace(10, "T")),
        ...tableCards.map((card) => (card.value + card.suit).replace(10, "T")),
      ];
      return PokerSolver.Hand.solve(allCards);
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes handStrengthIndicatorAnimation {
  0% {
    height: 0;
  }
  100% {
    height: 65px;
  }
}

.hand-strength-indicator {
  position: absolute;
  z-index: 11;
  top: 365px;
  width: 100%;
  overflow: hidden;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: handStrengthIndicatorAnimation;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-duration: 0.5s;
  &-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 0 100px;
    height: 65px;
    line-height: 65px;
    font-size: 48px;
    color: rgb(255, 255, 255);
    font-weight: bold;

    background: linear-gradient(
      90deg,
      transparent 0%,
      rgba(black, 0.5) 15%,
      black 30%,
      black 70%,
      rgba(black, 0.5) 85%,
      transparent 100%
    );
  }
}
</style>
