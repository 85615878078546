export default {

    /*
    *   Get preference by key
     */
    getValue(state) {
        return key => {
            const item = state.list.find(i => i.key == key);
            return item?.value || false
        }
    }

}