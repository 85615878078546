<template>
  <div class="settings-window">
    <div
      class="settings-window-outer"
      v-show="$store.state.modals.newSettingsWindow"
    >
      <div class="settings-window-inner">
        <div class="title-part profile-sprite light">
          <div class="title-text">{{ $t("blackJack.settings") }}</div>
          <div class="close-container" @click="closeWindow">
            <div class="close">
              <span class="sprite-table-pop-up exit-icon"></span>
            </div>
          </div>
        </div>
        <template v-for="(row, index) in gameSetting">
          <div
            class="game-settings"
            :key="`game-${index}`"
            :style="row.name === 'music' ? 'margin-top:120px' : ''"
            :class="row.name == 'sound' ? 'game-sound' : ''"
          >
            <span class="text">{{ $t(row.text) }}</span>
            <div class="slider-part">
              <div
                class="on-part"
                v-if="row.status"
                @click="settingsClick(row.name, !row.status, index)"
              >
                <div class="sprite-profile-popup button-green-s"></div>
                <span class="sprite-profile-popup circle-button"></span>
              </div>
              <div
                class="off-part"
                v-else
                @click="settingsClick(row.name, !row.status, index)"
              >
                <div class="sprite-profile-popup button-red-s"></div>
                <span class="sprite-profile-popup circle-button left"></span>
              </div>
            </div>
            <span class="sprite-table-pop-up red-line"></span>
          </div>
        </template>

        <div class="table-color">
          <span class="text">{{ $t("settings.tableColor") }}</span>
          <div class="options-container">
            <div class="option option-1" @click="tableColor = 'red'">
              <span class="ok-icon" v-if="tableColor === 'red'"></span>
            </div>
            <div class="option option-2" @click="tableColor = 'green'">
              <span class="ok-icon" v-if="tableColor === 'green'"></span>
            </div>
            <div class="option option-3" @click="tableColor = 'blue'">
              <span class="ok-icon" v-if="tableColor === 'blue'"></span>
            </div>
            <div class="option option-4" @click="tableColor = 'purple'">
              <span class="ok-icon" v-if="tableColor === 'purple'"></span>
            </div>
          </div>
        </div>
        <template v-for="(value, index) in rowValue">
          <a
            target="_blank"
            :href="
              value.name === 'privacyPolicy'
                ? 'https://turngs.com/privacy-policy/'
                : value.name === 'term-of-use'
                ? 'https://turngs.com/terms-of-use/'
                : '#'
            "
            v-if="
              value.name === 'privacyPolicy' || value.name === 'term-of-use'
            "
            :key="`link-item-${index}`"
          >
            <div
              class="row"
              :key="`s-item-${index}`"
              @click="openWindow(value.name)"
            >
              <span>{{ $t(value.text) }}</span>
            </div>
          </a>
          <div
            class="row"
            :key="`s-item-${index}`"
            @click="openWindow(value.name)"
            v-else
          >
            <span>{{ $t(value.text) }}</span>
          </div>
          <div
            class="account-container"
            v-if="value.name == 'account'"
            v-show="openedContent === 'account'"
            :key="`account-${index}`"
          >
            <div class="profile-id">
              <div class="title">
                <span>{{ $t("settings.profileID") }}</span>
              </div>
              <div class="text">{{ user.id }}</div>
            </div>
            <div class="device-no">
              <div class="title">{{ $t("settings.deviceNO") }}</div>
              <div class="text">N/A</div>
            </div>
            <div class="delete-acount-container">
              <div class="delete-title">{{ $t("settings.deleteAccount") }}</div>
              <button
                class="ui-button ui-button-small-red delete-account"
                @click="deleteButtonClick"
              >
                <span>{{ $t("settings.deleteAccount") }}</span>
              </button>
            </div>
          </div>
          <div
            class="lang-container"
            v-if="value.name == 'lang-options'"
            v-show="openedContent === 'lang-options'"
            :key="`lang-${index}`"
          >
            <button class="selector-button" @click="langClick">
              <div class="select-items">
                <div class="flag">
                  <div class="en-flag" v-if="lang.flag === 'en'"></div>
                  <span
                    v-else
                    class="sprite-profile-popup"
                    :class="`${lang.flag}-flag`"
                  ></span>
                </div>
                <span class="text">{{ $t(lang.text) }}</span>
                <span class="sprite-profile-popup language-ok"></span>
              </div>
            </button>
            <div
              class="selector-container"
              v-if="langContainerValue"
              v-click-outside="onClickedOutside"
            >
              <template v-for="(langItem, langIndex) in langs">
                <div
                  v-if="lang !== langItem"
                  class="item no-click-outside"
                  :key="langIndex"
                  @click="selectLanguage(langItem)"
                >
                  <span
                    class="sprite-profile-popup nation"
                    :class="`${langItem.flag}-flag`"
                    v-if="langItem.flag !== 'en'"
                  ></span>
                  <span class="en-flag" v-else></span>
                  <span class="option-text" :class="`${langItem.class}`">{{
                    $t(langItem.text)
                  }}</span>
                </div>
              </template>
            </div>
          </div>
        </template>
        <span class="version">{{ getApplicationVersion }}</span>
      </div>
    </div>
    <ConfirmWindow
      v-if="showConfirmWindow"
      :show="showConfirmWindow"
      @closeConfirm="closeConfirmWindow"
      @confirm="logOut"
      :titleText="$t('general.logout')"
    />
    <AreYouSureWindow
      v-if="deleteDialog"
      :show="deleteDialog"
      @closeAreYouSure="areYouSureClose"
      @confirm="deleteAccount"
      :titleText="$t('settings.deleteAccount')"
    />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import AppConstants from "@/constants/app";
import ConfirmWindow from "./ConfirmWindow";
import StorageManager from "@/plugins/storage-manager";
import { mapState, mapGetters } from "vuex";

export default {
  name: "NewSettingsWindow",
  directives: { ClickOutside },
  components: {
    XmarkIcon: () => import("@/components/Icons/Xmark"),
    ConfirmWindow,
    AreYouSureWindow: () => import("./AreYouSureWindow"),
  },
  watch: {
    "preferences.gifts": {
      handler(newVal) {
        this.$store.dispatch("preferences/set", {
          key: "animated_gifts",
          value: newVal ? "1" : "0",
        });
        this.preferences.gifts = newVal;
      },
    },
    "preferences.music": {
      handler(newVal) {
        this.$store.dispatch("preferences/set", {
          key: "music",
          value: newVal ? "1" : "0",
        });
        this.$store.state.preferences.musicValue = newVal;
      },
    },
    "preferences.sound": {
      handler(newVal) {
        this.$store.dispatch("preferences/set", {
          key: "sounds",
          value: newVal ? "1" : "0",
        });
        this.$store.state.preferences.soundValue = newVal;
      },
    },
    "preferences.vibration": {
      handler(newVal) {
        this.$store.dispatch("preferences/set", {
          key: "vibration",
          value: newVal ? "1" : "0",
        });
      },
    },
    "preferences.block_invite": {
      handler(newVal) {
        this.$store.state.user.invitation = newVal;
        // this.$store.dispatch("preferences/set", {
        //   key: "block_invite",
        //   value: newVal ? true : false,
        // });
      },
    },
    // "preferences.block_notification": {
    //   handler(newVal) {
    //     console.log("block_notification newVal", newVal);
    //     this.$store.dispatch("preferences/set", {
    //       key: "block_notification",
    //       value: newVal ? "1" : "0",
    //     });
    //   },
    //   immediate: true,
    // },
    async tableColor(newVal) {
      await this.$store.dispatch("preferences/setBatch", [
        {
          key: "table_background",
          value: newVal,
        },
      ]);
    },
    "$store.state.modals.newSettingsWindow": {
      handler(val) {
        if (val) {
          this.initialize();
        }
      },
      immediate: true,
    },
  },
  data(vm) {
    return {
      tableColor: "red",
      openedContent: "",
      langContainerValue: false,
      showConfirmWindow: false,
      facebookLogoutCount: 0,
      deleteDialog: false,
      preferences: {
        block_invite: true,
        music: false,
        sound: false,
        vibration: false,
        block_notification: false,
        gifts: false,
        hand_power: false,
      },
      lang: {
        text: vm.$t("settings.language.turkish"),
        value: "turkish",
        flag: "tr",
      },
      langs: [
        {
          text: "settings.language.english",
          value: "english",
          flag: "en",
          class: "en",
        },
        {
          text: "settings.language.turkish",
          value: "turkish",
          flag: "tr",
          class: "tr",
        },
        {
          text: "settings.language.italian",
          value: "italian",
          flag: "it",
          class: "it",
        },
        {
          text: "settings.language.indonesian",
          value: "indonesian",
          flag: "id",
          class: "id",
        },
        {
          text: "settings.language.portuguese",
          value: "portuguese",
          flag: "pt",
          class: "pt",
        },

        {
          text: "settings.language.russian",
          value: "russian",
          flag: "ru",
          class: "ru",
        },
        {
          text: "settings.language.malesian",
          value: "malesian",
          flag: "ms",
          class: "ms",
        },
      ],
      rowValue: [
        { name: "lang-options", text: "settings.languagePreference" },
        { name: "case-window", text: "general.case" },
        { name: "security", text: "settingsWindow.security" },
        { name: "game-history", text: "blackJack.gameHistory" },
        { name: "rules", text: "blackJack.rules" },
        { name: "term-of-use", text: "settings.termsOfUse" },
        { name: "privacyPolicy", text: "authentication.privacyPolicy" },
        { name: "account", text: "settings.accountInfo" },
        { name: "logout", text: "general.logout" },
      ],
      gameSetting: [
        // { name: "music", text: "settings.lobbyMusic", status: false },
        {
          name: "sound",
          text: "settings.soundEffects",
          status: false,
        },
        // {
        //   name: "vibration",
        //   text: "settings.vibration",
        //   status: false,
        // },
        {
          name: "block_invite",
          text: "settings.blockInvites",
          status: true,
        },
        {
          name: "block_notification",
          text: "settingsWindow.gameSettingsWindow.blockNotification",
          status: false,
        },
        // {
        //   name: "hand_power",
        //   text: "settings.handStrengthIndicator",
        //   status: false,
        // },
        {
          name: "gifts",
          text: "settings.giftHide",
          status: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["preferences/getValue"]),
    ...mapGetters({
      getPreferenceValue: "preferences/getValue",
    }),
    getUserLang() {
      return this["preferences/getValue"]("language");
    },
    getApplicationVersion() {
      return `v${AppConstants.applicationVersion}`;
    },
  },
  methods: {
    async saveTableColor(color) {
      await this.$store.dispatch("preferences/setBatch", [
        {
          key: "table_background",
          value: color,
        },
      ]);
    },
    langClick() {
      this.langContainerValue = !this.langContainerValue;
    },
    async selectLanguage(langItem) {
      this.lang = langItem;
      this.langContainerValue = false;

      await this.$store.dispatch("preferences/set", {
        key: "language",
        value: langItem.flag,
      });
    },
    settingsClick(name, status, index) {
      if (name === "block_invite") {
        this.$store.state.user.invitation = status;
      }
      this.preferences[name] = status;
      this.gameSetting[index].status = status;

      if (name == "block_notification") {
        this.$store.dispatch("preferences/set", {
          key: "block_notification",
          value: status ? "1" : "0",
        });
      }
    },
    async toggleSoundsPreference(status) {
      this.$plugins.audio.play("Button");
      await this.$store.dispatch("preferences/set", {
        key: "sounds",
        value: status ? "1" : "0",
      });
      // this.$plugins.haptics.selectionChanged();
    },
    onClickedOutside($event) {
      const excludedClassNames = ["item"];

      if (
        excludedClassNames.filter((excludedClassName) =>
          $event?.target?.className.includes(excludedClassName)
        ).length > 0 ||
        $event.path.filter((item) => item.className === "selector-button")
          .length > 0
      ) {
        return false;
      }
      if (this.langContainerValue === false) return;
      this.langContainerValue = false;
    },
    openWindow(value) {
      if (value == "security") {
        this.$store.commit("modals/show", "security");
        this.$adjust.trackEvent("ClickGoToSecurity");
        this.closeWindow();
      } else if (value == "case-window") {
        this.$store.commit("modals/show", "userCase");
        this.$adjust.trackEvent("ClickGoToCase");
        this.closeWindow();
      } else if (value === "rules") {
        this.$store.commit("modals/show", "rules");
        this.$adjust.trackEvent("ClickGoToRules");
        this.closeWindow();
      } else if (value === this.openedContent) {
        this.openedContent = "";
        return false;
      } else if (value === "game-history") {
        this.$store.commit("modals/show", "history");
        this.closeWindow();
        this.$adjust.trackEvent("ClickGoToHandHistory");
        // this.$data.show = false;
      } else {
        this.openedContent = value;
        if (this.openedContent === "logout") {
          this.showConfirmWindow = true;
          this.openedContent = "";
          this.$store.commit("modals/hide", "newSettingsWindow");
          return false;
        } else if (this.openedContent === "account") {
          return false;
        } else if (this.openedContent === "rules") {
          this.$store.commit("modals/show", "rules");
          this.$plugins.audio.play("Button");
          this.closeWindow();
        }
      }
    },
    async logOut() {
      await this.$store.dispatch("authentication/logout");
      this.showConfirmWindow = false;
      if (
        this.$parent &&
        this.$parent.$parent &&
        this.$parent.$parent.$refs.dynamicComponent
      ) {
        this.$parent.$parent.$refs.dynamicComponent.autoLoginProcessing = false;
      }
      this.$adjust.trackEvent("ClickLogout");
    },
    // async logOut() {
    //   try {
    //     if (
    //       this.$store.state.authentication.authType === "facebook" &&
    //       this.facebookLogoutCount < 1
    //     ) {
    //       this.facebookLogoutCount++;
    //       await FacebookLogin.logout();
    //     }

    //     this.$plugins.audio.play("Button");
    //     this.$plugins?.audio?.stop("Lobby1");
    //     await StorageManager.instance.clear();

    //     if (window.__notifyOnlineInterval)
    //       clearInterval(window.__notifyOnlineInterval);
    //     setTimeout(() => {
    //       location.reload();
    //     }, 1000);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    closeWindow() {
      this.$store.commit("modals/hide", "newSettingsWindow");
      this.$plugins?.audio?.play("button_close_new");
    },
    closeConfirmWindow() {
      this.$plugins.audio.play("Button");
      this.$data.showConfirmWindow = false;
      this.openedContent = "";
    },
    async deleteAccount() {
      const res = await this.$store.dispatch("user/deleteAccount", {
        accountType: this.$store.state.authentication.authType,
      });
      if (res) {
        this.$plugins.toast.show({
          text: this.$t("deleteConfirmWindow.deleteSuccessfully"),
          duration: 2000,
          variant: "success",
        });
        this.deleteDialog = false;
      } else {
        this.$plugins.toast.show({
          text: this.$t("deleteConfirmWindow.notBeDeleted"),
          duration: 2000,
          variant: "danger",
        });
      }
    },
    areYouSureClose() {
      this.deleteDialog = false;
      this.openedContent = "";
    },
    deleteButtonClick() {
      this.deleteDialog = true;
      this.openedContent === "";
      this.$store.commit("modals/hide", "newSettingsWindow");
    },
    settingWindowOpening() {
      this.preferences.sound = this.getPreferenceValue("sounds") == "1";
    },
    initialize() {
      this.$store.state.preferences.musicValue =
        this.getPreferenceValue("music") == "1";
      this.$store.state.preferences.soundValue =
        this.getPreferenceValue("sounds") == "1";
      // this.preferences.gifts = this.getPreferenceValue("animated_gifts") == "1";
      // console.log("this.preferences.gifts", this.preferences.gifts);

      this.preferences.music = this.getPreferenceValue("music") == "1";
      this.preferences.sound = this.getPreferenceValue("sounds") == "1";
      this.tableColor =
        this.getPreferenceValue("table_background") === "black"
          ? "red"
          : this.getPreferenceValue("table_background");

      this.gameSetting.forEach((setting) => {
        if (setting.name === "music") {
          setting.status = this.preferences.music;
        } else if (setting.name === "sound") {
          setting.status = this.preferences.sound;
        } else if (setting.name === "vibration") {
          setting.status = this.getPreferenceValue("vibration") == "1";
        }
        // else if (setting.name === "block_invite") {
        //   setting.status = this.getPreferenceValue("block_invite") == false;
        // }
        else if (setting.name === "block_notification") {
          setting.status = this.getPreferenceValue("block_notification") == "1";
        } else if (setting.name === "hand_power") {
          setting.status = this.getPreferenceValue("hand_power") == "1";
        } else if (setting.name === "gifts") {
          setting.status = this.getPreferenceValue("animated_gifts") == "1";
        }
      });

      this.lang.flag = this["preferences/getValue"]("language");
      this.lang.text =
        this.lang.flag === "tr"
          ? "Türkçe"
          : this.lang.flag === "en"
          ? "English"
          : this.lang.flag === "pt"
          ? "Portuguese"
          : this.lang.flag === "ru"
          ? "Русский"
          : this.lang.flag === "ms"
          ? "Melayu"
          : this.lang.flag === "it"
          ? "Italian"
          : this.lang.flag === "id"
          ? "Bahasa Indonesia"
          : "";
    },
    openHistory(item) {
      this.$store.commit("modals/show", "history");
      this.$plugins.audio.play("Button");
      // this.$data.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
.settings-window {
  position: relative;
  right: 0;
  top: 0;
  .settings-window-outer {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.5);

    .settings-window-inner {
      position: relative;
      right: 320px;
      width: 700px;
      height: 1080px;
      background: #3b0b14;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;

      .title-part {
        position: fixed;
        width: 27%;
        height: 120px;
        top: 178px;
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: #3b0b14;
        z-index: 2;
        .close-container {
          position: absolute;
          z-index: 3;
          top: 15px;
          right: 15px;
          height: 90px;
          width: 90px;
          cursor: pointer;
          .close {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
            top: 14px;
            right: 15px;
            width: 65px;
            height: 65px;
            border-radius: 100%;
            border: 3px solid #cf707f;
            cursor: pointer;

            transition: all 0.25s ease;
            &:active {
              filter: brightness(60%);
              -webkit-filter: brightness(60%);
            }
            .exit-icon {
              transform: scale(0.7);
            }
          }
        }

        .title-text {
          text-shadow: 3px 5px 3px black;
          font-size: 56px;
        }
      }
      .game-sound {
        margin-top: 120px;
      }
      .game-settings {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100px;
        min-height: 100px;

        .text {
          font-size: 36px;
          padding-left: 50px;
        }
        .red-line {
          position: absolute;
          bottom: 0;
          left: 50px;
          transform: scale(1.2);
        }
        .slider-part {
          position: relative;
          cursor: pointer;
          margin-right: 20px;
          .circle-button {
            position: absolute;
            right: -2px;
            top: -5px;
            &.left {
              left: -3px;
            }
          }
        }
      }
      .table-color {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100px;
        min-height: 100px;
        .text {
          font-size: 36px;
          padding-left: 50px;
        }
        .options-container {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .option {
            position: relative;
            width: 60px;
            height: 60px;
            border-radius: 10px;
            cursor: pointer;
            &-1 {
              background: #952523;
            }
            &-2 {
              background: #397446;
            }
            &-3 {
              background: #294d97;
            }
            &-4 {
              background: #9f3b61;
            }
            .ok-icon {
              background-image: url("~@/assets/images/lobby/black-tick.png");
              background-repeat: no-repeat;
              background-size: contain;
              width: 50px;
              height: 50px;
              position: absolute;
              left: 7px;
              top: 7px;
            }
          }
        }
      }
      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100px;
        min-height: 100px;
        background: linear-gradient(
          180deg,
          rgba(146, 38, 51, 1) 0%,
          rgba(92, 18, 28, 1) 50%
        );
        border-top: 1px solid #7b1d28;
        border-bottom: 1px solid #7a1d28;
        cursor: pointer;
        color: white;
        text-decoration: none;
        span {
          font-size: 36px;
          padding-left: 50px;
        }
      }
      .row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100px;
        min-height: 100px;
        background: linear-gradient(
          180deg,
          rgba(146, 38, 51, 1) 0%,
          rgba(92, 18, 28, 1) 50%
        );
        border-top: 1px solid #7b1d28;
        border-bottom: 1px solid #7a1d28;
        cursor: pointer;
        span {
          font-size: 36px;
          padding-left: 50px;
        }

        // &:hover,
        // &:focus {
        //   filter: brightness(0.8);
        // }
      }
      .lang-container {
        width: 100%;
        height: 150px;
        min-height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        .selector-button {
          position: absolute;
          left: 160px;
          width: 380px;
          height: 80px;
          border-radius: 50px;
          background: #f1872f;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          z-index: 2;
          .select-items {
            width: 90%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .text {
              font-size: 32px;
              line-height: 35px;
              color: white;
              font-weight: bold;
              text-transform: uppercase;
              text-shadow: 3px 3px 3px black;
            }
            .language-ok {
              transform: scale(0.8);
            }
            .en-flag {
              background: url("~@/assets/images/flags/GB.png") no-repeat 50% 50%;
              background-size: contain;
              width: 70px;
              height: 70px;
              position: absolute;
              left: 20px;
              bottom: 3px;
            }
          }
        }
        .selector-container {
          position: relative;
          left: 0px;
          top: 290px;
          background: #cfb1b5;
          width: 350px;
          height: 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          border-radius: 10px;
          border: 1px solid black;
          z-index: 2;
          .item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 80px;
            border-bottom: 1px solid #8d323d;
            margin-top: 3px;
            cursor: pointer;
            .en-flag {
              background: url("~@/assets/images/flags/GB.png") no-repeat 50% 50%;
              background-size: contain;
              width: 70px;
              height: 70px;
              position: absolute;
              left: 15px;
            }
            &:last-child {
              border-bottom: none;
            }
            .option-text {
              font-size: 36px;
              margin-left: 80px;
              color: black;
              text-transform: uppercase;
            }
            .nation {
              position: absolute;
              left: 20px;
            }
          }
        }
      }
      .account-container {
        position: relative;
        width: 100%;
        height: 500px;
        min-height: 500px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        .profile-id {
          width: 100%;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          margin-bottom: 50px;
          margin-top: 50px;
          .title {
            font-weight: bold;
            color: #cf707f;
            text-shadow: 3px 3px 3px black;
            font-size: 36px;
            text-transform: uppercase;
          }
          .text {
            color: white;
            font-size: 32px;
          }
        }
        .device-no {
          width: 100%;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .title {
            font-weight: bold;
            color: #cf707f;
            text-shadow: 3px 3px 3px black;
            font-size: 36px;
            text-transform: uppercase;
          }
          .text {
            color: white;
            font-size: 32px;
          }
        }
        .delete-acount-container {
          width: 100%;
          height: 150px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          margin-bottom: 50px;
          margin-top: 50px;
          .delete-title {
            font-weight: bold;
            color: #cf707f;
            text-shadow: 3px 3px 3px black;
            font-size: 36px;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          .delete-account {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 24px;
            font-weight: bold;
            text-transform: uppercase;
            text-shadow: 3px 3px 3px black;
            &:active,
            &:hover,
            &:focus {
              filter: brightness(0.8);
            }
          }
        }
      }
      .version {
        width: 100%;
        height: 60px;
        min-height: 60px;
        font-size: 40px;
        margin-right: 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
</style>
