import { render, staticRenderFns } from "./GameButtons.vue?vue&type=template&id=6a0b6b8d&scoped=true&"
import script from "./GameButtons.vue?vue&type=script&lang=js&"
export * from "./GameButtons.vue?vue&type=script&lang=js&"
import style0 from "./GameButtons.vue?vue&type=style&index=0&id=6a0b6b8d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0b6b8d",
  null
  
)

export default component.exports