<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1450"
    height="950"
    :show="true"
    :titleText="$t('blackJack.rules')"
  >
    <template>
      <div class="rules-window">
        <div class="tab-selectors">
          <div
            class="tab-selector texas"
            @click="handleTabs('texas')"
            :class="currentTab === 'texas' ? 'active' : ''"
          >
            <p>TEXAS HOLD'EM</p>
          </div>
          <div
            class="tab-selector omaha"
            @click="handleTabs('omaha')"
            :class="currentTab === 'omaha' ? 'active' : ''"
          >
            <p>OMAHA</p>
          </div>
          <div
            class="tab-selector blackjack"
            @click="handleTabs('blackjack')"
            :class="currentTab === 'blackjack' ? 'active' : ''"
          >
            <p>BLACKJACK</p>
          </div>
          <!-- <div
            class="tab-selector domino"
            @click="handleTabs('domino')"
            :class="currentTab === 'domino' ? 'active' : ''"
          >
            <p>DOMİNO</p>
          </div> -->
        </div>
        <div class="content-section">
          <div
            class="inner-content texas-content"
            v-if="currentTab === 'texas'"
          >
            <p class="description">{{ $t("rulesWindow.texasDesc") }}</p>
            <hr />
            <div class="hands">
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.royalFlush") }}
              </h2>
              <p class="hand-desc">
                {{ $t("rulesWindow.handDesc.royalFlush") }}
              </p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.straightFlush") }}
              </h2>
              <p class="hand-desc">
                {{ $t("rulesWindow.handDesc.straightFlush") }}
              </p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.fourOfAKind") }}
              </h2>
              <p class="hand-desc">
                {{ $t("rulesWindow.handDesc.fourOfAKind") }}
              </p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.fullHouse") }}
              </h2>
              <p class="hand-desc">
                {{ $t("rulesWindow.handDesc.fullHouse") }}
              </p>
              <h2 class="hand-name">{{ $t("rulesWindow.handNames.flush") }}</h2>
              <p class="hand-desc">{{ $t("rulesWindow.handDesc.flush") }}</p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.straight") }}
              </h2>
              <p class="hand-desc">{{ $t("rulesWindow.handDesc.straight") }}</p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.threeOfAKind") }}
              </h2>
              <p class="hand-desc">
                {{ $t("rulesWindow.handDesc.threeOfAKind") }}
              </p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.twoPair") }}
              </h2>
              <p class="hand-desc">{{ $t("rulesWindow.handDesc.twoPair") }}</p>
              <h2 class="hand-name">{{ $t("rulesWindow.handNames.pair") }}</h2>
              <p class="hand-desc">{{ $t("rulesWindow.handDesc.pair") }}</p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.highCard") }}
              </h2>
              <p class="hand-desc">{{ $t("rulesWindow.handDesc.highCard") }}</p>
            </div>
            <hr />
            <div class="expressions">
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.blinds.heading") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.blinds.desc") }}
              </p>
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.ante.heading") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.ante.desc") }}
              </p>
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.minimumRaise.heading") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.minimumRaise.desc") }}
              </p>
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.allIn.heading") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.allIn.desc") }}
              </p>
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.potSplit.heading") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.potSplit.desc") }}
              </p>
            </div>
            <hr />
            <div class="actions">
              <div class="desc">{{ $t("rulesWindow.actions.desc") }}</div>
              <h2 class="action-name">
                {{ $t("rulesWindow.actions.call.heading") }}
              </h2>
              <p class="action-desc">
                {{ $t("rulesWindow.actions.call.desc") }}
              </p>
              <h2 class="action-name">
                {{ $t("rulesWindow.actions.check.heading") }}
              </h2>
              <p class="action-desc">
                {{ $t("rulesWindow.actions.check.desc") }}
              </p>
              <h2 class="action-name">
                {{ $t("rulesWindow.actions.raise.heading") }}
              </h2>
              <p class="action-desc">
                {{ $t("rulesWindow.actions.raise.desc") }}
              </p>
              <h2 class="action-name">
                {{ $t("rulesWindow.actions.fold.heading") }}
              </h2>
              <p class="action-desc">
                {{ $t("rulesWindow.actions.fold.desc") }}
              </p>
            </div>
            <hr />
            <div class="states">
              <h2 class="state-name">
                {{ $t("rulesWindow.states.winning.heading") }}
              </h2>
              <p class="state-desc">
                {{ $t("rulesWindow.states.winning.desc") }}
              </p>
              <h2 class="state-name">
                {{ $t("rulesWindow.states.aggressivePlayer.heading") }}
              </h2>
              <p class="state-desc">
                {{ $t("rulesWindow.states.aggressivePlayer.desc") }}
              </p>
              <h2 class="state-name">
                {{ $t("rulesWindow.states.allInPlayer.heading") }}
              </h2>
              <p class="state-desc">
                {{ $t("rulesWindow.states.allInPlayer.desc") }}
              </p>
              <div class="desc">{{ $t("rulesWindow.states.desc") }}</div>
            </div>
          </div>
          <div
            class="inner-content omaha-content"
            v-if="currentTab === 'omaha'"
          >
            <p class="description">{{ $t("rulesWindow.omahaDesc") }}</p>
            <hr />
            <div class="hands">
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.royalFlush") }}
              </h2>
              <p class="hand-desc">
                {{ $t("rulesWindow.handDesc.royalFlush") }}
              </p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.straightFlush") }}
              </h2>
              <p class="hand-desc">
                {{ $t("rulesWindow.handDesc.straightFlush") }}
              </p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.fourOfAKind") }}
              </h2>
              <p class="hand-desc">
                {{ $t("rulesWindow.handDesc.fourOfAKind") }}
              </p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.fullHouse") }}
              </h2>
              <p class="hand-desc">
                {{ $t("rulesWindow.handDesc.fullHouse") }}
              </p>
              <h2 class="hand-name">{{ $t("rulesWindow.handNames.flush") }}</h2>
              <p class="hand-desc">{{ $t("rulesWindow.handDesc.flush") }}</p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.straight") }}
              </h2>
              <p class="hand-desc">{{ $t("rulesWindow.handDesc.straight") }}</p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.threeOfAKind") }}
              </h2>
              <p class="hand-desc">
                {{ $t("rulesWindow.handDesc.threeOfAKind") }}
              </p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.twoPair") }}
              </h2>
              <p class="hand-desc">{{ $t("rulesWindow.handDesc.twoPair") }}</p>
              <h2 class="hand-name">{{ $t("rulesWindow.handNames.pair") }}</h2>
              <p class="hand-desc">{{ $t("rulesWindow.handDesc.pair") }}</p>
              <h2 class="hand-name">
                {{ $t("rulesWindow.handNames.highCard") }}
              </h2>
              <p class="hand-desc">{{ $t("rulesWindow.handDesc.highCard") }}</p>
            </div>
            <hr />
            <div class="expressions">
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.blinds.heading") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.blinds.desc") }}
              </p>
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.ante.heading") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.ante.desc") }}
              </p>
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.minimumRaise.heading") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.minimumRaise.desc") }}
              </p>
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.allIn.heading") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.allIn.desc") }}
              </p>
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.potSplit.heading") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.potSplit.desc") }}
              </p>
            </div>
            <hr />
            <div class="actions">
              <div class="desc">{{ $t("rulesWindow.actions.desc") }}</div>
              <h2 class="action-name">
                {{ $t("rulesWindow.actions.call.heading") }}
              </h2>
              <p class="action-desc">
                {{ $t("rulesWindow.actions.call.desc") }}
              </p>
              <h2 class="action-name">
                {{ $t("rulesWindow.actions.check.heading") }}
              </h2>
              <p class="action-desc">
                {{ $t("rulesWindow.actions.check.desc") }}
              </p>
              <h2 class="action-name">
                {{ $t("rulesWindow.actions.raise.heading") }}
              </h2>
              <p class="action-desc">
                {{ $t("rulesWindow.actions.raise.desc") }}
              </p>
              <h2 class="action-name">
                {{ $t("rulesWindow.actions.fold.heading") }}
              </h2>
              <p class="action-desc">
                {{ $t("rulesWindow.actions.fold.desc") }}
              </p>
            </div>
            <hr />
            <div class="states">
              <h2 class="state-name">
                {{ $t("rulesWindow.states.winning.heading") }}
              </h2>
              <p class="state-desc">
                {{ $t("rulesWindow.states.winning.desc") }}
              </p>
              <h2 class="state-name">
                {{ $t("rulesWindow.states.aggressivePlayer.heading") }}
              </h2>
              <p class="state-desc">
                {{ $t("rulesWindow.states.aggressivePlayer.desc") }}
              </p>
              <h2 class="state-name">
                {{ $t("rulesWindow.states.allInPlayer.heading") }}
              </h2>
              <p class="state-desc">
                {{ $t("rulesWindow.states.allInPlayer.desc") }}
              </p>
              <div class="desc">{{ $t("rulesWindow.states.desc") }}</div>
            </div>
          </div>
          <div
            class="inner-content blackjack-content"
            v-if="currentTab === 'blackjack'"
          >
            <p class="description">{{ $t("rulesWindow.blackJackDesc") }}</p>
            <div class="expressions">
              <hr />
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.bjGameRules.heading") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.bjGameRules.desc") }}
              </p>
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.bjGameRules.headingInsurance") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.bjGameRules.descInsurance") }}
              </p>
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.bjGameRules.headingHit") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.bjGameRules.descHit") }}
              </p>
              <h2 class="exp-name">
                {{ $t("rulesWindow.expressions.bjGameRules.headingSplit") }}
              </h2>
              <p class="exp-desc">
                {{ $t("rulesWindow.expressions.bjGameRules.descSplit") }}
              </p>
            </div>
            <hr />
            <div class="states">
              <h2 class="state-name">
                {{ $t("rulesWindow.states.blackJackResult.heading") }}
              </h2>
              <p class="state-desc">
                {{ $t("rulesWindow.states.blackJackResult.desc") }}
              </p>
            </div>
          </div>
          <!-- <div class="inner-content tournament-content" v-show="currentTab === 'tournament'">

                    </div>
                    <div class="inner-content bandar-content" v-show="currentTab === 'bandar'">

                    </div>
                    <div class="inner-content slots-content" v-show="currentTab === 'slot'">

                    </div> -->
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";
import AnalyticsEvents from "@/plugins/analytics/events";

export default {
  name: "RulesWindow",
  data() {
    return {
      currentTab: "texas",
    };
  },
  components: {
    BaseWindow,
  },
  methods: {
    onShowRulesWindow() {
      //
    },
    closeWindow() {
      this.$store.commit("modals/hide", "rules");
    },
    handleTabs(tab) {
      this.$data.currentTab = tab;
    },
  },
  mounted() {
    this.onShowRulesWindow();
  },
};
</script>

<style lang="scss" scoped>
.rules-window {
  position: relative;
  width: 100%;
  height: 87%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .tab-selectors {
    width: 85%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    background: #83212d;
    box-shadow: inset 1px 3px 8px black;
    border-radius: 10px;
    .tab-selector {
      width: 100%;
      max-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 3px solid #902533;
      padding: 5px 0;
      &:last-child {
        border-right: none;
      }
      p {
        font-size: 32px;
        font-weight: bold;
      }
      &.active,
      &:active {
        top: -10px;
        height: 60px;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(241, 138, 48, 1) 0%,
          rgba(233, 107, 44, 1) 50%
        );
        border-radius: 10px;
        border: none;
      }
    }
  }
  .content-section {
    width: 98%;
    height: 87%;
    background: #83212d;
    border-radius: 20px;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    .inner-content {
      hr {
        margin: 30px 0;
        border: 2px solid #cf707f;
      }
      padding: 40px;
      &.texas-content,
      &.blackjack-content,
      &.omaha-content {
        .description {
          font-size: 40px;
          line-height: 50px;
        }
        .hands {
          .hand-name {
            font-size: 35px;
            line-height: 40px;
            font-weight: bold;
            margin: 30px 0;
          }
          .hand-desc {
            font-size: 30px;
            line-height: 35px;
          }
        }
        .expressions {
          .exp-name {
            font-size: 35px;
            line-height: 40px;
            font-weight: bold;
            margin: 30px 0;
          }
          .exp-desc {
            font-size: 30px;
            line-height: 35px;
          }
        }
        .actions {
          .desc {
            margin-top: 30px;
            font-size: 40px;
            line-height: 50px;
          }
          .action-name {
            font-size: 35px;
            line-height: 40px;
            font-weight: bold;
            margin: 30px 0;
          }
          .action-desc {
            font-size: 30px;
            line-height: 35px;
          }
        }
        .states {
          .desc {
            margin-top: 30px;
            font-size: 40px;
            line-height: 50px;
          }
          .state-name {
            font-size: 35px;
            line-height: 40px;
            font-weight: bold;
            margin: 30px 0;
          }
          .state-desc {
            font-size: 30px;
            line-height: 35px;
          }
        }
      }
    }
  }
}
</style>
