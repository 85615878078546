<template>
  <div
    v-if="getActiveRoomState.progressiveJackpot.status"
    class="progressive-jackpot jackpot-window-trigger"
    :class="[getRealPlatform === 'android' ? 'no-flick' : '']"
  >
    <div class="jackpot-progressive sprite-table pjp"></div>
    <div class="amount" v-if="getProgressiveJackpot">
      {{ getShortChipFormat(getProgressiveJackpot.amount) }}
    </div>
    <!-- <div class="type-text">PROGRESSIVE</div> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ProgressiveJackpotTableIndicator",
  computed: {
    ...mapState(["jackpots"]),
    ...mapGetters(["getShortChipFormat", "getRealPlatform"]),
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
    }),
    getProgressiveJackpot() {
      const jackpot = this.jackpots.list.find(
        (jackpot) => jackpot.type === "progressive"
      );
      return !jackpot ? false : jackpot;
    },
  },
};
</script>

<style lang="scss" scoped>
.progressive-jackpot {
  position: absolute;
  bottom: 50px;
  left: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  .jackpot-progressive {
    position: relative;
  }
  .amount {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 40px;
    width: 170px;
    color: rgb(208, 208, 208);
    font-size: 38px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 30px;
    font-weight: bold;
  }
  .type-text {
    position: absolute;
    bottom: -30px;
    width: 100%;
    height: 50px;
    color: #fbe699;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    text-shadow: 0 2px 3px black;
  }
  .pjp {
    transform: scale(1.25);
  }
}
</style>
