export default {
  clearList(state) {
    state.list.splice(0, state.list.length);
  },

  addToList(state, payload) {
    state.list.push(payload);
  },

  clearAllJackpot(state) {
    state.texasJackpots.splice(0, state.texasJackpots.length);
    state.omahaJackpots.splice(0, state.omahaJackpots.length);
  },

  addToJackpots(state, { payload, name }) {
    state[name].push(payload);
  },
};
