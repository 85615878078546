var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getActiveRoomState.countdown > 0 || _vm.getActiveRoomState.stage === 1),expression:"getActiveRoomState.countdown > 0 || getActiveRoomState.stage === 1"}],staticClass:"action-panel-outer"},[_c('div',{staticClass:"action-panel-inner"},[([1, 3, 5].includes(_vm.getActiveRoomState.stage))?_c('div',{staticClass:"action-panel-timer",class:_vm.getTimerClasses,style:({
        visibility: _vm.getActiveRoomState.countdown > 0 ? 'visible' : 'hidden',
      })},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.getActiveRoomState.countdown))])]):_vm._e(),(_vm.nGetMySeat.talked)?_c('div',{staticClass:"action-panel-info-text"},[_vm._v(" "+_vm._s(_vm.$t("blackJack.waitOtherPlayers"))+" ")]):_vm._e(),(_vm.getActiveRoomState.stage === 1)?[_c('div',{staticClass:"action-panel-info-text"},[_vm._v(" "+_vm._s(_vm.$t("blackJack.placeYourBet"))+" ")]),_c('BetSelector')]:_vm._e(),(_vm.ableToShowActionButtons)?[(!_vm.nGetMySeat.talked || !_vm.nGetMySeat.split.talked)?_c('div',{staticClass:"action-panel-info-text"},[_vm._v(" "+_vm._s(_vm.$t("blackJack.choose"))+" ")]):_vm._e(),_c('ActionButtons')]:_vm._e(),(
        _vm.getActiveRoomState.stage === 5 &&
        _vm.nGetMySeat.bet.main > 0 &&
        !_vm.nGetMySeat.talked
      )?[_c('div',{staticClass:"action-panel-info-text"},[_vm._v(" "+_vm._s(_vm.$t("blackJack.wantInsurance"))+" ")]),_c('InsuranceButtons')]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }