<template>
  <div
    class="game-buttons"
    ref="slider"
    v-show="!$parent.$parent.$data.showSlotSelection"
  >
    <div class="game">
      <button
        class="game-button"
        :disabled="!getGameStatus('texas_holdem_status')"
        @click="play('texas_holdem')"
      >
        <div class="texas-holdem-button game-button-inner">
          <div class="card-container">
            <div class="texas-card-1"></div>
            <div class="texas-card-2"></div>
            <div class="texas-chip"></div>
          </div>
        </div>

        <span
          class="under-maintenance no-flick"
          v-if="!getGameStatus('texas_holdem_status')"
        >
          <span>{{ $t("maintenance.underMaintenance") }}</span>
        </span>
      </button>
      <div
        class="play-now-arae"
        v-if="playNowShowValue"
        @click.prevent="onTexasHoldemButtonClicked"
      >
        <div class="play-now">
          <div class="play-now-text">
            {{ $t("tableList.tableList").toUpperCase() }}
          </div>
        </div>
      </div>
    </div>
    <div class="game">
      <button
        class="game-button"
        :disabled="!getGameStatus('omaha_status')"
        @click="onOmahaPokerButtonClicked"
      >
        <span class="omaha-button game-button-inner">
          <div class="card-container">
            <div class="omaha-card-1"></div>
            <div class="omaha-card-2"></div>
            <div class="omaha-card-3"></div>
            <div class="omaha-card-4"></div>
            <div class="omaha-chip"></div>
          </div>
        </span>
        <span
          class="under-maintenance no-flick"
          v-if="!getGameStatus('omaha_status')"
        >
          <span>{{ $t("maintenance.underMaintenance") }}</span>
        </span>
      </button>
      <div
        class="play-now-arae"
        @click="onOmahaPokerButtonClicked"
        v-if="playNowShowValue"
      >
        <div class="play-now">
          <div class="play-now-text">
            {{ $t("tableList.tableList").toUpperCase() }}
          </div>
        </div>
      </div>
    </div>

    <div class="game">
      <button
        class="game-button no-click-outside"
        :class="userLevel < 5 ? 'lock' : ''"
        :disabled="!getGameStatus('slots_status')"
        @click.stop="onSlotsButtonClicked"
      >
        <span v-if="userLevel < 5" class="game-button-lock"> </span>
        <span :style="darkening" class="slots-button game-button-inner">
          <div class="card-container">
            <div class="small-seven seven1"></div>
            <div class="big-seven seven2"></div>
            <div class="small-seven seven1"></div>
          </div>
        </span>
        <span
          class="under-maintenance no-flick"
          v-if="!getGameStatus('slots_status')"
        >
          <span>{{ $t("maintenance.underMaintenance") }}</span>
        </span>
      </button>
    </div>
    <div class="game">
      <button
        class="game-button"
        :class="userLevel < 5 ? 'lock' : ''"
        :disabled="!getGameStatus('blackjack_status')"
        @click="onBlackjackButtonClicked"
      >
        <span v-if="userLevel < 5" class="game-button-lock"> </span>

        <span :style="darkening" class="blackjack-button game-button-inner">
          <div class="card-container">
            <div class="blackjack-card-1"></div>
            <div class="blackjack-card-2"></div>
            <div class="blackjack-21"></div>
          </div>
        </span>
        <span
          class="under-maintenance no-flick"
          v-if="!getGameStatus('blackjack_status')"
        >
          <span>{{ $t("maintenance.underMaintenance") }}</span>
        </span>
      </button>
    </div>

    <!-- <button class="game-button">
      <span v-if="userLevel < 5" class="game-button-lock">
        <span>Level</span>
        <span>5</span>
      </span>
      <span :style="darkening" class="domino-button game-button-inner"></span>
      <span
        class="under-maintenance no-flick"
        v-if="!getGameStatus('blackjack_status')"
      >
        <span>{{ $t("maintenance.underMaintenance") }}</span>
      </span>
    </button> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { searchElementsSetProperty } from "@/helpers";
import AnimationMixin from "@/mixin/Animation.vue";

export default {
  name: "GameButtons",
  mixins: [AnimationMixin],
  data() {
    return {
      playNowShowValue: false,
    };
  },
  computed: {
    ...mapState(["config"]),
    ...mapGetters({
      texasRooms: `service/getTexasHoldemRooms`,
      omahaRooms: `service/getOmahaRooms`,
    }),

    userLevel() {
      return this.$store.getters["user/getLevel"](this.$store.state.user.xp)
        .number;
    },
    darkening() {
      if (this.userLevel < 5) {
        return { filter: "brightness(60%)" };
      }
    },
  },
  watch: {
    "$parent.$parent.$data.showSlotSelection": {
      handler(val) {
        if (!val) {
          this.$animejs({
            targets: [".game-button", ".play-now"],
            rotateY: [
              { value: 90, duration: 0 },
              { value: 0, duration: 250 },
            ],
            delay: 0,
            easing: "easeInQuad",
          });
        }
      },
    },
  },
  methods: {
    getGameStatus(game = "") {
      if (!this.config["game_status"]) return false;

      if (!game) return false;

      return this.config[game] ?? false;
    },
    onTexasHoldemButtonClicked() {
      if (this.getGameStatus("texas_holdem_status")) {
        console.log("audio play button");
        console.log("this.$plugins.audio", this.$plugins.audio);
        this.$plugins.audio.play("Button");
        this.$store.commit("changeScene", "TexasHoldemTableList");
        this.$store.state.service.lastOpenedTableList = "TexasHoldem";

        this.$adjust.trackEvent("ClickTexasHoldemTableList");

        // const safariButton = document.querySelector("#safari-button");
        // if (safariButton) {
        //   console.log("safariButton play", safariButton);
        //   safariButton.play();
        // }
      }
    },
    onOmahaPokerButtonClicked() {
      if (this.getGameStatus("omaha_status")) {
        this.$plugins.audio.play("Button");
        this.$store.commit("changeScene", "TexasHoldemTableList");
        this.$store.state.service.lastOpenedTableList = "Omaha";
        this.$adjust.trackEvent("ClickOmahaTableList");
      }
    },
    onBlackjackButtonClicked() {
      if (this.userLevel < 5) {
        this.$plugins.toast.show({
          text: this.$t("others.requiredLevelFive"),
          duration: 2500,
        });
        return;
      } else if (!this.getGameStatus("blackjack_status")) {
        this.$plugins.toast.show({
          text: this.$t("others.blackjackStatusInactive"),
          duration: 2500,
        });
        return;
      }
      this.$plugins.audio.play("Button");
      this.$store.dispatch("service/joinToBlackjackRoom");
    },
    slotButtonsOn() {
      this.$plugins.audio.play("Button");
      this.$parent.$parent.$data.showSlotSelection = true;
      searchElementsSetProperty(".game-button", [
        { property: "transform", value: "rotateY(0deg)" },
      ]);
    },
    onSlotsButtonClicked() {
      if (this.userLevel < 5) {
        this.$plugins.toast.show({
          text: this.$t("others.requiredLevelFive"),
          duration: 2500,
        });
        return;
      } else if (!this.getGameStatus("slots_status")) {
        this.$plugins.toast.show({
          text: this.$t("others.slotStatusInactive"),
          duration: 2500,
        });
        return;
      }

      this.$animejs({
        targets: [".game-button", ".play-now"],
        rotateY: [
          {
            value: 0,
            duration: 0,
          },
          {
            value: 90,
            duration: 250,
          },
        ],
        delay: 0,
        endDelay: 200,
        complete: this.slotButtonsOn,
        easing: "easeInQuad",
      });
      this.$adjust.trackEvent("SlotWindowOn");
    },
    onPlayButtonClick() {},
    play(selectedGame = "texas_holdem") {
      if (selectedGame === "texas_holdem") {
        if (this.getGameStatus("texas_holdem_status")) {
          this.findFilledTableAndGo("texas_holdem");
          this.$adjust.trackEvent("ClickTexasHoldemPlayNow");
        }
      } else if (selectedGame === "omaha") {
        if (this.getGameStatus("omaha_status")) {
          this.findFilledTableAndGo("omaha");
          this.$adjust.trackEvent("ClickOmahaPlayNow");
        }
      } else if (selectedGame === "blackjack") {
        if (this.getGameStatus("blackjack_status")) {
          if (this.userLevel < 5) {
            return;
          }
          this.$store.dispatch("service/joinToBlackjackRoom");
        }
      } else if (selectedGame === "turn_legends") {
        if (
          this.getGameStatus("slots_status") &&
          this.getGameStatus("turn_legends_status")
        ) {
          if (this.userLevel < 5) {
            return;
          }
          this.$parent.$parent.$data.showSlotSelection = true;
          this.$parent.$parent.$refs?.slotSelection.downloadOrPlay(
            "turnLegends"
          );
        }
      } else if (selectedGame === "fafafa") {
        if (
          this.getGameStatus("slots_status") &&
          this.getGameStatus("fafafa_status")
        ) {
          if (this.userLevel < 5) {
            return;
          }
          this.$parent.$parent.$data.showSlotSelection = true;
          this.$parent.$parent.$refs?.slotSelection.downloadOrPlay("fafafa");
        }
      } else if (selectedGame === "olympus") {
        if (this.getGameStatus("slots_status")) {
          if (this.userLevel < 5) {
            return;
          }
          this.$parent.$parent.$data.showSlotSelection = true;
          this.$parent.$parent.$refs?.slotSelection.downloadOrPlay("olympus");
        }
      } else if (selectedGame === "bamboo_panda") {
        if (
          this.getGameStatus("slots_status") &&
          this.getGameStatus("bamboo_panda_status")
        ) {
          if (this.userLevel < 5) {
            return;
          }
          this.$parent.$parent.$data.showSlotSelection = true;
          this.$parent.$parent.$refs?.slotSelection.downloadOrPlay(
            "bambooPanda"
          );
        }
      }
    },
    onPlayButtonDblClick() {
      this.$data.showList = true;
      this.$plugins.haptics.impact("LIGHT");
    },
    getGameStatus(game = "") {
      if (!this.config["game_status"]) return false;

      if (!game) return false;

      return this.config[game] ?? false;
    },
    async findFilledTableAndGo(gameType = "texas_holdem") {
      let innerTables;
      if (gameType == "texas_holdem") {
        innerTables = this.texasRooms;
      } else {
        innerTables = this.omahaRooms;
      }

      const tables = innerTables.filter((room) => {
        if (Number(room.metadata.minBuy) > this.$store.state.user.chip) {
          return false;
        }
        if (
          room.metadata.gameType !==
          (gameType == "omaha" ? "omaha_poker" : gameType)
        ) {
          return false;
        }
        if (
          typeof room.serverInfo === "undefined" ||
          typeof room.serverInfo.players === "undefined"
        ) {
          return false;
        }

        return true;
      });

      if (tables.length === 0) {
        if (gameType === "texas_holdem") {
          this.$store.state.service.lastOpenedTableList = "TexasHoldem";
          this.$store.commit("changeScene", "TexasHoldemTableList");
        } else {
          this.$store.state.service.lastOpenedTableList = "Omaha";
          this.$store.commit("changeScene", "TexasHoldemTableList");
        }

        return;
      }

      const table = [...tables].sort(
        (a, b) => a.metadata.bet - b.metadata.bet
      )[0];

      if (typeof table.id === "undefined") {
        if (table.metadata.gameType === "texas_holdem")
          this.$store.commit("changeScene", "TexasHoldemTableList");
        else {
          this.$store.commit("changeScene", "TexasHoldemTableList");
          this.$store.state.service.lastOpenedTableList = "Omaha";
        }
        return;
      }

      await this.$store.dispatch("service/joinPokerRoom", {
        roomId: String(table.id),
      });

      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("sit", {
        amount: table.metadata.minBuy,
        position: 0,
        autoPurchase: 0,
        autoCharging: 0,
      });
    },
  },
  mounted() {
    this.$animejs({
      targets: [".game-button", ".ad-image", ".play-now"],
      rotateY: [
        { value: 90, duration: 0 },
        { value: 0, duration: 250 },
      ],
      delay: 250,
      easing: "easeInQuad",
    });

    setTimeout(() => {
      this.playNowShowValue = true;
    }, 800);
  },
};
</script>

<style scoped lang="scss">
.game-buttons {
  display: flex;
  overflow-x: scroll;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-left: 10px;
  margin-top: 30px;
  max-width: 1506px;
  overflow: hidden;
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    display: none;
    color: rgba(black, 0.4);
  }
  .game {
    position: relative;
    width: 340px;
    height: 100%;
    margin-left: 30px;
    .game-button {
      position: relative;
      background-color: transparent;
      width: 100%;
      height: 100%;
      transition: all 0.25s ease;
      &:disabled {
        // .game-button-inner {
        //   opacity: 0.5;
        // }
        .under-maintenance {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          background-image: url("~@/assets/images/maintenance/maintenance.png");
          background-position: center;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-weight: bold;
            font-size: 40px;
            color: rgb(255, 255, 255);
            margin-top: -40px;
          }
        }
      }

      &:not(:disabled):active {
        opacity: 0.75;
      }
      .game-button-lock {
        background-image: url("~@/assets/images/lobby/game-button-lock.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 343px;
        height: 106px;
        position: absolute;
        bottom: 100px;
        left: -5px;

        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 38px;
        color: rgb(28, 28, 28);
        span {
          position: relative;
          top: 60px;
          line-height: 46px;
        }
      }
      .game-button-inner {
        height: 88%;
        width: 340px;
        margin-left: -10px;
        margin-top: -10px;
      }
      span {
        display: block;
      }
      .texas-holdem-button {
        background: url("~@/assets/images/lobby/texas-holdem-button.png")
          no-repeat center center;
        background-size: contain;
        position: relative;
        z-index: 1;
        .card-container {
          position: relative;
          height: 300px;
          top: 200px;
          .texas-card-1 {
            position: absolute;
            top: 0px;
            left: 20px;
            background: url("~@/assets/images/lobby/texas-card1-min.png");
            background-size: contain;
            background-repeat: no-repeat;
            height: 199px;
            width: 158px;
          }
          .texas-card-2 {
            position: absolute;
            top: -20px;
            left: 94px;
            background: url("~@/assets/images/lobby/texas-card2-min.png");
            background-size: contain;
            background-repeat: no-repeat;
            height: 205px;
            width: 149px;
          }
          .texas-chip {
            position: absolute;
            top: 80px;
            left: 145px;
            background: url("~@/assets/images/lobby/texas-chip-min.png")
              no-repeat;
            height: 205px;
            width: 160px;
          }
        }
      }
      .omaha-button {
        background: url("~@/assets/images/lobby/omaha-button.png") no-repeat
          center center;
        background-size: contain;
        .card-container {
          position: relative;
          height: 300px;
          top: 200px;
          .omaha-card-1 {
            position: absolute;
            top: 0px;
            left: 30px;
            background: url("~@/assets/images/lobby/omaha-card1-min.png");
            background-size: contain;
            background-repeat: no-repeat;
            height: 199px;
            width: 158px;
          }
          .omaha-card-2 {
            position: absolute;
            top: -30px;
            left: 70px;
            background: url("~@/assets/images/lobby/omaha-card2-min.png");
            background-size: contain;
            background-repeat: no-repeat;
            height: 199px;
            width: 158px;
          }
          .omaha-card-3 {
            position: absolute;
            top: -50px;
            left: 120px;
            background: url("~@/assets/images/lobby/omaha-card3-min.png");
            background-size: contain;
            background-repeat: no-repeat;
            height: 199px;
            width: 158px;
          }
          .omaha-card-4 {
            position: absolute;
            top: -60px;
            left: 160px;
            background: url("~@/assets/images/lobby/omaha-card4-min.png");
            background-size: contain;
            background-repeat: no-repeat;
            height: 199px;
            width: 158px;
          }
          .omaha-chip {
            position: absolute;
            top: 70px;
            left: 100px;
            background: url("~@/assets/images/lobby/omaha-chip-min.png")
              no-repeat;
            height: 205px;
            width: 160px;
          }
        }
      }
      .slots-button {
        background: url("~@/assets/images/lobby/slots-button.png") no-repeat
          center center;
        background-size: contain;
        .card-container {
          position: relative;
          height: 300px;
          top: 220px;
          .small-seven {
            position: absolute;
            top: 0px;
            left: 0px;
            background: url("~@/assets/images/lobby/7-small-min.png");
            background-size: contain;
            background-repeat: no-repeat;
            height: 199px;
            width: 158px;
            &:last-child {
              top: 0px;
              left: 185px;
            }
          }
          .big-seven {
            position: absolute;
            top: -20px;
            left: 80px;
            background: url("~@/assets/images/lobby/7-big-min.png");
            background-size: contain;
            background-repeat: no-repeat;
            height: 220px;
            width: 180px;
            z-index: 2;
          }
        }
      }
      .blackjack-button {
        background: url("~@/assets/images/lobby/blackjack-button.png") no-repeat
          center center;
        background-size: contain;
        .card-container {
          position: relative;
          height: 300px;
          top: 200px;
          .blackjack-card-1 {
            position: absolute;
            top: 0px;
            left: 10px;
            background: url("~@/assets/images/lobby/blackjack-card1-min.png");
            background-size: contain;
            background-repeat: no-repeat;
            height: 240px;
            width: 200px;
            z-index: 2;
          }
          .blackjack-card-2 {
            position: absolute;
            top: -20px;
            left: 70px;
            background: url("~@/assets/images/lobby/blackjack-card2-min.png");
            background-size: contain;
            background-repeat: no-repeat;
            height: 240px;
            width: 200px;
            z-index: 2;
          }
          .blackjack-21 {
            position: absolute;
            top: 20px;
            left: 120px;
            background: url("~@/assets/images/lobby/21-min.png");
            background-size: contain;
            background-repeat: no-repeat;
            height: 240px;
            width: 200px;
            z-index: 2;
          }
        }
      }
      .domino-button {
        background: url("~@/assets/images/lobby/domino.png") no-repeat center
          center;
        background-size: contain;
      }
    }
    .lock {
      filter: brightness(60%);
    }
    .play-now-arae {
      position: absolute;
      bottom: 110px;
      left: 10px;
      width: 320px;
      height: 110px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      .play-now {
        position: absolute;
        bottom: 10px;
        width: 242px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.25s all ease;
        background: url("~@/assets/images/lobby/table-list-button.png")
          no-repeat;
        background-size: contain;
        z-index: 10;
        margin-bottom: 10px;
        cursor: pointer;
        &-text {
          color: white;
          font-size: 34px;
          text-shadow: rgba(black, 0.3) 1px 8px;
        }
        &:active {
          transform: scale(0.9);
        }
        &:hover {
          background-color: rgba(rgb(0, 0, 0), 0.3);
        }
      }
    }
  }
}
</style>
