/*
    Scene component file names (exclude .vue)
*/

import Empty from "@/components/Scenes/Empty";
import Error from "@/components/Scenes/Error";
import ConnectionError from "@/components/Scenes/ConnectionError";
import UnderMaintenance from "@/components/Scenes/UnderMaintenance";

import Loading from "@/components/Scenes/Loading";
import Authentication from "@/components/Scenes/Authentication";
import Lobby from "@/components/Scenes/Lobby";
import TexasHoldemTableList from "@/components/Scenes/TexasHoldemTableList";
import PokerTable from "@/components/Scenes/PokerTable";
import Blackjack from "@/components/Scenes/Blackjack";
import Update from "@/components/Scenes/Update";

export default {
  Empty: Empty,
  Error: Error,
  Loading: Loading,
  ConnectionError: ConnectionError,
  UnderMaintenance: UnderMaintenance,
  Authentication: Authentication,
  Lobby: Lobby,
  TexasHoldemTableList: TexasHoldemTableList,
  PokerTable: PokerTable,
  Blackjack: Blackjack,
  Update: Update,
};
