import { Capacitor } from "@capacitor/core";
import i18n from "@/plugins/i18n";

export default {
  /*
   * Returns state.platform (web, mobile etc.)
   * */
  getPlatform(state) {
    return state.platform;
  },

  /*
   * Returns device platform provided by CapacitorJs (ios,android,web etc.)
   * */
  getRealPlatform() {
    return Capacitor.getPlatform();
  },

  /*
   * Returns application scale amount (getting from css property)
   */
  getTotalScaleAmount() {
    const appOuter =
      document.documentElement.style.getPropertyValue(`--app-main-scale`);

    return Number(appOuter);
  },

  /*
   * Setting application scale amount (css property)
   */
  setScaleAmount() {
    return (type = "", value) => {
      document.documentElement.style.setProperty(`--app-${type}-scale`, value);
    };
  },

  /*
   * Returns number as formatted by dots (generally used for chips)
   * Example: 100000 => 100.000
   */
  getChipFormat() {
    return (number) => {
      if (isNaN(number) || number == null || number == 0) return 0;
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };
  },

  /*
   * Returns number as formatted by K,M,T,P etc. (generally used for chips)
   * Example: 100000 => 100K
   */
  getShortChipFormat() {
    return (number, fraction = 1) => {
      if (isNaN(number) || number == null || number == 0) return 0;
      const notations = ["", "K", "M", "B", "T", "Q", "E", "Z", "Y"],
        i = Math.floor(Math.log(number) / Math.log(1000));
      return `${parseFloat((number / Math.pow(1000, i)).toFixed(fraction))}${
        notations[i]
      }`;
    };
  },

  /*
   *  i18n simple call method
   * */
  getLang() {
    return (key) => i18n.t(key);
  },

  /*
   *  is game in facebook?
   * */
  isInFacebook() {
    return (
      new URLSearchParams(window.location.search).get("auth_type") ===
      "facebook"
    );
  },
};
