<template>
  <div
    class="lds-dual-ring"
    :style="{
      width: `${width}px`,
      height: `${height}px`,
    }"
    v-show="loading"
  ></div>
</template>

<script>
export default {
  name: "DualRing",
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: "100",
    },
    height: {
      type: String,
      default: "100",
    },
  },
};
</script>

<style lang="scss" scoped>
.lds-dual-ring {
  display: inline-block;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 100px;
  height: 100px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
