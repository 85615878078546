export default {
  // general
  scene: "Authentication",
  platform: "mobile",

  config: {
    vip_price: 300000000,
    force_update: null,
    ad_bonus_amount: "",
  },
  levels: [],
  gameAds: [],
  animations: {
    moneyBox: true,
    bonusChip: true,
    shopChipShine: true,
  },
  language: null,
};
