/*
 * Russian language file
 */

export default {
  loading: "Грузится",

  general: {
    no: "Нет",
    yes: "Да",
    avatar: "Аватар",
    vipIcon: "ВИП-иконка",
    tableInformation: "Информация",
    cancel: "Закрыть",
    save: "Сохранить",
    logout: "Выйти",
    play: "Играть",
    chat: "Чат",
    leaders: "Лидеры",
    social: "Социальный",
    case: "Сейф",
    shop: "Магазин",
    okay: "Хорошо",
    refresh: "Обновить",
    send: "Отправить",
    username: "Имя Пользователя",
    delete: "Удалить",
    reject: "Отклонять",
    accept: "Принять",
    confirm: "Подтвердить",
    registrationDate: "Дата регистрации",
    name: "Имя",
    total: "Всего",
    copy: "Копировать",
    commission: "Комиссия",
    getGift: "Получи свой подарок",
    transfer: "Перевести",
    menu: "Меню",
    update: "Обновить",
    invite: "Пригласить",
    joinNow: "Присоединяйся сейчас",
    handHistory: "История рук", //NEW***
  },
  dailyEntry: {
    dailyEntry: "Ежедневный бонус", //NEW***
    dailyDesc: "Заходи каждый день и получай больше фишек!", //NEW***
    day: "День", //NEW***
  },
  noficationWindow: {
    notificationInviteTable:
      "{name} пригласил вас за стол в {gameType}{tableId} {date}", // NEW***
    notificationInviteChip: " {name} отправил вам {amount} фишек.", // NEW***
    sendMessage: "Пожалуйста, введите ваше сообщение", // NEW***
    tableInvite: "Приглашение за стол", // NEW***
    system: "Система", // NEW***
    messages: "Сообщения", // NEW***
    notifications: "Уведомления", // NEW***
  },

  giftsWindow: {
    gift: "Подарок", //NEW***
    getYourself: "Для себя", //NEW***
    getEveryone: "Для всех", //NEW***
    expression: "Эмоджи", //NEW***
    selectGift: "Выберите подарок", //NEW***
    noPlayersAtTable: "Нет игроков за столом", //NEW***
    sitATable: "Сначала сядьте за стол", //NEW***
    selectUser: "Пожалуйста, выберите пользователя", //NEW***
  },
  authentication: {
    signInToContinue: "Войдите, чтобы продолжить",
    anErrorOccuredWhileLoggingIn: "Произошла ошибка при входе в систему!",
    contact: "Связаться",
    privacyPolicy: "Политика конфиденциальности",
    provablyFair: " Provably Fair",
    sign_guest: "Играть как гость",
    sign_apple: "Войти через Apple",
    sign_google: "Войти через Google",
    sign_facebook: "Войти через Facebook",
    loginBonusText:
      "<p class='login-info-text'> Получите экстра бонусных фишек! <span class='color-white'> {0} </span> или <span class='color-white' > {1} </span> </p>",
    loginBonusTextWeb:
      "<p class='login-info-text'> Получите экстра бонусных фишек! <span class='color-white'> {0} </span> </p>",
  },
  playerActions: {
    enoughChips: "У вас недостаточно фишек",
    minSendChipAmount: "Сумма фишек менее 10B не может быть отправлена",
    failedTransfer: "Не удалось отправить фишки",
    successTransfer: "Фишки успешно отправлены.",
    validChip: "Please enter a valid amount of chips",
  },
  tableList: {
    table: "Стол",
    tableId: "Стол ID",
    tableName: "Название",
    blind: "Блайнд",
    minMax: "Мин/Макс",
    filterByBlinds: "Фильтровать по Блайнд",
    hideEmpties: "Скрыть пустые",
    hideFullyFilled: "Скрыть полные",
    all: "Все",
    players: "Игроки",
    full: "Полный",
    noRoomsMessage: "Нет комнат.",
    tableList: "Лист столов",
    tableInfo: {
      emptyRoomMessage: "Пустая комната.",
    },
  },
  message: {
    message: "Сообщение",
    sendMessage: "Отправить сообщение",
    typeMessage: "Введите ваше сообщение",
    sentMessage: "Отправил вам сообщение",
  },

  lobby: {
    playNow: "Играть сейчас",
    newSlotGame: "Новый Слот",
    availableNow: "Доступно сейчас",
    specialForYou: "Специально для вас",
    play: "Играть",
    favouriteGameButton: {
      instructionMessage: "Дважды нажмите, чтобы изменить",
    },
  },

  bonusWindow: {
    congratulations: "Поздравляем!",
    accept: "Принять",
  },

  maintenance: {
    gameMaintenance: "Игра находится на обслуживании",
    underMaintenance: "На обслуживании",
    tryAgainLAter: "Игра на техническом обслуживании. Повторите попытку позже!",
  },

  confirmWindow: {
    sure: "Вы уверены?",
    userNotFound: "Пользователь не найден",
    notAffiliate: "Пользователь не является партнером",
    DaysVip: "Вы покупаете ВИП подписку на 30 дней",
  },
  deleteConfirmWindow: {
    sure: "Вы собираетесь удалить свой аккаунт",
    content:
      "Если вы продолжите , ваша информация о пользователе и приобретенные вами продукты будут удалены",
    delete: "Удалить",
    cancel: "Отменить",
    deleteSuccessfully: "Аккаунт был успешно удален",
    notBeDeleted: "При удалении аккаунта произошла ошибка",
    deletefriends: "Ваш друг будет удален из друзей.",
    daysLimit:
      "Если ваша учетная запись не будет активна в течение 30 дней, она будет удалена без возможности восстановления", //NEW****
    areYouSure: "Вы уверены, что хотите удалить свой аккаунт?",
  },
  historyWindow: {
    hand: "Рука",
    preFlop: "Pre-Flop",
    flop: "Flop",
    turn: "Turn",
    river: "River",
    winner: "Победитель",
    speacial: "Специальный",
    team: "Командная Игра",
    anyHistory: "У вас нет истории",
  },
  socialWindow: {
    gameCardDesc:
      "Вы можете получить свой приз, введя код от выигранных билетов.",
    sharecode: "Поделиться кодом", // NEW***s
    friends: "Друзья",
    requests: "Запросы",
    gameCard: "Игровая карта",
    searchFriend: "Найти друга",
    chipTransferHistory: "История отправления фишек",
    socialMedia: "Социальные сети",
    socialMediaAndInviteCode: "Социальные сети и код приглашения",
    noFriendRequest: "У вас нет запросов на добавление в друзья.",
    noFriends: "У вас нет друзей.",
    noChipTransfer: "Нет истории отправления фишек",
    extraChip:
      "Подпишитесь на наши аккаунты в социальных сетях и получите бесплатные фишки.",
    followUs: "Подпишитесь на нас в социальных сетях!",
    inviteCode: "Код приглашения",
    myCode: "Мой Код",
    copy: "Копировать",
    inviteCodeDescription:
      "Поделитесь пригласительным кодом, находящимся выше, с вашим другом. <br><br>- Ваш друг, как новый игрок, сыграет 10 рук за покерными столами. <br><br>- Вы оба получаете 10B фишек!<br><small>Код приглашения недоступен для гостевых учетных записей.</small>",
    shareWithFriends: "Поделись с друзьями!",
    shareWithFriendsCode:
      "Вот наш бонусный код! Получите 10B бонусных фишек после того, как ваши друзья сыграют 10 рук в покер. Помните, чем больше пригласительных кодов вы отправите, тем больше бонусных фишек вы получите. Code: {code}",
    shareInviteCodeWithFriends: "Поделитесь своим пригласительным кодом",
    removeFriend: "Удалить из друзей",
    inviteCodeDescription:
      "-Поделитесь приведенным выше инвайт-кодом с вашим другом. <br><br>- Пусть ваш друг сыграет 10 рук за покерными столами в качестве нового участника. <br><br>- Вы оба получите 10B фишек !<br>",
    block: "Заблокировать",
    unBlock: "Разблокировать",
  },
  friendDetailsWindow: {
    failed: "Не удалось отправить запрос на добавление в друзья",
  },
  chipHistoryDetail: {
    youSent: "Вы отправили",
    chips: "Фишки",
    sentYou: "Отправил вам",
  },
  createTableWindow: {
    bets: "Ставки",
    createTable: "Создать стол",
    seatsNumber: "Номер места",
    potTable: "Пот Стол",
    teamTable: "Командный Стол",
    tellerEntrance: "Минимальный Лимит",
  },
  chipTransferWindow: {
    sendChip: "Отправить фишки",
    chipAmount: "Введите количество фишек",
    chipMessage: "Введите ваше сообщение здесь",
    warning: "*Вы можете отправить любое количество фишек! Без ограничений!",
  },
  playerDetailsWindow: {
    profileNote: "Примечание к профилю",
    statistics: "Статистика",
    level: "Уровень",
    recordChip: "Фишки",
    friendsCount: "Количество друзей",
    bestHand: "Лучшая рука",
    copied: "Скопировано!",
    failedChanges: "Не удалось сохранить изменения",
    enterProfileNote: "Введите примечание к своему профилю..", //NEW****
    gameStatistics: "Статистика игры", //NEW****
    sameFrameWarning:
      "Пожалуйста, выберите рамку, отличную от той, которую вы используете в данный момент.", //NEW****
    sameAvatarWarning:
      "Пожалуйста, выберите аватар, отличный от того, который вы сейчас используете.", //NEW****
    fillFields: "Пожалуйста, заполните все поля", //NEW****
    passwordsDoesNotMatch: "Ваши пароли не совпадают", //NEW****
    passwordLength:
      "Длина пароля должна быть не менее 6 и не более 30 символов",
    changeName:
      "Your right to change your name has expired. You can buy VIP and try again",
  },
  shopWindow: {
    redeem: "Активировать",
    keysField: "Пожалуйста, введите игровую карту в поле.",
    withoutChip: "Сесть за стол без фишек",
    buyChip: "Купить фишки",
    redeemCode: "Активировать код",
    noProduct: "Нет продуктов для отображения.",
    buyVip: "Купить ВИП",
    privateEmojis: "Специальные ВИП-эмодзи и подарки",
    unlimitedChipTransfer: "Неограниченный перевод фишек",
    privateProfile: "Специальные ВИП рамки",
    connectionNeverLost: "Стабильное соединение",
    unLimitedView: "Безграничное время просмотра столов",
    privateTable: "Создание специальный столов",
    usingPrivateCase: "Использование сейфа для сбережения фишек",
    xp: "10.000XP в подарок",
    freeChat: "Бесплатный чат",
    day: "День",
    daysLeft: "Осталось дней",
    alreadyVip: "У вас уже есть VIP-подписка.",
    noChips: "У вас недостаточно фишек для покупки VIP-подписки",
    successfullyPurchased: "Вы успешно приобрели VIP-подписку.",
    bronze: "Бронза",
    silver: "Серебро",
    gold: "Золото",
    chipAdded: "Фишки успешно получены",
  },
  caseWindow: {
    onlyVip: "Эта функция доступна только для VIP-пользователей",
    buyVip: "Купить VIP",
    chipInGame: "Фишки в игре",
    chipInCase: "Фишки в сейфе",
    theretentation:
      "Этот инструмент определяет долю сохранения количества ваших фишек в игре.",
    transactionFailed: "Транзакция не удалась",
    fromGame: "Из игры",
    fromCase: "Из сейфа",
    amount: "Ввести Сумму",
    transaction: "Успешно завершено.",
    enoughChip: "У вас недостаточно фишек для внесения депозита.",
    withdraw: "У вас недостаточно фишек для снятия с сейфа.",
    validAmount: "Введите действительную сумму",
    transfer: "Передача",
    friendRequestToYou: "Отправил вам запрос на добавление в друзья.",
  },
  friendsRequestWindow: {
    friendsOffer: "ВЫ ПОЛУЧИЛИ ЗАЯВКУ НА ДОБАВЛЕНИЕ В ДРУЗЬЯ!",
    successRequest: "Запрос на добавление в друзья успешно отправлен.",
    acceptRequest: "принял ваш запрос на добавление в друзья.",
    rejectedRequest: "отклонил ваш запрос на добавление в друзья.",
    becomeFriends:
      "отправил вам запрос на добавление в друзья! Хотите стать друзьями?",
    requestSent: "Запрос уже отправлен",
  },
  settingsWindow: {
    language: "Язык",
    gameSettings: "Настройки игры",
    customize: "Кастомизация",
    security: "Безопасность",
    successfullySaved: "Изменения успешно сохранены.",
    pinCode: {
      pinCode: "ПИН-КОД",
      createPinDesc: "Создайте пин-код для безопасности учетной записи",
      reEnterPin: "Повторно введите пин-код",
      emailAddress: "Адрес электронной почты",
      emailRequired: "Требуется для сброса пароля",
      areYouSure: "Вы уверены, что хотите удалить свой пин-код?",
      pinDesc:
        "Создание пин-кода очень важно для безопасности учетной записи. Turn Games не примет никаких жалоб до тех пор, пока у вашей учетной записи нет пин-кода.",
    },
    customizeWindow: {
      tableStyle: "Стиль стола",
      tableColors: "Цвет стола",
      roomBgColor: "Цвет фона комнаты",
    },
    gameSettingsWindow: {
      dontDistrub: "Не беспокоить",
      blockNotification: "Уведомления",
      showAnimatedGifts: "Показать анимированные подарки",
      newVersion: "Новая версия",
    },
  },
  reportWindow: {
    report: "Отчет",
    reportFilter: "Фильтр отчетов",
    audiences: "Зрители",
    players: "Игроки",
    successMessage: "Отчет успешно отправлен.",
    failedMessage: "Отчет отправлен с ошибкой.",
    swearing: "Ненормативная лексика",
    team: "Командная Игра",
    scissors: "Multiple Raise",
    noPlayersFound: "Нет игроков или зрителей.",
  },
  inboxContact: {
    contactUs: "Свяжитесь с нами",
    title: "Тема",
    textHere: "Текст",
    congratulations: "Поздравляем",
    inbox: "Входящие",
    desc: "Вы заработали 10 000 фишек за последний купленный пакет.",
    failedMessage: "Не удалось отправить сообщение",
    successfullyMessage: "Сообщение успешно отправлено",
    validEmail: "Пожалуйста, введите действительный адрес электронной почты",
    fillTabs: "Пожалуйста, заполните все поля",
  },
  errors: {
    connectionLost: "Соединение потеряно",
    yourConnection: "Ваше соединение потеряно.",
    errorOccured: "Произошла ошибка",
    gameServiceError:
      "Произошла ошибка игрового сервиса. Повторите попытку позже",
    slotDownloadError: "Произошла ошибка при загрузке игры.",
    loginError: "Произошла ошибка при входе в систему.",
    insufficientBalance: "Недостаточный баланс.",
    tooManyRequests: "Вы не можете запрашивать так часто.",
    haveToSitAtTable: "Вы должны сесть за стол.",
    youAreBlocked: "Вы заблокированы.",
    youAreBlockedTheUser: "Вы заблокировали пользователя.",
    youAreUnblockedTheUser: "Вы разблокировали пользователя.",
    userNotActiveGame: "Пользователь в данный момент находится не в игре",
    notEnoughLevelPlayGame:
      "{level} уровень {level} необходим для присоединения к {game} столу.",
    tableNotFound: "tableNotFound",
    playerIdWrong: "Реферанс ID неверен",
    searchedUserNotFound: "Реферанс ID не найден",
  },
  searchWindow: {
    searchUser: "Поиск пользователя",
    idOfUser: "Пожалуйста, введите ID пользователя, которого вы хотите найти.",
    search: "Искать",
    ownId: "Это ваш ID",
    validId: "Пожалуйста, введите действительный ID",
  },
  adBonusWindow: {
    title: "ПОЛУЧИТЕ ФИШКИ БЕСПЛАТНО!",
    content: "Посмотрите короткое видео и заработайте ${money} фишек!",
    watchButton: "Смотреть",
  },
  affiliate: {
    affiliateDesc: "Введите референс ID вашего друга.",
    affiliateOffDesc: "Ваш референс ID ",
    affiliate: "Партнер",
    addAffiliate: "Добавить партнера",
    affiliateId: "Введите ID партнера",
    clear: "Очистить",
    searchBy: "Искать по",
    name: "Имя",
    noData: "Нет данных",
    startDate: "Дата начала",
    endDate: "Дата окончания",
    warning: "Стань нашим партнером и обеспечь себе стабильный доход!",
    info: "Узнайте больше о том, как к нам присоединиться.",
    failedAffiliate: "Не удалось добавить партнера",
    successfullyAdded: "ID партнера успешно добавлено.",
    date_wrong: "Дата начала должна быть раньше даты окончания.",
    affiliateIdTitle: "Партнер ID",
    excelDownloaded: "Скачано в Документы",
  },
  notification: {
    noData: "Нет уведомлений",
  },
  chat: {
    worldChat: "Глобальный чат",
    directChat: "Личные сообщения",
    onlyVip:
      "Только VIP-пользователь могут отправлять сообщения в глобальный чат",
    showMore: "Показать больше",
    anyMessages: "У вас нет сообщений.",
  },
  disconnectedWindow: {
    disconnected: "Отсоединен",
    disconnectedServer: "Ой... Вы были отсоединены от сервера!",
    reconnect: "Подключиться",
    inactive: "Ой... Вы слишком долго были неактивны!",
    reconnected: "ПОВТОРНО ПОДКЛЮЧИТЬСЯ",
    banned: "Ваш аккаунт был забанен!",
    bannedDescription:
      "Вы можете связаться с нами по адресу support@turngs.com.",
  },

  rulesWindow: {
    texasDesc:
      "Техасский Холдем Покер — самая популярная карточная игра в мире. Цель игры заключается в том, чтобы выиграть раунд с более высокой рукой, чем противники. У вас на руке 2 карты, а на столе 5 карт, выигрывает рука, набравшая лучшую комбинацию из 5 карт. Каждая рука в списке ниже бьет следующую руку. Например, стрит-флеш бьет каре, стрит бьет пары. Руки расценены по своей силе..",
    omahaDesc:
      "Омаха — одна из самых популярных разновидностей покера в мире. Цель игры состоит в том, чтобы выиграть банк. Есть 2 основных способа выиграть фишки: выиграть покерную комбинацию выше, чем у оппонента, либо дождаться паса соперника или откажется от игры. В руке 5 карт. Правило заключается в том, что рука должны содержать три из пяти общих карт на столе и две из четырех карт, сданных игроку. Все руки в категории в списке бьют все руки в категориях ниже. Каждая рука в списке ниже бьет следующую руку. Например, стрит-флеш побеждает каре, стрит бьет пары. Руки расцениваются по их силе.",
    blackJackDesc:
      "Цель игры Turn Blackjack состоит в том, чтобы получить большим количество очков, чем у крупье, при этом количество очков не должно превышать 21. Выигрышная рука в Blackjack - общая сумма первых двух карт, сданных в этой руке, равна 21. Вы играете только против крупье, а не против другого игрока. В Blackjack играют восемью колодами. Крупье всегда останавливается на 17. Можно удвоить на любых двух стартовых картах. Стартовые карты одного достоинства можно разделить на две. Разделить можно каждой рукой . За каждый двойной туз сдаётся одна карта. После разделения карт удвоить нельзя. Страховка рекомендуется, когда у крупье есть туз. Блэкджек оплачивается 3:2. Страховка оплачивается 1:2",
    handNames: {
      royalFlush: "Royal Flush",
      straightFlush: "Straight Flush",
      fourOfAKind: "Four Of A Kind",
      fullHouse: "Full House",
      flush: "Flush",
      straight: "Straight",
      threeOfAKind: "Three Of A Kind",
      twoPair: "Two Pair",
      pair: "One Pair",
      highCard: "High Card",
    },
    handDesc: {
      royalFlush: "Самая сильная комбинация в покере.",
      straightFlush:
        "Пять последовательных карт одной масти. Между двумя руками Straight Flush побеждает тот, у кого старшая карта.",
      fourOfAKind:
        "Четыре карты одного достоинства. Самая сильная комбинация - та, в которой карты старшего достоинства. Если у игроков одинаковые руки, то победитель определяется по достоинству пятой карты (кикера).",
      fullHouse:
        "Комбинация тройка и пара. При сравнении комбинаций фулл-хауса побеждает игрок с тремя лучшими картами. Если они совпадают, побеждает игрок с лучшей парой.",
      flush:
        "Пять неупорядоченных карт одной масти. Если у двух или более игроков одна и та же масть, побеждает игрок со старшей картой.",
      straight:
        "Пять последовательных карт двух или более разных мастей. Из двух стритов побеждает тот, у кого карта наибольшего достоинства",
      threeOfAKind:
        "Комбинация из трех карт одного достоинства. Из двух троек побеждает та, у которой карты с наибольшим значением. Если тройки имеют одинаковое значение, победитель определяется по старшему из оставшихся двух кикеров.",
      twoPair:
        "Две карты одного ранга плюс две карты другого ранга. При сравнении дублей самая старшая пара определяет победителя. Если старшие пары имеют одинаковое значение, сравниваются младшие пары. Если младшие пары одинаковы номиналом, побеждает игрок с пятой картой (кикером) старшего номинала.",
      pair: "Две карты одного достоинства. Если у игроков одинаковая пара, то победитель определяется по старшей из трех оставшихся карт (кикеру).",
      highCard:
        "Покерная комбинация, которая не соответствует требованиям рук, описанных ранее. Если у игроков одинаковая старшая карта, вторая старшая карта (и т. д.) определяет победителя",
    },
    expressions: {
      blinds: {
        heading: "Блайнды",
        desc: "Перед тем, как будут сданы карты, игрок, сидящий слева от дилера, должен сделать ставку, называемую малым блайндом. Следующий игрок, сидящий слева от малого блайнда, должен сделать так называемый большой блайнд. Сумма больших блайндов в два раза превышает сумму малых блайндов",
      },
      ante: {
        heading: "Анте",
        desc: "Если игрок присоединяется к игре, он сидит за столом и не находится в позиции большого блайнда, этот игрок делает принудительную ставку, называемую анте. Это можно сделать один раз, и ставка анте равна большому блайнду.",
      },
      minimumRaise: {
        heading: "Минимальный Рейз",
        desc: "Игрок не может сделать рейз меньше большого блайнда.",
      },
      allIn: {
        heading: "Ва-Банк",
        desc: "Если у игрока недостаточно фишек, чтобы увидеть ставку, он может пойти ва-банк. Игрок ставит все, что осталось. Если игрок идет ва-банк, он не может выиграть больше фишек, чем он может видеть. Если банк увеличивается после того, как игрок идет ва-банк и выигрывает, банк будет разделен между этим игроком и игроком со следующей лучшей рукой, который может сделать рейз.",
      },
      potSplit: {
        heading: "Сплит Банка",
        desc: "If two or more players are in a draw, the pot is split equally between them.",
      },
      bjGameRules: {
        heading: "Правила Игры",
        desc: "Игрой управляет крупье, и в каждой раздаче может участвовать неограниченное количество игроков. Игра представлена ​​крупье и ведется восемью стандартными колодами по 52 карты. 21 ценность карт в игре следующая. На картах от 2 до 10 написано значение Карты с картинками (валеты, дамы и короли) стоят 10 очков Тузы стоят 1 или 11, игрок может выбрать подходящее значение в соответствии со своей рукой. Обратите внимание, что мягкая рука содержит 11 стоимостных тузов. По истечении времени ставок крупье раздает открытую карту всем игрокам, последнюю себе. После этого крупье раздает всем игрокам вторую открытую карту, а вторую крупье карта будет закрыта. Значение вашей первой руки указано рядом с вашими картами. Блэкджек (двадцать одна) Если две карты, которые вы получили в начале игры, составляют в сумме 21, у вас есть Блэкджек!",
        descInsurance:
          "Если открытой картой крупье является туз, вы можете купить страховку от риска того, что у крупье будет 21, даже если ваша общая рука равна 21. Страховая сумма будет равна половине вашей основной ставки, а страховка ставка будет обработана отдельно от вашей ручной ставки. После этого крупье просматривает достоинство своей закрытой карты, чтобы проверить, не выпал ли 21. Если крупье не набирает 21, раунд продолжается. Если круп, игра окончена, и крупье побеждает. Если и у вас, и у крупье есть 21, игра закончится ничьей, и ваша ставка будет возвращена. Обратите внимание, что если открытая карта крупье 10 или цветная карта, у вас будет возможность купить страховку, и крупье не будет смотреть на свою закрытую карту на 21.",
        headingInsurance: "Страхование",
        headingHit: "Удвоить, ударить или встать",
        descHit:
          "Если крупье обнаруживает, что после проверки своих первых двух карт он не получил 21, игрокам дается право выбрать больше карт, чтобы улучшить ценность своей руки, давая крупье больше карт игрокам. Если ваша первая рука не стоит 21, вы имеете право удвоить ставку. В этом случае ваша ставка будет удвоена, и вам будет предоставлена ​​только одна дополнительная карта для добавления к вашей руке. В качестве альтернативы вы можете использовать опцию Hit card чтобы получить дополнительные карты, чтобы увеличить ценность вашей руки. Вы можете брать несколько карт, пока не будете удовлетворены ценностью своей руки и не решите стоять. Обратите внимание, что игроку с мягкой рукой, равной 21, не будут предоставлены варианты.",
        headingSplit: "Разделить На Два",
        descSplit:
          "Если ваша первая рука состоит из двух карт одного ранга, вы можете разделить пару на две разные руки, где каждая из них имеет ставку, равную вашей основной ставке. После того, как обе руки раздадут вторую карту, вы можете увеличьте ценность этих двух рук, применив опцию Hit. Здесь вы можете выбрать опцию Stand, когда вы удовлетворены ценностью обеих рук. Однако, когда вы разделите первую пару Тузов, обе руки автоматически получат дополнительную карту и не будет возможности получить другую карту",
      },
    },
    actions: {
      desc: "Во время раунда торговли игрок может выполнить одно из следующих действий.",
      call: {
        heading: "Call",
        desc: "Если ставка была сделана, это означает, что игрок должен покрыть ставку. Например, если игрок A ставит 10, а игрок B видит ее, игрок B должен покрыть свою ставку в 10 и положить 10 в банк",
      },
      check: {
        heading: "Check",
        desc: "Игрок может сыграть чек, если в текущем раунде не было сделано ни одной ставки. Действие переходит к следующему игроку слева. Чек можно интерпретировать как своего рода пас; игрок может оставаться в игру, но решили не делать ставки на текущий раунд.",
      },
      raise: {
        heading: "Raise",
        desc: "Игрок может увеличить текущий размер ставки. Для этого ставка должна как минимум удвоить ставку предыдущего игрока. Чтобы продолжить борьбу за банк, все следующие игроки должны увидеть эту ставку, снова поднять ее или спасовать.",
      },
      fold: {
        heading: "Fold",
        desc: "Фолд ​​- это отказ от борьбы за текущий банк. Если игрок пасует, карты игрока не раздаются, и игрок не может выиграть в текущем раунде.",
      },
    },
    states: {
      winning: {
        heading: "Победа",
        desc: "Один или несколько игроков с выигрышной комбинацией должны показать свои закрытые карты.",
      },
      aggressivePlayer: {
        heading: "Агрессивный игрок",
        desc: "Агрессивный игрок - последний игрок, который поднимает ставку во время раздачи. Если ставка видна и раздача заканчивается раскрытием, агрессивный игрок должен показать свои закрытые карты независимо от исхода раздачи.",
      },
      allInPlayer: {
        heading: "Игрок ALL-In",
        desc: "Если игроки делают ставку на все фишки, все они делают все ставки и раздача в конце концов заканчивается открывающей картой. Агрессивные игроки должны показывать свои закрытые карты независимо от исхода раздачи.",
      },
      desc: "Если один или несколько игроков играют ва-банк и никаких дальнейших ставок не было сделано, все остальные игроки должны показать свои закрытые карты. В противном случае игроки, которые продолжают рейзить и увеличивать банк, должны показать свои карты агрессивному игроку и победитель будет определен по правилам.",
      blackJackResult: {
        heading: "Победа",
        desc: "Если общее значение вашей руки превышает 21, вы сгораете и теряете свою ставку на эту руку. После того, как ход всех пользователей прошел, крупье показывает значение своей закрытой карты. Если рука крупье 16 или меньше, крупье должен выбрать опцию Hi, но если в этой руке мягкая карта 17 или выше, крупье должен остановиться, выбрав опцию Stand (обратите внимание, что мягкая рука содержит значение Ase, равное 11). В конце концов, вы выиграйте, когда значение вашей руки ближе к 21, чем у крупье, или когда крупье сгорает. Если ваша рука того же достоинства, что и у крупье, раунд игры заканчивается ничьей, и ваша ставка возвращается. Варианты ставок, выигрыш до 100x.",
      },
    },
  },

  updateScene: {
    downloading: "Новая версия загружается...",
    installing: "Устанавливается....",
    installedVersion: "Установленная версия",
    currentVersion: "Текущая версия",
  },

  actionTypes: {
    call: "Call",
    check: "Check",
    fold: "Fold",
    raise: "Raise",
    allin: "All-in",
  },

  tableQuickMenu: {
    standUp: "Встать",
    goToLobby: "Перейти в лобби",
    soundOn: "Звук вкл.",
    soundOff: "Звук выкл.",
    vibrationOn: "Вибрация вкл.",
    vibrationOff: "Вибрация выкл.",
    animatedGiftsOn: "Показать анимацию",
    animatedGiftsOff: "Скрыть анимацию",
    watchWin: "Watch Win",
  },

  actionPanel: {
    check: "Check",
    call: "Call",
    fold: "Fold",
    raise: "Raise",
    allin: "All-in",
    callAny: "Call Any",
    checkFold: "Check/Fold",
  },

  selectPotWindow: {
    autoPurchase: "Авто покупка",
    autoPurchaseDescription: "Автоматическая покупка при стеке равным нулю.",
    autoCharging: "Автозарядка",
    autoChargingDescription: "Автозарядка выше, когда ваш стек меньше 70%.",
    sit: "Сесть",
    minEntry: "Минимальный Вход",
    maxEntry: "Максимальный Вход",
    setQuantity: "Установить капитал",
  },

  quickWindow: {
    quickChat: "Это быстрый чат.",
    readyMessage: "Готовые сообщения чата",
    messageAim:
      "Этот чат предназначен для более быстрого общения с пользователем.",
    quickEmoji: "Быстрые Эмодзи",
  },

  tipButton: {
    thankYou: "Спасибо",
  },

  fastChatMessages: [
    "Удачи!",
    "Поздравляю!",
    "Спасибо",
    "Блеф!",
    "Пожалуйста, играйте быстрее!",
    "Новичок!",
    "Хорошая игра, браво!",
    "Какая удача!",
    "Успокойся, чемпион!",
  ],

  chatWindow: {
    tabs: {
      chat: "Чат",
      dealer: "Дилер",
      mute: "Без звука",
    },
    audiences: "Зрители",
    players: "Игроки",
    typeHere: "Введите здесь...",
    xSecondsLeft: "{seconds} секунд осталось.",
  },

  dealerChat: {
    call: "{username}  called",
    check: "{username} checke",
    raise: "{username} raised {amount} ",
    fold: "{username} folded",
    allin: "{username} is all-in",
    win: "{username} выиграл {amount}",
    winWithCards: "{username} выиграл {amount} с {hand}",
    winWithCardIcons: "{username} выиграл {amount} с {cards} ({hand})",
  },

  systemMessages: {
    roomClosing:
      "Сервер перезагрузится через 2 минуты. Сыграны последние руки.",
    teamTableAlert:
      "Этот командный стол. Несколько аккаунтов могут сидеть и увеличивать ставки за столом. Аккаунты не будут забанены!",
    giftSentChatMessage: "{from} отправил {gift_name} подарок {to}.",
    inviteRequestSuccess: "Invitation sent successfully.",
  },
  blackJack: {
    hit: "Ударить",
    stand: "Встать",
    InsufficientChips: "Недостаточно фишек",
    undo: "ОТМЕНИТЬ",
    double: "УДВОИТЬ",
    repeat: "ПОВТОРИТЬ",
    xSecondsLeft: "{seconds} секунд осталось.",
    gameHistory: "История Игр",
    croupier: "Крупье",
    you: "Вы",
    main: "Главный",
    anyPair: "Любая пара",
    result: "Результат",
    previousGame: "Предыдущая игра",
    returnToList: "Вернуться к списку",
    nextGame: "Следующая игра",
    type: "Тип",
    pay: "Оплатить",
    winningsHands: "Выигрышные руки",
    rules: "Правила",
    otoPurchase: "Автопополнение",
    otoPurchaseText:
      "Если вы повторно войдете в систему, ваш стек будет сброшен.",
    blackjactSitInfo:
      "Игра играется против крупье. Неограниченное количество игроков может играть против крупье.",
    bjRules: `
            * Цель Turn Blackjack состоит в том, чтобы набрать больше очков, чем у крупье, но не более 21; <br>
            * Лучшая рука называется Блэкджек - общая стоимость первых двух карт, сданных в этой руке, равна 21;<br>
            * Вы играете только против крупье, а не против других игроков;<br>
            * В блэкджек играют восемью колодами;<br>
            * Крупье всегда останавливается на 17;<br>
            * Можно удвоить на любых двух стартовых картах;<br>
            * Стартовые карты одного достоинства можно разделить на две;<br>
            * Сплит можно делать каждой рукой;<br>
            * На каждый сплит двойного туза сдаётся одна карта;<br>
            * Дубль нельзя сделать после разделения карт;<br>
            * Страховка рекомендуется, когда у крупье туз;<br>
            * Блэкджек оплачивается 3:2;<br>
            * Страховка выплачивается 1:2;<br>
            * Когда руки равны, это ничья.<br>

            <hr>

            <strong>Правила игры</strong><br>

            * Игрой управляет крупье, и за раздачу может играть неограниченное количество игроков;<br>
            * Игра представлена ​​крупье и ведется восемью стандартными колодами по 52 карты. 21 значение карты в игре:<br>
            - На картах от 2 до 10 указаны номиналы.<br>
            - Карты с картинками (валеты, дамы и короли) приносят 10 очков.<br>
            - Тузы стоят 1 или 11, игрок может выбрать подходящее значение в соответствии со своей рукой. Обратите внимание, что мягкая рука содержит достоинства туза 11;<br>
            * Когда время ставок истекло, крупье раздает открытую карту всем игрокам, последнюю себе. После этого крупье раздает вторую карту лицом вверх всем игрокам, но вторая карта крупье будет закрыта. Стоимость вашей первой руки отображается рядом с вашими картами.<br>
            * Блэкджек (двадцать одно) = Если две карты, которые вы получили в начале игры, составляют в сумме 21, у вас Блэкджек!<br>
            <br>
            <strong>Страховка</strong>
            <br>
            * Если у крупье открытой картой является туз, вы можете купить страховку от риска того, что у крупье будет 21, даже если ваша общая рука равна 21. Страховая сумма будет равна половине вашей основной ставки, а страховая ставка будет обрабатываться отдельно от вашей ручной ставки. После этого крупье смотрит на свою закрытую карту, чтобы проверить, не выпал ли он 21.<br>
            - Если крупье не набирает 21, раунд продолжается. <br>
            - Если у крупье есть Блэкджек, а у вас нет, игра окончена и выигрывает крупье.<br>
            - Если и у вас, и у крупье будет 21, игра завершится вничью, и ваша ставка будет возвращена.<br>
            <br>
            Обратите внимание, что если у крупье открытая карта 10 или цветная карта, у вас будет возможность купить страховку, и крупье не будет смотреть свою закрытую карту на 21.
            <br>
            <strong>Удвоить, ударить или встать</strong>
            <br>
            Если крупье обнаруживает, что после проверки своих первых двух карт он не получил 21, игрокам предоставляется право выбрать больше карт, чтобы улучшить ценность своей руки, дав крупье больше карт игрокам.
            <br>
           Если ваша первая рука не стоит 21, вы имеете право удвоить ставку. В этом случае ваша ставка будет удвоена, и вам будет предоставлена ​​только одна дополнительная карта для добавления в вашу руку. В качестве альтернативы вы можете использовать опцию Hit (Ударить), чтобы получить дополнительные карты, которые повысят ценность вашей руки. Вы можете выбрать несколько карт, пока не будете удовлетворены ценностью своей руки и не решите Встать.
            <br>
            Обратите внимание, что игроку с мягкой рукой, равной 21, не будет предоставлен вариант решения.
            <br>

            <strong>Разделить На Два</strong><br>
            <br>
           Если ваша первая рука состоит из двух карт одного ранга, вы можете разделить пару на две разные руки, где каждая имеет ставку, равную вашей основной ставке. После того, как обе руки получили вторую карту, вы можете увеличить ценность этих двух рук, применив опцию Hit (Ударить). Здесь вы можете выбрать опцию Stand (Встать), когда вас устраивает ценность обеих рук. Однако, когда вы разделяете первую пару Тузов, обе руки автоматически получают дополнительную карту и не имеют шанса получить еще одну карту.
            <br>
            <strong>Результат</strong>
            <br>
            Если общее значение вашей руки превышает 21, вы сгораете и теряете свою ставку на эту руку.
            <br>
            После того, как ход всех пользователей прошел, крупье показывает значение своей закрытой карты. Если рука крупье 16 или меньше, крупье должен выбрать опцию Hit Ударить, но если эта рука мягкая 17 или выше, крупье должен остановиться, выбрав опцию Stand (Встать) (обратите внимание, что мягкая рука содержит значение Туза, равное 11).
            <br>
           В конце концов, вы выигрываете, когда значение вашей руки ближе к 21, чем у крупье, или когда крупье сгорает. Если ваша рука того же достоинства, что и у крупье, раунд игры заканчивается ничьей, и ваша ставка возвращается.
            <br>Играйте с дополнительными ставками, выигрывайте до 100x.`,
    settings: "Настройки",
    sounds: "Звук",
    bet: "СТАВКА",
    waitOtherPlayers: "Пожалуйста, подождите других игроков",
    placeYourBet: "Сделать ставку",
    choose: "Выбрать",
    wantInsurance: "Хотите страховку?",
    draw: "НИЧЬЯ",
    busted: "ПЕРЕБОР",
    youLost: "Вы проиграли",
    sit: "СЕСТЬ",
    otoPurchase: "Авто-покупка",
    otoPurchaseText: "При повторном входе фишки на столе обнуляются",
    okay: "ХОРОШО",
    insufficientBalance: "У вас недостаточный баланс, чтобы сесть.",
    ShufflingCards: "Перетасовка колоды карт...",
    youWon: "Вы выиграли",
    won: "ВЫИГРАЛ",
    dealNow: "СДЕЛКА",
    header: "Пополнение фишками из основого банка",
  },
  useInviteCode: {
    heading: "Пригласительный код",
    description: "Пожалуйста, введите пригласительный код ваших друзей.",
    unset: "Удалить пригласительный код",
    inviteCodeNotFound: "Неверный пригласительный код.",
  },
  profileSettings: {
    profileSettings: "Настройки профиля",
    RegistrationDate: "Дата регистрации",
    use: "ИСПОЛЬЗОВАТЬ",
    gift: "ПОДАРОК",
    buyVip: "Купить VIP",
    onlyVip: "Только VIP игроки могут приобрести этот подарок.",
    frame: "Рамка",
    buy: "Купить",
    statistics: {
      statistics: "Статистика",
      handPlayed: "Количество Прокруток",
      biggestPotWon: "Самый большой банк",
      biggestJackPotWon: "Самый большой Джекпот",
      recordChip: "Рекордное колиство фишек",
      brokenPotCounter: "Счетчик выигранных банков",
      winPercentage: "Процент выигрышей",
      LeadershipRanking: "Ранк",
      bestWinningHand: "Лучшая выигранная рука",
      biggestWin: "Самый крупный выигрышь",
    },
  },
  userProfile: {
    noteUpdated: "Your profile note has been updated",
  },
  settings: {
    lobbyMusic: "Музыка Лобби",
    soundEffects: "Звуковые эффекты",
    vibration: "Вибрация",
    blockInvites: "Приглашения",
    giftHide: "Скрыть подарок",
    handStrengthIndicator: "Измеритель силы рук",
    GraphicsPreferences: "Настройки графики",
    tableColor: "Цвет стола",
    roomBgColor: "Фон",
    languagePreference: "Языковые предпочтения",
    termsOfUse: "Условия использования",
    accountInfo: "Информация об учетной записи",
    profileID: "Идентификатор игргока",
    deviceNO: "Устройство",
    deleteAccount: "Удалить аккаунт",
    language: {
      turkish: "Турецкий",
      english: "Английский",
      italian: "Итальянский",
      indonesian: "Индонезийский",
      portuguese: "Португальский",
      russian: "Русский",
      malesian: "Малезийский",
    },
  },
  vip: {
    available: "VIP / {day} дней",
    noVip: "VIP недоступен",
  },
  friends: {
    online: "ОНЛАЙН",
    goToTable: "Присоединиться",
    chipTransfer: "Отправить фишки",
    friendRequests: "Запросы на добавление в друзья",
    incoming: "ВХОДЯЩИЕ",
    sent: "ОТПРАВЛЕНО",
    search: "ПОИСК",
    addButtonText: "Добавить",
    removeFriendNotification: "Removed from friend list",
    userProfile: "Профиль Пользователя",
    sendChipText: "Отправить",
    deletefriends: "Удалить",
  },
  progressiveJackpot: {
    join: "Присоединиться",
    desc1: "Вы платите фишками, равными малому блайнду за руку.",
    desc2:
      "Когда вы выиграете, вы получите приз, равный МАЛЫЙ БЛАЙНД ЗА РУКУ x Коэффициент.",
    desc3: "Это общий пот фишек, собранных на одном уровне ставки",
  },
  pinCode: {
    wrong: "Неверный пин-код!",
    pinCode: "ПИН-КОД",
    removePinCode: "Удалить пин-код",
    enterPin: "Пожалуйста, введите пин-код вашего аккаунта",
    reset: "Сбросить мой пароль!",
    logOut: "ВЫЙТИ",
    resetPin: "СБРОСИТЬ ПИН-КОД",
    enterEmail:
      "Пожалуйста, введите адрес электронной почты вашей учетной записи",
    mail: "Электронная почта",
    newPass: "Ваш новый пароль отправлен на ваш адрес электронной почты",
    wrongMail: "введен неправильный адрес электронной почты",
    failRemove: "Не удалось удалить пин-код",
    failAdd: "Не удалось сохранить пин-код",
  },
  redeem: {
    used: "Игровая карта уже использована!",
    expired: "Срок действия игровой карты истек!",
    failed: "Не удалось подтвердить игровую карту",
    validGameCard: "Введите действительную игровую карту",
  },
  level: {
    levelUpToastText:
      "Поздравляем! Вы достигли уровня {levelNumber}. <strong>Награда: {reward}</strong>",
  },
  inviteFriend: {
    inviteFriendText:
      "Вы пригласили своего друга, и вы оба получили 10B фишек!",
  },
  slotWinnerToast: {
    bigWinText: "{username} выиграл {amount} в слот!",
  },
  toast: {
    inviteRequestText: "{name} приглашает вас сыграть в {game}.",
  },
  updatedNotes: {
    title: "Примечания к Обновлению",
    notes: [
      "<div class='center'><span class='title'>Poker</span></div>",
      "Фоновый звук отключен по умолчанию",
      "50 игроков в списке Лидеров",
      "Доступ к функциям «Поиск» и «История рук» за столом",
      "Во внутриигровой чат могут писать только игроки, играющие за столом",
      "Потрясающий прозрачный игровой чат с левой стороны",
      "Совершение действий (колл/фолд/чек/рейз) при открытом чате",
      "Крутой таймер обратного отсчета",
      "<div class='center'><span class='title'>Blackjack</span></div>",
      "Комфортные увеличенные карты и текст",
      "Исправленные ошибки",
    ],
  },
  forceUpdateWindow: {
    title: "Доступна новая версия!",
    text: "Пожалуйста, обновите приложение до последней версии.",
  },
  table: {
    noHaveOnlineFriends: "У вас нет друзей в сети.",
    onlineFriends: "Друзья Онлайн",
    noHaveFriends: "You don't have any friends",
  },
  others: {
    friendRemovedInfo: "Друг был удален",
    notFoundFriend: "Friend is not found",
    notFoundDisplay: "User is not found",
    friendTableNotFound: "Friend’s table is not found",
    tableInFriendNotFound: "No player on the table",
    requiredSelectedGift: "Select Gift",
    currentLocationNotAvailableBuy:
      "Purchasing is unavailable for your country",
    requiredLevelFive: "Level 5 is required",
    blackjackStatusInactive: "Blackjack is not available",
    slotStatusInactive: "Slot is not avalable",
    muteAll: "Mute",
    changeNameSuccesInfo: "Имя пользователя изменено У вас осталось {0}",
    guestClose:
      "Вы не можете войти в систему как гость. Пожалуйста, войдите с другой учетной записью",
  },
};
