<template>
  <div class="win-amount-indicator" v-if="$data.show && $data.amount > 0">
    <div class="win-amount-indicator-inner">
      <div class="top-glow-border"></div>
      <div class="you-win-text">{{ $t("blackJack.youWon") }}</div>
      <div class="amount-text">
        <AnimatedNumber :number="$data.amount" :format="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import AnimatedNumber from "../../Utils/AnimatedNumber";

export default {
  name: "WinAmountIndicator",
  components: { AnimatedNumber },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    player() {
      return this.user;
    },
  },
  watch: {
    "getActiveRoomState.winners": function (newState, oldState) {
      if (newState.length === 0) {
        this.show = false;
        return false;
      }
      if (newState.length === oldState.length) return false;

      const currentPlayerIndex = newState.findIndex(
        (item) => item.playerId === this.nGetMySeat.id
      );
      if (currentPlayerIndex < 0) return false;

      this.$plugins.audio.play("BjWin");
      this.show =
        !this.nGetMySeat.busted &&
        this.nGetMySeat.handStrength !==
          this.getActiveRoomState.dealer.handStrength;
      this.amount = newState[currentPlayerIndex].amount;
    },
  },
  data() {
    return {
      show: false,
      amount: 0,
    };
  },
};
</script>

<style scoped lang="scss">
@-webkit-keyframes winAmountIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes winAmountIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes winAmountInnerGlowAnimation {
  0% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(-2px);
  }
}
@keyframes winAmountInnerGlowAnimation {
  0% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(-2px);
  }
}

.win-amount-indicator {
  position: absolute;
  z-index: 9;
  top: 100px;
  width: 530px;
  height: 107px;
  background-image: linear-gradient(
    90deg,
    transparent 0%,
    rgba(0, 0, 0, 0.5) 20%,
    rgba(0, 0, 0, 0.7) 50%,
    rgba(0, 0, 0, 0.5) 80%,
    transparent 100%
  );
  color: rgb(255, 255, 255);
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: center;
  animation-name: winAmountIncomingAnimation;
  animation-iteration-count: 1;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  &-inner {
    position: relative;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      top: -40%;
      left: 0;
      width: 100%;
      height: 90%;
      background-image: radial-gradient(
        rgba($greenColor, 0.8),
        transparent 65%
      );
    }
    .top-glow-border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-image: linear-gradient(
        90deg,
        transparent 20%,
        rgba($greenColor, 0.8),
        transparent 80%
      );
      animation-name: winAmountInnerGlowAnimation;
      animation-iteration-count: 1;
      animation-duration: 0.3s;
      animation-delay: 0.3s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }
  }
  .you-win-text {
    color: $greenColor;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0 2px 0 rgba(black, 0.8);
    text-align: center;
  }
  .amount-text {
    margin-top: 10px;
    font-size: 28px;
    font-weight: bold;
    text-shadow: 0 2px 0 rgba(black, 0.8);
    text-align: center;
  }
}
</style>
