import "capacitor-plugin-app-tracking-transparency"; // only if you want web support
import { AppTrackingTransparency } from "capacitor-plugin-app-tracking-transparency";

export default {
  instance: AppTrackingTransparency,

  async getStatus() {
    const response = await AppTrackingTransparency.getStatus();

    // { status: 'authorized' } for example

    return response;
  },

  install(Vue) {
    Vue.prototype.$plugins.appTrackingTransparency = AppTrackingTransparency;
  },

  async init() {
    const response = await AppTrackingTransparency.requestPermission();
    // { status: 'authorized' } for example
    return response;
  },
};
