<template>
  <div
    class="card"
    v-bind="$attrs"
    :class="[getFlippedClass, $props.value == 10 ? 'ten-case' : '']"
    v-if="index !== -1"
  >
    <div class="card-cutter" v-if="$props.cutter"></div>
    <div class="card-front">
      <div class="card-front-top">
        <div
          class="card-value"
          :class="[getValueColor, $props.value == 10 ? 'ten-case' : '']"
        >
          {{ value }}
        </div>
        <div class="card-suit" :class="[suit]"></div>
      </div>
      <div class="card-front-bottom">
        <div class="card-suit" :class="[suit]"></div>
        <div
          class="card-value"
          :class="[getValueColor, $props.value == 10 ? 'ten-case' : '']"
        >
          {{ value }}
        </div>
      </div>
    </div>
    <div class="card-back">
      <div class="card-bg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    index: {
      type: [Number, String],
      required: false,
      default: -2,
    },
    suit: {
      type: String,
      required: false,
      default: "NO-SUIT",
    },
    value: {
      type: [Number, String],
      required: false,
      default: "NO-VALUE",
    },
    cutter: {
      type: Boolean,
      required: false,
      default: false,
    },
    playSounds: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    index(newIndex, oldIndex) {
      if (parseInt(newIndex) > -1 && parseInt(oldIndex) === -2) {
        if (this.$props.playSounds) this.$plugins.audio.play("BjCard");
        this.flipped = false;
      }
    },
  },
  data() {
    return {
      flipped: true,
      flippedTimeout: 0,
    };
  },
  computed: {
    getFlippedClass() {
      return this.flipped ? "flipped" : "";
    },
    getValueColor() {
      return ["h", "d"].includes(this.$props.suit) ? "red" : "black";
    },
  },
  mounted() {
    if (this.flippedTimeout) {
      clearTimeout(this.flippedTimeout);
    }

    this.flippedTimeout = setTimeout(() => {
      if (this.index === -2) return false;
      if (this.$props.playSounds) this.$plugins.audio.play("BjCard");
      this.flipped = false;
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
@-webkit-keyframes flip {
  0% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
@keyframes flip {
  0% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes cardIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes cardIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card {
  position: relative;
  width: 53px;
  height: 76px;
  border-radius: 3px;
  flex-shrink: 0;
  transition: all 0.25s ease;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  animation-name: cardIncomingAnimation;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  .card-cutter {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, darkred, darken(red, 15%));
    border-radius: 3px;
    z-index: 5;
  }
  &.flipped {
    .card-front {
      transform: rotateY(180deg);
    }

    .card-back {
      transform: rotateY(0deg) !important;
    }
  }

  &-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 2px;
    border-radius: inherit;
    background-color: rgb(255, 255, 255);
    background-image: linear-gradient(-180deg, rgb(255, 255, 255), #ececec);
    transform: rotateY(-180deg);
    backface-visibility: hidden;
    box-shadow: 0 1px 3px 0 rgba(black, 0.7);
    transition: all 0.5s ease-in-out;

    .card-bg {
      position: relative;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      border-radius: inherit;
      background-image: url("~@/assets/images/card/back.png");
    }
  }

  &.blue {
    .card-bg {
      filter: hue-rotate(220deg) !important;
    }
  }

  &-front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    background-image: linear-gradient(-180deg, rgb(255, 255, 255), #ececec);
    border-radius: inherit;
    box-sizing: border-box;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 2px 4px 0 rgba(black, 0.7);
    padding: 2px;
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;

    &-top,
    &-bottom {
      width: 100%;
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
    .card-value {
      width: 50%;
      color: $cardBlack;
      font-family: $cardFont;
      font-size: 34px;
      text-align: left;
      text-indent: 1px;
      &.ten-case {
        font-size: 30px;
        text-indent: -1px;
      }

      &.red {
        color: $cardRed;
      }
    }

    .card-suit {
      width: 50%;
      height: 18px;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;

      &.s {
        background-image: url("~@/assets/images/blackjack/card/spades.png");
      }

      &.d {
        background-image: url("~@/assets/images/blackjack/card/diamonds.png");
        background-size: 78%;
      }

      &.c {
        background-image: url("~@/assets/images/blackjack/card/clubs.png");
      }

      &.h {
        background-image: url("~@/assets/images/blackjack/card/hearts.png");
      }
    }
    &-bottom {
      .card-value,
      .card-suit {
        transform: rotate(180deg);
      }
    }
  }

  &.ten-case {
    .card-front {
      padding: 3px;
    }
  }
}
</style>
