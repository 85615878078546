import AppConstants from "@/constants/app";

export default {
  /*
   *   Returns object which included level(number) and current xp in level
   */
  getLevel(state, getters, rootState) {
    return (experience) => {
      if (experience <= 0) {
        return {
          currentXp: 0,
          name: "level_1",
          number: 1,
          prevLevelRequiredXp: 0,
          prize: 0,
          requiredXp: 6,
        };
      }
      const levels = [...(rootState.levels || [])].sort(
        (l1, l2) => l2.number - l1.number
      );

      let prevLevelRequiredXp = 0;
      let currentLevel = {
        currentXp: experience,
        ...levels[levels.length - 1],
      };
      levels.forEach((level) => {
        if (experience < level.requiredXp) {
          currentLevel = level;
          const previousLevelIndex = levels.findIndex(
            (l) => l.number === level.number - 1
          );
          if (previousLevelIndex > -1) {
            prevLevelRequiredXp = levels[previousLevelIndex].requiredXp;
          } else {
            prevLevelRequiredXp = 0;
          }
        }
      });

      return {
        prevLevelRequiredXp,
        currentXp: experience,
        ...currentLevel,
      };
    };
  },

  getMyReferences(state) {
    return state.affiliateStatus.references;
  },

  getAllMyReferences(state) {
    return state.allAffiliatePlayer;
  },

  getUserGameHistory(state) {
    return state.userGameHistory;
  },

  getAvatarUrl() {
    return (playerId) => {
      if (playerId === -1) return AppConstants.defaultAvatarUrl;
      return `${AppConstants.apiUrl}/user/avatar/${playerId}`;
    };
  },
};
