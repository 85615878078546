import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// APP
import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

// MODULES
import modals from './modules/modals'
import authentication from './modules/authentication'
import service from './modules/service'
import user from './modules/user'
import bonus from './modules/bonus'
import preferences from './modules/preferences'
import friends from './modules/friends'
import products from './modules/products'
import userCase from './modules/userCase'
import chipTransfer from './modules/chipTransfer'
import chat from './modules/chat'
import slots from './modules/slots'
import top50 from './modules/top50'
import announcements from './modules/announcements'
import report from './modules/report'
import gift from './modules/gift'
import jackpots from './modules/jackpots'
import pin from './modules/pin'

const modules = {
  modals,
  authentication,
  service,
  user,
  bonus,
  preferences,
  friends,
  products,
  userCase,
  chipTransfer,
  chat,
  slots,
  top50,
  announcements,
  report,
  gift,
  jackpots,
  pin
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules
})
