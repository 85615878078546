<template>
  <div class="error-scene">
    <div class="heading">{{ $t("errors.errorOccured") }}</div>
    <div class="description">{{ $t("errors.gameServiceError") }}.</div>
    <div class="button-area">
      <button type="button" class="refresh-button" @click="refreshApplication">
        {{ $t("general.refresh") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  methods: {
    refreshApplication() {
      window.location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
.error-scene {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgb(255, 255, 255);
  background-image: linear-gradient(0deg, black, lighten(black, 10%), black);
  .heading {
    font-weight: bold;
    font-size: 56px;
  }
  .description {
    margin-top: 50px;
    font-size: 42px;
  }
  .button-area {
    margin-top: 90px;
    .refresh-button {
      display: block;
      text-align: center;
      width: auto;
      height: 90px;
      padding: 0 80px;
      font-size: 42px;
      background-color: transparent;
      border: 5px solid rgb(255, 255, 255);
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      font-weight: bold;
      line-height: 60px;
      transition: all 0.5s ease;
      &:active {
        box-shadow: 0 0 0 10px rgba(rgb(255, 255, 255), 0.35);
      }
    }
  }
}
</style>
