export default {
  newAnimation(state, data) {
    state.animations.push(data);
  },
  newGift(state, data) {
    if (data && data.hasOwnProperty("to") && data.to.length > 0) {
      data.to.forEach((toId) => {
        const innerData = { ...data };
        delete innerData.to;

        const giftToIdFindIndex = state.gifts.findIndex(
          (item) => item.to === toId
        );

        if (giftToIdFindIndex > -1) {
          state.gifts.splice(giftToIdFindIndex, 1);
        }
        state.gifts.push({ ...innerData, to: toId, completed: false });
      });
    }
  },
  giftAnimationCompleted(state, { from, to }) {
    const findIndex = state.gifts.findIndex(
      (item) => item.from === from && item.to === to
    );
    if (findIndex > -1) {
      state.gifts[findIndex].completed = true;
    }
  },
  giftRemoveByUserId(state, id) {
    const giftFindIndex = state.gifts.findIndex((item) => item.to === id);
    if (giftFindIndex > -1) {
      state.gifts.splice(giftFindIndex, 1);
    }
  },
  giftAllRemove(state) {
    state.gifts.splice(0, state.gifts.length);
  },
  deleteAnimation(state, id) {
    const newAnimations = state.animations.filter(
      (animation) => animation.from !== id
    );
    state.animations = newAnimations;
  },

  newDeleteAnimation(state, { from, id }) {
    const animationIndex = state.animations.findIndex(
      (item) => item.from === from && item.id === id
    );
    if (animationIndex > -1) {
      state.animations.splice(animationIndex, 1);
    }
  },
  allDeleteAnimation(state) {
    state.animations.splice(0, state.animations.length);
  },
};
