var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mute-container"},[(_vm.getPlayers.length > 0)?[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.$t("chatWindow.players")))]),_c('div',{staticClass:"hide-show-container"},[_c('span',{staticClass:"hide-show-container-text"},[_vm._v(_vm._s(_vm.$t("others.muteAll")))]),(_vm.status)?_c('div',{staticClass:"on-part",on:{"click":_vm.muteAll}},[_c('div',{staticClass:"sprite-profile-popup button-red-s"}),_c('span',{staticClass:"sprite-profile-popup circle-button right"})]):_vm._e(),(!_vm.status)?_c('div',{staticClass:"off-part",on:{"click":_vm.muteAll}},[_c('div',{staticClass:"sprite-profile-popup button-green-s"}),_c('span',{staticClass:"sprite-profile-popup circle-button"})]):_vm._e()])]),_c('div',{staticClass:"list"},_vm._l((_vm.getPlayers),function(player,playerIndex){return _c('div',{key:'muted-player-' + playerIndex,staticClass:"list-row",on:{"click":function($event){return _vm.muteOrUnmutePlayer(player.id)}}},[_c('div',{staticClass:"player-avatar"},[_c('Avatar',{attrs:{"url":player.avatar,"crown":player.crown,"size":"90"}}),_c('div',{staticClass:"player-name"},[_vm._v(_vm._s(player.name))])],1),_c('div',{staticClass:"mute-status",class:[
            'sprite-table-pop-up',
            _vm.isMutedPlayer(player.id) ? 'sound-off-icon' : 'sound-on-icon',
          ]})])}),0)]:_vm._e(),(_vm.getAudiences.length > 0)?[_c('div',{staticClass:"title audiences"},[_vm._v(_vm._s(_vm.$t("chatWindow.audiences")))]),_c('div',{staticClass:"list"},_vm._l((_vm.getAudiences),function(player,playerIndex){return _c('div',{key:'muted-player-' + playerIndex,staticClass:"list-row",on:{"click":function($event){return _vm.muteOrUnmutePlayer(player.id)}}},[_c('div',{staticClass:"player-avatar"},[_c('Avatar',{attrs:{"url":player.avatar,"crown":player.crown,"size":"90"}}),_c('div',{staticClass:"player-name"},[_vm._v(_vm._s(player.name))])],1),_c('div',{staticClass:"mute-status",class:[
            'sprite-table-pop-up',
            _vm.isMutedPlayer(player.id) ? 'sound-off-icon' : 'sound-on-icon',
          ]})])}),0)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }