/*ÖNDERİLEN
 * English language file
 */

export default {
  loading: "Yükleniyor...",

  general: {
    no: "Hayır",
    yes: "Evet",
    avatar: "Avatar",
    vipIcon: "Vip Icon",
    tableInformation: "Masa Bilgileri",
    cancel: "İptal",
    save: "Kaydet",
    logout: "Çıkış Yap",
    play: "Oyna",
    chat: "Sohbet",
    leaders: "Liderler",
    social: "Sosyal",
    case: "Kasa",
    shop: "Mağaza",
    okay: "Tamam",
    refresh: "Yenile",
    send: "Gönder",
    username: "Kullanıcı adı",
    delete: "Sil",
    reject: "Reddet",
    accept: "Kabul Et",
    getGift: "Hediyeni Al",
    confirm: "Onayla",
    registrationDate: "Kayıt Tarihi",
    name: "İsim",
    total: "Toplam",
    copy: "Kopyala",
    commission: "Komisyon",
    you: "Sen",
    transfer: "Transfer",
    menu: "Menü",
    all: "Tümü",
    update: "Güncelle",
    invite: "Davet Et",
    joinNow: "Şimdi Katıl",
    handHistory: "El Geçmişi", //NEW***********
  },
  giftsWindow: {
    gift: "Hediye", //NEW***
    getYourself: "Kendine al", //NEW***
    getEveryone: "Herkese al", //NEW***
    expression: "Emoji", //NEW***
    selectGift: "Lütfen bir hediye seçin", //NEW***
    noPlayersAtTable: "Masada oyuncu yok", //NEW***
    sitATable: "Lütfen önce masaya oturun", //NEW***
    selectUser: "Lütfen bir kullanıcı seçin", //NEW***
  },
  dailyEntry: {
    dailyEntry: "Günlük Bonus", //NEW***
    dailyDesc: "Her gün giriş yapın ve daha fazla fiş kazanın!", //NEW***
    day: "Gün", //NEW***
  },
  noficationWindow: {
    notificationInviteTable:
      "{name} seni masaya davet etti {gameType}{tableId} at {date}", // NEW***
    notificationInviteChip: " {name} Seni gönderdi {amount} chip.", // NEW***
    sendMessage: " Lütfen mesajınızı buraya yazın", // NEW***
    tableInvite: "Davetler", // NEW***
    system: "Sistem", // NEW***
    messages: "Mesajlar", // NEW***
    notifications: "Bildirimler", // NEW***
  },
  authentication: {
    signInToContinue: "Devam etmek için oturum açın",
    anErrorOccuredWhileLoggingIn: "Giriş yaparken bir hata oluştu!",
    contact: "İletişim",
    privacyPolicy: "Gizlilik Politikası",
    provablyFair: "Adil Oyun",
    sign_guest: "Misafir Girişi",
    sign_apple: "Apple ile Giriş ",
    sign_google: "Google ile Giriş",
    sign_facebook: "Facebook ile Giriş",
    loginBonusText:
      "<p class='login-info-text'> <span class='color-white'> {0} </span> veya <span class='color-white'> {1} </span> <br/> Ekstra bonus fiş kazan! </p>",
    loginBonusTextWeb:
      "<p class='login-info-text'> <span class='color-white'> {0} </span> ile bağlan <br/> Ekstra bonus fiş kazan! </p>",
  },
  playerActions: {
    enoughChips: "Yeterli fiş yok",
    minSendChipAmount: "Fiş gönderme miktarı 10B'nin altında olamaz",
    failedTransfer: "Fiş aktarılamadı",
    successTransfer: "Fişler başarıyla aktarıldı.",
    validChip: "Lütfen geçerli bir fiş miktarı giriniz",
  },
  tableList: {
    table: "Tablo",
    tableId: "Masa Id",
    tableName: "İsim",
    blind: "Bahisler",
    minMax: "Min/Max",
    filterByBlinds: "Bahislere göre filtrele",
    hideEmpties: "Boş Masaları Gizle",
    hideFullyFilled: "Dolu Masaları Gizle",
    all: "Hepsi",
    players: "Oyuncular",
    full: "Dolu",
    noRoomsMessage: "Oda yok.",
    tableList: "MASA LİSTESİ",
    tableInfo: {
      emptyRoomMessage: "Oda boş.",
    },
  },
  message: {
    message: "Mesaj",
    sendMessage: "Mesaj gönder",
    typeMessage: "Mesajınızı buraya yazın",
    sentMessage: "sana bir mesaj gönderdi",
  },

  lobby: {
    play: "Oyna",
    playNow: "Şimdi oyna",
    newSlotGame: "Yeni Slot Oyunu",
    availableNow: "Yayında",
    specialForYou: "Size özel kampanya",
    favouriteGameButton: {
      instructionMessage: "Değiştirmek için Çift Dokun",
    },
  },

  bonusWindow: {
    congratulations: "Tebrikler!",
    accept: "Kabul et",
  },

  maintenance: {
    gameMaintenance: "Oyun Bakımda",
    underMaintenance: "Bakımda",
    tryAgainLAter:
      "Oyunumuz şu anda bakımdadır. Lütfen daha sonra tekrar deneyiniz!",
  },

  confirmWindow: {
    sure: "Emin misin?",
    removeFriend: "Arkadaşlıktan Çıkar",
    userNotFound: "Kullanıcı Bulunamadı",
    notAffiliate: "Kullanıcı ortak değil",
    DaysVip: "30 Günlük vip aboneliği satın alıyorsunuz.",
  },
  deleteConfirmWindow: {
    sure: "Hesabınızı silmek üzeresiniz",
    content:
      "Bu işleme devam ederseniz kullanıcı bilgileriniz ve satın almış olduğunuz ürünler silinecektir",
    delete: "Sil",
    cancel: "İptal",
    deleteSuccessfully: "Hesap silme işlemi başarılı",
    notBeDeleted: "Silinirken bir hata oluştur",
    daysLimit:
      "Hesabınız 30 gün boyunca tekrar aktif olmazsa kalıcı olarak silinecektir.", //NEW****
    areYouSure: "Hesabınızı silmek istediğinizden emin misiniz?",
    deletefriends: "Arkadaşlar listenizden silinecektir",
  },
  historyWindow: {
    players: "oyuncular",
    hand: "El",
    preFlop: "Flop öncesi",
    flop: "Flop",
    turn: "Turn",
    river: "River",
    winner: "Kazanan",
    speacial: "Özel",
    team: "Takım",
    anyHistory: "Gösterilecek geçmişiniz yok",
  },
  socialWindow: {
    inviteCodeDescription:
      "-Yukarıdaki davet kodunu arkadaşınızla paylaşın. <br><br>- Arkadaşınızın yeni bir oyun üyesi olarak poker masalarında 10 el oynamasına izin verin. <br><br>- İkiniz de 10B fiş kazanın!<br>",

    gameCard: "Oyun Kartı",
    sharecode: "Kodu Paylaş", // NEW***
    friends: "Arkadaşlar",
    searchFriend: "Arkadaş ara",
    gameCard: "Oyun kartı",
    searchFriend: "Arkadaş Ara",
    requests: "Davetler",
    chipTransferHistory: "Hediye Fiş <br/> Transfer",
    socialMedia: "Sosyal Medya",
    socialMediaAndInviteCode: "Sosyal Medya ve Davet Kodu",
    noFriendRequest: "Arkadaşlık İsteğin Yok.",
    noFriends: "Hiç Arkadaşın Yok",
    noChipTransfer: "Chip Transfer Geçmişi Yok",
    extraChip: "Sosyal medya hesaplarımızı takip edin, bedava fişler kazanın. ",
    followUs: "Sosyal Medyada bizi takip edin!",
    inviteCode: "Davet Kodu",
    myCode: "Davet Kodu",
    copy: "Copy",
    inviteCodeDescription:
      "- Yukarıdaki referans kodunu arkadaşınla paylaş. <br><br>- Arkadaşın yeni üyeliği ile, Poker masalarında 10 el oyun oynasın. <br><br>- Hem sen, hem de arkadaşın 10B fiş kazanın!",
    shareWithFriends: "Arkadaşlarınla paylaş!",
    shareWithFriendsCode:
      "İşte bonus kodumuz! Arkadaşlarınız 10 poker eli oynadıktan sonra 10 B bonus fiş kazanın. Ne kadar çok davet kodu gönderirseniz, o kadar çok bonus fiş alacağınızdan bahsedin. Kod: {code}",
    shareInviteCodeWithFriends: "Davet kodunuzu paylaşın",
    removeFriend: "Arkadaşlıktan Çıkar",
    block: "Engelle",
    unBlock: "Engellendi",
    gameCardDesc:
      " Kazandığınız biletlerin üzerindeki kodu girerek ödülünüzü alabilirsiniz.",
  },
  friendDetailsWindow: {
    failed: "Arkadaşlık isteği gönderilemedi",
  },
  chipHistoryDetail: {
    youSent: "Gönderdin",
    chips: "Fişler",
    sentYou: "Sana",
  },
  createTableWindow: {
    bets: "Bahisler",
    createTable: "Masa Oluştur",
    seatsNumber: "Koltuk Sayısı",
    potTable: "Çanak Masası",
    pot: "Çanak Tutarı",
    teamTable: "Takım Masası",
    tellerEntrance: "Minimum Giriş",
    entryLimit: "Minimum Giriş",
    ipLimit: "IP Limit",
    create: "Oluştur",
  },
  chipTransferWindow: {
    sendChip: "Hediye Fiş Gönder",
    chipAmount: "Fiş Miktarını Giriniz. Min 10B",
    chipMessage: "Mesaj Yaz",
    warning: "Minimum fiş gönderim miktarı 10B, Limit yok!",
  },
  playerDetailsWindow: {
    profileNote: "Profil Notu",
    statistics: "İstatistik",
    level: "Seviye",
    recordChip: "Rekor Fiş",
    friendsCount: "Arkadaş Sayısı",
    bestHand: "En İyi El",
    copied: "ID no kopyalandı",
    failedChanges: "Değişiklikler kaydedilemedi",
    enterProfileNote: "Profil notunuzu girin..", //NEW****
    gameStatistics: "Oyun İstatistikleri", //NEW****
    sameFrameWarning:
      "Lütfen şu anda kullanmakta olduğunuzdan farklı bir çerçeve seçin.", //NEW****
    sameAvatarWarning:
      "Lütfen şu anda kullanmakta olduğunuzdan farklı bir avatar seçin.", //NEW****
    fillFields: "Lütfen tüm alanları doldurun", //NEW****
    passwordsDoesNotMatch: "Şifreleriniz birbiriyle eşleşmiyor", //NEW****
    passwordLength: "Şifre uzunluğu en az 6 en fazla 30 karakter olmalıdır",
    vipdesc: "Bu özellik sadece VIP oyuncular için geçerlidir.",
    changeName:
      "İsim değiştirme hakkınız bitmiştir. VIP satın alıp tekrar deneyebilirsiniz.",
  },
  shopWindow: {
    bronze: "Bronz",
    silver: "Gümüş",
    gold: "Altın",
    redeem: "Bozdur",
    keysField: "Lütfen oyun kartı kodunu alana giriniz.",
    withoutChip: "Her Masaya Giriş",
    buyChip: "Fiş satın al",
    redeemCode: "Kodu Kullan",
    noProduct: "Gösterilecek ürün bulunamadı.",
    buyVip: "VIP Satın Al",
    privateEmojis: "VIP Emoji, Hediye ve Çerçeve",
    privateProfile: "Özel VIP Profil Çerçeveleri",
    connectionNeverLost: "Kalıcı Bağlantı",
    unLimitedView: "Sınırsız İzleme Süresi",
    privateTable: "Özel Masa Açma Hakkı",
    usingPrivateCase: "Özel Kasa Kullanımı",
    unlimitedChipTransfer: "Sınırsız Fiş Gönderimi",
    xp: "10.000XP Hediye",
    freeChat: "Sınırsız Global Sohbet",
    day: "Gün",
    daysLeft: "Kalan gün",
    alreadyVip: "Zaten VIP aboneliğiniz var.",
    noChips: "VIP aboneliği satın almak için yeterli çipiniz yok",
    successfullyPurchased: "VIP aboneliğini başarıyla satın aldınız.",
    chipAdded: "Tebrikler!! Fişleriniz hesabınıza eklenmiştir.",
  },
  caseWindow: {
    onlyVip: "Bu özellik sadece VIP kullanıcılar için mevcuttur",
    buyVip: "VIP Satın Al",
    chipInGame: "Oyundaki Fiş Miktarı",
    chipInCase: "Kasadaki Fiş Miktarı",
    transactionFailed: "İşlem başarısız",
    fromGame: "Oyundan Kasaya",
    fromCase: "Kasadan Oyuna",
    amount: "Fiş Miktarını Giriniz",
    transaction: "İşlem başarıyla tamamlandı.",
    enoughChip: "Kasaya yatırmak için yeterli fişiniz yok.",
    withdraw: "Kasanızda yeterli miktarda fişiniz yok.",
    validAmount: "Geçerli bir miktar girin",
    transfer: "Aktar",
    theretentation:
      "Fişlerinizi diğer oyunculardan ve liderler tablosundan gizler.",
  },
  friendsRequestWindow: {
    friendsOffer: "BİR ARKADAŞLIK İSTEĞİ ALDINIZ!",
    successRequest: "Arkadaşlık isteği başarıyla gönderildi.",
    acceptRequest: "Arkadaş isteğini kabul etti.",
    rejectedRequest: "Arkadaşlık isteğini reddetti.",
    becomeFriends:
      "Sana arkadaşlık isteği gönderdi! <br/> arkadaş olmak ister misin",
    requestSent: "İstek zaten gönderildi",
    friendRequestToYou: "sana arkadaşlık isteği gönderdi.",
  },
  settingsWindow: {
    language: "Dil",
    gameSettings: "Oyun Ayarları",
    customize: "Özelleştirme",
    security: "Güvenlik",
    successfullySaved: "Değişiklikler başarıyla kaydedildi.",
    pinCode: {
      pinCode: "PIN KODU",
      createPinDesc: "Hesap güvenliği için bir Pin Kodu oluşturun",
      reEnterPin: "Pin Kodunu Tekrar Girin",
      emailAddress: "E-Mail Adres",
      emailRequired: "Parolanızı sıfırlamak için gerekli",
      areYouSure: "Pin kodunuzu kaldırmak istediğinizden emin misiniz?",
      pinDesc:
        "Pin Kodu oluşturmak, hesabınızın güvenliği için çok önemlidir. Turn Games, hesabınızda pin kodu olmadığı sürece, herhangi bir raporu kabul etmeyecektir.",
    },
    customizeWindow: {
      tableStyle: "Masa Stili",
      tableColors: "Masa Renkleri",
      roomBgColor: "Oda Arka Plan Rengi",
    },
    gameSettingsWindow: {
      dontDistrub: "Rahatsız etmeyin",
      blockNotification: "Bildirimler",
      showAnimatedGifts: "Hareketli Hediyeler",
      newVersion: "Yeni sürüm",
    },
  },
  reportWindow: {
    report: "Şikayet",
    reportFilter: "Rapor Filtresi",
    audiences: "İzleyiciler",
    players: "Oyuncular",
    successMessage: "Rapor başarıyla gönderildi.",
    failedMessage: "Rapor gönderilemedi.",
    swearing: "Küfür",
    team: "Takım",
    scissors: "Makas",
    noPlayersFound: "Oyuncu ve seyirci yok.",
  },
  inboxContact: {
    contactUs: "Bize Ulaşın",
    title: "Başlık",
    textHere: "Buraya Yazın",
    congratulations: "Tebrikler",
    inbox: "Gelen kutusu",
    desc: "Son satın aldığınız paket için 10.000 fiş kazandınız.",
    failedMessage: "Mesaj gönderilemedi",
    successfullyMessage: "Mesaj başarıyla gönderildi",
    validEmail: "Lütfen geçerli eposta adresini giriniz",
    fillTabs: "Lütfen Tüm Sekmeleri Doldurun",
  },
  errors: {
    connectionLost: "Bağlantı koptu",
    yourConnection: "Bağlantınız kesildi.",
    errorOccured: "Bir hata oluştu",
    gameServiceError:
      "Oyun hizmeti hatası oluştu. Lütfen daha sonra tekrar deneyiniz",
    slotDownloadError: "Oyun indirilirken bir hata oluştu.",
    loginError: "Giriş yapılırken bir hata oluştu.",
    insufficientBalance: "Yetersiz bakiye.",
    tooManyRequests: "Bu kadar sık gönderi paylaşamazsın.",
    haveToSitAtTable: "Masaya oturmak zorundasın.",
    youAreBlocked: "Engellendiniz.",
    youAreBlockedTheUser: "Kullanıcıyı engellediniz.",
    youAreUnblockedTheUser: "Kullanıcının engeli kaldırıldı.",
    chipTransferOneMonthWait:
      "Misafir kullanıcılar hesap oluşturulduktan bir ay sonra fiş transferi yapabilir.",
    userNotActiveGame: "Arkadaşınız şu anda aktif bir oyunda değildir",
    notEnoughLevelPlayGame:
      "{game} masasına katılmak için {level} level gereklidir.",
    tableNotFound: "Masa bulunamadı.",
    slotNotDownloaded: "Slot inidrilmemiş",
    playerIdWrong: "Kullanıcı Id'si hatalı",
    searchedUserNotFound: "Aranan kullanıcı bulunamadı",
  },
  searchWindow: {
    searchUser: "Oyuncu Ara",
    idOfUser: "Id numarası giriniz",
    search: "Arama",
    ownId: "Bu senin kendi kimliğin",
    validId: "Lütfen geçerli bir kimlik girin",
  },
  adBonusWindow: {
    title: "ÜCRETSİZ FİŞ KAZAN!",
    content: "Kısa bir video izle ve ${money} fiş kazan!",
    watchButton: "Şimdi İzle",
  },
  affiliate: {
    affiliate: "Referans",
    addAffiliate: "Referans Ekleyiniz",
    affiliateId: "Satış Ortağı Kimliğini Girin",
    clear: "Temizle",
    searchBy: "Oyuncu Ara",
    name: "İsim",
    noData: "Yükleniyor",
    startDate: "Başlangıç Tarihi",
    endDate: "Bitiş Tarihi",
    warning: "Ortağımız olun ve kendinize sabit bir gelir sağlayın!",
    info: "Bize nasıl katılacağınız hakkında daha fazla bilgi edinin.",
    failedAffiliate: "Referans ID eklenemedi",
    successfullyAdded: "Tebrikler referansınız başarı ile oluşturuldu.",
    date_wrong: "Başlangıç tarihi bitiş tarihinden küçük olmalı",
    affiliateIdTitle: "Referans Id",
    affiliateDesc: "Arkadaşınızın referans ID numarasını giriniz.",
    affiliateOffDesc: "Referans ID numaranız",
    excelDownloaded: "Dökümanlara İndirildi",
  },
  chat: {
    worldChat: "Global",
    directChat: "Kişisel",
    onlyVip: "Sadece VIP Kullanıcı Global Mesaj Gönderebilir",
    showMore: "Daha fazla göster",
    anyMessages: "Hiç mesajın yok.",
  },
  disconnectedWindow: {
    disconnected: "Bağlantı kesildi",
    disconnectedServer: "Hata... Sunucuyla bağlantınız kesildi!",
    reconnect: "Yeniden bağlan",
    inactive: "Hata... Çok uzun süredir hareketsizdiniz!",
    reconnected: "YENİDEN BAĞLAN",
    banned: "Oyundan engellendiniz! Üyeliğiniz askıya alınmıştır.",
    bannedDescription:
      "support@turngs.com mail adresi üzerinden iletişime geçebilirsiniz.",
  },

  rulesWindow: {
    texasDesc:
      "Texas Hold'em Poker dünyanın en popüler kart oyunudur. Oyunun amacı, rakiplerden daha yüksek bir el ile kazanmaktır. Elinizde 2 kart ve masada 5 kart var, toplam 7 karttan 5 kartın en iyi kombinasyonunu yapan el kazanır. Aşağıdaki listedeki her el bir sonraki eli yener. Örneğin, Düz Flush Four of Kind'i, Düz, Çiftleri yener. Eller güçlerine göre değerlenir.",
    omahaDesc:
      "Omaha, dünyadaki en popüler poker türlerinden biridir. Oyunun amacı potu kazanmaktır. Fiş kazanmanın 2 ana yolu vardır: rakibin elinden daha yüksek bir poker eli kazanmak veya rakibin pasını beklemek veya oyunu reddetmek. Bir el 5 kart içerir. Ellerin masadaki beş ortak karttan üçünü ve oyuncuya dağıtılan dört karttan ikisini içermesi gerektiğine dair bir kural vardır. Listedeki bir kategorideki tüm eller, altındaki kategorilerdeki tüm elleri yener. Aşağıdaki listedeki her el bir sonraki eli yener. Örneğin, Düz Flush Four of Kind'i, Düz, Çiftleri yener. Eller güçlerine göre değerlenir.",
    blackJackDesc:
      "Turn Blackjack'in amacı, 21'i aşmadan krupiyeden daha yüksek puana sahip bir ele sahip olmaktır. En iyi ele Blackjack denir - bu elde dağıtılan ilk iki kartın toplam değeri 21'dir. Sadece krupiyeye karşı oynarsınız, diğer oyunculara karşı değil.Blackjack sekiz deste ile oynanır.Krupiye her zaman 17'de durur.Herhangi iki başlangıç ​​kartında ikiye katlanabilir.Aynı değerdeki başlangıç ​​kartları ikiye bölünebilir.Bölme her elle yapılabilir.Bir her çift As paylaşımı için kart dağıtılır.Kartlar bölündükten sonra çift yapılamaz.Krupiyede bir As olduğunda sigorta önerilir.Blackjack 3:2 öder.Sigorta 1:2 öder.Eller eşit olduğunda, bu bir beraberliktir. ",
    handNames: {
      royalFlush: "Royal Flush",
      straightFlush: "Straight Flush",
      fourOfAKind: "Four Of A Kind",
      fullHouse: "Full House",
      flush: "Flush",
      straight: "Straight",
      threeOfAKind: "Three Of A Kind",
      twoPair: "Two Pair",
      pair: "One Pair",
      highCard: "High Card",
    },
    handDesc: {
      royalFlush: "Royal flush en yüksek poker elidir.",
      straightFlush:
        "Aynı türden beş ardışık kart. 2 Straight Flush el arasında, kazanan en yüksek karta sahip olandır.",
      fourOfAKind:
        "Aynı değerde dört kart. En güçlü kombinasyon, daha yüksek değerli kartlara sahip olandır. Oyuncuların elleri aynıysa, kazananı beşinci kartın (atıcı) değeri belirler.",
      fullHouse:
        "Üçlü ve ikili kombinasyon. Full House elleri karşılaştırılırken, en iyi üç karta sahip olan oyuncu kazanır. Eğer aynılarsa, en iyi çifte sahip olan oyuncu kazanır.",
      flush:
        "Aynı türden beş sırasız kart. İki veya daha fazla oyuncu aynı takıma sahipse, en yüksek karta sahip oyuncu kazanır.",
      straight:
        "İki veya daha fazla farklı türden beş ardışık kart. İki Düz el arasında kazanan, en yüksek değerli karta sahip olandır.",
      threeOfAKind:
        "Aynı değerdeki üç kartın birleşimi. İki üçlüden kazanan, en yüksek değerli kartlara sahip olandır. Üçler aynı değerdeyse, kalan iki vuruştan daha yüksek olanı kazananı belirler.",
      twoPair:
        "Aynı değerde iki kart artı başka bir değerde Two cards. Çiftleri karşılaştırırken, en yüksek çift kazananı belirler. Yüksek çiftler aynı değere sahipse, düşük çiftler karşılaştırılır. Alt çiftler aynı değerdeyse, daha yüksek değerli beşinci karta sahip olan oyuncu kazanır.",
      pair: "Aynı değerde iki kart. Oyuncular aynı çifte sahipse, kalan üç kartın en yükseği (kicker) kazananı belirler.",
      highCard:
        "Daha önce açıklanan ellerin gereksinimlerine uymayan bir poker eli. Oyuncular aynı yüksek karta sahipse, ikinci yüksek kart (vs.) kazananı belirler.",
    },
    expressions: {
      blinds: {
        heading: "Bahisler",
        desc: "Kartlar dağıtılmadan önce, krupiyenin solunda sol tarafta oturan oyuncu, küçük kör bahis adı verilen bir bahis yapmalıdır. Küçük kör bahsin solunda oturan bir sonraki oyuncu, büyük kör bahis yapmalıdır. Büyük kör bahislerin miktarı, küçük kör bahislerin miktarının iki katıdır.",
      },
      ante: {
        heading: "Ante",
        desc: "Bir oyuncu oyuna katılıyorsa, masada oturuyorsa ve büyük kör bahis konumunda değilse, o oyuncu ante adı verilen zorunlu bir bahis koyar. Bir kez yapılabilir ve ante bahis büyük kör bahise eşittir.",
      },
      minimumRaise: {
        heading: "Minimum Yükseltme",
        desc: "Bir oyuncu büyük kör bahisten daha az bir miktar artıramaz.",
      },
      allIn: {
        heading: "All In",
        desc: "Bir oyuncunun bir bahsi görmek için yeterli fişi yoksa, oyuncu 'all-in' yapabilir. Oyuncu, kalan her şeyle bahis yapar. Bir oyuncu all-in yaparsa, oyuncu gördüğünden daha fazla fiş kazanamaz. Oyuncu her şeyi yapıp kazandıktan sonra pot yükselirse, pot o oyuncu ile artırma yapabilecek bir sonraki en iyi ele sahip oyuncu arasında bölünür.",
      },
      potSplit: {
        heading: "Pot Bölmek",
        desc: "İki veya daha fazla oyuncu berabere kalırsa, pot aralarında eşit olarak bölünür.",
      },
      bjGameRules: {
        heading: "Oyun kuralları",
        desc: "Oyun bir krupiye tarafından yönetilir ve el başına sınırsız sayıda oyuncu oynayabilir. Oyun bir krupiye tarafından sunulur ve 52 kartlık sekiz standart deste ile oynanır. Oyundaki 21 kart değeri aşağıdaki gibidir. 2'den 10'a kadar olan kartların üzerinde yazılı değerler vardır. Resimli kartlar (Vale, Kız ve Papaz) 10 puan değerindedir. Aslar 1 veya 11 değerindedir, oyuncu uygun değeri seçebilir eline göre. Yumuşak elin 11 değerinde As içerdiğine dikkat edin. Bahis süresi sona erdiğinde, krupiye sonuncusu kendisine olmak üzere tüm oyunculara açık bir kart verir. Bundan sonra krupiye tüm oyunculara açık olarak ikinci bir kart dağıtır, ancak krupiyenin ikinci kartı kapalı olacaktır. İlk elinizin değeri kartlarınızın yanında gösterilir. Blackjack (Yirmi Bir) Oyunun başında size dağıtılan iki kartın toplamı 21'e ulaşırsa,",
        descInsurance:
          "Krupiyenin açık kartı As ise, toplam eliniz 21 olsa bile, krupiyenin 21'e sahip olma riskine karşı sigorta satın alabilirsiniz. Sigorta tutarı ana bahsinizin yarısına eşit olacak ve sigorta bahsi el bahsinizden ayrı olarak işlenir. Bundan sonra, krupiye 21 yapıp yapmadığını kontrol etmek için kapalı kartının değerine bakar. Eğer krupiye 21 yapmazsa, tur devam eder. Krupiyede Blackjack varsa ve siz yoksa, oyun biter ve krupiye kazanır. Hem siz hem de krupiye 21'e sahipseniz, oyun berabere biter ve bahsiniz iade edilir. Krupiyenin açık kartı 10 veya renkli ise, satın alma seçeneğiniz olacağını unutmayın. sigorta ve krupiye 21 için kapalı kartına bakmayacak.",
        headingInsurance: "Sigorta",
        headingHit: "Çift, Vur veya Dur",
        descHit:
          "Krupiye ilk iki kartını kontrol ettikten sonra 21 yapmadığını tespit ederse, oyunculara krupiyeye daha fazla kart vererek ellerinin değerini artırmak için daha fazla kart seçme hakkı verilir. 21 değerinde değil, bahsi ikiye katlama hakkınız var. Bu durumda, bahsiniz ikiye katlanır ve elinize eklemeniz için size sadece bir ek kart verilir. Alternatif olarak, elinizin değerini artırmak için ek kartlar almak için Hit card seçeneğini kullanabilirsiniz. Elinizin değerinden memnun kalana kadar birden fazla kart vurabilir ve Durmaya karar verebilirsiniz. Yumuşak eli 21'e eşit olan bir oyuncuya karar seçeneği verilmeyeceğini unutmayın.",
        headingSplit: "İkiye Böl",
        descSplit:
          "İlk eliniz aynı değerde iki karttan oluşuyorsa, çifti, her birinin ana bahsinize eşit bir bahsi olan iki farklı ele bölebilirsiniz. Her iki ele de ikinci bir kart dağıtıldıktan sonra, Hit card seçeneğini uygulayarak bu iki elin değerini artırabilirsiniz. Burada, her iki elin değerinden memnun olduğunuzda Stand seçeneğini seçebilirsiniz. Ancak, ilk As çiftini böldüğünüzde, her iki el de otomatik olarak ek bir kart alacak ve başka bir kart alma şansı olmayacaktır.",
      },
    },
    actions: {
      desc: "Bir bahis turu sırasında, oyuncu aşağıdaki işlemlerden birini yapabilir.",
      call: {
        heading: "Call",
        desc: "Bir bahis yapılmışsa, bunu görmek, bir oyuncunun bahsi karşılaması gerektiği anlamına gelir. Örneğin, Oyuncu A 10 bahis yaparsa ve oyuncu B bunu görürse, Oyuncu B 10 bahsini kapatmalı ve pota 10 koymalıdır..",
      },
      check: {
        heading: "Check",
        desc: "Bir oyuncu, mevcut turda bahis yapılmamışsa bir çek oynayabilir. Eylem, soldaki bir sonraki oyuncuya taşınacaktır. Kontrol, bir tür geçiş olarak yorumlanabilir; oyuncu oyunda kalabilir ancak mevcut turda bahis yapmamaya karar verebilir.",
      },
      raise: {
        heading: "Raise",
        desc: "Bir oyuncu bahsin mevcut boyutunu artırabilir. Bunu yapmak için, bahis, önceki oyuncunun bahsini en az ikiye katlamalıdır. Pot savaşına devam etmek için, takip eden tüm oyuncular bu bahsi görmeli, tekrar yükseltmeli veya pas geçmelidir.",
      },
      fold: {
        heading: "Fold",
        desc: "Katlama, mevcut pot için mücadele etmeyi reddetmektir. Bir oyuncu geçerse, oyuncunun kartları dağıtılmaz ve oyuncu mevcut turda kazanamaz.",
      },
    },
    states: {
      winning: {
        heading: "Winning",
        desc: "Kazanan ele sahip bir veya daha fazla oyuncu kapalı kartlarını göstermelidir.",
      },
      aggressivePlayer: {
        heading: "Aggressive Player",
        desc: "Agresif oyuncu, el sırasında bahsi artıran son oyuncudur. Bahis görülür ve el ortaya çıkarsa, agresif oyuncu elin sonucu ne olursa olsun kapalı kartlarını göstermelidir.",
      },
      allInPlayer: {
        heading: "ALL-In Player",
        desc: "Oyuncular tüm fişlere bahse girerse, her şeyi yapmış olurlar ve el sonunda açık bir kartla sona erer. Agresif oyuncular, elin sonucundan bağımsız olarak kapalı kartlarını göstermelidir.",
      },
      desc: "Bir veya daha fazla oyuncu all-in oynarsa ve başka bahis yapılmadıysa, diğer tüm oyuncular kapalı kartlarını göstermelidir. Aksi takdirde, potu yükseltmeye ve oluşturmaya devam eden oyuncular, kartlarını agresif oyuncuya göstermelidir ve kazanan kurallara göre belirlenir.",
      blackJackResult: {
        heading: "Winning",
        desc: "Elinizin toplam değeri 21'i geçerse, yanarsınız ve o eldeki bahsinizi kaybedersiniz. Tüm kullanıcıların sırası geçtikten sonra, krupiye kendi kapalı kartının değerini gösterir. Krupiyenin eli 16 veya daha azsa, krupiye Hit kartı seçeneğini seçmelidir, ancak bu el yumuşak 17 veya daha yüksekse, krupiye Dur seçeneğini seçerek durmalıdır (yumuşak elin As değeri 11'i içerdiğini unutmayın). ).Sonunda, elinizin değeri krupiyenin elinden 21'e yakın olduğunda veya krupiye yandığında kazanırsınız. Eliniz krupiyeninkiyle aynı değerdeyse oyun turu berabere biter ve bahsiniz iade edilir.Yan Bahis seçenekleriyle oynayın, '100x'e kadar kazanın.",
      },
    },
  },

  updateScene: {
    downloading: "Yeni sürüm indiriliyor...",
    installing: "Yükleniyor...",
    installedVersion: "Yüklenen Sürüm",
    currentVersion: "Güncel Sürüm",
  },

  actionTypes: {
    call: "Gör",
    check: "Bop",
    fold: "Pas",
    raise: "Arttır",
    allin: "Rest",
  },

  tableQuickMenu: {
    standUp: "Kalk",
    goToLobby: "Lobiye Git",
    soundOn: "Ses",
    soundOff: "Ses",
    vibrationOn: "Titreşim Açık",
    vibrationOff: "Titreşim Kapalı",
    animatedGiftsOn: "İfadeler",
    animatedGiftsOff: "İfadeler",
    watchWin: "İzle Kazan",
  },

  actionPanel: {
    check: "Bop",
    call: "Gör",
    fold: "Pas",
    raise: "Arttır",
    allin: "Rest",
    callAny: "Hepsini Gör",
    checkFold: "Bop/Pas",
  },

  selectPotWindow: {
    autoPurchase: "Otomatik Satın Alma",
    autoPurchaseDescription:
      "Bakiyeniz sıfıra eşit olduğunda otomatik satın alma.",
    autoCharging: "Otomatik Tamamlama",
    autoChargingDescription:
      "Kasanız %70'in altına düştüğünde otomatik olarak yukarıya şarj olur.",
    sit: "Otur",
    minEntry: "Minimum Giriş",
    maxEntry: "Maksimum Giriş",
    transfer: "Aktar",
    setQuantity: "Miktarı Ayarla",
  },

  quickWindow: {
    quickChat: "Burası hızlı sohbet alanıdır.",
    readyMessage: "Hazır sohbet mesajları",
    messageAim: "Burası kullanıcıyla daha hızlı sohbet etmeyi amaçlıyor.",
    quickEmoji: "Hızlı Emoji",
  },

  tipButton: {
    thankYou: "Teşekkür ederim",
  },

  fastChatMessages: [
    "Bol şans",
    "Tebrikler",
    "Teşekkürler",
    "Blöf",
    "Lütfen daha hızlı oynayın",
    "Acemi",
    "İyi oyun, bravo",
    "Ne şans",
    "Sakin ol şampiyon",
  ],

  chatWindow: {
    tabs: {
      chat: "Chat",
      dealer: "Dealer",
      mute: "Sustur",
    },
    audiences: "İzleyiciler",
    players: "Oyuncular",
    typeHere: "Buraya yaz...",
    xSecondsLeft: "{seconds} saniye kaldı.",
  },

  dealerChat: {
    call: "{username} gör dedi",
    check: "{username} bop dedi",
    raise: "{username}, {amount} kadar arttırdı",
    fold: "{username} pas dedi",
    allin: "{username} rest dedi!",
    win: "{username}, {amount} kazandı",
    winWithCards: "{username}, {hand} ile {amount} kazandı",
    winWithCardIcons: "{username}, {cards}({hand}) ile {amount} kazandı",
  },

  systemMessages: {
    roomClosing:
      "Sunucu 2 dakika içinde yeniden başlatılacaktır. Son eller oynanacak.",
    teamTableAlert:
      "Bu bir takım masasıdır. Birden fazla hesap masada oturabilir ve raise yapabilir. Bu oyunda herhangi bir yasak yoktur!",
    giftSentChatMessage: '{from}, {to} oyuncusuna "{gift_name}" gönderdi.',
    inviteRequestSuccess: "Davet başarı ile gönderildi",
  },
  blackJack: {
    hit: "Hit",
    stand: "Stand",
    InsufficientChips: "Yetersiz Fiş",
    undo: "GERİ ALMA",
    double: "DOUBLE",
    repeat: "REPEAT",
    xSecondsLeft: "{seconds} saniye kaldı.",
    gameHistory: "El Geçmişi",
    croupier: "Krupiye",
    you: "Sen",
    main: "Main",
    anyPair: "Any Pair",
    result: "Sonuç",
    previousGame: "Önceki Oyun",
    returnToList: "Listeye Dön",
    nextGame: "Sonraki Oyun",
    type: "Tip",
    pay: "Ödemek",
    winningsHands: "Kazanan Eller",
    rules: "Kurallar",
    blackjactSitInfo:
      "Oyun, kurpiyere karşı oynanır. Sınırsız sayıda oyuncu, kurpiyere karşı oynayabilir.",
    bjRules: `
            * Turn Blackjack'in amacı, 21'i aşmadan krupiyeden daha yüksek puana sahip bir ele sahip olmaktır.; <br>
            * En iyi ele Blackjack denir - bu elde dağıtılan ilk iki kartın toplam değeri 21'dir;<br>
            * Diğer oyunculara karşı değil, sadece krupiyeye karşı oynuyorsunuz;<br>
            * Blackjack sekiz desteyle oynanır;<br>
            * Krupiye her zaman 17'de durur;<br>
            * Herhangi iki başlangıç ​​kartında ikiye katlanabilir;<br>
            * Aynı değerdeki başlangıç ​​kartları ikiye ayrılabilir;<br>
            * Bölme her elle yapılabilir;<br>
            * Her çift As paylaşımı için bir kart dağıtılır;<br>
            * Kartları böldükten sonra ikiye katlama yapılamaz;<br>
            * Krupiyenin bir As'ı olduğunda sigorta önerilir;<br>
            * Blackjack 3:2 öder;<br>
            * Bonus Blackjack el sonunda yapıldığında 3:2 öder;<br>
            * Sigorta 1:2 öder;<br>
            * Eller eşit olduğunda berabere olur.<br>

            <hr>

            <strong>Oyun kuralları</strong><br>

            *<strong>Oyun, kurpiyere karşı oynanır. Sınırsız sayıda oyuncu, kurpiyere karşı oynayabilir.</strong><br>
            * Oyun bir krupiye tarafından sunulur ve 52 kartlık sekiz standart deste ile oynanır. Oyundaki 21 kart değeri aşağıdaki gibidir:<br>
            - 2'den 10'a kadar olan kartların üzerinde yazılı değerler bulunur.<br>
            - Resimli kartlar (Vale, Kız ve Papaz) 10 puan değerindedir.<br>
            - Aslar 1 veya 11 değerindedir, oyuncu eline göre uygun değeri seçebilir. Yumuşak elin 11 değerli As içerdiğine dikkat edin;<br>
            * Bahis süresi sona erdiğinde, krupiye sonuncusu kendisine olmak üzere tüm oyunculara açık bir kart verir. Bundan sonra krupiye tüm oyunculara açık olarak ikinci bir kart dağıtır, ancak krupiyenin ikinci kartı kapalı olacaktır. Birinci elinizin değeri kartlarınızın yanında gösterilir.<br>
            * Blackjack (Twenty-One) = Oyunun başında size dağıtılan iki kartın toplamı 21 ise, Blackjack'iniz var demektir!<br>
            <br>
            <strong>Sigorta</strong>
            <br>
            * Krupiyenin açık kartı As ise, toplam eliniz 21 olsa bile, krupiyenin 21'e sahip olma riskine karşı sigorta satın alabilirsiniz. Sigorta tutarı ana bahsinizin yarısına eşit olacak ve sigorta bahsi el bahsinizden ayrı olarak işlenir. Bundan sonra, krupiye 21 yapıp yapmadığını kontrol etmek için kapalı kartının değerine bakar.<br>
            - Krupiye 21 yapmazsa tur devam eder. <br>
            - Krupiyede Blackjack varsa ve siz yoksa, oyun biter ve krupiye kazanır.<br>
            - Hem siz hem de krupiye 21'e sahipseniz, oyun berabere biter ve bahsiniz iade edilir.<br>
            <br>
            Krupiyenin açık kartı 10 veya renkli ise, sigorta satın alma seçeneğiniz olacağını ve krupiyenin 21 için kapalı kartına bakmayacağını unutmayın.<br>
            <br>
            <strong>Double, Hit or Stand</strong>
            <br>
            Krupiye ilk iki kartını kontrol ettikten sonra 21 yapmadığını tespit ederse, krupiye oyunculara daha fazla kart vererek oyunculara ellerinin değerini artırmak için daha fazla kart seçme hakkı verilir.<br>
            <br>
            İlk eliniz 21 değerinde değilse, bahsi ikiye katlama hakkınız vardır. Bu durumda, bahsiniz ikiye katlanır ve elinize eklemeniz için size sadece bir ek kart verilir. Alternatif olarak, elinizin değerini artırmak için ek kartlar almak için Hit card seçeneğini kullanabilirsiniz. Elinizin değerinden memnun kalana kadar birden fazla karta vurabilir ve Durmaya karar verebilirsiniz.<br>
            <br>
            21'e eşit yumuşak eli olan bir oyuncuya karar seçeneği verilmeyeceğini unutmayın.
            <br>

            <strong>İkiye Böl</strong><br>
            <br>
            İlk eliniz aynı değerde iki karttan oluşuyorsa, çifti, her birinin ana bahsinize eşit bir bahsi olan iki farklı ele bölebilirsiniz. Her iki ele de ikinci bir kart dağıtıldıktan sonra, Hit card seçeneğini uygulayarak bu iki elin değerini artırabilirsiniz. Burada, her iki elin değerinden memnun olduğunuzda Stand seçeneğini seçebilirsiniz. Ancak, ilk As çiftini böldüğünüzde, her iki el de otomatik olarak ek bir kart alacak ve başka bir kart alma şansı olmayacaktır.
            <br>
            <strong>Sonuç</strong>
            <br>
            Elinizin toplam değeri 21'i geçerse yanarsınız ve o eldeki bahsinizi kaybedersiniz.<br>
            <br>
            Tüm kullanıcıların sırası geçtikten sonra, krupiye kendi kapalı kartının değerini gösterir. Krupiyenin eli 16 veya daha az ise, krupiye Hit kartı seçeneğini seçmelidir, ancak bu el yumuşak 17 veya daha yüksekse, krupiye Dur seçeneğini seçerek durmalıdır.(yumuşak elin 11'lik bir As değeri içerdiğine dikkat edin.).
            <br>
            Sonunda, elinizin değeri krupiyenin elinden 21'e yakın olduğunda veya krupiye yandığında kazanırsınız. Eliniz krupiyeninkiyle aynı değerdeyse oyun turu berabere biter ve bahsiniz iade edilir.
            <br>Yan Bahis seçenekleriyle oynayın, "100x"e kadar kazanın.`,
    settings: "Ayarlar",
    sounds: "Sesler",
    bet: "BAHİS",
    waitOtherPlayers: "Lütfen diğer oyuncuları bekleyin",
    placeYourBet: "Bahsinizi koyun",
    choose: "Seçmek",
    wantInsurance: "Sigorta istiyor musun?",
    draw: "BERABERE",
    busted: "BUSTED",
    youLost: "Kaybettin",
    sit: "OTUR",
    header: "Ana Kasanızdan Blackjack Kasanıza Fiş Aktarın",
    otoPurchase: "Oto Alım",
    otoPurchaseText:
      "Yeniden giriş yaparsanız hemen fişler masada sıfıra düşer",
    okay: "TAMAM",
    insufficientBalance: "Oturmak için yeterli paran yok.",
    ShufflingCards: "Kart destesini karıştırma...",
    youWon: "Kazandın",
    won: "KAZANDI",
    dealNow: "Hemen Dağıt",
  },
  useInviteCode: {
    heading: "Davet Kodu",
    description: "Lütfen arkadaş davet kodunu giriniz.",
    unset: "Davet Kodunu Kaldır",
    inviteCodeNotFound: "Yanlış davet kodu.",
  },
  progressiveJackpot: {
    join: "Katıl",
    desc1:
      "Katıldığınızda her el küçük kör bahis miktarı kadar fiş ödemesi yaparsınız.",
    desc2: "Kazandığınızda küçük kör bahisin çarpanı kadar ödül alırsınız.",
    desc3: "Aynı bahis seviyesinde toplanan fişlerin ortak çanağıdır.",
  },
  pinCode: {
    wrong: "Pin Kodu Yanlış!",
    pinCode: "PIN KODU",
    removePinCode: "Pin Kodunu Kaldır",
    enterPin: "Lütfen hesabınızın pin kodunu giriniz",
    reset: "Şifremi Sıfırla",
    logOut: "ÇIKIŞ YAP",
    resetPin: "PIN KODUNU SIFIRLA",
    enterEmail: "Lütfen hesabınızın e-posta adresini girin",
    mail: "E-posta Adresi",
    newPass: "Yeni Şifreniz Email Adresinize Gönderildi",
    wrongMail: "Girdiğiniz e-posta adresi yanlış",
    failRemove: "Pin kodu kaldırılamadı",
    failAdd: "Pin kodu kaydedilemedi",
    areYouSure: "Pin kodunuzu kaldırmak istediğinizden emin misiniz?",
  },
  redeem: {
    used: "Oyun kartı zaten kullanılmış!",
    expired: "Oyun kartının süresi doldu!",
    failed: "Oyun kartı doğrulanamadı.",
    validGameCard: "Lütfen geçerli bir oyun kartı girin",
  },

  level: {
    levelUpToastText:
      "Tebrikler! {levelNumber} seviyeye ulaştınız. <strong>Ödül: {reward}</strong>",
  },
  inviteFriend: {
    inviteFriendText: "Arkadaşınızı davet ettiniz ve ikinizde 10B kazandınız!",
  },
  slotWinnerToast: {
    bigWinText: "{username}, slot oyununda {amount} miktarda kazandı!",
  },
  toast: {
    inviteRequestText: "{name} seni {game} oynamaya davet etti!",
  },
  updatedNotes: {
    title: "Güncelleme Notları",
    notes: [
      "<div class='center'><span class='title'>Poker</span></div>",
      "Arka plan sesi varsayılan olarak kapalı ayarlandı",
      "Liderler listesindeki oyuncu sayısı 50 oyuncuya çıkarıldı",
      "Masaya oturduğunda 'Arama' ve 'El Geçmişi' özelliklerine erişebilme imkanı eklendi",
      "Oyun sohbetine sadece masada oynayan oyuncular yazabilir",
      "Sohbet artık sol tarafta ve şeffaf",
      "Sohbet esnasın işlem yapabilme özelliği eklendi (Call/Fold/Check/Raise)",
      "<div class='center'><span class='title'>Blackjack</span></div>",
      "Kartları ve metinlerin boyutları büyütüldü",
      "Hatalar düzeltildi",
    ],
  },
  forceUpdateWindow: {
    title: "Uygulamanın Yeni Versiyonu Mevcut!",
    text: "Lütfen uygulamanızı güncelleyiniz",
  },
  table: {
    noHaveOnlineFriends: "Çevirimiçi arkadaşınız bulunmamaktadır.",
    noHaveFriends: "Arkadaşınız bulunmamaktadır.",
    onlineFriends: "Çevirimiçi Arkadaşlar",
  },
  profileSettings: {
    profileSettings: " Profil Ayarları ",
    registrationDate: "Kayıt Tarihi",
    use: "KULLAN",
    gift: "Hediye",
    buyVip: "Vip Satın Al",
    onlyVip: " Bu hediyeyi sadece VIP üyeler satın alabilir.",
    frame: "Çerçeve",
    buy: "Satın Al",
    statistics: {
      statistics: "İstatistik",
      handPlayed: "Oynanan El Sayısı",
      biggestPotWon: "Kazanılan En Büyük Pot",
      biggestJackPotWon: "Kazanılan En Büyük JackPot",
      recordChip: "Rekor Fiş",
      brokenPotCounter: "Kırılan Çanak Sayısı",
      winPercentage: "Kazanma Yüzdesi",
      leadershipRanking: "Liderlik Sıralaması",
      bestWinningHand: "En İyi Kazandıran El",
      biggestWin: "En Yüksek Kazanç",
    },
  },
  friends: {
    online: "ONLINE",
    goToTable: "Katıl",
    chipTransfer: "Fiş transfer",
    friendRequests: "Arkadaş istekleri",
    incoming: "GELEN",
    sent: "GÖNDERİLEN",
    search: "ARAMA",
    addButtonText: "Arkadaş Ekle",
    removeFriendNotification: "Arkadaş listenizden kaldırıldı",
    userProfile: "Kullanıcı Profili",
    sendChipText: "Fiş Gönder",
    deletefriends: "Arkadaşlıktan Çıkar",
  },

  settings: {
    lobbyMusic: "Lobi Müziği",
    soundEffects: "Ses Efektleri",
    vibration: "Titreşim",
    blockInvites: "İstekler",
    giftHide: "Hediyeler",
    handStrengthIndicator: "El Gücü Göstergesi",
    graphicsPreferences: "Grafik Tercihleri",
    tableColor: "Masa Rengi",
    roomBgColor: "Arkaplan Rengi",
    languagePreference: "Dil Tercihi",
    termsOfUse: "Kullanım Şartları",
    accountInfo: "Hesap Bilgileri",
    profileID: "Profil ID",
    deviceNO: "Cihaz NO",
    deleteAccount: "Hesabı sil",
    language: {
      turkish: "Türkçe",
      english: "İngilizce",
      italian: "İtalyanca",
      indonesian: "Endonezce",
      portuguese: "Portekizce",
      russian: "Rusça",
      malesian: "Malayca",
    },
  },
  vip: {
    available: "VIP / {day} Günler",
    noVip: "VIP mevcut değil",
  },
  notification: {
    noData: "Bildiriminiz bulunmamaktadır.",
  },
  userProfile: {
    noteUpdated: "Profil notunuz güncellendi",
  },
  others: {
    friendRemovedInfo: "Arkadaş listenizden kaldırıldı",
    notFoundFriend: "Arkadaş bulunamadı",
    notFoundDisplay: "Gönderilecek kişi bulunamadı",
    friendTableNotFound: "Arkadaşınızın masası bulunamadı",
    tableInFriendNotFound: "Masada oyuncu bulunmuyor",
    requiredSelectedGift: "Gift Seçmelisiniz",
    currentLocationNotAvailableBuy:
      "Bulunduğunuz lokasyonda satın alma işlemi gerçekleştirilemiyor",
    requiredLevelFive: "Seviye 5 olmanız gerekmektedir",
    blackjackStatusInactive: "Blackjack oyunu şuanda aktif değil",
    slotStatusInactive: "Slot oyunu şuanda aktif değil",
    muteAll: "Herkesi Sustur",
    chipSendAreYouSure:
      "{0} Id numaralı {1} adlı kullanıcıya fiş <br/> göndermek istediğinize emin misiniz?",
    changeNameSuccesInfo: "Kullanıcı ismi değiştirildi {0} hakkınız kaldı",
    guestClose: "Misafir hesabı açılamıyor diğer giriş yöntemlerini deneyiniz",
  },
};
