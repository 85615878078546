<script>
export default {
  computed: {
    timeZone() {
      return new Date().getTimezoneOffset();
    },
  },
  methods: {
    isMyFriend(id) {
      return this.$store.state.friends.friendsList.list.some(
        (friend) => friend.user.id === id
      );
    },
    crownCondition(crownNumber = 0, vip = false) {
      const calcCrown = crownNumber < 1 && vip ? 0 : crownNumber;
      return vip ? calcCrown : -1;
    },
    gameTextComputed(text) {
      return text
        ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
        : "";
    },
    formatChipAmount(amount, valueName) {
      const result = String(amount)
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.$nextTick(() => (this.$data[valueName] = result));
    },
    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    },
  },
};
</script>
