<template>
  <div class="winner-list-outer" v-show="list.length > 0">
    <div class="winner-list-inner">
      <div class="winner-list-header">
        <div class="winner-count">
          <span class="number">{{ list.length }}</span>
          <svg
            fill="currentColor"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div class="total-amount">
          <AnimatedNumber :number="getTotalAmount" :format="true" />
          <span class="white">{{ $t("blackJack.won") }}</span>
        </div>
      </div>
      <div class="winner-list-body">
        <div
          v-for="(item, itemIndex) in getList"
          :key="'winner-' + itemIndex"
          class="row"
          :class="[getList.length > 9 ? 'animate' : '']"
          :style="{ transitionDelay: `${(itemIndex + 1) * 0.5}s` }"
        >
          <div class="name">{{ item.player.name }}</div>
          <div class="amount">{{ getChipFormat(item.amount) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import AnimatedNumber from "../../Utils/AnimatedNumber";

export default {
  name: "WinnerList",
  components: { AnimatedNumber },
  computed: {
    ...mapGetters(["getChipFormat", "getLang"]),
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
    }),
    getTotalAmount() {
      return [...this.list].reduce((acc, item) => acc + item.amount, 0);
    },
    getList() {
      return [...this.list].slice(0, 20).sort((a, b) => b.amount - a.amount);
    },
  },
  watch: {
    "getActiveRoomState.winners": function (newValue) {
      if (newValue.length === 0) {
        this.list.splice(0, this.list.length);
        return false;
      }

      newValue.forEach((data) => this.insertIntoList(data));
    },
  },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    insertIntoList({ playerId, amount }) {
      const index = this.list.findIndex((item) => item.player.id === playerId);
      if (index > -1) return false;

      const player = this.getActiveRoomState.players.find(
        (p) => p.id === playerId
      );
      if (typeof player === "undefined") return false;

      this.list.push({
        player: {
          id: player.id,
          name: player.name,
        },
        amount,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.winner-list-outer {
  position: absolute;
  left: 0;
  bottom: -40px;
  width: 300px;
  height: 300px;
  z-index: 15;
  display: flex;
  align-items: stretch;
  background-image: linear-gradient(
    0deg,
    black 0%,
    rgba(black, 0.8) 20%,
    rgba(black, 0.4) 80%,
    transparent 100%
  );
  transition: transform 0.25s ease;
  .winner-list-inner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .winner-list-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      padding: 5px;
      height: 30px;
      box-shadow: 0 1px 10px 0 rgba(black, 0.25);
      color: rgb(255, 255, 255);
      text-shadow: 0 1px 0 rgba(black, 0.75);
      .winner-count {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        .number {
          margin-right: 5px;
        }
      }
      .total-amount {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 0;
        font-size: 26px;
        font-weight: bold;
        color: #ecaf5c;
        text-shadow: 0 1px 0 darken(#ecaf5c, 50%);
        .white {
          display: block;
          color: rgb(255, 255, 255);
          margin-left: 5px;
          text-transform: uppercase;
        }
      }
    }
    .winner-list-body {
      flex: 1;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      height: calc(100% - 30px);
      overflow: hidden;
      .row {
        flex-shrink: 0;
        width: 100%;
        box-sizing: border-box;
        height: 30px;
        padding: 0 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(black, 0.15);
        color: #ecaf5c;
        font-size: 24px;
        overflow: hidden;
        transition: all 0.25s ease;
        &.animate {
          height: 0 !important;
          border-color: transparent !important;
        }
        &:last-child {
          margin-bottom: 10px;
          border-bottom: 0;
        }
        .name {
          max-width: 70%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.platform-mobile {
  .winner-list-outer {
    width: 500px;
    height: 500px;
    position: absolute;
    bottom: 0;
    left: 20px;
    z-index: 13;
    .winner-list-inner {
      .winner-list-header {
        height: 50px;
        .winner-count {
          font-size: 32px;
        }
        .total-amount {
          font-size: 34px;
          .white {
            display: block;
            color: rgb(255, 255, 255);
            margin-left: 5px;
            text-transform: uppercase;
          }
        }
      }
      .winner-list-body {
        .row {
          height: 60px;
          font-size: 34px;
          &.animate {
            height: 0 !important;
            border-color: transparent !important;
          }
          &:last-child {
            margin-bottom: 10px;
            border-bottom: 0;
          }
          .name {
            max-width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
