import SpriteTable from "@/assets/images/atlas/sprite-table.png";
import LobbyAtlas from "@/assets/images/atlas/lobby-atlas.png";
import SpriteTablePopUp from "@/assets/images/atlas/sprite-table-pop-up.png";
import SpriteTableJackpot from "@/assets/images/atlas/sprite-table-jackpot.png";
import SpriteListGame from "@/assets/images/atlas/sprite-list-game.png";
import SpriteGifts from "@/assets/images/atlas/sprite-gifts.png";
import SpriteExpression from "@/assets/images/atlas/sprite-expression.png";
import Cards from "@/assets/images/atlas/cards.png";

import ProfileSprite from "@/assets/images/atlas/profile-sprite.png";
import ProfileSpritePopup from "@/assets/images/atlas/sprite-profile-popup.png";
import Background from "@/assets/images/backgrounds/background.png";
import LobbyBackground from "@/assets/images/backgrounds/lobby-background.png";
import BlackjackBackground from "@/assets/images/blackjack/background.png";
import AuthenticationBackground from "@/assets/images/backgrounds/authentication-bg.png";
import LoadingBackground from "@/assets/images/backgrounds/loading-bg.png";

import BronzeVip from "@/assets/images/lobby/bronze-vip.png";
import SilverVip from "@/assets/images/lobby/silver-vip.png";
import GoldVip from "@/assets/images/lobby/gold-vip.png";

import tableInviteJoinButton from "@/assets/images/table/table-invite-join-button.png";
import BronzeBg from "@/assets/images/lobby/bronze-vip.png";
import SilverBg from "@/assets/images/lobby/silver-vip.png";
import GoldBg from "@/assets/images/lobby/gold-vip.png";
import LobbyTexasHoldem from "@/assets/images/lobby/texas-holdem-button.png";
import LobbyOmaha from "@/assets/images/lobby/omaha-button.png";
import LobbySlots from "@/assets/images/lobby/slots-button.png";
import LobbyBlackJack from "@/assets/images/lobby/blackjack-button.png";
import LobbyDomino from "@/assets/images/lobby/domino.png";
import SocialLeftLikeButtons from "@/assets/images/lobby/background-image-left.png";
import SocialRightLikeButtons from "@/assets/images/lobby/background-image-right.png";
import UiButtonOrange from "@/assets/images/ui_button/orange-button.png";
import UiButtonSmallOrange from "@/assets/images/ui_button/orange-button-small.png";
import UiButtonGreen from "@/assets/images/ui_button/green-button.png";
import UiButtonRed from "@/assets/images/ui_button/red-button.png";
import UiButtonSmallGreen from "@/assets/images/ui_button/green-button-small.png";
import UiButtonSmallRed from "@/assets/images/ui_button/red-button-small.png";
import UiButtonDisabled from "@/assets/images/ui_button/inactive-small-button.png";
import FacebookButton from "@/assets/images/backgrounds/facebook-button.png";
import GoogleButton from "@/assets/images/backgrounds/google-button.png";
import GuestButton from "@/assets/images/backgrounds/guest-button.png";
import AppleButton from "@/assets/images/backgrounds/apple-button.png";
import AuthBar from "@/assets/images/backgrounds/info-bar.png";
import TurnLogo from "@/assets/images/backgrounds/logo.png";
import LoadingImage1 from "@/assets/images/backgrounds/loading-1.png";
import LoadingImage2 from "@/assets/images/backgrounds/loading-2.png";
import LoadingImage3 from "@/assets/images/backgrounds/loading-3.png";
import LoadingImage4 from "@/assets/images/backgrounds/loading-4.png";

import LobbyFafafa from "@/assets/images/lobby/fafafa.png";
import LobbyTurn from "@/assets/images/lobby/turn-legends2.png";
import LobbyOlympus from "@/assets/images/lobby/turn-of-olympus3.png";
import LobbyPanda from "@/assets/images/lobby/bamboopanda.png";

import DealerWoman from "@/assets/images/animations/dealer.png";
import TableBlue from "@/assets/images/poker/tables/table-blue.png";
import TableGreen from "@/assets/images/poker/tables/table-green.png";
import TablePurple from "@/assets/images/poker/tables/table-purple.png";
import TableRed from "@/assets/images/poker/tables/table-red.png";

import Crown0 from "@/assets/images/vip_crowns/0.png";
import Crown1 from "@/assets/images/vip_crowns/1.png";
import Crown2 from "@/assets/images/vip_crowns/2.png";
import Crown3 from "@/assets/images/vip_crowns/3.png";
import Crown4 from "@/assets/images/vip_crowns/4.png";
import Crown5 from "@/assets/images/vip_crowns/5.png";
import Crown6 from "@/assets/images/vip_crowns/6.png";
import Crown7 from "@/assets/images/vip_crowns/7.png";
import Crown8 from "@/assets/images/vip_crowns/8.png";
import Crown9 from "@/assets/images/vip_crowns/9.png";
import Crown10 from "@/assets/images/vip_crowns/10.png";
import Crown11 from "@/assets/images/vip_crowns/11.png";
import Crown12 from "@/assets/images/vip_crowns/12.png";
import EmojiIcon from "@/assets/images/lobby/emoji.png";

import GBFlag from "@/assets/images/flags/GB.png";

const IMAGES = [
  SpriteListGame,
  Background,
  LobbyBackground,
  BlackjackBackground,
  tableInviteJoinButton,
  ProfileSprite,
  ProfileSpritePopup,
  SpriteGifts,
  SpriteExpression,
  LobbyAtlas,
  BronzeBg,
  SilverBg,
  GoldBg,
  LobbyTexasHoldem,
  LobbyOmaha,
  LobbySlots,
  LobbyBlackJack,
  LobbyDomino,
  SocialLeftLikeButtons,
  SocialRightLikeButtons,
  UiButtonOrange,
  UiButtonSmallOrange,
  UiButtonGreen,
  UiButtonSmallGreen,
  UiButtonSmallRed,
  UiButtonRed,
  UiButtonDisabled,
  SpriteTable,
  SpriteTablePopUp,
  SpriteTableJackpot,
  AuthenticationBackground,
  LoadingBackground,
  FacebookButton,
  GoogleButton,
  GuestButton,
  AppleButton,
  AuthBar,
  TurnLogo,
  LoadingImage1,
  LoadingImage2,
  LoadingImage3,
  LoadingImage4,
  LobbyFafafa,
  LobbyTurn,
  LobbyOlympus,
  LobbyPanda,
  Cards,
  BronzeVip,
  SilverVip,
  GoldVip,
  DealerWoman,
  TableBlue,
  TableGreen,
  TablePurple,
  TableRed,
  Crown0,
  Crown1,
  Crown2,
  Crown3,
  Crown4,
  Crown5,
  Crown6,
  Crown7,
  Crown8,
  Crown9,
  Crown10,
  Crown11,
  Crown12,
  GBFlag,
  EmojiIcon,
];

const preloader = {
  async init() {
    return this.load(IMAGES);
  },
  async load(images = []) {
    if (images.length === 0) return false;
    for (let i = 0; i < images.length; i++) {
      const image = new Image();
      image.src = images[i];
      new Promise((resolve) => {
        image.onload = () => {
          // Logger.print('info', `PRELOADER "${images[i]}" loaded`)
          resolve(true);
        };
        image.onerror = () => {
          // Logger.print('info', `PRELOADER "${images[i]}" failed to load`)
          resolve(true);
        };
      });
    }
  },
};

export default preloader;
