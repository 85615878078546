export default {
  authType: "",
  token: {
    access: "",
    refresh: "",
  },
  showOverlay: true,
  inviteCode: null,
  requestedAuthMethod: "",
  isTryingAutoLogin: false,
  refreshTokenError: false,
  newUserNotRegistered: false,
};
