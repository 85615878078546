import axios from '@/services/api/axios'

export default {

  async send({ state, rootState }, { playerIds, currentChat = [], allPlayers = [], type }) {
    try {
      const roomId = rootState.service.ACTIVE_ROOM.id

      const response = await axios.post(`/reports/add`, {
        type, game: rootState.service.colyseusConnection.ACTIVE_ROOM.name || 'poker',
        data: { roomId, players: playerIds, allPlayers, currentChat }
      });

      if (response.status > 400)
        return false;

      return typeof response.data.success !== 'undefined';
    } catch {
      return false;
    }
  },

}
