<template>
  <div
    class="players-actions-indicator"
    :class="[[1, 2, 3, 5].includes(getActiveRoomState.stage) ? 'show' : '']"
  >
    <template v-if="getActiveRoomState.stage === 1 && bettingPlayersCount > 0">
      <div class="with-icon">
        <div class="icon">
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div class="text"><AnimatedNumber :number="bettingPlayersCount" /></div>
        <Spinner
          class="spinner"
          :loading="getActiveRoomState.countdown > 0"
          size="10px"
          color="white"
        />
      </div>
    </template>
    <template v-if="getActiveRoomState.stage === 3 && actionPlayersCount > 0">
      <div class="with-icon">
        <div class="icon">
          <svg
            fill="currentColor"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div class="text"><AnimatedNumber :number="actionPlayersCount" /></div>
        <Spinner
          class="spinner"
          :loading="getActiveRoomState.countdown > 0"
          size="10px"
          color="white"
        />
      </div>
    </template>
    <template v-if="getActiveRoomState.stage === 6 && bustedPlayersCount > 0">
      <div class="with-icon">
        <div class="icon">
          <svg
            width="20"
            height="20"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div class="text"><AnimatedNumber :number="hitPlayersCount" /></div>
        <Spinner
          class="spinner"
          :loading="getActiveRoomState.countdown > 0"
          size="10px"
          color="white"
        />
      </div>
    </template>
    <template v-if="getActiveRoomState.stage === 5 && insuredPlayersCount > 0">
      <div class="with-icon">
        <div class="icon">
          <svg
            width="20"
            height="20"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div class="text"><AnimatedNumber :number="insuredPlayersCount" /></div>
        <Spinner
          class="spinner"
          :loading="getActiveRoomState.countdown > 0"
          size="10px"
          color="white"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AnimatedNumber from "../../Utils/AnimatedNumber";
import Spinner from "../../Utils/Spinner";

export default {
  name: "PlayersActionsIndicator",
  components: {
    AnimatedNumber,
    Spinner,
  },
  computed: {
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
    }),
    getPlayers() {
      if (!this.getActiveRoomState || !this.getActiveRoomState.players) {
        return [];
      }

      return this.getActiveRoomState.players;
    },
    bettingPlayersCount() {
      if (this.getPlayers.length < 1) {
        return 0;
      }
      return (
        this.getPlayers.length -
        [...this.getPlayers].filter((player) => player.bet.main > 0).length
      );
    },
    actionPlayersCount() {
      return (
        this.getPlayers.filter((player) => player.bet.main > 0).length -
        [...this.getPlayers].filter(
          (player) => player.bet.main > 0 && player.lastAction !== 0
        ).length
      );
    },
    insuredPlayersCount() {
      return [...this.getPlayers].filter(
        (player) => player.bet.main > 0 && player.insured
      ).length;
    },
    hitPlayersCount() {
      return [...this.getPlayers].filter(
        (player) => player.bet.main > 0 && player.lastAction === 1
      ).length;
    },
    bustedPlayersCount() {
      return [...this.getPlayers].filter(
        (player) => player.bet.main > 0 && player.busted
      ).length;
    },
  },
};
</script>

<style scoped lang="scss">
.players-actions-indicator {
  position: absolute;
  bottom: 240px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  max-height: 30px;
  background-image: linear-gradient(
    90deg,
    transparent,
    black 40%,
    black 60%,
    transparent
  );
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 18px;
  opacity: 0;
  transition: all 0.25s ease;
  &.show {
    opacity: 1;
  }
  .with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    .icon {
      margin-top: 2px;
      margin-right: 10px;
      height: 30px;
      width: 30px;
    }
    .text {
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      font-size: 25px;
    }
    .spinner {
      margin-left: 5px;
    }
  }
}

.platform-mobile {
  .players-actions-indicator {
    position: absolute;
    bottom: 300px;
    right: 100px;
    z-index: 5;
    min-width: 120px;
    transform: scale(1.8);
  }
}
</style>
