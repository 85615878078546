<template>
  <div class="shop-window">
    <div class="tab-selectors">
      <div
        class="tab-selector"
        @click="handleTabs('buy-chip')"
        :class="currentTab === 'buy-chip' ? 'profile-sprite tab1 active' : ''"
      >
        <p>{{ $t("shopWindow.buyChip") }}</p>
      </div>
      <div
        @click="handleTabs('buy-vip')"
        class="tab-selector"
        :class="currentTab === 'buy-vip' ? 'profile-sprite tab2 active' : ''"
      >
        <p>{{ $t("shopWindow.buyVip") }}</p>
      </div>
      <div class="shop-close" @click="closeShop">
        <div class="sprite-table-pop-up exit-icon"></div>
      </div>
    </div>
    <div class="content-section">
      <div class="inner-content buy-chip" v-if="currentTab === 'buy-chip'">
        <div class="products">
          <div class="inner-products" :style="innerProductStyle">
            <div
              class="no-product-message"
              v-if="
                getProducts.length === 0 &&
                getPlatformProducts.length === 0 &&
                getFacebookProduct.length === 0
              "
            >
              {{ $t("shopWindow.noProduct") }}
            </div>
            <template
              v-if="!(getRealPlatform === 'android' && user.country === 'ID')"
            >
              <template v-if="getRealPlatform === 'ios'">
                <PlatformProduct
                  v-for="(product, productIndex) in getProducts"
                  :key="'ios-product-' + productIndex"
                  platform="ios"
                  :prodIndex="(productIndex += 1)"
                  :product="product"
                  @buy="buy"
                />
              </template>
              <template v-if="getRealPlatform === 'android'">
                <Product
                  v-for="(product, productIndex) in getProducts"
                  :key="'and-product-' + productIndex"
                  platform="android"
                  :prodIndex="(productIndex += 1)"
                  :product="product"
                  @buy="buy"
                />
              </template>
            </template>
            <template v-if="isInFacebook">
              <Product
                v-for="(product, productIndex) in getFacebookProduct"
                :key="'fb-product-' + productIndex"
                platform="facebook"
                :product="product"
                @buy="buyFacebookProduct"
              />
              <Product
                v-for="(product, productIndex) in getPlatformProducts"
                :key="'plt-product-' + productIndex"
                :product="product"
                @buy="selectPaymentProduct"
              />
            </template>
          </div>
        </div>
      </div>
      <div class="inner-content buy-vip" v-if="currentTab === 'buy-vip'">
        <div class="vip-container">
          <div
            class="part-of-vip"
            :class="item.class"
            v-for="(item, index) in vipItems"
            :key="`vip${index}`"
          >
            <div class="text-container">
              <div
                class="row"
                v-for="(list, listIndex) in item.list"
                :key="`vip${index}list${listIndex}`"
              >
                {{ list }}
              </div>
              <div class="title">
                <span class="top-title">{{ item.text }} VIP</span>
                <span class="bottom-title">30 {{ $t("shopWindow.day") }}</span>
              </div>
              <div
                class="ui-button ui-button-green bottom"
                @click="showConfirmWindow = true"
              >
                <div class="sprite-table chip icon"></div>
                <div class="vip-title">
                  {{ `${getShortChipFormat(vipAmount)}` }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmWindow
      :show="$data.showConfirmWindow"
      :titleText="$t('shopWindow.buyVip')"
      @closeConfirm="closeConfirmWindow"
      @confirm="buyVip"
      :defaultSure="defaultSure"
    />
    <SelectGatewayWindow
      :show="showSelectGateway"
      :product="selectedPlatformProduct"
      :gateways="selectedProductGateWays"
      @closeWindow="closeSelectGateway"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BaseWindow from "../BaseWindow";
import ConfirmWindow from "../ConfirmWindow";
import SelectGatewayWindow from "../SelectGatewayWindow";

import MomentPlugin from "@/plugins/moment";

export default {
  name: "ShopWindow",
  components: {
    BaseWindow,
    ConfirmWindow,
    SelectGatewayWindow,
    Product: () => import("./Product"),
    PlatformProduct: () => import("./PlatformProduct"),
  },
  watch: {
    "$store.state.modals.shop": {
      handler(val) {
        if (val) {
          this.onShowShopWindow();
          if (this.$store.state.modals.nextWindowText) {
            this.currentTab = this.$store.state.modals.nextWindowText;
            this.$store.state.modals.nextWindowText = "";
          }
          this.$adjust.trackEvent("ShopWindowOn");
        }
      },
    },
    currentTab(val) {
      if (val === "buy-vip") {
        this.$adjust.trackEvent("ShopWindowClickBuyVip");
      } else if (val === "buy-chip") {
        this.$adjust.trackEvent("ShopWindowClickBuyChip");
      }
    },
  },
  computed: {
    ...mapState(["products", "user"]),
    ...mapGetters(["getRealPlatform", "getShortChipFormat", "getChipFormat"]),
    innerProductStyle() {
      const style = {};
      if (this.getRealPlatform === "ios") {
        style.flexWrap = "wrap";
        style.alignContent = "space-around";
      }

      return style;
    },
    getProducts() {
      const returnProduct = [...this.products.list].filter(
        (p) => p.title !== null
      );
      if (this.getRealPlatform === "ios") {
        if (
          returnProduct &&
          returnProduct.length > 0 &&
          returnProduct[0].hasOwnProperty("priceMicros")
        ) {
          return returnProduct.sort((a, b) => {
            return a.priceMicros > b.priceMicros;
          });
        }
      }
      console.log("returnProduct", returnProduct);
      return returnProduct;
    },
    getPlatformProducts() {
      return this.$data.platformProducts;
    },
    getFacebookProduct() {
      return this.facebookProducts;
    },
    isInFacebook() {
      let searchIndex = window.location.href.indexOf("facebook");
      if (searchIndex > -1) {
        return true;
      } else {
        return false;
      }
    },
    getVipStatus() {
      let expiresDay = Math.floor(
        (this.user.vip.expiresAt - MomentPlugin.instance().unix()) /
          (60 * 60 * 24)
      );
      expiresDay = expiresDay < 1 && !this.user.vip.status ? 1 : expiresDay;
      return {
        expired: !this.user.vip.status,
        expiresAt: this.user.vip.expiresAt,
        expiresDay: expiresDay,
      };
    },
    vipList() {
      return [
        this.$t("shopWindow.withoutChip"),
        this.$t("shopWindow.privateEmojis"),
        this.$t("shopWindow.unLimitedView"),
        this.$t("shopWindow.privateTable"),
        this.$t("shopWindow.unlimitedChipTransfer"),
        this.$t("shopWindow.usingPrivateCase"),
        this.$t("shopWindow.xp"),
        this.$t("shopWindow.freeChat"),
      ];
    },
    vipAmount() {
      return this.$store.state.config.vip_price;
    },

    vipItems() {
      return [
        // {
        //   name: "vip1",
        //   text: this.$t("shopWindow.bronze"),
        //   list: this.vipList,
        //   amount: "TRY 99.99",
        //   class: "bronze",
        // },
        // {
        //   name: "vip2",
        //   text: "silver",
        //   list: this.vipList,
        //   amount: "TRY 179.99",
        //   class: "silver",
        // },
        {
          name: "vip3",
          text: "gold",
          list: this.vipList,
          amount: "BUY VIP",
          class: "gold",
        },
      ];
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      currentTab: "buy-chip",
      showConfirmWindow: false,
      showSelectGateway: false,
      quantity: 1,
      isfbPopupOpen: false,
      facebookProducts: [],
      enteredRedeemCode: "",
      platformProducts: [],
      selectedPlatformProduct: {},
      selectedProductGateWays: [],
      defaultSure: false,
      dummyProductss: [
        {
          billingPeriod: 0,
          billingPeriodUnit: "Day",
          countryCode: "TR",
          currency: "TRY",
          description: "30.000.000.000",
          discounts: null,
          group: "null",
          id: "product_199",
          introPrice: null,
          introPriceMicros: null,
          introPricePaymentMode: null,
          introPricePeriod: null,
          introPricePeriodUnit: null,
          price: "69,99",
          priceMicros: 69990000,
          title: "30.000.000.000",
        },
        {
          billingPeriod: 0,
          billingPeriodUnit: "Day",
          countryCode: "TR",
          currency: "TRY",
          description: "75.000.000.000",
          discounts: null,
          group: "null",
          id: "product_202",
          introPrice: null,
          introPriceMicros: null,
          introPricePaymentMode: null,
          introPricePeriod: null,
          introPricePeriodUnit: null,
          price: "69,99",
          priceMicros: 69990000,
          title: "75.000.000.000",
        },
        {
          billingPeriod: 0,
          billingPeriodUnit: "Day",
          countryCode: "TR",
          currency: "TRY",
          description: "250.000.000.000",
          discounts: null,
          group: "null",
          id: "product_203",
          introPrice: null,
          introPriceMicros: null,
          introPricePaymentMode: null,
          introPricePeriod: null,
          introPricePeriodUnit: null,
          price: "69,99",
          priceMicros: 69990000,
          title: "250.000.000.000",
        },
        {
          billingPeriod: 0,
          billingPeriodUnit: "Day",
          countryCode: "TR",
          currency: "TRY",
          description: "500.000.000.000",
          discounts: null,
          group: "null",
          id: "product_204",
          introPrice: null,
          introPriceMicros: null,
          introPricePaymentMode: null,
          introPricePeriod: null,
          introPricePeriodUnit: null,
          price: "69,99",
          priceMicros: 69990000,
          title: "500.000.000.000",
        },
        {
          billingPeriod: 0,
          billingPeriodUnit: "Day",
          countryCode: "TR",
          currency: "TRY",
          description: "1.000.000.000.000",
          discounts: null,
          group: "null",
          id: "product_205",
          introPrice: null,
          introPriceMicros: null,
          introPricePaymentMode: null,
          introPricePeriod: null,
          introPricePeriodUnit: null,
          price: "69,99",
          priceMicros: 69990000,
          title: "1.000.000.000.000",
        },
        {
          billingPeriod: 0,
          billingPeriodUnit: "Day",
          countryCode: "TR",
          currency: "TRY",
          description: "5.000.000.000.000",
          discounts: null,
          group: "null",
          id: "product_206",
          introPrice: null,
          introPriceMicros: null,
          introPricePaymentMode: null,
          introPricePeriod: null,
          introPricePeriodUnit: null,
          price: "69,99",
          priceMicros: 69990000,
          title: "5.000.000.000.000",
        },
      ],
      dummyProducts: [
        {
          id: "product_101",
          alias: "product_101",
          type: "consumable",
          group: "",
          state: "valid",
          title: "30.000.000.000 Turn Games Fiş",
          description: "30.000.000.000 Turn Games Fiş",
          priceMicros: 58990000,
          price: "₺58,99",
          currency: "TRY",
          countryCode: null,
          loaded: true,
          canPurchase: true,
          owned: false,
          introPrice: "",
          introPriceMicros: "",
          introPricePeriod: null,
          introPriceNumberOfPeriods: null,
          introPricePeriodUnit: null,
          introPriceSubscriptionPeriod: null,
          introPricePaymentMode: null,
          ineligibleForIntroPrice: null,
          discounts: [],
          downloading: false,
          downloaded: false,
          additionalData: null,
          transaction: null,
          trialPeriod: null,
          trialPeriodUnit: null,
          billingPeriod: null,
          billingPeriodUnit: null,
          valid: true,
        },
        {
          id: "product_102",
          alias: "product_102",
          type: "consumable",
          group: "",
          state: "valid",
          title: "75.000.000.000",
          description: "75.000.000.000 Turn Games Fiş",
          priceMicros: 93990000,
          price: "₺93,99",
          currency: "TRY",
          countryCode: null,
          loaded: true,
          canPurchase: true,
          owned: false,
          introPrice: "",
          introPriceMicros: "",
          introPricePeriod: null,
          introPriceNumberOfPeriods: null,
          introPricePeriodUnit: null,
          introPriceSubscriptionPeriod: null,
          introPricePaymentMode: null,
          ineligibleForIntroPrice: null,
          discounts: [],
          downloading: false,
          downloaded: false,
          additionalData: null,
          transaction: null,
          trialPeriod: null,
          trialPeriodUnit: null,
          billingPeriod: null,
          billingPeriodUnit: null,
          valid: true,
        },
        {
          id: "product_104",
          alias: "product_104",
          type: "consumable",
          group: "",
          state: "valid",
          title: "500.000.000.000",
          description: "500.000.000.000 Turn Games Fiş",
          priceMicros: 254990000,
          price: "₺254,99",
          currency: "TRY",
          countryCode: null,
          loaded: true,
          canPurchase: true,
          owned: false,
          introPrice: "",
          introPriceMicros: "",
          introPricePeriod: null,
          introPriceNumberOfPeriods: null,
          introPricePeriodUnit: null,
          introPriceSubscriptionPeriod: null,
          introPricePaymentMode: null,
          ineligibleForIntroPrice: null,
          discounts: [],
          downloading: false,
          downloaded: false,
          additionalData: null,
          transaction: null,
          trialPeriod: null,
          trialPeriodUnit: null,
          billingPeriod: null,
          billingPeriodUnit: null,
          valid: true,
        },
        {
          id: "product_106",
          alias: "product_106",
          type: "consumable",
          group: "",
          state: "valid",
          title: "5.000.000.000.000",
          description: "5.000.000.000.000 Turn Games Fiş",
          priceMicros: 1529990000,
          price: "₺1.529,99",
          currency: "TRY",
          countryCode: null,
          loaded: true,
          canPurchase: true,
          owned: false,
          introPrice: "",
          introPriceMicros: "",
          introPricePeriod: null,
          introPriceNumberOfPeriods: null,
          introPricePeriodUnit: null,
          introPriceSubscriptionPeriod: null,
          introPricePaymentMode: null,
          ineligibleForIntroPrice: null,
          discounts: [],
          downloading: false,
          downloaded: false,
          additionalData: null,
          transaction: null,
          trialPeriod: null,
          trialPeriodUnit: null,
          billingPeriod: null,
          billingPeriodUnit: null,
          valid: true,
        },
        {
          id: "product_107",
          alias: "product_106",
          type: "consumable",
          group: "",
          state: "valid",
          title: "5.000.000.000.000",
          description: "5.000.000.000.000 Turn Games Fiş",
          priceMicros: 1529990000,
          price: "₺10.529,99",
          currency: "TRY",
          countryCode: null,
          loaded: true,
          canPurchase: true,
          owned: false,
          introPrice: "",
          introPriceMicros: "",
          introPricePeriod: null,
          introPriceNumberOfPeriods: null,
          introPricePeriodUnit: null,
          introPriceSubscriptionPeriod: null,
          introPricePaymentMode: null,
          ineligibleForIntroPrice: null,
          discounts: [],
          downloading: false,
          downloaded: false,
          additionalData: null,
          transaction: null,
          trialPeriod: null,
          trialPeriodUnit: null,
          billingPeriod: null,
          billingPeriodUnit: null,
          valid: true,
        },
        {
          id: "product_108",
          alias: "product_106",
          type: "consumable",
          group: "",
          state: "valid",
          title: "5.000.000.000.000",
          description: "5.000.000.000.000 Turn Games Fiş",
          priceMicros: 1529990000,
          price: "₺100.529,99",
          currency: "TRY",
          countryCode: null,
          loaded: true,
          canPurchase: true,
          owned: false,
          introPrice: "",
          introPriceMicros: "",
          introPricePeriod: null,
          introPriceNumberOfPeriods: null,
          introPricePeriodUnit: null,
          introPriceSubscriptionPeriod: null,
          introPricePaymentMode: null,
          ineligibleForIntroPrice: null,
          discounts: [],
          downloading: false,
          downloaded: false,
          additionalData: null,
          transaction: null,
          trialPeriod: null,
          trialPeriodUnit: null,
          billingPeriod: null,
          billingPeriodUnit: null,
          valid: true,
        },
      ],
    };
  },
  methods: {
    closeShop() {
      this.$store.commit("modals/hide", "shop");
    },
    async onShowShopWindow() {
      try {
        if (this.$store.state.modals.isVipRedirected) {
          this.currentTab = "buy-vip";
        }
        this.$plugins.audio.play("StoreOpen");
        if (this.isInFacebook) {
          this.facebookProducts = await this.$store.dispatch(
            "products/getFacebookProducts"
          );

          // window.FB.api("/app/products", "get", (response) => {
          //   if (response && response.data && response.data.length > 0) {
          //     this.facebookProducts = [];
          //     response.data.forEach((element) => {
          //       this.facebookProducts.push(element);
          //     });
          //     console.log("this.facebookProducts", this.facebookProducts);
          //   } else {
          //     console.log("facebook product not found.");
          //   }
          //   console.log("/app/products", response.data);
          // });
        }
        if (this.getRealPlatform === "android") {
          this.platformProducts = await this.$store.dispatch(
            "products/getPaymentProducts"
          );
        } else if (this.getRealPlatform === "web") {
          console.log("window.FB", window.FB);

          window.FB.api(
            "/app/purchases",
            "get",
            { access_token: window.FB.getAccessToken() }, // user access token
            (payload) => {
              // callback function
              console.log("purchases payload:", payload);
            }
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    closeWindow() {
      this.$store.commit("modals/hide", "shop");
      this.currentTab = "buy-chip";
      this.$store.state.modals.isVipRedirected = false;

      if (this.$store.state.modals.nextWindowText) {
        this.$store.state.modals.nextWindowText = "";
      }
    },
    handleTabs(tab) {
      this.currentTab = tab;
    },
    closeConfirmWindow() {
      this.$data.showConfirmWindow = false;
    },
    // getProductPrice(product) {
    //   return product.price - product.price * (product.discount / 100);
    // },
    closeSelectGateway() {
      this.showSelectGateway = false;
      this.selectedPlatformProduct = {};
      this.selectedProductGateWays = [];
    },
    async selectPaymentProduct(product) {
      if (this.user.country !== "ID") {
        return;
      }
      const res = await this.$store.dispatch(
        "products/getProductGateWays",
        product.id
      );
      this.selectedPlatformProduct = product;
      this.selectedProductGateWays = res;
      this.showSelectGateway = true;
    },
    showFBPaymentUI({ productURL, quantity, productId }) {
      this.isfbPopupOpen = true;

      window.FB.ui(
        {
          method: "pay",
          action: "purchaseitem",
          quantity: 1,
          product: productURL,
        },
        (data) => {
          this.isfbPopupOpen = false;
          console.log("pay response:", data);
          if (typeof data === "undefined") return;
          if (typeof data.error_code !== "undefined")
            return this.$plugins.toast.show({
              text: this.$t("caseWindow.transactionFailed"),
              duration: 3000,
              variant: "danger",
            });
          if (typeof data.status === "undefined") return;
          if (data.status == "completed") {
            this.$plugins.toast.show({
              text: this.$t("caseWindow.transaction"),
              duration: 3000,
              variant: "success",
            });
            window.gtag("event", "facebook_purchase", {
              event_category: "PURCHASE",
              event_label: "PURCHASE",
              value: productId,
            });
          }
        }
      );
    },
    buyFacebookProduct(productId) {
      if (this.isfbPopupOpen == true) return;
      if (!productId) return;

      this.isfbPopupOpen = true;
      let productURL = `${process.env.VUE_APP_API_URL}/products/facebook/${productId}`;
      this.showFBPaymentUI({ productURL, quantity: 1, productId });
      console.log("productId", productId);
      console.log("productURL", productURL);
      // FB.ui(
      //   {
      //     method: "pay",
      //     action: "purchaseiap",
      //     product_id: String(productId),
      //   },
      //   (response) => {
      //     console.log("FB.ui pay:", response);
      //     this.isfbPopupOpen = false;
      //     if (response && response) {
      //     }
      //   } // Callback function
      // );
    },
    async redeemCode() {
      const here = this;
      if (!this.enteredRedeemCode) {
        return this.$plugins.toast.show({
          text: this.$t("redeem.failed"),
          duration: 3000,
          variant: "danger",
        });
      }
      const res = await this.$store.dispatch(
        "products/redeemCode",
        this.enteredRedeemCode
      );
      if (res) {
        this.enteredRedeemCode = "";
        this.$plugins.toast.show({
          text: `${here.getChipFormat(res.amount)}``${this.$t(
            "shopWindow.chipAdded"
          )}`,
          duration: 3000,
          variant: "success",
        });
      }
    },
    async buyVip() {
      this.closeConfirmWindow();
      this.loading = true;
      const response = await this.$store.dispatch("products/buyVip");
      if (response.response && response.response.status === 409) {
        this.$plugins.toast.show({
          text: this.$t("shopWindow.alreadyVip"),
          duration: 3000,
          variant: "danger",
        });
      } else if (response.response && response.response.status === 402) {
        this.$plugins.toast.show({
          text: this.$t("shopWindow.noChips"),
          duration: 3000,
          variant: "danger",
        });
        this.$adjust.trackEvent("ClickBuyVipAndInsufficientChips");
      } else if (response.id) {
        this.$plugins.toast.show({
          text: this.$t("shopWindow.successfullyPurchased"),
          duration: 3000,
          variant: "success",
        });
        this.$adjust.trackEvent("ClickBuyVipAndSuccessful");
        this.$store.commit("user/setUserData", {
          vip: {
            status: true,
            expiresAt: response.expiresAt,
          },
        });
      }
      this.loading = false;
    },
    async buy(product) {
      try {
        if (!product.canPurchase) return false;
        await this.$plugins.inAppPurchase.order(product.id);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.shop-window {
  position: fixed;
  width: 1920px;
  height: 1080px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  top: 180px;
  left: 320px;
  z-index: 12;
  .tab-selectors {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 5;
    background-color: #3d0b14;
    border-radius: 15px 15px 0px 0px;

    .shop-close {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;
      top: 20px;
      right: 20px;
      width: 65px;
      height: 65px;
      border-radius: 100%;
      border: 3px solid #cf707f;
      cursor: pointer;
      transition: all 0.25s ease;
      &:active {
        filter: brightness(60%);
        -webkit-filter: brightness(60%);
      }
      .exit-icon {
        transform: scale(0.7);
      }
    }
    .tab-selector {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-width: 271px;
      min-height: 99px;
      cursor: pointer;
      color: #cf707f;
      font-size: 35px;
      font-weight: bold;
      text-transform: uppercase;
      text-shadow: 3px 3px 3px black;
      &.active,
      &:focus,
      &:active {
        color: white;
      }
    }
    .vip-gold {
      position: absolute;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 84px;
      left: 20px;
      top: 10px;
      background: -webkit-linear-gradient(#fdfe9a, #fbe349);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;
      font-weight: bold;
    }
    .vip-time {
      position: absolute;
      height: 116px;
      width: 350px;
      bottom: -200px;
      background-image: url("~@/assets/images/lobby/vip-bar.png");
      background-size: cover;
      background-repeat: no-repeat;
      display: none;
      .vip-time-text {
        position: absolute;
        bottom: 20px;
        right: 10px;
        font-size: 35px;
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
  .content-section {
    width: 100%;
    height: 90%;
    background: url("~@/assets/images/backgrounds/lobby-background.png")
      no-repeat center center;
    background-size: cover;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;

    .inner-content {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;
      .no-product-message {
        width: 100%;
        height: 87%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 45px;
        margin-top: 40px;
        text-align: center;
      }
      &.buy-chip {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .products {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .inner-products {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            overflow-x: scroll;

            justify-content: space-around;
            .loading-container {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      &.redeem {
        .redeem-container {
          position: relative;
          width: 100%;
          height: 100%;
          position: relative;
          .redeem-bg {
            position: absolute;
            top: 100px;
          }
          .redeem-info-text {
            font-size: 50px;
            width: 100%;
            height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fedb98;
            margin-bottom: 60px;
          }
          input {
            width: 700px;
            height: 90px;
            background: #16052f;
            font-size: 45px;
            text-align: center;
            border-radius: 45px;
            border: 2px solid #558c29;
            display: flex;
            align-items: center;
            justify-content: center;
            background: (rgba(black, 0.8));
            left: 0;
            right: 0;
            margin: auto;
            margin-bottom: 30px;
            color: #fedb98;
            font-weight: bold;
            &::placeholder {
              color: rgba(black, 0.5);
              color: #fedb98;
              font-weight: bold;
            }
            &:focus {
              box-shadow: inset 0 6px 6px #558c29;
              background: (rgba(black, 0.8));
              color: #fedb98;
              font-weight: bold;
            }
          }
          .redeem-button {
            display: flex;
            width: 100%;
            height: 50%;
            justify-content: center;
            align-items: center;

            button {
              text-transform: uppercase;
            }
          }
        }
      }
      &.buy-vip {
        .vip-container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .part-of-vip {
            position: relative;
            width: 600px;
            height: 800px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            .text-container {
              position: absolute;
              top: 140px;
              left: 50px;
              width: 500px;
              height: 480px;
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
              .vip-title {
                font-size: 35px;
                margin-left: 10px;
              }
              .row {
                font-size: 36px;
                line-height: 50px;
              }
              .title {
                position: absolute;
                top: -130px;
                width: 100%;
                height: 80px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                color: #452a1c;

                .top-title {
                  font-size: 36px;
                  text-transform: uppercase;
                  font-weight: bold;
                }
                .bottom-title {
                  font-size: 32px;
                }
              }
              .bottom {
                position: absolute;
                bottom: -125px;
                left: 60px;
              }
            }
            // &.bronze {
            //   background-image: url("~@/assets/images/lobby/bronze-vip.png");
            // }
            // &.silver {
            //   background-image: url("~@/assets/images/lobby/silver-vip.png");
            // }
            &.gold {
              background-image: url("~@/assets/images/lobby/gold-vip.png");
            }
          }
        }
      }
    }
  }
}
.error-info {
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column;
}
</style>
