import PokerRoomConstants from "@/constants/game/poker/room";

export default {
  // communication websocket instance
  communicationWebsocket: null,

  roomList: [],

  // colyseus client instance
  colyseusClients: {
    POKER: null,
    BLACKJACK: null,
  },

  colyseusConnection: {
    LOBBY: null,
    ACTIVE_ROOM: null,
  },

  ACTIVE_ROOM: PokerRoomConstants,

  isLobbyConnected: false,

  isBlackjackLoading: true,

  lastTableId: 0,
  pokerWinnerCards: [],
  slotBigWinners: [],
  lastOpenedTableList: "",
  tableSitRequest: false,
  reConnectCount: 0,
  userRaiseClick: false,
  userFoldClick: false,
  userCallClick: false,
  appScaleAmount: 1,
};
