import moment from 'moment';
require('moment-timezone')

export default {

    instance: moment,

    install(Vue) {
        Vue.prototype.$plugins.moment = moment
    }

}