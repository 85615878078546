<template>
  <div
    class="action-buttons"
    v-show="
      !nGetMySeat.split.active ||
      (!nGetMySeat.split.busted && nGetMySeat.split.handStrength < 21)
    "
  >
    <button
      type="button"
      class="action-button double"
      v-if="!nGetMySeat.split.active"
      :disabled="
        nGetMySeat.talked ||
        !ableToDoubleDown ||
        nGetMySeat.split.handStrength > 0 ||
        nGetMySeat.stack < nGetMySeat.bet.main
      "
      @click="doubleDown"
    >
      <span class="icon">2X</span>
      <span class="text">Double</span>
      <span class="percent"><AnimatedNumber :number="getDdPercent" />%</span>
    </button>
    <button
      type="button"
      class="action-button hit"
      :disabled="nGetMySeat.talked"
      @click="hit"
    >
      <span class="icon">+</span>
      <span class="text">{{ $t("blackJack.hit") }}</span>
      <span class="percent"
        ><AnimatedNumber :number="getActionPercentByCode(1)" />%</span
      >
    </button>
    <button
      type="button"
      class="action-button stand"
      :disabled="nGetMySeat.talked"
      @click="stand"
    >
      <span class="icon">-</span>
      <span class="text">{{ $t("blackJack.stand") }}</span>
      <span class="percent"
        ><AnimatedNumber :number="getActionPercentByCode(2)" />%</span
      >
    </button>
    <button
      type="button"
      class="action-button split"
      v-if="!nGetMySeat.split.active"
      :disabled="
        nGetMySeat.talked ||
        !hasPair ||
        nGetMySeat.insured ||
        nGetMySeat.cards.length > 2 ||
        nGetMySeat.split.bet > 0 ||
        nGetMySeat.stack < nGetMySeat.bet.main
      "
      @click="split"
    >
      <span class="icon split"></span>
      <span class="text">Split</span>
      <span class="percent"
        ><AnimatedNumber :number="getActionPercentByCode(4)" />%</span
      >
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import AnimatedNumber from "../../../Utils/AnimatedNumber";

export default {
  name: "ActionButtons",
  components: { AnimatedNumber },
  computed: {
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    getDdPercent() {
      const allPlayers = this.getActiveRoomState.players.filter(
        (player) => player.bet.main > 0
      );
      const doubledownPlayers = this.getActiveRoomState.players.filter(
        (player) => player.bet.main > 0 && player.doubledown
      );

      return Math.floor((100 * doubledownPlayers.length) / allPlayers.length);
    },
    ableToDoubleDown() {
      return this.nGetMySeat.cards.length === 2;
    },
    hasPair() {
      if (this.nGetMySeat.cards.length < 2) return false;

      const firstTwoCards = [
        this.nGetMySeat.cards[0],
        this.nGetMySeat.cards[1],
      ];

      return (
        firstTwoCards[0].index === firstTwoCards[1].index ||
        firstTwoCards[0].value === firstTwoCards[1].value
      );
    },
  },
  methods: {
    getActionPercentByCode(actionCode = 0) {
      const allPlayers = this.getActiveRoomState.players.filter(
        (player) => player.bet.main > 0
      );
      const sameActionCodePlayers = this.getActiveRoomState.players.filter(
        (player) =>
          player.bet.main > 0 &&
          player.lastAction === actionCode &&
          !player.doubledown
      );

      return Math.floor(
        (100 * sameActionCodePlayers.length) / allPlayers.length
      );
    },
    hit() {
      this.$plugins.audio.play("BjClick");
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("hit");
    },
    stand() {
      this.$plugins.audio.play("BjClick");
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("stand");
    },
    doubleDown() {
      this.$plugins.audio.play("BjClick");
      if (this.nGetMySeat.stack < this.nGetMySeat.bet.main)
        return this.$plugins.toast.show({
          text: this.$t("blackJack.InsufficientChips"),
          variant: "danger",
        });
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send(
        "doubleDown"
      );
    },
    split() {
      this.$plugins.audio.play("BjClick");
      if (this.nGetMySeat.stack < this.nGetMySeat.bet.main)
        return this.$plugins.toast.show({
          text: this.$t("blackJack.InsufficientChips"),
          variant: "danger",
        });
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("split");
    },
  },
};
</script>

<style scoped lang="scss">
.action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 180px;
  .action-button {
    position: relative;
    width: 134px;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: transparent;
    background-image: linear-gradient(
      0deg,
      rgb(255, 255, 255) 0%,
      darken(rgb(255, 255, 255), 20%) 30%,
      rgb(255, 255, 255) 70%,
      darken(rgb(255, 255, 255), 20%) 100%
    );
    margin-right: 15px;
    border-radius: 8px;
    padding: 0;
    border: 0;
    box-shadow: 0 0 0 4px rgba(rgb(255, 255, 255), 0.3);
    opacity: 0.9;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 18px;
    text-shadow: 0 1px 0 black;
    cursor: pointer;
    transition: all 0.25s ease;
    &:disabled {
      opacity: 0.5;
      filter: grayscale(100%);
    }
    .icon {
      display: block;
      font-size: 42px;
    }
    .text {
      display: block;
      margin-top: -5px;
    }
    .percent {
      position: absolute;
      left: 0;
      bottom: -26px;
      font-weight: bold;
      font-size: 17px;
      width: 98%;
      margin-left: 1%;
      text-shadow: 0 0 5px black;
      text-align: center;
      background-color: rgba(black, 0.15);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &:not(:disabled) {
      &:hover {
        opacity: 1;
        transform: scale(1.05);
      }
      &:active {
        opacity: 1;
        transform: scale(0.95);
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &.double,
    &.split {
      width: 100px;
      height: 59px;
      .icon {
        display: block;
        font-size: 22px;
      }
      .text {
        display: block;
      }
    }
    &.double {
      box-shadow: 0 0 0 5px rgba(#e27116, 0.5);
      background-image: linear-gradient(
        0deg,
        #e27116 0%,
        #c05601 50%,
        #e27116 100%
      );
    }
    &.hit {
      box-shadow: 0 0 0 5px rgba(#20cf68, 0.5);
      background-image: linear-gradient(
        0deg,
        #22ab59 0%,
        #208849 30%,
        #20cf68 100%
      );
    }
    &.stand {
      box-shadow: 0 0 0 5px rgba(#bd2531, 0.5);
      background-image: linear-gradient(
        0deg,
        #b83634 0%,
        #932122 30%,
        #bd2531 100%
      );
    }
    &.split {
      box-shadow: 0 0 0 5px rgba(#29a4d0, 0.5);
      background-image: linear-gradient(
        0deg,
        #2295cc 0%,
        #2b8ed1 50%,
        #29a4d0 100%
      );
      .icon {
        background-image: url("~@/assets/images/blackjack/icons/split-icon.png");
        background-repeat: no-repeat;
        background-size: 100%;
        width: 31px;
        height: 18px;
      }
    }
  }
}

.platform-mobile {
  .action-buttons {
    margin-top: 0;
    transform: scale(1.4);
  }
}
</style>
