/*
 * Indonesian language file
 */

export default {
  loading: "Memuat...",

  general: {
    no: "Tidak",
    yes: "Ya",
    avatar: "Avatar",
    vipIcon: "Ikon Vip",
    cancel: "Batal",
    save: "Simpan",
    logout: "Keluar",
    play: "Main",
    chat: "Obrolan",
    leaders: "Top List",
    tableInformation: "Informasi",
    social: "Sosial",
    case: "Brangkas",
    shop: "Toko",
    okay: "OK",
    refresh: "Segarkan",
    send: "Kirim",
    username: "Nama Pengguna",
    delete: "Hapus",
    reject: "Tolak",
    accept: "Terima",
    confirm: "Konfirmasi",
    registrationDate: "Tanggal Registrasi",
    name: "Nama",
    total: "Total",
    copy: "Salinan",
    commission: "Commission",
    getGift: "Dapatkan Hadiah",
    transfer: "Transfer",
    menu: "Tidak bisa",
    update: "Pembaruan",
    invite: "Undang",
    joinNow: "Gabung",
    handHistory: "Riwayat Kartu", //NEW***
  },
  giftsWindow: {
    gift: "Hadiah", //NEW***
    getYourself: "Untuk Anda", //NEW***
    getEveryone: "Untuk Semua", //NEW***
    expression: "Emoji", //NEW***
    selectGift: "Pilih hadiah", //NEW***
    noPlayersAtTable: "Tidak ada pemain di meja", //NEW***
    sitATable: "Silakan duduk terlebih dahulu.", //NEW***
    selectUser: "Silakan pilih pengguna", //NEW***
  },
  noficationWindow: {
    notificationInviteTable:
      "{name} mengundang Anda ke meja {gameType}{tableId} pada {date}", // NEW***
    notificationInviteChip: " {name} mengirim Anda {amount} chips.", // NEW***
    sendMessage: " Tulis pesan Anda di sini", // NEW***
    tableInvite: "Meja Undangan", // NEW***
    system: "Sistem", // NEW***
    messages: "Pesan", // NEW***
    notifications: "Notifikasi", // NEW***
  },
  dailyEntry: {
    dailyEntry: "Bonus Harian ", //NEW***
    dailyDesc: "Log in setiap hari dan dapatkan lebih banyak chips!", //NEW***
    day: "Hari", //NEW***
    sign_guest: "Mode Pengunjung",
    sign_apple: "Masuk dengan Apple",
    sign_google: "Masuk dengan Google",
    sign_facebook: "Masuk dengan Facebook",
    loginBonusText:
      "<p class='login-info-text'> Dapatkan bonus $5.000.000.000 chips! <span class='color-white'> {0} </span> atau <span class='color-white'> {1} </span> </p>",
    loginBonusTextWeb:
      "<p class='login-info-text'> Dapatkan bonus $5.000.000.000 chips! <span class='color-white'> {0} </span> </p>",
  },
  authentication: {
    signInToContinue: "Sign-in untuk melanjutkan",
    anErrorOccuredWhileLoggingIn: "Terjadi kesalahan saat login!",
    contact: "Kontak",
    privacyPolicy: "Kebijakan Privasi",
    provablyFair: " Provably Fair",
    sign_guest: "Mode Pengunjung",
    sign_apple: "Masuk dengan Apple",
    sign_google: "Masuk dengan Google",
    sign_facebook: "Masuk dengan Facebook",
    loginBonusText:
      "<p class='login-info-text'> Dapatkan bonus ekstra chips! <span class='color-white'> {0} </span> or <span class='color-white'> {1} </span> </p>",
  },
  playerActions: {
    enoughChips: "Chips Anda tidak cukup",
    minSendChipAmount: "Jumlah pengiriman chip tidak boleh kurang dari 10B",
    failedTransfer: "Pengiriman chips gagal",
    successTransfer: "Chips berhasil terkirim.",
    validChip: "Mohon masukkan jumlah yang valid",
  },
  tableList: {
    table: "Meja",
    tableId: "ID Meja",
    tableName: "Nama",
    blind: "Blind",
    minMax: "Min/Maks",
    filterByBlinds: "Filter dengan Blinds",
    hideEmpties: "Sembunyikan meja kosong",
    hideFullyFilled: "Sembunyikan meja penuh",
    all: "Semua",
    players: "Pemain",
    full: "Penuh",
    noRoomsMessage: "Tidak ada meja.",
    tableList: "Daftar Meja",
    tableInfo: {
      emptyRoomMessage: "Meja kosong.",
    },
  },
  message: {
    message: "Pesan",
    sendMessage: "Kirim Pesan",
    typeMessage: "Tulis Pesan Anda",
    sentMessage: "Mengirim Anda pesan",
  },

  lobby: {
    playNow: "Main Sekarang ",
    newSlotGame: "Permainan Slot Baru",
    availableNow: "Telah Hadir ",
    specialForYou: "Spesial Untuk Anda",
    play: "Main",
    favouriteGameButton: {
      instructionMessage: "Tekan dua kali untuk mengubah",
    },
  },

  bonusWindow: {
    congratulations: "Selamat!",
    accept: "Terima",
  },

  maintenance: {
    gameMaintenance: "Permainan dalam pemeliharaan",
    underMaintenance: "Sedang dalam pemeliharaan",
    tryAgainLAter:
      "Permainan sedang dalam pemeliharaan. Silakan coba beberapa saat lagi!",
  },

  confirmWindow: {
    sure: "Anda yakin?",
    userNotFound: "Pengguna tidak ditemukan",
    notAffiliate: "Pengguna bukan affiliasi",
    DaysVip: "Anda akan berlangganan VIP untuk 30 hari",
  },
  deleteConfirmWindow: {
    sure: "Anda akan menghapus akun Anda",
    content:
      "Jika Anda melanjutkan proses ini, informasi pengguna Anda dan produk yang telah Anda beli akan dihapus",
    delete: "Hapus",
    cancel: "Batal",
    deleteSuccessfully: "Penghapusan akun berhasil",
    notBeDeleted: "Kesalahan saat menghapus",
    daysLimit:
      "Jika akun Anda tidak aktif selama 30 hari, akun akan dihapus permanen.", //NEW****
    areYouSure: "Yakin akan menghapus akun Anda?",
    deletefriends: "Pertemanan telah berhasil dihapus",
  },
  historyWindow: {
    hand: "Tangan Kartu",
    preFlop: "Pre-Flop",
    flop: "Flop",
    turn: "Turn",
    river: "River",
    winner: "Pemenang",
    speacial: "Spesial",
    team: "Tim",
    anyHistory: "Tidak ada riwayat untuk ditampilkan",
  },
  socialWindow: {
    gameCardDesc: "Dapatkan bonus dari tiket yang Anda menangkan.",
    friends: "Teman",
    searchFriend: "Cari teman",
    gameCard: "Kartu game",
    requests: "Permintaan",
    sharecode: "Bagikan Kode", // NEW***
    inviteCodeDescription:
      "-Bagikan kode undangan di atas dengan teman. <br><br>- Biarkan teman baru Anda bermain 10 tangan di meja poker. <br><br>- Kalian berdua mendapatkan 10B chips!<br>",
    chipTransferHistory: "Riwayat pengiriman chips",
    socialMedia: "Media Sosial",
    socialMediaAndInviteCode: "Media sosial & Kode Undangan",
    noFriendRequest: "Anda tidak memiliki permintaan pertemanan.",
    noFriends: "Anda tidak memiliki pertemanan.",
    noChipTransfer: "Tidak ada riwayat pengiriman chips",
    extraChip: "Ikuti akun sosmed kami untuk bonus chips.",
    followUs: "Ikuti kami di Media Sosial!",
    inviteCode: "Kode Undangan",
    myCode: "My Code",
    inviteCodeDescription:
      "Bagikan kode undangan di atas dengan teman Anda. <br><br>- Biarkan teman Anda sebagai anggota baru bermain 10 tangan di meja poker. <br><br>- Kalian berdua mendapatkan 10B chip!<br><small>Kode undangan tidak tersedia untuk akun pengunjung.</small>",
    shareWithFriends: "Bagikan dengan teman!",
    shareWithFriendsCode:
      "Dapatkan chip bonus 10 m setelah teman Anda memainkan 10 tangan poker. Semakin banyak kode undangan yang Anda kirim, makin banyak pula chip bonus yang akan Anda dapatkan. Code: {code}",
    shareInviteCodeWithFriends: "Bagikan kode undangan anda",
    removeFriend: "Hapus pertemanan",
    block: "Blokir",
    unBlock: "Buka Blokir",
  },
  friendDetailsWindow: {
    failed: "Gagal mengirim permintaan pertemanan",
  },
  chipHistoryDetail: {
    youSent: "Anda mengirim",
    chips: "Chips",
    sentYou: "Mengirimi Anda",
  },
  createTableWindow: {
    bets: "Taruhan",
    createTable: "Buat Meja",
    seatsNumber: "Nomor duduk",
    potTable: "Meja Pot",
    teamTable: "Meja Tim",
    tellerEntrance: "Pintu Masuk Kasir",
  },
  chipTransferWindow: {
    sendChip: "Kirim Chips",
    chipAmount: "Masukkan jumlah chip",
    chipMessage: "Tulis pesan di sini",
    warning: "*Minimum pengiriman chips adalah 10B dan tanpa batas maksimum!",
  },
  playerDetailsWindow: {
    profileNote: "Catatan Profil",
    statistics: "Statistik",
    level: "Level",
    recordChip: "Chip Rekor",
    friendsCount: "Jumlah Teman",
    bestHand: "Tertinggi",
    copied: "Tersalin!",
    failedChanges: "Gagal menyimpan perubahan",
    enterProfileNote: "Masukkan catatan profil Anda..", //NEW****
    gameStatistics: "Statistik Permainan", //NEW****
    sameFrameWarning: "Pilih bingkai yang lain dari sebelumnya.", //NEW****
    sameAvatarWarning: "Pilih avatar yang lain dari sebelumnya.", //NEW****
    fillFields: "Silakan isi semua kolom", //NEW****
    passwordsDoesNotMatch: "Kata sandi tidak cocok", //NEW****
    passwordLength: "Kata sandi harus memiliki minimal 6 sampai 30 karakter",
    vipdesc: "Fitur ini hanya tersedia untuk pemain VIP.",
    changeName:
      "Kesempatan Anda mengganti nama telah habis. Anda dapat membeli VIP dan coba kembali",
  },
  shopWindow: {
    bronze: "Bronze",
    silver: "Silver",
    gold: "Gold",
    redeem: "Redeem",
    keysField: "Silakan masukkan kode kartu game.",
    withoutChip: "Masuk Meja Tanpa Chip",
    buyChip: "Beli Chip",
    redeemCode: "Kode Redeem",
    noProduct: "Tidak ada produk untuk ditampilkan.",
    buyVip: "Beli Vip",
    privateEmojis: "VIP Emoji, Gifts dan Bingkai Profil",
    privateProfile: "Bingkai Profil Spesial VIP",
    connectionNeverLost: "Koneksi tanpa Henti",
    unLimitedView: "Waktu Menonton Tanpa Batas",
    privateTable: "Membuat Meja Spesial",
    unlimitedChipTransfer: "Unlimited Chip Transfer",
    usingPrivateCase: "Menggunakan Berangkas spesial",
    xp: "10.000XP Gift",
    freeChat: "Bebas Chat",
    day: "Hari",
    daysLeft: "Sisa Hari",
    alreadyVip: "Anda sudah berlangganan VIP.",
    noChips: "Chip Anda tidak mencukupi untuk berlangganan VIP",
    successfullyPurchased: "Anda telah berhasil berlangganan VIP.",
    chipAdded: "Chips berhasil diterima",
  },
  caseWindow: {
    onlyVip: "Fitur ini hanya tersedia untuk pengguna VIP",
    buyVip: "Beli VIP",
    chipInGame: "Chip Dalam Game",
    chipInCase: "Chip Dalam Brangkas",
    transactionFailed: "Transaksi gagal",
    theretentation: "Sembunyikan chips dari Leaderboard dan  pemain lain.",
    fromGame: "Dari Permainan",
    fromCase: "Dari Brangkas",
    amount: "Jumlah",
    transaction: "Tindakan telah berhasil dilakukan.",
    enoughChip: "Chip Anda tidak cukup untuk deposit brangkas.",
    withdraw: "Chip Anda tidak cukup untuk penarikan dari brangkas.",
    validAmount: "Masukkan jumlah yang valid",
    transfer: "Transfer",
  },
  friendsRequestWindow: {
    friendsOffer: "ANDA MENERIMA PERMINTAAN PERTEMANAN!",
    successRequest: "Permintaan pertemanan telah berhasil terkirim.",
    acceptRequest: "telah menerima permintaan pertemanan Anda.",
    rejectedRequest: "telah menolak permintaan pertemanan Anda.",
    becomeFriends:
      "mengirim Anda permintaan pertemanan! Apakah ingin berteman?",
    requestSent: "Permintaan telah terkirim",
    friendRequestToYou: "mengirim Anda pertemanan.",
  },
  friends: {
    online: "ONLINE",
    goToTable: "Gabung",
    chipTransfer: "Chip Transfer",
    friendRequests: "Permintaan Teman",
    incoming: "MASUK",
    sent: "TELAH TERKIRIM",
    search: "CARI",
    addButtonText: "Tambah Teman",
    userProfile: "Profil Pengguna",
    sendChipText: "Kirim Chips",
    deletefriends: "Hapus Pertemanan",
  },
  settings: {
    lobbyMusic: "Musik Lobi",
    soundEffects: "Sound FX",
    vibration: "Getar",
    blockInvites: "Permintaan Teman",
    giftHide: "Sembunyikan Hadiah",
    handStrengthIndicator: "Indikator kartu",
    graphicsPreferences: "Pengaturan grafik",
    tableColor: "Warna Meja",
    roomBgColor: "Latar belakang ",
    languagePreference: "Pengaturan bahasa",
    termsOfUse: "Aturan Penggunaan",
    accountInfo: "Info Akun",
    profileID: "ID Pemain",
    deviceNO: "Perangkat",
    deleteAccount: "Hapus Akun",
    language: {
      turkish: "Turki",
      english: "Inggris",
      italian: "Italia",
      indonesian: "Bahasa Indonesia",
      portuguese: "Portugis",
      russian: "Russia",
      malesian: "Melayu",
    },
  },
  settingsWindow: {
    language: "Bahasa",
    gameSettings: "Pengaturan Permainan",
    customize: "Penyesuaian",
    security: "Keamanan",
    successfullySaved: "Perubahan berhasil tersimpan.",
    pinCode: {
      pinCode: "KATA SANDI",
      createPinDesc: "Buat Kata Sandi untuk keamanan akun",
      reEnterPin: "Masukkan ulang Kata Sandi",
      emailAddress: "Alamat E-Mail",
      emailRequired: "Diperlukan untuk mengatur ulang Kata Sandi Anda",
      areYouSure: "Yakin akan menghapus kode pin Anda?",
      pinDesc:
        "Kode pin sangat penting untuk keamanan akun. Turn Games tidak akan menerima laporan apa pun kecuali Anda memiliki kode pin di akun Anda",
    },
    customizeWindow: {
      tableStyle: "Gaya Meja",
      tableColors: "Warna Meja",
      roomBgColor: "Warna Latar Belakang",
    },
    gameSettingsWindow: {
      dontDistrub: "Jangan Ganggu",
      blockNotification: "Notifikasi",
      showAnimatedGifts: "Tampilkan Gifts Animasi",
    },
  },
  reportWindow: {
    report: "Laporan",
    reportFilter: "Filter Laporan",
    audiences: "Peserta",
    players: "Pemain",
    successMessage: "Laporan telah berhasil terkirim.",
    failedMessage: "Laporan gagal terkirim.",
    swearing: "memaki",
    team: "Tim",
    scissors: "Multiple raise",
    noPlayersFound: "Tidak ada pemain atau peserta.",
  },
  inboxContact: {
    contactUs: "Hubungi Kami",
    title: "Judul",
    textHere: "Tulis di sini",
    congratulations: "Selamat",
    inbox: "Kotak Masuk",
    desc: "Anda mendapatkan 10,000 chips dari paket pembelian terakhir.",
    failedMessage: "Gagal mengirim pesan",
    successfullyMessage: "Pesan telah terkirim",
    validEmail: "Silakan masukkan Email yang valid",
    fillTabs: "Mohon isi semua kolom",
  },
  errors: {
    connectionLost: "Koneksi terputus",
    yourConnection: "Koneksi Anda terputus.",
    errorOccured: "Telah terjadi kesalahan",
    gameServiceError:
      "Terjadi kesalahan pada Layanan permainan. Silakan coba kembali",
    slotDownloadError: "Terjadi kesalahan saat memuat permainan.",
    loginError: "Terjadi kesalahan saat login.",
    insufficientBalance: "Saldo Anda tidak cukup.",
    tooManyRequests: "Anda tidak dapat mengirim terlalu sering.",
    haveToSitAtTable: "You have to sit at the table.",
    youAreBlocked: "You are blocked.",
    youAreBlockedTheUser: "Anda Telah memblokir pengguna",
    youAreUnblockedTheUser: "Anda telah membuka blokir pengguna",
    userNotActiveGame: "Saat ini pengguna tidak dalam permainan",
    notEnoughLevelPlayGame:
      "Dibutuhkan level {level}  untuk bergabung di meja {game}.",
    tableNotFound: "tableNotFound",
    playerIdWrong: "ID Referral salah",
    searchedUserNotFound: "ID Referral tidak ditemukan",
  },
  searchWindow: {
    searchUser: "Mencari pengguna",
    idOfUser: "Silakan masukkan ID pengguna yang Anda cari.",
    search: "pencarian",
    ownId: "Ini adalah ID Anda",
    validId: "Silakan masukkan ID yang valid",
  },
  adBonusWindow: {
    title: "DAPATKAN CHIPS GRATIS!",
    content: "Tonton video singkat dan dapatkan ${money} chips!",
    watchButton: "Tonton",
  },
  affiliate: {
    affiliateDesc: " Masukkan ID Referral teman Anda.",
    affiliateOffDesc: "ID Referral Anda",
    affiliate: "Affiliasi",
    addAffiliate: "Tambahkan Referral",
    affiliateId: "Masukkan ID Referral",
    clear: "Hapus",
    searchBy: "Cari dengan",
    name: "Nama",
    noData: "Tidak ada data",
    startDate: "Waktu memulai",
    endDate: "Waktu mengakhiri",
    warning: "Jadilah mitra kami dan ciptakan kestabilan profit Anda!",
    info: "Pelajari lebih lanjut tentang cara bergabung dengan kami.",
    failedAffiliate: "Gagal menambahkan referral",
    successfullyAdded: "ID Referral berhasil ditambahkan.",
    date_wrong: "Tanggal mulai harus sebelum tanggal akhir",
    affiliateId: "ID Referral",
    excelDownloaded: "Diunduh ke Dokumen",
  },
  notification: {
    noData: "Tidak ada notifikasi",
  },
  chat: {
    worldChat: "Obrolan Global",
    directChat: "Obrolan Pribadi",
    onlyVip: "Hanya pengguna VIP yang dapat mengirim pesan di Obrolan Global",
    showMore: "Tampilkan",
    anyMessages: "Anda tidak mempunya pesan.",
  },
  disconnectedWindow: {
    disconnected: "Tiada hubungan",
    disconnectedServer: "Oops… Anda tidak terhubung dengan server!",
    reconnect: "Hubungkan ulang",
    inactive: "Oops…Anda tidak aktif untuk beberapa saat!",
    reconnected: "HUBUNGKAN ULANG",
    banned: "Anda telah terbanned dari permainan!",
    bannedDescription:
      "Anda dapat menghubungi kami melalui support@turngs.com.",
  },

  rulesWindow: {
    texasDesc:
      "Texas Hold'em Poker adalah permainan kartu paling populer di dunia. Tujuan dari permainan ini adalah untuk menang dengan tangan yang lebih tinggi dari lawan. Ada 2 kartu di tangan Anda dan 5 kartu di atas meja, tangan yang membuat kombinasi 5 kartu terbaik dari total 7 kartu, menang. Setiap tangan dalam daftar di bawah ini mengalahkan tangan berikut. Misalnya, Straight Flush mengalahkan Four of Kind, Straight mengalahkan Pair. Tangan dihitung sesuai dengan kekuatannya.",
    omahaDesc:
      "Omaha adalah salah satu jenis poker paling populer di dunia. Tujuan dari permainan ini adalah untuk memenangkan pot. Ada 2 cara utama untuk mendapatkan chip: memenangkan kartu poker yang lebih tinggi dari tangan lawan atau menunggu umpan lawan atau menolak permainan. Satu tangan berisi 5 kartu. Aturannya adalah tangan harus berisi tiga dari lima kartu komunitas di atas meja dan dua kartu dari empat kartu yang dibagikan kepada pemain. Semua tangan dalam kategori dalam daftar mengalahkan semua tangan dalam kategori di bawahnya. Setiap tangan dalam daftar di bawah ini mengalahkan tangan berikut. Misalnya, Straight Flush mengalahkan Four of Kind, Straight mengalahkan Pair. Tangan dinilai sesuai dengan kekuatannya.",
    blackJackDesc:
      "Tujuan Turn Blackjack adalah untuk memiliki kartu dengan poin lebih tinggi dari bandar tanpa melebihi 21. Tangan terbaik disebut Blackjack - nilai total dari dua kartu pertama yang dibagikan di tangan ini adalah 21. Anda bermain hanya melawan bandar, tidak melawan pemain lain.Blackjack dimainkan dengan delapan deck. Bandar selalu berhenti di 17. Dapat digandakan pada dua kartu starter mana pun. Kartu awal dengan nilai yang sama dapat dibagi menjadi dua. Pembagian dapat dilakukan oleh masing-masing tangan. Satu kartu dibagikan untuk setiap pembagian As ganda. Penggandaan tidak dapat dilakukan setelah pembagian kartu. Asuransi direkomendasikan ketika bandar memiliki kartu As. Blackjack membayar 3:2. Asuransi membayar 1:2. Ketika tangan sama, itu seri.",
    handNames: {
      royalFlush: "Royal Flush",
      straightFlush: "Straight Flush",
      fourOfAKind: "Four Of A Kind",
      fullHouse: "Full House",
      flush: "Flush",
      straight: "Straight",
      threeOfAKind: "Three Of A Kind",
      twoPair: "Two Pair",
      pair: "One Pair",
      highCard: "High Card",
    },
    handDesc: {
      royalFlush: "The royal flush adalah tangan tertinggi poker.",
      straightFlush:
        "Lima kartu berurutan dengan jenis yang sama. Di antara 2 tangan Straight Flush, pemenangnya adalah yang memiliki kartu tertinggi.",
      fourOfAKind:
        "Empat kartu dengan peringkat yang sama. Kombinasi terkuat adalah kombinasi dengan kartu bernilai lebih tinggi. Jika pemain memiliki tangan yang sama, nilai kartu kelima (kicker) menentukan pemenangnya.",
      fullHouse:
        "Kombinasi triple dan pair. Saat membandingkan tangan Full House, pemain dengan tiga kartu terbaik akan menang. Jika sama, pemain dengan pair terbaik akan  menang.",
      flush:
        "Lima kartu tidak berurutan dengan jenis yang sama. Jika dua atau lebih pemain memiliki jenis yang sama, pemain dengan kartu tertinggi yang akan menang.",
      straight:
        "Lima kartu berurutan dari dua atau lebih jenis yang berbeda. Di antara dua tangan straight, pemenangnya adalah yang memiliki kartu dengan nilai tertinggi.",
      threeOfAKind:
        "Kombinasi tiga kartu dengan peringkat yang sama. Dari kedua trio tersebut, pemenangnya adalah yang memiliki kartu dengan nilai tertinggi. Jika trio ini memiliki nilai yang sama, yang lebih tinggi dari dua kicker yang tersisa menentukan pemenangnya.",
      twoPair:
        "Dua kartu dengan peringkat yang sama ditambah dua kartu dengan peringkat lain. Saat membandingkan pair, pasangan tertinggi menentukan pemenangnya. Jika pasangan tinggi memiliki nilai yang sama, pasangan rendah dibandingkan. Jika pasangan yang lebih rendah memiliki nilai yang sama, pemain dengan nilai kartu kelima (kicker) yang lebih tinggi yang akan menang.",
      pair: "Dua kartu dengan peringkat yang sama. Jika pemain memiliki pasangan yang sama, yang tertinggi dari tiga kartu yang tersisa (kicker) menentukan pemenangnya.",
      highCard:
        "Tangan poker yang tidak sesuai dengan persyaratan tangan yang dijelaskan sebelumnya. Jika pemain memiliki kartu tinggi yang sama, kartu tinggi kedua (dst.) menentukan pemenangnya.",
    },
    expressions: {
      blinds: {
        heading: "Blinds",
        desc: "Sebelum kartu dibagikan, pemain yang duduk di sisi kiri di sebelah kiri dealer harus memasang taruhan yang disebut small blind. Pemain berikutnya yang duduk di sebelah kiri small blind harus menempatkan apa yang disebut big blind. Jumlah big blind adalah dua kali lipat jumlah small blind.",
      },
      ante: {
        heading: "Ante",
        desc: "Jika seorang pemain bergabung dalam permainan, dia duduk di meja dan tidak dalam posisi big blind, pemain itu memasang taruhan paksa yang disebut ante. Itu bisa dilakukan sekali dan taruhan ante sama dengan big blind.",
      },
      minimumRaise: {
        heading: "Minimum Raise",
        desc: "Pemain tidak dapat melakukan raise kurang dari big blind.",
      },
      allIn: {
        heading: "All In",
        desc: "Jika seorang pemain tidak memiliki cukup chip untuk check taruhan, pemain dapat melakukan 'all-in'. Taruhan pemain dengan semua yang tersisa. Jika seorang pemain melakukan all-in, pemain tidak bisa memenangkan lebih banyak chip daripada yang bisa dia lihat. Jika pot naik setelah pemain melakukan all-in dan menang, pot akan dibagi antara pemain itu dan pemain dengan tangan terbaik berikutnya yang bisa membuat raise.",
      },
      potSplit: {
        heading: "Bagi Pot",
        desc: "Jika ada dua atau lebih pemain yang seri, pot dibagi rata di antara mereka.",
      },
      bjGameRules: {
        heading: "Aturan Permainan",
        desc: "Permainan ini dikelola oleh bandar dan jumlah pemain yang tidak terbatas dapat dimainkan per tangan. Permainan ini disajikan oleh bandar dan dimainkan dengan delapan dek standar yang terdiri dari 52 kartu. Nilai kartu 21 dalam permainan adalah sebagai berikut. Kartu dari 2 hingga 10 memiliki nilai yang tertulis di dalamnya. Kartu bergambar (Jacks, Queens dan Kings) bernilai 10 poin. Ace bernilai 1 atau 11, pemain dapat memilih nilai yang sesuai dengan tangannya. Perhatikan bahwa soft hand berisi 11 nilai Aces. Ketika waktu taruhan telah berakhir, bandar membagikan kartu terbuka ke semua pemain, yang terakhir untuk dirinya sendiri. Setelah itu bandar memberikan kartu kedua menghadap ke atas ke semua pemain, tetapi kartu kedua bandar akan menghadap ke bawah. Nilai tangan pertama Anda ditampilkan di sebelah kartu Anda. Blackjack (Dua Puluh Satu) Jika dua kartu yang Anda bagikan di awal permainan berjumlah 21, Anda memiliki Blackjack!",
        descInsurance:
          "Jika kartu bandar yang menghadap ke atas adalah As, Anda dapat membeli asuransi terhadap risiko bandar memiliki 21, bahkan jika total tangan Anda adalah 21. Jumlah asuransi akan sama dengan setengah dari taruhan utama Anda dan taruhan asuransi akan menjadi diproses secara terpisah dari taruhan tangan Anda. Setelah itu, bandar melihat nilai kartu bawahnya untuk memeriksa apakah dia membuat 21. Jika bandar tidak menghasilkan 21, putaran berlanjut. Jika bandar memiliki Blackjack, permainan berakhir dan bandar menang. Jika Anda dan bandar memiliki 21, permainan akan berakhir seri dan taruhan Anda akan dikembalikan. Perhatikan bahwa jika kartu menghadap ke atas bandar adalah 10 atau kartu warna, Anda akan memiliki opsi untuk membeli asuransi dan bandar tidak akan melihat kartu bawahnya untuk 21.",
        headingInsurance: "Asuransi",
        headingHit: "Double, Tambah atau Cukup",
        descHit:
          "Jika bandar menemukan bahwa setelah memeriksa dua kartu pertamanya dia belum membuat 21, para pemain diberi hak untuk memilih lebih banyak kartu untuk meningkatkan nilai tangan mereka, dengan memberikan bandar lebih banyak kartu kepada para pemain. tidak bernilai 21, Anda memiliki hak untuk menggandakan taruhan. Dalam hal ini, taruhan Anda akan digandakan dan Anda hanya akan diberikan satu kartu tambahan untuk ditambahkan ke tangan Anda. Atau, Anda dapat menggunakan opsi Tambah kartu untuk mendapatkan kartu tambahan untuk menambah nilai tangan Anda. Anda dapat menambah beberapa kartu sampai Anda puas dengan nilai tangan Anda dan memutuskan untuk Cukup (stand). Perhatikan bahwa pemain dengan soft hand sama dengan 21 tidak akan diberikan opsi keputusan.",
        headingSplit: "Bagi Dua",
        descSplit:
          "Jika tangan pertama Anda terdiri dari dua kartu dengan peringkat yang sama, Anda dapat membagi pasangan menjadi dua tangan yang berbeda, di mana masing-masing memiliki taruhan yang sama dengan taruhan utama Anda. Setelah kedua tangan dibagikan kartu kedua, Anda dapat meningkatkan nilai kedua tangan ini dengan menerapkan opsi Tambah kartu. Di sini, Anda dapat memilih opsi Cukup ketika Anda puas dengan nilai kedua tangan. Namun, ketika Anda membagi pasangan As pertama, kedua tangan secara otomatis akan menerima kartu tambahan dan tidak akan memiliki kesempatan untuk mendapatkan kartu lain.",
      },
    },
    actions: {
      desc: "Selama ronde pertaruhan, pemain dapat melakukan salah satu tindakan berikut.",
      call: {
        heading: "Call",
        desc: "Jika taruhan telah dibuat, berarti pemain harus mengakhiri taruhan. Misalnya, jika Pemain A bertaruh 10 dan pemain B melihatnya, Pemain B harus menutup taruhannya sebesar 10 dan menempatkan 10 ke dalam pot.",
      },
      check: {
        heading: "Check",
        desc: "Seorang pemain dapat melakukan check jika tidak ada taruhan yang dibuat di babak saat itu. Tindakan akan dipindahkan ke pemain berikutnya di sebelah kiri. Check dapat diartikan semacam pass; pemain dapat tetap mengikuti permainan tetapi memutuskan untuk tidak bertaruh pada ronde saat itu.",
      },
      raise: {
        heading: "Raise",
        desc: "Seorang pemain dapat menaikkan taruhan saat itu. Untuk melakukan ini, setidaknya harus menggandakan taruhan pemain sebelumnya. Untuk melanjutkan pertarungan pot, semua pemain berikut harus melihat taruhan ini, menaikkannya lagi, atau pass.",
      },
      fold: {
        heading: "Fold",
        desc: "Fold adalah penolakan untuk bnrhenti mengejar pot saat itu. Jika pemain lewat, kartu pemain tidak dibagikan dan pemain tidak bisa menang di babak itu.",
      },
    },
    states: {
      winning: {
        heading: "Kemenangan",
        desc: "Satu atau lebih pemain dengan tangan yang menang harus menunjukkan kartu turun mereka.",
      },
      aggressivePlayer: {
        heading: "Pemain Agresif",
        desc: "Pemain agresif adalah pemain terakhir yang menaikkan taruhan selama tangan. Jika taruhan terlihat dan tangan berakhir dengan pembukaan kartu, pemain agresif harus menunjukkan kartu turunnya terlepas dari hasil tangan.",
      },
      allInPlayer: {
        heading: "ALL-In Player",
        desc: "Jika pemain bertaruh semua chip, mereka semua melakukan All in dan tangan akan diakhiri dengan kartu terbuka. Pemain agresif harus menunjukkan kartu turun mereka terlepas dari hasil tangan.",
      },
      desc: "Jika satu atau lebih pemain bermain all-in dan tidak ada taruhan lanjut yang dipasang, semua pemain lain harus menunjukkan kartu turun mereka. Jika tidak, pemain yang terus menaikkan dan menghasilkan pot harus menunjukkan kartu mereka kepada pemain agresif dan pemenangnya akan ditentukan oleh aturan.",
      blackJackResult: {
        heading: "Kemenangan",
        desc: "Jika nilai total tangan Anda melebihi 21, Anda dinyatakan hangus dan kehilangan taruhan Anda di tangan tsb. Setelah semua giliran berlalu, bandar menunjukkan nilai kartu bawahnya. Jika tangan bandar 16 atau kurang, bandar harus memilih opsi Tambah kartu, tetapi jika tangan itu soft 17 atau lebih tinggi, bandar harus berhenti dengan memilih opsi Cukup (perhatikan bahwa soft hand berisi nilai As 11). Anda menang ketika nilai tangan Anda mendekati ke 21 daripada tangan bandar, atau ketika bandar hangus. Jika tangan Anda memiliki nilai yang sama dengan bandar, putaran permainan berakhir seri dan taruhan Anda dikembalikan. Mainkan dengan opsi Taruhan Samping, menangkan hingga '100x'.",
      },
    },
  },

  updateScene: {
    downloading: "Memuat versi terbaru...",
    installing: "Memasang...",
    installedVersion: "Versi pemasangan",
    currentVersion: "Versi saat ini",
    newVersion: "Versi terbaru",
  },

  actionTypes: {
    call: "Call",
    check: "Check",
    fold: "Fold",
    raise: "Raise",
    allin: "All-in",
  },

  tableQuickMenu: {
    standUp: "Berdiri",
    goToLobby: "Pergi ke Lobi",
    soundOn: "Suara Aktif",
    soundOff: "Suara Off",
    vibrationOn: "Getar Aktif",
    vibrationOff: "Getar Off",
    animatedGiftsOn: "Tampilkan Emoji",
    animatedGiftsOff: "Sembunyikan Emoji",
    watchWin: "Iklan",
  },
  profileSettings: {
    profileSettings: " Pengaturan Profil ",
    registrationDate: "Tanggal registrasi",
    use: "Gunakan",
    gift: "HADIAH",
    buyVip: "Beli VIP",
    onlyVip: "  Hanya anggota VIP yang dapat menggunakan hadiah ini.",
    frame: "Bingkai",
    buy: "BELI",
    statistics: {
      statistics: "Statistik",
      handPlayed: "Jumlah putaran",
      biggestPotWon: "Pot terbesar",
      biggestJackPotWon: "JackPot terbesar",
      recordChip: "Rekor Chip",
      brokenPotCounter: "Menang Jackpot",
      winPercentage: "Persentase Kemenangan",
      leadershipRanking: "Leadership Ranking",
      bestWinningHand: "Tangan Terbaik",
      biggestWin: "Menang terbesar",
    },
  },
  vip: {
    available: "VIP / {day} Hari",
    noVip: "VIP tidak tersedia",
  },
  actionPanel: {
    check: "Check",
    call: "Call",
    fold: "Fold",
    raise: "Raise",
    allin: "All-in",
    callAny: "Call Any",
    checkFold: "Check/Fold",
  },

  selectPotWindow: {
    setQuantity: "Atur modal bermain",
    autoPurchase: "Isi Ulang Otomatis",
    autoPurchaseDescription:
      "Otomatis terisi kembali ketika stack modal Anda habis.",
    autoCharging: "Tambah Otomatis",
    autoChargingDescription:
      "Otomatis bertambah ketika stack modal Anda kurang dari 70%.",
    sit: "Duduk",
    minEntry: "Min Masuk",
    maxEntry: "Maks Masuk",
  },

  quickWindow: {
    quickChat: "Ini adalah area obrolan singkat.",
    readyMessage: "Berikut adalah pesan obrolan yang sudah jadi",
    messageAim:
      "Bagian ini bertujuan untuk mengobrol lebih cepat dengan pengguna lain",
    quickEmoji: "Emoji cepat",
  },

  tipButton: {
    thankYou: "Terimakasih",
  },

  fastChatMessages: [
    "Gas..Gas..Gaasss!",
    "Selamat Bro!",
    "Maaf ya Boss..",
    "Bluff!",
    "Main cepat yuk!",
    "Segitu doang?!",
    "Kebetulan tuh!",
    "Kartu mantap, no debat!",
    "Tunggu balasan saya!",
  ],

  chatWindow: {
    tabs: {
      chat: "Obrolan",
      dealer: "Bandar",
      mute: "Senyapkan",
    },
    audiences: "Peserta",
    players: "Pemain",
    typeHere: "Tulis di sini...",
    xSecondsLeft: "{seconds} detik lagi.",
  },

  dealerChat: {
    call: "{username} melakukan call",
    check: "{username} melakukan check",
    raise: "{username} melakukan raise {amount}",
    fold: "{username} melakukan fold",
    allin: "{username} melakukan all in",
    win: "{username} menang {amount}",
    winWithCards: "{username} menang {amount} dengan {hand}",
    winWithCardIcons: "{username} menang {amount} dengan {cards} ({hand})",
  },

  systemMessages: {
    roomClosing:
      "Server akan memuat ulang dalam 2 menit. Tangan terakhir akan dimainkan.",
    teamTableAlert:
      "Ini adalah meja tim. Beberapa akun bisa duduk dan raise di meja. Tidak ada aturan (banned) dalam permainan!",
    giftSentChatMessage: "{from} mengirim {gift_name} hadiah kepada {to}.",
    inviteRequestSuccess: "Udangan telah berhasil terkirim",
  },
  blackJack: {
    hit: "Tambah",
    stand: "Cukup",
    InsufficientChips: "Chip tidak cukup",
    undo: "KEMBALI",
    double: "GANDA",
    repeat: "ULANG",
    xSecondsLeft: "{seconds} detik lagi.",
    gameHistory: "Riwayat Permainan",
    croupier: "Bandar",
    you: "Anda",
    main: "Utama",
    anyPair: "Any Pair",
    result: "Hasil",
    previousGame: "Permainan Sebelumnya",
    returnToList: "Kembali ke Daftar",
    nextGame: "Permainan Berikutnya",
    type: "Tipe",
    pay: "Bayar",
    winningsHands: "Kartu Kemenangan",
    rules: "Aturan",
    header: "TUR JUMLAH CHIPS UNTUK BERMAIN",
    otoPurchase: "Pengisian Otomatis",
    blackjactSitInfo:
      "Permainan ini dimainkan dengan melawan Bandar. Tidak ada batasan jumlah pemain yang akan melawan Bandar",
    otoPurchaseText: "Jika Anda masuk kembali, Stake akan diset ulang",
    bjRules: `
            * Tujuan Turn Blackjack adalah untuk memiliki tangan dengan poin lebih tinggi dari bandar tanpa melebihi 21; <br>
            * Tangan terbaik disebut Blackjack - nilai total dari dua kartu pertama yang dibagikan di tangan ini adalah 21;<br>
            * Anda bermain hanya melawan bandar, bukan melawan pemain lain;<br>
            * Blackjack dimainkan dengan 8 dek;<br>
            * Bandar akan selalu berhenti pada 17;<br>
            * Memungkinkan memilih penggandaan pada dua kartu awal;<br>
            * Kartu awalan yang sama nilainya dapat dibagi menjadi 2 tangan;<br>
            * Membagi kartu dapat dilakukan dengan masing-masing tangan;<br>
            * Satu kartu akan dibagikan untuk setiap As ganda yang dipecah;<br>
            * Penggandaan tidak dapat dilakukan setelah membagi (split) kartu;<br>
            * Asuransi disarankan ketika Bandar memiliki As;<br>
            * Blackjack dibayar 3:2;<br>
            * Insurance dibayar 1:2;<br>
            * Jika nilai tangan sama, maka seri.<br>

            <hr>

            <strong>Aturan Permainan</strong><br>

            * Permainan ini dikelola oleh bandar dan jumlah pemain yang tidak terbatas pada setiap tangan;<br>
            * Permainan ini disajikan oleh bandar dan dimainkan dengan 8 deck standar dari 52 kartu. Nilai 21 kartu dalam permainan adalah sebagai berikut:<br>
            - Kartu dari 2 hingga 10 memiliki nilai yg tertulis di atasnya.<br>
            - Kartu bergambar (Jack, Queen and King) bernilai 10 poin.<br>
            - As bernilai 1 atau 11, pemain dapat memilih nilai yang sesuai dengan tangannya. Perhatikan bahwa soft hand berisi 11 nilai As;<br>
            * Ketika waktu taruhan telah berakhir, bandar membagikan kartu terbuka ke semua pemain, yang terakhir untuk dirinya sendiri. Setelah itu bandar memberikan kartu kedua menghadap ke atas ke semua pemain, tetapi kartu kedua bandar akan menghadap ke bawah. Nilai tangan pertama Anda ditampilkan di sebelah kartu Anda.<br>
            * Blackjack (Dua Puluh Satu) = Jika dua kartu Anda di awal permainan berjumlah 21, Anda memiliki Blackjack!<br>
            <br>
            <strong>Asuransi</strong>
            <br>
            * Jika kartu terbuka bandar adalah As, Anda dapat membeli asuransi terhadap risiko bandar memiliki 21, bahkan jika total tangan Anda adalah 21. Jumlah asuransi akan sama dengan setengah dari taruhan utama Anda dan taruhan asuransi akan diproses secara terpisah dari taruhan tangan Anda. Setelah itu, bandar melihat nilai kartu bawahnya untuk memeriksa apakah dia menghasilkan 21.<br>
            - Jika Bandar tidak mencapai 21, permainan akan berlanjut.<br>
            - Jika Bandar memiliki Blackjack dan Anda tidak, maka permainan berakhir dan bandar menang.<br>
            - Jika Anda dan Bandar sama-sama memiliki Blackjack maka permaian berakhir dengan seri dan taruhan Anda akan dikembalikan.<br>
            <br>
            Perhatikan bahwa jika kartu Bandar yang terbuka adalah 10 atau kartu raja, Anda akan memiliki opsi untuk membeli asuransi dan Bandar tidak akan melihat kartu bawahnya untuk 21.
            <br>
            <strong>Ganda, Tambah atau Cukup</strong>
            <br>
            Jika Bandar menemukan bahwa setelah memeriksa dua kartu pertamanya dia belum membuat 21, para pemain diberi hak untuk memilih lebih banyak kartu untuk meningkatkan nilai tangan mereka, dengan memberikan bandar lebih banyak kartu kepada para pemain.
            <br>
            Jika tangan pertama Anda tidak bernilai 21, Anda berhak menggandakan taruhan. Dalam hal ini, taruhan Anda akan digandakan dan Anda hanya akan diberikan satu kartu tambahan untuk ditambahkan ke tangan Anda. Atau, Anda dapat menggunakan opsi Tambah kartu untuk mendapatkan kartu tambahan untuk menambah nilai tangan Anda. Anda dapat Menambah beberapa kartu sampai Anda puas dengan nilai tangan Anda dan memutuskan untuk Cukup.
            <br>
            Perhatikan bahwa pemain dengan soft hand 21 tidak akan diberikan opsi keputusan.
            <br>

            <strong>Bagi dua</strong><br>
            <br>
            Jika tangan pertama Anda terdiri dari dua kartu dengan peringkat yang sama, Anda dapat membagi pasangan menjadi dua tangan yang berbeda, di mana masing-masing memiliki taruhan yang sama dengan taruhan utama Anda. Setelah kedua tangan dibagikan kartu kedua, Anda dapat meningkatkan nilai kedua tangan ini dengan menerapkan opsi Tambah kartu. Anda dapat memilih opsi Cukup ketika Anda puas dengan nilai kedua tangan. Namun, ketika Anda membagi pasangan As pertama, kedua tangan secara otomatis akan menerima kartu tambahan dan tidak akan memiliki kesempatan untuk mendapatkan kartu lain.
            <br>
            <strong>Hasil</strong>
            <br>
            Jika nilai total tangan Anda melebihi 21, Anda dinyatakan hangus dan Anda akan kehilangan taruhan Anda di tangan tsb.
            <br>
            Setelah giliran semua pemain berlalu, Bandar menunjukkan nilai kartu bawahnya. Jika tangan Bandar 16 atau kurang, Bandar harus memilih opsi Tambah kartu, tetapi jika soft hand 17 atau lebih tinggi, bandar harus berhenti dengan memilih opsi Cukup (perhatikan bahwa soft hand berisi nilai As 11).
            <br>
            Anda akan menang ketika nilai tangan Anda lebih dekat dengan 21 daripada tangan Bandar, atau ketika bandar hangus. Jika tangan Anda memiliki nilai yang sama dengan bandar, putaran permainan berakhir seri dan taruhan Anda dikembalikan.
            <br>Mainkan dengan opsi Taruhan Samping, menangkan hingga "100x".`,
    settings: "Pengaturan",
    sounds: "Suara",
    bet: "TARUHAN",
    waitOtherPlayers: "Silakan tunggu pemain lain",
    placeYourBet: "Pasang taruhan Anda",
    choose: "Pilih",
    wantInsurance: "Apakah butuh asuransi?",
    draw: "SERI",
    busted: "HANGUS",
    youLost: "Anda Kalah",
    sit: "DUDUK",
    otoPurchase: "Pembelian Otomatis",
    otoPurchaseText:
      "Jika Anda masuk kembali chip akan kembali nol di atas meja",
    okay: "OK",
    insufficientBalance: "Chip tidak cukup untuk duduk.",
    ShufflingCards: "Mengocok kartu pada dek...",
    youWon: "Anda Menang",
    won: "MENANG",
    dealNow: "Bagi sekarang",
  },
  useInviteCode: {
    heading: "Kode Undangan",
    description: "Silakan masukkan kode undangan pertemanan teman Anda.",
    unset: "Hapus kode Undangan",
    inviteCodeNotFound: "Kode Undangan Salah.",
  },
  progressiveJackpot: {
    join: "Gabung",
    desc1: "Anda akan membayar chip yang sama dengan small blind per tangan.",
    desc2: "Ketika menang, Anda akan mendapatkan SMALL BLIND x  PENGGANDAAN.",
    desc3: "Chips dikumpulkan dari level taruhan yang sama.",
  },
  pinCode: {
    wrong: "Kata Sandi Salah!",
    pinCode: "KATA SANDI",
    removePinCode: "Hapus Kode Pin",
    enterPin: "Silakan masukkan Kata Sandi akun Anda",
    reset: "Atur ulang Kata Sandi saya!",
    logOut: "KELUAR",
    resetPin: "ATUR ULANG KATA SANDI",
    enterEmail: "Silakan masukkan alamat email akun Anda",
    mail: "Email",
    newPass: "Kata Sandi baru telah dikirim ke alamat email Anda",
    wrongMail: "alamat email yang Anda masukkan salah",
    failRemove: "Gagal menghapus kata sandi",
    failAdd: "Gagal menyimpan kata sandi",
  },
  redeem: {
    used: "Kartu permainan sudah digunakan!",
    expired: "Kartu permainan sudah kadaluarsa!",
    failed: "Gagal memvalidasi kartu permainan.",
    validGameCard: "Masukkan kartu permainan yang valid",
  },
  level: {
    levelUpToastText:
      "Selamat! Anda telah mencapai level {levelNumber}. <strong>Hadiah: {reward}</strong>",
  },
  inviteFriend: {
    inviteFriendText:
      "Anda telah mengundang teman Anda dan Anda berdua mendapatkan 10M!",
  },
  slotWinnerToast: {
    bigWinText: "{username} telah memenangkan {amount} di game slot!",
  },
  toast: {
    inviteRequestText: "{name} mengundang Anda untuk bermain {game}.",
  },
  userProfile: {
    noteUpdated: "Catatan profil telah diperbarui",
  },
  updatedNotes: {
    title: "Info Pembaruan",
    notes: [
      "<div class='center'><span class='title'>Poker</span></div>",
      "Suara latar tidak aktif pada pengaturan bawaan",
      "50 pemain teratas di Top Leaders",
      "Akses fitur 'Pencarian' dan 'Riwayat permainan' di meja",
      "Hanya pemain yang bermain di meja, dapat menulis di chat game",
      "Chat transparan yang atraktif ada di sebelah kiri",
      "Melakukan tindakan (Call/Fold/Check/Raise) saat chat terbuka",
      "Animasi timer yang keren!",
      "<div class='center'><span class='title'>Blackjack</span></div>",
      "Kartu dan teks yang diperbesar dengan sangat nyaman",
      "Bug telah diperbaiki!",
    ],
  },
  forceUpdateWindow: {
    title: "Versi baru telah tersedia!",
    text: "Silakan perbarui aplikasi ke versi terbaru.",
  },
  table: {
    noHaveOnlineFriends: "Tidak ada teman yang online.",
    onlineFriends: "Teman Online",
    noHaveFriends: "Tidak ada pertemanan",
  },
  others: {
    friendRemovedInfo: "Pertemanan telah dihapus",
    notFoundFriend: "Teman tidak ditemukan",
    notFoundDisplay: "Pengguna tidak ditemukan",
    friendTableNotFound: "Pengguna telah meninggalkan meja",
    tableInFriendNotFound: "Tidak ada pemain di meja",
    requiredSelectedGift: "Pilih hadiah",
    currentLocationNotAvailableBuy:
      "Pembelian tidak tersedia untuk negara Anda",
    requiredLevelFive: "Diperlukan Level 5",
    blackjackStatusInactive: "Blackjack tidak tersedia",
    slotStatusInactive: "Slot tidak tersedia",
    muteAll: "Senyapkan",
    changeNameSuccesInfo: "Nama pengguna diubah Anda memiliki sisa {0}",
    guestClose:
      "Anda tidak dapat login dengan akun pengunjung. Silakan login dengan akun lain",
  },
};
