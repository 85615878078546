import Logger from "@/logger";
import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";
import axios from "@/services/api/axios";
import adjust from "@/plugins/adjust";

import AdmobManager from "@/plugins/admob";

export default {
  /*
   *  Get one click bonus status
   */
  async getOneClickBonusStatus({ state }) {
    try {
      const response = await axios.get(`/bonus/one-click-bonus/status`);

      if (response && response.data) {
        Logger.print("info", ["getOneClickBonusStatus", response.data]);

        state.oneClickBonus.ready = Boolean(response.data.ready);
        state.oneClickBonus.lastClaimAt = response.data.lastClaimAt;

        return response.data;
      }
      return false;
    } catch (error) {
      Logger.print("error", "ONE_CLICK_BONUS error " + error);
    }
  },

  /*
   *  Claim one click bonus
   */
  async collectOneClickBonus({ state, rootState, commit }) {
    try {
      EventBus.$emit(EventConstants.ONE_CLICK_BONUS_CLAIMING);
      const response = await axios.get(`/bonus/one-click-bonus/collect`);

      Logger.print("info", ["collectOneClickBonus", response.data]);

      state.oneClickBonus.ready = Boolean(response.data.ready);

      state.oneClickBonus.lastClaimAt = response.data.lastClaimAt;
      if (response.data?.prize) {
        state.oneClickBonus.lastPrize = Number(response.data.prize);
        EventBus.$emit(EventConstants.ONE_CLICK_BONUS_CLAIMED, {
          amount: Number(response.data.prize),
        });
        rootState.animations.bonusChip = true;

        setTimeout(() => {
          state.oneClickBonus.ready = true;
        }, rootState.config.one_click_bonus_time * 1000);
      }
      adjust.trackEvent("ClickDailyBonus");
      return response.data;
    } catch (error) {
      Logger.print("error", "ONE_CLICK_BONUS error " + error);
      // EventBus.$emit(EventConstants.ONE_CLICK_BONUS_CLAIM_ERROR);
    }
  },

  /*
   *  Get ad bonus status
   */
  async getAdBonusStatus({ state }) {
    try {
      const response = await axios.get(`/bonus/ad-bonus/status`);

      Logger.print("info", ["getAdBonusStatus", response.data]);

      state.adBonus.ready = response.data.ready === true;

      state.adBonus.lastClaimAt = response.data.lastClaimAt;

      return response.data;
    } catch (error) {
      Logger.print("error", "AD_BONUS error " + error);
    }
  },

  /*
   *  Show admob reward video
   */
  async collectAdBonus({ rootState }) {
    await AdmobManager.instance.rewardVideo(rootState.user.id);
  },
  async prepareRewardVideoAd() {
    await AdmobManager.instance.prepareRewardVideoAd();
  },
};
