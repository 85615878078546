import store from "@/store";
export default {
  /*
   *   Get preference by key
   */
  isOpenModal(state) {
    const modalNames = Object.keys(state);

    const lastIsOpenModal = modalNames.some((name) => state[name] === true);
    if (store.state.scene === "PokerTable") return false;
    return lastIsOpenModal;
  },
};
