export default {
  show(state, modalName) {
    if (!modalName) return;

    state[modalName] = true;
  },

  hide(state, modalName) {
    if (!modalName) return;

    state[modalName] = false;
  },

  hideModals(state, ignore) {
    const modalKeys = Object.keys(state);
    modalKeys.forEach((modal) => {
      if (modal !== "lastOpenedModal" && !ignore.includes(modal)) {
        state[modal] = false;
      }
    });
  },
};
