<template>
  <div class="bet-area">
    <div
      class="bet-pot-area"
      :class="[isBetAvailable ? 'bets-active' : '']"
      v-if="nGetMySeat"
    >
      <button
        type="button"
        :disabled="!ableToSideBet"
        class="bet-pot-button twenty-one-plus-one"
        :class="[nGetMySeat.wonWithTypes.twentyOne > 0 ? 'x-glow' : '']"
        @click="addBet('twentyOne')"
      >
        <span
          v-if="nGetMySeat.bet.twentyOne > 0"
          class="chip"
          :class="[
            getChipColorClass(
              nGetMySeat.bet.twentyOne *
                Math.max(nGetMySeat.wonWithTypes.twentyOne, 1)
            ),
          ]"
          >{{
            getShortChipFormat(
              nGetMySeat.bet.twentyOne *
                Math.max(nGetMySeat.wonWithTypes.twentyOne, 1)
            )
          }}</span
        >
        <span class="won-with" v-if="nGetMySeat.wonWithTypes.twentyOne > 0">{{
          `${nGetMySeat.wonWithTypes.twentyOne}x`
        }}</span>
      </button>
      <button
        type="button"
        :disabled="!isBetAvailable"
        class="bet-pot-button main"
        :class="[
          nGetMySeat.bet.main <= 0 && getActiveRoomState.stage === 1
            ? 'glow-animation'
            : '',
          nGetMySeat.wonWithTypes.main > 0 ? 'x-glow' : '',
        ]"
        @click="addBet('main')"
      >
        <span class="insurance" v-show="nGetMySeat.insured"></span>
        <span
          v-if="nGetMySeat.bet.main > 0"
          class="chip"
          :class="[
            getChipColorClass(
              (nGetMySeat.bet.main + nGetMySeat.split.bet) *
                Math.max(nGetMySeat.wonWithTypes.main, 1)
            ),
          ]"
          >{{
            getShortChipFormat(
              (nGetMySeat.bet.main + nGetMySeat.split.bet) *
                Math.max(nGetMySeat.wonWithTypes.main, 1)
            )
          }}</span
        >
        <span class="won-with" v-if="nGetMySeat.wonWithTypes.main > 0">{{
          `${nGetMySeat.wonWithTypes.main}x`
        }}</span>
      </button>
      <button
        type="button"
        :disabled="!ableToSideBet"
        class="bet-pot-button any-pair"
        :class="[nGetMySeat.wonWithTypes.anyPair > 0 ? 'x-glow' : '']"
        @click="addBet('anyPair')"
      >
        <span
          v-if="nGetMySeat.bet.anyPair > 0"
          class="chip"
          :class="[
            getChipColorClass(
              nGetMySeat.bet.anyPair *
                Math.max(nGetMySeat.wonWithTypes.anyPair, 1)
            ),
          ]"
          >{{
            getShortChipFormat(
              nGetMySeat.bet.anyPair *
                Math.max(nGetMySeat.wonWithTypes.anyPair, 1)
            )
          }}</span
        >
        <span class="won-with" v-if="nGetMySeat.wonWithTypes.anyPair > 0">{{
          `${nGetMySeat.wonWithTypes.anyPair}x`
        }}</span>
      </button>
    </div>
    <div
      class="bet-amount-indicator"
      :style="{ visibility: totalBetAmount === 0 ? 'hidden' : 'visible' }"
    >
      {{ $t("blackJack.bet") }}
      <span>{{ getChipFormat(totalBetAmount) }}</span>
    </div>
  </div>
</template>

<script>
import EventBus from "@/event-bus";
import { mapState, mapGetters } from "vuex";

const MULTIPLICATIONS = [1, 2, 5, 25, 100, 500, 1000];

export default {
  name: "BetArea",
  watch: {
    isBetAvailable() {
      this.selectedBet.amount = 0;
      this.selectedBet.color = "";
    },
  },
  computed: {
    ...mapGetters(["getChipFormat", "getShortChipFormat"]),
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    isBetAvailable() {
      return this.getActiveRoomState.stage === 1;
    },
    ableToSideBet() {
      return this.isBetAvailable && this.nGetMySeat.bet.main > 0;
    },
    totalBetAmount() {
      if (!this.nGetMySeat) return 0;
      let total = 0;
      for (const key in this.nGetMySeat.bet) {
        total += this.nGetMySeat.bet[key];
      }
      total += this.nGetMySeat.split.bet;
      return total;
    },
  },
  data() {
    return {
      selectedBet: {
        amount: 0,
        color: "",
      },
    };
  },
  methods: {
    getChipColorClass(amount) {
      let className = "";

      let multiplier = "";
      for (const item of MULTIPLICATIONS) {
        if (amount >= this.getActiveRoomState.minBuy * item) {
          multiplier = item;
        }
      }

      switch (multiplier) {
        case 1:
          className = "red";
          break;
        case 2:
          className = "purple";
          break;
        case 5:
          className = "green";
          break;
        case 25:
          className = "turquoise";
          break;
        case 100:
          className = "blue";
          break;
        case 500:
          className = "grey";
          break;
        case 1000:
          className = "black";
          break;
      }
      return className;
    },
    onBetSelected(data) {
      this.selectedBet.amount = data.amount;
      this.selectedBet.color = data.color ?? "";
    },
    addBet(type = "main") {
      if (this.nGetMySeat.stack < this.selectedBet.amount)
        return this.$plugins.toast.show({
          text: this.$t("blackJack.InsufficientChips"),
          variant: "danger",
        });

      this.$plugins.audio.play("BjBet");
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("bet", {
        amount: this.selectedBet.amount,
        type,
      });
    },
  },
  beforeDestroy() {
    EventBus.$off("onBetSelected", this.onBetSelected.bind(this));
  },
  created() {
    EventBus.$on("onBetSelected", this.onBetSelected.bind(this));
  },
};
</script>

<style scoped lang="scss">
@-webkit-keyframes chipIncomingAnimation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes chipIncomingAnimation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes wonWithIncomingAnimation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes wonWithIncomingAnimation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes mainBetGlowAnimation {
  0% {
    box-shadow: 0 0 0 0 rgba(rgb(255, 255, 255), 0.5);
  }
  50% {
    box-shadow: 0 0 20px 0 rgba(rgb(255, 255, 255), 0.25);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(rgb(255, 255, 255), 0.5);
  }
}

.bet-area {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  perspective: 1000px;
  -webkit-perspective: 1000px;
  padding-bottom: 20px;
  .bet-pot-area {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform: rotateX(35deg);
    transition: transform 0.3s linear;
    .bet-pot-button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      width: 85px;
      height: 85px;
      box-sizing: border-box;
      border: 3px solid rgba(rgb(255, 255, 255), 0.3);
      background-color: transparent;
      border-radius: 100%;
      margin-right: 10px;
      cursor: pointer;
      background-image: url("~@/assets/images/blackjack/icons/main-bet-icon.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
      transition: all 0.25s ease;
      &:last-child {
        margin-right: 0;
      }
      &:not(:disabled):hover {
        border-color: rgb(255, 255, 255);
      }

      &.main {
        &.glow-animation {
          animation-name: mainBetGlowAnimation;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
      &.twenty-one-plus-one {
        background-image: url("~@/assets/images/blackjack/icons/21+3.png");
        background-size: 70%;
      }
      &.any-pair {
        background-image: url("~@/assets/images/blackjack/icons/perfect-pairs.png");
        background-size: 70%;
      }

      &.x-glow {
        box-shadow: 0 0 10px 0 rgb(255, 255, 255);
      }

      .insurance {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 32px;
        height: 32px;
        background-image: url("~@/assets/images/blackjack/icons/insurance-active.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
        z-index: 5;
      }

      .chip {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 68px;
        background-image: url("~@/assets/images/blackjack/chips/chip-red.png");
        background-position: center;
        background-repeat: no-repeat;
        font-size: 15px;
        font-weight: bold;
        text-shadow: 0 1px 0 black;
        color: rgb(255, 255, 255);
        border-radius: 100%;
        box-shadow: 0 3px 0 0 black;
        animation-name: chipIncomingAnimation;
        animation-timing-function: linear;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        transition: all 0.25s ease;
        &.blue {
          background-image: url("~@/assets/images/blackjack/chips/chip-blue.png");
        }
        &.green {
          background-image: url("~@/assets/images/blackjack/chips/chip-green.png");
        }
        &.purple {
          background-image: url("~@/assets/images/blackjack/chips/chip-purple.png");
        }
        &.turquoise {
          background-image: url("~@/assets/images/blackjack/chips/chip-turquoise.png");
        }
        &.grey {
          background-image: url("~@/assets/images/blackjack/chips/chip-grey.png");
        }
        &.black {
          background-image: url("~@/assets/images/blackjack/chips/chip-black.png");
        }
      }

      .won-with {
        position: absolute;
        bottom: -30px;
        color: rgb(255, 255, 255);
        font-weight: bold;
        font-size: 18px;
        text-shadow: 0 2px 0 rgba(black, 0.8), 0 1px 10px $yellowColor;
        text-transform: uppercase;
        animation-name: wonWithIncomingAnimation;
        animation-timing-function: linear;
        animation-duration: 0.25s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }
    &.bets-active {
      perspective: unset;
      transform: scale(1.05) rotateX(0);
      margin-bottom: 70px;
      .bet-pot-button.main {
        width: 100px;
        height: 100px;
      }
    }
  }
  .bet-amount-indicator {
    font-weight: bold;
    font-size: 30px;
    padding: 5px 15px;
    min-width: 200px;
    text-align: center;
    border-radius: 10px;
    background-color: rgba(black, 0.5);

    span {
      color: $yellowColor;
    }
  }
}

.platform-mobile {
  .bet-area {
    transform: translateY(-10px);
  }
}
</style>
