<template>
  <BaseWindow
    @closeWindow="closeWindow"
    :show="$store.state.modals.social"
    :titleText="$t('general.social')"
    :stopClickOutside="true"
  >
    <template>
      <div class="social-window">
        <div class="tab-selectors">
          <div
            class="selector"
            @click="handleTabs('game-card')"
            :class="
              currentTab === 'game-card' ? 'profile-sprite tab1 active' : ''
            "
          >
            <span :class="currentTab === 'game-card' ? 'text-active' : ''">{{
              $t("socialWindow.gameCard")
            }}</span>
            <span
              class="tab-line"
              :class="
                currentTab === 'game-card' ? 'profile-sprite tabline' : ''
              "
            ></span>
            <span v-if="currentTab !== 'social'" class="right-line"></span>
          </div>
          <div
            class="selector"
            @click="handleTabs('social')"
            :class="currentTab === 'social' ? 'profile-sprite tab2' : ''"
          >
            <span
              class="social-media"
              :class="currentTab === 'social' ? 'text-active' : ''"
              >{{ $t("socialWindow.socialMedia") }}</span
            >
            <span
              class="tab-line"
              :class="currentTab === 'social' ? 'profile-sprite tabline' : ''"
            ></span>
            <span v-if="currentTab !== 'inviteCode'" class="right-line"></span>
          </div>
          <div
            class="selector"
            @click="handleTabs('inviteCode')"
            :class="currentTab === 'inviteCode' ? 'profile-sprite tab2' : ''"
          >
            <span
              class="invite-code"
              :class="currentTab === 'inviteCode' ? 'text-active' : ''"
              >{{ $t("socialWindow.inviteCode") }}</span
            >
            <span
              class="tab-line"
              :class="
                currentTab === 'inviteCode' ? 'profile-sprite tabline' : ''
              "
            ></span>
            <span v-if="currentTab !== 'reedem'" class="right-line"></span>
          </div>
        </div>
        <div class="content-section">
          <div class="inner-content social" v-if="currentTab === 'social'">
            <div class="left-bg"></div>
            <div class="right-bg"></div>
            <div class="social-icons">
              <a
                href="https://www.facebook.com/TurnTexasHoldEmPoker"
                target="_blank"
                rel="noopener noreferrer"
                @click="facebookClick"
                ><div class="sprite-profile-popup facebook-icon"></div
              ></a>
              <a
                href="https://www.instagram.com/turnpokerofficial/"
                target="_blank"
                rel="noopener noreferrer"
                @click="instagramClick"
                ><div class="sprite-profile-popup instagram-icon"></div
              ></a>
              <a
                href="https://www.twitter.com/turnpokerid/"
                target="_blank"
                rel="noopener noreferrer"
                @click="twitterClick"
                ><div class="sprite-profile-popup twitter-icon"></div
              ></a>

              <a
                href="https://www.youtube.com/channel/UCE7OU-q5N1ghd2BnC9Uaegw"
                target="_blank"
                rel="noopener noreferrer"
                @click="youtubeClick"
                ><div class="sprite-profile-popup youtube-icon"></div
              ></a>
              <a
                href="https://t.me/TurnPokerCasinoSlots"
                target="_blank"
                rel="noopener noreferrer"
                @click="telegramClick"
                ><div class="sprite-profile-popup telegram-icon"></div
              ></a>
            </div>
            <div class="text">
              <p>
                {{ $t("socialWindow.extraChip") }} <br />
                <!-- {{ $t("socialWindow.followUs") }} -->
              </p>
            </div>
          </div>
          <div
            class="inner-content inviteCode"
            v-if="currentTab === 'inviteCode'"
          >
            <div class="container">
              <div class="invite-container">
                <div class="title">
                  <span>{{ $t("socialWindow.sharecode") }} </span>
                  <span class="sprite-table-pop-up red-line"></span>
                </div>
                <div class="invite-icon"></div>
                <input type="text" :value="user.inviteCode" readonly />
                <div class="orange-content" @click="clipBoardInviteCode">
                  <div class="profile-sprite copy-icon"></div>
                  <div class="tooltip" v-if="copyInfoShow">
                    <span
                      class="tooltiptext tooltip-top"
                      :style="{
                        visibility: `${copyInfoShow ? 'visible' : 'hidden'}`,
                      }"
                      >{{ $t("playerDetailsWindow.copied") }}</span
                    >
                  </div>
                </div>
                <div class="description-row">
                  <div
                    class="invite-code"
                    v-html="$t('socialWindow.inviteCodeDescription')"
                  ></div>
                </div>
              </div>
              <div class="redeem-container">
                <div class="title">
                  <span>{{ $t("shopWindow.redeem") }}</span>
                  <span class="sprite-table-pop-up red-line"></span>
                </div>
                <div class="redeem-mid">
                  <input
                    type="text"
                    class="redeem-input"
                    :placeholder="$t('useInviteCode.description')"
                    :maxlength="50"
                    v-model="code"
                  />
                  <div class="redeem-button">
                    <div
                      class="ui-button ui-button-small-orange"
                      @click="onOkClicked"
                    >
                      {{ $t("shopWindow.redeem") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="inner-content game-card"
            v-if="currentTab === 'game-card'"
          >
            <div class="game-card-container">
              <div class="game-card-ticket"></div>
              <div class="game-card-text">
                {{ $t("socialWindow.gameCardDesc") }}
              </div>

              <div class="redeem-mid">
                <input
                  type="text"
                  class="redeem-input"
                  v-model.trim="enteredRedeemCode"
                  :placeholder="$t('shopWindow.keysField')"
                />
                <div class="redeem-button" v-if="reedemActive">
                  <div
                    class="ui-button ui-button-small-orange"
                    @click="redeemCode"
                  >
                    {{ $t("shopWindow.redeem") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { Share } from "@capacitor/share";

import BaseWindow from "./BaseWindow";

import Helpers from "@/mixin/Helpers";

export default {
  name: "SocialWindow",
  components: {
    BaseWindow,
  },
  mixins: [Helpers],
  data() {
    return {
      searchText: null,
      loading: false,
      currentTab: "game-card",
      enteredRedeemCode: "",
      reedemActive: true,
      code: null,
      copyInfoShow: false,
    };
  },
  watch: {
    copyInfoShow: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.copyInfoShow = false;
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["getRealPlatform"]),
    ...mapState(["user"]),
    async canShare() {
      const canShare = await Share.canShare();
      return canShare?.value;
    },
  },
  methods: {
    facebookClick() {
      this.$adjust.trackEvent("SocialWindowFacebookClick");
    },
    instagramClick() {
      this.$adjust.trackEvent("SocialWindowInstagramClick");
    },
    twitterClick() {
      this.$adjust.trackEvent("SocialWindowTwitterClick");
    },
    youtubeClick() {
      this.$adjust.trackEvent("SocialWindowYoutubeClick");
    },
    telegramClick() {
      this.$adjust.trackEvent("SocialWindowTelegramClick");
    },
    async clipBoardInviteCode() {
      await this.$plugins.clipboard.write({
        type: "string",
        value: String(this.user.inviteCode),
      });
      this.copyInfoShow = true;

      this.$adjust.trackEvent("MyInviteCodeCopy");
    },
    async copy() {
      return this.$plugins.clipboard.write({
        type: "string",
        value: String(this.user.inviteCode),
      });
    },
    async shareCode() {
      await Share.share({
        title: this.$t("socialWindow.shareWithFriends"),
        text: this.$t("socialWindow.shareWithFriendsCode").replace(
          "{code}",
          this.user.inviteCode
        ),
        url: "http://turngs.com/play-now",
        dialogTitle: this.$t("socialWindow.shareInviteCodeWithFriends"),
      });
    },
    closeWindow() {
      this.$store.commit("modals/hide", "social");
    },
    async openUserWindow(id) {
      this.loading = true;
      this.$store.state.modals.lastOpenedModal = "social";
      await this.$store.dispatch("modals/openUserDetailWindow", id);
      this.closeWindow();
      this.loading = false;
    },
    handleTabs(tab) {
      this.$data.currentTab = tab;
    },
    async redeemCode() {
      if (!this.enteredRedeemCode) {
        return this.$plugins.toast.show({
          text: this.$t("redeem.validGameCard"),
          duration: 3000,
          variant: "danger",
        });
      }
      this.reedemActive = false;
      const res = await this.$store.dispatch(
        "products/redeemCode",
        this.enteredRedeemCode
      );
      setTimeout(() => {
        this.reedemActive = true;
      }, 5000);
      if (res) {
        this.enteredRedeemCode = "";
        this.$plugins.toast.show({
          text: this.$t("shopWindow.chipAdded"),
          duration: 3000,
        });

        this.$adjust.trackEvent("RedeemCodeSuccess");
      }
      // else {
      //   this.$plugins.toast.show({
      //     text: this.$t("redeem.validGameCard"),
      //     duration: 3000,
      //     variant: "danger",
      //   });
      // }
    },
    async onOkClicked() {
      this.$store.commit("authentication/setInviteCode", {
        code: this.$data.code,
      });
    },
  },
  mounted() {
    // setTimeout(() => {
    //   const searchInput = document.querySelector(".search-input");
    //   if (searchInput) searchInput.focus();
    // }, 1250);
  },
};
</script>

<style lang="scss">
.social-window {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tab-selectors {
    width: 99%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 5;
    text-transform: uppercase;
    .selector {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 271px;
      height: 99px;
      cursor: pointer;
      color: #cf707f;
      font-size: 35px;
      font-weight: bold;
      text-transform: uppercase;
      text-shadow: 3px 3px 3px black;
      .social-media {
        left: 45px !important;
      }
      .invite-code {
        left: 53px !important;
      }
      .right-line {
        position: absolute;
        height: 50%;
        width: 2px;
        background: #902533;
        right: 0;
      }
      :first-child {
        .text-active {
          position: absolute;
          left: 45px;
          color: white;
        }
      }
      :second-child() {
        .text-active {
          position: absolute;
          left: 45px;
          color: white;
        }
      }
      .text-active {
        position: absolute;
        color: white;
      }
      .tab-line {
        position: absolute;
        bottom: 0;
        transform: scale(0.7);
        left: 17px;
      }
    }
  }

  .content-section {
    width: 99%;
    height: 87%;
    background: #83212d;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    .inner-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: relative;
      overflow: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      &.inviteCode {
        display: flex;
        justify-content: center;
        align-items: center;
        .container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 90%;
          .invite-container {
            position: relative;
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-right: 2px solid #3b0b14;
            .title {
              font-size: 45px;
              height: 70px;
              position: absolute;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: column;
              top: 20px;
              text-shadow: 0px 3px 2px black;
            }
            .invite-icon {
              height: 100px;
              width: 100px;
              background: url("~@/assets/images/lobby/invite.png");
              background-repeat: no-repeat;
              background-size: contain;
              margin-bottom: 50px;
            }
            input {
              width: 400px;
              height: 90px;
              background: #3b0b14;
              border: none;
              border-radius: 10px;
              box-shadow: inset 1px 3px 8px black;
              color: white;
              font-size: 40px;
              text-align: center;
            }
            .description-row {
              height: 24%;
              text-align: center;
              font-size: 36px;
              line-height: 30px;
              display: flex;
              justify-content: center;
              align-items: flex-end;
              .invite-code {
                position: relative;
                top: 130px;
                line-height: 44px;
              }
            }
            .orange-content {
              position: absolute;
              top: 346px;
              left: 600px;
              height: 55px;
              width: 55px;
              border-radius: 23px;
              background: rgb(127, 30, 29);
              background: linear-gradient(0deg, #f49648 0%, #f17e32 100%);
              border: solid 1px #1e0809;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              flex-shrink: 0;
              .tooltip {
                position: relative;
                display: inline-block;
                border-bottom: 1px dotted black;
                bottom: 70px;
                right: 110px;
                .tooltiptext {
                  width: 200px;
                  height: 40px;
                  background-color: black;
                  color: #fff;
                  text-align: center;
                  padding: 5px 0;
                  border-radius: 10px;
                  position: absolute;
                  font-size: 25px;
                  z-index: 1;
                }
                .tooltiptext::after {
                  content: " ";
                  position: absolute;
                  top: 100%; /* At the bottom of the tooltip */
                  left: 50%;
                  margin-left: -5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: black transparent transparent transparent;
                }
              }
            }
          }
          .redeem-container {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .title {
              font-size: 45px;
              height: 70px;
              position: absolute;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: column;
              top: 60px;
              text-shadow: 0px 3px 2px black;
            }
            .redeem-mid {
              width: 100%;
              height: 25%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              input {
                width: 80%;
                height: 95px;
                font-size: 36px;
                font-weight: bold;
                border-radius: 50px;
                background: #55121b;
                box-shadow: inset 1px 3px 8px black;
                border: none;
                color: rgba(rgb(255, 255, 255), 1);
                padding-left: 20px;
                display: flex;
                box-shadow: inset 1px 3px 8px black;
                align-items: center;
                justify-content: center;
                &::placeholder {
                  font-size: 36px;
                  color: rgba(rgb(255, 255, 255), 0.8);
                  font-weight: normal;
                  padding: 0px 20px;
                }
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
              }
              .redeem-button {
                display: flex;
                width: 100%;
                height: 20%;
                justify-content: center;
                align-items: center;

                button {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
      &.social {
        position: relative;
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        .left-bg {
          position: absolute;
          left: -10px;
          top: 0;
          width: 350px;
          height: 500px;
          background-image: url("~@/assets/images/lobby/background-image-left.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
        .right-bg {
          position: absolute;
          right: 0px;
          bottom: 0px;
          width: 350px;
          height: 500px;
          background-image: url("~@/assets/images/lobby/background-image-right.png");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .social-icons {
          width: 60%;
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .text {
          height: 20%;
          font-size: 45px;
          line-height: 50px;
          text-align: center;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
      &.game-card {
        .game-card-container {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .game-card-ticket {
            height: 200px;
            width: 200px;
            background: url("~@/assets/images/lobby/ticket2.png");
            background-repeat: no-repeat;
            background-size: contain;
          }
          .game-card-text {
            font-size: 40px;
            margin-bottom: 100px;
          }
          .redeem-mid {
            width: 50%;
            height: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            input {
              width: 80%;
              height: 95px;
              font-size: 36px;
              font-weight: bold;
              border-radius: 50px;
              background: #55121b;
              box-shadow: inset 1px 3px 8px black;
              border: none;
              color: rgba(rgb(255, 255, 255), 1);
              padding-left: 20px;
              display: flex;
              box-shadow: inset 1px 3px 8px black;
              align-items: center;
              justify-content: center;
              &::placeholder {
                font-size: 36px;
                color: rgba(rgb(255, 255, 255), 0.8);
                font-weight: normal;
                padding: 0px 20px;
              }
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
            .redeem-button {
              display: flex;
              width: 100%;
              height: 20%;
              justify-content: center;
              align-items: center;

              button {
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }
}
</style>
