var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"window-outer",class:[_vm.getRealPlatform === 'android' ? 'no-flick' : '', _vm.globalClassName],style:(_vm.outerStyle)},[_c('div',{staticClass:"window-outer-bg",class:_vm.outerWindowClass,style:({
      width: _vm.width + 'px',
      height: _vm.height + 'px',
      left: `${_vm.outerLeftPx ? _vm.outerLeftPx + 'px' : ''}`,
    })},[(_vm.$props.showCloseButton)?_c('div',{staticClass:"close-container",on:{"click":_vm.emitCloseWindow}},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"window-inner-bg",style:({
        background: _vm.$props.innerBackground,
        width: _vm.width + 'px',
        height: _vm.height + 'px',
        borderRadius: _vm.$props.borderRadius,
      })},[(_vm.titleText)?_c('div',{staticClass:"window-title",class:_vm.titleClasses,style:({
          ..._vm.outerTitleStyle,
          height: _vm.width == 1920 && _vm.height == 1080 ? '8%' : '13%',
        })},[_vm._t("title"),_c('span',{staticClass:"title-text"},[_vm._v(" "+_vm._s(_vm.titleText)+" ")]),_c('div',{staticClass:"profile-sprite light"})],2):_vm._e(),_vm._t("default"),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"loading-spinner"},[_c('Spinner',{attrs:{"size":'50px'}})],1)],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"close"},[_c('span',{staticClass:"sprite-table-pop-up exit-icon"})])
}]

export { render, staticRenderFns }