<template>
  <div class="table" :class="getClasses">
    <div class="table-referans"></div>
    <DealerCharacter />

    <div class="table-abs">
      <div class="table-abs-inner">
        <Seats />
        <Pots />
        <Cards />
        <HandStrengthIndicator
          :winner="handStrengthIndicator.winner"
          :amount="handStrengthIndicator.amount"
          @onHandShowing="onHandShowing"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/event-bus";
import { mapGetters } from "vuex";
import HandStrengthIndicator from "./HandStrengthIndicator";
import DealerCharacter from "./DealerCharacter";
import Seats from "./Seats";
import Pots from "./Pots";
import Cards from "./Cards";

export default {
  name: "Table",
  components: {
    DealerCharacter,
    HandStrengthIndicator,
    Seats,
    Pots,
    Cards,
  },
  computed: {
    ...mapGetters({
      getPreferenceValue: "preferences/getValue",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    getClasses() {
      const classes = [];

      const tableColor = this.getPreferenceValue("table_background");
      classes.push(`table-color-${tableColor}`);

      const tableStyle = this.getPreferenceValue("table_style");
      classes.push(`table-style-${tableStyle}`);

      return classes;
    },
  },
  watch: {
    "getActiveRoomState.stage": function (stage) {
      if (
        stage === 1 &&
        this.$store.getters["service/getActiveRoomState"].players.filter(
          (p) => p.position > -1
        ).length >= 2
      ) {
        this.$store.commit("service/setActiveRoomWinnerCards", []);
      }
    },
    "getActiveRoomState.currentTurn": function (currentTurn) {
      if (currentTurn === this.$store.getters["service/nGetMySeat"]?.position)
        this.$plugins.audio.play("TurnStart");
    },
    "getActiveRoomState.winners": function (winners, oldWinners) {
      if (winners.length === 0) return false;
      if (winners.length === oldWinners.length) return false;
      EventBus.$emit("onWinnerReceived", winners);
    },
  },
  data() {
    return {
      handStrengthIndicator: {
        winner: -1,
        amount: 0,
        _timeout: 0,
      },
    };
  },
  methods: {
    onHandShowing({ winnerCards }) {
      this.$store.commit("service/setActiveRoomWinnerCards", []);
      this.$store.commit("service/setActiveRoomWinnerCards", winnerCards);
    },
    onWinnerSeatAnimationTriggered({ position, amount }) {
      this.handStrengthIndicator.winner = position;
      this.handStrengthIndicator.amount = amount;

      if (this.handStrengthIndicator._timeout)
        clearTimeout(this.handStrengthIndicator._timeout);

      this.handStrengthIndicator._timeout = setTimeout(() => {
        this.handStrengthIndicator.winner = -1;
        this.handStrengthIndicator.amount = 0;
      }, 2000);
    },
  },
  beforeDestroy() {
    EventBus.$off(
      "onWinnerSeatAnimationTriggered",
      this.onWinnerSeatAnimationTriggered.bind(this)
    );
  },
  created() {
    EventBus.$on(
      "onWinnerSeatAnimationTriggered",
      this.onWinnerSeatAnimationTriggered.bind(this)
    );
  },
};
</script>

<style scoped lang="scss">
.table {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/images/poker/tables/table-green.png");
  background-repeat: no-repeat;
  &.table-color-green {
    background-image: url("~@/assets/images/poker/tables/table-green.png");
  }
  &.table-color-red {
    background-image: url("~@/assets/images/poker/tables/table-red.png");
  }
  &.table-color-blue {
    background-image: url("~@/assets/images/poker/tables/table-blue.png");
  }
  &.table-color-purple {
    background-image: url("~@/assets/images/poker/tables/table-purple.png");
  }
  &-abs {
    width: 1360px;
    height: 590px;
    position: absolute;
    top: 220px;
    left: 280px;
    opacity: 1;
  }
  // .table-referans {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url("~@/assets/images/auto.jpg");
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   opacity: 0;
  // }
  &-abs-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
</style>
