<template>
  <div class="pots">
    <Pot
      v-for="(tablePot, tablePotIndex) in getActiveRoomState.pots"
      :key="'table-pot-' + tablePotIndex"
      :index="tablePotIndex"
      :realIndex="tablePotIndex"
      :data="tablePot"
      type="table"
    />
    <Pot
      v-for="(seatPot, seatPotIndex) in getSeatsPots"
      :key="'seat-pot-' + seatPotIndex"
      :index="getComputedIndex(seatPot.position)"
      :realIndex="seatPot.position"
      :data="seatPot"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pot from "./Pot";

export default {
  name: "Pots",
  components: { Pot },
  data() {
    return {
      dummySeatPots: [
        {
          position: 0,
          amount: 2000,
          eligiblePlayers: [],
          winningPlayers: [],
          start: false,
        },
        {
          position: 1,
          amount: 1000,
          eligiblePlayers: [],
          winningPlayers: [],
          start: false,
        },
        {
          position: 2,
          amount: 1000,
          eligiblePlayers: [],
          winningPlayers: [],
          start: false,
        },
        {
          position: 3,
          amount: 1000,
          eligiblePlayers: [],
          winningPlayers: [],
          start: false,
        },
        {
          position: 4,
          amount: 1000,
          eligiblePlayers: [],
          winningPlayers: [],
          start: false,
        },
        {
          position: 5,
          amount: 1000,
          eligiblePlayers: [],
          winningPlayers: [],
          start: false,
        },
        {
          position: 6,
          amount: 1000,
          eligiblePlayers: [],
          winningPlayers: [],
          start: false,
        },
        {
          position: 7,
          amount: 1000,
          eligiblePlayers: [],
          winningPlayers: [],
          start: false,
        },
        {
          position: 8,
          amount: 1000,
          eligiblePlayers: [],
          winningPlayers: [],
          start: false,
        },
      ],
      dummyTablePots: [
        {
          position: 0,
          amount: 2000,
          eligiblePlayers: [],
          winningPlayers: [],
          start: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
      nGetSeats: "service/nGetSeats",
      nGetMySeat: "service/nGetMySeat",
      nAmISitting: "service/nAmISitting",
    }),
    getSeatsPots() {
      const seats = [...this.nGetSeats]
        .filter((s) => s.id > 0 && s.position > -1)
        .sort((a, b) => a.position - b.position);

      return seats.map((seat) => ({
        position: seat.position,
        amount: seat.bet,
        eligiblePlayers: [],
        winningPlayers: [],
      }));
    },
  },
  methods: {
    getComputedIndex(index) {
      if (!this.nAmISitting) return index;

      const shiftValue = 7 - this.nGetMySeat.position;

      let ret = (index + shiftValue) % 9;

      if (ret < 0) return 8;

      return ret;
    },
  },
};
</script>

<style scoped lang="scss">
.pots {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &-inner {
    position: relative;
  }
}
</style>
