import axios from '@/services/api/axios'
import toast from '@/plugins/toast'
import i18n from "@/plugins/i18n"

export default {
    async addPinCode(store, data) {
        try {
            const response = await axios.post(`/pin-code/add`, data);
            if(response.data) {
             return true
            }
        } catch(error) {
            toast.instance.show({
                text: i18n.t("pinCode.failAdd"),
                duration: 3000,
                variant: 'danger'
            })
            return false;
        }
    },
    
    async removePinCode() {
        try {
            await axios.get(`/pin-code/remove`);
            return true
        } catch(error) {
            toast.instance.show({
                text: i18n.t("pin.failRemove"),
                duration: 3000,
                variant: 'danger'
            })
            return false;
        }
    }, 

    async resetPinCode(store, email) {
        try {
            const res = await axios.post(`/pin-code/forgot-password`, {
                email: email
            });
            
            return toast.instance.show({
                text: i18n.t("pinCode.newPass"),
                duration: 3000,
                variant: 'success'
            })
        } catch(error) {
            toast.instance.show({
                text: i18n.t("pin.wrongMail"),
                duration: 3000,
                variant: 'danger'
            })
            return false;
        }
    }

}
