<template>
  <div class="dealer-side">
    <HandStrength
      :value="getDealerHandStrength"
      :twoState="getDealerHandStrengthTS"
      :showWonLoseIndicator="false"
    />
    <Cards />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import HandStrength from "./HandStrength";
import Cards from "./DealerSide/Cards";

export default {
  name: "DealerSide",
  components: {
    HandStrength,
    Cards,
  },
  computed: {
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
    }),
    getDealerHandStrength() {
      return this.getActiveRoomState.dealer.handStrength;
    },
    getDealerHandStrengthTS() {
      return this.getActiveRoomState.dealer.handStrengthTS;
    },
  },
};
</script>

<style scoped lang="scss">
.dealer-side {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
}

.platform-mobile {
  .dealer-side {
    transform: scale(1.25);
  }
}
</style>
