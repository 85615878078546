<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="800"
    height="600"
    :show="show"
    :outerStyle="outerStyle"
    :loading="loading"
    preventClickOutside
    :titleText="$t('message.sendMessage')"
  >
    <div class="container">
      <textarea
        type="text"
        v-model="message"
        :placeholder="$t('message.typeMessage')"
        maxlength="255"
      />
      <div class="buttons">
        <button class="ui-button ui-button-small-red" @click="closeWindow">
          {{ $t("general.cancel") }}
        </button>
        <button
          class="ui-button ui-button-small-green confirm-button"
          @click="send"
        >
          {{ $t("general.send") }}
        </button>
      </div>
    </div>
  </BaseWindow>
</template>

<script>
import BaseWindow from "../BaseWindow";

export default {
  name: "SendMessage",
  data() {
    return {
      message: "",
      loading: false,
    };
  },
  components: {
    BaseWindow,
  },
  props: {
    outerStyle: {
      type: [Object, String],
      default: "",
    },
    show: {
      type: Boolean,
      required: true,
    },
    receiverId: {
      type: [Number],
      default: -1,
    },
  },
  methods: {
    async send() {
      if (this.receiverId < 0) {
        this.$plugins.toast.show({
          text: this.$t("others.notFoundDisplay"),
          duration: 2500,
          variant: "danger",
        });
        return;
      }
      if (this.message !== "") {
        this.loading = true;
        const res = await this.$store.dispatch("chat/sendDirectMessage", {
          receiverId: this.$props.receiverId,
          message: this.$data.message,
        });
        this.loading = false;
        if (!res) {
          return this.$plugins.toast.show({
            text: this.$t("inboxContact.failedMessage"),
            duration: 2500,
            variant: "danger",
          });
        }
        this.$plugins.toast.show({
          text: this.$t("inboxContact.successfullyMessage"),
          duration: 2500,
          variant: "success",
        });
        this.message = "";
        this.closeWindow();
      }
    },
    closeWindow() {
      this.$emit("closeWindowSendMessage");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 97%;
  height: 85%;
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: #83212d;
  border-radius: 20px;
  .heading {
    font-size: 55px;
    line-height: 75px;
    text-transform: uppercase;
    margin-top: 30px;
    color: #fff;
    background: #fff;
    text-shadow: 3px 3px 3px black;
    background-clip: text;
    z-index: 1;
    -webkit-background-clip: text;
  }
  .title-bg {
    position: absolute;
  }
  textarea {
    width: 600px;
    height: 200px;
    background: #55121b;
    font-size: 45px;
    text-align: left;
    border-radius: 45px;
    padding: 30px;
    color: rgb(255, 255, 255);
    box-shadow: inset 1px 3px 8px black;
    overflow-y: scroll;
    border: none;
    z-index: 3;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  textarea::-webkit-input-placeholder {
    color: #fff;
  }

  textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
  }

  textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
  }

  textarea:-ms-input-placeholder {
    color: #fff;
  }

  textarea::placeholder {
    color: #fff;
  }
  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;
    color: #fff !important;
    .confirm-button {
      color: #fff !important;
    }
    .ui-button-small-red {
      color: #fff !important;
    }
  }
}
</style>
