<template>
  <BaseWindow
    @closeWindow="closeWindow"
    :show="true"
    :titleText="$t('settingsWindow.security')"
  >
    <div class="security">
      <div class="security-content" v-if="!user.isPinCodeActive">
        <div class="security-content-section">
          <span class="security-content-section-title">{{
            $t("settingsWindow.pinCode.pinCode")
          }}</span>
          <input
            class="security-content-section-input"
            autocomplete="password"
            :placeholder="$t('settingsWindow.pinCode.createPinDesc')"
            type="password"
            v-model="password"
          />
        </div>
        <div class="security-content-section">
          <span class="security-content-section-title">{{
            $t("settingsWindow.pinCode.pinCode")
          }}</span>
          <input
            class="security-content-section-input"
            :placeholder="$t('settingsWindow.pinCode.reEnterPin')"
            autocomplete="new-password"
            type="password"
            v-model="rePassword"
          />
        </div>
        <div class="security-content-section">
          <span class="security-content-section-title">{{
            $t("settingsWindow.pinCode.emailAddress")
          }}</span>
          <input
            class="security-content-section-input"
            :placeholder="$t('settingsWindow.pinCode.emailRequired')"
            type="email"
            v-model.trim="email"
            @keyup.enter="save"
          />
        </div>
        <p class="security-content-info">
          {{ $t("settingsWindow.pinCode.pinDesc") }}
        </p>
        <div class="ui-button ui-button-small-orange" @click="save">
          {{ $t("general.save") }}
        </div>
      </div>
      <div class="security-content" v-if="user.isPinCodeActive">
        <div class="remove-container">
          <div class="desc">
            {{ $t("settingsWindow.pinCode.areYouSure") }}
          </div>
          <div class="ui-button ui-button-red" @click="removePinCode">
            {{ $t("pinCode.removePinCode") }}
          </div>
        </div>
      </div>
    </div>
  </BaseWindow>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "CaseWindow",
  components: {
    Avatar: () => import("@/components/Misc/Avatar"),
    BaseWindow: () => import("../BaseWindow"),
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["getChipFormat", "getShortChipFormat", "getRealPlatform"]),
  },
  data() {
    return {
      password: null,
      rePassword: null,
      email: null,
    };
  },
  methods: {
    save() {
      if (!this.password || !this.rePassword || !this.email) {
        return this.$plugins.toast.show({
          text: this.$t("playerDetailsWindow.fillFields"),
          duration: 2500,
          variant: "danger",
        });
      } else if (this.validateEmail(this.$data.email) == null) {
        return this.$plugins.toast.show({
          text: this.$t("inboxContact.validEmail"),
          duration: 2500,
          variant: "danger",
        });
      } else if (this.password !== this.rePassword) {
        return this.$plugins.toast.show({
          text: this.$t("playerDetailsWindow.passwordsDoesNotMatch"),
          duration: 2500,
          variant: "danger",
        });
      } else if (this.password.length < 6 || this.password.length > 30) {
        return this.$plugins.toast.show({
          text: this.$t("playerDetailsWindow.passwordLength"),
          duration: 2500,
          variant: "danger",
        });
      }
      const res = this.$store.dispatch("pin/addPinCode", {
        email: this.$data.email,
        password: this.$data.password,
      });
      if (res) {
        this.$store.commit("user/setUserData", {
          isPinCodeActive: true,
        });
        this.$plugins.toast.show({
          text: this.$t("settingsWindow.successfullySaved"),
          duration: 3000,
          variant: "success",
        });
      }
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    removePinCode() {
      const res = this.$store.dispatch("pin/removePinCode");
      this.password = "";
      this.rePassword = "";
      this.email = "";
      if (res) {
        this.$store.commit("user/setUserData", {
          isPinCodeActive: false,
        });
        this.$plugins.toast.show({
          text: this.$t("settingsWindow.successfullySaved"),
          duration: 3000,
          variant: "success",
        });
      } else {
        this.$plugins.toast.show({
          text: this.$t("errors.errorOccured"),
          duration: 3000,
          variant: "danger",
        });
      }
    },
    closeWindow() {
      this.$store.commit("modals/hide", "security");
      this.$plugins?.audio?.play("button_close_new");
    },
  },
};
</script>

<style lang="scss" scoped>
.security {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgb(90, 18, 26) 0%, rgb(147, 38, 52) 100%);
  .security-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &-section {
      height: 20%;
      display: flex;
      flex-direction: column;
      min-height: 200px;
      &-input {
        width: 600px;
        height: 80px;
        background: #55121b;
        box-shadow: inset 1px 3px 8px black;
        color: #ffffff;
        border-radius: 40px;
        font-size: 36px;
        padding-left: 20px;
      }
      &-input::placeholder {
        font-size: 30px;
        color: #ffffff;
      }
      &-title {
        font-size: 36px;
        color: #ffffff;
        text-shadow: 0 3px 5px #000000;
        position: relative;
        bottom: 15px;
        left: 30px;
      }
    }
    &-info {
      font-size: 34px;
      color: #ffffff;
      position: relative;
      bottom: 60px;
      line-height: 51px;
      width: 73%;
      text-align: center;
    }
    .ui-button-small-orange {
      min-width: 200px;
      position: relative;
      bottom: 20px;
    }
    .remove-container {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      .desc {
        font-size: 45px;
      }
    }
  }
}
</style>
