<template>
  <div class="card" :class="getClasses" v-bind="$attrs">
    <div class="card-inner">
      <div class="card-back card-sprite b0"></div>
      <div class="card-front" :class="getFrontClasses"></div>
    </div>
    <div class="card-win" v-if="winCard"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Card",
  props: {
    type: {
      type: String,
      default: "normal",
      required: false,
    },
    suit: {
      type: String,
      default: "b",
      required: false,
    },
    value: {
      type: String,
      default: "0",
      required: false,
    },
  },
  data() {
    return {
      flipped: true,
      winCard: false,
    };
  },
  watch: {
    "getActiveRoomState.stage": {
      handler(val) {
        if (val === 1) {
          this.winCard = false;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
    }),
    getClasses() {
      return [
        `card-type-${this.$props.type}`,
        this.$data.flipped ? "card-flipped" : "",
        this.getWinnerCardClass,
      ];
    },
    getFrontClasses() {
      const suit = String(this.$props.suit).toLowerCase();
      const value = String(this.$props.value).toLowerCase();

      if (this.$props.suit === "" || this.$props.value === "") {
        return [`card-sprite b0`];
      }

      return [`card-sprite ${suit}${value}`];
    },
    getWinnerCardClass() {
      if (this.getActiveRoomState.stage !== 5) return "";

      if (
        this.$store.state.service.pokerWinnerCards.length === 0 ||
        this.$store.state.service.pokerWinnerCards.length < 5
      )
        return "";

      const cardValue = String(this.$props.value).replace("10", "T");
      const isInWinnerCards =
        this.$store.state.service.pokerWinnerCards.includes(
          cardValue + this.$props.suit
        );

      if (isInWinnerCards) {
        this.winCard = true;
        return "card-winner-card";
      }

      return "card-not-winner-card";
    },
  },
  mounted() {
    setTimeout(
      () => {
        this.$data.flipped = false;
      },
      this.$props.suit === "" || this.$props.value === "" ? 0 : 100
    );
  },
};
</script>

<style scoped lang="scss">
.card {
  position: relative;
  width: 105px;
  height: 149px;
  transition: transform 0.25s ease;
  left: 5px;
  &.card-not-winner-card {
    filter: brightness(50%);
  }

  &.card-winner-card.card-type-table {
    transform: rotateX(30deg) skewX(0deg) scale(1.1) translateY(-20px);
  }

  &.card-type-table {
    transform-style: preserve-3d;
    transform: rotateX(30deg) skewX(0deg) scale(1.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
  }
  &.seat-card {
    .card-win {
      position: absolute;
      left: -4px;
      top: -4px;
      width: 107%;
    }
  }

  &-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.4s;
    transform-style: preserve-3d;
  }
  &-win {
    position: absolute;
    width: 105%;
    height: 106%;
    z-index: -1;
    border-radius: 10px;
    background-color: rgba(#fccf27, 1);
    box-shadow: 0px 0px 10px #fccf27;
  }
  &-front {
    box-shadow: 0 12px 40px 0 rgb(0 0 0 / 30);
  }
  &-front,
  &-back {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    box-shadow: 0 6px 5px 0 rgba(black, 0.3);
  }
  &-back {
    transform: rotateY(180deg);
  }

  &.card-flipped {
    .card-inner {
      transform: rotateY(180deg);
    }
  }
}
.seat-me {
  .card-inner {
    transform: scale(1.12);
  }
  .seat-card {
    .card-win {
      position: absolute;
      left: -10px;
      top: -12px;
      width: 119%;
    }
  }
}
.card:last-child {
  margin-right: 0px;
}
</style>
