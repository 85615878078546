<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1100"
    height="850"
    :show="show"
    innerBackground=""
    preventClickOutside
    :outerWindowStyle="{
      position: 'relative',
      top: '30px',
    }"
  >
    <template>
      <div
        class="progressive-jackpot-window sprite-table-jackpot background-image"
      >
        <div class="title-image progressive-jackpot-image"></div>
        <div
          class="progresive-jackpot-chip-container sprite-table-jackpot progressive-chip-background-image"
        >
          <div class="jackpot-slot" v-if="getProgressiveJackpot">
            <span class="chip">{{
              getChipFormat(getProgressiveJackpot.amount)
            }}</span>
            <!-- <span class="blind"
              >Small Blind:
              {{ getShortChipFormat(getActiveRoomState.bet) }}</span
            > -->
          </div>
        </div>
        <div class="table-line">
          <div class="progressive-texts">
            <div
              class="text"
              v-for="(item, index) in progressiveJackpotKeys"
              :key="index"
            >
              <span>{{
                item === "royalFlushX"
                  ? "Royal Flush"
                  : item === "straightFlushX"
                  ? "Straight Flush"
                  : item === "fourOfAKindX"
                  ? "Four of a Kind"
                  : item === "fullHouseASX"
                  ? "Full House A's"
                  : item
              }}</span>
              <span>{{ getActiveRoomState.progressiveJackpot[item] }}X</span>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="config">
            <div class="config-info-text">
              <p>- {{ $t("progressiveJackpot.desc1") }}</p>
              <p>- {{ $t("progressiveJackpot.desc2") }}</p>
            </div>
            <div class="slider-part" @click="handleProgressive">
              <div class="on-part" v-if="amIJoined">
                <div class="sprite-profile-popup button-green-s"></div>
                <span class="sprite-profile-popup circle-button"></span>
              </div>
              <div class="off-part" v-else>
                <div class="sprite-profile-popup button-red-s"></div>
                <span class="sprite-profile-popup circle-button left"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import EventBus from "@/event-bus";
import { mapState, mapGetters } from "vuex";

import BaseWindow from "@/components/Misc/GlobalWindows/BaseWindow";

export default {
  name: "ProgressiveJackPotWindow",
  components: {
    BaseWindow,
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.initializeProgressiveJackpot();
      }
    },
  },
  computed: {
    ...mapState(["jackpots"]),
    ...mapGetters(["getShortChipFormat", "getChipFormat", "getRealPlatform"]),
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
      nGetMySeat: "service/nGetMySeat",
      nAmISitting: "service/nAmISitting",
    }),
    isJoinButtonActive() {
      return (
        typeof this.nGetMySeat !== "undefined" &&
        this.nGetMySeat.stack > this.getActiveRoomState.bet &&
        this.nGetMySeat.position > -1
      );
    },
    amIJoined() {
      return this.nGetMySeat?.isInProgressiveJackpot;
    },
    getProgressiveJackpot() {
      const jackpot = this.jackpots.list.find(
        (jackpot) => jackpot.type === "progressive"
      );
      return !jackpot ? false : jackpot;
    },
  },
  data() {
    return {
      show: false,
      joinProgressiveValue: false,
      progressiveJackpotKeys: [],
    };
  },
  methods: {
    progressiveJackpotJoin() {
      progressiveJackpotJoin;
    },
    initializeProgressiveJackpot() {
      const keys = Object.keys(this.getActiveRoomState.progressiveJackpot);
      if (keys.length > 0) {
        this.progressiveJackpotKeys = keys.filter((key) => key !== "status");
      }
    },
    closeWindow() {
      this.show = false;
    },
    handleProgressive() {
      if (this.isJoinButtonActive) {
        this.joinProgressiveValue = !this.joinProgressiveValue;
        this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send(
          "toggleProgressiveJackpot"
        );
      }
    },
    onJackpotDetailRequested() {
      this.show = true;
    },
  },
  beforeDestroy() {
    EventBus.$off(
      "onProgressiveJackpotDetailRequested",
      this.onJackpotDetailRequested.bind(this)
    );
  },
  created() {
    EventBus.$on(
      "onProgressiveJackpotDetailRequested",
      this.onJackpotDetailRequested.bind(this)
    );
  },
};
</script>

<style lang="scss" scoped>
.progressive-jackpot-window {
  // position: relative;
  // background-image: url("~@/assets/images/jackpots/progressive-jackpot-window.png");
  // background-position: top;
  // background-repeat: no-repeat;
  // background-size: cover;
  // left: 0;
  // right: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 5px;

  .title-image {
    position: absolute;
    top: -85px;
    left: 142px;
    z-index: 2;
  }
  .progressive-jackpot-image {
    background: url("~@/assets/images/jackpots/jackpot-progressive.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 310px;
    width: 800px;
  }
  .progresive-jackpot-chip-container {
    position: relative;
    top: 47px;
    left: 317px;
    display: flex;
    justify-content: center;
    align-items: center;

    .jackpot-slot {
      // position: relative;
      // top: 33px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 20%;
      font-weight: bold;

      .chip {
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 40px;

        color: #f7bd37;
        text-shadow: -3px 3px 7px black;
      }
      // .blind {
      //   display: flex;
      //   justify-content: center;
      //   width: 100%;
      //   margin-top: 35px;
      //   color: #000000;
      //   font-size: 32px;
      // }
    }
  }
  .table-line {
    position: absolute;
    top: 55px;
    height: 100%;
    display: flex;
    justify-content: center;
    .progressive-texts {
      // width: 70%;
      position: relative;
      min-height: 130px;
      width: 1000px;
      margin: auto;
      padding: 24px 20px;
      background-color: #982735;
      border: 3px solid #efd37c;
      border-radius: 23px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .text {
        width: 95%;
        // height: 22%;
        margin: auto;
        font-size: 45px;
        text-shadow: 2px 2px 3px black;
        background: #3c0b13;
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        // padding-top: 7px;
        padding: 15px 15px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .bottom {
    position: absolute;
    bottom: 43px;
    width: 90%;

    .config {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .config-info-text {
        left: 40px;
        bottom: 20px;
        font-size: 32px;
        line-height: 32px;
        p:not(:last-child) {
          margin-bottom: 20px;
        }
      }
      .slider-part {
        position: absolute;
        cursor: pointer;
        right: -15px;
        top: -9px;
        z-index: 2;
        .circle-button {
          position: absolute;
          right: -20px;
          top: -8px;
          &.left {
            left: -20px;
          }
        }
      }
      // .option {
      //   transform: scale(0.6);
      //   position: absolute;
      //   bottom: 3px;
      //   right: -25px;
      //   &:disabled,
      //   &.disabled {
      //     opacity: 0.5;
      //   }
      // }
    }
  }
}
</style>
