<template>
  <div class="player-details-window">
    <div class="player-details-close-container" @click="closeWindow">
      <div class="player-details-close">
        <span class="sprite-table-pop-up exit-icon"></span>
      </div>
    </div>
    <div class="player-details-window-title">
      <span class="title-text">
        {{ $t("profileSettings.profileSettings") }}
      </span>
      <div class="profile-sprite light"></div>
    </div>
    <div class="menus">
      <template v-for="(item, index) in menuItems">
        <div
          class="menu-item"
          :class="
            currentSection === item.value
              ? item.value === 'statistics'
                ? 'profile-sprite tab1'
                : 'profile-sprite tab2'
              : ''
          "
          :key="`menu-item${index}`"
          @click="currentSection = item.value"
        >
          <span class="menu-text" :key="`menu-text${index}`">{{
            $t(item.text)
          }}</span>
          <div class="slot-line" v-if="currentSection === item.value"></div>
          <div :class="currentSection !== item.value ? 'right-line' : ''"></div>
        </div>
      </template>
    </div>
    <div class="player-details-content">
      <div class="statistics" v-if="currentSection === 'statistics'">
        <div class="section-statistics">
          <div class="player-avatar">
            <Avatar
              class="player-window-avatar"
              :url="user.avatar"
              :lazy="false"
              :crown="myCrownCondition(user.crown, user.vip.status)"
              size="200"
            />
            <div class="level-star"></div>
            <!-- <div class="level-start-lv">
                    Lv.
                  </div> -->
            <span class="level-star-text">
              {{ ` ${getLevelInfo.number}` }}
            </span>
          </div>
          <div class="player-info">
            <div class="profile-sprite profil-button">
              <div class="profile-button-container">
                <input
                  type="text"
                  class="profile-change-name"
                  :readonly="!editableName"
                  @keyup.enter="updateProfileName"
                  v-model.trim="editProfile.selectedName"
                  ref="name"
                  maxlength="14"
                />
                <div class="orange-content" @click.stop="changeEditableName">
                  <div class="profile-sprite edit-icon"></div>
                </div>
              </div>
            </div>
            <div class="profile-sprite profil-button">
              <div class="profile-button-container">
                <div class="red-content">
                  <div class="profile-sprite dollar-icon"></div>
                </div>
                <span class="profile-info-text">{{
                  getChipFormat(user.chip)
                }}</span>
                <div class="orange-content" @click="showShopWindow('buy-chip')">
                  <div class="profile-sprite chip-add-icon"></div>
                </div>
              </div>
            </div>
            <div class="profile-sprite profil-button">
              <div class="profile-button-container">
                <div class="red-content">
                  <div class="profile-sprite id-icon"></div>
                </div>
                <span class="profile-info-text">{{
                  $store.state.user.id
                }}</span>
                <div class="orange-content">
                  <div
                    class="profile-sprite copy-icon"
                    @click="clipBoardId"
                  ></div>

                  <div class="tooltip">
                    <span
                      class="tooltiptext tooltip-top"
                      v-show="copyInfoShow"
                      >{{ $t("playerDetailsWindow.copied") }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="profile-sprite profil-button">
                    <div class="red-content">
                      <div class="profile-sprite level-icon"></div>
                    </div>
                    <span class="profile-info-text"
                      >Lv. {{ getLevelInfo.number }}</span
                    >
                    <div class="null-content"></div>
                  </div> -->
            <div class="profile-sprite profil-button">
              <div class="profile-button-container">
                <div class="red-content">
                  <div class="profile-sprite vip-icon"></div>
                </div>
                <span class="profile-info-text">
                  {{
                    user.vip.status
                      ? $t("vip.available", { day: vipRemainingTime })
                      : $t("vip.noVip")
                  }}
                </span>
                <div class="orange-content" @click="showShopWindow('buy-vip')">
                  <div class="profile-sprite shopping-icon"></div>
                </div>
              </div>
            </div>
            <div class="profile-info-other">
              <div class="top-part">
                <div class="profile-sprite profil-button2">
                  <div class="profil-button-2-container">
                    <div class="red-content">
                      <div class="profile-sprite friends-icon"></div>
                    </div>
                    <span class="profile-info-text">{{
                      user.friendCount
                    }}</span>
                  </div>
                </div>
                <div class="profile-sprite profil-button2">
                  <div class="profil-button-2-container">
                    <div class="red-content">
                      <div class="profile-sprite like-button"></div>
                    </div>
                    <span class="profile-info-text">N/A</span>
                  </div>
                </div>
              </div>
              <div class="register-content flex-center">
                <span class="register-time"
                  >{{ $t("profileSettings.registrationDate") }}: N/A</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="content-statistics">
          <div class="note-container">
            <div class="note-container-header">
              <span class="note-container-header-text">{{
                $t("playerDetailsWindow.profileNote")
              }}</span>
              <div class="orange-content" @click="changeEditableNote">
                <div class="profile-sprite edit-icon"></div>
              </div>
            </div>
            <input
              class="note-container-input"
              type="text"
              maxlength="61"
              v-model="editProfile.profileNote"
              @keyup.enter="updateProfileNote"
              :readonly="!editableNote"
              :placeholder="$t('playerDetailsWindow.enterProfileNote')"
              ref="note"
            />
          </div>
          <div class="statistic-title">
            <span class="text">{{
              $t("playerDetailsWindow.gameStatistics")
            }}</span>
          </div>
          <div
            class="statistics-game-info blackjack-info"
            :style="{
              'min-height': holdemInfoValue
                ? getBestHandDesc
                  ? '650px'
                  : '470px'
                : '100px',
            }"
            @click="openContent('holdemInfoValue')"
          >
            <span
              class="statistics-game-info-title"
              @click.stop="holdemInfoValue = !holdemInfoValue"
              >Texas Hold'em</span
            >
            <div class="slot-line" v-show="holdemInfoValue"></div>
            <div class="blackjack-info-content" v-show="holdemInfoValue">
              <div class="blackjack-info-content-hand">
                <span class="blackjack-info-content-title">
                  {{ $t("profileSettings.statistics.handPlayed") }}
                </span>
                <span class="blackjack-info-content-text">N/A</span>
              </div>
              <div class="blackjack-info-content-big-chip">
                <span class="blackjack-info-content-title">
                  {{ $t("profileSettings.statistics.biggestWin") }}
                </span>
                <span class="blackjack-info-content-text"> N/A</span>
              </div>
            </div>
            <div class="record-chip-content" v-show="holdemInfoValue">
              <span class="record-chip-content-title">{{
                $t("profileSettings.statistics.recordChip")
              }}</span>
              <span class="record-chip-content-text"
                >$ {{ getChipFormat($store.state.user.recordChip) }}</span
              >
            </div>
            <div class="won-content" v-show="holdemInfoValue">
              <div class="won-content-section">
                <div class="won-content-section-title">
                  {{ $t("profileSettings.statistics.brokenPotCounter") }}
                </div>
                <div class="won-content-section-text">
                  {{ $store.state.user.jackpotExplosionCount }}
                </div>
              </div>
              <div class="won-content-section">
                <div class="won-content-section-title">
                  {{ $t("profileSettings.statistics.winPercentage") }}
                </div>
                <div class="won-content-section-text">N/A</div>
              </div>
              <div class="won-content-section">
                <div class="won-content-section-title">
                  {{ $t("profileSettings.statistics.leadershipRanking") }}
                </div>
                <div class="won-content-section-text">
                  {{ getPlacement }}
                </div>
              </div>
            </div>
            <div
              class="won-content"
              v-show="holdemInfoValue && getBestHandDesc"
            >
              <div class="won-content-section">
                <div class="won-content-section-title">
                  {{ $t("profileSettings.statistics.bestWinningHand") }}
                </div>
              </div>
              <div class="won-content-section-card">
                <template v-if="userBestHand.length > 0">
                  <Card
                    v-for="(card, cardIndex) in userBestHand"
                    :key="'table-card-' + cardIndex"
                    :suit="card.suit"
                    :value="card.value"
                    type="table"
                  />
                </template>
              </div>
              <div class="won-content-section">
                <div class="won-content-section-title">
                  {{ getBestHandDesc }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="statistics-game-info blackjack-info"
            :style="{
              'min-height': blackjackInfoValue ? '180px' : '100px',
            }"
            @click="openContent('blackjackInfoValue')"
          >
            <span
              class="statistics-game-info-title"
              @click.stop="blackjackInfoValue = !blackjackInfoValue"
              >Blackjack</span
            >
            <div class="slot-line" v-show="blackjackInfoValue"></div>
            <div class="blackjack-info-content" v-show="blackjackInfoValue">
              <div class="blackjack-info-content-hand">
                <span class="blackjack-info-content-title">{{
                  $t("profileSettings.statistics.handPlayed")
                }}</span>
                <span class="blackjack-info-content-text">N/A</span>
              </div>
              <div class="blackjack-info-content-big-chip">
                <span class="blackjack-info-content-title">
                  {{ $t("profileSettings.statistics.biggestWin") }}
                </span>
                <span class="blackjack-info-content-text">N/A</span>
              </div>
            </div>
          </div>
          <div
            class="statistics-game-info slots-info"
            :style="{
              'min-height': slotsInfoValue ? '750px' : '100px',
            }"
            @click="openContent('slotsInfoValue')"
          >
            <span
              class="statistics-game-info-title"
              @click.stop="slotsInfoValue = !slotsInfoValue"
              >Slots</span
            >
            <div class="slot-line" v-show="slotsInfoValue"></div>
            <div class="slots-info-content" v-show="slotsInfoValue">
              <div class="turn-legends">
                <span class="slots-title">TURN LEGENDS</span>
                <div class="slots-info-section">
                  <div class="slots-info-content-hand">
                    <span class="slots-info-content-title">{{
                      $t("profileSettings.statistics.handPlayed")
                    }}</span>
                    <span class="slots-info-content-text">N/A</span>
                  </div>
                  <div class="slots-info-content-big-chip">
                    <span class="slots-info-content-title">
                      {{ $t("profileSettings.statistics.biggestWin") }}
                    </span>
                    <span class="slots-info-content-text">N/A</span>
                  </div>
                </div>
                <div class="slot-line"></div>
              </div>
              <div class="fafafa">
                <span class="slots-title">FAFAFA</span>
                <div class="slots-info-section">
                  <div class="slots-info-content-hand">
                    <span class="slots-info-content-title">{{
                      $t("profileSettings.statistics.handPlayed")
                    }}</span>
                    <span class="slots-info-content-text">N/A</span>
                  </div>
                  <div class="slots-info-content-big-chip">
                    <span class="slots-info-content-title">
                      {{ $t("profileSettings.statistics.biggestWin") }}
                    </span>
                    <span class="slots-info-content-text">N/A</span>
                  </div>
                </div>
                <div class="slot-line"></div>
              </div>
              <div class="bamboo">
                <span class="slots-title">Bamboo Panda</span>
                <div class="slots-info-section">
                  <div class="slots-info-content-hand">
                    <span class="slots-info-content-title">{{
                      $t("profileSettings.statistics.handPlayed")
                    }}</span>
                    <span class="slots-info-content-text">N/A</span>
                  </div>
                  <div class="slots-info-content-big-chip">
                    <span class="slots-info-content-title">
                      {{ $t("profileSettings.statistics.biggestWin") }}
                    </span>
                    <span class="slots-info-content-text">N/A</span>
                  </div>
                </div>
                <div class="slot-line"></div>
              </div>
              <div class="turn-of-olympus">
                <span class="slots-title">Turn Olympus</span>
                <div class="slots-info-section">
                  <div class="slots-info-content-hand">
                    <span class="slots-info-content-title">{{
                      $t("profileSettings.statistics.handPlayed")
                    }}</span>
                    <span class="slots-info-content-text">N/A</span>
                  </div>
                  <div class="slots-info-content-big-chip">
                    <span class="slots-info-content-title">
                      {{ $t("profileSettings.statistics.biggestWin") }}
                    </span>
                    <span class="slots-info-content-text">N/A</span>
                  </div>
                </div>
                <div class="slot-line"></div>
              </div>
            </div>
          </div>
          <!-- <div class="statistics-game-info domino-info">
                  <span class="statistics-game-info-title">Domino</span>
                </div> -->
        </div>
      </div>
      <div class="avatar" v-if="currentSection === 'avatar'">
        <div class="avatar-profile">
          <Avatar
            class="player-window-avatar"
            :url="innerUserAvatar"
            :crown="myCrownCondition(user.crown, user.vip.status)"
            size="200"
            :lazy="false"
          />
          <div
            class="avatar-button ui-button ui-button-orange"
            @click="changeAvatar"
          >
            {{ $t("profileSettings.use") }}
          </div>
          <button
            class="fb-default-button ui-button ui-button-orange"
            v-if="$store.state.authentication.authType === 'facebook'"
            @click="setDefaultFBAvatar"
          >
            SET DEFAULT AVATAR
          </button>
        </div>
        <div class="avatar-content">
          <div class="avatar-content-item" v-for="number in 36" :key="number">
            <template v-if="number !== 0">
              <Avatar
                :url="getAvatarBaseUrl + number + '.png'"
                size="200"
                :crown="-1"
                @click.native="selectAvatar(number)"
                :lazy="number > 15 ? true : false"
              />
              <span
                v-show="getEditAvatarUrl === getAvatarBaseUrl + number + '.png'"
                class="select-icon"
              ></span>
            </template>
          </div>
        </div>
      </div>
      <div class="frame" v-if="currentSection === 'frame'">
        <div class="frame-profile">
          <Avatar
            class="player-window-avatar"
            :url="user.avatar"
            :crown="innerUserCrown"
            size="200"
            :lazy="false"
          />
          <div class="profile-sprite profil-button">
            <div class="orange-content">
              <div class="profile-sprite dollar-icon"></div>
            </div>
            <span class="profil-button-chip">
              {{ getChipFormat(parseInt(user.availableChip)) }}
            </span>
          </div>
          <div
            class="ui-button ui-button-orange frame-button"
            @click="setNewCrown"
            v-if="user.vip.status"
          >
            {{ $t("profileSettings.use") }}
          </div>
          <div
            class="ui-button ui-button-orange frame-button"
            @click="showShopWindow('buy-vip')"
            v-else
          >
            {{ $t("profileSettings.buyVip") }}
          </div>
        </div>
        <div class="frame-content">
          <template>
            <div
              class="frame-content-avatar"
              v-for="number in 13"
              :key="number"
            >
              <Avatar
                :crown="number - 1"
                size="200"
                @click.native="avatarSelect(number - 1)"
                :lazy="false"
              />
              <span
                v-show="innerUserCrown === number - 1"
                class="selected-icon"
              ></span>
            </div>
          </template>
        </div>
      </div>
      <!-- <div class="hand-history" v-if="currentSection === 'hand-history'">
        <HandHistoryContent v-if="currentSection === 'hand-history'" />
      </div> -->
    </div>
    <AreYouSureWindow
      :show="deleteDialog"
      @closeAreYouSure="areYouSureClose"
      @confirm="deleteAccount"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import PokerSolver from "pokersolver";

export default {
  name: "PlayerDetailsWindow",
  components: {
    AreYouSureWindow: () => import("../AreYouSureWindow"),
    Avatar: () => import("@/components/Misc/Avatar"),
    Card: () => import("@/components/Misc/PokerTable/Cards/Card"),
  },
  data() {
    return {
      userCrown: 0,
      editableNote: false,
      editableName: false,
      holdemInfoValue: true,
      blackjackInfoValue: false,
      slotsInfoValue: false,
      handHistoryHeaderValue: "holdem",
      currentSection: "statistics",
      currentScreen: "details",
      profileCurrentTab: "statistics",
      editCurrentTab: "avatar",
      showConfirm: false,
      levelNumber: 0,
      loading: false,
      deleteDialog: false,
      copyInfoShow: false,

      editProfile: {
        selectedAvatar: null,
        selectedName: "",
        selectedCrown: 0,
        profileNote: "",
      },
      menuItems: [
        {
          text: "profileSettings.statistics.statistics",
          value: "statistics",
        },
        {
          text: "general.avatar",
          value: "avatar",
        },
        // {
        //   text: this.$t("profileSettings.gift"),
        //   value: "gift",
        // },
        {
          text: "profileSettings.frame",
          value: "frame",
        },
        // {
        //   text: "general.case",
        //   value: "case",
        // },
        // {
        //   text: "blackJack.gameHistory",
        //   value: "hand-history",
        // },
        // {
        //   text: "settingsWindow.security",
        //   value: "security",
        // },
      ],
      dummyCards: [
        { index: 33, suit: "c", value: "9" },
        { index: 35, suit: "c", value: "J" },
        { index: 39, suit: "h", value: "2" },
        { index: 3, suit: "s", value: "5" },
        { index: 3, suit: "s", value: "5" },
      ],
    };
  },
  watch: {
    // "$store.state.modals.userDetails": {
    //   handler(val) {
    //     if (val) {
    //       this.onUserDetailsShow();
    //       this.userCrown = this.user.crown;
    //     }
    //   },
    // },
    currentSection(val) {
      if (val === "statistics") {
        this.$adjust.trackEvent("ClickGoToStatistics");
      } else if (val === "avatar") {
        this.$adjust.trackEvent("ClickGoToAvatar");
      } else if (val === "frame") {
        this.$adjust.trackEvent("ClickGoToFrame");
      }
      this.$plugins?.audio?.play("Button");
    },
    copyInfoShow: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.copyInfoShow = false;
          }, 1000);
        }
      },
      immediate: true,
    },
    holdemInfoValue(val) {
      if (val) {
        this.slotsInfoValue = false;
        this.blackjackInfoValue = false;
        this.$adjust.trackEvent("ClickTexasHoldemStatistics");
      }
    },
    blackjackInfoValue(val) {
      if (val) {
        this.slotsInfoValue = false;
        this.holdemInfoValue = false;
        this.$adjust.trackEvent("ClickBlackjackStatistics");
      }
    },
    slotsInfoValue(val) {
      if (val) {
        this.blackjackInfoValue = false;
        this.holdemInfoValue = false;
        this.$adjust.trackEvent("ClickSlotsStatistics");
      }
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters([
      "getChipFormat",
      "getShortChipFormat",
      "user/getLevel",
      "getRealPlatform",
      "user/getLevel",
    ]),
    getBestHandDesc() {
      if (this.user.bestPokerHand && this.user.bestPokerHand !== "-") {
        let arrBestHand = this.user.bestPokerHand.split(" ");
        let bestHandPokerName = PokerSolver.Hand.solve(arrBestHand);
        return bestHandPokerName.name;
      }
      return "";
    },
    getPlacement() {
      if (this.user.placementInPlayers !== undefined) {
        if (this.user.placementInPlayers > 999) {
          return (this.user.placementInPlayers =
            this.user.placementInPlayers + "+");
        } else {
          return this.user.placementInPlayers;
        }
      }
      return "";
    },
    userBestHand() {
      if (this.user && this.user.bestPokerHand) {
        const split = String(this.user.bestPokerHand || "").split(" ");
        if (split.length <= 0) return [];
        return [...split].map((item) => ({
          suit: item[1],
          value: item[0] == "T" ? "10" : item[0],
        }));
      }
      return [];
    },
    vipRemainingTime() {
      const vipExpireTime = this.user.vip.expiresAt;
      const nowTime = Math.round(this.$plugins.moment.now() / 1000);

      let returnValue = Math.floor((vipExpireTime - nowTime) / 3600 / 24) + 1;

      if (returnValue > 0) {
        return returnValue;
      }

      return "0";
    },
    innerUserCrown: {
      get() {
        return this.userCrown;
      },
      set(val) {
        this.userCrown = val;
      },
    },
    innerUserAvatar() {
      if (!this.editProfile.selectedAvatar) {
        return this.getAvatarBaseUrl + this.user.avatarNum + ".png";
      } else {
        if (this.editProfile.selectedAvatar) {
          return (
            this.getAvatarBaseUrl + this.editProfile.selectedAvatar + ".png"
          );
        }
        return this.user.avatar;
      }
    },
    getLevelInfo() {
      return this["user/getLevel"](this.user.xp);
    },

    getEditAvatarUrl() {
      if (this.editProfile.selectedAvatar !== null) {
        return this.getAvatarBaseUrl + this.editProfile.selectedAvatar + ".png";
      } else {
        return this.getAvatarBaseUrl + this.user.avatarNum + ".png";
      }
    },
    getAvatarBaseUrl() {
      return process.env.VUE_APP_AVATAR_URL;
    },
    getBestHandCards() {
      if (this.user.bestPokerHand == "" || this.user.bestPokerHand == "-")
        return [];

      const split = String(this.user.bestPokerHand || "").split(" ");
      if (split.length <= 0) return [];
      return [...split].map((item) => ({
        suit: item[1],
        value: item[0],
      }));
    },
  },
  methods: {
    openContent(text) {
      if (!this.$data[text]) {
        this.$data[text] = true;
      }
    },
    changeEditableNote() {
      this.editableNote = !this.editableNote;
      this.updateProfileNote();
      this.$refs.note.focus();
      this.$plugins.audio.play("Button");
    },

    changeEditableName() {
      if (this.editableName) {
        this.updateProfileName();
      }
      this.editableName = !this.editableName;

      this.$refs.name.focus();
      this.$plugins.audio.play("Button");
    },
    async setNewCrown() {
      if (this.user.crown !== this.userCrown && this.userCrown > -1) {
        const response = await this.$store.dispatch(
          "user/changeProfileCrown",
          this.userCrown
        );

        if (!response) {
          this.loading = false;
          return this.$plugins.toast.show({
            text: this.$t("playerDetailsWindow.failedChanges"),
            duration: 2500,
            variant: "danger",
          });
        }
        this.$store.commit("user/setUserData", {
          crown: response.crown,
        });
        return this.$plugins.toast.show({
          text: this.$t("caseWindow.transaction"),
          duration: 2500,
          variant: "success",
        });
      } else {
        return this.$plugins.toast.show({
          text: this.$t("playerDetailsWindow.sameFrameWarning"),
          duration: 2500,
          variant: "danger",
        });
      }
    },
    avatarSelect(number) {
      if (!this.user.vip.status) {
        return this.$plugins.toast.show({
          text: this.$t("playerDetailsWindow.vipdesc"),
          duration: 2500,
          variant: "danger",
        });
      } else {
        this.innerUserCrown = number;
      }
    },
    async changeAvatar() {
      if (
        this.editProfile.selectedAvatar !== this.$store.state.user.avatarNum
      ) {
        const response = await this.$store.dispatch(
          "user/changeProfileAvatar",
          this.editProfile.selectedAvatar
        );
        if (!response) {
          return this.$plugins.toast.show({
            text: this.$t("playerDetailsWindow.failedChanges"),
            duration: 2500,
            variant: "danger",
          });
        }
        this.$store.commit("user/setUserData", {
          avatar:
            this.getAvatarBaseUrl +
            this.$data.editProfile.selectedAvatar +
            ".png",
        });
        this.$store.state.user.avatarNum = this.editProfile.selectedAvatar;
        this.$store.dispatch("service/requestUserDataFromLobby");

        this.$plugins.toast.show({
          text: this.$t("caseWindow.transaction"),
          duration: 3000,
          variant: "success",
        });
      } else {
        return this.$plugins.toast.show({
          text: this.$t("playerDetailsWindow.sameAvatarWarning"),
          duration: 3000,
          variant: "danger",
        });
      }
    },
    clipBoardId() {
      this.$plugins.clipboard.write({
        type: "string",
        value: String(this.$store.state.user.id),
      });
      this.copyInfoShow = true;
      this.$adjust.trackEvent("ClickIdCopy");
    },
    myCrownCondition(crownNumber = 0, vip = false) {
      const calcCrown = crownNumber < 1 && vip ? 0 : crownNumber;
      return vip ? calcCrown : -1;
    },
    areYouSureClose() {
      this.deleteDialog = false;
      this.$store.commit("modals/show", "userDetails");
    },
    deleteButtonClick() {
      this.deleteDialog = true;
      this.$store.commit("modals/hide", "userDetails");
    },
    async deleteAccount() {
      const res = await this.$store.dispatch("user/deleteAccount", {
        accountType: this.$store.state.authentication.authType,
      });
      if (res) {
        this.$plugins.toast.show({
          text: this.$t("deleteConfirmWindow.deleteSuccessfully"),
          duration: 2000,
          variant: "success",
        });
        this.deleteDialog = false;
        this.$adjust.trackEvent("ClickDeleteAccount");
      } else {
        this.$plugins.toast.show({
          text: this.$t("deleteConfirmWindow.notBeDeleted"),
          duration: 2000,
          variant: "danger",
        });
      }
    },
    onUserDetailsShow() {
      // this.editProfile.selectedName = this.user.name;
      this.currentSection = "statistics";
      this.editProfile.profileNote = this.user.profileNote;
      this.editProfile.selectedCrown = this.user.crown;
      this.levelNumber = this["user/getLevel"](this.user.xp).number;
      this.editProfile.selectedName = this.$store.state.user.name;
      // this.editProfile.selectedAvatar = this.$store.state.user.avatarNum;
    },
    openEditWindow() {
      this.currentScreen = "edit";
    },
    closeEditWindow() {
      this.currentScreen = "details";
    },
    getBestHandCardClasses(card) {
      const value = String(card.value).replace("T", "10");
      return String(card.suit + value).toLowerCase();
    },
    closeWindow() {
      this.currentScreen = "details";
      this.$store.commit("modals/hide", "userDetails");
      this.updateProfileNote();
    },
    async copyToClipboard(value) {
      await this.$plugins.clipboard.write({
        type: "string",
        value: String(value),
      });
      this.$refs.popover.style.visibility = "visible";
      setTimeout(() => {
        this.$refs.popover.style.visibility = "hidden";
      }, 2000);

      this.$plugins.audio.play("Button");
      return this.$plugins.toast.show({
        text: this.$t("playerDetailsWindow.copied"),
        duration: 2500,
        variant: "success",
      });
    },
    getBestHandCardClasses(card) {
      const value = String(card.value).replace("T", "10");
      return String(card.suit + value).toLowerCase();
    },
    handleTabs(tab) {
      this.$data.profileCurrentTab = tab;
    },
    handleEditTabs(tab) {
      this.$data.editCurrentTab = tab;
    },
    selectCrown(number) {
      this.editProfile.selectedCrown = number;
    },
    selectAvatar(number) {
      if (this.editProfile.selectedAvatar === number) {
        this.editProfile.selectedAvatar = null;
      } else {
        this.editProfile.selectedAvatar = number;
      }
      // this.selectedAvatar = number;
    },
    setDefaultFBAvatar() {
      this.editProfile.selectedAvatar = "default";
      this.updateProfile();
    },
    async updateProfile() {
      this.loading = true;

      if (
        this.editProfile.selectedName &&
        this.editProfile.selectedName !== this.user.name
      ) {
        const response = await this.$store.dispatch(
          "user/changeProfileName",
          this.editProfile.selectedName
        );
        if (!response) {
          this.loading = false;
          return this.$plugins.toast.show({
            text: this.$t("playerDetailsWindow.failedChanges"),
            duration: 2500,
            variant: "danger",
          });
        }
        this.$store.commit("user/setUserData", {
          name: response.name,
          rightToChangeName: this.user.rightToChangeName - 1,
        });
      }

      if (this.editProfile.profileNote !== this.user.profileNote) {
        const response = await this.$store.dispatch(
          "user/changeProfileNote",
          this.editProfile.profileNote
        );
        if (!response) {
          this.loading = false;
          return this.$plugins.toast.show({
            text: this.$t("playerDetailsWindow.failedChanges"),
            duration: 2500,
            variant: "danger",
          });
        }
        this.$store.commit("user/setUserData", {
          profileNote: response.profileNote,
        });
      }
      if (
        this.editProfile.selectedCrown >= 0 &&
        this.editProfile.selectedCrown !== this.user.crown
      ) {
        const response = await this.$store.dispatch(
          "user/changeProfileCrown",
          this.editProfile.selectedCrown
        );
        if (!response) {
          this.loading = false;
          return this.$plugins.toast.show({
            text: this.$t("playerDetailsWindow.failedChanges"),
            duration: 2500,
            variant: "danger",
          });
        }
        this.$store.commit("user/setUserData", {
          crown: response.crown,
        });
      }
      if (
        this.$data.editProfile.selectedAvatar &&
        this.getAvatarBaseUrl +
          this.$data.editProfile.selectedAvatar +
          ".png" !==
          this.user.avatar
      ) {
        const response = await this.$store.dispatch(
          "user/changeProfileAvatar",
          this.editProfile.selectedAvatar
        );

        if (!response) {
          this.loading = false;
          return this.$plugins.toast.show({
            text: this.$t("playerDetailsWindow.failedChanges"),
            duration: 2500,
            variant: "danger",
          });
        }
        this.$store.commit("user/setUserData", {
          avatar:
            this.getAvatarBaseUrl +
            this.$data.editProfile.selectedAvatar +
            ".png",
        });
        this.$store.dispatch("service/requestUserDataFromLobby");
      }
      this.currentScreen = "details";

      this.loading = false;
    },
    showShopWindow(tab) {
      this.$store.state.modals.nextWindowText = tab;
      this.$store.commit("modals/show", "shop");
      this.$adjust.trackEvent("ClickPlayerDetailShopWindowOn");
    },
    async updateProfileNote() {
      if (this.editProfile.profileNote !== this.user.profileNote) {
        this.loading = true;
        const response = await this.$store.dispatch(
          "user/changeProfileNote",
          this.editProfile.profileNote
        );
        if (!response) {
          this.loading = false;
          return this.$plugins.toast.show({
            text: this.$t("playerDetailsWindow.failedChanges"),
            duration: 2500,
            variant: "danger",
          });
        } else {
          this.$plugins.toast.show({
            text: this.$t("userProfile.noteUpdated"),
            duration: 3000,
          });
        }
        this.$store.commit("user/setUserData", {
          profileNote: response.profileNote,
        });
        this.editableNote = false;
        this.editableName = false;
        this.loading = false;
      }
    },
    async updateProfileName() {
      if (this.user.rightToChangeName === 0) {
        this.$plugins.toast.show({
          text: this.$t("playerDetailsWindow.changeName"),
          duration: 2500,
          variant: "danger",
        });
        return;
      }
      if (
        this.editProfile.selectedName &&
        this.editProfile.selectedName !== this.user.name
      ) {
        const response = await this.$store.dispatch(
          "user/changeProfileName",
          this.editProfile.selectedName
        );
        if (!response) {
          this.loading = false;
          return this.$plugins.toast.show({
            text: this.$t("playerDetailsWindow.failedChanges"),
            duration: 2500,
            variant: "danger",
          });
        }
        this.$store.commit("user/setUserData", {
          name: response.name,
          rightToChangeName: this.user.rightToChangeName - 1,
        });
        this.$plugins.toast.show({
          text: this.$t("others.changeNameSuccesInfo", [
            this.user.rightToChangeName,
          ]),
          duration: 2500,
        });
      }
    },
  },
  mounted() {
    this.onUserDetailsShow();
    this.userCrown = this.user.crown;
  },
};
</script>

<style lang="scss">
.player-details-window {
  position: fixed;
  width: 1920px;
  height: 1080px;
  top: 180px;
  left: 320px;
  z-index: 6;
  .player-details-close-container {
    position: absolute;
    top: 5px;
    right: 15px;
    z-index: 7;
    height: 90px;
    width: 98px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .player-details-close {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;
      width: 65px;
      height: 65px;
      border-radius: 100%;
      border: 3px solid #cf707f;
      cursor: pointer;
      transition: all 0.25s ease;
      .exit-icon {
        transform: scale(0.7);
      }
    }
  }
  // .hand-history {
  //   position: relative;
  //   top: 20px;
  //   width: 100%;
  //   height: 100%;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }

  .player-details-window-title {
    width: 100%;
    height: 82px;
    background-color: #3d0b14;
    .title-text {
      display: block;
      text-shadow: 3px 5px 3px #000;
      font-size: 56px;
      position: relative;
      text-align: center;
      z-index: 2;
    }
    .light {
      position: absolute;
      left: 450px;
      top: 0px;
      z-index: 1;
    }
  }
  .menus {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    position: relative;
    background-color: #3b0b14;
    .menu-item {
      position: relative;
      width: 14%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 2;
      max-height: 80px;
      &:first-child {
        .slot-line {
          width: 90%;
          position: relative;
          top: 8px;
          left: 0px;
          z-index: 1;
        }
      }
      .slot-line {
        width: 90%;
        position: relative;
        top: 8px;
        left: 8px;
        z-index: 1;
      }
      .right-line {
        position: absolute;
        height: 50%;
        width: 2px;
        background: #902533;
        left: -5px;
      }
      .menu-text {
        font-size: 32px;
        color: #cf707f;
        text-shadow: rgba(0, 0, 0, 1) 0px 2px 1px;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        min-height: 52px;
        z-index: 1;
        position: relative;
        top: 10px;
      }
      .profile-sprite {
        position: relative;
        display: inline;
        z-index: 0;
        left: 1px;
        top: 0px;
      }
      &:first-child {
        .right-line {
          display: none;
        }
      }
    }
    .tab1,
    .tab2 {
      .menu-text {
        color: #ffffff;
      }
    }
    .tab1 {
      background-position: 1px -157px;
      border-top-right-radius: 15px;
    }
    .tab2 {
      background-position: -280px -152px;
    }
  }
  .player-details-content {
    height: 85%;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(90, 18, 26, 1) 0%,
      rgba(147, 38, 52, 1) 100%
    );
    .statistics {
      height: 100%;
      display: flex;
      overflow: hidden;
      .section-statistics {
        position: relative;
        height: 100%;
        width: 22%;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-right: 2px solid #6f1923;
        .player-avatar {
          width: 100%;
          height: 35%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          right: 18px;
          top: -11px;
          // .avatar-gift {
          //   height: 65px;
          //   width: 65px;
          //   background: rgba(#000000, 0.4);
          //   border-radius: 30px;
          //   z-index: 1;
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   position: relative;
          //   left: 25px;
          // }
          .level-star {
            background: url("~@/assets/images/profile/level-star.png") 50% 50%
              no-repeat;
            width: 62px;
            height: 64px;
            position: absolute;
            bottom: 50px;
            right: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          // .level-start-lv{
          //    position: absolute;
          //   bottom: 68px;
          //   right: 105px;
          //   font-size: 28px;
          //   color: #ffffff;
          // }
          .level-star-text {
            position: absolute;
            bottom: 68px;
            right: 81px;
            font-size: 28px;
            color: #ffffff;
          }
        }
        .player-info {
          width: 100%;
          height: 65%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .profile-change-name {
            width: 260px;
            height: 90%;
            background: transparent;
            border: none;
            font-size: 32px;
            color: #ffffff;
            margin-left: 20px;
          }
          .profil-button {
            margin-bottom: 30px;
            overflow: visible;
            display: flex;
            justify-content: center;
            align-items: center;
            .profile-button-container {
              width: 95%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .orange-content {
                flex-shrink: 0;
                .tooltip {
                  position: relative;
                  display: inline-block;
                  border-bottom: 1px dotted black;
                  bottom: 70px;
                  right: 130px;
                  .tooltiptext {
                    width: 200px;
                    height: 40px;
                    background-color: black;
                    color: #fff;
                    text-align: center;
                    padding: 5px 0;
                    border-radius: 10px;
                    position: absolute;
                    left: 20px;
                    top: -5px;
                    font-size: 26px;
                    z-index: 1;
                  }
                  .tooltiptext::after {
                    content: " ";
                    position: absolute;
                    top: 100%; /* At the bottom of the tooltip */
                    left: 50%;
                    margin-left: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: black transparent transparent transparent;
                  }
                }
              }
            }
          }
          .profile-info-other {
            width: 100%;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            .top-part {
              display: flex;
              width: 100%;
              justify-content: space-around;
              align-items: center;
              .profil-button2 {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .profil-button-2-container {
                  width: 80%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }
              }
            }
          }
          .profile-info-text {
            font-size: 29px;
            color: #ffffff;
          }
          .red-content {
            flex-shrink: 0;
            height: 45px;
            width: 45px;
            border-radius: 23px;
            background: rgb(127, 30, 29);
            background: linear-gradient(
              0deg,
              rgba(127, 30, 29, 1) 0%,
              rgba(177, 47, 48, 1) 100%
            );
            border: solid 1px #1e0809;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .orange-content {
            height: 55px;
            width: 55px;
            border-radius: 23px;
            background: rgb(127, 30, 29);
            background: linear-gradient(0deg, #f49648 0%, #f17e32 100%);
            border: solid 1px #1e0809;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:active {
              transform: scale(1.1);
            }
          }
          .null-content {
            min-width: 45px;
          }
          .register-content {
            width: 100%;
            .register-time {
              margin-top: 20px;
              font-size: 30px;
              color: #cbbebf;
            }
          }
        }
      }
      .content-statistics {
        width: 78%;
        height: 96%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        .statistic-title {
          display: flex;
          align-items: flex-end;
          width: 89%;
          min-height: 80px;
          .text {
            font-size: 36px;
            text-shadow: 0px 3px 2px black;
            font-weight: bold;
            margin-bottom: 15px;
          }
        }
        .blackjack-info {
          &-content {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: space-between;
            margin-top: 15px;
            &-hand {
              width: 49%;
              height: 100px;
              background-color: rgba(#54121c, 1);
              margin-left: 8px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
            &-big-chip {
              width: 49%;
              height: 100px;
              background-color: rgba(#54121c, 1);
              margin-left: 9px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
            &-title {
              font-size: 34px;
              color: #cf707f;
            }
            &-text {
              font-size: 34px;
              margin-top: 5px;
              color: #ffffff;
            }
          }
        }
        .statistics-game-info {
          width: 90%;
          min-height: 100px;
          background-color: rgba(0, 0, 0, 0.6);
          border-radius: 10px;
          margin-top: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          &-title {
            font-size: 40px;
          }
          .slots-info-content {
            width: 100%;
            margin-top: 10px;
            .slots-title {
              font-size: 38px;
              color: #ffffff;
              margin: 10px 0px;
            }
            .turn-legends,
            .fafafa,
            .bamboo,
            .turn-of-olympus {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .slots-info-section {
                display: flex;
                flex-direction: row;
                width: 100%;
                .slots-info-content-hand {
                  width: 49%;
                  height: 100px;
                  background-color: rgba(#54121c, 1);
                  margin-left: 8px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  border-radius: 10px;
                }
                .slots-info-content-big-chip {
                  width: 49%;
                  height: 100px;
                  background-color: rgba(#54121c, 1);
                  margin-left: 9px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  border-radius: 10px;
                }

                .slots-info-content-title {
                  font-size: 34px;
                  color: #cf707f;
                }
                .slots-info-content-text {
                  font-size: 34px;
                  margin-top: 7px;
                  color: #ffffff;
                }
              }
            }
            .slot-line {
              background: url("~@/assets/images/profile/slot-line.png")
                no-repeat 50% 50%;
              width: 100%;
              height: 1px;
              margin-top: 10px;
            }
          }
          .record-chip-content {
            width: 99%;
            height: 100px;
            background-color: rgba(#54121c, 1);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 15px 0px;
            .record-chip-content-title {
              font-size: 34px;
              color: #cf707f;
            }
            .record-chip-content-text {
              font-size: 34px;
              margin-top: 7px;
              color: #ffffff;
            }
          }
          .won-content {
            width: 99%;
            height: 150px;
            background-color: rgba(#54121c, 1);
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            margin-bottom: 15px;
            .won-content-section {
              width: 33.3333%;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: center;
              .won-content-section-title {
                font-size: 34px;
                color: #cf707f;
              }
              .won-content-section-text {
                font-size: 34px;
                margin-top: 7px;
                color: #ffffff;
              }
            }
            .won-content-section-card {
              width: 33.3333%;
              display: flex;
              flex-direction: row;
              .card {
                transform: scale(0.8);
                position: relative;
              }
            }
          }
        }
        .note-container {
          width: 90%;
          min-height: 180px;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          align-items: center;
          position: relative;
          .note-container-header {
            width: 99%;
            height: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &-text {
              font-size: 36px;
              text-shadow: 0px 3px 2px black;
              font-weight: bold;
            }
            .orange-content {
              position: absolute;
              right: 20px;
              bottom: 17px;
              height: 60px;
              width: 60px;
              border-radius: 23px;
              background: rgb(127, 30, 29);
              background: linear-gradient(0deg, #f49648 0%, #f17e32 100%);
              box-shadow: 0px 5px 2px black;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              &:active {
                transform: scale(1.1);
              }
            }
          }
          .note-container-input {
            width: 99%;
            height: 50%;
            background: #3b0b14;
            border: none;
            border-radius: 10px;
            box-shadow: inset 1px 3px 8px black;
            font-size: 32px;
            color: white;
            padding-left: 20px;
            display: flex;
            align-items: center;
            &::placeholder {
              font-size: 32px;
              color: #918284;
              padding-left: 20px;
            }
          }
        }
      }
    }
    .avatar {
      width: 100%;
      height: 100%;
      display: flex;
      .avatar-profile {
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 2px solid #6f1923;
        .avatar {
          position: relative;
          top: 50px;
        }
        .avatar-button {
          position: relative;
          top: 90px;
        }
        text-shadow: rgba(0, 0, 0, 1) 0px 4px 3px;
      }
      .avatar-content {
        width: 75%;
        height: 90%;
        position: relative;
        top: 20px;
        left: 20px;
        background-color: #3b0b14;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        overflow-y: scroll;
        .avatar-content-item {
          width: 20%;
          height: 190px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          margin-bottom: 60px;
          .avatar {
            position: relative;
            top: 60px;
          }
          .select-icon {
            position: absolute;
            top: 63px;
            height: 184px;
            width: 184px;
            background-color: rgb(37 145 64 / 41%);
            border-radius: 100%;
          }
        }
      }
    }
    .frame {
      width: 100%;
      height: 100%;
      display: flex;
      .frame-profile {
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 2px solid #6f1923;
        .avatar {
          position: relative;
          top: 50px;
        }
        .profil-button {
          position: relative;
          top: 100px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          .orange-content {
            height: 45px;
            width: 45px;
            border-radius: 23px;
            background: rgb(127, 30, 29);
            background: linear-gradient(0deg, #f49648 0%, #f17e32 100%);
            border: solid 1px #1e0809;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
          }
          .profil-button-chip {
            min-width: 200px;
            font-size: 32px;
            color: #ffffff;
          }
        }
        .frame-button {
          position: relative;
          top: 140px;
        }
        text-shadow: rgba(0, 0, 0, 1) 0px 4px 3px;
      }
      .frame-content {
        width: 75%;
        height: 90%;
        position: relative;
        top: 20px;
        left: 20px;
        background-color: #3b0b14;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: scroll;
        padding-top: 60px;
        &-avatar {
          position: relative;
          width: 20%;
          height: 190px;
          display: flex;
          justify-content: center;
          align-items: center;
          .selected-icon {
            position: absolute;
            height: 190px;
            width: 190px;
            background-color: rgb(37 145 64 / 41%);
            border-radius: 100%;
          }
        }
        .desc {
          width: 100%;
          height: 100%;
          font-size: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    // .hand-history {
    //   width: 100%;
    //   height: 100%;
    //   display: flex;
    //   .content {
    //     width: 100%;
    //     height: 100%;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     .hand-history-header {
    //       width: 100%;
    //       height: 100px;
    //       display: flex;
    //       flex-direction: row;
    //       z-index: 2;
    //       &-tab {
    //         width: 70%;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         &s {
    //           width: 900px;
    //           height: 60px;
    //           background-color: rgba(#000000, 0.5);
    //           border-radius: 10px;
    //           display: flex;
    //           flex-direction: row;
    //           &-item {
    //             width: 33.3333%;
    //             display: flex;
    //             justify-content: center;
    //             align-items: center;
    //             font-size: 36px;
    //             text-shadow: 0 2px 3px #000000;
    //           }
    //           .active {
    //             background: rgb(233, 107, 44);
    //             background: linear-gradient(
    //               0deg,
    //               rgba(233, 107, 44, 1) 0%,
    //               rgba(244, 138, 48, 1) 100%
    //             );
    //             border-radius: 10px;
    //           }
    //         }
    //       }
    //       &-date {
    //         width: 30%;
    //       }
    //     }
    //     .hand-history-top {
    //       width: 100%;
    //       height: 100px;
    //       display: flex;
    //       flex-direction: column;
    //       &-title {
    //         display: flex;
    //         flex-direction: row;
    //         justify-content: center;
    //         font-size: 36px;
    //         &-item {
    //           text-align: center;
    //           color: #ffffff;
    //           height: 40px;
    //           &-inner {
    //             display: flex;
    //             flex-direction: row;
    //             width: 45%;
    //             &-item {
    //               text-align: center;
    //               color: #ffffff;
    //               width: 20%;
    //             }
    //           }
    //         }
    //         &-item:nth-child(1) {
    //           width: 18%;
    //         }
    //         &-item:nth-child(3) {
    //           width: 25%;
    //         }
    //       }

    //       .line {
    //         min-height: 3px;
    //         margin-top: 20px;
    //         position: relative;
    //         left: 130px;
    //       }
    //       &-card {
    //         width: 30%;
    //         position: relative;
    //         bottom: -1px;
    //         left: 750px;
    //         display: flex;
    //         flex-direction: row;
    //         height: 130px;
    //         &-item {
    //           width: 34%;
    //           display: flex;
    //           flex-direction: row;
    //           position: relative;
    //           //kaldik
    //           .card {
    //             position: relative;
    //             bottom: 10px;
    //             transform: scale(0.8);
    //           }
    //         }
    //         &-item:first-child {
    //           width: 30%;
    //           left: 25px;
    //         }
    //         &-item:nth-child(2) {
    //           left: 90px;
    //         }
    //         &-item:nth-child(3) {
    //           left: 70px;
    //         }
    //       }
    //     }
    //     .hand-history-content {
    //       height: 70%;
    //       width: 100%;
    //       display: flex;
    //       flex-direction: column;
    //       align-items: center;
    //       margin-top: 80px;
    //       overflow-y: scroll;
    //     }
    //   }
    // }
  }
}

.avatar {
  .ok-icon {
    position: absolute;
    left: 0px;
    top: 0px;
  }
}
.slot-line {
  background: url("~@/assets/images/profile/slot-line.png") no-repeat 50% 50%;
  background-size: contain;
  width: 15%;
  height: 1px;
  margin-top: 7px;
}
</style>
