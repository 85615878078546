<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1450"
    height="950"
    :show="true"
    :stopClickOutside="true"
    titleText="BAKIMDAYIZ"
  >
    <template>
      <div class="maintenance-window">
        <div class="maintenance-text">
          {{ $t("maintenance.tryAgainLater") }}
        </div>
        <div class="maintenance-img sprite-list-pop-up care-image"></div>
        <div class="maintenance-btn ui-button ui-button-small-orange">
          <span>{{ $t("general.okay") }}</span>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";

export default {
  name: "MaintenanceWindow",
  components: {
    BaseWindow,
  },

  methods: {
    closeWindow() {
      this.$store.commit("modals/hide", "maintenance");
    },
  },
};
</script>

<style lang="scss" scoped>
.maintenance-window {
  background: #83212d;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  width: 99%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .maintenance-text {
    color: white;
    font-size: 40px;
    text-shadow: 0px 5px 0px #000000;
  }
  .maintenance-btn {
    width: 227px;
  }
}
</style>
