<template>
  <div class="friends-item" :class="index % 2 == 0 ? 'bg-black' : 'bg-red'">
    <div class="friends-item-online" :class="isOnline ? 'on' : 'off'"></div>
    <div class="friends-item-avatar" @click="getUser(friend.user.id)">
      <Avatar :url="friend.user.avatar" size="90" :crown="friend.user.crown" />
    </div>
    <div class="friends-item-name">{{ friend.user.name }}</div>
    <div class="friends-item-chip">
      <div class="sprite-profile-popup chip"></div>
      <div class="friends-item-chip-amount">
        {{ getShortChipFormat(friend.user.chip) }}
      </div>
    </div>
    <div class="friends-item-table">
      <div
        class="friends-item-table-text"
        v-if="friend.user.room > -1 && friend.user.game"
      >
        {{ friend.user.room }}/{{ friend.user.game }}
      </div>
    </div>
    <div class="delete-icon" @click="deleteDialog = true"></div>
    <div class="chat-icon" @click="sendMessage"></div>
    <div class="chip-icon" @click="sendChip"></div>
    <div
      class="friends-item-go-table ui-button ui-button-small-orange"
      :class="
        friend.user.room > -1 &&
        (friend.user.game === this.$constant.gameType.TEXAS ||
          friend.user.game === this.$constant.gameType.OMAHA)
          ? 'ui-button-small-orange'
          : 'ui-button-disable'
      "
      @click="goFriendTable(friend)"
    >
      {{ $t("friends.goToTable") }}
    </div>
    <AreYouSureWindow
      :stepCount="1"
      :show="deleteDialog"
      @closeAreYouSure="deleteDialog = false"
      @confirm="removeFriend"
      :content="$t('deleteConfirmWindow.deletefriends')"
      :titleText="$t('socialWindow.removeFriend')"
      :name="friend.user.name"
      :defaultText="defaultText"
    />
  </div>
</template>

<script>
import AreYouSureWindow from "../../AreYouSureWindow.vue";
import { mapGetters } from "vuex";
export default {
  name: "FriendsItem",
  components: {
    Avatar: () => import("@/components/Misc/Avatar"),
    AreYouSureWindow,
  },
  data() {
    return {
      deleteDialog: false,
      defaultText: false,
    };
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    friend: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getShortChipFormat"]),
    getBaseAvatarUrl() {
      return process.env.VUE_APP_AVATAR_URL;
    },
    isOnline() {
      return this.friend.user.online;
    },
  },
  methods: {
    goFriendTable(friend) {
      if (friend.user.room > -1 && friend.user.game) {
        this.$emit("goTable", friend);
      } else {
        this.$plugins.toast.show({
          text: this.$t("others.friendTableNotFound"),
          duration: 4500,
        });
      }
    },
    sendMessage() {
      this.$emit("sendMessage", this.friend.user.id);
      this.$plugins?.audio?.play("Button");
    },
    sendChip() {
      this.$store.commit(
        "chipTransfer/setSendChipWindowData",
        this.friend.user
      );
      this.$store.commit("modals/show", "sendChip");
      this.$plugins?.audio?.play("Button");
      // this.$store.commit("modals/hide", "friendsWindow");
      // this.$store.state.modals.lastOpenedModal = "friendsWindow";
    },
    async removeFriend() {
      if (this.friend.user.id) {
        const friendRemoveResponse = await this.$store.dispatch(
          "friends/removeFriend",
          this.friend.user.id
        );
        if (friendRemoveResponse && friendRemoveResponse == true) {
          this.$store.commit(
            "friends/friendListRemoveItem",
            this.friend.user.id
          );
        }
        this.deleteDialog = false;
        if (friendRemoveResponse) {
          this.$plugins.toast.show({
            text: this.$t("others.friendRemovedInfo"),
            duration: 2500,
          });
        }

        this.$plugins.audio.play("Button");
      } else {
        this.$plugins.toast.show({
          text: this.$t("others.notFoundFriend"),
          duration: 2500,
        });
      }
    },
    async getUser(userid) {
      if (userid) {
        if (parseInt(userid) === this.$store.state.user.id) {
          return this.$plugins.toast.show({
            text: this.$t("searchWindow.ownId"),
            duration: 2000,
            variant: "danger",
          });
        }
        this.loading = true;
        const res = await this.$store.dispatch("friends/getProfiles", [userid]);
        if (!res) {
          this.$plugins.toast.show({
            text: this.$t("confirmWindow.userNotFound"),
            duration: 2000,
            variant: "danger",
          });
          this.loading = false;
          return;
        }
        this.$store.commit("friends/setSelectedUserModal", res[0]);
        this.loading = false;
        this.$store.commit("modals/show", "friendDetails");
      } else {
        return this.$plugins.toast.show({
          text: this.$t("searchWindow.validId"),
          duration: 2000,
          variant: "danger",
        });
      }
    },
    closeWindow() {
      if (this.deleteDialog) {
        return;
      }
      this.$refs.friendDetails.classList.add("closeAnimate");
      setTimeout(() => {
        this.$store.commit("modals/hide", "friendDetails");
        this.$store.commit("friends/clearSelectedUserModal");
        if (this.$store.state.modals.lastOpenedModal !== "") {
          this.$store.commit(
            "modals/show",
            this.$store.state.modals.lastOpenedModal
          );
          this.$store.state.modals.lastOpenedModal = "";
        }
      }, 700);
    },
  },
};
</script>

<style lang="scss" scoped>
.friends-item {
  width: 99%;
  min-height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
  align-items: center;
  font-size: 34px;
  &-online {
    height: 80px;
    width: 6px;
    border-radius: 100px;
    background-color: #ffffff;
    margin: 0px 20px;
  }
  .on {
    background-color: #47930b;
  }
  .off {
    background-color: #d20f0e;
  }
  &-avatar {
    width: 9%;
  }
  &-name {
    min-width: 12%;
  }
  &-chip {
    width: 250px;
    height: 80px;

    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 36px;
    margin: 0 30px;
    .chip {
      position: relative;
      top: 3px;
      transform: scale(1.5);
    }
    &-amount {
      position: relative;
      left: 30px;
    }
  }
  &-table {
    width: 17%;
    height: 80px;

    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 40px;
  }
  .delete-icon {
    background: url("~@/assets/images/profile/delete-button.png");
    width: 83px;
    height: 86px;
    margin-right: 70px;
    cursor: pointer;
  }
  .chip-icon {
    background: url("~@/assets/images/buttons/send-chip-button.png") no-repeat
      50% 50%;
    width: 83px;
    height: 86px;
  }
  .chat-icon {
    background: url("~@/assets/images/buttons/chat-button.png") no-repeat 50%
      50%;
    width: 83px;
    height: 86px;
  }
  .chip-icon {
    margin-right: 70px;
    cursor: pointer;
  }
  .chat-icon {
    margin-right: 70px;
    cursor: pointer;
  }

  &-go-table {
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 0 3px 5px #000000;
    color: #ffffff;
  }
}
</style>
