<template>
  <div class="message-container">
    <div class="direct-message" :class="selected ? 'active' : ''">
      <div class="unread-indicator" v-show="conversation.unread"></div>
      <div class="player-avatar">
        <Avatar
          :url="conversation.user.avatar"
          :crown="conversation.user.crown"
          size="100"
          @click.native.stop="openUserWindow"
        />
      </div>
      <div class="player-info">
        <div class="user-name">{{ conversation.user.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Misc/Avatar";

export default {
  name: "DirectMessage",
  components: {
    Avatar,
  },
  props: {
    conversation: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      show: true,
      item: 1,
    };
  },
  methods: {
    openUserWindow() {
      this.$store.state.modals.lastOpenedModal = "chat";
      this.$store.commit("modals/hide", "chat");
      this.$store.dispatch(
        "modals/openUserDetailWindow",
        this.conversation.user.id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.message-container {
  position: relative;
  width: 98%;
  .direct-message {
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(183, 57, 75, 1) 0%,
      rgba(141, 33, 49, 1) 55%
    );
    border-radius: 20px;
    width: 340px;
    height: 120px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    margin-left: 20px;
    align-items: center;
    .unread-indicator {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 5px;
      right: 5px;
      border-radius: 50%;
      background: red;
    }
    &.active {
      opacity: 0.8;
    }
    .player-info {
      align-items: flex-start;
      display: flex;
      width: 65%;
      height: 80%;
      flex-direction: column;
      font-weight: bold;
      justify-content: space-around;
      .user-name {
        font-size: 32px;
        color: #fff;
        text-shadow: 3px 3px 3px black;
      }
    }
    .chat-icon {
      width: 80px;
      height: 80px;
      margin-left: 10px;
    }
    .player-avatar {
      margin: 20px 20px;
      flex-shrink: 0;
    }
  }
}
</style>
