export default {
  friendsList: {
    total: 0,
    list: [],
  },
  requestsList: {
    total: 0,
    list: [],
  },
  inviteList: [],
  selectedUserModal: {},
  receivedFriendRequest: {},
};
