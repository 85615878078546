import SlotsConstants from "@/constants/slots";

export default {
  playing: "",

  /*
   * Turn Legends
   */
  turnLegends: {
    version: "",
    url: process.env.VUE_APP_SLOTS_LEGENDS_URL,
    zip: "",
    progress: 0,
    playableLink: "",
    status: SlotsConstants.status.AVAILABLE,
  },

  /*
   * Bamboo Panda
   */
  bambooPanda: {
    version: "",
    url: process.env.VUE_APP_SLOTS_PANDA_URL,
    zip: "",
    progress: 0,
    playableLink: "",
    status: SlotsConstants.status.AVAILABLE,
  },

  /*
   * Fafafa
   */
  fafafa: {
    version: "",
    url: process.env.VUE_APP_SLOTS_FAFAFA_URL,
    zip: "",
    progress: 0,
    playableLink: "",
    status: SlotsConstants.status.AVAILABLE,
  },

  olympus: {
    version: "",
    url: process.env.VUE_APP_SLOTS_OLYMPUS_URL,
    zip: "",
    progress: 0,
    playableLink: "",
    status: SlotsConstants.status.AVAILABLE,
  },
};
