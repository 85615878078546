<template>
  <div class="dealer-cards-perspective">
    <div class="dealer-cards">
      <Card
        v-for="(card, cardIndex) in getDealerCards"
        :key="'seat-card-' + cardIndex"
        :index="card.index"
        :value="card.value"
        :suit="card.suit"
        :class="[$store.state.service.ACTIVE_ROOM.cardColor]"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Card from "../Card";

export default {
  name: "Cards",
  components: { Card },
  computed: {
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    getDealerCards() {
      return this.getActiveRoomState.dealer.cards;
    },
  },
};
</script>

<style scoped lang="scss">
.dealer-cards-perspective {
  perspective: 900px;
  -webkit-perspective: 900px;
  will-change: auto;
}
.dealer-cards {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(40deg);
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: auto;
  .card {
    transform: scale(1.1) translate3d(0, 0, 0);
    margin-left: -20px;
    z-index: 2;
    will-change: auto;
    &:last-child {
      transform: scale(1.1) translateZ(0.2px) translate3d(0, 0, 0);
    }
    &.flipped {
      transform: scale(1.1) translateX(50px) translate3d(0, 0, 0);
    }
  }
}
</style>
