<template>
  <BaseWindow
    width="1450"
    height="950"
    :show="true"
    :showCloseButton="false"
    class="disconnect"
    :titleText="$t('disconnectedWindow.disconnected')"
  >
    <template>
      <div class="disconnect-window">
        <div class="disconnect-icon"></div>
        <div class="disconnect-window-paragraph">
          {{ $t("disconnectedWindow.disconnectedServer") }}
        </div>
        <div class="disconnect-window-footer">
          <div
            type="button"
            class="ui-button ui-button-orange"
            @click="refreshApp"
          >
            {{ $t("disconnectedWindow.reconnect") }}
          </div>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";

export default {
  name: "DisconnectWindow",
  data() {
    return {};
  },
  components: {
    BaseWindow,
  },
  methods: {
    onShowDisconnectWindow() {
      this.$data.show = true;
    },
    refreshApp() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.disconnect {
  z-index: 9999999;
  .disconnect-window {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background: #83212d;
    .disconnect-icon {
      width: 400px;
      height: 400px;
      background-image: url("~@/assets/images/lobby/wifi.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    &-footer {
      width: 100%;
      text-align: center;
    }
    &-paragraph {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 48px;
      height: 300px;
    }
  }
}
</style>
