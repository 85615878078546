<template>
  <div
    class="chat"
    :class="[$parent.showChat ? 'show' : '']"
    v-click-outside="onClickedOutside"
  >
    <div class="chat-header">
      <span>{{ $t("general.chat") }}</span>
      <button
        type="button"
        class="close-button flex-center"
        @click="$parent.showChat = false"
      >
        X
      </button>
    </div>
    <div class="chat-body">
      <div
        v-for="(message, messageIndex) in getMessages"
        :key="'chat-message-' + messageIndex"
        class="chat-message"
        :class="[
          message.sender.id === player.id ? 'me' : '',
          isPlayerVip(message.sender.id) ? 'vip' : '',
        ]"
      >
        <div class="chat-content">
          <strong>{{ message.sender.name }}:&nbsp;</strong
          ><span>{{ message.message }}</span>
        </div>
      </div>
    </div>
    <div class="chat-footer">
      <input
        type="text"
        maxlength="255"
        class="chat-input"
        :placeholder="chatMessageInputPlaceholderText"
        v-model="message"
        :disabled="blocked"
        @keyup.enter="send"
      />
      <button type="button" class="chat-send-button" @click="send"></button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import EventBus from "@/event-bus";

const CHAT_BLOCK_SECONDS = 2;

export default {
  name: "Chat",
  directives: { ClickOutside },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    player() {
      return this.user;
    },
    getMessages() {
      return [...this.messages];
    },
    chatMessageInputPlaceholderText() {
      if (this.blocked)
        return this.$t("blackJack.xSecondsLeft").replace(
          "{seconds}",
          this.blockCountdown
        );
      return this.$t("chatWindow.typeHere");
    },
  },
  data: () => ({
    messages: [],
    message: "",
    blocked: false,
    blockCountdown: 2,
    blockInterval: 0,
    scrollLocked: false,
  }),
  methods: {
    async send() {
      if (this.blocked) return false;

      const response = await this.$store.dispatch("chat/sendRoomMessage", {
        game: "blackjack",
        room: 3013,
        message: this.message,
      });

      if (response !== false) {
        this.message = "";
        if (this.player.isVip) return false;
        this.blocked = true;
        this.blockCountdown = CHAT_BLOCK_SECONDS;
        if (this.blockInterval) clearInterval(this.blockInterval);
        this.blockInterval = setInterval(() => {
          this.blockCountdown--;
          if (this.blockCountdown < 1) {
            clearInterval(this.blockInterval);
            document.querySelector(".chat-input")?.focus();
            this.blocked = false;
          }
        }, 1000);
        return true;
      }

      return false;
    },
    isPlayerVip(playerId) {
      const player = this.getActiveRoomState.players.find(
        (player) => player.id === playerId
      );
      if (typeof player === "undefined") return false;
      return player.vip;
    },
    scrollToBottom() {
      if (this.scrollLocked) {
        return false;
      }

      const chatScrollingBox = this.$el.querySelector(".chat-body");
      if (chatScrollingBox) {
        chatScrollingBox.scrollTop = chatScrollingBox.scrollHeight;
      }
    },
    onScrolled(element) {
      if (
        element &&
        element.target &&
        element.target.offsetHeight + element.target.scrollTop >=
          element.target.scrollHeight
      ) {
        this.scrollLocked = false;
        return;
      }
      this.scrollLocked = true;
    },
    onChatMessageReceived(data) {
      if (this.messages.length > 30) this.messages.splice(0, 1);

      if (!this.$parent.showChat) this.$parent.unreadMessages = true;

      const player = this.getActiveRoomState.players.find(
        (p) => p.id === data.sender
      );
      if (!player) return false;

      this.messages.push({
        sender: {
          id: player.id,
          name: player.name,
        },
        message: data.message,
      });
    },
    onClickedOutside($event) {
      if (typeof $event?.target?.className.includes === "undefined")
        return false;
      if (this.$store.state.platform !== "mobile") return false;
      if ($event?.target?.className.includes("chat-button")) return false;
      this.$parent.showChat = false;
    },
  },
  mounted() {
    const chatScrollingBox = this.$el.querySelector(".chat-body");
    if (chatScrollingBox) {
      chatScrollingBox.scrollTop = chatScrollingBox.scrollHeight;
      chatScrollingBox.addEventListener("scroll", this.onScrolled.bind(this));
    }
  },
  beforeDestroy() {
    const chatScrollingBox = this.$el.querySelector(".chat-body");
    chatScrollingBox.removeEventListener(
      "scroll",
      this.onScrolled.bind(this),
      true
    );
    EventBus.$off(
      "onRoomChatMessageReceived",
      this.onChatMessageReceived.bind(this)
    );
  },
  created() {
    EventBus.$on(
      "onRoomChatMessageReceived",
      this.onChatMessageReceived.bind(this)
    );
  },
  updated() {
    this.scrollToBottom();
  },
};
</script>

<style scoped lang="scss">
.chat {
  position: absolute;
  top: -5px;
  right: 0;
  width: 600px;
  height: 1080px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgba(lighten(black, 10%), 0.8);
  border-radius: 0;
  z-index: 12;
  overflow: hidden;
  transform: translateY(calc(-100% - 60px));
  opacity: 0;
  transition: all 0.25s ease;
  &.show {
    transform: translateY(0);
    opacity: 1;
  }
  &-header {
    width: 100%;
    height: 90px;
    line-height: 90px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px calc(10px + env(safe-area-inset-right)) 10px 10px;
    font-weight: bold;
    font-size: 32px;
    background-color: rgba(black, 0.7);
    .close-button {
      border: 0;
      padding: 0;
      background-color: transparent;
      color: rgb(255, 255, 255);
      font-weight: bold;
      font-size: 32px;
      transition: color 0.25s ease;
      cursor: pointer;
      &:hover {
        color: rgba(rgb(255, 255, 255), 0.7);
      }
    }
  }
  &-body {
    position: relative;
    height: calc(100% - 70px);
    box-sizing: border-box;
    padding-top: 5px;
    padding-right: calc(10px + env(safe-area-inset-right));
    overflow-x: hidden;
    overflow-y: auto;
    .chat-message {
      width: 100%;
      color: rgb(255, 255, 255);
      padding-right: 7px;
      padding-left: 7px;
      padding-bottom: 7px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      box-sizing: border-box;
      .chat-content {
        min-width: 40%;
        max-width: 80%;
        font-size: 13px;
        padding: 20px;
        font-size: 24px;
        max-width: 80%;
        border-radius: 5px;
        background-color: lighten($containerLightColor, 10%);
      }
      &.me {
        justify-content: flex-end;
        .chat-content {
          text-align: right;
          color: black;
          background-color: $greenColor;
        }
      }
      &.vip {
        .chat-content {
          box-shadow: 0 0 0 1px rgba(rgb(255, 255, 255), 0.5),
            0 0 8px -2px rgb(255, 255, 255);
        }
      }
    }
  }
  &-footer {
    position: relative;
    padding-left: 7px;
    padding-right: calc(7px + env(safe-area-inset-right));
    padding-bottom: 7px;
    .chat-input {
      width: 100%;
      box-sizing: border-box;
      background-color: rgba(black, 0.8);
      border: 0;
      box-shadow: inset 0 0 0 2px rgba(rgb(255, 255, 255), 0.7);
      color: rgb(255, 255, 255);
      padding: 32px;
      font-size: 24px;
      border-radius: 5px;
      transition: all 0.25s ease;
      &:focus {
        box-shadow: inset 0 0 0 2px rgb(255, 255, 255);
      }
      &::placeholder {
        color: rgba(rgb(255, 255, 255), 0.5);
      }
    }
    .chat-send-button {
      position: absolute;
      right: 20px;
      top: 16px;
      width: 64px;
      height: 64px;
      background-color: transparent;
      background-image: url("~@/assets/images/blackjack/icons/send-icon.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 65%;
      border: 0;
    }
  }
}
</style>
