import Logger from '@/logger'

export default {

    async install(Vue, plugins = []) {
        if (plugins.length === 0) return false;

        Vue.prototype.$plugins = {}

        for (let i = 0; i < plugins.length; i++) {
            try {
                const plugin = await import(`./${plugins[i]}/index.js`);
                if (plugin.default) {
                    if (plugin.default?.init)
                        await plugin.default.init()
                    Vue.use(plugin.default);
                }
                Logger.print('info', `${plugins[i]} plugin attached to Vue instance.`);
            } catch (error) {
                Logger.print('error', `${plugins[i]} could not be loaded!`)
                Logger.print('error', error)
            }
        }
    }

}