<template>
  <div class="insurance-buttons">
    <button
      type="button"
      class="insurance-button insurance-yes"
      @click="insurance(true)"
      :disabled="clicked || nGetMySeat.stack < nGetMySeat.bet.main / 2"
    >
      <span class="icon"></span>
      <span class="text">{{ $t("general.yes") }}</span>
    </button>
    <button
      type="button"
      class="insurance-button insurance-no"
      @click="insurance(false)"
      :disabled="clicked"
    >
      <span class="icon"></span>
      <span class="text">{{ $t("general.no") }}</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InsuranceButtons",
  computed: {
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
    }),
  },
  data() {
    return {
      clicked: false,
    };
  },
  methods: {
    insurance(yes = false) {
      this.clicked = true;
      if (yes === true) {
        this.$plugins.audio.play("BjClick");
        this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send(
          "insurance"
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.insurance-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 180px;
  .insurance-button {
    position: relative;
    width: 136px;
    height: 97px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: transparent;
    background-image: linear-gradient(
      0deg,
      rgb(255, 255, 255) 0%,
      darken(rgb(255, 255, 255), 20%) 30%,
      rgb(255, 255, 255) 70%,
      darken(rgb(255, 255, 255), 20%) 100%
    );
    margin-right: 40px;
    border-radius: 8px;
    padding: 0;
    border: 0;
    box-shadow: 0 0 0 4px rgba(rgb(255, 255, 255), 0.3);
    opacity: 0.9;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 18px;
    text-shadow: 0 1px 0 black;
    cursor: pointer;
    transition: all 0.25s ease;

    &:disabled {
      opacity: 0.5;
    }

    .icon {
      display: block;
      width: 40px;
      height: 42px;
      font-size: 48px;
      background-position: center;
    }

    .text {
      display: block;
    }

    .percent {
      position: absolute;
      left: 0;
      bottom: -25px;
      font-size: 12px;
      width: 100%;
      text-align: center;
    }

    &:not(:disabled) {
      &:hover {
        opacity: 1;
        transform: scale(1.05);
      }
      &:active {
        opacity: 1;
        transform: scale(0.95);
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &.insurance-yes {
      box-shadow: 0 0 0 5px rgba(#20cf68, 0.5);
      background-image: linear-gradient(
        0deg,
        #22ab59 0%,
        #208849 30%,
        #20cf68 100%
      );
      .icon {
        background-image: url("~@/assets/images/blackjack/icons/insurance-active.png");
        background-repeat: no-repeat;
      }
    }
    &.insurance-no {
      box-shadow: 0 0 0 5px rgba(#bd2531, 0.5);
      background-image: linear-gradient(
        0deg,
        #b83634 0%,
        #932122 30%,
        #bd2531 100%
      );
      .icon {
        background-image: url("~@/assets/images/blackjack/icons/insurance-pasive.png");
        background-repeat: no-repeat;
      }
    }
  }
}

.platform-mobile {
  .insurance-buttons {
    margin-top: -25px;
  }
}
</style>
