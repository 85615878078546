<template>
  <div>
    <div id="app" :class="getAppClasses" :style="getAppStyles">
      <div id="app-content" :style="getAppContentStyles">
        <!-- <keep-alive
          :exclude="[
            'Empty',
            'Error',
            'ConnectionError',
            'UnderMaintenance',
            'Loading',
            'Authentication',
            'Lobby',
            'PokerTable',
            'Blackjack',
          ]"
        > -->
        <component
          v-if="
            scene !== 'SlotGame' &&
            (!$store.state.modals.leaderBoard ||
              !$store.state.modals.userDetails)
          "
          :is="scene"
          ref="dynamicComponent"
        />
        <!-- </keep-alive> -->
        <GlobalWindows @slotPlay="slotPlay" />
      </div>
    </div>
    <Toast />
    <FriendInviteToast @slotPlay="slotPlay" />
    <SlotGame v-if="scene === 'SlotGame'" />
  </div>
</template>

<script>
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { Capacitor } from "@capacitor/core";
import { mapState, mapGetters } from "vuex";

import APP from "@/constants/app";
import SCENES from "@/constants/scenes";

import { App } from "@capacitor/app";
import packageJson from "@/../package.json";

export default {
  name: "App",
  components: {
    ...SCENES,
    SlotGame: () => import("./components/Scenes/SlotGame"),
    GlobalWindows: () =>
      import("./components/Misc/GlobalWindows/GlobalWindows"),
    Toast: () => import("./components/Misc/Toast"),
    FriendInviteToast: () => import("./components/Misc/FriendInviteToast"),
  },
  watch: {
    "$store.state.config.force_update": {
      handler(forceUpdateVal) {
        if (forceUpdateVal) {
          const currentAppVersion = parseInt(
            packageJson.version.replaceAll(".", "")
          );
          const controlPanelMobileAppVersion = parseInt(
            this.$store.state.config.mobile_application_version.replaceAll(
              ".",
              ""
            )
          );
          if (
            currentAppVersion &&
            controlPanelMobileAppVersion &&
            forceUpdateVal &&
            this.getRealPlatform !== "web" &&
            controlPanelMobileAppVersion > currentAppVersion
          ) {
            //force update
            this.$store.commit("modals/show", "forceUpdateWindow");
            return;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    async scene(newValue, oldValue) {
      if (
        newValue === "Lobby" &&
        oldValue === "SlotGame" &&
        !this.$store.state.service.isLobbyConnected
      ) {
        this.$store.dispatch("service/connectToLobby");
      }
      if (
        newValue === "Lobby" &&
        oldValue === "Authentication" &&
        this.loginCount === 0
      ) {
        const response = await this.$store.dispatch("user/userOnlinePost");
        if (response.loginCount === 1) {
          this.$store.commit("modals/show", "updateNotesWindow");
        }
        this.loginCount++;
      }
    },
    // "$store.state.preferences.musicValue": {
    //   handler(val) {
    //     if (!this.musicElement) {
    //       this.musicElement = document.getElementById("lobby_music");
    //     }
    //     setTimeout(() => {
    //       if (val) {
    //         this.musicElement?.play();
    //       } else {
    //         this.musicElement?.pause();
    //       }
    //     }, 2000);
    //   },
    //   immediate: true,
    // },
  },
  computed: {
    ...mapState(["scene", "platform"]),
    ...mapGetters([
      "setScaleAmount",
      "preferences/getValue",
      "service/isRoomJoinAndDisconnectOn",
    ]),
    ...mapGetters({
      getRealPlatform: "getRealPlatform",
    }),
    getAppClasses() {
      const classes = [];

      classes.push(`platform-${this.platform}`);
      classes.push(`scene-${this.scene}`);
      if (this.scene === "PokerTable") {
        const tableBackground =
          this["preferences/getValue"]("background_color");
        classes.push(`scene-${this.scene}-${tableBackground}`);
      }

      if (APP.mode === "staging") classes.push("debug");

      return classes;
    },
    getAppStyles() {
      const styles = {};

      styles.width = `${APP.game.width}px`;
      styles.height = `${APP.game.height}px`;
      styles.backgroundSize = `cover`;
      styles.backgroundRepeat = "no-repeat";

      const x = this.$data.window.width / APP.game.safe.width;
      const y = this.$data.window.height / APP.game.safe.height;
      const scaleAmount = Math.min(x, y);

      styles.backgroundPosition = "center !important";
      if (x !== y) {
        styles.backgroundSize = `${76}% !important`;
      } else {
        styles.backgroundSize = `${75}% !important`;
      }

      styles.transform = `translate(-50%, -50%) scale(${scaleAmount}) translate3d(0,0,0)`;

      this.setScaleAmount("main", scaleAmount);

      return styles;
    },
    getAppContentStyles() {
      return {};
    },
    isInFacebook() {
      let searchIndex = window.location.href.indexOf("facebook");
      if (searchIndex > -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      musicElement: null,
      soundOldValue: false,
      musicOldValue: false,
      loginCount: 0,
    };
  },
  methods: {
    slotPlay(slotName) {
      if (this.$refs.dynamicComponent.$refs.hasOwnProperty("slotSelection")) {
        this.$refs.dynamicComponent.$refs.slotSelection.downloadOrPlay(
          slotName
        );
      }
    },
    onResize($event) {
      this.$data.window.width = Number($event.target.innerWidth);
      this.$data.window.height = Number($event.target.innerHeight);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize.bind(this));
    this.musicElement?.pause();
    if (this.getRealPlatform !== "web") {
      App.removeEventListener("appStateChange", this, false);
    }
  },
  created() {
    window.addEventListener("resize", this.onResize.bind(this));
  },
  async mounted() {
    if (Capacitor.getPlatform() === "web") {
      await FacebookLogin.initialize({
        appId: process.env.VUE_APP_FACEBOOK_APP_ID,
      });
    }

    if (Capacitor.getPlatform() === "ios") {
      const trackingStatus =
        await this.$plugins.appTrackingTransparency.getStatus();
      if (trackingStatus.status !== "authorized") {
        await this.$plugins.appTrackingTransparency.requestPermission();
      }
    }

    App.addListener("appStateChange", ({ isActive }) => {
      if (this.getRealPlatform === "web") return;

      if (!isActive) {
        this.soundOldValue = this.$store.state.preferences.soundValue;
        this.musicOldValue = this.$store.state.preferences.musicValue;
        this.$store.state.preferences.soundValue = false;
        this.$store.state.preferences.musicValue = false;
      } else {
        this.$store.state.preferences.soundValue = this.soundOldValue;
        this.$store.state.preferences.musicValue = this.musicOldValue;
      }
    });

    // const langObj = await DeviceManager.instance.getLanguageCode();
    // this.$store.state.language = langObj?.value;

    // if (this.$store.state.language) {
    //   i18n.changeLocale(`${this.$store.state.language.substring(0, 2)}`);
    // }
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/ui";

.no-flick {
  transform: translate3d(0.1px, 0.1px, 0.1px);
  backface-visibility: hidden;
}

#app {
  position: fixed;
  top: 50%;
  left: 50%;
  color: rgb(255, 255, 255);
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0) translateZ(0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  &.debug {
    box-shadow: inset 0 0 0 8px red;
  }
}

#app-content {
  width: 100%;
  height: 100%;
  color: rgb(255, 255, 255);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scene-fade-enter-active,
.scene-fade-leave-active {
  transition: opacity 0.1s linear;
}
.scene-fade-enter,
.scene-fade-leave-to {
  opacity: 0;
}
</style>
