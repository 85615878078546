<template>
  <div class="top-player" v-bind="$attrs">
    <Avatar
      :crown="player.crown"
      :url="player.avatar"
      :size="playerNo === '1' ? '145' : '115'"
      :lazy="true"
    />
    <div class="top-player-right">
      <div class="top-player-right-flag">
        <div class="country" :style="getFlagSource(player.country)"></div>
      </div>
      <div class="top-player-right-text">Top {{ playerNo }}</div>
    </div>
    <div
      class="top-player-bottom sprite-profile-popup"
      :class="`top${playerNo}-info`"
    >
      <div class="top-player-bottom-name" :class="`top-${playerNo}`">
        {{ player.name }}
      </div>
      <div class="top-player-bottom-chip">
        <div
          class="top-player-bottom-chip-icon sprite-profile-popup chip"
        ></div>
        {{ getChipFormat(player.chip) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TopPlayer",
  components: {
    Avatar: () => import("@/components/Misc/Avatar"),
  },
  props: {
    playerNo: {
      type: String,
      default: "0",
    },
    player: {
      type: [Object, Boolean],
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getChipFormat"]),
    getBaseAvatarUrl() {
      return process.env.VUE_APP_AVATAR_URL;
    },
  },
  methods: {
    getFlagSource(country) {
      let styles = {};
      styles.width = "100px";
      styles.height = "100px";
      styles.marginLeft = "10px";
      if (!country || country == "undefined") return styles;
      styles.backgroundImage = `url(${require("@/assets/images/flags/" +
        country.toUpperCase() +
        ".png")}`;
      styles.backgroundSize = "contain";
      styles.backgroundRepeat = "no-repeat";
      styles.backgroundPosition = "center";
      return styles;
    },
  },
};
</script>

<style lang="scss" scoped>
.top-player {
  position: relative;
  bottom: 50px;
  &-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-text {
      font-size: 36px;
      color: #ffffff;
      position: relative;
      top: -10px;
      left: 5px;
    }
    .country {
      z-index: -1;
      position: relative;
      left: 15px;
      width: 100px;
      height: 100px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-bottom {
    position: absolute;
    top: 150px;
    font-size: 34px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-chip {
      &-icon {
        position: relative;
        top: 6px;
        right: 3px;
      }
    }
    &-name {
      color: #f5a325;
    }
  }
}
</style>
