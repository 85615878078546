<template>
  <div class="tip-button-outer">
    <div class="tip-text-popup" v-if="lastTipper != ''">
      <div class="tip-text-popup-inner">
        {{ $t("tipButton.thankYou") }},<br />{{ lastTipper }}
      </div>
    </div>
    <button
      type="button"
      class="sprite-table tip-button"
      :disabled="!ableToShow"
      @click="sendTip"
    ></button>
  </div>
</template>

<script>
import EventBus from "@/event-bus";
import { mapGetters } from "vuex";

export default {
  name: "TipButton",
  computed: {
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
      nAmISitting: "service/nAmISitting",
      nGetMySeat: "service/nGetMySeat",
    }),
    ableToShow() {
      return (
        !this.clicked &&
        this.nAmISitting &&
        this.nGetMySeat.stack > this.getActiveRoomState.bet * 2
      );
    },
  },
  watch: {
    "getActiveRoomState.stage": function (currentStage) {
      if (currentStage !== 5) {
        this.$data.lastTipper = "";
        return false;
      }
      this.$data.clicked = false;
    },
  },
  data() {
    return {
      lastTipper: "",
      clicked: false,
    };
  },
  methods: {
    sendTip() {
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("tip");
      this.$data.clicked = true;
    },
    onTipCame({ player }) {
      this.$data.lastTipper = player.name;
      // if (this.$data.clicked) this.$plugins.audio.play("ParrotThankYou");
    },
  },
  beforeDestroy() {
    EventBus.$off("onTipCame", this.onTipCame.bind(this));
  },
  created() {
    EventBus.$on("onTipCame", this.onTipCame.bind(this));
  },
};
</script>

<style lang="scss" scoped>
@keyframes tipIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tip-button-outer {
  position: absolute;
  bottom: 17px;
  left: -55px;
  // z-index: 11;
  button.tip-button {
    position: absolute;
    top: -230px;
    left: 1080px;
    z-index: 3;
    background-color: transparent;
    transition: filter 0.25s ease;
    &:disabled,
    &.disabled {
      filter: brightness(40%);
    }
    &:active {
      filter: brightness(70%);
    }
  }
  .tip-text-popup {
    position: absolute;
    top: -338px;
    right: -1129px;
    height: 122px;
    width: 225px;
    z-index: 9;
    transform: skew(0);
    animation-name: tipIncomingAnimation;
    animation-timing-function: linear;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    background-image: url("~@/assets/images/table/text-baloon1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    .tip-text-popup-inner {
      margin-top: 14px;
      margin-bottom: 20px;
      margin-left: 5px;
      margin-right: 10px;
      width: 210px;
      height: 60px;
      color: white;
      font-size: 26px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }
  }
}
</style>
