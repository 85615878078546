<template>
  <div
    class="jackpot jackpot-window-trigger"
    :class="[`jackpot-${$props.identity}`]"
  >
    <div class="jackpot-normal" v-if="$props.identity === 'system'">
      <div class="sprite-table jp"></div>
      <div class="amount-container">
        <div class="amount">{{ getShortChipFormat($props.amount) }}</div>
      </div>
    </div>
    <div class="" v-if="$props.identity === 'special'">
      <div class="amount-container">
        <div class="amount">{{ getShortChipFormat($props.amount) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "JackpotTableIndicator",
  props: {
    identity: {
      type: [String, Number],
      required: true,
      default: "",
    },
    amount: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(["getShortChipFormat"]),
  },
};
</script>

<style scoped lang="scss">
.jackpot {
  position: relative;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  .amount {
    position: absolute;
  }

  &.jackpot-system {
    .amount-container {
      .amount {
        width: 100%;

        left: 0;
        top: 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        font-size: 40px;
        font-weight: bold;
      }
    }
  }

  &.jackpot-special {
    .amount-container  {
      .amount {
        position: absolute;
        left: 0;
        bottom: 10px;
        width: 100%;
        height: 40px;
        background: linear-gradient(
          90deg,
          transparent 0%,
          rgba(black, 0.2) 10%,
          black 40%,
          black 60%,
          rgba(black, 0.2) 80%,
          transparent 100%
        );
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        font-weight: bold;
      }
    }
  }
  .jackpot-normal {
    display: flex;
    flex-direction: column;
    align-items: center;

    .amount-container {
      display: flex;
      justify-content: center;
      .amount {
        position: relative;
        color: rgb(208, 208, 208);
        font-size: 38px;
        background-color: rgb(0, 0, 0, 0.6);
        height: 40px;
        border-radius: 30px;

        width: 170px;
      }
    }
    .jp {
      transform: scale(1.22);
    }
  }
}
</style>
