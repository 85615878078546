<template>
  <div class="loading-view">
    <div class="bj-logo"></div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      loadingPercent: 5,
    };
  },
};
</script>

<style scoped lang="scss">
.loading-view {
  width: 100%;
  height: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
  box-sizing: border-box;
  .cards-chips {
    width: 333px;
    height: 247px;
    background-image: url("~@/assets/images/blackjack/loading/cards-chips.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
  }
  .bj-logo {
    width: 90%;
    max-width: 800px;
    height: 189px;
    background-image: url("~@/assets/images/blackjack/loading/logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  .loading-bar {
    width: 950px;
    height: 42px;
    border-radius: 50px;
    background: linear-gradient(180deg, #d49b6f, #78563a);
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 0 0 rgba(black, 0.5);
    &-inner {
      width: 100%;
      margin: 0 7px;
      height: 31px;
      border-radius: inherit;
      box-sizing: border-box;
      overflow: hidden;
      background: linear-gradient(0deg, #201c19, #43342c);
    }
    &-progress {
      height: 31px;
      width: 5%;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      background-image: linear-gradient(90deg, #8f1a32, #eb385e);
      box-shadow: 0 0 10px 3px #f14a6d;
      transition: all 0.5s linear;
    }
  }
}
</style>
