<template>
  <div class="table-information-container" v-click-outside="onClickedOutside">
    <div class="table-information-bg">
      <div class="close-container" @click="closeWindow">
        <div class="close-button flex-center">
          <span class="sprite-table-pop-up exit-icon"></span>
        </div>
      </div>
      <div class="table-information-inner-bg">
        <div class="title">{{ $t("general.tableInformation") }}</div>
        <div class="items-container">
          <div class="table-info">
            <div class="left-side">
              <span class="text"
                >{{ $t("tableList.tableId") }}:
                {{ getActiveRoomState.id }}</span
              >
            </div>
            <div class="right-side">
              <span
                >BLIND:
                {{
                  `${getShortChipFormat(
                    getActiveRoomState.bet
                  )} / ${getShortChipFormat(getActiveRoomState.bet * 2)}`
                }}</span
              >
            </div>
          </div>
          <!-- <div class="title">
            <div class="left-side">Profil</div>
            <div class="middle">Giriş</div>
            <div class="right-side">Kazançlar</div>
          </div> -->
          <div class="player-container">
            <template v-if="getTablePlayers.length > 0">
              <div class="players-title">
                {{ $t("tableList.players") }} ({{ getTablePlayers.length }})
              </div>
              <div class="players">
                <div
                  class="avatars"
                  v-for="(player, index) in getTablePlayers"
                  :key="index"
                >
                  <Avatar
                    :size="120"
                    :url="player.avatar"
                    @click.native="showPlayerDetails(player.id)"
                  />
                  <div class="name">{{ player.name }}</div>
                </div>
              </div>
              <!-- <div
                class="item"
                v-for="(player, index) in getTablePlayers"
                :key="index"
              >
                <div class="left-side">{{ player.name }}</div>
                <div class="middle">100M</div>
                <div class="right-side">200M</div>
              </div> -->
            </template>
            <template v-else>
              <div class="no-players">
                {{ $t("giftsWindow.noPlayersAtTable") }}
              </div>
            </template>
            <span class="sprite-table-pop-up red-line"></span>
          </div>
          <div class="audiences-container">
            <div class="audiences-title">
              {{ $t("reportWindow.audiences") }} ({{
                getTableAudiences.length
              }})
            </div>
            <div class="avatars-container">
              <div
                class="avatars"
                v-for="(audience, index) in getTableAudiences"
                :key="`audience-${index}`"
              >
                <Avatar
                  :size="120"
                  :url="audience.avatar"
                  @click.native="showPlayerDetails(audience.id)"
                />
                <div class="name">{{ audience.name }}</div>
              </div>
            </div>
            <span class="sprite-table-pop-up red-line"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
import AnalyticsEvents from "@/plugins/analytics/events";

export default {
  name: "TableInformationWindow",
  components: {
    Avatar: () => import("@/components/Misc/Avatar"),
  },
  directives: { ClickOutside },
  computed: {
    ...mapGetters({
      getShortChipFormat: "getShortChipFormat",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    getTableAudiences() {
      return this.getActiveRoomState.players.filter((player) => {
        return player.position === -1;
      });
    },
    getTablePlayers() {
      return this.getActiveRoomState.players.filter(
        (player) => player.position !== -1
      );
    },
  },
  data() {
    return {
      tableItems: [
        {
          player: "Ali",
          entry: 100,
          amount: -300,
          changeStatus: false,
          avatar:
            "https://turnpoker.ams3.digitaloceanspaces.com/avatar_dev/uploads/user5366523-profileImage",
        },
        {
          player: "Ali",
          entry: 100,
          amount: +300,
          changeStatus: true,
          avatar:
            "https://turnpoker.ams3.digitaloceanspaces.com/avatar_dev/uploads/user5366523-profileImage",
        },
        {
          player: "Ali",
          entry: 100,
          amount: +300,
          changeStatus: true,
          avatar:
            "https://turnpoker.ams3.digitaloceanspaces.com/avatar_dev/uploads/user5366523-profileImage",
        },
        {
          player: "Ali",
          entry: 100,
          amount: -300,
          changeStatus: false,
        },
        {
          player: "Ali",
          entry: 100,
          amount: 300,
          changeStatus: false,
        },
        {
          player: "Ali",
          entry: 100,
          amount: 300,
          changeStatus: false,
        },
        {
          player: "Ali",
          entry: 100,
          amount: 300,
          changeStatus: false,
        },
        // {
        //   player: "Ali",
        //   entry: 100,
        //   amount: +300,
        //   changeStatus: true,
        // },
        // {
        //   player: "Ali",
        //   entry: 100,
        //   amount: 300,
        //   changeStatus: false,
        // },
      ],
    };
  },
  methods: {
    onClickedOutside($event) {
      const excludedClassNames = ["no-click-outside"];

      if (
        excludedClassNames.filter((excludedClassName) =>
          $event?.target?.className.includes(excludedClassName)
        ).length > 0
      )
        return false;
      if ((this.$parent.$parent.$data.showTableInformation = false))
        return false;
      this.$parent.$parent.$data.showTableInformation = false;
    },
    async showPlayerDetails(playerId) {
      if (playerId) {
        if (parseInt(playerId) === this.$store.state.user.id) {
          return this.$plugins.toast.show({
            text: this.$t("searchWindow.ownId"),
            duration: 2000,
            variant: "danger",
          });
        }

        const res = await this.$store.dispatch("friends/getProfiles", [
          playerId,
        ]);
        if (!res) {
          this.$plugins.toast.show({
            text: this.$t("confirmWindow.userNotFound"),
            duration: 2000,
            variant: "danger",
          });
          return;
        }
        this.$store.commit("friends/setSelectedUserModal", res[0]);
        this.$store.commit("modals/show", "friendDetails");
      } else {
        return this.$plugins.toast.show({
          text: this.$t("searchWindow.validId"),
          duration: 2000,
          variant: "danger",
        });
      }
    },
    closeWindow() {
      this.$parent.$parent.$data.showTableInformation = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-information-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 33%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;

  .table-information-bg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(#3b0b14, 0.9);
    .close-container {
      position: absolute;
      top: 5px;
      right: 15px;
      height: 87px;
      width: 100px;
      z-index: 4;
      cursor: pointer;
      .close-button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
        top: 13px;
        right: 15px;
        width: 58px;
        height: 58px;
        border-radius: 100%;
        border: 4px solid #cf707f;
        cursor: pointer;

        transition: all 0.25s ease;
        &:active {
          filter: brightness(60%);
          -webkit-filter: brightness(60%);
        }
        .exit-icon {
          transform: scale(0.7);
        }
      }
    }
    .table-information-inner-bg {
      position: relative;
      border-radius: 22px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      .title {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 45px;
        color: #cf707f;
        text-shadow: 0px 3px 2px black;
        text-transform: uppercase;
        background: rgba(59, 11, 20, 0.9);
      }
      .items-container {
        background: rgba(#60181e, 0.5);
        width: 98%;
        height: 89%;
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
        .table-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          min-height: 70px;
          background: #3b0b14;
          font-size: 32px;
        }
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: transparent;
          width: 100%;
          min-height: 60px;
          font-size: 32px;
        }
        .player-container {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          .players-title {
            font-size: 36px;
            color: #e8672c;
            font-weight: bold;
            text-shadow: 0px 2px 1px black;
            margin-bottom: 10px;
            margin-top: 10px;
          }
          .players {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
            padding: 10px;
            width: 95%;
            .avatars {
              width: 180px;
              height: 170px;
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: column;
              .name {
                font-size: 32px;
              }
            }
          }

          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 70px;
            background: #3b0b14;
            margin-bottom: 10px;
            font-size: 32px;
            font-weight: bold;
            &:first-child {
              background: #1e070a;
              color: #e27e2a;
              text-shadow: 0px 2px 1px black;
            }
          }
          .no-players {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            font-size: 36px;
          }
        }
        .audiences-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          .audiences-title {
            font-size: 36px;
            color: #e8672c;
            font-weight: bold;
            text-shadow: 0px 2px 1px black;
            margin-bottom: 10px;
            margin-top: 10px;
          }
          .avatars-container {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
            padding: 10px;
            width: 95%;
            .avatars {
              width: 180px;
              height: 170px;
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: column;
              .name {
                font-size: 32px;
              }
            }
          }
        }
        .left-side {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 10px;
        }
        .right-side {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          margin-right: 10px;
          &.green {
            color: #3a9c48;
          }
          &.red {
            color: #c9141e;
          }
        }
        .middle {
          width: 33%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
