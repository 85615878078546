<template>
  <div class="global-windows">
    <PlayerDetailsWindow v-if="$store.state.modals.userDetails" />
    <ShopWindow v-show="$store.state.modals.shop" />
    <RulesWindow v-if="$store.state.modals.rules" />
    <ChatWindow v-show="$store.state.modals.chat" />
    <SocialWindow @slotPlay="(slotName) => $emit('slotPlay', slotName)" />
    <BonusWindow v-if="scene === 'Lobby'" />
    <DisconnectWindow v-if="$store.state.modals.disconnect" />
    <AffiliateWindow v-if="$store.state.modals.affiliate" />
    <SendChipWindow v-if="$store.state.modals.sendChip" />
    <FriendsRequestWindow v-if="$store.state.modals.friendRequest" />
    <InboxContactWindow v-if="$store.state.modals.inbox" />
    <FriendDetailsWindow v-if="$store.state.modals.friendDetails" />
    <LeaderBoard v-show="$store.state.modals.leaderBoard" />
    <HistoryWindow v-show="$store.state.modals.history" />
    <CaseWindow v-if="$store.state.modals.userCase" />
    <SecurityWindow v-if="$store.state.modals.security" />
    <SearchUserWindow v-show="$store.state.modals.search" />
    <UseInviteCodeWindow v-if="$store.state.modals.useInviteCode" />
    <EnterPinCodeWindow v-if="$store.state.modals.pinCode" />
    <BannedWindow v-if="$store.state.modals.banned" />
    <InactivityWindow v-if="$store.state.modals.disconnectInactivity" />
    <UpdateNotesWindow v-if="$store.state.modals.updateNotesWindow" />
    <ForceUpdateWindow v-if="$store.state.modals.forceUpdateWindow" />
    <FriendsWindow v-show="$store.state.modals.friendsWindow" />
    <NewSettingsWindow />
    <DailyLoginBonus v-if="$store.state.modals.dailyLoginBonus" />
    <NotificationWindow v-show="$store.state.modals.notificationWindow" />
    <WatchWinWindow v-if="$store.state.modals.watchWinWindow" />
    <MaintenanceWindow v-if="$store.state.modals.maintenance" />
    <SendGiftWindow v-if="$store.state.modals.sendGiftWindow" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "GlobalWindows",
  components: {
    UseInviteCodeWindow: () => import("./UseInviteCodeWindow"),
    PlayerDetailsWindow: () => import("./PlayerDetailsWindow"),
    ShopWindow: () => import("./ShopWindow/index.vue"),
    ChatWindow: () => import("./ChatWindow"),
    SocialWindow: () => import("./SocialWindow"),
    DisconnectWindow: () => import("./DisconnectWindow"),
    BonusWindow: () => import("./BonusWindow"),
    AffiliateWindow: () => import("./AffiliateWindow"),
    SendChipWindow: () => import("./SendChipWindow"),
    FriendsRequestWindow: () => import("./FriendsRequestWindow"),
    InboxContactWindow: () => import("./InboxContactWindow"),
    FriendDetailsWindow: () => import("./FriendDetailsWindow"),
    LeaderBoard: () => import("./LeaderBoard/index"),
    HistoryWindow: () => import("./HistoryWindow"),
    CaseWindow: () => import("./CaseWindow"),
    SecurityWindow: () => import("./SecurityWindow"),
    SearchUserWindow: () => import("./SearchUserWindow"),
    FriendsWindow: () => import("./FriendsWindow/index"),
    EnterPinCodeWindow: () => import("./EnterPinCodeWindow"),
    BannedWindow: () => import("./BannedWindow"),
    InactivityWindow: () => import("./InactivityWindow"),
    UpdateNotesWindow: () => import("./UpdateNotesWindow"),
    ForceUpdateWindow: () => import("./ForceUpdateWindow"),
    NewSettingsWindow: () => import("./NewSettingsWindow.vue"),
    DailyLoginBonus: () => import("./DailyLoginBonus"),
    NotificationWindow: () => import("./NotificationWindow"),
    WatchWinWindow: () => import("./WatchWinWindow"),
    MaintenanceWindow: () => import("./MaintenanceWindow"),
    SendGiftWindow: () => import("./SendGiftWindow/index.vue"),
    RulesWindow: () => import("./RulesWindow"),
  },
  computed: {
    ...mapState(["scene"]),
  },
};
</script>

<style lang="scss" scoped>
.global-windows {
  position: relative;
  top: 0px;
  left: 0px;

  // will-change: contents;
}
</style>
