export default {
  setChipTransferHistory(state, { data, offSet }) {
    if (offSet === 0) {
      state.historyList.list.splice(0, state.historyList.list.length);
    }
    // data.list.sort((a,b) => {
    //     return a.createdAt - b.createdAt
    // })
    data.list.forEach((item) => {
      state.historyList.list.push(item);
    });
    state.historyList.total = data.total;
  },
  setSendChipWindowData(state, data) {
    state.sendChipWindowData = data;
  },
  clearSendChipWindowData(state) {
    state.sendChipWindowData = {};
  },
};
