var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseWindow',{attrs:{"show":_vm.show,"preventClickOutside":""},on:{"closeWindow":_vm.closeWindow}},[[_c('div',{staticClass:"select-pot-window"},[_c('div',{staticClass:"title-part"},[_c('div',{staticClass:"sprite-table-pop-up logo-image"}),_c('div',{staticClass:"chip-container"},[_c('span',{staticClass:"lobby-sprite chip"}),_c('span',{staticClass:"chip-amount"},[_vm._v(_vm._s(_vm.getChipFormat(_vm.user.chip)))])])]),_c('div',{staticClass:"middle"},[_c('div',{staticClass:"slider-desc"},[_vm._v(_vm._s(_vm.$t("selectPotWindow.setQuantity")))]),_c('div',{staticClass:"slider-part"},[_c('div',{staticClass:"slider-part-top"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"entry"},[_vm._v(" "+_vm._s(_vm.$t("selectPotWindow.minEntry"))+" ")]),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.getShortChipFormat(_vm.getMinBuy))+" ")])]),_c('div',{staticClass:"current-chip"},[_c('input',{attrs:{"placeholder":_vm.getChipFormat(_vm.currentAmount),"disabled":"disabled","readonly":""},domProps:{"value":`$ ${_vm.getChipFormat(_vm.currentAmount)}`}})]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"entry"},[_vm._v(" "+_vm._s(_vm.$t("selectPotWindow.maxEntry"))+" ")]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.getShortChipFormat(_vm.getMaxBuy)))])])]),_c('div',{staticClass:"slider-bg"},[_c('div',{staticClass:"circle-button",on:{"click":_vm.decreaseSitAmount}},[_c('span',{staticClass:"sprite-table-pop-up minus-icon"})]),_c('VueSlider',{ref:"slider",attrs:{"width":"1200px","height":"25px","silent":true,"disabled":_vm.getSitAmounts.length <= 1,"min":_vm.getMinBuy,"max":_vm.getMaxBuy,"data":_vm.getSitAmounts,"included":true,"zoom":_vm.getTotalScaleAmount,"dotOptions":{
                style: {
                  width: '109px',
                  height: '109px',
                },
                focusStyle: {
                  boxShadow: '0 0 16px 0 #1f2025',
                  border: '1px solid #1f2025',
                },
                tooltipStyle: {
                  color: 'white',
                  backgroundColor: 'black',
                  borderColor: 'black',
                  fontSize: '12px',
                  padding: '3px 12px',
                },
              },"processStyle":{
                borderRadius: '30px',
                border: '2px solid #8a5f45',
              },"railStyle":{
                background: 'rgba(0,0,0,0.5)',
                border: '1px solid rgba(255,255,255,0)',
              },"adsorb":true,"drag-on-click":true,"tooltip":"none"},on:{"dragging":_vm.onSliderDragging,"drag-end":_vm.onSliderDragEnd},scopedSlots:_vm._u([{key:"dot",fn:function(){return [_c('div',{staticClass:"sprite-table-pop-up level-bar-button"})]},proxy:true}]),model:{value:(_vm.currentAmount),callback:function ($$v) {_vm.currentAmount=$$v},expression:"currentAmount"}}),_c('div',{staticClass:"circle-button",on:{"click":_vm.increaseSitAmount}},[_c('span',{staticClass:"sprite-table-pop-up plus-icon"})])],1)])]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"options"},[_c('div',{staticClass:"auto auto-get",on:{"click":function($event){return _vm.selectOption(0)}}},[_c('div',{staticClass:"auto-top"},[_c('div',{staticClass:"selector"},[_c('span',{class:_vm.selectedOption === 0
                      ? 'sprite-table-pop-up report-icon-orange'
                      : ''})]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("selectPotWindow.autoPurchase"))+" ")])]),_c('div',{staticClass:"auto-description"},[_vm._v(" "+_vm._s(_vm.$t("selectPotWindow.autoPurchaseDescription"))+" ")])]),_c('div',{staticClass:"auto auto-fill"},[_c('div',{staticClass:"auto-top",on:{"click":function($event){return _vm.selectOption(1)}}},[_c('div',{staticClass:"selector"},[_c('span',{class:_vm.selectedOption === 1
                      ? 'sprite-table-pop-up report-icon-orange'
                      : ''})]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("selectPotWindow.autoCharging"))+" ")])]),_c('div',{staticClass:"auto-description"},[_vm._v(" "+_vm._s(_vm.$t("selectPotWindow.autoChargingDescription"))+" ")])])])]),_c('div',{staticClass:"button-container"},[(_vm.user.chip >= _vm.getMinBuy)?_c('div',{staticClass:"sit-button ui-button ui-button-small-orange",on:{"click":_vm.onOkButtonClicked}},[_vm._v(" "+_vm._s(_vm.$t("selectPotWindow.sit"))+" ")]):_vm._e()])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }