<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1000"
    height="600"
    :show="show"
    preventClickOutside
    :loading="loading"
    :titleText="$t('affiliate.affiliateIdTitle')"
  >
    <template>
      <div class="confirm-profile-window">
        <div class="information">
          <div class="player-avatar">
            <Avatar
              class="player-window-avatar"
              :url="user.avatar"
              size="195"
            />
          </div>
          <div class="user-data">
            <p class="name">{{ user.name }}</p>
            <p class="id">ID: {{ user.id }}</p>
          </div>
        </div>
        <div class="buttons">
          <button class="ui-button ui-button-small-red" @click="closeWindow">
            {{ $t("general.cancel") }}
          </button>
          <button class="ui-button ui-button-small-green" @click="confirm">
            {{ $t("general.confirm") }}
          </button>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";
import Avatar from "../Avatar";

export default {
  name: "ProfileConfirmWindow",
  data() {
    return {
      loading: false,
      user: {},
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    userId: {
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
  },
  components: {
    BaseWindow,
    Avatar,
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.$emit("closeWindow");
    },
    closeWindow() {
      this.$emit("closeWindow");
    },
  },
  async mounted() {
    this.$data.loading = true;
    const response = await this.$store.dispatch("user/confirmUser", {
      userId: this.userId,
      type: this.type,
    });
    if (response === 404) {
      this.$plugins.toast.show({
        text: this.$t("confirmWindow.userNotFound"),
        duration: 3000,
        variant: "danger",
      });
      this.$emit("closeWindow");
      return;
    } else if (response === 400 && this.$props.type === "affiliate") {
      this.$plugins.toast.show({
        text: this.$t("confirmWindow.notAffiliate"),
        duration: 3000,
        variant: "danger",
      });
      this.$emit("closeWindow");
      return;
    }
    this.$data.user = response;
    this.$data.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.confirm-profile-window {
  position: relative;
  width: 100%;
  height: 100%;
  background: #83212d;
  width: 98%;
  height: 85%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .information {
    width: 90%;
    height: 70%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .player-avatar {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .user-data {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 50px;
        line-height: 80px;
        color: #fff;
        text-shadow: 0px 3px 2px black;
      }
      .name {
        font-size: 45px;
      }
    }
  }
  .buttons {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .ui-button {
      color: #fff;
    }
  }
}
</style>
