<template>
  <div class="draw-indicator" v-if="show">
    <div class="draw-indicator-inner">
      <div class="top-glow-border"></div>
      <div class="draw-text">{{ $t("blackJack.draw") }}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "DrawIndicator",
  computed: {
    ...mapState(["player"]),
    ...mapGetters(["getChipFormat"]),
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    show() {
      if (this.getActiveRoomState.stage !== 4 || this.nGetMySeat.lose)
        return false;

      const state =
        this.nGetMySeat.handStrength ===
        this.getActiveRoomState.dealer.handStrength;

      if (this.nGetMySeat.split.bet > 0)
        return state && this.nGetMySeat.split.handStrength;

      return state;
    },
  },
};
</script>

<style scoped lang="scss">
@-webkit-keyframes drawIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes drawIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes drawInnerGlowAnimation {
  0% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(-2px);
  }
}
@keyframes drawInnerGlowAnimation {
  0% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(-2px);
  }
}

.draw-indicator {
  position: absolute;
  z-index: 9;
  top: 100px;
  width: 530px;
  height: 107px;
  background-image: linear-gradient(
    90deg,
    transparent 0%,
    rgba(0, 0, 0, 0.5) 20%,
    rgba(0, 0, 0, 0.7) 50%,
    rgba(0, 0, 0, 0.5) 80%,
    transparent 100%
  );
  color: rgb(255, 255, 255);
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: center;
  animation-name: drawIncomingAnimation;
  animation-iteration-count: 1;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  &-inner {
    position: relative;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      top: -40%;
      left: 0;
      width: 100%;
      height: 90%;
      background-image: radial-gradient(
        rgba($yellowColor, 0.8),
        transparent 65%
      );
    }
    .top-glow-border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-image: linear-gradient(
        90deg,
        transparent 20%,
        rgba($yellowColor, 0.8),
        transparent 80%
      );
      animation-name: drawInnerGlowAnimation;
      animation-iteration-count: 1;
      animation-duration: 0.3s;
      animation-delay: 0.3s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }
  }
  .draw-text {
    color: $yellowColor;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0 2px 0 rgba(black, 0.8);
    text-align: center;
  }
}
</style>
