<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1000"
    height="600"
    :loading="loading"
    :show="true"
    :titleText="$t('useInviteCode.heading')"
  >
    <template>
      <div class="search-user-modal">
        <div class="content">
          <p>{{ $t("useInviteCode.description") }}</p>
          <div class="id-input">
            <input type="text" :maxlength="50" v-model="code" />
          </div>
        </div>
        <div class="footer">
          <button class="ui-button ui-button-small-orange" @click="onOkClicked">
            {{ $t("general.okay") }}
          </button>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";
import AnalyticsEvents from "@/plugins/analytics/events";

export default {
  name: "UseInviteCodeWindow",
  components: {
    BaseWindow,
  },
  data() {
    return {
      loading: false,
      code: null,
    };
  },
  methods: {
    closeWindow() {
      this.$store.commit("modals/hide", "useInviteCode");
      this.code = null;
    },
    async onOkClicked() {
      this.$store.commit("authentication/setInviteCode", {
        code: this.$data.code,
      });
      this.$store.commit("modals/hide", "useInviteCode");
    },
  },
};
</script>

<style lang="scss" scoped>
.search-user-modal {
  position: relative;
  width: 98%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #83212d;
  border-radius: 20px;
  .heading {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 55px;
    font-weight: bold;
    line-height: 60px;
    background: -webkit-linear-gradient(#ffcc66, #ffffcc);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .content {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    p {
      font-size: 40px;
    }
    .id-input {
      display: flex;
      align-items: center;
      justify-content: center;
      input[type="number"] {
        -moz-appearance: textfield;
      }
      input {
        width: 570px;
        height: 85px;
        font-size: 36px;
        font-weight: bold;
        border-radius: 50px;
        background: #55121b;
        box-shadow: inset 1px 3px 8px black;
        border: none;
        color: rgba(rgb(255, 255, 255), 1);
        padding-left: 20px;
        display: flex;
        box-shadow: inset 1px 3px 8px black;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .footer {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    .search-button {
      text-transform: uppercase;
    }
  }
}
</style>
