import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

export class Firebase {
    constructor(config) {
        this.config = config;
    }

    init() {
        FirebaseAnalytics.initializeFirebase(this.config)
            .then(() => {
                FirebaseAnalytics.setCollectionEnabled({ enabled: true });
            })
            .catch(e => console.log(e));
    }

    fireEvent(eventName, data) {
        FirebaseAnalytics.logEvent({
            name: eventName,
            params: data,
        });
    }
}