<template>
  <div
    class="seat-cards"
    :class="[
      isSplitActive ? 'split-active' : '',
      nGetMySeat.bet.main <= 0 ? 'ghost' : '',
    ]"
  >
    <Card
      v-for="(card, cardIndex) in getSeatCards"
      :key="'seat-card-' + cardIndex"
      :index="card.index"
      :value="card.value"
      :suit="card.suit"
      :style="getCardStyles(cardIndex)"
      :class="[$store.state.service.ACTIVE_ROOM.cardColor]"
    />
    <div
      class="last-action-indicator"
      v-show="nGetMySeat.bet.main > 0 && nGetMySeat.cards.length >= 2"
      :style="getLastActionIndicatorStyles"
      :class="[
        `last-action-indicator-${
          nGetMySeat.doubledown ? 3 : nGetMySeat.lastAction
        }`,
      ]"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Card from "../Card";

export default {
  name: "Cards",
  components: {
    Card,
  },
  computed: {
    ...mapGetters({
      getBjActiveSeat: "service/getBjActiveSeat",
      nGetMySeat: "service/nGetMySeat",
    }),
    getSeatCards() {
      if (typeof this.nGetMySeat === "undefined") return [];
      if (
        this.nGetMySeat.bet.main <= 0 &&
        typeof this.getBjActiveSeat !== "undefined"
      ) {
        return this.getBjActiveSeat?.cards;
      }

      return this.nGetMySeat?.cards;
    },
    isSplitActive() {
      return this.nGetMySeat?.split.bet > 0;
    },
    getLastActionIndicatorStyles() {
      const styles = {};

      if (this.nGetMySeat.cards.length < 2) return styles;

      let lastCardIndex = this.nGetMySeat.cards.length;
      if (this.nGetMySeat.doubledown && this.nGetMySeat.cards.length === 3)
        lastCardIndex -= 1;

      let translateX = 26 * lastCardIndex;
      let translateY = 28 * lastCardIndex;

      styles.transform = `translate(${translateX}px, ${translateY * -1}px)`;

      return styles;
    },
  },
  methods: {
    getCardStyles(index = 0) {
      const styles = {};

      let translateX = 28 * index;
      let translateY = 35 * index;

      if (
        this.nGetMySeat.doubledown &&
        this.nGetMySeat.cards.length > 2 &&
        index === this.nGetMySeat.cards.length - 1
      ) {
        translateX = 0;
        translateY += 5;
      }

      styles.overflow = "visible";
      styles.zIndex = 1 + index;
      styles.transform = `rotateX(35deg) translate(${translateX}px, ${
        translateY * -1
      }px)`;

      return styles;
    },
  },
};
</script>

<style scoped lang="scss">
.seat-cards {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  min-height: 76px;
  margin-bottom: -10px;
  perspective: 1950px;
  -webkit-perspective: 1950px;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: scale(1.1);
  &.ghost {
    opacity: 0.5;
  }
  .card {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.last-action-indicator {
  width: 32px;
  height: 32px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: inset 0 0 0 2px rgba(black, 0.3);
  text-shadow: 0 1px 0 rgba(black, 0.5);
  overflow: hidden;
  &-1 {
    display: flex;
    background-color: $greenColor;
    &::after {
      content: "+";
      font-size: 24px;
      font-weight: bold;
      color: rgb(255, 255, 255);
    }
  }
  &-2 {
    display: flex;
    background-color: $redColor;
    &::after {
      content: "-";
      font-size: 24px;
      font-weight: bold;
      color: rgb(255, 255, 255);
    }
  }
  &-3 {
    display: flex;
    background-color: #e27116;
    &::after {
      content: "2X";
      font-size: 16px;
      font-weight: bold;
      color: rgb(255, 255, 255);
    }
  }
}
</style>
