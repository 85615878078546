import { Preferences } from "@capacitor/preferences";

const StorageManager = {
  async get({ key }) {
    return Preferences.get({ key });
  },

  set({ key, value }) {
    return Preferences.set({ key, value });
  },

  remove({ key }) {
    return Preferences.remove({ key });
  },

  clear() {
    return Preferences.clear();
  },
};

export default {
  instance: StorageManager,

  install(Vue) {
    Vue.prototype.$plugins.storage = StorageManager;
  },
};
