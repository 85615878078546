export default {
  clearAuthenticationState(state) {
    state.token.access = "";
    state.token.refresh = "";
    state.authType = "";
  },

  setInviteCode(state, { code }) {
    state.inviteCode = code;
  },
};
