<template lang="html">
  <div class="vue-sprite" v-bind="$attrs">
    <canvas
      class="sprite-canvas"
      :id="id"
      :width="getWidth"
      :height="getHeight"
      ref="sprite-canvas"
    ></canvas>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SpritesheetAnimation",
  props: {
    spritesheet: {
      required: true,
      type: String,
      default: "",
    },
    json: {
      required: true,
      type: Object,
      default: {},
    },
    fps: {
      type: Number,
      default: 30,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    yoyo: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "vue-sprite",
    },
    requiredWidth: {
      type: Number,
      required: false,
      default: 0,
    },
    requiredHeight: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    getWidth() {
      if (this.$props.requiredWidth > 0) return this.$props.requiredWidth;
      return this.$data.width;
    },
    getHeight() {
      if (this.$props.requiredHeight > 0) return this.$props.requiredHeight;
      return this.$data.height;
    },
    ...mapGetters({
      getRealPlatform: "getRealPlatform",
    }),
  },
  data() {
    return {
      frames: [],
      visible: true,
      length: 0,
      frameIndex: 0,
      currentIndex: 0,
      animationFrameID: null,
      yoyodirection: 0,
      sprite: null,
      ctx: null,
      height: 0,
      width: 0,
      now: 0,
      then: 0,
    };
  },
  methods: {
    init() {
      this.ctx = this.$refs["sprite-canvas"]?.getContext("2d");
      this.currentIndex = 0;
      this.render();

      this.autoplay && this.loop();
    },
    render() {
      this.ctx && this.ctx.clearRect(0, 0, this.$data.width, this.$data.height);
      if (
        this.yoyo &&
        this.currentIndex % this.length === 0 &&
        this.currentIndex
      ) {
        this.yoyodirection = Number(!this.yoyodirection);
      }
      const index = Math.abs(
        (this.currentIndex % this.length) - this.length * this.yoyodirection
      );
      const x = this.frames[index].x;
      const y = this.frames[index].y;
      this.ctx &&
        this.ctx.drawImage(
          this.sprite,
          x,
          y,
          this.$data.width,
          this.$data.height,
          0,
          0,
          this.$data.width,
          this.$data.height
        );
    },
    loop(playOnce = false) {
      this.$nextTick(() => {
        this.now = Date.now();
        const delta = this.now - this.then;
        if (delta > 1000 / this.fps) {
          this.then = this.now - (delta % (1000 / this.fps));
          this.render();
          this.currentIndex++;
        }
        if (playOnce === true && this.currentIndex >= this.fps) {
          this.stop();
          return;
        }
        this.animationFrameID = window.requestAnimationFrame(
          this.loop.bind(this, playOnce)
        );
      });
    },
    stop() {
      window.cancelAnimationFrame(this.animationFrameID);
      this.currentIndex = 0;
    },
    play(from) {
      this.currentIndex = Number.isNaN(Number(from)) ? this.currentIndex : from;
      this.loop();
    },
    playOnce(loop = true) {
      this.currentIndex = 0;
      this.loop(loop);
    },
  },
  mounted() {
    console.log("mounted json", this.json);
    const jsonFrames =
      this.getRealPlatform == "ios"
        ? this.json.frames.reverse()
        : this.json.frames;
    const frames = Object.values(jsonFrames);
    frames.forEach((frame) => {
      this.frames.push({
        name: frame.filename,
        x: frame.frame.x,
        y: frame.frame.y,
        w: frame.frame.w,
        h: frame.frame.h,
      });
    });
    this.frames.sort((a, b) => a.filename < b.filename);
    this.$data.width = this.frames[0].w;
    this.$data.height = this.frames[0].h;
    this.length = this.frames.length - 1;
  },
  created() {
    this.$nextTick(() => {
      this.sprite = new Image();
      this.sprite.src = this.spritesheet;
      this.sprite.onload = () => {
        this.init();
      };
    });
  },
};
</script>

<style lang="scss">
.table-seat-animation {
  .sprite-canvas {
    position: relative;
    z-index: 100 !important;
  }
}
</style>
