<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1100"
    :height="rowCount == 5 ? 850 : rowCount == 4 ? 750 : 750"
    :show="show"
    preventClickOutside
  >
    <template>
      <div class="jackpot-window sprite-table-jackpot background-image">
        <div class="title-image jackpot-image"></div>
        <div
          class="
            jackpot-chip-container
            sprite-table-jackpot
            jackpot-chip-background-image
          "
        >
          <div class="jackpot-slot">{{ getChipFormat(total) }}</div>
        </div>

        <div class="table-line">
          <div class="table-container">
            <div class="texts" v-if="identity !== ''">
              <div
                class="text"
                v-for="(jackpot, jackpotIndex) in getJackpots[identity]
                  .jackpots"
                :key="'jackpot-' + jackpotIndex"
              >
                <div
                  class="text-rule"
                  v-for="(rule, ruleIndex) in getJackpotRules(jackpot)"
                  :key="'jackpot-rule-' + ruleIndex"
                >
                  <span class="name">{{ getRuleNameByValue(rule.rank) }}</span>
                  <span class="percent">Win %75</span>
                  <span class="amount">
                    <AnimatedNumber :number="jackpot.amount" :format="true" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="jackpot-info">
          <p class="info-text">
            {{ $t("progressiveJackpot.desc3") }}
          </p>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import { mapGetters } from "vuex";

import BaseWindow from "@/components/Misc/GlobalWindows/BaseWindow";
import EventBus from "@/event-bus";
import AnimatedNumber from "@/components/Utils/AnimatedNumber";

export default {
  name: "JackpotWindow",
  components: {
    AnimatedNumber,
    BaseWindow,
  },
  computed: {
    ...mapGetters(["getShortChipFormat", "getChipFormat", "getRealPlatform"]),
    ...mapGetters({
      getJackpots: "jackpots/getJackpots",
    }),
    total() {
      return this.getJackpots.system.total + this.getJackpots.special.total;
    },
    rowCount() {
      if (this.getJackpots && this.getJackpots[this.identity]) {
        return this.getJackpots[this.identity].length;
      }
    },
  },
  data() {
    return {
      show: false,
      identity: "",
    };
  },
  methods: {
    closeWindow() {
      this.$data.show = false;
    },
    onClickedOutside($event) {
      if ($event?.target?.className.includes("jackpot-window-trigger"))
        return false;

      this.close();
    },
    onJackpotDetailRequested({ id }) {
      this.$data.show = true;
      this.$data.identity = id;
    },
    close() {
      this.$data.show = false;
      this.$data.identity = "";
    },
    getRuleNameByValue(value = 0) {
      const ranks = {
        1: "High Card",
        2: "Pair",
        3: "Two Pair",
        4: "Three of a Kind",
        5: "Straight",
        6: "Flush",
        7: "Full House",
        8: "Four of a Kind",
        9: "Straight Flush",
        10: "Royal Flush",
      };
      if (typeof ranks[value] === "undefined") return "";
      return ranks[value];
    },
    getJackpotRules(jackpot) {
      return [...jackpot.rules].sort((a, b) => a.rank - b.rank);
    },
  },
  beforeDestroy() {
    EventBus.$off(
      "onJackpotDetailRequested",
      this.onJackpotDetailRequested.bind(this)
    );
  },
  created() {
    EventBus.$on(
      "onJackpotDetailRequested",
      this.onJackpotDetailRequested.bind(this)
    );
  },
};
</script>

<style lang="scss" scoped>
.jackpot-window {
  // position: relative;
  // left: 0;
  // right: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 5px;
  .title-image {
    position: absolute;
    top: -80px;
    left: 138px;
  }
  .jackpot-image {
    background: url("~@/assets/images/jackpots/jackpot.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 310px;
    width: 800px;
  }
  .jackpot-chip-container {
    position: absolute;
    top: 130px;
    left: 343px;
    // z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .jackpot-slot {
      // padding-top: 35px;
      font-size: 40px;
      font-weight: bold;
      color: #f7bd37;
      text-shadow: -3px 3px 7px black;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30%;
      // z-index: 90;
    }
  }
  .table-line {
    position: relative;
    top: 55px;
    height: 100%;
    display: flex;
    justify-content: center;
    .table-container {
      position: relative;
      min-height: 130px;
      width: 1000px;
      margin: auto;
      background-color: #982735;
      border: 3px solid #efd37c;
      border-radius: 23px;
      .texts {
        position: relative;
        // top: 47px;
        // left: 43px;
        // width: 900px;
        margin: 25px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 95%;
          height: 65px;
          margin-bottom: 20px;
          font-size: 42px;
          text-shadow: 2px 2px 3px black;
          font-weight: bold;
          background: #3c0b13;
          border-radius: 10px;
          padding: 5px 20px;
          &:last-child {
            margin-bottom: 0px;
          }
          .text-rule {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name {
              min-width: 250px;
            }
            .percent {
              min-width: 150px;
            }
            .amount {
              min-width: 150px;
            }
          }
        }
      }
    }
  }
  .jackpot-info {
    width: 98%;
    margin: auto;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 47px;
    .info-text {
      font-size: 40px;
    }
  }
}
</style>
