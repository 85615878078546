<template>
  <div class="update-text">
    {{ $t("general.update") }}
  </div>
</template>

<script>
export default {
  name: "Update",
};
</script>

<style scoped lang="scss">
.update-text {
  position: absolute;
  top: 28%;
  font-size: 65px;
  text-shadow: 0px 3px 2px black;
  color: #fff;
}
</style>
