import { Clipboard } from "@capacitor/clipboard";

const ClipboardManager = {
  write({ type, value }) {
    return Clipboard.write({
      [type]: value,
    });
  },

  read() {
    const readClipBoard = Clipboard.read();
    return readClipBoard;
  },
};

export default {
  instance: ClipboardManager,

  install(Vue) {
    Vue.prototype.$plugins.clipboard = ClipboardManager;
  },
};
