<template>
  <BaseWindow
    @closeWindow="closeWindow"
    :width="getUserAffiliateRegister ? '1000' : '1920'"
    :height="getUserAffiliateRegister ? '600' : '1080'"
    :show="true"
    :stopClickOutside="true"
    :titleText="$t('affiliate.affiliate')"
  >
    <div class="affiliate-window">
      <div class="add-affiliate" v-if="!user.affiliateStatus.isAffiliate">
        <div class="info">
          <!-- <div class="warning">{{ $t("affiliate.warning") }}</div> -->
          <!-- <a :href="getLink" target="_blank" class="link">{{
            $t("affiliate.info")
          }}</a> -->
        </div>
        <div class="input-section">
          <!-- <div class="label">{{ $t("affiliate.affiliate") }} ID</div> -->
          <div class="affiliate-icon"></div>
          <div class="affiliate-desc" v-show="user.affiliateStatus.isAffiliate">
            {{ $t("affiliate.affiliateDesc") }}
          </div>
          <div
            class="affiliate-desc"
            v-show="!user.affiliateStatus.isAffiliate"
          >
            {{ $t("affiliate.affiliateOffDesc") }}
          </div>
          <input
            type="number"
            class="id-input"
            v-show="user.affiliateStatus.affiliateId === -1"
            v-model="affiliateId"
            :placeholder="$t('affiliate.addAffiliate')"
          />
          <div
            class="affiliate-id"
            v-show="user.affiliateStatus.affiliateId > -1"
          >
            {{ user.affiliateStatus.affiliateId }}
          </div>
        </div>
        <div class="footer">
          <div
            class="ui-button ui-button-small-orange confirm-button"
            v-show="user.affiliateStatus.affiliateId === -1"
            @click="showConfirmWindow"
          >
            {{ $t("general.confirm") }}
          </div>
        </div>
        <ProfileConfirmWindow
          v-if="showConfirm"
          :show="showConfirm"
          :userId="affiliateId"
          :type="'affiliate'"
          @closeWindow="closeConfirmWindow"
          @confirm="addAffiliate"
        />
      </div>
      <div class="affiliate-container" v-if="user.affiliateStatus.isAffiliate">
        <AffiliatePanel />
      </div>
    </div>
  </BaseWindow>
</template>

<script>
import ProfileConfirmWindow from "./ProfileConfirmWindow";
import AffiliatePanel from "./AffiliateComponents/AffiliatePanel";

import { mapState, mapGetters } from "vuex";

import BaseWindow from "./BaseWindow";

export default {
  name: "AffiliateWindow",
  data() {
    return {
      affiliateId: null,
      showConfirm: false,
    };
  },
  watch: {
    "$store.state.modals.affiliate": {
      handler(val) {
        if (val) {
          // this.$store.dispatch("user/getAffiliateReferences", {
          //   from: 0,
          //   to: 0,
          //   limit: 25,
          //   offSet: 0,
          // });
        }
      },
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({ getPreferenceValue: "preferences/getValue" }),
    getLink() {
      if (this.getPreferenceValue("language") === "id") {
        return "https://turngs.com/id/referral/";
      } else {
        return "https://turngs.com/reference-system/";
      }
    },
    getUserAffiliateRegister() {
      if (
        !this.user.affiliateStatus.isAffiliate &&
        this.user.affiliateStatus.affiliateId !== -1
      ) {
        return true;
      }
      return false;
    },
  },
  components: {
    BaseWindow,
    ProfileConfirmWindow,
    AffiliatePanel,
  },
  methods: {
    closeWindow() {
      this.$store.commit("modals/hide", "affiliate");
    },
    showConfirmWindow() {
      if (this.$data.affiliateId !== null) {
        this.showConfirm = true;
      }
    },
    closeConfirmWindow() {
      this.$data.showConfirm = false;
    },
    async addAffiliate() {
      const res = await this.$store.dispatch(
        "user/addAffiliate",
        this.$data.affiliateId
      );

      if (!res) {
        this.$plugins.toast.show({
          text: this.$t("affiliate.failedAffiliate"),
          duration: 2500,
          variant: "danger",
        });
      }
      await this.$store.dispatch("user/getAffiliateStatus");

      this.$plugins.toast.show({
        text: this.$t("affiliate.successfullyAdded"),
        duration: 2500,
        variant: "success",
      });
      this.$adjust.trackEvent("AffiliateConfirmSuccess");
    },
  },
};
</script>

<style lang="scss" scoped>
.affiliate-window {
  position: relative;
  width: 98%;
  height: 92%;
  background: #83212d;
  border-radius: 20px;
  overflow-x: hidden;
  .add-affiliate {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      height: 12%;
      .warning {
        font-size: 35px;
      }
      .link {
        color: #ebb726;
        font-weight: bold;
        font-size: 35px;
      }
    }
    .input-section {
      width: 100%;
      height: 56%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      bottom: 70px;
      .affiliate-id {
        font-size: 50px;
      }
      .affiliate-icon {
        height: 150px;
        width: 150px;
        background: url("~@/assets/images/lobby/affiliate-icon.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
      .affiliate-desc {
        font-size: 40px;
        margin-bottom: 40px;
      }
      .label {
        font-size: 40px;
        font-weight: bold;
        text-shadow: 3px 3px 3px black;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .id-input,
      input {
        width: 800px;
        height: 88px;
        font-size: 36px;
        font-weight: bold;
        border-radius: 50px;
        background: #55121b;
        box-shadow: inset 1px 3px 8px black;
        border: none;
        color: rgba(rgb(255, 255, 255), 1);
        padding-left: 20px;
        display: flex;
        box-shadow: inset 1px 3px 8px black;
        align-items: center;
        justify-content: center;
        &::placeholder {
          font-size: 32px;
          color: rgba(rgb(255, 255, 255), 1);
          font-weight: normal;
          padding: 0px 20px;
          margin-bottom: 30px;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .footer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .confirm-button {
        text-transform: uppercase;
      }
    }
  }
}
.affiliate-container {
  width: 100%;
  height: 100%;
}
</style>
