<template>
  <div class="allin-animation-sprite" @click="$emit('avatarClick')">
    <div class="allin-image"></div>
    <!-- <AllinAnimationSprite ref="allInAnimationSprite"/> -->
  </div>
</template>

<script>
// import AllinAnimationSprite from '@/components/Misc/Animations/AllinAnimation'

export default {
  name: "AllinAnimation",
  // components: {
  //   AllinAnimationSprite
  // },
  mounted() {
    // this.$refs.allInAnimationSprite.$refs.allinAnimationSprite.playOnce()
  },
};
</script>

<style scoped lang="scss">
@keyframes seatGlow {
  0% {
    box-shadow: 0px 0 16px 1px rgba(89, 4, 4, 0);
  }
  50% {
    box-shadow: 0px 0 50px 3px rgb(224, 2, 2);
  }
  to {
    box-shadow: 0px 0 16px 1px rgba(81, 1, 1, 0);
  }
}
.allin-animation-sprite {
  position: absolute;
  z-index: 4;
  top: 0px;
  left: 0px;
}
.allin-image {
  background-repeat: no-repeat;
  width: 207px;
  height: 207px;
  border-radius: 100%;
  background-position: center center;
  animation-name: seatGlow;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
</style>
