<template>
  <div
    class="action-panel-container"
    :class="[getRealPlatform === 'android' ? 'no-flick' : '']"
  >
    <div class="action-panel-container-inner">
      <div class="action-bar">
        <div
          class="action-bar-container"
          v-show="
            getActiveRoomState.currentTurn === nGetMySeat.position &&
            !isAnyAutoButtonSelected
          "
        >
          <div class="sprite-table pass-button1 action-button" @click="fold">
            <span class="action-button-text">
              {{ $t("actionPanel.fold") }}
            </span>
          </div>
          <div
            class="sprite-table call-button1 call-btn action-button"
            @click="call"
            v-show="getCallAmount > 0"
          >
            <span class="action-button-text">
              {{ $t("actionPanel.call") }}
            </span>
            <span class="action-button-amount flex-center">
              <template
                v-if="getCallAmount > nGetMySeat.stack + nGetMySeat.bet"
              >
                {{
                  getShortChipFormat(
                    nGetMySeat.stack ? `${nGetMySeat.stack}` : "5"
                  )
                }}
              </template>
              <template
                v-if="getCallAmount <= nGetMySeat.stack + nGetMySeat.bet"
              >
                {{
                  getShortChipFormat(
                    getCallAmount ? `${getCallAmount - nGetMySeat.bet}` : ""
                  )
                }}
              </template>
            </span>
          </div>
          <div
            class="sprite-table call-button1 check-btn action-button"
            @click="check"
            v-show="getCallAmount === 0"
          >
            <span class="action-button-text">
              {{ $t("actionPanel.check") }}
            </span>
            <!-- {{ $t("actionPanel.check") }} -->
          </div>
          <div
            class="sprite-table raise-button action-button"
            @click="doRaiseOrOpenRaisePanel"
            v-show="
              isRaisePanelShowable &&
              getRaiseAmount > getCallAmount &&
              getRaiseAmount - nGetMySeat.bet > 0
            "
          >
            <span class="action-button-text">
              {{ $t("actionPanel.raise") }}
            </span>
            <!-- {{ $t("actionPanel.raise") }} -->
          </div>
        </div>
        <AutoActionButtons
          :callAmount="getCallAmount"
          @onAction="onAutoActionRequested"
        />
      </div>
      <div
        v-if="showRaisePanel && getMaxRaiseAmount > getActiveRoomState.minRaise"
        v-click-outside="onClickedOutsideOfSidebar"
        class="raise-panel"
      >
        <div class="raise-panel-inner">
          <div class="raise-amount">
            {{ getShortChipFormat(getRaiseAmount) }}
          </div>
          <div class="interaction-area">
            <div class="fast-buttons">
              <button type="button" class="fast-buttons-item" @click="setAllin">
                {{ $t("actionPanel.allin") }}
              </button>
              <button
                type="button"
                class="fast-buttons-item"
                :disabled="
                  getTotalTablePotAmount === 0 ||
                  nGetMySeat.stack < getTotalTablePotAmount ||
                  this.getActiveRoomState.minRaise >=
                    this.getTotalTablePotAmount * 2 ||
                  this.getTotalTablePotAmount > this.getMaxRaiseAmount
                "
                @click="setPot(false)"
              >
                POT
              </button>
              <!-- {{
                this.getActiveRoomState.minRaise > this.getTotalTablePotAmount
              }} -->

              <button
                type="button"
                class="fast-buttons-item"
                :disabled="
                  getTotalTablePotAmount === 0 ||
                  nGetMySeat.stack < getTotalTablePotAmount / 2 ||
                  this.getActiveRoomState.minRaise >
                    this.getTotalTablePotAmount / 2 ||
                  this.getTotalTablePotAmount > this.getMaxRaiseAmount / 2
                "
                @click="setPot(true)"
              >
                1/2
              </button>
              <button
                type="button"
                class="fast-buttons-circle raise-slider-plus-button raise-slider-button"
                @click="incrRaiseAmount"
              >
                <span class="fast-buttons-circle-icon">+</span>
              </button>
              <button
                type="button"
                class="fast-buttons-circle raise-slider-minus-button raise-slider-button"
                @click="decrRaiseAmount"
              >
                <span class="fast-buttons-circle-icon minus">-</span>
              </button>
            </div>
            <div class="raise-slider-container no-flick">
              <div class="sprite-table chip-bar">
                <VueSlider
                  width="141px"
                  height="644px"
                  :contained="true"
                  direction="ttb"
                  :min="getActiveRoomState.minRaise"
                  :max="getMaxRaiseAmount"
                  :data="getRaiseAmountsArr"
                  :zoom="getTotalScaleAmount"
                  :processStyle="{}"
                  :railStyle="{}"
                  :adsorb="true"
                  v-model="raiseAmount"
                  tooltip="none"
                  :dragOnClick="true"
                  @dragging="onSliderDragging"
                  :silent="false"
                >
                  <template v-slot:dot>
                    <div class="sprite-table chip2"></div>
                  </template>
                </VueSlider>
                <span ref="amount0" class="chip-bar-amount amount-0">Rest</span>
                <span ref="amount1" class="chip-bar-amount amount-1">
                  {{
                    getShortChipFormat(
                      Math.round(this.getMaxRaiseAmount * 0.75)
                    )
                  }}</span
                >
                <span ref="amount2" class="chip-bar-amount amount-2">{{
                  getShortChipFormat(Math.round(this.getMaxRaiseAmount * 0.5))
                }}</span>
                <span ref="amount3" class="chip-bar-amount amount-3">{{
                  getShortChipFormat(Math.round(this.getMaxRaiseAmount * 0.25))
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import ClickOutside from "vue-click-outside";
import AutoActionButtons from "@/components/Misc/PokerTable/ActionPanel/AutoActionButtons";
import AnalyticsEvents from "@/plugins/analytics/events";
import HapticsPlugin from "@/plugins/haptics";

export default {
  name: "ActionPanel",
  components: {
    VueSlider,
    AutoActionButtons,
  },
  directives: { ClickOutside },
  watch: {
    "getActiveRoomState.stage": function (newValue, oldValue) {
      if (newValue === 5 && oldValue <= 1) {
        this.$data.isAnyAutoButtonSelected = false;
      }
      this.$store.state.service.userRaiseClick = false;
    },
    "getActiveRoomState.currentTurn": function (currentTurn) {
      this.$data.showRaisePanel = false;
      if (currentTurn < 0) return false;

      this.raiseAmount = this.getActiveRoomState.minRaise;

      if (this.nAmISitting && currentTurn === this.nGetMySeat.position) {
        if (this.getPreferenceValue("vibration") == 1)
          HapticsPlugin.instance.vibrate();
      }
    },
    "getActiveRoomState.minRaise": {
      handler(val) {
        this.raiseAmount = val;
      },
      immediate: true,
    },
    getRaiseAmount: {
      handler(val) {
        if (this.$refs.amount0) {
          if (val >= this.getMaxRaiseAmount * 0.25 && this.$refs.amount3) {
            this.$refs.amount3.style.opacity = "0";
          } else {
            this.$refs.amount3.style.opacity = "1";
          }
          if (val >= this.getMaxRaiseAmount * 0.5 && this.$refs.amount2) {
            this.$refs.amount2.style.opacity = "0";
          } else {
            this.$refs.amount2.style.opacity = "1";
          }
          if (val >= this.getMaxRaiseAmount * 0.75 && this.$refs.amount1) {
            this.$refs.amount1.style.opacity = "0";
          } else {
            this.$refs.amount1.style.opacity = "1";
          }
          if (val <= this.getMaxRaiseAmount * 0.95 && this.$refs.amount0) {
            this.$refs.amount0.style.opacity = "1";
          } else {
            this.$refs.amount0.style.opacity = "0";
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      getPreferenceValue: "preferences/getValue",
      nGetMySeat: "service/nGetMySeat",
      nAmISitting: "service/nAmISitting",
      getActiveRoomState: "service/getActiveRoomState",
      getChipFormat: "getChipFormat",
      getShortChipFormat: "getShortChipFormat",
      getTotalScaleAmount: "getTotalScaleAmount",
      getRealPlatform: "getRealPlatform",
    }),
    getRaiseAmount() {
      if (this.raiseAmount > this.nGetMySeat.stack + this.nGetMySeat.bet)
        return this.nGetMySeat.stack + this.nGetMySeat.bet;

      return this.raiseAmount;
    },
    getTableName() {
      return this.$t(this.getActiveRoomState.name);
    },
    secondRichestSeat() {
      return [...this.getActiveRoomState.players].sort(
        (a, b) => b.stack + b.bet > a.stack + a.bet
      )[0];
    },
    isRaisePanelShowable() {
      return (
        this.getRaiseAmount > 0 &&
        this.getRaiseAmount !== this.getCallAmount &&
        this.getRaiseAmount <= this.getMaxRaiseAmount
      );
    },
    getTotalPLayerPotAmount() {
      return this.getActiveRoomState.players
        .filter((player) => player.position > -1 && player.bet > 0)
        .reduce((total, current) => {
          return total + current.bet;
        }, 0);
    },
    getTotalTablePotAmount() {
      const totalPlayerPot = this.getActiveRoomState.players
        .filter((player) => player.position > -1 && player.bet > 0)
        .reduce((total, current) => {
          return total + current.bet;
        }, 0);
      return this.getActiveRoomState.pots.reduce((total, current) => {
        return total + current.amount;
      }, totalPlayerPot);
    },
    getRaiseAmountsArr() {
      const stepCount = Math.ceil(
        this.getMaxRaiseAmount / (this.getActiveRoomState.bet * 2)
      );

      const amounts = [];
      let count = stepCount;
      while (count >= 1) {
        let amount = count * this.getActiveRoomState.bet * 2;

        if (amount >= this.getActiveRoomState.minRaise) {
          if (amount > this.getMaxRaiseAmount) amount = this.getMaxRaiseAmount;

          amounts.push(amount);
        }

        count--;
      }

      return [...amounts];
    },
    is3xPotButtonDisabled() {
      return (
        this.getTotalPLayerPotAmount * 3 > this.getActiveRoomState.minRaise ||
        this.getTotalTablePotAmount === 0 ||
        this.nGetMySeat.stack < this.getTotalTablePotAmount ||
        this.getActiveRoomState.minRaise >= this.getTotalTablePotAmount ||
        this.getTotalTablePotAmount > this.getMaxRaiseAmount
      );
    },
    isHalfPotButtonDisabled() {
      return (
        this.getTotalTablePotAmount === 0 ||
        this.nGetMySeat.stack < this.getTotalTablePotAmount / 2 ||
        this.getActiveRoomState.minRaise > this.getTotalTablePotAmount / 2 ||
        this.getTotalTablePotAmount / 2 > this.getMaxRaiseAmount
      );
    },
    isPotButtonDisabled() {
      return (
        this.getTotalTablePotAmount === 0 ||
        this.nGetMySeat.stack < this.getTotalTablePotAmount ||
        this.getActiveRoomState.minRaise >= this.getTotalTablePotAmount ||
        this.getTotalTablePotAmount > this.getMaxRaiseAmount
      );
    },
    getCallAmount() {
      if (!this.nAmISitting) return 0;
      if (this.nGetMySeat.bet === this.getActiveRoomState.callAmount) return 0;
      /*if (this.getActiveRoomState.callAmount > this.nGetMySeat.stack)
        return this.nGetMySeat.stack + this.nGetMySeat.bet;*/
      const bets = this.getActiveRoomState.players
        .filter((player) => player.in)
        .sort((a, b) => b.bet - a.bet);
      if (
        bets[0] &&
        bets[0].bet > 0 &&
        bets[0].bet >= this.getActiveRoomState.callAmount
      )
        return bets[0].bet;
      return this.getActiveRoomState.callAmount;
    },
    getMaxRaiseAmount() {
      if (this.getActiveRoomState.maxRaise > this.nGetMySeat.stack)
        return this.nGetMySeat.stack + this.nGetMySeat.bet;
      return this.getActiveRoomState.maxRaise;
    },
    getInfoMessage() {
      if (!this.nGetMySeat?.in) return this.$t("please_wait_next_hand");
      if (!this.nAmISitting) return this.$t("please_seat");

      return "";
    },
  },
  data() {
    return {
      raiseAmount: 0,
      isAnyAutoButtonSelected: false,
      showRaisePanel: false,
      sliderSwipePlay: false,
    };
  },
  methods: {
    setAllin() {
      this.raiseAmount = this.getMaxRaiseAmount;
      this.$plugins?.audio?.play("Button");
    },
    setPot3x() {
      if (this.getActiveRoomState.minRaise > this.getTotalTablePotAmount)
        return false;

      if (this.getTotalPLayerPotAmount * 3 > this.getActiveRoomState.minRaise)
        return false;

      if (this.getTotalTablePotAmount > this.getMaxRaiseAmount) return false;

      this.raiseAmount = this.getTotalTablePotAmount * 3;
      return true;
    },
    setPot(half = false) {
      if (this.getTotalTablePotAmount === 0) return false;

      if (!half) {
        if (this.getActiveRoomState.minRaise > this.getTotalTablePotAmount) {
          return false;
        }

        if (this.getTotalTablePotAmount > this.getMaxRaiseAmount) {
          return false;
        }

        this.raiseAmount = this.getTotalTablePotAmount;
        return true;
      }

      if (this.getTotalTablePotAmount / 2 > this.getMaxRaiseAmount) {
        return false;
      }

      if (this.getActiveRoomState.minRaise > this.getTotalTablePotAmount / 2) {
        return false;
      }

      const halfAmount = this.getTotalTablePotAmount / 2;
      if (halfAmount & (1000 !== 0)) {
      }
      this.raiseAmount = this.getTotalTablePotAmount / 2;
      this.$plugins?.audio?.play("Button");
    },
    onSliderDragging() {
      if (!this.sliderSwipePlay) {
        this.$plugins.audio.play("SliderSwipe");
        this.sliderSwipePlay = true;
        setTimeout(() => {
          this.sliderSwipePlay = false;
        }, 500);
      }
    },
    incrRaiseAmount() {
      const to = this.raiseAmount + this.getActiveRoomState.bet * 2;
      if (to > this.getMaxRaiseAmount) {
        this.raiseAmount = this.getMaxRaiseAmount;
        return;
      }
      this.$plugins?.audio?.play("Button");
      this.raiseAmount = to;
    },
    decrRaiseAmount() {
      const currentIndex = this.getRaiseAmountsArr.indexOf(this.raiseAmount);
      if (currentIndex + 1 >= this.getRaiseAmountsArr.length) return;
      if (currentIndex === this.getRaiseAmountsArr.length - 1) {
        this.raiseAmount =
          this.getRaiseAmountsArr[this.getRaiseAmountsArr.length - 2];
        return;
      }

      const to = this.raiseAmount - this.getActiveRoomState.bet * 2;
      if (to < this.getActiveRoomState.minRaise) {
        this.raiseAmount = this.getActiveRoomState.minRaise;
        return;
      }
      this.$plugins?.audio?.play("Button");
      this.raiseAmount = to;
    },
    doRaiseOrOpenRaisePanel() {
      if (
        this.getMaxRaiseAmount > this.getActiveRoomState.minRaise &&
        !this.showRaisePanel
      ) {
        this.showRaisePanel = true;
        return;
      }
      this.raise();
    },
    onClickedOutsideOfSidebar($event) {
      if (typeof $event?.target?.className.includes === "undefined")
        return false;
      if (typeof $event?.target?.className.includes("raise-button"))
        return false;
      if (!this.showRaisePanel) return false;
      this.showRaisePanel = false;
    },
    call() {
      // this.$plugins.audio.play("Call");
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("call", {
        amount: this.getCallAmount - this.nGetMySeat.bet,
      });
      this.$store.state.service.userCallClick = true;
    },
    check() {
      // this.$plugins.audio.play("Check");
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("check");
    },
    raise() {
      // this.$plugins.audio.play("Raise");
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("raise", {
        amount: this.getRaiseAmount - this.nGetMySeat.bet,
      });

      this.$store.state.service.userRaiseClick = true;
    },
    fold() {
      // this.$plugins.audio.play("Fold");
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("fold");

      this.$store.state.service.userFoldClick = true;
    },
    onAutoActionRequested(action) {
      if (this[action] === "undefined") return false;

      this[action]();
    },
    actionButtonRemoveEventListeners() {
      const checkBtn = document.querySelector(".check-btn");
      const callBtn = document.querySelector(".call-btn");
      const foldBtn = document.querySelector(".fold-button");
      const raiseBtn = document.querySelector(".raise-button");

      this.removeElementListeners(checkBtn, "click");
      this.removeElementListeners(callBtn, "click");
      this.removeElementListeners(foldBtn, "click");
      this.removeElementListeners(raiseBtn, "click");
    },
    playSoundName(name = "") {
      if (name) {
        this.$plugins.audio.play(name);
      }
    },
    removeElementListeners(element = null, eventName) {
      if (element && eventName) {
        element.removeEventListener(eventName, (event) => {});
      }
    },
    addElementListeners(element = null, eventName, soundName) {
      if (element && eventName && soundName) {
        element.addEventListener(eventName, this.playSoundName(soundName));
      }
    },
    actionButtonAddEventListeners() {
      const checkBtn = document.querySelector(".check-btn");
      const callBtn = document.querySelector(".call-btn");
      const foldBtn = document.querySelector(".fold-button");
      const raiseBtn = document.querySelector(".raise-button");

      this.addElementListeners(checkBtn, "click", "Check");
      this.addElementListeners(callBtn, "click", "Call");
      this.addElementListeners(foldBtn, "click", "Fold");
      this.addElementListeners(raiseBtn, "click", "Raise");
    },
  },
  // mounted() {
  //   console.log("mountedd");
  //   const foldBtn = document.querySelector(".fold-button");
  //   if (foldBtn) {
  //     this.actionButtonAddEventListeners();
  //   } else {
  //     setTimeout(() => {
  //       this.actionButtonAddEventListeners();
  //     }, 1000);
  //   }
  // },
};
</script>

<style lang="scss">
.action-panel-container {
  position: absolute;
  right: 4%;
  bottom: 0;
  width: 35%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  .action-bar {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 149px;
    box-sizing: border-box;
    .action-bar-container {
      width: 950px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .action-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 42px;
        font-weight: bold;
        text-shadow: 0 3px 0 rgba(black, 0.3);
        text-transform: uppercase;
        margin-right: 0px;
        transition: all 0.25s ease;
        &-text {
          position: absolute;
          bottom: 142px;
          text-shadow: 0 3px 5px #000000;
        }
        &-amount {
          color: #01b3ae;
          font-size: 38px;
          position: absolute;
          text-shadow: 0 3px 3px #000000;
          bottom: 0px;
          min-width: 150px;
          height: 50px;
          border-radius: 25px;
          background-color: rgba(black, 0.8);
          margin-bottom: 60px;
        }
        cursor: pointer;
        // &:last-child {
        //   margin-right: 0;
        // }
        &:active {
          opacity: 0.8;
        }
      }
      .pass-button1 {
        .action-button-text {
          color: #fd3b3d;
        }
      }
      .check-btn,
      .call-btn {
        .action-button-text {
          color: #05b3af;
        }
      }
      .raise-button {
        .action-button-text {
          color: #feb540;
        }
      }
    }
  }

  .raise-panel {
    position: absolute;
    right: -70px;
    bottom: 0px;
    z-index: 10;
    width: 630px;
    height: 1080px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    z-index: -1;
    // &.bg-black {
    //   background-image: linear-gradient(0deg, #2d2e42, #585b7e);
    //   .raise-panel-inner {
    //     .raise-amount {
    //       background-color: #303247 !important;
    //     }
    //   }
    // }
    &-inner {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: stretch;
      justify-content: stretch;
      flex-direction: column;
      border-radius: inherit;
      overflow: hidden;
      background: rgb(69, 12, 21);
      background: linear-gradient(
        0deg,
        rgba(69, 12, 21, 1) 0%,
        rgba(140, 30, 43, 1) 100%
      );
      .raise-amount {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 56px;
        font-weight: bold;
        height: 10%;
        width: 90%;
        text-align: center;
        border-radius: 10px;
        background-color: #3d0b14;
        box-shadow: 0 3px 0 #000000;
        margin: 20px 0 0 30px;
      }
      .interaction-area {
        position: relative;
        height: 89%;
        display: flex;
        justify-content: space-around;
        .fast-buttons {
          width: 55%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          padding-top: 0px;
          .fast-buttons-circle-icon {
            margin-bottom: 10px;
          }
          .fast-buttons-item,
          .fast-buttons-circle {
            text-transform: uppercase;
            margin-top: 50px;
            color: #f6aa3b;
            font-size: 46px;
            background: transparent;
            border: 5px solid #ffb53d;
            &:disabled {
              opacity: 0.5;
            }
            &:active {
              filter: brightness(70%);
            }
          }
          .fast-buttons-item {
            width: 280px;
            height: 90px;
            border-radius: 50px;
          }
          .fast-buttons-circle {
            height: 100px;
            width: 100px;
            border-radius: 50px;
            font-size: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            .minus {
              position: relative;
              margin-bottom: 35px;
            }
          }

          .fast-buttons-item:nth-child(1) {
            margin-top: 30px;
          }
        }
        .raise-slider-container {
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          margin-top: 30px;

          height: 100%;
          .chip-bar {
            .vue-slider {
              .vue-slider-rail {
                background-image: url("~@/assets/images/table/chip-progress-bar.png");
                width: 141px;
                height: 644px;
                background-size: cover;
                background-color: transparent;
                position: relative;
                right: 5px;
                .vue-slider-process {
                  background-color: transparent;
                  background-image: url("~@/assets/images/table/chip-container-bar.png");
                  width: 141px;
                  height: 644px;
                  background-size: cover;
                  background-color: transparent;
                }
                .vue-slider-dot {
                  .chip2 {
                    position: absolute;
                    right: -50px;
                    bottom: 5px;
                    z-index: 1;
                  }
                  // .chip2:nth-child(2) {
                  //   bottom: -10px;
                  //   z-index: 0;
                  // }
                }
              }
            }
            .chip-bar-amount {
              position: absolute;
              font-size: 36px;
              color: #ffb73e;
              z-index: 2;
              width: 60%;
              text-align: center;
            }
            .amount-0 {
              top: 5px;
            }
            .amount-1 {
              top: 110px;
            }
            .amount-2 {
              top: 250px;
            }
            .amount-3 {
              top: 450px;
            }
          }
          .raise-slider-button {
            background-color: transparent;
            transition: all 0.25s ease;
            &:disabled {
              opacity: 0.5;
            }
            &:active {
              filter: brightness(70%);
            }
          }
          .raise-slider-minus-button {
            position: absolute;
            right: 220px;
            bottom: 30px;
            transform: scale(1.1);
          }
          .raise-slider-plus-button {
            position: absolute;
            bottom: 30px;
            right: 30px;
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
</style>
