<template>
  <div
    class="leader-player"
    :class="playerIndex === 0 ? 'bg-black' : playerIndex === 1 ? 'bg-red' : ''"
  >
    <div class="leader-player-placement" :class="getClass()">
      {{ this.getPlacement }}
    </div>
    <div class="leader-player-no">
      {{ leaderBoardIndex ? leaderBoardIndex : "" }}
    </div>
    <div class="leader-player-flag">
      <div class="country" :style="getFlagSource(player.country)"></div>
    </div>
    <div class="leader-player-avatar">
      <Avatar :crown="-1" :url="player.avatar" size="80" :lazy="me" />
    </div>
    <div class="leader-player-name" :class="getClass()">{{ player.name }}</div>
    <div class="leader-player-chip" :class="getClass()">
      <div class="sprite-profile-popup chip"></div>
      {{ getChipFormat(player.chip) }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LeaderPlayer",
  components: {
    Avatar: () => import("@/components/Misc/Avatar"),
  },
  props: {
    playerIndex: {
      type: Number,
      default: -1,
    },
    player: {
      type: [Object, Boolean],
      default: false,
    },
    leaderBoardIndex: {
      type: Number,
      default: 0,
    },
    me: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getChipFormat"]),
    getBaseAvatarUrl() {
      return process.env.VUE_APP_AVATAR_URL;
    },
    getPlacement() {
      if (this.player.placement > 999) {
        return (this.player.placement = this.player.placement + "+");
      } else {
        return this.player.placement;
      }
    },
  },
  methods: {
    getClass() {
      if (!this.me) {
        return "textYellow";
      }
    },
    getFlagSource(country) {
      let styles = {};
      styles.width = "100px";
      styles.height = "100px";
      styles.marginLeft = "10px";
      if (!country || country == "undefined") return styles;
      styles.backgroundImage = `url(${require("@/assets/images/flags/" +
        country.toUpperCase() +
        ".png")}`;
      styles.backgroundSize = "contain";
      styles.backgroundRepeat = "no-repeat";
      styles.backgroundPosition = "center";
      return styles;
    },
  },
};
</script>

<style lang="scss" scoped>
.leader-player {
  width: 72%;
  min-height: 90px;
  border-radius: 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 36px;
  color: #ffffff;
  margin-bottom: 10px;
  &-no {
    width: 5%;
    position: relative;
    left: 25px;
  }
  &-flag {
    width: 15%;
    .country {
      width: 100px;
      height: 100px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-avatar {
    width: 8%;
    cursor: pointer;
  }
  &-name {
    margin-left: 20px;
    width: 42%;
  }
  &-chip {
    width: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .chip {
      position: relative;
      right: 10px;
      top: 3px;
    }
  }
}
.textYellow {
  color: #f5a325;
}
.bg-black {
  background: rgb(32, 6, 10);
  background: linear-gradient(
    0deg,
    rgba(32, 6, 10, 1) 0%,
    rgba(32, 6, 10, 1) 100%
  );
}
.bg-red {
  background: rgb(32, 6, 10);
  background: linear-gradient(0deg, #430d16 0%, #430d16 100%);
}
</style>
