<template>
  <div class="mute-container">
    <template v-if="getPlayers.length > 0">
      <div class="title">
        <span class="title-text">{{ $t("chatWindow.players") }}</span>
        <div class="hide-show-container">
          <span class="hide-show-container-text">{{
            $t("others.muteAll")
          }}</span>
          <div class="on-part" v-if="status" @click="muteAll">
            <div class="sprite-profile-popup button-red-s"></div>
            <span class="sprite-profile-popup circle-button right"></span>
          </div>
          <div class="off-part" v-if="!status" @click="muteAll">
            <div class="sprite-profile-popup button-green-s"></div>
            <span class="sprite-profile-popup circle-button"></span>
          </div>
        </div>
      </div>
      <div class="list">
        <div
          class="list-row"
          v-for="(player, playerIndex) in getPlayers"
          :key="'muted-player-' + playerIndex"
          @click="muteOrUnmutePlayer(player.id)"
        >
          <div class="player-avatar">
            <Avatar :url="player.avatar" :crown="player.crown" size="90" />
            <div class="player-name">{{ player.name }}</div>
          </div>

          <div
            class="mute-status"
            :class="[
              'sprite-table-pop-up',
              isMutedPlayer(player.id) ? 'sound-off-icon' : 'sound-on-icon',
            ]"
          ></div>
        </div>
      </div>
    </template>
    <template v-if="getAudiences.length > 0">
      <div class="title audiences">{{ $t("chatWindow.audiences") }}</div>
      <div class="list">
        <div
          class="list-row"
          v-for="(player, playerIndex) in getAudiences"
          :key="'muted-player-' + playerIndex"
          @click="muteOrUnmutePlayer(player.id)"
        >
          <div class="player-avatar">
            <Avatar :url="player.avatar" :crown="player.crown" size="90" />
            <div class="player-name">{{ player.name }}</div>
          </div>
          <div
            class="mute-status"
            :class="[
              'sprite-table-pop-up',
              isMutedPlayer(player.id) ? 'sound-off-icon' : 'sound-on-icon',
            ]"
          ></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Avatar from "@/components/Misc/Avatar";

export default {
  name: "Mute",
  components: {
    Avatar,
  },
  data() {
    return {
      status: false,
    };
  },
  computed: {
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
    }),
    getPlayers() {
      return [...this.getActiveRoomState.players].filter(
        (player) =>
          player.position > -1 &&
          player.id !== this.$store.state.user.id &&
          !player.ghost
      );
    },
    getAudiences() {
      return [...this.getActiveRoomState.players].filter(
        (player) =>
          player.position < 0 &&
          player.id !== this.$store.state.user.id &&
          !player.ghost
      );
    },
  },

  methods: {
    muteAll() {
      this.status = !this.status;
      if (!this.status) {
        this.$parent.$data.mutedPlayers.splice(
          0,
          this.$parent.$data.mutedPlayers.length
        );
      } else {
        this.$parent.$data.mutedPlayers = [
          ...this.getPlayers.map((player) => player.id),
          ...this.getAudiences.map((player) => player.id),
        ];
      }
    },
    muteOrUnmutePlayer(playerId) {
      if (this.$parent.$data.mutedPlayers.includes(playerId)) {
        const index = this.$parent.$data.mutedPlayers.findIndex(
          (mp) => Number(mp) === Number(playerId)
        );
        if (index > -1) this.$parent.$data.mutedPlayers.splice(index, 1);
        return;
      }
      this.$parent.$data.mutedPlayers.push(playerId);
    },
    isMutedPlayer(playerId) {
      return this.$parent.$data.mutedPlayers.includes(playerId);
    },
  },
};
</script>

<style lang="scss" scoped>
.mute-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  .audiences {
    justify-content: flex-start !important;
    padding-left: 20px;
    color: #e8672c;
  }
  .title {
    width: 100%;
    height: 10%;
    min-height: 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 36px;
    font-weight: bold;
    text-shadow: 0px 3px 2px black;
    text-transform: uppercase;
    border-bottom: 2px solid #691822;
    margin-bottom: 20px;
    .title-text {
      color: #e8672c !important;
    }
    .hide-show-container {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      .circle-button {
        position: absolute;
      }
      .on-part {
        .circle-button {
          top: -5px;
          right: 58px;
        }
      }
      .off-part {
        .circle-button {
          top: -6px;
          right: -10px;
        }
      }
      .hide-show-container-text {
        font-size: 36px;
        text-shadow: 0 3px 5px #000000;
        margin-right: 20px;
      }
    }
  }
  .list-row {
    width: 570px;
    height: 120px;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    align-items: center;
    margin-bottom: 10px;
    background: #3b0b14;
    border-radius: 10px;
    .mute-status {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }
    .player-avatar {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .player-name {
        font-size: 36px;
        margin-left: 20px;
      }
    }
  }
}
</style>
