import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";

const DeviceManager = {
  getId() {
    return Device.getId();
  },

  getInfo() {
    return Device.getInfo();
  },

  async getBatteryInfo() {
    if (Capacitor.getPlatform() === "web") {
      return {
        level: 100,
        charging: true,
      };
    }
    const batteryInfo = await Device.getBatteryInfo();
    return {
      level: batteryInfo.batteryLevel * 100,
      charging: batteryInfo.isCharging || false,
    };
  },

  async getLanguageCode() {
    return await Device.getLanguageCode();
  },
};

export default {
  instance: DeviceManager,

  install(Vue) {
    Vue.prototype.$plugins.device = DeviceManager;
  },
};
