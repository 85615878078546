export default [
  // {
  //   name: "Lobby1",
  //   url: "https://cdn.playturnpoker.com/app_sounds/lobby_music.mp3",
  //   file_name: "lobby_music.mp3",
  //   volume: 0.2,
  // },
  {
    name: "Canak",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/glass_breaking.mp3",
    file_name: "glass_breaking.mp3",
  },
  {
    name: "TurnStart",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/turnstart.mp3",
    file_name: "turnstart.mp3",
  },
  {
    name: "Call",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/call2.mp3",
    file_name: "call2.mp3",
  },
  {
    name: "Raise",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/raise.mp3",
    file_name: "raise.mp3",
  },
  {
    name: "Fold",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/fold4.mp3",
    file_name: "fold4.mp3",
  },
  {
    name: "Check",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/check3.mp3",
    file_name: "check3.mp3",
  },
  {
    name: "Win",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/winchips.mp3",
    file_name: "winchips.mp3",
  },
  {
    name: "Flip",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/flip_d.mp3",
    file_name: "flip_d.mp3",
  },
  {
    name: "Shuffle",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/shuffle.mp3",
    file_name: "shuffle.mp3",
  },
  {
    name: "TurnReminder",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/turnreminder.mp3",
    file_name: "turnreminder.mp3",
    volume: 0.3,
  },
  {
    name: "TurnReminder2",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/turnreminder2.mp3",
    file_name: "turnreminder2.mp3",
  },
  {
    name: "Silence",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/silence.mp3",
    file_name: "silence.mp3",
  },
  {
    name: "Tip",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/tip-sound.mp3",
    file_name: "tip-sound.mp3",
  },
  {
    name: "IfadeBomba",
    url: "https://cdn.playturnpoker.com/gift_sounds/IfadeBomba.mp3",
    file_name: "IfadeBomba.mp3",
  },
  {
    name: "IfadeOpucuk",
    url: "https://cdn.playturnpoker.com/gift_sounds/IfadeOpucuk.mp3",
    file_name: "IfadeOpucuk.mp3",
  },
  {
    name: "IfadeTokat",
    url: "https://cdn.playturnpoker.com/gift_sounds/IfadeTokat.mp3",
    file_name: "IfadeTokat.mp3",
  },
  {
    name: "IfadeYumruk",
    url: "https://cdn.playturnpoker.com/gift_sounds/IfadeYumruk.mp3",
    file_name: "IfadeYumruk.mp3",
  },
  {
    name: "IfadeYumurta",
    url: "https://cdn.playturnpoker.com/gift_sounds/IfadeYumurta.mp3",
    file_name: "IfadeYumurta.mp3",
  },
  {
    name: "IfadeMendil",
    url: "https://cdn.playturnpoker.com/gift_sounds/IfadeMendil.mp3",
    file_name: "IfadeMendil.mp3",
  },
  {
    name: "IfadeSilah",
    url: "https://cdn.playturnpoker.com/gift_sounds/IfadeSilah.mp3",
    file_name: "IfadeSilah.mp3",
  },
  {
    name: "IfadeKalp",
    url: "https://cdn.playturnpoker.com/gift_sounds/IfadeKalp.mp3",
    file_name: "IfadeKalp.mp3",
  },
  {
    name: "IfadeDomates",
    url: "https://cdn.playturnpoker.com/gift_sounds/IfadeDomates.mp3",
    file_name: "IfadeDomates.mp3",
  },
  {
    name: "IfadeBira",
    url: "https://cdn.playturnpoker.com/gift_sounds/IfadeBira.mp3",
    file_name: "IfadeBira.mp3",
  },
  {
    name: "Jackpot",
    url: "https://cdn.playturnpoker.com/builds/assets/sounds/jackpot.mp3",
    file_name: "jackpot.mp3",
  },
  {
    name: "BjSit",
    url: "https://cdn.playturnpoker.com/blackjack_sounds/sit.mp3",
    file_name: "sit.mp3",
  },
  {
    name: "BjBet",
    url: "https://cdn.playturnpoker.com/blackjack_sounds/bet.mp3",
    file_name: "bet.mp3",
  },
  {
    name: "BjCard",
    url: "https://cdn.playturnpoker.com/blackjack_sounds/card.mp3",
    file_name: "card.mp3",
  },
  {
    name: "BjClick",
    url: "https://cdn.playturnpoker.com/blackjack_sounds/click.mp3",
    file_name: "click.mp3",
  },
  {
    name: "BjReminder",
    url: "https://cdn.playturnpoker.com/blackjack_sounds/reminder.mp3",
    file_name: "reminder.mp3",
  },
  {
    name: "BjReminder2",
    url: "https://cdn.playturnpoker.com/blackjack_sounds/reminder2.mp3",
    file_name: "reminder2.mp3",
  },
  {
    name: "BjWin",
    url: "https://cdn.playturnpoker.com/blackjack_sounds/win.wav",
    file_name: "win.wav",
  },
  {
    name: "Bonus",
    url: "https://cdn.playturnpoker.com/app_sounds/bonus.mp3",
    file_name: "bonus.mp3",
  },
  {
    name: "JackpotWon",
    url: "https://cdn.playturnpoker.com/app_sounds/jackpot_won.mp3",
    file_name: "jackpot_won.mp3",
  },
  {
    name: "ToLobby",
    url: "https://cdn.playturnpoker.com/app_sounds/to_lobby.mp3",
    file_name: "to_lobby.mp3",
    volume: 1,
  },
  {
    name: "MessageSent",
    url: "https://cdn.playturnpoker.com/app_sounds/message_sent.mp3",
    file_name: "message_sent.mp3",
  },
  {
    name: "ParrotThankYou",
    url: "https://cdn.playturnpoker.com/app_sounds/parrot_thank_you.wav",
    file_name: "parrot_thank_you.wav",
  },
  {
    name: "SliderSwipe",
    url: "https://cdn.playturnpoker.com/app_sounds/slider-swipe.mp3",
    file_name: "slider-swipe.mp3",
  },
  {
    name: "StoreOpen",
    url: "https://cdn.playturnpoker.com/app_sounds/store_open.mp3",
    file_name: "store_open.mp3",
    volume: 0.5,
  },
  // {
  //   name: "Lobby2",
  //   url: "https://cdn.playturnpoker.com/app_sounds/Mysterious%20Funk.mp3",
  //   file_name: "Mysterious%20Funk.mp3",
  //   volume: 0.2,
  // },
  {
    name: "Button",
    url: "https://cdn.playturnpoker.com/app_sounds/button_open_new.wav",
    file_name: "button.wav",
  },
  {
    name: "jp_win_big",
    url: "https://cdn.playturnpoker.com/app_sounds/jp_win_big.wav",
    file_name: "jp_win_big.wav",
  },
  {
    name: "friend_chip_send",
    url: "https://cdn.playturnpoker.com/app_sounds/friend_chip_send.wav",
    file_name: "friend_chip_send.wav",
  },
  {
    name: "lobby_one_click",
    url: "https://cdn.playturnpoker.com/app_sounds/lobby_one_click.wav",
    file_name: "lobby_one_click.wav",
    volume: 0.3,
  },
  {
    name: "button_open_window",
    url: "https://cdn.playturnpoker.com/app_sounds/button_open_new.wav",
    file_name: "button_open_window.wav",
  },
  {
    name: "button_close_new",
    url: "https://cdn.playturnpoker.com/app_sounds/button_close_new.wav",
    file_name: "button_close_new.wav",
  },
  //expression audios
  {
    name: "yumurta",
    url: "https://cdn.playturnpoker.com/app_sounds/expression/yumurta.mp3",
    file_name: "expression/yumurta.wav",
  },
  {
    name: "yumruk",
    url: "https://cdn.playturnpoker.com/app_sounds/expression/yumruk.mp3",
    file_name: "expression/yumruk.wav",
  },
  {
    name: "tokat",
    url: "https://cdn.playturnpoker.com/app_sounds/expression/tokat.mp3",
    file_name: "expression/tokat.wav",
  },
  {
    name: "silah",
    url: "https://cdn.playturnpoker.com/app_sounds/expression/silah.mp3",
    file_name: "expression/silah.wav",
  },
  {
    name: "opucuk",
    url: "https://cdn.playturnpoker.com/app_sounds/expression/opucuk.mp3",
    file_name: "expression/opucuk.wav",
  },
  {
    name: "kalp",
    url: "https://cdn.playturnpoker.com/app_sounds/expression/kalp.mp3",
    file_name: "expression/kalp.wav",
  },
  {
    name: "domates",
    url: "https://cdn.playturnpoker.com/app_sounds/expression/domates.mp3",
    file_name: "expression/domates.wav",
  },
  {
    name: "bomba",
    url: "https://cdn.playturnpoker.com/app_sounds/expression/bomba.mp3",
    file_name: "expression/bomba.wav",
  },
  {
    name: "bira",
    url: "https://cdn.playturnpoker.com/app_sounds/expression/bira.mp3",
    file_name: "expression/bira.wav",
  },
  {
    name: "aglama",
    url: "https://cdn.playturnpoker.com/app_sounds/expression/aglama.mp3",
    file_name: "expression/aglama.wav",
  },

  {
    name: "gul",
    url: "https://cdn.playturnpoker.com/app_sounds/expression/gul.wav",
    file_name: "expression/gul.wav",
  },
  {
    name: "konfeti",
    url: "https://cdn.playturnpoker.com/app_sounds/expression/konfeti.mp3",
    file_name: "expression/konfeti.wav",
  },
  {
    name: "salatalik",
    url: "https://cdn.playturnpoker.com/app_sounds/expression/salatalik.mp3",
    file_name: "expression/salatalik.wav",
  },
];
