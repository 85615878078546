/*
 * Malaysian language file
 */

export default {
  loading: "Memuatkan...",

  general: {
    no: "Tidak",
    tableInformation: "Informasi",
    yes: "Ya",
    avatar: "Avatar",
    vipIcon: "Ikon Vip",
    cancel: "Batalkan",
    save: "Simpan",
    logout: "Keluar",
    play: "Bermain",
    chat: "Sembang",
    leaders: "Senarai Teratas",
    social: "Sosial",
    case: "Peti",
    shop: "Kedai",
    okay: "OK",
    refresh: "Segarkan Semula",
    send: "Hantar",
    username: "Nama Pengguna",
    delete: "Padam",
    reject: "Menolak",
    accept: "Menerima",
    confirm: "Mengesahkan",
    registrationDate: "Tarikh Pendaftaran",
    name: "Nama",
    total: "Jumlah",
    copy: "Salinan",
    commission: "Commission",
    getGift: "Ambil Hadiah",
    transfer: "Hantar",
    menu: "Menu",
    update: "Kemas kini",
    invite: "Jemput",
    joinNow: "Kongsikan",
    handHistory: "Rekod Kad", //NEW***
  },
  giftsWindow: {
    gift: "Hadiah", //NEW***
    getYourself: "Untuk Anda", //NEW***
    getEveryone: "untuk Semua", //NEW***
    expression: "Emoji", //NEW***
    selectGift: "Pilih hadiah", //NEW***
    noPlayersAtTable: "Tak ada pemain di meja", //NEW***
    sitATable: "Sila duduk terlebih dahulu.", //NEW***
    selectUser: "sila pilih pengguna", //NEW***
  },
  dailyEntry: {
    dailyEntry: "Bonus tiap hari ", //NEW***
    dailyDesc: "Log masuk tiap hari dan dapatkan lebih banyak cip!", //NEW***
    day: "Hari", //NEW***
  },

  noficationWindow: {
    notificationInviteTable:
      "{name} menjemput anda ke meja {gameType}{tableId} pada {date}", // NEW**
    notificationInviteChip: " {name} menghantar anda {amount} cip.", // NEW***
    sendMessage: " Taip mesej disini", // NEW***
    tableInvite: "Meja Jemputan", // NEW***
    system: "Sistem", // NEW***
    messages: "Mesej", // NEW***
    notifications: "Pemberitahuan", // NEW***
  },
  authentication: {
    signInToContinue: "Sign-in guna meneruskan",
    anErrorOccuredWhileLoggingIn: "Kesalahan berlaku semasa login!",
    contact: "Kenalan",
    privacyPolicy: "Dasar privasi",
    provablyFair: " Provably Fair",
    sign_guest: "Mod Tetamu",
    sign_apple: "Masuk guna Apple",
    sign_google: "Masuk guna Google",
    sign_facebook: "Masuk guna Facebook",
    loginBonusText:
      "<p class='login-info-text'> Boleh dapat bonus cip! <span class='color-white'> {0} </span> atau <span class='color-white'> {1} </span> </p>",
    loginBonusTextWeb:
      "<p class='login-info-text'> Boleh dapat bonus cip! <span class='color-white'> {0} </span> </p>",
  },
  playerActions: {
    enoughChips: "Cip Anda tidak mencukupi",
    minSendChipAmount: "Jumlah cip kurang daripada 10B tidak boleh dihantar",
    failedTransfer: "Menghantar cip gagal",
    successTransfer: "Hantaran cip berjaya.",
    validChip: "Sila taip jumlah yang sah",
  },
  tableList: {
    table: "Meja",
    tableId: "ID Meja",
    tableName: "Nama",
    blind: "Blind",
    minMax: "Min/Maks",
    filterByBlinds: "Saring dengan Blind",
    hideEmpties: "Tutup meja kosong",
    hideFullyFilled: "Tutup meja penuh",
    all: "Semua",
    players: "Pemain",
    full: "Penuh",
    noRoomsMessage: "Tidak ada meja.",
    tableList: "Senarai Meja",
    tableInfo: {
      emptyRoomMessage: "Meja kosong.",
    },
  },
  message: {
    message: "mesej",
    sendMessage: "Hantar Mesej",
    typeMessage: "Taip mesej Anda",
    sentMessage: "Menghantar Anda mesej",
  },

  lobby: {
    playNow: " Terus main ",
    newSlotGame: "Permainan Slot Baharu",
    availableNow: "Sudah Siap",
    specialForYou: "Khas Untuk Anda",
    play: "Bermain",
    favouriteGameButton: {
      instructionMessage: "ketuk dua kali guna ubah",
    },
  },

  bonusWindow: {
    congratulations: "Tahniah!",
    accept: "Menerima",
  },

  maintenance: {
    gameMaintenance: "Permainan dalam penyelenggaraan",
    underMaintenance: "Tengah dalam penyelenggaraan",
    tryAgainLAter: "Permainan dalam penyelenggaraan. Sila cuba lagi nanti!",
  },

  confirmWindow: {
    sure: "Adakah Anda pasti?",
    userNotFound: "Pengguna tidak ditemui",
    notAffiliate: "Pengguna bukan Referral",
    DaysVip: "Melanggan VIP untuk masa 30 hari",
  },
  deleteConfirmWindow: {
    sure: "Anda akan memadamkan akaun anda",
    content:
      "Jika anda meneruskan proses, maklumat pengguna anda dan produk yang anda beli akan dipadamkan",
    delete: "Padam",
    cancel: "Batalkan",
    deleteSuccessfully: "Pemadaman akaun berjaya",
    notBeDeleted: "Buat ralat semasa memadam",
    daysLimit:
      "Jika akaun anda tak aktif sampai 30 hari, akaun akan dipadam kekal.", //NEW****
    areYouSure: "Adakah pasti mahu padam akaun anda?",
    deletefriends: "Rakan akan dipadam dari pada daftar rakan",
  },
  historyWindow: {
    hand: "Tangan Kad",
    preFlop: "Pre-Flop",
    flop: "Flop",
    turn: "Turn",
    river: "River",
    winner: "Pemenang",
    speacial: "Spesial",
    team: "Tim",
    anyHistory: "Tiada rekod untuk ditunjukkan",
  },
  userProfile: {
    noteUpdated: "Nota profil anda telah dikemas kini",
  },
  socialWindow: {
    gameCardDesc: "Menangi bonus daripada tiket yang anda punyai.",
    inviteCodeDescription:
      "- Kongsikan kod jemputan di atas dengan kawan. <br><br>- Bagi masa untuk kawan baharu anda bermain 10 tangan di meja poker. <br><br>- Dua-dua sekali mendapat 10B cip!<br>",
    sharecode: "Kongsi Kod", // NEW***
    friends: "Rakan",
    searchFriend: "Cari kawan",
    gameCard: "Kad game",
    requests: "Permohonan",
    chipTransferHistory: "Sejarah Hantaran Cip",
    socialMedia: "Media Sosial",
    socialMediaAndInviteCode: "Media sosial & Kod Jemputan",
    noFriendRequest: "Anda tiada mempunyai permohonan rakan.",
    noFriends: "Anda tiada mempunyai rakan.",
    noChipTransfer: "Tiada rekod hantaran cip",
    extraChip: "Ikuti akaun media sosial kami dan dapatkan cip percuma",
    followUs: "Ikuti kami dekat Media Sosial!",
    inviteCode: "Kod Jemputan",
    myCode: "My Code",
    inviteCodeDescription:
      "Kongsi kod jemputan di atas dengan rakan anda. <br><br>- Biarkan rakan anda sebagai ahli baharu, bermain 10 tangan di meja poker. <br><br>- Anda berdua memenangi cip 10B!<br><small>Kod jemputan tak tersedia untuk akaun tetamu.</small>",
    shareWithFriends: "Bagikan dengan teman!",
    shareWithFriendsCode:
      "Dapatkan cip bonus 10B selepas rakan Anda mainkan 10 tangan poker. Makin banyak kod jemputan anda hantar, lagi banyak cip bonus yang akan anda dapatkan. Code: {code}",
    shareInviteCodeWithFriends: "Kongsikan kode undangan anda",
    removeFriend: "Padam rakan",
    block: "Sekat",
    unBlock: "Menyahsekat",
  },
  friendDetailsWindow: {
    failed: "Gagal menghantar permohonan rakan",
  },
  chipHistoryDetail: {
    youSent: "Anda menghantar",
    chips: "Cip",
    sentYou: "menghantar Anda",
  },
  createTableWindow: {
    bets: "Taruhan",
    createTable: "Membuat Meja",
    seatsNumber: "Nombor duduk",
    potTable: "Meja Periuk",
    teamTable: "Meja Tim",
    tellerEntrance: "Gate Masuk",
  },
  chipTransferWindow: {
    sendChip: "Hantar Cip",
    chipAmount: "Masukkan jumlah cip",
    chipMessage: "Taip mesej di sini",
    warning: "*Minimum penghantaran cip 10B dan tiada had maksimum!",
  },
  playerDetailsWindow: {
    profileNote: "Nota Profil",
    statistics: "Statistik",
    level: "Level",
    recordChip: "Cip Rekod",
    friendsCount: "Jumlah Rakan",
    bestHand: "Terbaik",
    copied: "disalin!",
    failedChanges: "Gagal menyimpan ubahan",
    enterProfileNote: "Taip nota profil anda..", //NEW****
    gameStatistics: "Statistik Permainan", //NEW****
    sameFrameWarning: "Pilih bingkai lain dari semula.", //NEW****
    sameAvatarWarning: "Pilih avatar lain dari semula.", //NEW****
    fillFields: "Sila isi semua kolum", //NEW****
    passwordsDoesNotMatch: "Kata laluan tidak sesuai", //NEW****
    passwordLength: "Kata laluan kena ada 6 sehingga 30 karakter",
    changeName:
      "Hak anda guna menukar nama anda telah tamat tempoh. Anda boleh melanggan VIP dan cuba lagi",
  },
  shopWindow: {
    bronze: "Bronze",
    silver: "Silver",
    gold: "Gold",
    redeem: "tebus",
    keysField: "Sila masukkan kod kad permainan.",
    withoutChip: "Masuk Meja Tanpa Cip",
    buyChip: "Membeli Cip",
    redeemCode: "Kod Tebus",
    noProduct: "Tiada barang untuk ditunjukkan.",
    buyVip: "Membeli Vip",
    privateEmojis: "VIP Emoji, Gift dan Bingkai Profil",
    privateProfile: "Bingkai Profil Spesial VIP",
    connectionNeverLost: "Sambungan tiada henti",
    unLimitedView: "Masa Pengawasan Tiada Had",
    privateTable: "Membuat Meja Spesial",
    unlimitedChipTransfer: "Unlimited Chip Transfer",
    usingPrivateCase: "Menggunakan Peti khas",
    xp: "10.000XP Gift",
    freeChat: "Bebas Sembang",
    day: "Hari",
    daysLeft: "Sisa Hari",
    alreadyVip: "Anda sudah melanggan VIP.",
    noChips: "Cip anda tak cukup untuk melanggan VIP",
    successfullyPurchased: "Anda berjaya melanggan VIP.",
    chipAdded: "Cip telah berjaya diterima",
  },
  caseWindow: {
    onlyVip: "Ciri ini setakat untuk pengguna VIP",
    buyVip: "Membeli VIP",
    chipInGame: "Cip Dalam Game",
    chipInCase: "Cip Dalam Peti",
    transactionFailed: "Transaksi gagal",
    theretentation: "Sembunyikan cip di Leaderboard dan  pemain lain.",
    fromGame: "Daripada Permainan",
    fromCase: "Daripada Peti",
    amount: "Jumlah",
    transaction: "Urusan sudah berjaya.",
    enoughChip: "Cip anda tak cukup guna deposit peti.",
    withdraw: "Cip anda tak cukup guna penarikan daripada peti.",
    validAmount: "masukkan jumlah sah",
    transfer: "Transfer",
  },
  friendsRequestWindow: {
    friendsOffer: "ANDA MENERIMA PERMOHONAN RAKAN!",
    successRequest: "Permohonan rakan sudah dihantar.",
    acceptRequest: "telah menerima permohonan rakan anda.",
    rejectedRequest: "telah menolak permohonan rakan anda.",
    becomeFriends: "menghantar anda permohonan rakan! Adakah mahu berkawan?",
    requestSent: "Permohonan sudah dihantar",
    friendRequestToYou: "menghantar anda permintaan kawan.",
  },
  settingsWindow: {
    language: "Bahasa",
    gameSettings: "Tetapan Permainan",
    customize: "Menyesuaikan",
    security: "Keselamatan",
    successfullySaved: "Simpan ubahan berjaya.",
    pinCode: {
      pinCode: "KATA LALUAN",
      createPinDesc: "Buat Kata Laluan guna keselamatan akaun",
      reEnterPin: "Masukkan semula Kata Laluan",
      emailAddress: "Alamat E-Mail",
      areYouSure: "Adakah pasti akan memadam kod pin anda?",
      emailRequired: "Diperlukan guna mengatur semula Kata Laluan anda",
      pinDesc:
        "Kod pin adalah sangat penting untuk keselamatan akaun. Turn Games tidak akan menerima sebarang repot melainkan anda mempunyai kod pin pada akaun anda",
    },
    customizeWindow: {
      tableStyle: "Gaya Meja",
      tableColors: "Warna Meja",
      roomBgColor: "Warna Latar Belakang",
    },
    gameSettingsWindow: {
      dontDistrub: "Jangan Kacau",
      blockNotification: "Pemberitahuan",
      showAnimatedGifts: "Tunjukkan Gifts Animasi",
      newVersion: "Versi baharu",
    },
  },
  reportWindow: {
    report: "Repot",
    reportFilter: "Saring repot",
    audiences: "Khalayak",
    players: "Pemain",
    successMessage: "Repot bejaya dihantar.",
    failedMessage: "Repot gagal dihantar.",
    swearing: "menghinakan",
    team: "Tim",
    scissors: "Kenaikan berganda",
    noPlayersFound: "Tiada pemain atau khalayak.",
  },
  inboxContact: {
    contactUs: "Hubungi Kami",
    title: "Tajuk",
    textHere: "Taip di sini",
    congratulations: "Tahniah",
    inbox: "Peti Masuk",
    desc: "Anda mendapat 10,000 cip daripada paket pembelian terakhir.",
    failedMessage: "Gagal menghantar mesej",
    successfullyMessage: "Mesej sudah dihantar",
    validEmail: "Sila masukkan Email sah",
    fillTabs: "Mohon isi semua kolum",
  },
  errors: {
    connectionLost: "Sambungan hilang",
    yourConnection: "Sambungan anda hilang.",
    errorOccured: "Kesalahan sudah berlaku",
    gameServiceError:
      "Kesalahan berlaku pada pengkhidmatan permainan. Sila cuba lagi",
    slotDownloadError: "Kesalahan berlaku semasa memuat permainan.",
    loginError: "Kesalahan berlaku semasa login.",
    insufficientBalance: "Baki Anda tidak cukup.",
    tooManyRequests: "Anda tidak boleh menghantar terlalu sering.",
    haveToSitAtTable: "Anda kena duduk dekat meja.",
    youAreBlocked: "You are blocked.",
    youAreBlockedTheUser: "Anda telah menyekat pengguna",
    youAreUnblockedTheUser: "Anda telah menyahsekat pengguna",
    userNotActiveGame: "Pada masa ini, user bukan dalam permainan",
    notEnoughLevelPlayGame:
      "Diperlukan level {level}  guna berkongsi pada meja {game}.",
    tableNotFound: "tableNotFound",
    playerIdWrong: "ID Referral tidak betul",
    searchedUserNotFound: "ID referral tidak ditemui",
  },
  searchWindow: {
    searchUser: "Mencari pengguna",
    idOfUser: "Sila masukkan ID pengguna.",
    search: "pencarian",
    ownId: "Ini adalah ID anda",
    validId: "Sila masukkan ID sah",
  },
  adBonusWindow: {
    title: "AMBIL CIP PERCUMA!",
    content: "Tengok video pendek dan boleh dapat ${money} cip!",
    watchButton: "Tengok",
  },
  affiliate: {
    affiliateDesc: "Masukkan ID Referral rakan anda.",
    affiliateOffDesc: "ID Referral anda",
    affiliate: "Referral",
    addAffiliate: "Tambah Ahli kongsi",
    affiliateId: "Masukkan ID Referral",
    clear: "Padam",
    searchBy: "Cari dengan",
    name: "Nama",
    noData: "Tiada data",
    startDate: "Masa memulakan",
    endDate: "Masa berhenti",
    warning: "Jadilah rakan kongsi kami dan buat steady keuntungan anda!",
    info: "Pelajari lebih lanjut cara berkongsi dengan kami.",
    failedAffiliate: "Gagal tambah referral",
    successfullyAdded: "Tambah ID Referral berjaya.",
    date_wrong: "Tarikh mula kena simpan sebelum tarikh tamat",
    affiliateIdTitle: "ID Referral",
    excelDownloaded: "Dimuat turun ke Dokumen",
  },
  chat: {
    worldChat: "Sembang Global",
    directChat: "sembang Peribadi",
    onlyVip: "Setakat pengguna VIP boleh hantar mesej dekat Sembang Global",
    showMore: "Tunjukkan",
  },
  disconnectedWindow: {
    disconnected: "Tiada hubungan",
    disconnectedServer: "Oops… Anda tak tersambung dengan layanan!",
    reconnect: "menyambung semula",
    inactive: "Oops…Anda tidak bersambung beberapa saat!",
    reconnected: "SAMBUNG SEMULA",
    banned: "Anda sudah diharamkan daripada permainan!",
  },

  rulesWindow: {
    texasDesc:
      "Texas Hold'em Poker ialah permainan kad paling popular di dunia. Objektif permainan adalah untuk menang dengan tangan yang lebih tinggi daripada lawan anda. Terdapat 2 kad di tangan anda dan 5 kad di atas meja, tangan yang membuat kombinasi 5 kad terbaik daripada jumlah 7 kad menang. Setiap tangan dalam senarai di bawah mengalahkan tangan berikut. Contohnya, Straight Flush mengalahkan Four of Kind, Straight mengalahkan Pair. Tangan dikira mengikut kekuatannya.",
    omahaDesc:
      "Omaha ialah salah satu jenis poker yang paling popular di dunia. Objektif permainan adalah untuk memenangi periuk. Terdapat 2 cara utama untuk mendapatkan cip: memenangi kad poker yang lebih tinggi daripada tangan lawan atau menunggu hantaran lawan atau menolak permainan. Satu tangan mengandungi 5 kad. Peraturannya ialah tangan mesti mengandungi tiga daripada lima kad komuniti di atas meja dan dua daripada empat kad yang diedarkan kepada pemain. Semua tangan dalam kategori dalam senarai mengalahkan semua tangan dalam kategori di bawahnya. Setiap tangan dalam senarai di bawah mengalahkan tangan berikut. Contohnya, Straight Flush mengalahkan Four of Kind, Straight mengalahkan Pair. Tangan dikira mengikut kekuatannya.",
    blackJackDesc:
      "Matlamat Turn Blackjack adalah untuk mempunyai kad dengan mata yang lebih tinggi daripada dealer tanpa melebihi 21. Tangan terbaik dipanggil Blackjack - jumlah nilai dua kad pertama yang diuruskan dalam tangan ini ialah 21. Anda hanya bermain menentang dealer, bukan menentang pemain lain. Blackjack dimainkan dengan lapan dek. Dealer sentiasa berhenti pada mata 17. Boleh digandakan pada mana-mana dua kad permulaan. Kad permulaan dengan nilai yang sama boleh dibahagikan kepada dua. Pembahagian boleh dilakukan oleh setiap tangan. Satu kad diberikan untuk setiap ace berganda. Penggandaan tidak boleh dilakukan selepas berurusan dengan kad. Insurans disyorkan apabila dealer mempunyai ace. Blackjack membayar 3:2. Insurans membayar 1:2. Apabila tangan sama, ia adalah seri.",
    handNames: {
      royalFlush: "Royal Flush",
      straightFlush: "Straight Flush",
      fourOfAKind: "Four Of A Kind",
      fullHouse: "Full House",
      flush: "Flush",
      straight: "Straight",
      threeOfAKind: "Three Of A Kind",
      twoPair: "Two Pair",
      pair: "One Pair",
      highCard: "High Card",
    },
    handDesc: {
      royalFlush: "Royal flush adalah tangan paling tinggi poker.",
      straightFlush:
        "Lima kad berurut dengan jenis sama. Di antara 2 tangan Straight Flush, pemenangnya ialah yang memiliki kad paling tinggi.",
      fourOfAKind:
        "Empat kad yang sama pangkat. Gabungan yang paling kuat ialah gabungan dengan kad nilai yang lebih tinggi. Jika pemain mempunyai tangan yang sama, nilai kad kelima (kicker) menentukan pemenang.",
      fullHouse:
        "Gabungan tiga kali ganda dan berpasangan. Apabila membandingkan tangan Full House, pemain dengan tiga kad terbaik menang. Jika mereka sama, pemain yang mempunyai pasangan terbaik menang.",
      flush:
        "Lima kad tidak tersusun dengan sut yang sama. Jika dua atau lebih pemain mempunyai saman yang sama, pemain dengan kad tertinggi menang.",
      straight:
        "Lima kad berturut-turut dua atau lebih sut berbeza. Di antara dua tangan straight, pemenang adalah yang mempunyai kad nilai tertinggi.",
      threeOfAKind:
        "Gabungan tiga kad yang sama pangkat. Daripada dua trio, pemenang adalah yang mempunyai kad nilai tertinggi. Jika trio ini mempunyai markah yang sama, lebih tinggi daripada dua kicker yang tinggal menentukan pemenang.",
      twoPair:
        "Dua kad pangkat yang sama ditambah dua kad pangkat lain. Apabila membandingkan pasangan, pasangan tertinggi menentukan pemenang. Jika pasangan tinggi mempunyai nilai yang sama, pasangan rendah dibandingkan. Jika pasangan yang lebih rendah mempunyai nilai yang sama, pemain dengan nilai kad kelima yang lebih tinggi (kicker) menang.",
      pair: "Dua kad yang sama pangkat. Jika pemain mempunyai pasangan yang sama, yang tertinggi daripada tiga baki kad (kicker) menentukan pemenang.",
      highCard:
        "Tangan poker yang tidak sepadan dengan keperluan tangan yang diterangkan sebelum ini. Jika pemain mempunyai kad tinggi yang sama, kad tinggi kedua (dll.) menentukan pemenang.",
    },
    expressions: {
      blinds: {
        heading: "Blind",
        desc: "Sebelum kad diuruskan, pemain yang duduk di sebelah kiri di sebelah kiri peniaga mesti membuat pertaruhan yang dipanggil blind kecil. Pemain seterusnya yang duduk di sebelah kiri blind kecil mesti meletakkan apa yang dipanggil blind besar. Jumlah blind besar adalah dua kali ganda jumlah blind kecil.",
      },
      ante: {
        heading: "Ante",
        desc: "Jika pemain menyertai permainan, dia duduk di meja dan tidak berada dalam big blind, pemain itu meletakkan pertaruhan paksa yang dipanggil ante. Ia boleh dilakukan sekali dan taruhan ante adalah sama dengan blind besar.",
      },
      minimumRaise: {
        heading: "Minimum Raise",
        desc: "Pemain tidak boleh menaikkan kurang daripada blind besar.",
      },
      allIn: {
        heading: "All In",
        desc: "Jika pemain tidak mempunyai cip yang mencukupi untuk menyemak pertaruhan, pemain boleh buat 'all-in'. Pertaruhan pemain dengan semua yang tinggal. Jika pemain buat all-in, pemain tidak boleh memenangi lebih banyak cip daripada yang dia boleh lihat. Jika periuk naik selepas pemain masuk semua dan menang, periuk akan dibahagikan antara pemain itu dan pemain dengan tangan terbaik seterusnya untuk dinaikkan..",
      },
      potSplit: {
        heading: "Kongsi Periuk",
        desc: "Jika dua atau lebih pemain seri, periuk dibahagikan sama rata antara mereka.",
      },
      bjGameRules: {
        heading: "Peraturan Permainan",
        desc: "Permainan ini diuruskan oleh dealer dan bilangan pemain yang tiada terhad boleh dimainkan setiap tangan. Permainan ini dipersembahkan oleh dealer dan dimainkan dengan lapan dek standard 52 kad. Nilai 21 kad dalam permainan adalah seperti berikut. Kad dari 2 hingga 10 mempunyai nilainya tertulis padanya. Kad gambar (Jack, Queen dan King) bernilai 10 mata. Ace bernilai 1 atau 11, pemain boleh memilih nilai yang sesuai dengan tangannya. Ambil perhatian bahawa soft hand mengandungi 11 nilai Aces. Apabila masa pertaruhan telah tamat, dealer memberikan kad muka kepada semua pemain, yang terakhir kepada dirinya sendiri. Selepas itu dealer memberikan kad kedua menghadap ke atas kepada semua pemain, tetapi kad kedua dealer akan menghadap ke bawah. Nilai tangan pertama anda dipaparkan di sebelah kad anda. Blackjack (Dua Puluh Satu) Jika dua kad yang dibagi pada permulaan permainan berjumlah 21, anda mempunyai Blackjack!",
        descInsurance:
          "Jika kad muka dealer ialah ace, anda boleh membeli insurans terhadap risiko dealer mempunyai 21, walaupun jumlah tangan anda ialah 21. Amaun insurans akan bersamaan dengan separuh daripada pertaruhan utama anda dan pertaruhan insurans akan diproses secara berasingan daripada pertaruhan tangan anda. Selepas itu, dealer melihat nilai kad bawahnya untuk memeriksa sama ada dia membuat 21. Jika peniaga tidak mencapai 21, pusingan diteruskan. Jika dealer mempunyai Blackjack, permainan tamat dan dealer menang. Jika anda dan dealer mempunyai 21, permainan akan berakhir dengan seri dan pertaruhan anda akan dikembalikan. Ambil perhatian bahawa jika kad muka ke atas dealer ialah kad 10 atau kad raja, anda akan mempunyai pilihan untuk membeli insurans dan dealer tidak akan melihat kad bawahnya untuk 21.",
        headingInsurance: "Insurans",
        headingHit: "Double, Hit atau Stand",
        descHit:
          "Jika dealer mendapati bahawa selepas menyemak dua kad pertamanya dia belum membuat 21, pemain diberi hak untuk memilih lebih banyak kad untuk meningkatkan nilai tangan mereka. tidak bernilai 21, anda mempunyai hak untuk menggandakan pertaruhan. Dalam kes ini, pertaruhan anda akan digandakan dan anda hanya akan diberikan satu kad tambahan untuk ditambahkan pada tangan anda. Sebagai alternatif, anda boleh menggunakan pilihan Hit kad untuk mendapatkan kad tambahan untuk menambah nilai pada tangan anda. Anda boleh menambah beberapa kad sehingga anda berpuas hati dengan nilai tangan anda dan memutuskan untuk Stand. Ambil perhatian bahawa pemain dengan soft hand bersamaan dengan 21 tidak akan diberi pilihan keputusan.",
        headingSplit: "Bahagi Dua",
        descSplit:
          "Jika tangan pertama anda terdiri daripada dua kad yang mempunyai kedudukan yang sama, anda boleh membahagikan pasangan itu kepada dua tangan yang berbeza, setiap satunya mempunyai kepentingan yang sama dengan pertaruhan utama anda. Selepas kedua-dua tangan diberikan kad kedua, anda boleh meningkatkan nilai kedua-dua tangan ini dengan menggunakan pilihan Hit kad. Di sini, anda boleh memilih pilihan Stand apabila anda berpuas hati dengan nilai kedua-dua tangan. Walau bagaimanapun, apabila anda membahagikan pasangan ace pertama, kedua-dua tangan akan menerima kad tambahan secara automatik dan tidak akan mempunyai peluang untuk mendapatkan kad lain.",
      },
    },
    actions: {
      desc: "Semasa pusingan pertaruhan, pemain boleh melakukan mana-mana tindakan berikut.",
      call: {
        heading: "Call",
        desc: "Jika pertaruhan telah dibuat, bermakna pemain mesti menamatkan pertaruhan. Sebagai contoh, jika Pemain A bertaruh 10 dan Pemain B melihatnya, Pemain B mesti menutup taruhan dengan 10 dan meletakkan 10 ke dalam periuk.",
      },
      check: {
        heading: "Check",
        desc: "Pemain boleh menyemak sama ada tiada pertaruhan dibuat dalam pusingan semasa. Tindakan akan bergerak ke pemain seterusnya di sebelah kiri. Check boleh ditafsirkan sebagai sejenis pas; pemain boleh kekal dalam permainan tetapi memutuskan untuk tidak bertaruh pada pusingan semasa.",
      },
      raise: {
        heading: "Raise",
        desc: "Seorang pemain boleh menaikkan pertaruhan pada masa itu. Untuk melakukan ini, ia mesti sekurang-kurangnya menggandakan pertaruhan pemain sebelumnya. Untuk meneruskan pertempuran periuk, semua pemain berikut mesti melihat pertaruhan ini, menaikkannya semula atau pas.",
      },
      fold: {
        heading: "Fold",
        desc: "Fold adalah keengganan untuk berhenti mengejar periuk pada ketika itu. Jika pemain melepasi, kad pemain tidak diuruskan dan pemain tidak boleh menang dalam pusingan itu.",
      },
    },
    states: {
      winning: {
        heading: "Kemenangan",
        desc: "Satu atau lebih pemain dengan tangan yang menang mesti menunjukkan kad turun mereka.",
      },
      aggressivePlayer: {
        heading: "Pemain Agresif",
        desc: "Pemain yang agresif adalah pemain terakhir yang menaikkan taruhan semasa tangan. Jika pertaruhan dilihat dan tangan berakhir dengan pembukaan kad, pemain yang agresif mesti menunjukkan kad turunnya tanpa mengira keputusan tangan.",
      },
      allInPlayer: {
        heading: "ALL-In Player",
        desc: "Jika pemain mempertaruhkan semua cip, mereka semua All in dan tangan berakhir dengan kad muka. Pemain yang agresif mesti menunjukkan kad turun mereka tanpa mengira keputusan tangan.",
      },
      desc: "Jika seorang atau lebih pemain masuk secara all-in dan tiada pertaruhan lagi diletakkan, semua pemain lain mesti menunjukkan kad turun mereka. Jika tidak, pemain yang terus menaikkan dan membuat periuk mesti menunjukkan kad mereka kepada pemain yang agresif dan pemenang akan ditentukan oleh peraturan.",
      blackJackResult: {
        heading: "Kemenangan",
        desc: "Jika jumlah nilai tangan anda melebihi 21, anda diisytiharkan bankrap dan kalah taruhan anda pada tangan itu. Selepas semua pusingan berlalu, dealer menunjukkan nilai kad bawahnya. Jika tangan dealer adalah 16 atau kurang, dealer mesti memilih pilihan Hit kad, tetapi jika tangan soft 17 atau lebih tinggi, dealer mesti berhenti dengan memilih pilihan Stand (perhatikan bahawa soft hand mengandungi ace 11). Anda menang apabila nilai tangan anda lebih hampir kepada 21 daripada tangan dealer, atau apabila dealer dilucuthakkan. Jika tangan anda mempunyai nilai yang sama dengan dealer, pusingan permainan berakhir dengan seri dan pertaruhan anda akan dikembalikan. Bermain dengan pilihan Pertaruhan Sampingan menang sehingga '100x'.",
      },
    },
  },

  updateScene: {
    downloading: "Muatkan versi terkini...",
    installing: "memasang...",
    installedVersion: "Versi pemasangan",
    currentVersion: "Versi masa ini",
  },

  actionTypes: {
    call: "Call",
    check: "Check",
    fold: "Fold",
    raise: "Raise",
    allin: "All-in",
  },

  tableQuickMenu: {
    standUp: "Stand",
    goToLobby: "Pergi ke Lobi",
    soundOn: "Bunyi Aktif",
    soundOff: "Bunyi padam",
    vibrationOn: "Getaran Aktif",
    vibrationOff: "Getaran padam",
    animatedGiftsOn: "Tunjukkan Emotikon",
    animatedGiftsOff: "Tutup Emotikon",
    watchWin: "Ad",
  },
  notification: {
    noData: "Tiada pemberitahuan",
  },

  actionPanel: {
    check: "Check",
    call: "Call",
    fold: "Fold",
    raise: "Raise",
    allin: "All-in",
    callAny: "Call Any",
    checkFold: "Check/Fold",
  },

  selectPotWindow: {
    autoPurchase: "Auto Isi Semula",
    autoPurchaseDescription:
      "Auto isi semula jika modal bermain anda telah habis",
    autoCharging: "Auto Topup",
    autoChargingDescription:
      "Topup automatik jika modal Anda kurang daripada 70%.",
    sit: "Duduk",
    minEntry: "Min Masuk",
    maxEntry: "Maks Masuk",
    setQuantity: "Tentukan modal untuk bermain",
  },
  settings: {
    lobbyMusic: "Musik Lobi",
    soundEffects: "Sound FX",
    vibration: "Getaran",
    blockInvites: "Jemputan",
    giftHide: "Ekspresi",
    handStrengthIndicator: "Penunjuk kad",
    graphicsPreferences: "Tetapan grafik",
    tableColor: "Warna Meja",
    roomBgColor: "Latar belakang ",
    languagePreference: "Tetapan  bahasa",
    termsOfUse: "Terma Pengguna",
    accountInfo: "Info Akaun",
    profileID: "ID Pemain",
    deviceNO: "Peranti",
    deleteAccount: "Padam Akaun",
    language: {
      turkish: "Turki",
      english: "Inggris",
      italian: "Italia",
      indonesian: "Bahasa Indonesia",
      portuguese: "Portugis",
      russian: "Russia",
      malesian: "Melayu",
    },
  },
  vip: {
    available: "VIP / {day} Hari",
    noVip: "VIP tidak ada",
  },
  quickWindow: {
    quickChat: "Ini adalah kawasan sembang pendek.",
    readyMessage: "Berikut ialah mesej sembang yang telah siap",
    messageAim:
      "Bahagian ini guna bersembang lebih pantas dengan pengguna lain",
    quickEmoji: "Emotikon pantas",
  },

  tipButton: {
    thankYou: "Timekaseeh..",
  },

  fastChatMessages: [
    "Terbaik!",
    "Tahniah, Bro!",
    "Terimakasih",
    "Matak aji semar ngiseng!",
    "Laju siket, Bro!",
    "Payung siket!",
    "Lawa punya kad!!",
    "Nasib baik!",
    "Sorry,, i mestilah menang!",
  ],

  chatWindow: {
    tabs: {
      chat: "Sembang",
      dealer: "Dealer",
      mute: "Diam",
    },
    audiences: "Khalayak",
    players: "Pemain",
    typeHere: "Taip di sini...",
    xSecondsLeft: "{seconds} detik lagi.",
  },
  friends: {
    online: "ONLINE",
    goToTable: "Kongsi",
    chipTransfer: "Cip Transfer",
    friendRequests: "Permintaan Rakan",
    incoming: "MASUK",
    sent: "SUDAH DIHANTAR",
    search: "CARI",
    removeFriendNotification: "Rakan berjaya dipadam",
    addButtonText: "Tambah Rakan",
    userProfile: "Profil Pengguna",
    sendChipText: "Hantar Cip",
    deletefriends: "Padam Rakan",
  },
  dealerChat: {
    call: "{username} buat call",
    check: "{username} buat check",
    raise: "{username} buat raise {amount}",
    fold: "{username} buat fold",
    allin: "{username} buat all in",
    win: "{username} menang {amount}",
    winWithCards: "{username} menang {amount} dengan {hand}",
    winWithCardIcons: "{username} menang {amount} dengan {cards} ({hand})",
  },

  systemMessages: {
    roomClosing:
      "Pelayan akan memuatkan ulang dalam 2 minit. Kad tangan terakhir akan dimainkan.",
    teamTableAlert:
      "Ini meja tim. Berbilang akaun boleh duduk dan buat raise dekat meja. Tiada peraturan (larangan) pada permainan!",
    giftSentChatMessage: "{from} menghantar berian {gift_name} untuk {to}.",
    inviteRequestSuccess: "Jemputan sudah berjaya dihantar",
  },
  blackJack: {
    hit: "Hit",
    stand: "Stand",
    InsufficientChips: "Cip tidak cukup",
    undo: "BUAT ASAL",
    double: "DOUBLE",
    repeat: "ULANGAN",
    xSecondsLeft: "{seconds} detik lagi.",
    gameHistory: "Rekod permainan",
    croupier: "Dealer",
    you: "Anda",
    main: "Utama",
    anyPair: "Any Pair",
    result: "Hasil",
    previousGame: "Permainan Sebelum",
    returnToList: "Kembali ke Senarai",
    nextGame: "Permainan Seterusnya",
    type: "Jenis",
    pay: "Bayar",
    winningsHands: "Kad Menang",
    rules: "Peraturan",
    header: "TENTUKAN JUMLAH CIP GUNA BERMAIN",
    otoPurchase: "Mengisi Auto",
    blackjactSitInfo:
      "Permainan ini dipermainkan untuk menentang Dealer. Jumlah pemain yang tidak terhad boleh bermain menentang Dealer",
    otoPurchaseText: "Jika anda masuk balik, cip akan diset semula",
    bjRules: `
            * Matlamat Turn Blackjack adalah untuk mempunyai tangan dengan mata yang lebih tinggi daripada dealer tanpa melebihi 21; <br>
            *Tangan terbaik dipanggil Blackjack - jumlah nilai dua kad pertama yang diuruskan dalam tangan ini ialah 21;<br>
            * Anda hanya bermain menentang dealer, bukan menentang pemain lain;<br>
            * Blackjack dimainkan dengan 8 dek;<br>
            * Dealer akan sentiasa berhenti pada mata 17;<br>
            * Membolehkan memilih Double pada dua kad pertama;<br>
            * Kad awalan yang sama nilai boleh dibahagikan kepada 2 tangan;<br>
            * membahagi kad boleh diuruskan dengan setiap tangan;<br>
            * Satu kad akan diuruskan untuk setiap ace berganda yang dibahagi;<br>
            * Double tidak boleh dibuat selepas membelah kad;<br>
            * Insurans disyorkan apabila dealer mempunyai Ace;<br>
            * Blackjack dibayar 3:2;<br>
            * Insurance dibayar 1:2;<br>
            * Jika nilai tangan adalah sama, maka ia adalah seri.<br>

            <hr>

            <strong>Peraturan Permainan</strong><br>

            * Permainan ini diuruskan oleh dealer dan bilangan pemain yang tidak terhad setiap tangan;<br>
            * Permainan ini dipersembahkan oleh dealer dan dimainkan dengan 8 dek standard 52 kad. Nilai kad 21 dalam permainan adalah seperti berikut:<br>
            - Kad dari 2 hingga 10 mempunyai nilai yang tertulis padanya.<br>
            - Kad gambar (Jack, Queen dan King) bernilai 10 mata.<br>
            - Ace bernilai 1 atau 11, pemain boleh memilih nilai yang sesuai dengan tangannya. Ambil perhatian bahawa soft hand mengandungi nilai Ace 11;<br>
            * Apabila masa pertaruhan telah tamat, dealer memberikan kad muka kepada semua pemain, yang terakhir kepada dirinya sendiri. Selepas itu dealer memberikan kad kedua menghadap ke atas kepada semua pemain, tetapi kad kedua dealer akan menghadap ke bawah. Nilai tangan pertama anda dipaparkan di sebelah kad anda.<br>
            * Blackjack (Twenty One) = Jika dua kad anda pada permulaan permainan berjumlah 21, anda mempunyai Blackjack!<br>
            <br>
            <strong>Insurans</strong>
            <br>
            * Jika kad muka dealer ialah ace, anda boleh membeli insurans terhadap risiko dealer mempunyai 21, walaupun jumlah tangan anda ialah 21. Amaun insurans akan bersamaan dengan separuh daripada pertaruhan utama anda dan pertaruhan insurans akan diproses secara berasingan daripada pertaruhan tangan anda. Selepas itu, dealer melihat nilai kad turunnya untuk menyemak sama ada dia membuat 21.<br>
            - Jika dealer tidak mencapai 21, permainan akan diteruskan.<br>
            - Jika dealer mempunyai Blackjack dan anda tidak, maka permainan tamat dan peniaga itu menang.<br>
            - Jika anda dan dealer kedua-duanya mempunyai Blackjack maka permainan berakhir dengan seri dan pertaruhan anda akan dikembalikan.<br>
            <br>
            Ambil perhatian bahawa jika kad terdedah dealer ialah kad 10 atau kad raja, anda akan mempunyai pilihan untuk membeli insurans dan dealer tidak akan melihat kad bawah untuk 21.
            <br>
            <strong>Double, Hit atau Stand</strong>
            <br>
           Jika dealer mendapati bahawa selepas memeriksa dua kad pertamanya dia belum membuat 21, pemain diberi hak untuk memilih lebih banyak kad untuk meningkatkan nilai tangan mereka, dealer akan memberi lebih kad kepada pemain.
            <br>
           Jika tangan pertama anda tidak bernilai 21, anda berhak menggandakan pertaruhan. Dalam kes ini, pertaruhan anda akan digandakan dan anda hanya akan diberikan satu kad tambahan untuk ditambahkan pada tangan anda. Sebagai alternatif, anda boleh menggunakan pilihan Hit kad untuk mendapatkan kad tambahan untuk menambah nilai pada tangan anda. Anda boleh Hit beberapa kad sehingga anda berpuas hati dengan nilai tangan anda dan memutuskan untuk Stand.
            <br>
          Ambil perhatian bahawa pemain dengan soft hand 21 tidak akan diberi pilihan keputusan.
            <br>

            <strong>Bahagi dua</strong><br>
            <br>
            Jika tangan pertama anda terdiri daripada dua kad yang mempunyai kedudukan yang sama, anda boleh membahagikan pasangan itu kepada dua tangan yang berbeza, setiap satunya mempunyai kepentingan yang sama dengan pertaruhan utama anda. Selepas kedua-dua tangan diberikan kad kedua, anda boleh meningkatkan nilai kedua-dua tangan ini dengan menggunakan pilihan Hit kad. Anda boleh memilih pilihan Stand apabila anda berpuas hati dengan nilai kedua-dua tangan. Walau bagaimanapun, apabila anda membahagikan pasangan ace pertama, kedua-dua tangan akan menerima kad tambahan secara automatik dan tidak akan mempunyai peluang untuk mendapatkan kad lain.
            <br>
            <strong>Hasil</strong>
            <br>
            Jika jumlah nilai tangan anda melebihi 21, anda diisytiharkan bankrap dan anda akan kehilangan pertaruhan anda pada tangan itu.
            <br>
            Selepas giliran semua pemain telah berlalu, dealer menunjukkan nilai kad bawah. Jika tangan dealer adalah 16 atau kurang, dealer mesti memilih pilihan Hit kad, tetapi jika soft hand 17 atau lebih tinggi, dealer mesti berhenti dengan memilih pilihan Stand (perhatikan bahawa soft hand mengandungi ace 11).
            <br>
            Anda akan menang apabila nilai tangan anda lebih hampir kepada 21 daripada tangan dealer, atau apabila dealer bankrap. Jika tangan anda mempunyai nilai yang sama dengan dealer, pusingan permainan berakhir dengan seri dan pertaruhan anda akan dikembalikan.
            <br>Main dengan pilihan Pertaruhan Sampingan menang sehingga "100x".`,
    settings: "Tetapan",
    sounds: "Bunyi",
    bet: "PERTARUHAN",
    waitOtherPlayers: "Sila tunggu pemain lain",
    placeYourBet: "letak pertaruhan anda",
    choose: "Pilih",
    wantInsurance: "Adakah anda memerlukan insurans?",
    draw: "SERI",
    busted: "BANKRAP",
    youLost: "Anda Kalah",
    sit: "DUDUK",
    otoPurchase: "Pembelian Automatik",
    otoPurchaseText:
      "Jika anda masuk balik, cip akan mengembalikan sifar di atas meja",
    okay: "OK",
    insufficientBalance: "Tidak cukup cip untuk duduk.",
    ShufflingCards: "Kocok kad di atas dek...",
    youWon: "Anda Menang",
    won: "MENANG",
    dealNow: "Bagi sekarang",
  },
  useInviteCode: {
    heading: "Kod Jemputan",
    description: "Sila masukkan kod jemputan rakan anda.",
    unset: "Padam kod Jemputan",
    inviteCodeNotFound: "Kod Jemputan Salah.",
  },
  progressiveJackpot: {
    join: "Kongsi",
    desc1: "Anda membayar cip sama dengan blind kecil setiap tangan.",
    desc2:
      "Jika menang, anda akan menerima  PERTARUHAN BLIND KECIL x PENGGANDA.",
    desc3: "Cip yang dikumpul dari pada tahap pertaruhan yang sama",
  },
  profileSettings: {
    profileSettings: " Tetapan Profil ",
    registrationDate: "Masa registrasi",
    use: "PAKAI",
    gift: "HADIAH",
    buyVip: "Beli VIP",
    onlyVip: "  Setakat member VIP sahaja boleh guna hadiah ini.",
    frame: "Bingkai",
    buy: "BELI",
    statistics: {
      statistics: "Statistik",
      handPlayed: "Banyak pusingan",
      biggestPotWon: "Pot paling besar",
      biggestJackPotWon: "JackPot paling besar",
      recordChip: "Rekod Cip",
      brokenPotCounter: "Menang Jackpot",
      winPercentage: "Persentase Kemenangan",
      leadershipRanking: "Leadership Ranking",
      bestWinningHand: "Tangan Terbaik",
      biggestWin: "Menang besar",
    },
  },
  pinCode: {
    wrong: "Kata Laluan Salah!",
    pinCode: "KATA LALUAN",
    removePinCode: "Padam Kod Pin",
    enterPin: "Sila masukkan Kata Laluan akaun anda",
    reset: "Set semula Kata Laluan saya!",
    logOut: "KELUAR",
    resetPin: "SET SEMULA KATA LALUAN",
    enterEmail: "Sila masukkan alamat email akaun anda",
    mail: "Email",
    newPass: "Kata Laluan baharu sudah dihantar ke alamat email anda",
    wrongMail: "alamat email salah",
    failRemove: "Gagal padam kata laluan",
    failAdd: "Gagal simpan kata laluan",
  },
  redeem: {
    used: "Kad permainan sudah digunakan!",
    expired: "Kad permainan tamat tempoh!",
    failed: "Gagal mengesahkan kad permainan.",
    validGameCard: "Masukkan kad game yang valid",
  },
  level: {
    levelUpToastText:
      "Tahniah! Anda sudah melampaui tahap {levelNumber}. <strong>Ganjaran: {reward}</strong>",
  },
  inviteFriend: {
    inviteFriendText:
      "Anda sudah menjemput rakan anda dan anda berdua memperoleh 10M!",
  },
  slotWinnerToast: {
    bigWinText: "{username} telah memenangi {amount} dalam permainan slot!",
  },
  toast: {
    inviteRequestText: "{name} menjemput anda main {game}.",
  },
  updatedNotes: {
    title: "Nota Kemas Kini",
    notes: [
      "<div class='center'><span class='title'>Poker</span></div>",
      "Bunyi Latar Belakang dimatikan dengan tetapan lalai",
      "50 Pemain dalam senarai Leaders",
      "Membuat akses untuk ciri 'pencarian' dan 'Sejarah Tangan' di atas meja",
      "Hanya pemain di atas meja boleh menulis dalam sembang permainan",
      "Sembang permainan telus yang hebat berada di sebelah kiri",
      "Ambil tindakan (Call/Fold/Check/Raise) semasa sembang dibuka",
      "Pemasa undur yang lawa",
      "<div class='center'><span class='title'>Blackjack</span></div>",
      "Kad dan teks yang diperbesarkan dengan selesa",
      "Bugs dibetulkan",
    ],
  },
  forceUpdateWindow: {
    title: "Versi baharu tersedia!",
    text: "Sila kemas kini apl kepada versi terkini.",
  },
  table: {
    noHaveOnlineFriends: "Tiada kawan dalam talian.",
    onlineFriends: "Kawan dalam talian",
    noHaveFriends: "Tiada memiliki rakan",
  },
  others: {
    friendRemovedInfo: "Rakan telah dipadam",
    notFoundFriend: "Rakan tidak ditemui",
    notFoundDisplay: "Pengguna tidak ditemui",
    friendTableNotFound: "Pengguna sudah pergi dari meja",
    tableInFriendNotFound: "Tiada pemain di meja",
    requiredSelectedGift: "Pilih hadiah",
    currentLocationNotAvailableBuy: "Pembelian tidak ada untuk negara anda",
    requiredLevelFive: " Kena ada Level 5",
    blackjackStatusInactive: "Blackjack tidak ada",
    slotStatusInactive: "Slot tidak ada",
    muteAll: "Diam",
    anyMessages: "Anda tiada mempunyai mesej.",
    changeNameSuccesInfo: "Nama pengguna ditukar Anda mempunyai {0} lagi",
    guestClose:
      "Anda tidak boleh log masuk guna akaun tetamu. Sila log masuk guna akaun yang lain",
  },
};
