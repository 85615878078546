<template>
  <div class="drawn-cards-box">
    <div class="drawn-cards-box-inner">
      <div class="cards">
        <div
          v-for="card in drawnCardCount"
          :key="'drawn-card-' + card"
          class="card"
          :class="[getActiveRoomState.cardColor]"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/event-bus';

export default {
  name: "DrawnCardsBox",
  watch: {
    'getActiveRoomState.stage': function(currentStage, oldStage) {
      if (currentStage === 0 && oldStage === 4) {
        this.drawnCardCount = Math.abs((52 * 8 + 1) - this.getActiveRoomState.deck.length);
      }
    },
    'getActiveRoomState.deck': function(current, old) {
      if (old.length > 0 && current.length === 0) {
        this.drawnCardCount = 0;
        return;
      }
      if (current.length > 0 && old.length === 0){
        this.drawnCardCount = (52 * 8 + 1) - current.length;
      }
    }
  },
  computed: {
    ...mapGetters({
      getActiveRoomState: 'service/getActiveRoomState',
      getBjActiveSeat: 'service/getBjActiveSeat'
    })
  },
  data() {
    return {
      drawnCardCount: 0
    }
  },
  methods: {
    onShuffleRequested() {
      this.drawnCardCount = 0;
    }
  },
  beforeDestroy() {
    EventBus.$off('onShuffleRequested', this.onShuffleRequested.bind(this));
  },
  created() {
    EventBus.$on('onShuffleRequested', this.onShuffleRequested.bind(this));
  }
}
</script>

<style scoped lang="scss">
@-webkit-keyframes cardIncomingAnimation {
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}
@keyframes cardIncomingAnimation {
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}

.drawn-cards-box{
  position:absolute;
  left:-20px;
  top:-90px;
  width:118px;
  height:145px;
  background-image:url("~@/assets/images/blackjack/boxes/card-box-empty.png");
  background-repeat:no-repeat;
  .drawn-cards-box-inner{
    position:relative;
    width:100%;
    height:100%;
    .cards{
      position:absolute;
      display:flex;
      align-items: flex-end;
      flex-direction: column-reverse;
      flex-wrap: nowrap;
      bottom:13px;
      right:12px;
      .card{
        width:54px;
        height:39px;
        background-image:url("~@/assets/images/blackjack/boxes/card.png");
        background-repeat:no-repeat;
        margin-top:-38.87px;
        animation-name:cardIncomingAnimation;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
        animation-iteration-count: 1;
        will-change: auto;
        &.blue{
          filter:hue-rotate(220deg);
        }
      }
    }
  }
}

.platform-mobile{
  .drawn-cards-box{
    top:0;
  }
}
</style>
