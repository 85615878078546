export default {
  // when app initialized
  // network status (when lost or changed)
  NETWORK_STATUS_CHANGED: "onNetworkStatusChanged",

  // loading
  LOADING_PERCENT_INCREASED: "onLoadingPercentIncreased",

  // login screen
  AUTO_LOGIN_PROCESSING: "onAutoLoginProcessing",
  AUTO_LOGIN_FAILED: "onAutoLoginFailed",
  LOGGING_IN: "onLoggingIn",
  LOGGED_IN_SUCCESSFULLY: "onLoggedInSuccessfully",
  LOGIN_ERROR: "onAuthenticationError",
  LOGIN_BANNED_ERROR: "onAuthenticationBannedError",

  // communication websocket (socket.io)
  COMMUNICATION_WEBSOCKET_AUTHENTICATED:
    "onCommunicationWebsocketAuthenticated",

  // colyseus
  COLYSEUS_CLIENT_INITIALIZED: "onColyseusClientinitialized",

  // user
  USER_DATA_UPDATED: "onUserDataUpdated",

  // user click functionality events
  SHOW_USER_DETAILS_WINDOW: "onUserDetailsShow",
  SHOW_SHOP_WINDOW: "onShowShop",
  SHOW_RULES_WINDOW: "onShowRules",
  SHOW_SETTINGS_WINDOW: "onShowSettings",
  SHOW_CHAT_WINDOW: "onShowChat",
  SHOW_SOCIAL_WINDOW: "onShowSocial",
  SHOW_CASE_WINDOW: "onShowCase",
  SHOW_INBOX_WINDOW: "onShowInboxWindow",
  SHOW_HISTORY_WINDOW: "onShowHistoryWindow",
  SHOW_DISCONNECT_WINDOW: "onShowDisconnect",
  SHOW_AFFILIATE_WINDOW: "onShowAffiliate",
  SHOW_LEADER_BOARD_WINDOW: "onShowLeaderBoard",

  //global events

  SHOW_FRIEND_REQUEST: "onShowFriendRequest",

  // table list
  SHOW_TEXAS_HOLDEM_TABLE_LIST: "onTexasHoldemTableListRequested",
  SHOW_OMAHA_TABLE_LIST: "onOmahaTableListRequested",

  // slot selection
  SLOT_SELECTION_REQUESTED: "onSlotSelectionRequested",

  // one-click bonus
  ONE_CLICK_BONUS_CLAIMING: "onOneClickBonusClaiming",
  ONE_CLICK_BONUS_CLAIMED: "onOneClickBonusClaimed",
  ONE_CLICK_BONUS_CLAIM_ERROR: "onOneClickBonusClaimError",
  // ad bonus
  AD_BONUS_CLAIMING: "onAdBonusClaiming",
  AD_BONUS_CLAIMED: "onAdBonusClaimed",
  AD_BONUS_CLAIM_ERROR: "onAdBonusClaimError",

  // admob
  ADMOB_BANNER_LOADED: "onAdmobBannerLoaded",
  ADMOB_BANNER_FAILED_TO_LOAD: "onAdmobBannerFailedToLoad",
  ADMOB_BANNER_CLOSED: "onAdmobBannerClosed",
  ADMOB_BANNER_SIZE_CHANGED: "onAdmobBannerSizeChanged",
  ADMOB_REWARD_AD_LOADED: "onAdmobRewardAdLoaded",
  ADMOB_REWARD_AD_REWARDED: "onAdmobRewardAdRewarded",

  // toasts
  TOAST_REQUESTED: "onToastRequested",
  FRIEND_INVITE_TOAST_REQUESTED: "onFriendInviteToastRequested",

  // ads
  AD_CLICKED: "onAdClicked",

  // auto update
  APP_UPDATE_DOWNLOADING: "onAppUpdateDownloading",
  APP_UPDATE_INSTALLING: "onAppUpdateInstalling",

  // emote
  EMOTE: "onEmoteCame",

  // chat
  CHAT_MSG_SENT_ROOM: "onRoomChatMessageReceived",

  // gift sent
  GIFT_SENT: "onGiftSent",

  // jackpots
  JACKPOT_EXPLOSION: "onJackpotExplosion",
  JACKPOT_AMOUNT_CHANGED: "onJackpotAmountChanged",

  // progressive jackpot
  PROGRESSIVE_JACKPOT_AMOUNT_CHANGED: "onProgressiveJackpotAmountChanged",
};
