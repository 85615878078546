import moment from "@/plugins/moment";

export default {
  setUserRequests(state, { data, offSet }) {
    if (offSet === 0) {
      state.requestsList.list.splice(0, state.requestsList.list.length);
    }
    data.list.forEach((item) => {
      state.requestsList.list.push(item);
    });
    state.requestsList.total = data.total;
  },
  friendRequestRemoveItem(state, id) {
    const index = state.requestsList.list.findIndex(
      (item) => item.sender.id === id
    );
    if (index > -1) {
      state.requestsList.list.splice(index, 1);
      state.requestsList.total = state.requestsList.total - 1;
    }
  },
  friendListRemoveItem(state, id) {
    const index = state.friendsList.list.findIndex(
      (item) => item.user.id === id
    );
    if (index > -1) {
      state.friendsList.list.splice(index, 1);
      state.friendsList.total = state.friendsList.total - 1;
    }
  },
  inviteListSplice(state, userId) {
    const findIndex = state.inviteList.findIndex(
      (invite) => invite.userId === userId
    );
    if (findIndex > -1) {
      state.inviteList.splice(findIndex, 1);
    }
  },
  setUserFriends(state, { data, offSet }) {
    if (offSet === 0) {
      state.friendsList.list.splice(0, state.friendsList.list.length);
    }
    data.list.forEach((item) => {
      state.friendsList.list.push(item);
    });
    state.friendsList.total = data.total;
  },
  setFriendIndex(state, { data, index }) {
    if (!(index > state.friendsList.length)) {
      const oldFriendObject = state.friendsList.list[index];
      oldFriendObject.user.game = data.game;
      oldFriendObject.user.online = data.online;
      oldFriendObject.user.room = data.room;
      oldFriendObject.user.userId = data.userId;
      oldFriendObject.user.online = data.online;

      if (data.offline <= data.lastOnline) {
        if (oldFriendObject.user.lastOnlineTime <= data.lastOnline) {
          oldFriendObject.user.lastOnlineTime = data.lastOnline;
        }
      } else {
        if (oldFriendObject.user.lastOnlineTime <= data.offline) {
          oldFriendObject.user.lastOnlineTime = data.offline;
        }
      }

      state.friendsList.list.splice(index, 1, {
        ...oldFriendObject,
      });
    }
  },
  setInviteRequest(state, data) {
    const oldRequestIndex = state.inviteList.findIndex(
      (invite) => invite.userId === data.userId
    );
    if (oldRequestIndex > -1) {
      state.inviteList.splice(oldRequestIndex, 1);
    }
    state.inviteList.push({
      userId: data.userId,
      game: data.game,
      room: data.room,
      name: data.name,
      updatedAt: data.updatedAt,
    });
  },

  setSelectedUserModal(state, data) {
    state.selectedUserModal = data;
  },
  clearSelectedUserModal(state) {
    state.selectedUserModal = {};
  },
  setReceivedFriendRequest(state, data) {
    state.receivedFriendRequest = data;
  },
  clearReceivedFriendRequest(state) {
    state.receivedFriendRequest = {};
  },
};
