<template>
  <div class="seat-side">
    <div class="seat-all-cards-outer">
      <div class="seat-cards-outer">
        <HandStrength
          :value="getSeatHandStrength"
          :twoState="getSeatHandStrengthTS"
          :currentTurn="
            !nGetMySeat.split.active &&
            !nGetMySeat.busted &&
            !nGetMySeat.talked &&
            $store.state.service.ACTIVE_ROOM.stage === 3
          "
        />
        <Cards />
      </div>
      <div class="seat-split-cards-outer" v-if="nGetMySeat.split.bet > 0">
        <HandStrength
          :value="getSeatSplitHandStrength"
          :twoState="getSeatSplitHandStrengthTS"
          :currentTurn="
            nGetMySeat.split.active &&
            !nGetMySeat.split.busted &&
            !nGetMySeat.talked &&
            $store.state.service.ACTIVE_ROOM.stage === 3
          "
        />
        <SplitCards />
      </div>
    </div>
    <BetArea v-if="nGetMySeat.in" />
    <div class="sit-area" v-if="!nGetMySeat.in">
      <button class="sit-button" type="button" @click="openSitWindow">
        {{ $t("blackJack.sit") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/event-bus";

import HandStrength from "./HandStrength";
import Cards from "./SeatSide/Cards";
import SplitCards from "./SeatSide/SplitCards";
import BetArea from "./SeatSide/BetArea";

export default {
  name: "SeatSide",
  components: {
    HandStrength,
    SplitCards,
    Cards,
    BetArea,
  },
  computed: {
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
      getBjActiveSeat: "service/getBjActiveSeat",
    }),
    getSeatHandStrength() {
      if (
        this.nGetMySeat?.bet.main <= 0 &&
        typeof this.getBjActiveSeat !== "undefined"
      )
        return this.getBjActiveSeat?.handStrength;
      return this.nGetMySeat?.handStrength;
    },
    getSeatHandStrengthTS() {
      if (
        this.nGetMySeat?.bet.main <= 0 &&
        typeof this.getBjActiveSeat !== "undefined"
      )
        return this.getBjActiveSeat?.handStrengthTS;
      return this.nGetMySeat?.handStrengthTS;
    },
    getSeatSplitHandStrength() {
      return this.nGetMySeat.split.handStrength;
    },
    getSeatSplitHandStrengthTS() {
      return this.nGetMySeat.split.handStrengthTS;
    },
  },
  methods: {
    async openSitWindow() {
      await this.$store.dispatch("user/getCurrentUserDetails");
      EventBus.$emit("onBjSitWindowRequested");
    },
  },
  mounted() {
    this.openSitWindow();
  },
};
</script>

<style scoped lang="scss">
@keyframes sitButtonAnimation {
  0% {
    box-shadow: 0 0 0 0 rgba(rgb(255, 255, 255), 0.5);
  }
  50% {
    box-shadow: 0 0 20px 0 rgba(rgb(255, 255, 255), 0.25);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(rgb(255, 255, 255), 0.5);
  }
}

.seat-side {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  min-width: 400px;
  height: 300px;

  .seat-all-cards-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    margin-left: -50px;
  }

  .seat-cards-outer {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .hand-strength {
      margin-right: 10px !important;
    }
  }

  .seat-split-cards-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100px;
    margin-left: 100px;
    .hand-strength {
      margin-right: 10px !important;
    }
  }

  .sit-area {
    perspective: 1000px;
    -webkit-perspective: 1000px;
    margin-bottom: 90px;
    .sit-button {
      width: 134px;
      height: 134px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: transparent;
      padding: 0;
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
      color: rgba(rgb(255, 255, 255), 0.5);
      text-shadow: 0 3px 0 rgba(black, 0.2);
      border: 3px solid rgba(rgb(255, 255, 255), 0.3);
      border-radius: 100%;
      cursor: pointer;
      transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;
      transform: rotateX(35deg);
      box-shadow: 0 0 0 0 rgba(rgb(255, 255, 255), 0.5);
      transition: all 0.25s ease;
      animation-name: sitButtonAnimation;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-duration: 2s;
      &:hover {
        border-color: rgb(255, 255, 255);
        background-color: rgba(rgb(255, 255, 255), 0.1);
        color: rgba(rgb(255, 255, 255), 1);
        text-shadow: 0 3px 0 rgba(black, 0.5);
      }
      &:active {
        text-shadow: 0 -3px 0 rgba(black, 0.5);
        box-shadow: inset 0 5px 3px 0 rgba(black, 0.5) !important;
      }
    }
  }
}

.platform-mobile {
  .seat-side {
    transform: scale(1.2);
  }
}
</style>
