import axios from "@/services/api/axios";

export default {
  async getAnnouncements({ commit }) {
    try {
      let response = await axios.get(`/announcements/list`);
      if (response && response.data) {
        commit("setAnnouncements", response.data);
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};
