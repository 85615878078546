export default {
  setLastTableId(state, id) {
    state.lastTableId = id;
  },

  spliceSlotBigWinner(state, index) {
    state.slotBigWinners.splice(0, index);
  },

  setActiveRoomWinnerCards(state, cards) {
    state.pokerWinnerCards.splice(0, state.pokerWinnerCards.length);

    cards.forEach((card) => {
      state.pokerWinnerCards.push(card);
    });
  },
};
