<template>
  <div class="seat" :class="getClasses">
    <div class="seat-sit-button" @click="openSitWindow" v-if="!isAnyoneSitting">
      <span class="sprite-table sit-icon" v-if="!nAmISitting"></span>
      <span class="sprite-table invite-icon" v-if="nAmISitting"></span>
    </div>
    <div class="seat-inner" v-else>
      <Timer
        v-if="getActiveRoomState.currentTurn === data.position"
        @avatarClick="showPlayerDetails"
      />
      <!-- <ActionTooltip
        :actionCode="data.lastAction"
        :me="seatIsMe"
        v-if="data.lastAction !== 5"
      /> -->
      <Emote @emoteClick="showPlayerDetails" />
      <AllinAnimation
        v-if="data.lastAction === 5"
        @avatarClick="showPlayerDetails"
      />
      <div v-show="scroolingTextWinChip.length > 0">
        <ScroolingText
          v-for="(item, index) in scroolingTextWinChip"
          :key="index"
          :amount="item"
        />
      </div>
      <!-- <WinJackpotAnimation v-show="winJackpotAnimation" /> -->

      <div
        class="seat-action"
        :class="[...getIconClasses, `${seatIsMe ? 'action-me' : ''}`]"
        v-if="
          data.lastAction &&
          !data.bet &&
          lastActionTurn === getActiveRoomState.stage
        "
      >
        <div
          class="seat-action-icon flex-center"
          :class="`seat-action-${
            chipIsMeAndRaise
              ? 'raise'
              : data.id === user.id
              ? 'none'
              : lastActionText
          }`"
        >
          <CheckMark
            class="svg-check"
            v-if="
              (lastActionText == 'check' ||
                lastActionText == null ||
                lastActionText == undefined) &&
              lastActionText !== 'raise' &&
              lastActionText !== 'allin'
            "
          />
          <ChevronDown
            class="chevron-down"
            v-if="
              chipIsMeAndRaise ||
              lastActionText === 'raise' ||
              lastActionText === 'allin'
            "
          />
        </div>
      </div>

      <div
        class="talk-bubble tri-right btm-right-in border tip-text-popup"
        v-show="messageOn"
      >
        <div class="talktext">
          <span class="scroll-text">
            {{ messageText }}
          </span>
        </div>
      </div>
      <Avatar
        :url="data.avatar"
        :crown="crownCondition(data.crown, data.vip)"
        :size="205"
        :lazy="true"
        @click.native="showPlayerDetails"
      />
      <!-- <div class="seat-inner-avatar-effect"></div> -->
      <!-- <div class="seat-inner-lvl flex-center">
        <span class="seat-inner-lvl-text">99</span>
      </div> -->
      <div
        class="seat-inner-gift flex-center"
        :class="[
          giftArrived ? '' : 'seat-inner-gift-bg',
          getActiveRoomState.gameType === 'omaha_poker' ? 'omaha-gift' : '',
        ]"
        v-if="nAmISitting && !isSeatGift"
        @click="giftWindowOpen"
      >
        <div class="sprite-table gift-box" v-show="!giftArrived"></div>
        <Gift
          v-show="giftArrived"
          :playerId="data.id"
          @incomingGift="incomingGift"
        />
      </div>
      <div
        @click="showPlayerDetails"
        class="seat-cards"
        v-show="data.in || data.id === user.id"
        :class="[data.cards.length > 2 ? 'four-cards' : '']"
      >
        <template v-for="(seatCard, seatCardIndex) in data.cards">
          <Card
            :key="'seat-card-' + seatCardIndex"
            class="seat-card"
            :suit="seatCard.suit"
            :value="seatCard.value"
            v-if="seatCard.suit !== '' && cardFlipped"
          />
          <div
            v-if="seatCardIndex === 0 && !(seatCard.suit !== '' && cardFlipped)"
            class="sprite-table closed-cards"
            :key="`card-close-${seatCardIndex}`"
          ></div>
        </template>
      </div>
      <div
        class="player-details"
        :class="[data.cards.length > 2 ? 'details-four' : '']"
        @click="showPlayerDetails"
      >
        <div
          class="player-details-user-name"
          v-if="data.id !== $store.state.user.id"
        >
          {{ data.name }}
        </div>
        <div class="player-details-user-stack">
          <span class="player-details-user-stack-dollar"></span>
          {{ getShortChipFormat(getDataStack) }}
        </div>
      </div>
      <!-- <ChipAnimation
        v-if="winAmountIndicator.amount > 0"
        class="table-win-chip-animation"
        ref="win-animation"
      /> -->

      <!-- <div class="hand-strength-indicator" v-if="seatIsMe"></div> -->
      <!-- <div class="hand-strength-indicator-fill" v-if="seatIsMe"></div>
      <div class="hand-strength-info" v-if="seatIsMe">
        <div class="hand-strength-info-text">Straight Flush</div>
        <div
          class="hand-strength-info-icon sprite-table hand-strength-icon"
        ></div>
      </div> -->
    </div>

    <!-- <div class="seat-expression" v-if="isAnyoneSitting">
      <template v-for="(item, index) in getAnimations">
        <template v-for="(goTo, goToIndex) in item.to">
          <div
            class="seat-expression-item"
            v-if="item.from === data.id"
            :key="`animation${index}-to${goToIndex}`"
          >
            <GlobalAnimation
              :sendPlayerId="item.from"
              :type="item.id"
              :from="getComputedIndex"
              :to="seatAnimationToPosition(goTo)"
            />
          </div>
        </template>
      </template>
    </div> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import chipActions from "@/constants/game/poker/playerChipLastAction";

import EventBus from "@/event-bus";
import Helpers from "@/mixin/Helpers";

export default {
  name: "Seat",
  mixins: [Helpers],
  components: {
    Avatar: () => import("@/components/Misc/Avatar"),
    Card: () => import("@/components/Misc/PokerTable/Cards/Card"),
    Emote: () => import("@/components/Misc/PokerTable/Seat/Emote"),
    Gift: () => import("@/components/Misc/PokerTable/Seat/Gift"),
    Timer: () => import("@/components/Misc/PokerTable/Seat/Timer"),
    ActionTooltip: () =>
      import("@/components/Misc/PokerTable/Seat/ActionTooltip"),
    AllinAnimation: () => import("./Seat/AllinAnimation"),
    ScroolingText: () => import("./Seat/ScroolingText.vue"),
    WinJackpotAnimation: () => import("./Seat/WinJackpotAnimation"),
    CheckMark: () => import("@/components/Icons/CheckMark"),
    ChevronDown: () => import("@/components/Icons/ChevronDown"),
    GlobalAnimation: () =>
      import("@/components/Misc/Animations/GlobalAnimation"),
    // ChipAnimation: () => import("@/components/Misc/ChipAnimation"),
  },
  props: {
    index: {
      type: [Number, String],
      default: 0,
      required: true,
    },
  },
  watch: {
    "getActiveRoomState.stage": {
      handler(v) {
        if (this.seatIsMe && v === 1) {
          this.cardFlipped = false;
          if (this.cardFlipTimingNo) {
            clearTimeout(this.cardFlipTimingNo);
            this.cardFlipTimingNo = 0;
          }
          this.cardFlipTimingNo = setTimeout(() => {
            this.cardFlipped = true;
          }, 50);
        }
      },
      immediate: true,
    },
    "getActiveRoomState.cards": {
      handler(v) {
        this.userActionIconStateSetDefault();
      },
      immediate: true,
      deep: true,
    },
    giftArrived(val) {
      if (val) {
        setTimeout(() => {
          this.giftArrived = false;
        }, 1800000);
      }
    },
    "winAmountIndicator.amount": {
      handler(value) {
        if (value > 0) {
          this.scroolingTextWinChip.push(value);

          setTimeout(() => {
            this.scroolingTextWinChip.splice(0, 1);
          }, 2000);
        }
      },
    },
    "data.lastAction": function (currentAction) {
      this.lastActionTurn = this.getActiveRoomState.stage;
      let amount = 0;
      if (currentAction === 3) amount = this.data.bet;

      EventBus.$emit("onPlayerSpoke", {
        action: currentAction,
        amount,
        position: this.data.position,
        player: {
          id: this.data.id,
          name: this.data.name,
        },
      });

      if (true) {
        if (currentAction == 1) {
          this.$plugins.audio.play("Call");
        } else if (currentAction == 2) {
          this.$plugins.audio.play("Check");
        } else if (currentAction == 3) {
          this.$plugins.audio.play("Raise");
        } else if (currentAction == 4) {
          this.$plugins.audio.play("Fold");
        } else if (currentAction == 5) {
          this.$plugins.audio.play("Win");
        }
      }
    },
    "data.stack": {
      handler(newStack, oldStack) {
        if (
          typeof oldStack !== "undefined" &&
          newStack > oldStack &&
          this.getActiveRoomState.stage === 5
        ) {
          this.onStackIncreased(newStack - oldStack);
        }
      },
      immediate: true,
    },
    tableMessages: {
      handler(value) {
        this.showSeatChat(value);
      },
      immediate: true,
    },
    nAmISitting: {
      handler(val) {
        if (!val) {
          this.giftArrived = false;
        }
      },
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      nGetSeats: "service/nGetSeats",
      nGetMySeat: "service/nGetMySeat",
      nAmISitting: "service/nAmISitting",
      getActiveRoomState: "service/getActiveRoomState",
      getShortChipFormat: "getShortChipFormat",
      tableMessages: "chat/getTableMessages",
      getAnimations: "gift/getAnimations",
      getGifts: "gift/getGifts",
    }),
    chipIsMeAndRaise() {
      if (this.$store.state.service.userRaiseClick && this.seatIsMe) {
        return true;
      }
      // else if (this.$store.state.service.userFoldClick && this.seatIsMe) {
      //   return "fold";
      // } else if (this.$store.state.service.userCallClick && this.seatIsMe) {
      //   return "call";
      // }
      return false;
    },
    lastActionText() {
      if (this.data.lastAction) {
        return chipActions[this.data.lastAction];
      }
      return null;
    },
    seatIsMe() {
      return this.data.id === this.user.id || false;
    },
    getDataStack() {
      const val = this.data.stack;
      if (isNaN(val) || typeof val === "undefined" || val == "" || val == null)
        return 0;
      return val;
    },
    getIconClasses() {
      return [
        `seat-action-r-${this.$props.index}`,
        `seat-action-${this.getComputedIndex}`,
      ];
    },
    getClasses() {
      return [
        `seat-index-r-${this.$props.index}`,
        `seat-index-${this.getComputedIndex}`,
        this.data.id > 0 ? `player-${this.data.id}` : "",
        this.nAmISitting && this.nGetMySeat.position === this.$props.index
          ? "seat-me"
          : "",
        !this.data.in && this.isAnyoneSitting ? "seat-not-in" : "",
      ];
    },
    getComputedIndex() {
      if (!this.nAmISitting) return this.$props.index;

      let shiftValue = 7 - this.nGetMySeat.position;
      if (shiftValue < 0) shiftValue = 8;

      return (this.$props.index + shiftValue) % 9;
    },
    isAnyoneSitting() {
      if (typeof this.nGetSeats[this.$props.index] === "undefined")
        return false;
      return this.nGetSeats[this.$props.index].position > -1;
    },
    data() {
      return this.nGetSeats[this.$props.index];
    },
    isSeatGift() {
      return (
        this.getGifts.filter((item) => item.to === this.data.id).length > 0
      );
    },
  },
  data() {
    return {
      cardFlipTimingNo: 0,
      scroolingTextWinChip: [],
      giftArrived: false,
      winAmountIndicator: {
        amount: 0,
        animation: false,
        _timeout: 0,
      },
      winJackpotAnimation: false,
      messageOn: false,
      messageText: "",
      cardFlipped: true,
      lastActionTurn: -1,
    };
  },
  methods: {
    seatAnimationToPosition(playerId) {
      const findSeat = this.nGetSeats.find((item) => item.id === playerId);
      if (findSeat && findSeat.position > -1) {
        if (!this.nAmISitting) {
          return findSeat.position;
        }

        let shiftValue = 7 - findSeat.position;
        if (shiftValue < 0) shiftValue = 8;

        return (this.$props.index + shiftValue) % 9;
      } else {
        console.log("player not found");
        return -1;
      }
    },
    onStackIncreased(amount) {
      if (!this.data.in) return false;
      // if (this.winAmountIndicator._timeout)
      //   clearTimeout(this.winAmountIndicator._timeout);
      this.winAmountIndicator.amount = amount;

      setTimeout(() => {
        this.winAmountIndicator.amount = 0;
      }, 1100);
      // setTimeout(() => {
      //   this.winAmountIndicator.amount = amount;
      //   this.winAnimationStart();
      //   this.winAmountIndicator._timeout = setTimeout(() => {
      //     this.winAmountIndicator.amount = 0;
      //   }, 1100);
      // }, 750);
    },
    userActionIconStateSetDefault() {
      this.$store.state.service.userRaiseClick = false;
      this.$store.state.service.userFoldClick = false;
      this.$store.state.service.userCallClick = false;
    },
    inviteFriendWindowOpen() {
      this.$store.state.modals.showTableInviteWindow = true;
    },
    incomingGift() {
      this.giftArrived = true;
    },
    giftWindowOpen() {
      this.$parent.$parent.$parent.$parent.showChatPopup = false;
      if (this.data.id < 1) {
        return;
      }
      this.$store.state.gift.sendGiftPlayerId = this.data.id;
      this.$store.commit("modals/show", "sendGiftWindow");
    },
    // winAnimationStart() {
    //   if (
    //     this.$refs &&
    //     this.$refs["win-animation"] &&
    //     this.$refs["win-animation"].$refs["sprite-win-chip"] &&
    //     this.$refs["win-animation"].$refs["sprite-win-chip"].hasOwnProperty(
    //       "playOnce"
    //     )
    //   ) {
    //     this.$refs["win-animation"].$refs["sprite-win-chip"].playOnce(false);
    //   }
    // },
    showSeatChat(messages) {
      const value = messages;
      if (this.data.id < 1 || this.data.position < 0) return;

      if (!value && value.length < 1) return;
      const trueMessage = value.findIndex(
        (mess) => mess.sender.id == this.data.id
      );
      if (trueMessage < 0) return;
      this.messageText = value[trueMessage].message;
      this.messageOn = true;

      setTimeout(() => {
        this.$store.state.chat.tableMessages.splice(trueMessage, 1);
        this.messageText = "";
        this.messageOn = false;

        if (this.$store.state.chat.tableMessages.length > 0)
          this.showSeatChat(this.tableMessages);
      }, 3000);
    },
    async showPlayerDetails($event) {
      this.$parent.$parent.$parent.$parent.showChatPopup = false;
      this.$store.commit("modals/hide", "sendGiftWindow");
      if (this.data.id) {
        if (parseInt(this.data.id) === this.$store.state.user.id) {
          // return this.$plugins.toast.show({
          //   text: this.$t("searchWindow.ownId"),
          //   duration: 2000,
          //   variant: "danger",
          // });
          this.$store.commit("modals/show", "friendDetails");
          if ($event) {
            $event.preventDefault();
          }
        }
        const res = await this.$store.dispatch("friends/getProfiles", [
          this.data.id,
        ]);
        if (!res) {
          this.$plugins.toast.show({
            text: this.$t("confirmWindow.userNotFound"),
            duration: 2000,
            variant: "danger",
          });
          return;
        }
        this.$store.commit("friends/setSelectedUserModal", res[0]);
        this.$store.commit("modals/show", "friendDetails");
      } else {
        return this.$plugins.toast.show({
          text: this.$t("searchWindow.validId"),
          duration: 2000,
          variant: "danger",
        });
      }
    },
    async openSitWindow() {
      if (this.nAmISitting) {
        this.$store.state.modals.showTableInviteWindow = true;
      } else {
        if (this.user.chip < this.getActiveRoomState.minBuy)
          return this.$plugins.toast.show({
            text: this.$t("errors.insufficientBalance"),
            variant: "danger",
          });

        EventBus.$emit("onSitWindowRequested", {
          position: this.$props.index,
          onCompleted: this.sit.bind(this),
        });
      }
    },
    async sit(amount, selectedOption) {
      await this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send(
        "sit",
        {
          amount,
          position: this.$props.index,
          autoPurchase: selectedOption === 0 ? 1 : 0,
          autoCharging: selectedOption === 1 ? 1 : 0,
        }
      );
    },

    // onJackpotWinnerAnnounced(data) {
    //   if (!(data && data.player) || !this.data) return;
    //   if (data.player.id !== this.data.id) return false;

    //   if (this.$data.winJackpotAnimation)
    //     this.$data.winJackpotAnimation = false;

    //   this.$data.winJackpotAnimation = true;
    //   setTimeout(() => {
    //     this.$data.winJackpotAnimation = false;
    //   }, 1000 * 2);
    // },
  },
  // beforeDestroy() {
  //   EventBus.$off(
  //     "onJackpotExplosion",
  //     this.onJackpotWinnerAnnounced.bind(this)
  //   );
  // },
  // created() {
  //   EventBus.$on(
  //     "onJackpotExplosion",
  //     this.onJackpotWinnerAnnounced.bind(this)
  //   );
  // },
  mounted() {},
};
</script>

<style lang="scss">
.seat {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  min-height: 200px;
  max-width: 200px;
  max-height: 200px;
  transition: filter 0.25s linear;

  &.seat-not-in {
    .avatar,
    .card {
      filter: brightness(30%);
    }
  }

  &-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 200px;
    min-height: 200px;
    width: 200px;
    height: 195px;
    z-index: 1;

    .seat-timer {
      z-index: 3;
    }
    .avatar {
      z-index: 1;
      .vip-crown {
        z-index: 2;
      }
    }
    .win-chip {
      z-index: 9;
    }
    .talk-bubble {
      z-index: 6;
    }
    .allin-animation-sprite {
      z-index: 6;
    }
    .emote-container {
      z-index: 7;
      pointer-events: none;
    }
    .player-details {
      position: absolute;
      width: 190px;
      height: 70px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background: linear-gradient(90deg, transparent, #000 50%, transparent);
      border-radius: 50px;
      z-index: 5;
      top: 183px;
      // left: 3px;
      font-size: 34px;
      &-user-name {
        width: 180px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #f8af18;
        padding-bottom: 5px;
      }
      &-user-stack {
        width: 180px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &-dollar {
          background: url("~@/assets/images/table/dollar-image.png") no-repeat
            50% 50%;
          background-size: contain;
          width: 17px;
          height: 30px;
          position: relative;
          top: 1px;
          right: 5px;
        }
      }
    }
    .seat-cards {
      min-width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform-origin: top;
      position: absolute;
      top: 20px;

      .closed-cards {
        z-index: 5;
      }
      .seat-card {
        z-index: 8;
      }
      .card {
        transform: scale(1.1);
      }
      .seat-card:first-child {
        margin-right: 15px;
      }
      &.four-cards {
        // left: -10px !important;
        .seat-card {
          margin-left: -73px !important;
        }
      }
    }
    .seat-inner-lvl {
      position: absolute;
      width: 50px;
      height: 50px;
      background-color: rgba(#000000, 1);
      border-radius: 30px;
      border: 2px solid #b8a15d;
      z-index: 4;
      &-text {
        font-size: 30px;
        color: #ffffff;
      }
    }
    .seat-inner-gift {
      position: absolute;
      height: 70px;
      width: 70px;
      z-index: 5;
      cursor: pointer;
      &-bg {
        border-radius: 35px;
        background-color: rgba(#000000, 0.8);
      }
    }
    .hand-strength-indicator,
    .hand-strength-indicator-fill {
      height: 15px;
      border-radius: 20px;
      position: absolute;
      top: 221px;
      left: -5px;
    }
    .hand-strength-indicator {
      background-color: rgba(#c80813, 1);
      z-index: 0;
      width: 210px;
    }
    .hand-strength-indicator-fill {
      background-color: rgba(#1f1e2a, 1);
      overflow: hidden;
      z-index: 1;
      max-width: 210px;
      width: 120px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .hand-strength-info {
      position: fixed;
      bottom: -80px;
      right: 210px;
      min-width: 250px;
      height: 50px;
      max-height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      font-size: 36px;
      color: #ffffff;
      &-text {
        word-wrap: normal;
      }
    }
    // &-avatar-effect {
    //   height: 205px;
    //   width: 205px;
    //   position: absolute;
    //   border: 5px solid red;
    //   border-radius: 200px;
    // }

    .action-me {
      transform: scale(0.75);
      .seat-action-none {
        display: none;
      }
    }
    .seat-action {
      position: absolute;
      z-index: 99;
      .seat-action-icon {
        background-color: rgba(#b11d1d, 0.8);
        width: 42px;
        height: 42px;
        border-radius: 25px;
        .svg-check {
          width: 34px;
          height: 34px;
          position: absolute;
          color: rgb(0, 0, 0);
        }
        .chevron-down {
          width: 36px;
          height: 36px;
          color: rgb(0, 0, 0);
          transform: rotate(180deg);
          position: absolute;
          top: 4px;
        }
      }
      .seat-action-null {
        background-color: rgba(#db15c4, 1);
      }
      .seat-action-check {
        background-color: rgba(#01b3ae, 1);
      }
      .seat-action-call {
        background-color: rgba(#01b3ae, 1);
      }
      .seat-action-raise {
        background-color: rgba(#ffba3e, 1);
      }
      .seat-action-allin {
        background-color: rgba(#ffba3e, 1);
      }
      .seat-action-fold {
        background-color: rgba(#d0222d, 1);
      }
      .seat-action-call::before {
        font-weight: bold;
        color: rgb(0, 0, 0);
        content: "C";
        font-size: 36px;
      }
      .seat-action-fold::after {
        content: "X";
        font-size: 34px;
        font-weight: bold;
        font-family: arial;
        color: #000000;
      }
    }
    .seat-action-0 {
      top: -20px;
      left: 270px;
    }
    .seat-action-1 {
      top: 195px;
      left: 285px;
    }
    .seat-action-2 {
      top: 270px;
      left: 80px;
    }
    .seat-action-3 {
      top: 270px;
      left: 80px;
    }
    .seat-action-4 {
      top: 195px;
      left: -150px;
    }
    .seat-action-5 {
      top: -15px;
      left: -150px;
    }
    .seat-action-6 {
      top: -70px;
      left: 40px;
    }
    .seat-action-7 {
      top: -90px;
      left: 70px;
    }
    .seat-action-8 {
      top: -75px;
      left: 115px;
    }
    .table-win-chip-animation {
      position: absolute;
      top: -170px;
      z-index: 6;
    }
  }

  &.seat-me {
    .seat-inner {
      position: relative;
      .avatar {
        z-index: 3;
      }
      .player-details {
        z-index: 5;
        top: 217px;
        width: 200px;
        height: 40px;
        border-radius: 0px;
      }
      .gift-container {
        z-index: 9 !important;
      }
      .seat-cards {
        z-index: 4;
        width: 270px;
        height: 170px;
        overflow: hidden;
        &.four-cards {
          top: 65px;
          transform: scale(0.9);
          width: 370px;
          .seat-card {
            box-shadow: -5px 0 5px #000000;
            &:nth-child(1) {
              transform: rotate(-30deg);
              left: -5px;
              top: 40px;
            }
            &:nth-child(2) {
              transform: rotate(-15deg);
              left: 12px;
              top: 15px;
            }
            &:nth-child(3) {
              transform: rotate(15deg);
              left: 40px;
              top: 20px;
            }
            &:nth-child(4) {
              transform: rotate(30deg);
              top: 50px;
              left: 70px;
            }
          }
        }
        &:not(.four-cards) {
          top: 65px;
          transform: scale(0.9);
          .seat-card {
            top: 45px;
            box-shadow: -5px 0 5px #000000;
            transform: rotate(-15deg);
            &:nth-child(1) {
              left: 10px;
            }
            &:nth-child(2) {
              transform: rotate(15deg);
              left: -10px;
            }
          }
        }
      }
      .details-four {
        z-index: 4;
      }
      &-gift {
        z-index: 5;
        top: 140px !important;
        left: -75px !important;
      }
      .omaha-gift {
        top: 60px !important;
        left: -70px !important;
      }
    }
  }
  &:not(.seat-me) {
    .seat-inner {
      .four-cards {
        .seat-card:nth-child(1) {
          left: -5px;
        }
        .seat-card:nth-child(2) {
          left: 10px;
        }
        .seat-card:nth-child(3) {
          left: 40px;
        }
        .seat-card:nth-child(4) {
          left: 70px;
        }
      }
    }
  }
  &.seat-index-0 {
    left: -160px;
    top: 290px;
  }
  &.seat-index-1 {
    left: -115px;
    top: -22px;
  }
  &.seat-index-2 {
    left: 272px;
    top: -165px;
  }
  &.seat-index-3 {
    left: 875px;
    top: -173px;
  }
  &.seat-index-4 {
    left: 1270px;
    top: -23px;
  }
  &.seat-index-5 {
    left: 1320px;
    top: 290px;
  }
  &.seat-index-6 {
    top: 420px;
    left: 1010px;
  }
  &.seat-index-7 {
    top: 500px;
    left: 580px;
  }
  &.seat-index-8 {
    top: 415px;
    left: 140px;
  }

  &.seat-index-0,
  &.seat-index-1,
  &.seat-index-2,
  &.seat-index-7,
  &.seat-index-8 {
    .seat-inner {
      .seat-cards {
        .closed-cards {
          position: absolute;
          top: 55px;
          left: 180px;
        }
      }

      &-lvl {
        top: -10px;
        left: 2px;
      }
      &-gift {
        top: 120px;
        left: -40px;
      }
    }
  }
  &.seat-index-3,
  &.seat-index-4,
  &.seat-index-5,
  &.seat-index-6 {
    .seat-inner {
      .seat-cards {
        .closed-cards {
          position: absolute;
          top: 78px;
          left: -45px;
        }
      }

      &-lvl {
        top: -10px;
        left: 145px;
      }
      &-gift {
        top: 110px;
        left: 170px;
      }
    }
  }

  &.seat-index-0,
  &.seat-index-1,
  &.seat-index-2,
  &.seat-index-8 {
    .seat-inner {
      .player-details {
        .four-cards {
          right: 15px;
        }
      }
      .win-chip {
        right: 30px;
      }
    }
  }
  &.seat-index-3 {
    .seat-inner {
      .player-details {
        .four-cards {
          right: -65px;
        }
      }
    }
  }
  &.seat-index-6 {
    .seat-inner {
      .player-details {
        .four-cards {
          right: -65px;
        }
      }
    }
  }

  .seat-sit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    width: 170px;
    height: 170px;
    background-color: rgba(black, 0.35);
    border-radius: 100%;
    border: 2px solid #8c2532;
    z-index: 2;
    // transition: all 0.25s ease;
  }
  .seat-expression {
    position: absolute;
    top: 0;
    max-height: 50px;
    width: 100%;
    .seat-expression-item {
      position: relative;
      z-index: 50;
    }
  }
}

.seat-index-1,
.seat-index-0 {
  .talk-bubble {
    bottom: 94px;
    right: -20px;
    z-index: 330;
    background: url("~@/assets/images/table/text-baloon2.png");
    background-size: contain;
    height: 133px;
    width: 260px;
    background-repeat: no-repeat;
  }
}
.seat-index-2 {
  .talk-bubble {
    bottom: 94px;
    right: -20px;
    z-index: 330;
    background: url("~@/assets/images/table/text-baloon2.png");
    background-size: contain;
    height: 133px;
    width: 260px;
    background-repeat: no-repeat;
  }
}
// .seat-index-3 {
//   .talk-bubble {
//     bottom: 3px;
//     right: 82px;
//     z-index: 10;
//   }
// }
.seat-index-3,
.seat-index-4,
.seat-index-5,
.seat-index-6 {
  .talk-bubble {
    bottom: 94px;
    right: -33px;
    z-index: 10;
    background: url("~@/assets/images/table/text-baloon1.png");
    height: 133px;
    width: 260px;
    background-repeat: no-repeat;
  }
}

.seat-index-7 {
  .talk-bubble {
    bottom: 94px;
    right: -33px;
    z-index: 10;
    background: url("~@/assets/images/table/text-baloon1.png");
    height: 133px;
    width: 260px;
    background-repeat: no-repeat;
    opacity: 0.9;
  }
}
.seat-index-8 {
  .talk-bubble {
    bottom: 94px;
    right: -33px;
    z-index: 10;
    background: url("~@/assets/images/table/text-baloon2.png");
    height: 133px;
    width: 260px;
    background-repeat: no-repeat;
  }
}

.seat-me {
  .talk-bubble {
    bottom: 94px;
    right: -33px;
    z-index: 10;
    background: url("~@/assets/images/table/text-baloon1.png");
    height: 133px;
    width: 260px;
    background-repeat: no-repeat;
  }
}

.talk-bubble {
  margin: 5px;
  display: inline-block;
  position: absolute;
}

.round {
  border-radius: 33px;
  -webkit-border-radius: 33px;
  -moz-border-radius: 33px;

  border-bottom-right-radius: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-bottom-right-radius: 0px;
}

.talktext {
  margin: 6px 8px 8px 9px;
  text-align: left;
  line-height: 1.3em;
  font-size: 24px;
  max-height: 60px;
  overflow: scroll;
  width: 236px;
  overflow: hidden;
  position: relative;
  left: 0;
  top: 0;
  min-height: 80px;

  .scroll-text {
    position: relative;
    display: block;
    color: white;
    width: 236px;
    height: auto;
    text-align: start;
    word-wrap: break-word;
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;

    // animation-iteration-count: 1;
    // -moz-animation: my-animation 3.5s linear normal;
    // -webkit-animation: my-animation 3.5s linear normal;
    // animation: my-animation 3.5s linear normal;
  }
}

// @keyframes my-animation {
//   0% {
//     -moz-transform: translateY(-100%);
//     -webkit-transform: translateY(-100%);
//     transform: translateY(70%);
//   }
//   100% {
//     -moz-transform: translateY(90%);
//     -webkit-transform: translateY(90%);
//     transform: translateY(-40%);
//   }
// }

@keyframes winnerChip {
  0% {
  }

  100% {
    transform: translateY(185px);
  }
}
.win-chip {
  position: absolute;
  z-index: 5;
  top: 0px;
  right: 36px;
  width: 130px;
  height: 35px;
  line-height: 32px;
  background-color: #396204;
  border-radius: 20px;
  text-align: center;
  font-size: 30px;
  animation-name: winnerChip;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  .prefix {
    position: relative;
    top: 3px;
    font-size: 32px;
    color: rgba($color: #ffffff, $alpha: 1);
  }
  .amount {
    color: rgba($color: #ffffff, $alpha: 1);
  }
}

.seat-index-0 {
  .card-close {
    top: 30px;
    left: 380px;
  }
}
.seat-index-1 {
  .card-close {
    top: 160px;
    left: 370px;
  }
}
.seat-index-2 {
  .card-close {
    top: 400px;
    left: 310px;
  }
}

.seat-index-3 {
  .card-close {
    top: 400px;
    left: -240px;
  }
}
.seat-index-4 {
  .card-close {
    top: 245px;
    left: -280px;
  }
}
.seat-index-5 {
  .card-close {
    top: 30px;
    left: -300px;
  }
}
.seat-index-6 {
  .card-close {
    top: -180px;
    left: -130px;
  }
}
.seat-index-7 {
  .card-close {
    top: -170px;
    left: -50px;
  }
}
.seat-index-8 {
  .card-close {
    top: -170px;
    left: 80px;
  }
}

.seat-me {
  top: 533px !important;
  left: 577px !important;
  transform: scale(1.25);
  .seat-action {
    top: -73px !important;
    left: 80px !important;
  }
}
</style>
