<template>
  <div class="distribute-now-button-container" v-if="show && showLocal">
    <button
      type="button"
      class="distribute-now-button"
      @click="onClickedDistributeNowButton"
    >
      {{ $t("blackJack.dealNow") }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DistributeNowButton",
  watch: {
    "getActiveRoomState.stage": function (newValue, oldValue) {
      if (newValue < oldValue) {
        this.$data.showLocal = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    show() {
      const audiences = this.getActiveRoomState.players.filter(
        (p) => p.in && p.bet.main === 0
      );
      const bettingPlayers = this.getActiveRoomState.players.filter(
        (p) => p.in && p.bet.main > 0
      );

      return (
        this.nGetMySeat?.in &&
        bettingPlayers.length === 1 &&
        this.getActiveRoomState.stage === 1 &&
        this.nGetMySeat.bet.main > 0 &&
        audiences.length === 0
      );
    },
  },
  data() {
    return {
      showLocal: true,
    };
  },
  methods: {
    onClickedDistributeNowButton() {
      this.$data.showLocal = false;
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send(
        "distributeNow"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.distribute-now-button-container {
  position: absolute;
  bottom: 300px;
  padding-bottom: 15px;
  .distribute-now-button {
    background-color: black;
    border: 3px solid lighten($greenColor, 20%);
    box-shadow: 0 0 3px 0 black;
    border-radius: 30px;
    font-size: 24px;
    color: rgb(255, 255, 255);
    width: 200px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease;
    &:active {
      box-shadow: 0 0 3px 0 black, 0 0 0 5px rgba(rgb(255, 255, 255), 0.15);
    }
  }
}

.platform-mobile {
  .distribute-now-button-container {
    bottom: 50px;
  }
}
</style>
