import axios from "@/services/api/axios";

export default {
  async deposit(store, amount) {
    try {
      const response = await axios.post(`/case/deposit`, {
        amount: parseInt(amount),
      });
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async withdraw(store, amount) {
    try {
      const response = await axios.post(`/case/withdraw`, {
        amount: parseInt(amount),
      });
      return response.data;
    } catch (error) {
      return false;
    }
  },
};
