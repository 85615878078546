import Logger from "@/logger";
import axios from "@/services/api/axios";
import i18n from "@/plugins/i18n";

export default {
  /*
   *   Get user preferences from API
   */
  async fetch({ dispatch }) {
    try {
      const response = await axios.get(`/user/preferences`);

      if (response.data.length > 0)
        await dispatch("fill", { list: response.data });

      return response.data || [];
    } catch (error) {
      Logger.print("error", error);
      return false;
    }
  },

  /*
   *   Filling preferences list after fetched
   */
  async fill({ state, commit }, { list }) {
    if (typeof list === "undefined" || list.length === 0) return false;

    commit("removeAll");

    list.forEach(({ key, value }) => {
      commit("updateOne", { key, value });
      if (key === "language") {
        i18n.changeLocale(value);
      }
    });

    return true;
  },

  async set({ state, commit }, { key, value }) {
    if (key === "sounds") {
      state.soundValue = value === "1";
    }
    commit("updateOne", { key, value });
    try {
      const response = await axios.post(`/user/preferences/set`, {
        list: [{ key, value }],
      });

      if (
        response &&
        Array.isArray(response.data) &&
        response.data.length > 0
      ) {
        commit("updateOne", {
          key: response.data.key,
          value: response.data.value,
        });
      }

      if (key === "language") {
        i18n.changeLocale(value);
      }
    } catch (error) {
      Logger.print("error", `Error while saving preference "${key}"`);
      Logger.print("error", error);
    }
  },

  async setBatch({ state, commit }, list = []) {
    if (list.length === 0) return false;
    try {
      const response = await axios.post(`/user/preferences/set`, { list });

      if (Array.isArray(response.data) && response.data.length > 0) {
        response.data.forEach(({ key, value }) =>
          commit("updateOne", { key, value })
        );
      }
    } catch (error) {
      Logger.print("error", `Error while saving preference (batch)`);
      Logger.print("error", error);
    }
  },
};
