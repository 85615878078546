export default {
  FRIEND_REQUEST_SENT: "friend_request_sent",
  FRIEND_REQUEST: "friend_request",
  FRIEND_REQUEST_ACCEPTED: "friend_request_accepted",
  FRIEND_REQUEST_REJECTED: "friend_request_rejected",
  GIFT_SENT: "gift_sent",
  ANIMATION_SENT: "animation_sent",
  CHAT_MSG_SENT_ROOM: "chat_message_sent_room",
  CHAT_MSG_SENT_GLOBAL: "chat_message_sent_global",
  CHAT_MSG_SENT_USER: "chat_message_sent_user",
  CHIP_TRANSFER_RECEIVED: "chip_transfer_received",
  JACKPOT_EXPLOSION: "jackpot_explosion",
  JACKPOT_AMOUNT_CHANGED: "jackpot_amount_changed",
  PROGRESSIVE_JACKPOT_AMOUNT_CHANGED: "progressive_jackpot_amount_changed",
  CONFIGURATION_CHANGED: "configuration_changed",
  AD_BONUS_GAINED: "ad_bonus_gained",
  USER_DATA_UPDATED: "user_data_updated",
  USER_LEVEL_UP: "user_level_up",
  EMOTE: "emote",
  INVITE_FRIENDS_REWARDS: "invite_friends_rewards",
  SLOT_BIG_WINNER: "slot_big_winner",
  FRIEND_ONLINE: "friend_online",
  FRIEND_OFFLINE: "friend_offline",
  INVITE_FRIEND_ROOM: "invite_friend_room",
  PLAYER_PRODUCT_PURCHASE_SUCCESSFUL: "player_product_purchase_successful",
};
