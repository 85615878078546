<template>
  <div class="seats">
    <Seat
      v-for="(seat, seatIndex) in nGetSeats"
      :key="'seat-' + seatIndex"
      :index="seatIndex"
    />
    <DealerIcon />

    <SeatExpression
      v-for="(animation, index) in nGetSeats"
      :key="`animation-${index}`"
      :index="index"
    />

    <SeatGift
      v-for="(gift, index) in nGetSeats"
      :key="`gift-${index}`"
      :index="index"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Seat from "./Seat";
import DealerIcon from "./DealerIcon";
import SeatExpression from "./SeatExpression";
import SeatGift from "./SeatGift";

export default {
  name: "Seats",
  components: {
    Seat,
    DealerIcon,
    SeatExpression,
    SeatGift,
  },
  watch: {
    nGetSeats: {
      handler(newVal, oldVal) {
        oldVal.forEach((element, index) => {
          if (oldVal[index].id !== newVal[index].id) {
            this.$store.commit("gift/giftRemoveByUserId", oldVal[index].id);
          }
        });
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      nGetSeats: "service/nGetSeats",
      getGifts: "gift/getGifts",
    }),
    seatLength() {
      return this.nGetSeats.length || [];
    },
  },
  methods: {
    testReconnect() {
      this.$store.dispatch(
        "authentication/reAuthenticated",
        {},
        { root: true }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.seats {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 5;
}
</style>
