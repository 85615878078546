import Unzipper from "@/plugins/unzipper/unzipper";

const instance = new Unzipper()

export default {

    instance,

    install(Vue) {
        Vue.prototype.$plugins.unzipper = instance;
    }
}
