<template>
  <div class="jackpot-notification-animation">
    <div
      class="jackpot-notification"
      :style="{ 'background-image': backgroundUrl }"
    ></div>
    <!-- <SpritesheetAnimation id="jackpotNotification-sprite"
      :spritesheet="require('@/assets/images/animations/jackpot_notification.png')"
      :json="require('@/assets/images/animations/jackpot_notification.json')"
      :yoyo="false"
      :autoplay="false"
      :fps="28"
      :required-height="450"
      ref="jackpotNotificationAnimation"
    /> -->
  </div>
</template>

<script>
// import SpritesheetAnimation from "../../Utils/SpritesheetAnimation";

export default {
  name: "JackpotNotificationAnimation",
  // components: { SpritesheetAnimation },
  // mounted() {
  //   this.$refs.jackpotNotificationAnimation.play()
  // }
  props: {
    backgroundUrl: {
      require: true,
      default: `url(${require("@/assets/images/table/jackpot.png")})`,
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.jackpot-notification-animation {
  .jackpot-notification {
    background-image: url("~@/assets/images/table/jackpot.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0px 0px;
    width: 402px;
    height: 365px;
  }
}
</style>
