export default {
  getJackpots(state, getters) {
    return {
      system: getters.getSystemJackpots,
      special: getters.getSpecialJackpots,
    };
  },

  getAllJackpots(state, getters, rootState, rootGetters) {
    const tableListName = rootState.service.lastOpenedTableList;
    if (tableListName == "TexasHoldem") {
      return state.texasJackpots;
    } else if (tableListName == "Omaha") {
      return state.omahaJackpots;
    }
  },

  getSystemJackpots(state) {
    const systemJackpots = [...state.list]
      .filter(
        (jackpot) => jackpot.type === "system" && jackpot.rules.length > 0
      )
      .sort((a, b) => b.amount - a.amount)
      .sort((a, b) => b.rules[0].rank - a.rules[0].rank);
    return {
      jackpots: systemJackpots,
      total: Math.floor(
        systemJackpots.reduce((acc, current) => acc + current.amount, 0)
      ),
    };
  },

  getSpecialJackpots(state) {
    const specialJackpots = [...state.list]
      .filter(
        (jackpot) => jackpot.type === "special" && jackpot.rules.length > 0
      )
      .sort((a, b) => b.amount - a.amount)
      .sort((a, b) => b.rules[0].rank - a.rules[0].rank);
    return {
      jackpots: specialJackpots,
      total: Math.floor(
        specialJackpots.reduce((acc, current) => acc + current.amount, 0)
      ),
    };
  },
};
