export default {
  userDetails: false,
  userDetailsTable: false,
  shop: false,
  rules: false,
  settings: false,
  chat: false,
  social: false,
  userCase: false,
  security: false,
  inbox: false,
  disconnect: false,
  disconnectInactivity: false,
  banned: false,
  affiliate: false,
  leaderBoard: false,
  sendChip: false,
  friendRequest: false,
  emojis: false,
  profileConfirm: false,
  confirm: false,
  bonus: false,
  history: false,
  friendDetails: false,
  friendsWindow: false,
  search: false,
  isVipRedirected: false,
  useInviteCode: false,
  pinCode: false,
  createTable: false,
  lastOpenedModal: "",
  bonusWindow: false,
  updateNotesWindow: false,
  forceUpdateWindow: false,
  onlinefriendsWindow: false,
  newSettingsWindow: false,
  dailyLoginBonus: false,
  notificationWindow: false,
  notificationWindowTabValue: "",
  watchWinWindow: false,
  maintenance: false,
  nextWindowText: "buy-vip",
  sendGiftWindow: false,
  showTableInviteWindow: false,
  deleteModal: false,
  blockInvites: false,
};
