import Logger from "@/logger";
import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";

export default {
  setUserData(state, data) {
    for (const datum in data) {
      if (typeof data[datum] === "undefined") continue;
      if (["chip"].includes(datum)) {
        this._vm.$set(state, "chip", data[datum]);
      }
      if (["availableChip"].includes(datum)) {
        this._vm.$set(
          state,
          "availableChip",
          data[datum] - state.userCase.total
        );
      }
      this._vm.$set(state, datum, data[datum]);
    }

    EventBus.$emit(EventConstants.USER_DATA_UPDATED, data);

    Logger.print("info", data);
  },

  setAffiliateGameTotals(state, { poker, blackjack, slots, qq }) {
    state.affiliateStatus.referencesTotal.poker = poker;
    state.affiliateStatus.referencesTotal.blackjack = blackjack;
    state.affiliateStatus.referencesTotal.slots = slots;
    state.affiliateStatus.referencesTotal.qq = qq;
  },

  setAllAffiliateGameTotals(state, { poker, blackjack, slots, qq }) {
    state.allAffiliateTotal = { poker, blackjack, slots, qq };
  },

  setAffiliateTotal(state, total) {
    if (total) {
      state.affiliateStatus.total = total;
    }
  },

  pushMyReferences(state, players) {
    let _players = [...state.affiliateStatus.references];

    for (let i = 0; i < players.length; i++) {
      _players.push({
        name: players[i].name,
        date: new Date(players[i].registeredAt * 1000).toLocaleDateString(
          "tr-TR"
        ),
        dateUnix: players[i].registeredAt,
        id: players[i].id,
        qq: players[i].gainedAmounts[1]["QIU_QIU"],
        blackjack: players[i].gainedAmounts[5]["BLACK_JACK"],
        slot:
          players[i].gainedAmounts[2]["TURN_LEGENDS"] +
          players[i].gainedAmounts[3]["BAMBOO_PANDA"] +
          players[i].gainedAmounts[4]["FAFAFA"] +
          players[i].gainedAmounts[6]["OLYMPUS"],
        poker: players[i].gainedAmounts[0]["TURN_POKER"],
        total:
          players[i].gainedAmounts[1]["QIU_QIU"] +
          (players[i].gainedAmounts[2]["TURN_LEGENDS"] +
            players[i].gainedAmounts[3]["BAMBOO_PANDA"] +
            players[i].gainedAmounts[4]["FAFAFA"]) +
          players[i].gainedAmounts[0]["TURN_POKER"] +
          players[i].gainedAmounts[5]["BLACK_JACK"] +
          players[i].gainedAmounts[6]["OLYMPUS"],
      });
    }

    this._vm.$set(state.affiliateStatus, "references", _players);
  },

  setMyReferences(state, players) {
    let _players = [];

    for (let i = 0; i < players.length; i++) {
      _players.push({
        name: players[i].name,
        date: new Date(players[i].registeredAt * 1000).toLocaleDateString(
          "tr-TR"
        ),
        dateUnix: players[i].registeredAt,
        id: players[i].id,
        qq: players[i].gainedAmounts[1]["QIU_QIU"],
        blackjack: players[i].gainedAmounts[5]["BLACK_JACK"],
        slot:
          players[i].gainedAmounts[2]["TURN_LEGENDS"] +
          players[i].gainedAmounts[3]["BAMBOO_PANDA"] +
          players[i].gainedAmounts[4]["FAFAFA"] +
          players[i].gainedAmounts[6]["OLYMPUS"],
        poker: players[i].gainedAmounts[0]["TURN_POKER"],
        total:
          players[i].gainedAmounts[1]["QIU_QIU"] +
          (players[i].gainedAmounts[2]["TURN_LEGENDS"] +
            players[i].gainedAmounts[3]["BAMBOO_PANDA"] +
            players[i].gainedAmounts[4]["FAFAFA"]) +
          players[i].gainedAmounts[0]["TURN_POKER"] +
          players[i].gainedAmounts[5]["BLACK_JACK"] +
          players[i].gainedAmounts[6]["OLYMPUS"],
      });
    }

    this._vm.$set(state.affiliateStatus, "references", _players);
  },

  setAllMyReferences(state, players) {
    let _players = [];

    for (let i = 0; i < players.length; i++) {
      _players.push({
        name: players[i].name,
        date: new Date(players[i].registeredAt * 1000).toLocaleDateString(
          "tr-TR"
        ),
        dateUnix: players[i].registeredAt,
        id: players[i].id,
        qq: players[i].gainedAmounts[1]["QIU_QIU"],
        blackjack: players[i].gainedAmounts[5]["BLACK_JACK"],
        slot:
          players[i].gainedAmounts[2]["TURN_LEGENDS"] +
          players[i].gainedAmounts[3]["BAMBOO_PANDA"] +
          players[i].gainedAmounts[4]["FAFAFA"] +
          players[i].gainedAmounts[6]["OLYMPUS"],
        poker: players[i].gainedAmounts[0]["TURN_POKER"],
        total:
          players[i].gainedAmounts[1]["QIU_QIU"] +
          (players[i].gainedAmounts[2]["TURN_LEGENDS"] +
            players[i].gainedAmounts[3]["BAMBOO_PANDA"] +
            players[i].gainedAmounts[4]["FAFAFA"]) +
          players[i].gainedAmounts[0]["TURN_POKER"] +
          players[i].gainedAmounts[5]["BLACK_JACK"] +
          players[i].gainedAmounts[6]["OLYMPUS"],
      });
    }

    this._vm.$set(state, "allAffiliatePlayer", _players);
  },

  setUserGameHistory(state, data) {
    this._vm.$set(state, "userGameHistory", data);
  },
};
