<template>
  <div
    class="dealer-icon dealer-button"
    :class="getClasses"
    v-if="getActiveRoomState.dealer >= 0"
  ></div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DealerIcon",
  computed: {
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
      nAmISitting: "service/nAmISitting",
      nGetMySeat: "service/nGetMySeat",
    }),
    getDealerIndex() {
      const originalIndex = this.getActiveRoomState.dealer;
      if (!this.nAmISitting) return originalIndex;

      const shiftValue = 7 - this.nGetMySeat.position;

      let ret = (originalIndex + shiftValue) % 9;

      if (ret < 0) return 8;

      return ret;
    },
    getClasses() {
      const classes = [];
      classes.push(`dealer-index-${this.getDealerIndex}`);
      return classes;
    },
  },
};
</script>

<style scoped lang="scss">
.dealer-button {
  height: 40px;
  width: 40px;
  background: url("~@/assets/images/table/dealer-button.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.dealer-icon {
  position: absolute;
  top: 0;
  left: 0;
  &.dealer-index-0 {
    top: 330px;
    left: 85px;
  }
  &.dealer-index-1 {
    top: 220px;
    left: 115px;
  }
  &.dealer-index-2 {
    top: 100px;
    left: 470px;
  }
  &.dealer-index-3 {
    top: 100px;
    left: 825px;
  }
  &.dealer-index-4 {
    top: 220px;
    left: 1210px;
  }
  &.dealer-index-5 {
    top: 330px;
    left: 1243px;
  }
  &.dealer-index-6 {
    top: 435px;
    left: 974px;
  }
  &.dealer-index-7 {
    top: 435px;
    left: 542px;
  }
  &.dealer-index-8 {
    top: 436px;
    left: 353px;
  }
}
</style>
