<template>
  <span>{{ $props.format ? $store.getters.getChipFormat(getDisplayNumber) : getDisplayNumber }}</span>
</template>
<script>
import gsap from 'gsap';

export default {
  name: "AnimatedNumber",
  props: {
    number: {
      default: 0
    },
    format: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 0.5
    }
  },
  computed: {
    getDisplayNumber() {
      return Number(this.displayNumber).toFixed(0);
    }
  },
  watch: {
    number(newValue) {
      if(this.number === this.displayNumber)
        return;

      gsap.to(this.$data, { duration: this.$props.duration, displayNumber: newValue });
    }
  },
  data() {
    return {
      displayNumber: 0,
    }
  },
  mounted() {
    this.displayNumber = this.number ? this.number : 0;
  }
}
</script>