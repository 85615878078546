import { Capacitor } from "@capacitor/core";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

const HapticsManager = {
  /*
   * Styles: HEAVY, MEDIUM, LIGHT
   * */
  async impact(style) {
    if (Capacitor.getPlatform() === "web") return false;
    return Haptics.impact({ style });
  },

  async vibrate(duration = 500) {
    if (Capacitor.getPlatform() === "web") return false;
    return Haptics.vibrate({ duration });
  },

  async selectionStart() {
    return Haptics.selectionStart();
  },

  async selectionChanged() {
    return Haptics.selectionChanged();
  },

  async selectionEnd() {
    return Haptics.selectionEnd();
  },

  /*
   * Types: SUCCESS, WARNING, ERROR
   * */
  async notification(type = "SUCCESS") {
    if (Capacitor.getPlatform() === "web") return false;
    return Haptics.notification({ type });
  },
};

export default {
  instance: HapticsManager,

  install(Vue) {
    Vue.prototype.$plugins.haptics = HapticsManager;
  },
};
