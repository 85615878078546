<template>
  <div
    class="daily-item sprite-list-pop-up"
    :class="isActive ? 'day-active-background' : 'day-background'"
  >
    <div class="daily-item-title flex-center">{{ $t("dailyEntry.day") }}</div>
    <div
      class="daily-item-content"
      :style="{
        marginTop: `${isActive ? '5' : ''}0px`,
      }"
    >
      <div
        v-if="index < activeIndex"
        class="sprite-profile-popup ok-icon"
      ></div>
      <div
        v-if="!(index > activeIndex)"
        class="sprite-list-pop-up"
        :class="`chip-day${index}`"
      ></div>
      <div v-else class="sprite-list-pop-up chip-close-icon"></div>
    </div>

    <div class="daily-item-price flex-center">$100</div>
  </div>
</template>

<script>
export default {
  name: "DailyItem",
  props: {
    index: {
      type: Number,
      default: 0,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isActive() {
      return this.index === this.activeIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.daily-item {
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 36px;
  text-shadow: 0 3px 5px #000000;
  text-transform: uppercase;
  color: #ffffff;

  &-title {
    min-height: 48px;
    width: 100%;
  }
  &-content {
    min-height: 168px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .ok-icon {
      position: relative;
      top: -120px;
      left: 115px;
    }
  }
  &-price {
    position: relative;
    top: 31px;
    width: 100%;
  }
}
.daily-item:last-child {
  margin-right: 0px;
}
</style>
