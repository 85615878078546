<template>
  <div class="dealer-character">
    <div class="dealer-character-inner">
      <!-- <div class="dealer-women"></div> -->
      <DealerAnimation class="dealer-animation" ref="dealerAnimation" />
      <TipButton />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DealerAnimation from "@/components/Misc/Animations/DealerAnimation";
import TipButton from "@/components/Misc/PokerTable/TipButton";

export default {
  name: "DealerCharacter",
  components: {
    DealerAnimation,
    TipButton,
  },
  computed: {
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
    }),
  },
  data() {
    return {
      stageOneTiming: 0,
      stageTwoTiming: 0,
      stageThreeTiming: 0,
    };
  },
  watch: {
    "getActiveRoomState.stage": function (stage) {
      if (
        stage === 1 &&
        this.getActiveRoomState.players.filter((p) => p.position > -1).length >=
          2
      ) {
        if (
          this.$refs.dealerAnimation &&
          this.$refs.dealerAnimation.$refs.characterSprite
        ) {
          this.$plugins.audio.play("Shuffle");
          this.$refs.dealerAnimation.$refs.characterSprite.play();
        }
        if (this.stageOneTiming) {
          clearTimeout(this.stageOneTiming);
          this.stageOneTiming = 0;
        }
        this.stageOneTiming = setTimeout(this.animationStop, 800);
      }
      if ([2, 3, 4].includes(stage)) {
        if (
          this.$refs.dealerAnimation &&
          this.$refs.dealerAnimation.$refs.characterSprite
        ) {
          this.$refs.dealerAnimation.$refs.characterSprite.play();
        }
        if (this.stageTwoTiming) {
          clearTimeout(this.stageTwoTiming);
          this.stageTwoTiming = 0;
        }
        this.stageTwoTiming = setTimeout(this.animationStop, 800);
      }
    },
  },
  methods: {
    animationStop() {
      if (
        this.$refs.dealerAnimation &&
        this.$refs.dealerAnimation.$refs.characterSprite
      ) {
        this.$refs.dealerAnimation.$refs.characterSprite.stop();
        this.$refs.dealerAnimation.$refs.characterSprite.currentIndex = 0;
      } else {
        if (this.stageThreeTiming) {
          clearTimeout(this.stageThreeTiming);
          this.stageThreeTiming = 0;
        }
        this.stageThreeTiming = setTimeout(this.animationStop, 1000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dealer-character {
  position: absolute;
  top: 70px;
  left: 0;
  height: 325px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  &-inner {
    height: 325px;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    // .dealer-women {
    //   background-image: url("~@/assets/images/table/dealer-women.png");
    //   background-repeat: no-repeat;
    //   background-size: contain;
    //   width: 270px;
    //   height: 270px;
    //   position: absolute;
    //   top: 0px;
    //   left: 6px;
    // }

    .dealer-animation {
      height: 325px;
      overflow: visible;
    }
  }
}
</style>
