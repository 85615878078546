<template>
  <div
    class="friend-request-item"
    :class="index % 2 == 0 ? 'bg-black' : 'bg-red'"
  >
    <div class="friend-request-item-avatar" @click="getUser(player.sender.id)">
      <Avatar :url="player.sender.avatar" size="120" :crown="-1" />
    </div>
    <div class="friend-request-item-content">
      <div class="friend-request-item-content-text">
        {{ player.sender.name }}
        {{ $t("friendsRequestWindow.friendRequestToYou") }}
      </div>
    </div>
    <div
      class="friend-request-item-accept ui-button ui-button-small-green"
      @click="requestResponse(true)"
    >
      <span>{{ $t("general.accept") }}</span>
    </div>
    <div
      class="friend-request-item-reject ui-button ui-button-small-red"
      @click="requestResponse(false)"
    >
      <span>{{ $t("general.reject") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FriendsRequestItem",
  components: {
    Avatar: () => import("@/components/Misc/Avatar"),
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    player: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  computed: {
    getBaseAvatarUrl() {
      return process.env.VUE_APP_AVATAR_URL;
    },
  },
  methods: {
    async requestResponse(status) {
      const response = await this.$store.dispatch(
        "friends/respondFriendRequest",
        {
          requestId: this.player.id,
          accept: status,
        }
      );
      if (response && response.status == 204) {
        this.$store.commit(
          "friends/friendRequestRemoveItem",
          this.player.sender.id
        );
      }
    },
    async getUser(userid) {
      if (userid) {
        if (parseInt(userid) === this.$store.state.user.id) {
          return this.$plugins.toast.show({
            text: this.$t("searchWindow.ownId"),
            duration: 2000,
            variant: "danger",
          });
        }
        this.loading = true;
        const res = await this.$store.dispatch("friends/getProfiles", [userid]);
        if (!res) {
          this.$plugins.toast.show({
            text: this.$t("confirmWindow.userNotFound"),
            duration: 2000,
            variant: "danger",
          });
          this.loading = false;
          return;
        }
        this.$store.commit("friends/setSelectedUserModal", res[0]);
        this.loading = false;
        this.$store.commit("modals/show", "friendDetails");
      } else {
        return this.$plugins.toast.show({
          text: this.$t("searchWindow.validId"),
          duration: 2000,
          variant: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.friend-request-item {
  width: 98%;
  min-height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin: 10px 0;
  font-size: 34px;
  &-avatar {
    width: 6%;
    margin-left: 50px;
  }
  &-content {
    width: 61%;
    background-color: #6f1c26;
    min-height: 85px;
    border-radius: 40px;
    margin: 0 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 25px;
  }
  &-accept,
  &-reject {
    text-transform: uppercase;
    margin-left: 10px;
  }
  .ui-button-small-green {
    margin-right: 20px;
  }
}
</style>
