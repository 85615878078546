<script>
export default {
  methods: {
    flipAnimation(id) {
      return this.$store.state.friends.friendsList.list.some(
        (friend) => friend.user.id === id
      );
    },
  },
};
</script>
