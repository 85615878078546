<template>
  <div class="navigation-menu">
    <div class="navigation-menu-container" @click.stop="showAffiliateWindow">
      <button type="button" class="navigation-menu-button no-click-outside">
        <span class="navigation-menu-button-icon affiliate-icon"></span>
      </button>
    </div>
    <div class="navigation-menu-container" @click.stop="showSearchWindow">
      <button type="button" class="navigation-menu-button no-click-outside">
        <span
          class="navigation-menu-button-icon lobby-sprite search-icon"
        ></span>
      </button>
    </div>

    <div class="navigation-menu-container" @click.stop="showLeadersWindow">
      <button type="button" class="navigation-menu-button no-click-outside">
        <span
          class="navigation-menu-button-icon lobby-sprite leaders-icon"
        ></span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AnalyticsEvents from "@/plugins/analytics/events";

export default {
  name: "NavigationBar",
  computed: {
    ...mapGetters("chat", ["isAnyUnread"]),
    ...mapGetters({
      isAnyRequest: "friends/isAnyRequest",
    }),
  },
  methods: {
    showAffiliateWindow() {
      this.$store.commit("modals/show", "affiliate");
      this.$plugins.audio.play("Button");
      this.$adjust.trackEvent("ClickGoToReference");
    },
    showSearchWindow() {
      this.$store.commit("modals/show", "search");

      this.$plugins.audio.play("Button");
      this.$adjust.trackEvent("ClickGoToSearchPlayer");
    },
    showLeadersWindow() {
      this.$store.commit("modals/show", "leaderBoard");

      this.$plugins.audio.play("Button");
    },
  },
};
</script>

<style scoped lang="scss">
.navigation-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  margin-left: 30px;
  &-container {
    margin-left: 50px;
    cursor: pointer;
    .navigation-menu-button {
      width: 100px;
      height: 100px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #cf707f;
      border-radius: 100%;
      background: rgba(black, 0.5);

      &:active {
        filter: brightness(50%);
      }
      .affiliate-icon {
        height: 54px;
        width: 84px;
        background: url("~@/assets/images/lobby/affiliate.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}
</style>
