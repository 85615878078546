<template>
  <div class="chat-container">
    <!-- <div class="chat-top" v-if="selectorTab === 'chat'">
    </div> -->
    <div
      class="chat-body"
      :style="{
        maxHeight: `${selectorTab === 'chat' ? '80%' : '100%'}`,
        height: `${selectorTab === 'chat' ? '65%' : '100%'}`,
        minHeight: `${selectorTab === 'chat' ? '90%' : '80%'}`,
      }"
    >
      <template v-if="selectorTab === 'chat'">
        <div
          v-for="(message, messageIndex) in getMessages"
          :key="'message-' + messageIndex"
          class="chat-row"
          :class="[
            message.sender.id === player.id ? 'me' : '',
            message.system ? 'message-system' : '',
          ]"
        >
          <template v-if="message.system">
            <div class="chat-user-avatar"></div>
            <div class="chat-info">
              <div class="chat-username"></div>
              <div class="chat-bubble-text" v-html="message.message"></div>
            </div>
          </template>
          <template v-if="!message.system">
            <div class="chat-user-avatar">
              <Avatar
                :url="message.sender.avatar"
                :crown="getUserCrown(message.sender.id)"
                size="100"
              />
              <div class="chat-username">{{ message.sender.name }}</div>
            </div>
            <div class="chat-info">
              <div class="chat-bubble-text selectable-text">
                {{ message.message }}
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-if="selectorTab === 'quick'">
        <div
          class="emote-window-fast-chat-message"
          v-for="(fastMessage, fastMessageIndex) in $t('fastChatMessages')"
          :key="'fast-message-' + fastMessageIndex"
          @click="sendMessage(fastMessage)"
        >
          <span>{{ $t("fastChatMessages")[fastMessageIndex] }}</span>
          <span class="sprite-table-pop-up white-line"></span>
        </div>
      </template>
      <template v-if="selectorTab === 'emoji'">
        <div class="emote-window-emotes-tabs">
          <div
            class="emote-window-emotes-tab"
            @click="emojiTab = 'emojis'"
            :class="[emojiTab === 'emojis' ? 'active' : '']"
          >
            <div class="sprite emote-emojis-sprite emote-4"></div>
          </div>

          <div
            class="emote-window-emotes-tab"
            @click="emojiTab = 'cats'"
            :class="[emojiTab === 'cats' ? 'active' : '']"
          >
            <div class="sprite emote-cats-sprite emote-1"></div>
          </div>
          <div
            class="emote-window-emotes-tab"
            @click="emojiTab = 'dogs'"
            :class="[emojiTab === 'dogs' ? 'active' : '']"
          >
            <div class="sprite emote-dogs-sprite emote-1"></div>
          </div>
          <div
            class="emote-window-emotes-tab"
            @click="emojiTab = 'birds'"
            :class="[emojiTab === 'birds' ? 'active' : '']"
          >
            <div class="sprite emote-birds-sprite emote-1"></div>
          </div>
        </div>
        <div class="emote-window-emotes-items">
          <div
            v-if="!user.vip.status && emojiTab !== 'emojis'"
            class="sprite-table-pop-up vip-text-tab"
          >
            <span class="vip-text">Bu Paket VIP üyelerimize özeldir.</span>
          </div>
          <div
            class="emote-window-emotes-item"
            v-for="(emote, emoteIndex) in getEmotes"
            :key="'emote-' + emoteIndex"
          >
            <div
              :class="[`emote-${emojiTab}-sprite`, `emote-${emote}`]"
              @click="sendEmote(`${emojiTab}-${emote}`, emojiTab)"
            ></div>
          </div>
          <!-- <div
            class="emote-window-emotes-item"
            v-for="(emote, emoteIndex) in getEmotes"
            :key="'emote-' + emoteIndex"
          >
            <div
              :class="[`emote-${emojiTab}-sprite`, `emote-${emote}`]"
              @click="sendEmote(`${emojiTab}-${emote}`, emojiTab)"
            ></div>
          </div> -->
        </div>
      </template>
    </div>
    <div
      class="chat-footer"
      :style="{
        maxHeight: `${chatActive && selectorTab === 'chat' ? '20' : '10'}%`,
      }"
    >
      <div class="bottom-bar">
        <div
          class="message-process"
          v-if="chatActive && selectorTab === 'chat'"
        >
          <div
            class="sprite-table-pop-up trash-icon"
            @click="removeOldMessages"
          ></div>
          <emoji-picker
            ref="emojiPicker"
            @emoji="onEmojiClicked"
            class="emojis-outer no-click-outside"
          >
            <div
              slot="emoji-invoker"
              slot-scope="{ events: { click: clickEvent } }"
              @click.stop="clickEvent"
            >
              <button type="button" class="chat-button">
                <span class="emoji"></span>
              </button>
            </div>
            <div slot="emoji-picker" slot-scope="{ emojis, insert }">
              <div class="emojis-container no-click-outside">
                <div
                  v-for="(emojiGroup, category) in emojis"
                  :key="category"
                  class="emoji-category"
                >
                  <div class="category-heading">{{ category }}</div>
                  <div class="category-emojis">
                    <span
                      v-for="(emoji, emojiName) in emojiGroup"
                      :key="emojiName"
                      @click="insert(emoji)"
                      :title="emojiName"
                      class="emoji-button"
                      >{{ emoji }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </emoji-picker>
          <input
            type="text"
            v-model="message"
            :placeholder="chatMessageInputPlaceholderText"
            maxlength="255"
            @keyup.enter="send"
          />
          <div
            type="button"
            class="send-button sprite-table-pop-up send-icon"
            @click="send"
          ></div>
        </div>
        <div class="selector" @click="tabChange('chat')">
          <span
            :class="
              selectorTab === 'chat'
                ? 'sprite-table-pop-up chat-icon'
                : 'sprite-table-pop-up off-chat-icon'
            "
          ></span>
        </div>
        <div class="selector" @click="tabChange('emoji')">
          <span
            :class="
              selectorTab === 'emoji'
                ? 'sprite-table-pop-up emoji-icon'
                : 'sprite-table-pop-up off-emoji-icon'
            "
          ></span>
        </div>
        <div class="selector" @click="tabChange('quick')">
          <span
            :class="
              selectorTab === 'quick'
                ? 'sprite-table-pop-up hi-icon'
                : 'sprite-table-pop-up off-hi-icon'
            "
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";
import { mapState, mapGetters } from "vuex";
import Avatar from "@/components/Misc/Avatar";
import EmojiPicker from "vue-emoji-picker";

const CHAT_BLOCK_SECONDS = 3;

export default {
  name: "Chat",
  components: {
    EmojiPicker,
    Avatar,
  },
  watch: {
    showMessages(currentStatus) {
      if (currentStatus) {
        this.scrollLocked = false;
        this.scrollToBottom();
      }
    },
    "$parent.showChatPopup": {
      handler(val) {
        if (val) {
          this.scrollLocked = false;
          this.scrollToBottom();
        }
      },
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      nAmISitting: "service/nAmISitting",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    chatActive() {
      return (
        this.nAmISitting ||
        (this.getActiveRoomState.minBuy <= this.user.chip &&
          this.user.vip.status)
      );
    },
    player() {
      return this.user;
    },
    getPlayersWithoutMe() {
      return [...this.getActiveRoomState.players].filter(
        (p) => p.id !== this.player.id
      );
    },
    getMessages() {
      return [...this.messages].filter((message) => {
        return !this.$parent.$data.mutedPlayers.includes(message.sender.id);
      });
    },
    chatMessageInputPlaceholderText() {
      if (this.blocked)
        return this.$t("chatWindow.xSecondsLeft").replace(
          "{seconds}",
          this.blockCountdown
        );
      return this.$t("chatWindow.typeHere");
    },
    getEmotes() {
      const categories = {
        emojis: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        ],
        birds: [1, 2, 3, 4, 5, 6, 7, 8],
        dogs: [1, 2, 3, 4, 5, 6, 7, 8],
        cats: [1, 2, 3, 4, 5, 6, 7, 8],
        rabbits: [1, 2, 3, 4, 5, 6, 7, 8],
      };

      if (typeof categories[this.$data.emojiTab] === "undefined")
        return categories["emojis"];

      return categories[this.$data.emojiTab];
    },
  },
  data: () => ({
    messages: [],
    message: "",
    blocked: false,
    blockCountdown: 3,
    blockInterval: 0,
    scrollLocked: false,
    selectorTab: "chat",
    status: "on",
    emojiTab: "emojis",
  }),
  methods: {
    tabChange(value) {
      if (!this.nAmISitting) {
        if (value == "emoji" || value == "quick") {
          return;
        }
      }
      this.selectorTab = value;
    },
    removeOldMessages() {
      this.messages.splice(0, this.messages.length);
    },
    async send() {
      if (!this.nAmISitting && !this.user.vip.status) return false;

      // if (this.blocked) return false;

      const response = await this.$store.dispatch("chat/sendRoomMessage", {
        game: "poker",
        room: this.getActiveRoomState.id,
        message: this.message,
      });

      if (response !== false) {
        this.scrollToBottom();
        this.message = "";
        if (this.user.vip.status) return;
        // this.blocked = true;
        // this.blockCountdown = CHAT_BLOCK_SECONDS;
        // if (this.blockInterval) clearInterval(this.blockInterval);
        // this.blockInterval = setInterval(() => {
        //   this.blockCountdown--;
        //   if (this.blockCountdown < 1) {
        //     clearInterval(this.blockInterval);
        //     this.blocked = false;
        //   }
        // }, 1000);
        return true;
      }

      return false;
    },
    scrollToBottom() {
      if (this.scrollLocked) {
        return false;
      }

      const chatScrollingBox = this.$el.querySelector(".chat-body");
      if (chatScrollingBox) {
        chatScrollingBox.scrollTop = chatScrollingBox.scrollHeight;
      }
    },
    onScrolled(element) {
      if (
        element.target.offsetHeight + element.target.scrollTop >=
        element.target.scrollHeight
      ) {
        this.scrollLocked = false;
        return;
      }
      this.scrollLocked = true;
    },
    onChatMessageReceived(data) {
      // if (this.messages.length > 30) this.messages.splice(0, 1);

      const player = this.getActiveRoomState.players.find(
        (player) => player.id === data.sender
      );

      if (typeof player === "undefined") return;

      if (!this.$parent.$parent.$parent.$data.showChatPopup)
        this.$parent.$parent.$parent.$data.unreadChatMessages = true;

      const newMessage = {
        system: false,
        sender: {
          id: data.sender,
          name: String(player.name),
          avatar: String(player.avatar),
        },
        message: data.message,
      };

      this.messages.push(newMessage);
      this.$store.commit("chat/setTableMessages", newMessage);

      this.scrollToBottom();
    },
    onEmojiClicked(emoji) {
      this.message += emoji;
      this.$refs.emojiPicker.$data.display.visible = false;
    },
    onGiftSent({ from, id, to, type }) {
      const toPlayer = this.getActiveRoomState.players.find(
        (p) => p.id === to[0]
      );
      const fromPlayer = this.getActiveRoomState.players.find(
        (p) => p.id === from
      );
      if (!toPlayer || !fromPlayer) return false;

      let giftName = "GIFT";
      let giftsByIds = {
        1: "Birds",
        2: "Mickey",
        3: "Monkey",
        4: "Donkey",
        5: "Covboy",
        6: "Baby",
        7: "Cry",
        8: "Alcohol",
        9: "Turn Poker",
        10: "Cucumber",
        11: "Skeleton Head",
        12: "Hearth",
      };
      if (typeof giftsByIds[id] !== "undefined") giftName = giftsByIds[id];

      this.messages.push({
        system: true,
        sender: {
          id: 0,
          name: "",
          avatar: "",
        },
        message: this.$t("systemMessages.giftSentChatMessage")
          .replace("{from}", `<strong>${fromPlayer.name}</strong>`)
          .replace("{to}", `<strong>${toPlayer.name}</strong>`)
          .replace("{gift_name}", "Gift"),
        // .replace("{gift_name}", giftName),
      });

      this.scrollToBottom();
    },
    getUserCrown(userId) {
      const player = this.getActiveRoomState.players.find(
        (p) => p.id === userId
      );

      return !player ? 0 : player.crown;
    },
    sendMessage(message = "") {
      this.$store.dispatch("chat/sendRoomMessage", {
        game: "poker",
        room: this.$store.state.service.ACTIVE_ROOM.id,
        message,
      });
      this.selectorTab = "chat";
    },
    sendEmote(key, tab) {
      if (!this.user.vip.status && tab !== "emojis") {
        return this.$plugins.toast.show({
          text: "Bu Özelliği kullanabilmek için Vip olmanız gerekmektedir.",
          variant: "danger",
        });
      }

      this.$store.dispatch("gift/sendEmote", {
        id: key,
        roomId: this.$store.state.service.ACTIVE_ROOM.id,
      });
      this.$parent.$parent.$data.showEmojiPopup = false;
    },
  },
  mounted() {
    const chatScrollingBox = this.$el.querySelector(".chat-body");
    if (chatScrollingBox) {
      chatScrollingBox.scrollTop = chatScrollingBox.scrollHeight;
      chatScrollingBox.addEventListener("scroll", this.onScrolled.bind(this));
    }
  },
  beforeDestroy() {
    const chatScrollingBox = this.$el.querySelector(".chat-body");
    chatScrollingBox.removeEventListener(
      "scroll",
      this.onScrolled.bind(this),
      true
    );
    EventBus.$off(
      "onRoomChatMessageReceived",
      this.onChatMessageReceived.bind(this)
    );
    EventBus.$off(EventConstants.GIFT_SENT, this.onGiftSent.bind(this));
  },
  created() {
    EventBus.$on(
      "onRoomChatMessageReceived",
      this.onChatMessageReceived.bind(this)
    );
    EventBus.$on(EventConstants.GIFT_SENT, this.onGiftSent.bind(this));
  },
  updated() {
    this.scrollToBottom();
  },
};
</script>

<style lang="scss" scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  .chat-mute-window {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0px;
    overflow: hidden !important;
    background-color: rgba($containerColor, 0.9);
    z-index: 3;
    opacity: 0;
    transition: all 0.25s ease;
    &.open {
      opacity: 1;
      height: calc(100% - 30px);
      overflow-x: hidden;
      overflow-y: auto;
    }
    &-player {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 7px;
      font-size: 14px;
      border-bottom: 1px solid rgba(black, 0.5);
      .chat-mute-checkbox {
        position: relative;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        background-color: rgba(black, 0.25);
        border: 1px solid rgba(black, 0.5);
        border-radius: 2px;
        transition: all 0.25s ease;
        &.checked {
          border-color: $greenColor;
          box-shadow: 0 0 0 2px rgba($greenColor, 0.2);
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background-image: url("~@/assets/mobile/tick.png");
            background-repeat: no-repeat;
            background-size: 80%;
            background-position: center;
          }
        }
      }
      .player-name {
        display: flex;
        align-items: center;
        justify-content: center;

        .visitor-icon {
          display: flex;
          align-items: center;
          width: 16px;
          height: 16px;
          margin-left: 10px;
          svg {
            display: block;
          }
        }
      }
    }
  }
  .chat-header {
    position: absolute;
    top: -30px;
    right: -1px;
    z-index: 2;
    width: 100%;
    height: 30px;
    line-height: 30px;
    flex-shrink: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      min-width: 50px;
      height: 100%;
      border-top-left-radius: 5px;
      background-color: $containerColor;
      border-left: 1px solid rgba(black, 0.5);
      border-right: 1px solid rgba(black, 0.5);
      border-top: 1px solid rgba(black, 0.5);
      transition: all 0.25s ease;
      .chat-header-button {
        width: 24px;
        height: 24px;
        background-color: transparent;
        border: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        opacity: 0.7;
        transition: all 0.25s ease;
        .text {
          display: none;
        }
        &:first-child {
          margin-left: 10px;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .chat-top {
    width: 100%;
    height: 7%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: rgba(96, 24, 30, 0.5);
  }
  .chat-body {
    height: 100% !important;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 5px;
    &::-webkit-scrollbar {
      display: none;
    }
    .emote-window-emotes-tabs {
      position: absolute;
      width: 90%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(#771e2b, 0.7);
      margin-top: 4px;
      border-radius: 50px;
      left: 30px;
      .emote-window-emotes-tab {
        width: 120px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.6;
        cursor: pointer;
        .sprite {
          transform: scale(0.5);
          &.emote-4 {
            transform: scale(0.45);
            margin-bottom: 5px;
          }
          &.emote-birds-sprite {
            &.emote-1 {
              transform: scale(0.45);
            }
          }
        }
        &.active {
          opacity: 1;
        }
      }
    }
    .emote-window-emotes-items {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      max-height: 750px;
      overflow-x: hidden;
      overflow-y: auto;
      margin-top: 100px;
      .emote-window-emotes-item {
        width: 25%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0;
      }
      .vip-text-tab {
        position: absolute;
        bottom: 300px;
        left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        .vip-text {
          font-size: 36px;
          margin-left: -150px;
        }
      }
    }
    &.direct {
      width: 70%;
      left: unset;
      right: 50px;
    }
    .chat-row {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      padding: 15px;
      &:last-child {
        margin-bottom: 70px;
      }
      .chat-user-avatar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        margin-right: 30px;
        height: 140px;
        .chat-username {
          width: 100%;
          font-size: 27px;
          text-align: center;
        }
      }
      .chat-info {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        max-width: 70%;
        min-width: 30%;

        .chat-bubble-text {
          position: relative;
          padding: 17px;
          width: 100%;
          min-height: 70px;
          max-width: 100%;
          line-height: 40px;
          word-break: break-all;
          box-sizing: border-box;
          background-color: #cf707f;
          color: black;
          margin-top: 10px;
          border-radius: 50px;
          font-size: 28px;
          &::after {
            content: "";
            position: absolute;
            top: 40%;
            left: -6px;
            background-color: #cf707f;
            border: 10px solid #cf707f;
            transform: rotate(45deg);
          }
        }
        .chat-message-date {
          position: absolute;
          right: -150px;
          bottom: 5px;
          font-size: 20px;
          color: rgba(rgb(255, 255, 255), 0.5);
        }
      }

      &.me {
        flex-direction: row-reverse;
        align-items: center;
        .chat-user-avatar {
          margin-left: 30px;
          margin-right: 0;
        }
        .chat-info {
          align-items: flex-end;
          .chat-username {
            text-align: right;
          }
          .chat-bubble-text {
            background-color: #fcc6cf;
            &::after {
              top: 40%;
              left: auto;
              right: -5px;
              background-color: #fcc6cf;
              border: 6px solid #fcc6cf;
            }
          }
          .chat-message-date {
            right: auto;
            left: -150px;
          }
        }
      }

      &.message-system {
        display: flex;
        align-items: center;
        justify-content: center;
        .chat-info {
          max-width: 100%;
          .chat-bubble-text {
            background-color: rgb(252 120 120 / 30%);
            color: rgb(255, 255, 255);
            text-shadow: 0 1px 1px black;
            &::after {
              display: none !important;
            }
          }
        }
      }
    }
    .emote-window-fast-chat-message {
      position: relative;
      font-size: 40px;
      height: 93px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .white-line {
        position: absolute;
        bottom: 0;
      }
      &:last-child {
        .white-line {
          display: none;
        }
      }
    }
  }
  .chat-footer {
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    height: 20%;
    width: 100%;
    color: rgb(255, 255, 255);
    box-sizing: border-box;
    background: rgba(59, 11, 20, 0.9);
    border-top: 5px solid #5f151d;
    z-index: 2;
    .emojis-outer {
      position: absolute;
      width: 90px;
      margin-right: 20px;
      right: 8%;
      top: -2px;
    }
    .chat-button {
      background-color: transparent;
      width: 90px;
      transform: scale(0.8);
      .emoji {
        margin: 0 !important;
        transform: scale(0.6);
      }
    }
    .message-process {
      width: 100%;
      min-height: 70px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0 10px;
      position: absolute;
      bottom: 110px;
      .trash-icon {
        margin-right: 13px;
        cursor: pointer;
        &:active {
          transform: scale(1.1);
        }
      }
      .send-button {
        text-transform: uppercase;
      }
      input {
        width: 100%;
        height: 75px;
        background: white;
        box-shadow: inset 0 6px 6px rgba(black, 0.5);
        font-size: 32px;
        text-align: left;
        border-radius: 45px;
        padding: 20px;
        padding-right: 127px;
        margin-right: 5px;
        color: black;
        box-sizing: border-box;
        transition: all 0.25s ease;
        &::placeholder {
          color: black;
          opacity: 1;
        }
        &:focus {
          box-shadow: inset 0 6px 6px rgba(black, 0.7);
        }
      }
      .send-button {
        position: absolute;
        right: 20px;
        cursor: pointer;
        &:active {
          transform: scale(1.1);
        }
      }
      .emoji {
        position: absolute;
        right: -15px;
        bottom: -88px;
        background-image: url("~@/assets/images/lobby/emoji.png");
        width: 110px;
        height: 110px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        &:active {
          transform: scale(1.1);
          cursor: pointer;
        }
      }
    }

    .bottom-bar {
      width: 100%;
      min-height: 100px;
      max-height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      bottom: 0px;
      .selector {
        width: 33%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 2px solid #902533;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .chat-mobile-tabs {
    display: none;
  }
}

.emojis-outer {
  position: relative;
  width: 180px;
  div {
    button {
      background: transparent;
    }
  }
  .emojis-container {
    position: absolute;
    left: -355px;
    bottom: 90px;
    width: 520px;
    height: 380px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #3e0b15;
    z-index: 3;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px rgba(rgb(255, 255, 255), 0.1);
    .emoji-category {
      margin-bottom: 10px;
      .category-heading {
        height: 1px;
        overflow: hidden;
        background-color: rgba(rgb(255, 255, 255), 0.1);
      }
      .category-emojis {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        span {
          display: block;
          padding: 5px;
          font-size: 60px;
          border-radius: 5px;
          cursor: pointer;
          transition: all 0.25s ease;
          &:hover {
            background-color: rgba(rgb(255, 255, 255), 0.1);
          }
        }
      }
      &:first-child {
        .category-heading {
          display: none;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
