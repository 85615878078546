var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-quick-menu"},[_c('div',{staticClass:"table-quick-menu-inner"},[_c('div',{staticClass:"table-quick-menu-button lobby-simple-menu no-click-outside",on:{"click":_vm.toggleShow}},[_c('div',{staticClass:"sprite-table menu-icon icon no-click-outside"})]),(_vm.show)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickedOutsideWindow),expression:"onClickedOutsideWindow"}],staticClass:"table-quick-menu-dropdown"},[_c('div',{staticClass:"table-quick-menu-dropdown-items"},[_c('div',{staticClass:"dropdown-item",class:[_vm.nAmISitting && _vm.nGetMySeat.leaving ? 'leaving' : ''],on:{"click":_vm.standUpOrGoToLobby}},[_c('div',{staticClass:"icon"},[(_vm.nAmISitting)?_c('div',{staticClass:"sprite-table-pop-up get-up-icon"}):_vm._e(),(!_vm.nAmISitting)?_c('div',{staticClass:"sprite-table-pop-up go-lobby-icon"}):_vm._e()]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.getStandUpOrGoToLobbyText))]),_c('span',{staticClass:"sprite-table-pop-up red-line"})]),_c('div',{staticClass:"dropdown-item no-click-outside",on:{"click":function($event){$event.stopPropagation();return _vm.searchBtnClick.apply(null, arguments)}}},[_vm._m(0),_c('div',{staticClass:"text no-click-outside"},[_vm._v(" "+_vm._s(_vm.$t("affiliate.searchBy"))+" ")]),_c('span',{staticClass:"sprite-table-pop-up red-line"})]),_c('div',{staticClass:"dropdown-item",on:{"click":_vm.toggleSoundsPreference}},[_c('div',{staticClass:"icon"},[_c('div',{staticClass:"sprite-table-pop-up",class:[
                _vm.getPreferenceValue('sounds') == 1
                  ? 'sound-on-icon2'
                  : 'sound-off-icon2',
              ]})]),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.getPreferenceValue("sounds") == 1 ? _vm.$t("tableQuickMenu.soundOn") : _vm.$t("tableQuickMenu.soundOff"))+" ")]),_c('div',{staticClass:"slider-part"},[(_vm.getPreferenceValue('sounds') == 1)?_c('div',{staticClass:"on-part"},[_c('div',{staticClass:"sprite-profile-popup button-green-s"}),_c('span',{staticClass:"sprite-profile-popup circle-button"})]):_c('div',{staticClass:"off-part"},[_c('div',{staticClass:"sprite-profile-popup button-red-s"}),_c('span',{staticClass:"sprite-profile-popup circle-button left"})])]),_c('span',{staticClass:"sprite-table-pop-up red-line"})]),_c('div',{staticClass:"dropdown-item",on:{"click":_vm.toggleEmotePreference}},[_c('div',{staticClass:"icon"},[_c('div',{class:[
                _vm.getPreferenceValue('animated_gifts') == 1
                  ? 'sprite-table-pop-up expression-icon'
                  : 'sprite-table-pop-up expression-off-icon',
              ]})]),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.getPreferenceValue("animated_gifts") == 1 ? _vm.$t("tableQuickMenu.animatedGiftsOff") : _vm.$t("tableQuickMenu.animatedGiftsOn"))+" ")]),_c('div',{staticClass:"slider-part"},[(_vm.getPreferenceValue('animated_gifts') == 1)?_c('div',{staticClass:"on-part"},[_c('div',{staticClass:"sprite-profile-popup button-green-s"}),_c('span',{staticClass:"sprite-profile-popup circle-button"})]):_c('div',{staticClass:"off-part"},[_c('div',{staticClass:"sprite-profile-popup button-red-s"}),_c('span',{staticClass:"sprite-profile-popup circle-button left"})])]),_c('span',{staticClass:"sprite-table-pop-up red-line"})]),_c('div',{staticClass:"dropdown-item no-click-outside",on:{"click":function($event){$event.stopPropagation();return _vm.toggleInviteBlock.apply(null, arguments)}}},[_vm._m(1),_c('div',{staticClass:"text no-click-outside"},[_vm._v(" "+_vm._s(_vm.$t("settings.blockInvites"))+" ")]),_c('div',{staticClass:"slider-part"},[(_vm.$store.state.user.invitation)?_c('div',{staticClass:"on-part"},[_c('div',{staticClass:"sprite-profile-popup button-green-s"}),_c('span',{staticClass:"sprite-profile-popup circle-button"})]):_c('div',{staticClass:"off-part"},[_c('div',{staticClass:"sprite-profile-popup button-red-s"}),_c('span',{staticClass:"sprite-profile-popup circle-button left"})])]),_c('span',{staticClass:"sprite-table-pop-up red-line"})]),(_vm.getRealPlatform !== 'web')?_c('div',{staticClass:"dropdown-item",on:{"click":_vm.toggleVibrationPreference}},[_c('div',{staticClass:"icon"},[_c('div',{staticClass:"sprite-table-pop-up",class:[
                _vm.getPreferenceValue('vibration') == 1
                  ? 'vibration-icon'
                  : 'vibration-off-icon ',
              ]})]),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.getPreferenceValue("vibration") == 1 ? _vm.$t("tableQuickMenu.vibrationOn") : _vm.$t("tableQuickMenu.vibrationOff"))+" ")]),_c('div',{staticClass:"slider-part"},[(_vm.getPreferenceValue('vibration') == 1)?_c('div',{staticClass:"on-part"},[_c('div',{staticClass:"sprite-profile-popup button-green-s"}),_c('span',{staticClass:"sprite-profile-popup circle-button"})]):_c('div',{staticClass:"off-part"},[_c('div',{staticClass:"sprite-profile-popup button-red-s"}),_c('span',{staticClass:"sprite-profile-popup circle-button left"})])]),_c('span',{staticClass:"sprite-table-pop-up red-line"})]):_vm._e(),_c('div',{staticClass:"dropdown-item no-click-outside",on:{"click":function($event){$event.stopPropagation();return _vm.historyBtnClick.apply(null, arguments)}}},[_vm._m(2),_c('div',{staticClass:"text no-click-outside"},[_vm._v(" "+_vm._s(_vm.$t("blackJack.gameHistory"))+" ")]),_c('span',{staticClass:"sprite-table-pop-up red-line"})]),_c('div',{staticClass:"dropdown-item no-click-outside",on:{"click":function($event){$event.stopPropagation();return _vm.toggleReportPopup.apply(null, arguments)}}},[_vm._m(3),_c('div',{staticClass:"text no-click-outside"},[_vm._v(" "+_vm._s(_vm.$t("reportWindow.report"))+" ")])])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon no-click-outside"},[_c('div',{staticClass:"sprite-table-pop-up player-search-icon no-click-outside"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon no-click-outside"},[_c('div',{staticClass:"player-invite-icon no-click-outside"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon no-click-outside"},[_c('div',{staticClass:"sprite-table-pop-up hand-history-icon no-click-outside"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon no-click-outside"},[_c('div',{staticClass:"sprite-table-pop-up report-icon no-click-outside"})])
}]

export { render, staticRenderFns }