<template>
  <div class="jackpot-notification-container" v-if="animate">
    <div class="notification-inner">
      <JackpotNotificationAnimation />
      <Avatar
        class="player-window-avatar"
        size="120"
        :url="$store.getters['user/getAvatarUrl']($data.winner.id)"
      />
      <div class="texts">
        <p class="username">{{ winner.name }}</p>
        <!-- <div class="jackpot-name">{{ $data.blind }}</div> -->
      </div>
      <!-- <div class="jackpot">JACKPOT</div> -->
      <div class="jackpot-amount">{{ getShortChipFormat($data.prize) }}</div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/event-bus";
import { mapGetters } from "vuex";

import JackpotNotificationAnimation from "@/components/Misc/Animations/JackpotNotificationAnimation";
import Avatar from "@/components/Misc/Avatar";

const NOTIFICATION_ELAPSE_SECONDS = 7;

export default {
  name: "JackPotNotification",
  components: {
    JackpotNotificationAnimation,
    Avatar,
  },
  computed: {
    ...mapGetters(["getShortChipFormat"]),
  },
  watch: {
    "winner.name": {
      handler(val) {},
      immediate: true,
    },
  },
  data() {
    return {
      animate: false,
      prize: 0,
      blind: "",
      winner: {
        id: -1,
        name: "",
      },
      isProgressiveJackpot: false,
      resetTimeout: 0,
      dummyData: {
        id: 167,
        player: {
          id: 5366395,
          name: "Guest_30206",
        },
        hand: {
          name: "Pair",
          description: "Pair, 8's",
          rank: 2,
          cards: ["8d", "8s", "Ah", "Qh", "Th"],
        },
        bet: "500",
        amount: 1781250000,
      },
    };
  },
  methods: {
    onJackpotWinnerAnnounced(data) {
      // if (this.$data.resetTimeout) clearTimeout(this.$data.resetTimeout);

      this.$plugins.audio.play("JackpotWon");

      console.log("Jackpot data:", data);

      this.$data.animate = true;
      this.$data.isProgressiveJackpot = false;
      this.$data.winner.id = data.player.id;
      this.$data.winner.name = data.player.name;
      this.$data.prize = Math.floor(Number(data.amount));
      this.$data.blind = `${this.getShortChipFormat(
        Number(data.bet)
      )}/${this.getShortChipFormat(Number(data.bet) * 2)}`;

      setTimeout(() => {
        this.reset();
      }, 1000 * NOTIFICATION_ELAPSE_SECONDS);
    },
    reset() {
      this.$data.animate = false;
      this.$data.winner.id = -1;
      this.$data.winner.name = "";
      this.$data.isProgressiveJackpot = false;
      this.$data.prize = 0;
      this.$data.blind = "";
    },
  },
  beforeDestroy() {
    EventBus.$off(
      "onJackpotExplosion",
      this.onJackpotWinnerAnnounced.bind(this)
    );
  },
  created() {
    EventBus.$on(
      "onJackpotExplosion",
      this.onJackpotWinnerAnnounced.bind(this)
    );
  },
};
</script>

<style lang="scss" scoped>
.jackpot-notification-container {
  position: relative;
  height: 330px;
  z-index: 12;

  .notification-inner {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    .player-window-avatar {
      position: absolute;
      top: 130px;
      left: 20px;
      width: 100px;
      height: 100px;
    }

    .texts {
      position: absolute;
      top: 90px;
      left: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 240px;
      min-width: 240px;
      max-height: 90px;
      min-height: 90px;
      word-break: break-word;
      display: flex;
      align-items: center;
      justify-content: center;
      .username {
        font-size: 40px;
      }

      .jackpot-name {
        font-size: 40px;
        background: -webkit-linear-gradient(#ffcc66, #ffffcc);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 5px;
      }
    }

    .jackpot {
      position: absolute;
      left: 88px;
      bottom: 102px;
      font-size: 75px;
      background: -webkit-linear-gradient(#ffcc66, #ffffcc);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;
      font-weight: bold;
    }

    .jackpot-amount {
      position: absolute;
      left: 150px;
      bottom: 92px;
      color: #f6e379;
      font-weight: bold;
      font-size: 50px;
      text-align: center;
      width: 230px;
    }
  }
}
</style>
