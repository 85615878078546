<template>
  <div class="seat-gift" :class="getClasses" @click="giftWindowOpen">
    <div class="seat-gift">
      <template v-for="(item, itemIndex) in getGifts">
        <div
          class="seat-gift-item"
          v-if="
            (item.from === data.id && !item.completed) ||
            (item.to === data.id && item.completed)
          "
          :key="`gift${itemIndex}-to${item.to}`"
        >
          <GlobalGift
            :sendPlayerId="item.from"
            :giftId="item.id"
            :from="item.from"
            :to="item.to"
            :index="index"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "SeatGift",
  components: {
    GlobalGift: () => import("@/components/Misc/Animations/GlobalGift"),
  },
  props: {
    index: {
      type: [Number, String],
      default: 0,
      required: true,
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      nGetSeats: "service/nGetSeats",
      getGifts: "gift/getGifts",
      nAmISitting: "service/nAmISitting",
      nGetMySeat: "service/nGetMySeat",
    }),
    getClasses() {
      return [
        `seat-gift-r-${this.$props.index}`,
        `seat-gift-${this.getComputedIndex}`,
        `gift-${this.data.id}`,
      ];
    },
    getComputedIndex() {
      if (!this.nAmISitting) return this.$props.index;

      let shiftValue = 7 - this.nGetMySeat.position;
      if (shiftValue < 0) shiftValue = 8;

      return (this.$props.index + shiftValue) % 9;
    },
    data() {
      return this.nGetSeats[this.$props.index];
    },
  },
  methods: {
    giftWindowOpen() {
      this.$store.state.gift.sendGiftPlayerId = this.data.id;
      this.$store.commit("modals/show", "sendGiftWindow");
      this.$parent.$parent.$parent.$parent.showChatPopup = false;
    },
    toGiftIsSeats(toId) {
      const findGiftDom = document.querySelector(`.gift-${toId}`);
      if (findGiftDom) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.seat-gift {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  .seat-gift-item {
    position: absolute;
  }
}

.seat-gift-0 {
  left: -190px;
  top: 420px;
}
.seat-gift-1 {
  left: -145px;
  top: 110px;
}
.seat-gift-2 {
  left: 242px;
  top: -35px;
}
.seat-gift-3 {
  left: 1058px;
  top: -51px;
}
.seat-gift-4 {
  left: 1453px;
  top: 98px;
}
.seat-gift-5 {
  left: 1500px;
  top: 410px;
}
.seat-gift-6 {
  top: 540px;
  left: 1193px;
}
.seat-gift-7 {
  top: 700px;
  left: 480px;
}
.seat-gift-8 {
  top: 545px;
  left: 112px;
}
</style>
