<template>
  <SafeArea>
    <div class="lobby-scene" :class="getClasses">
      <div class="lobby-scene-inner">
        <div class="lobby-ad-anchor-over" @click="onLobbyAdAnchorClicked"></div>
        <div class="lobby-row lobby-top-row">
          <PlayerDetails />
          <div class="chip-amount-container">
            <div class="lobby-sprite lobi-chip-shopping" @click="shopOpen">
              <div class="shine" v-if="$store.state.animations.shopChipShine">
                <div class="shine-item"></div>
              </div>
            </div>
            <div class="chip-text-bg">
              <span class="chip-text">
                <AnimatedNumber
                  :number="parseInt(user.chip)"
                  :format="true"
                  :duration="2"
                />
              </span>
            </div>
          </div>
          <QuickMenu ref="quickMenu" />
        </div>
        <div class="lobby-row lobby-middle-row">
          <div class="ad-container">
            <div class="ad-image">
              <span class="title"> </span>
              <div class="ad-character"></div>
              <div class="texts">
                <p class="texts-info">
                  <span class="small-text">
                    {{ $t("lobby.newSlotGame").toUpperCase() }}
                  </span>
                  <br />
                  <span class="big-text">{{
                    $t("lobby.availableNow").toUpperCase()
                  }}</span>
                </p>
              </div>
            </div>

            <div class="ad-image-shadow" v-if="showSlotSelection"></div>
            <div
              class="ad-image-back"
              v-if="showSlotSelection"
              @click="slotBackButtonClick"
            >
              <!-- <div class="lobby-sprite back-bar"></div> -->
              <!-- <div class="lobby-sprite back-arrow"></div> -->
              <div class="lobby-sprite back-elips">
                <span class="icon"></span>
              </div>
              <!-- <div class="back-text"></div> -->
            </div>
          </div>
          <div class="shadow"></div>
          <GameButtons ref="gameButtons" />
        </div>
        <div class="lobby-row lobby-bottom-row">
          <div class="lobby-bottom-grid">
            <div class="lobby-bottom-navigation-bar">
              <NavigationBar />
            </div>
            <div class="middle-part">
              <div class="speacial-for-you-button text" @click="showShopWindow">
                <div class="gift-image"></div>
                <div class="text" v-html="$t('lobby.specialForYou')"></div>
              </div>
              <img
                class="logo"
                src="@/assets/images/lobby/logo.png"
                alt=""
                srcset=""
              />
            </div>

            <div class="lobby-bottom-favourite-game-area">
              <div class="menu-container">
                <div class="menu-container-area" @click="showChatWindow">
                  <button type="button" class="menu-button no-click-outside">
                    <span class="lobby-sprite chat-icon"></span>
                    <span class="green-icon" v-if="isAnyUnread"></span>
                  </button>
                </div>
                <div class="menu-container-area" @click="friendsWindowOpen">
                  <button type="button" class="menu-button no-click-outside">
                    <span class="lobby-sprite friends-icon"></span>
                    <span class="green-icon" v-if="unRead"></span>
                  </button>
                </div>
                <div class="menu-container-area" @click="showSocialWindow">
                  <button type="button" class="menu-button no-click-outside">
                    <span class="social-icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SlotSelection v-show="showSlotSelection" ref="slotSelection" />
    </div>
  </SafeArea>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SafeArea from "@/components/Screen/SafeArea";
import QuickMenu from "@/components/Misc/Lobby/QuickMenu";
import PlayerDetails from "@/components/Misc/Lobby/PlayerDetails";
import GameButtons from "@/components/Misc/Lobby/GameButtons";
import NavigationBar from "@/components/Misc/Lobby/NavigationBar";
import SlotSelection from "@/components/Misc/Lobby/SlotSelection";
import SelectPotWindow from "@/components/Misc/PokerTable/SelectPotWindow";
import { searchElementsSetProperty } from "@/helpers";

export default {
  name: "Lobby",
  components: {
    SlotSelection,
    SafeArea,
    PlayerDetails,
    QuickMenu,
    GameButtons,
    NavigationBar,
    SelectPotWindow,
    AnimatedNumber: () => import("@/components/Utils/AnimatedNumber"),
    ActionPanel: () => import("@/components/Misc/PokerTable/ActionPanel"),
  },
  watch: {
    "$store.state.animations.bonusChip": {
      handler(val) {
        if (val) {
          this.shopChipIconBounceAnimationStart();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState(["user", "slots", "config"]),
    ...mapGetters("chat", ["isAnyUnread"]),
    ...mapGetters(["getRealPlatform", "getChipFormat"]),
    ...mapGetters({
      getPreferenceValue: "preferences/getValue",
    }),
    getClasses() {
      return [this.user.id < 1 ? "initializing" : ""];
    },
    unRead() {
      return this.$store.state.friends.requestsList.list.length > 0;
    },
  },
  data() {
    return {
      showSlotSelection: false,
      shoppingChipAnimation: null,
    };
  },
  methods: {
    slotButtonClick() {
      if (this.showSlotSelection) {
        return;
      }
      if (this.config["olympus_status"] == 1) {
        this.$refs.slotSelection.downloadOrPlay("olympus");
      } else {
        this.$plugins.toast.show({
          text: this.$t("errors.errorOccured"),
          duration: 2000,
          variant: "success",
        });
      }
    },
    shopOpen() {
      this.$store.commit("modals/show", "shop");
    },
    slotBackButtonClick() {
      this.$animejs({
        targets: ".slot-button",
        rotateY: {
          value: 90,
          duration: 250,
        },
        complete: this.slotSelectionHide,
        endDelay: 200,
        easing: "easeInQuad",
      });
    },
    slotSelectionHide() {
      this.$plugins?.audio?.play("button_close_new");
      this.showSlotSelection = false;
    },
    friendsWindowOpen() {
      this.$store.commit("modals/show", "friendsWindow");
      this.$plugins.audio.play("Button");
      this.$adjust.trackEvent("ClickGoToFriends");
    },
    async collect() {
      this.$refs.quickMenu.$refs.adBonus.$data.claiming = true;
      await this.$store.dispatch("bonus/collectAdBonus");
    },
    onLobbyAdAnchorClicked() {
      if (
        this.$refs.backgroundObjects &&
        this.$refs.backgroundObjects.$refs.lobbyAds &&
        this.$refs.backgroundObjects.$refs.lobbyAds.hasOwnProperty("onClicked")
      ) {
        this.$refs.backgroundObjects.$refs.lobbyAds.onClicked();
      }
    },
    showChatWindow() {
      this.$store.commit("modals/show", "chat");
      this.$plugins.audio.play("Button");
      this.$adjust.trackEvent("ClickGoToChat");
    },
    showSocialWindow() {
      this.$store.commit("modals/show", "social");
      this.$plugins.audio.play("Button");
      this.$adjust.trackEvent("ClickGoToSocial");
    },
    showShopWindow() {
      this.$store.commit("modals/show", "shop");
      this.$plugins.audio.play("Button");
      this.$adjust.trackEvent("ClickGoToSpecialForYou");
    },
    shopChipIconBounceAnimationStop() {
      searchElementsSetProperty(".lobi-chip-shopping", [
        { property: "transform", value: "scale(1)" },
      ]);
    },
    shopChipIconBounceAnimationStart() {
      this.$animejs({
        targets: ".lobi-chip-shopping",
        scale: [
          { value: 1, duration: 1100 },
          { value: 1.2, duration: 100 },
          { value: 1, duration: 100 },
          { value: 1.2, duration: 100 },
          { value: 1, duration: 100 },
          { value: 1.2, duration: 100 },
          { value: 1, duration: 100 },
          { value: 1.2, duration: 100 },
          { value: 1, duration: 100 },
          { value: 1.2, duration: 100 },
          { value: 1, duration: 100 },
        ],
        delay: 0,
        easing: "linear",
      });
    },
  },
  beforeDestroy() {
    // this.$plugins?.audio?.stop("Lobby1");
  },
  deactivated() {
    // this.$plugins?.audio?.stop("Lobby1");
  },
  activated() {
    // if (this.getRealPlatform === "android") {
    //   this.$plugins?.audio?.loop("Lobby1");
    //   this.$plugins?.audio?.play("Lobby1");
    // } else {
    //   this.$plugins?.audio?.loop("Lobby1");
    // }

    this.$store.dispatch("service/requestUserDataFromLobby");
  },

  async mounted() {
    if (this.getRealPlatform) {
      this.$service.put(`/user/last-device`, {
        lastLoginDevice: this.getRealPlatform,
      });

      this.$store.dispatch("user/updateGameUserInfo", {
        game: this.$constant.gameType.LOBBY,
        room: -1,
      });
    }

    this.$adjust.trackEvent("LobbyScreenOn");
  },
  created() {
    this.$store.dispatch("service/requestUserDataFromLobby");
    this.$store.dispatch("jackpots/fetchTopJackpot", {
      limit: 5,
    });
  },
};
</script>

<style lang="scss" scoped>
.lobby-scene {
  position: relative;
  width: 100%;
  height: 100%;

  &.initializing {
    display: none;
  }

  &-inner {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 3;

    .lobby-ad-anchor-over {
      position: absolute;
      left: 160px;
      bottom: 150px;
      width: 800px;
      height: 300px;
      border-top-right-radius: 100px;
    }

    .lobby-row {
      width: 100%;
    }
    .lobby-top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      height: 175px;
      .chip-amount-container {
        will-change: transform;
        width: 17%;
        height: 93%;
        display: flex;
        justify-content: left;
        align-items: center;
        position: relative;
        .chip-text-bg {
          border-radius: 60px;
          z-index: -1;
          margin-left: 130px;
          .chip-text {
            width: 100%;
            height: 100%;
            display: flex;
            float: left;
            justify-content: center;
            width: 100%;
            text-align: center;
            font-size: 40px;

            color: white;
            text-transform: capitalize;
            text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.2);
            font-size: bold;
          }
        }
        .lobi-chip-shopping {
          position: absolute;
          cursor: pointer;
          overflow: hidden;
          &:active,
          &:hover {
            transform: scale(1.1);
          }
          .shine {
            overflow: hidden;
            position: relative;
            left: 8px;
            top: 5px;
            width: 102px;
            height: 102px;
            border-radius: 100%;
            .shine-item {
              width: 100%;
              height: 100%;
              border-radius: 100%;
            }
          }
          .shine-item:after {
            animation: shineEffect 3s ease-in-out infinite;
            animation-fill-mode: forwards;
            content: "";
            position: absolute;
            top: -110%;
            left: -210%;
            width: 100%;
            height: 200%;
            opacity: 0;
            transform: rotate(45deg);
            border-radius: 100%;

            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.15) 20%,
              rgba(255, 255, 255, 0.25) 50%,
              rgba(255, 255, 255, 0.05) 80%,
              rgba(255, 255, 255, 0) 100%
            );
          }
          .shine-item:active:after {
            opacity: 0;
          }
        }
      }
    }
    .lobby-middle-row {
      position: relative;
      width: 100%;
      height: 100%;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      top: 35px;
      .ad-container {
        width: 385px;
        height: 86%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        position: relative;
        overflow: hidden;
        border-radius: 23px;
        cursor: pointer;
        .ad-image {
          width: 100%;
          height: 100%;
          background-image: url("~@/assets/images/lobby/turn-of-olympus2.png");
          background-repeat: no-repeat;
          background-position: center;
          .title {
            position: absolute;
            top: 10px;
            left: 72px;
            height: 150px;
            width: 235px;
            background: url("~@/assets/images/lobby/turn-of-olympus.png");
            background-repeat: no-repeat;
            background-size: contain;
            z-index: 10;
          }
          .ad-character {
            position: absolute;
            top: 70px;
            left: -2px;
            height: 582px;
            width: 397px;
            background: url("~@/assets/images/lobby/zeus-min.png") no-repeat;
            background-size: contain;
            z-index: 2;
          }
          z-index: 1;
          .texts {
            position: absolute;
            bottom: 5%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 36px;
            font-weight: bold;
            text-align: center;
            text-shadow: 3px 5px 3px rgba(black, 0.7);
            z-index: 9;
            background: rgb(255, 255, 255);
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0%) 0%,
              rgba(100, 9, 121, 0.7777485994397759) 45%,
              rgba(255, 255, 255, 0%) 100%
            );
            // &.text-ms {
            //   bottom: 4%;
            //   left: 12%;
            // }
            // &.text-id {
            //   bottom: 4%;
            //   left: 15%;
            // }
            // &.text-pt {
            //   bottom: 3%;
            //   left: 6%;
            // }
            // &.text-it {
            //    bottom: 4%;
            //   left: 15%;
            // }
            // &.text-tr {
            //   bottom: 4%;
            //   left: 22%;
            // }
            // &.text-ru {
            //   bottom: 4%;
            //   left: 14%;
            // }
            .bottom-text {
              font-size: 50px;
            }
            &-info {
              color: #ffffff;
              line-height: 45px;
              .small-text {
                font-size: 33px;
                font-weight: normal;
              }
              .big-text {
                font-size: 37px;
                font-weight: bold;
              }
            }
          }
        }
        .ad-image-shadow {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          border-radius: 23px;
          z-index: 2;
          top: 0;
        }
        .ad-image-back {
          position: absolute;
          width: 82%;
          height: 91%;
          bottom: 45px;
          border-radius: 25px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          z-index: 10;
          cursor: pointer;
          div {
            position: absolute;
          }
          .back-arrow {
            top: 420px;
            left: 60px;
          }
          .back-bar {
            top: 406px;
            left: 5px;
          }
          .home-icon {
            top: 200px;
          }
          .back-elips {
            display: flex;
            align-items: center;
            justify-content: center;
            top: 190px;
            font-size: 100px;
            color: #fff;
            .icon {
              background: url("~@/assets/images/lobby/left-arrow.png");
              height: 85px;
              width: 85px;
              background-size: cover;
            }
          }
          .back-text {
            position: absolute;
            top: 415px;
            left: 105px;
            font-size: 40px;
            min-width: 200px;
            text-align: center;
          }
        }
      }
      .shadow {
        position: absolute;
        bottom: 12px;
        left: 13px;
        height: 62px;
        width: 393px;
        background: url("../../assets/images/lobby/shadow.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    .lobby-bottom-row {
      padding-bottom: 15px;
      .lobby-bottom-grid {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .lobby-bottom-navigation-bar {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: -7px;
          width: 22%;
          height: 200px;
        }
        .middle-part {
          width: 33%;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .logo {
            margin-top: 20px;
          }
          .speacial-for-you-button {
            height: 142px;
            width: 540px;
            background: url("~@/assets/images/lobby/for-you-button.png");
            position: relative;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            .text {
              text-align: center;
              color: #ffcc66;
              font-weight: bold;
              width: 100%;
              height: 120px;
              font-size: 40px;
              line-height: 55px;
              font-style: italic;
              text-shadow: -8px 4px 2px rgba(0, 0, 0, 0.6);
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
            .gift-image {
              background: url("~@/assets/images/lobby/watch-win-collect.png");
              background-repeat: no-repeat;
              background-size: cover;
              min-height: 162px;
              min-width: 132px;
            }
            &:active {
              transform: scale(1.1);
              transition: 0.1s linear;
            }
          }
        }
        .lobby-bottom-favourite-game-area {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: -30px;
          width: 23%;
          height: 200px;
          .menu-container {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            height: 100%;
            margin-top: 10px;
            margin-right: 20px;
            &-area {
              cursor: pointer;
              width: 100px;
              height: 100px;
              margin-right: 50px;
              .menu-button {
                width: 100px;
                height: 100px;
                position: relative;

                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid #cf707f;
                border-radius: 100%;
                background: rgba(black, 0.5);
                margin-right: -50px;
                &:last-child {
                  margin-right: -25px;
                }
                &:active {
                  filter: brightness(50%);
                }
                .green-icon {
                  position: absolute;
                  top: 20px;
                  right: 7px;
                  width: 18px;
                  height: 18px;
                  border-radius: 100%;
                  background: #16fa03;
                  border: 3px solid #0dae01;
                }
                .social-icon {
                  height: 73px;
                  width: 79px;
                  background: url("../../assets/images/lobby/ticket.png");
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }
              .green-icon {
                position: absolute;
                top: -10px;
                right: -15px;
                width: 22px;
                height: 22px;
                border-radius: 100%;
                background: #16fa03;
                border: 3px solid #0dae01;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes shineEffect {
  30% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 1s, 1s, 0.2s;
    transition-timing-function: ease;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
