import axios from "@/services/api/axios";
import audio from "@/plugins/audio";
import toast from "@/plugins/toast";
import i18n from "@/plugins/i18n";

export default {
  async sendGlobalMessage(store, message) {
    try {
      const response = await axios.post(`/chat/global`, {
        message: message,
      });
      audio.instance.play("MessageSent");
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      if (error.response.status === 429) {
        toast.instance.show({
          text: i18n.t("errors.tooManyRequests"),
          duration: 3000,
          variant: "danger",
        });
      }
      if (error.response.status === 423) {
        toast.instance.show({
          text: i18n.t("errors.youAreBlocked"),
          duration: 3000,
          variant: "danger",
        });
      }
      return false;
    }
  },
  async sendDirectMessage(store, { receiverId, message }) {
    try {
      const response = await axios.post(`/chat/user`, {
        message: message,
        to: receiverId,
      });

      audio.instance.play("MessageSent");
      return true;
    } catch (error) {
      if (error.response.status === 429) {
        toast.instance.show({
          text: i18n.t("errors.tooManyRequests"),
          duration: 3000,
          variant: "danger",
        });
      }
      if (error.response.status === 423) {
        toast.instance.show({
          text: i18n.t("errors.youAreBlocked"),
          duration: 3000,
          variant: "danger",
        });
      }
      return false;
    }
  },
  async sendRoomMessage(store, { game, room, message }) {
    try {
      const response = await axios.post(`/chat/room`, {
        message: message,
        room: room,
        game: game,
      });
      if (response && response.status === 204) {
        audio.instance.play("MessageSent");
        return response.data;
      } else {
        toast.instance.show({
          text: i18n.t("inboxContact.failedMessage"),
          duration: 3000,
          variant: "danger",
        });
      }
    } catch (error) {
      if (error?.response?.status === 423) {
        toast.instance.show({
          text: i18n.t("errors.youAreBlocked"),
          duration: 3000,
          variant: "danger",
        });
      } else if (error?.response?.status === 400) {
        toast.instance.show({
          text: i18n.t("errors.tooManyRequests"),
          duration: 3000,
          variant: "danger",
        });
      }
      return false;
    }
  },
  async getUserConversation({ commit }, { conversationId, offSet }) {
    try {
      const response = await axios.get(
        `/chat/conversations/${conversationId}?offset=${offSet}`
      );
      if (response.data) {
        commit("setSelectedConversation", {
          data: response.data,
          offSet: offSet,
        });
      }
    } catch (error) {
      return false;
    }
  },
  async getUserConversations({ commit }) {
    try {
      const response = await axios.get(`/chat/conversations`);
      if (response.data) {
        commit("setUserConversations", response.data);
      }
    } catch (error) {
      return false;
    }
  },

  async sendContactUsMessage(store, body) {
    try {
      const response = await axios.post(`/contact-us/send`, body);

      return true;
    } catch (error) {
      return false;
    }
  },

  async toggleBlockUser({ rootState }, { userId }) {
    const index = rootState.user.blockedUsers.findIndex((b) => b === userId);
    const endpoint = index >= 0 ? "unblock-user" : "block-user";

    try {
      const response = await axios.post(`/chat/${endpoint}`, { userId });

      const blockedUsers = response.data;

      if (!Array.isArray(blockedUsers)) return false;

      rootState.user.blockedUsers.splice(0, rootState.user.blockedUsers.length);
      blockedUsers.forEach((bu) => rootState.user.blockedUsers.push(bu));

      if (endpoint === "unblock-user")
        toast.instance.show({
          text: i18n.t("errors.youAreUnblockedTheUser"),
        });
      else
        toast.instance.show({
          text: i18n.t("errors.youAreBlockedTheUser"),
        });

      return blockedUsers;
    } catch {
      console.log("error");
    }
  },

  async deleteConversation(store, { conversationId }) {
    try {
      const response = await axios.post(`/chat/delete-conversation`, {
        conversationId,
      });

      return response?.data?.success;
    } catch {
      console.log("error");
    }
  },
};
