<template>
  <div class="card-box">
    <div class="card-box-inner">
      <div class="cards">
        <div
          v-for="card in getDeck"
          :key="'box-deck-card2-' + card"
          class="card"
          :class="[
            getActiveRoomState.cardColor,
            getCutterCardPosition === card ? 'cutter' : ''
          ]"
        ></div>
      </div>
      <div class="card-box-front"></div>
    </div>

    <Card v-if="cutterDrawn" :cutter="true" class="cutter departed-cutter" :index="-2" suit="" value="" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Card from './Card';

import EventBus from '@/event-bus';

export default {
  name: "CardBox",
  components: { Card },
  computed: {
    ...mapGetters({
      getActiveRoomState: 'service/getActiveRoomState'
    }),
    getCutterCardPosition() {
      return this.getActiveRoomState.deck.findIndex(c => c.cutter);
    },
    getDeck() {
      const maxCount = 52 * 8 + 1;
      let drawnCardsCount = (52 * 8 + 1) - [...this.getActiveRoomState.deck].length;
      if (drawnCardsCount < 0)
        drawnCardsCount = 0;

      const count = Math.max(maxCount - drawnCardsCount, 0);

      const arr = [];
      for (let i = 0; i < count; i++)
        arr.push(i);

      return arr;
    }
  },
  data() {
    return {
      cutterDrawn: false
    }
  },
  methods: {
    getCardStyles(index = 0) {
      const styles = {};

      const translateY = index * 0.06;

      styles.transform = `rotateY(72deg) rotateZ(35deg)`;

      if (this.getCutterCardPosition === index)
        styles.transform += ` translateY(2px)`;

      styles.marginTop = `${translateY}px`;
      return styles;
    },
    onShuffleRequested() {
      this.blueCards = !this.blueCards;
      this.cutterDrawn = false;
    },
    onCutterDrawn() {
      this.cutterDrawn = true;
    }
  },
  beforeDestroy() {
    EventBus.$off('onShuffleRequested', this.onShuffleRequested.bind(this));
    EventBus.$off('onCutterDrawn', this.onCutterDrawn.bind(this));
  },
  created() {
    EventBus.$on('onShuffleRequested', this.onShuffleRequested.bind(this));
    EventBus.$on('onCutterDrawn', this.onCutterDrawn.bind(this));
  }
}
</script>

<style scoped lang="scss">
.card-box{
  position:absolute;
  top:-50px;
  right:-30px;
  width:242px;
  height:92px;
  background-image:url("~@/assets/images/blackjack/boxes/card-box2-back.png");
  background-repeat:no-repeat;
  .card-box-inner{
    position:relative;
    width:100%;
    height:100%;
    .cards{
      position:absolute;
      right:-140px;
      bottom:68px;
      width:72%;
      height:39px;
      display:flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      will-change: auto;
      transform:rotateZ(-11deg);
      .card{
        flex-shrink: 0;
        width:36px;
        height:53px;
        background-image:url("~@/assets/images/blackjack/boxes/card2.png");
        background-repeat:no-repeat;
        margin-left:-36.31px;
        &.blue{
          filter:hue-rotate(220deg);
        }
      }
    }
    .card-box-front{
      position:absolute;
      right:32px;
      bottom:3px;
      width:176px;
      height:69px;
      background-image:url("~@/assets/images/blackjack/boxes/card-box2-front.png");
      background-repeat:no-repeat;
      opacity:1;
      transform:translateY(0);
      transition: opacity 0.3s linear, transform 0.25s ease;
      &.hide{
        opacity:0;
        transform:translateY(50px);
      }
    }
  }
  .departed-cutter{
    position:absolute;
    top:100px;
    left:-730px;
    transform:scale(0.8) rotateX(45deg) rotateY(-10deg) rotateZ(12deg);
  }
}

.platform-mobile{
  .card-box{
    top:30px;
  }
}
</style>
