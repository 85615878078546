<template>
  <div class="blackjack-table" :style="getTableStyles">
    <div class="blackjack-table-inner">
      <div class="croupier-area">
        <div class="croupier-object">
          <div class="croupier-person"></div>
        </div>
      </div>
      <div class="table-object" :class="getTableObjectClasses">
        <div class="table-object-inner">
          <div class="deck-shuffle-info-text" v-if="cardShuffling">
            {{ $t("blackJack.ShufflingCards") }}
          </div>
          <div class="turn-games-logo" v-if="platform !== 'mobile'">
            <div class="turn-games-logo-inner"></div>
          </div>
          <div class="table-text"></div>
          <DrawnCardsBox v-if="platform !== 'mobile'" />
          <CardBox v-if="platform !== 'mobile'" />
          <PlayersActionsIndicator v-if="platform !== 'mobile'" />
          <WinnerList v-if="platform !== 'mobile'" />
          <WinAmountIndicator />
          <DrawIndicator />
          <LoseIndicator />
          <ActionPanel v-if="nGetMySeat.in" />
          <DealerSide />
          <SeatSide />
          <DistributeNowButton />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import EventBus from "@/event-bus";

import ActionPanel from "./ActionPanel";
import DealerSide from "./DealerSide";
import SeatSide from "./SeatSide";
import WinAmountIndicator from "./WinAmountIndicator";
import DrawIndicator from "./DrawIndicator";
import LoseIndicator from "./LoseIndicator";
import PlayersActionsIndicator from "./PlayersActionsIndicator";
import WinnerList from "./WinnerList";
import CardBox from "./CardBox";
import DrawnCardsBox from "./DrawnCardsBox";
import DistributeNowButton from "./DistributeNowButton";

export default {
  name: "Table",
  components: {
    PlayersActionsIndicator,
    WinAmountIndicator,
    DrawIndicator,
    LoseIndicator,
    WinnerList,
    ActionPanel,
    DealerSide,
    SeatSide,
    CardBox,
    DrawnCardsBox,
    DistributeNowButton,
  },
  computed: {
    ...mapState(["platform"]),
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
    }),
    getTableStyles() {
      const styles = {};
      if (this.platform === "mobile") {
        styles.transform = `translate(-50%, -50%) scale(1.6)`;
        styles["transform-origin"] = "center center";
      }
      return styles;
    },
    getTableObjectClasses() {
      const classes = [];

      const selectedTableColor =
        this.$store.getters["preferences/getValue"]("table_color");
      if (typeof selectedTableColor !== "undefined")
        classes.push(selectedTableColor);
      return classes;
    },
  },
  data() {
    return {
      window: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      },
      cardShuffling: false,
    };
  },
  methods: {
    onWindowResize(event) {
      const window = event.target;
      this.$data.window.innerWidth = window.innerWidth;
      this.$data.window.innerHeight = window.innerHeight;
    },
    onShuffleRequested() {
      this.cardShuffling = true;
      setTimeout(() => {
        this.cardShuffling = false;
      }, 3800);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize.bind(this));
    EventBus.$off("onShuffleRequested", this.onShuffleRequested.bind(this));
  },
  created() {
    window.addEventListener("resize", this.onWindowResize.bind(this));
    EventBus.$on("onShuffleRequested", this.onShuffleRequested.bind(this));
  },
};
</script>

<style scoped lang="scss">
.blackjack-table {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .blackjack-table-inner {
    position: relative;
    width: 100%;
    height: 100%;
    .croupier-area {
      position: absolute;
      left: 0;
      top: -175px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .croupier-object {
        position: relative;
        z-index: 6;
        background-image: url("~@/assets/images/blackjack/chair.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        width: 214px;
        height: 183px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        .croupier-person {
          flex-shrink: 0;
          margin-top: -95px;
          width: 290px;
          height: 396px;
          background-image: url("~@/assets/images/blackjack/croupier.png");
          background-repeat: no-repeat;
          background-size: 100%;
          transition: all 0.25s ease;
          &.busted {
            filter: grayscale(100%);
          }
        }
      }
    }
    .table-object {
      position: relative;
      width: 85%;
      margin-left: 7.5%;
      height: 810px;
      background-image: url("~@/assets/images/blackjack/tables/table-green.png");
      background-repeat: no-repeat;
      background-position: top;
      background-size: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      &.green {
        background-image: url("~@/assets/images/blackjack/tables/table-green.png");
      }
      &.red {
        background-image: url("~@/assets/images/blackjack/tables/table-red.png");
      }
      &.blue {
        background-image: url("~@/assets/images/blackjack/tables/table-blue.png");
      }
      &.purple {
        background-image: url("~@/assets/images/blackjack/tables/table-purple.png");
      }
      .table-object-inner {
        position: relative;
        margin-top: 40px;
        width: 80%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        .turn-games-logo {
          position: absolute;
          top: 60px;
          left: 0;
          min-height: 80px;
          width: 100%;
          transform-style: preserve-3d;
          perspective: 400px;
          display: flex;
          align-items: center;
          justify-content: center;
          &-inner {
            width: 300px;
            height: 80px;
            background-image: url("~@/assets/images/blackjack/loading/logo.png");
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            transform: rotateX(40deg);
            filter: grayscale(100%) brightness(300%);
            opacity: 0.2;
          }
        }
        .table-text {
          position: absolute;
          top: 150px;
          left: 0;
          height: 99px;
          width: 100%;
          background-image: url("~@/assets/images/blackjack/table-text.png");
          background-position: center;
          background-size: 508px auto;
          background-repeat: no-repeat;
        }
        .deck-shuffle-info-text {
          background-image: linear-gradient(
            90deg,
            transparent 0%,
            black 30%,
            black 70%,
            transparent 100%
          );
          height: 40px;
          line-height: 40px;
          width: 100%;
          margin-top: 40px;
          text-align: center;
          font-weight: bold;
          font-size: 28px;
          color: rgb(255, 255, 255);
          text-transform: uppercase;
          text-shadow: 0 1px 0 black;
          overflow: hidden;
          transform: translate3d(0, 0, 0);
          animation-name: infoTextIncomingAnimation;
          animation-fill-mode: forwards;
          animation-duration: 0.5s;
          animation-timing-function: linear;
          animation-iteration-count: 1;
        }
      }
    }
  }
}

.platform-mobile {
  .blackjack-table {
    width: 1416px;
    height: 864px;
    margin: 0;
    top: 60%;
    left: 50%;
    bottom: unset;
    transform: translateZ(0.1px) translate(-50%, -50%);
    transform-origin: center center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0.1px);
    z-index: 1;
    will-change: auto;
    .blackjack-table-inner {
      .croupier-area {
        display: none;
      }
      .table-object {
        width: 100%;
        height: 100%;
        margin-left: 0;
        &-inner {
          height: 75%;
          .turn-games-logo {
            top: 72px;
          }
          .table-text {
            height: 130px;
            background-size: auto 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transform: translateZ(0.1px);
            z-index: 1;
            will-change: auto;
          }
        }
      }
    }
  }
}
</style>
