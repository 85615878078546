<template>
  <div class="sit-window-outer" v-show="show">
    <div class="sit-window-inner" v-click-outside="onClickedOutside">
      <button type="button" class="close" @click="close">
        <svg
          width="48"
          height="48"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>
      <div class="header">{{ $t("blackJack.header") }}</div>
      <div class="body">
        <div class="total-user-chip">
          <div class="lobby-sprite chip"></div>
          {{ getChipFormat(user.chip) }}
        </div>
        <template v-if="player.availableChip >= getMinAmount">
          <div class="current-amount">{{ getChipFormat(currentAmount) }}</div>
          <div class="slider">
            <div class="slider-inner">
              <button
                type="button"
                class="slider-button slider-minus"
                @click="onSliderButtonClicked(0)"
              ></button>
              <VueSlider
                width="100%"
                height="64px"
                :silent="true"
                :disabled="getMinAmount === getMaxAmount"
                :min="getMinAmount"
                :max="getMaxAmount"
                :included="true"
                :contained="true"
                :zoom="getTotalScaleAmount"
                :dotOptions="{
                  style: {
                    width: '64px',
                    height: '64px',
                    marginTop: '-24px',
                    marginLeft: '-24px',
                    backgroundColor: 'white',
                    boxShadow: '0 0 0 0 transparent',
                    border: '0px',
                  },
                  focusStyle: {
                    boxShadow: '0 0 16px 0 #1f2025',
                    border: '1px solid #1f2025',
                  },
                  tooltipStyle: {
                    color: 'white',
                    backgroundColor: 'black',
                    borderColor: 'black',
                    fontSize: '12px',
                    padding: '3px 12px',
                  },
                }"
                :processStyle="{
                  background: 'linear-gradient(180deg, #FFDB76, #f8bc07)',
                }"
                :railStyle="{
                  borderRadius: '20px',
                  background: 'rgba(0,0,0,0.3)',
                  border: '1px solid rgba(255,255,255,0.15)',
                }"
                v-model="currentAmount"
                :adsorb="true"
                :drag-on-click="true"
                tooltip="none"
                :tooltipFormatter="() => getShortChipFormat(currentAmount)"
                @dragging="onSliderDragging"
                @drag-end="onSliderDragEnd"
              />
              <button
                type="button"
                class="slider-button slider-plus"
                @click="onSliderButtonClicked(1)"
              ></button>
            </div>
            <div class="slider-info">
              <div class="min-buy">{{ getChipFormat(getMinAmount) }}</div>
              <div class="max-buy">{{ getChipFormat(getMaxAmount) }}</div>
            </div>
          </div>

          <div class="options">
            <div
              class="option auto-purchase"
              :class="[autoPurchase ? 'active' : '']"
              @click="autoPurchase = !autoPurchase"
            >
              <div class="checkmark">
                <div class="inner" v-show="autoPurchase"></div>
              </div>
              <div class="right">
                <div class="heading">{{ $t("blackJack.otoPurchase") }}</div>
                <div class="description">
                  {{ $t("blackJack.otoPurchaseText") }}
                </div>
              </div>
            </div>
          </div>

          <div class="ok-button">
            <button
              type="button"
              @click="send"
              class="ui-button ui-button-green"
            >
              {{ $t("selectPotWindow.transfer") }}
            </button>
          </div>
          <p class="blackjack-info">{{ $t("blackJack.blackjactSitInfo") }}</p>
        </template>
        <template v-if="player.availableChip < getMinAmount">
          <div class="error-message">
            {{ $t("blackJack.insufficientBalance") }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import EventBus from "@/event-bus";
import ClickOutside from "vue-click-outside";
import VueSlider from "vue-slider-component";

export default {
  name: "SitWindow",
  components: {
    VueSlider,
  },
  watch: {
    show(currentState) {
      if (currentState) {
        this.currentAmount = this.getActiveRoomState.minBuy;
        return false;
      }

      this.currentAmount = this.getActiveRoomState.minBuy;
    },
    "nGetMySeat.in": function (newValue, oldValue) {
      if (!newValue && oldValue === true) {
        this.show = true;
        return;
      }

      this.show = false;
    },
  },
  computed: {
    ...mapGetters(["getShortChipFormat", "getChipFormat"]),
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
      getActiveRoomState: "service/getActiveRoomState",
      getTotalScaleAmount: "getTotalScaleAmount",
    }),
    ...mapState(["user"]),
    player() {
      return this.user;
    },
    getMinAmount() {
      return this.getActiveRoomState.minBuy;
    },
    getMaxAmount() {
      if (this.player.availableChip < this.getActiveRoomState.minBuy)
        return this.getActiveRoomState.minBuy;
      return this.player.availableChip > 10000000000000
        ? 10000000000000
        : this.player.availableChip;
    },
  },
  directives: { ClickOutside },
  data() {
    return {
      show: false,
      autoPurchase: false,
      currentAmount: 0,
      clickOutsideBlocked: false,
    };
  },
  methods: {
    onSliderDragEnd() {
      this.clickOutsideBlocked = false;
    },
    onSliderDragging() {
      this.clickOutsideBlocked = true;
    },
    onBjSitWindowRequested() {
      this.$data.show = true;
    },
    onClickedOutside($event) {
      if (this.$data.clickOutsideBlocked) return false;
      if (
        typeof $event?.target?.className.includes !== "undefined" &&
        $event?.target?.className.includes("sit-button")
      )
        return false;
      this.close();
    },
    onSliderButtonClicked(direction = 1) {
      if (direction === 0) {
        this.$data.currentAmount -= this.getMinAmount;
        if (this.$data.currentAmount < this.getMinAmount)
          this.$data.currentAmount = this.getMinAmount;

        return;
      }

      this.$data.currentAmount += this.getMinAmount;
      if (this.$data.currentAmount > this.getMaxAmount)
        this.$data.currentAmount = this.getMaxAmount;
    },
    close() {
      this.$data.show = false;
      this.$data.currentAmount = 0;
      this.$data.clickOutsideBlocked = false;
      this.$data.autoPurchase = false;
    },
    send() {
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("sit", {
        amount: this.currentAmount,
        autoPurchase: this.$data.autoPurchase ? 1 : 0,
      });
      this.close();
      this.$adjust.trackEvent("BlackjackSitButtonClick");
    },
  },
  beforeDestroy() {
    EventBus.$off(
      "onBjSitWindowRequested",
      this.onBjSitWindowRequested.bind(this)
    );
  },
  created() {
    this.$data.currentAmount = this.getActiveRoomState.minBuy;
    EventBus.$on(
      "onBjSitWindowRequested",
      this.onBjSitWindowRequested.bind(this)
    );
  },
};
</script>

<style scoped lang="scss">
.sit-window-outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  .sit-window-inner {
    position: relative;
    width: 1100px;
    height: 800px;
    background-color: $containerColor;
    border-radius: 15px;
    font-size: 36px;
    box-shadow: inset 0 0 0 1px rgba(rgb(255, 255, 255), 0.1), 0 2px 3px 0 black;
    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 48px;
      height: 48px;
      display: block;
      background-color: transparent;
      border: 0;
      color: rgba(rgb(255, 255, 255), 0.5);
      transition: all 0.25s ease;
      cursor: pointer;
      &:hover {
        color: rgb(255, 255, 255);
      }
    }
    .header {
      align-items: center;
      justify-content: flex-start;
      height: 110px;
      padding: 10px 30px;
      box-sizing: border-box;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 34px;
      background: linear-gradient(0deg, rgba(black, 0.2), transparent);
      border-bottom: 1px solid rgba(rgb(255, 255, 255), 0.1);
      text-align: center;
      padding-top: 35px;
    }
    .body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      box-sizing: border-box;
      padding: 30px;
      .current-amount {
        padding: 15px 25px;
        background-color: rgba(black, 0.5);
        border-radius: 10px;
        color: rgb(255, 255, 255);
        font-size: 48px;
        font-weight: bold;
      }
      .slider {
        margin-top: 20px;
        width: 100%;
        .slider-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 26px;
          margin-top: 10px;
        }
        .slider-inner {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .slider-button {
            flex-shrink: 0;
            width: 72px;
            height: 72px;
            background-color: rgba(black, 0.5);
            border: 0;
            border-radius: 100%;
            background-size: 80%;
            background-position: center;
            background-repeat: no-repeat;
            box-shadow: 0 0 0 1px rgba(rgb(255, 255, 255), 0.2);
            transition: all 0.25s ease;
            &:hover {
              background-color: rgba(black, 0.7);
              box-shadow: 0 0 0 1px rgba(rgb(255, 255, 255), 0.2),
                0 0 0 5px rgba(rgb(255, 255, 255), 0.1);
            }
            &:active {
              box-shadow: 0 0 0 1px rgba(rgb(255, 255, 255), 0.2),
                0 0 0 5px rgba(rgb(255, 255, 255), 0.1);
            }
            &:first-child {
              margin-right: 20px;
            }
            &:last-child {
              margin-left: 20px;
            }
            &.slider-plus {
              background-image: url("data:image/svg+xml,%3Csvg fill='none' stroke='white' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 6v6m0 0v6m0-6h6m-6 0H6'%3E%3C/path%3E%3C/svg%3E");
            }
            &.slider-minus {
              background-image: url("data:image/svg+xml,%3Csvg fill='none' stroke='white' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M20 12H4'%3E%3C/path%3E%3C/svg%3E");
            }
          }
        }
      }
      .options {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        .option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 40px;
          padding: 15px;
          max-width: 80%;
          transition: all 0.25s ease;
          cursor: pointer;
          .checkmark {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 64px;
            height: 64px;
            box-sizing: border-box;
            border: 2px solid rgba(rgb(255, 255, 255), 0.5);
            border-radius: 100%;
            margin-right: 20px;
            transition: all 0.25s ease;
            .inner {
              width: 32px;
              height: 32px;
              border-radius: 100%;
              background: linear-gradient(
                180deg,
                $greenColor,
                darken($greenColor, 20%)
              );
            }
          }
          .right {
            color: rgba(rgb(255, 255, 255), 0.5);
            transition: all 0.25s ease;
            .heading {
              font-weight: bold;
            }
            .description {
              margin-top: 10px;
              font-size: 21px;
            }
          }
          &:hover {
            .checkmark {
              border-color: rgba(rgb(255, 255, 255), 0.7);
            }
            .right {
              color: rgba(rgb(255, 255, 255), 0.7);
            }
          }
          &.active {
            border-color: rgb(255, 255, 255);
            .checkmark {
              border-color: rgb(255, 255, 255);
            }
            .right {
              color: rgb(255, 255, 255);
            }
          }
        }
      }
      .blackjack-info {
        margin-top: 40px;
        font-size: 30px;
        color: #f8a624;
      }
      .ok-button {
        margin-top: 20px;
        button {
          text-transform: uppercase;
        }
      }
      .error-message {
        color: $redColor;
      }
    }
  }
}

.platform-mobile {
  .sit-window-outer {
    background-color: transparent;
    .sit-window-inner {
      width: 1300px;
      height: 800px;
      border-radius: 30px;
      box-shadow: none;
      .close {
        width: auto;
        height: auto;
      }
      .body {
        margin-top: 50px;
        padding: 10px 30px;
        overflow-x: hidden;
        overflow-y: auto;
        justify-content: flex-start;
        max-height: calc(100% - 60px);
        .options {
          margin-top: 20px;
        }
        .ok-button {
          margin-top: 65px;
          button {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.total-user-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  .chip {
    margin-right: 20px;
  }
}
</style>
