import axios from "@/services/api/axios";
import { reject } from "lodash";

export default {
  async fetch({ state, commit }, { serverId, roomId }) {
    state.fetching = true;
    try {
      const response = await axios.get(`/jackpots/v2/${serverId}/${roomId}`);

      state.fetching = false;

      commit("clearList");

      response.data.forEach((jackpot) => commit("addToList", jackpot));
    } catch {
      return false;
    }
  },

  async fetchTopJackpot({ state, commit }, { limit = 3 }) {
    state.fetching = true;
    try {
      const response = await axios.get(`jackpots/v2/jackpot-all-active-rooms`);

      state.fetching = false;

      await commit("clearAllJackpot");

      if (response.data.length > 0) {
        const sortedJackpots = response.data.sort(
          (a, b) => parseInt(b.metadata.bet) - parseInt(a.metadata.bet)
        );

        sortedJackpots.forEach((jackpot) => {
          if (jackpot.totaljackpotAmount > 0 && jackpot.metadata.useJackpots) {
            if (jackpot.metadata.gameType === "texas_holdem") {
              if (state.texasJackpots.length > 0) {
                if (
                  state.texasJackpots.filter(
                    (jack) => jack.metadata.bet == jackpot.metadata.bet
                  ).length < 1
                ) {
                  commit("addToJackpots", {
                    payload: jackpot,
                    name: "texasJackpots",
                  });
                }
              } else {
                commit("addToJackpots", {
                  payload: jackpot,
                  name: "texasJackpots",
                });
              }
            } else {
              if (state.omahaJackpots.length > 0) {
                if (
                  state.omahaJackpots.filter(
                    (jack) => jack.metadata.bet == jackpot.metadata.bet
                  ).length < 1
                ) {
                  commit("addToJackpots", {
                    payload: jackpot,
                    name: "omahaJackpots",
                  });
                }
              } else {
                commit("addToJackpots", {
                  payload: jackpot,
                  name: "omahaJackpots",
                });
              }
            }
          }
        });
      }
    } catch {
      return false;
    }
  },

  // async allFetch({ state, commit }, { serverId, roomIds }) {
  //   state.allJackpots = [];
  //   const promiseJackpots = new Promise((resolve, reject) => {
  //     try {
  //       const jackpots = [];
  //       let roomJackpotLenght = 0;
  //       roomIds.forEach(async (room) => {
  //         const response = await axios.get(
  //           `/jackpots/v2/${serverId}/${room.id}`
  //         );

  //         roomJackpotLenght += 1;
  //         response.data.forEach((jackpot) => {
  //           if (jackpot?.type === "system")
  //             jackpots.push({ ...jackpot, tableId: room.id, bet: room.bet });
  //         });
  //         if (roomJackpotLenght >= roomIds.length) {
  //           resolve(jackpots);
  //         }
  //       });
  //     } catch (error) {
  //       console.log(error);
  //       reject(false);
  //     }
  //   });
  //   promiseJackpots.then((res) => {
  //     const sortJackPots = res.sort((a, b) =>
  //       a.amount > b.amount ? -1 : b.amount > a.amount ? 1 : 0
  //     );
  //     state.allJackpots = sortJackPots;
  //   });
  // },

  async fetchMyJackpot(store) {
    try {
      const response = await axios.get(`/jackpots/v2/my`);

      return response.data;
    } catch {
      return false;
    }
  },
};
