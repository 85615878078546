<template>
  <div class="ad-bonus-outer">
    <button
      class="lobby-sprite watch-win lobby-watch-and-win-button ad-bonus-button"
      :class="[getRealPlatform === 'android' ? 'no-flick' : '']"
      :disabled="!isReady || $store.state.bonus.adBonusClaiming"
      @click="adBonusWindowOpen"
    ></button>
    <!-- <div class="bonus-countdown" v-if="!isReady && user.id > 0">
      <Countdown :deadline="getAvailableTime" @onEnd="updateStatus" />
    </div> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";
// import AnalyticsEvents from "@/plugins/analytics/events";
// import Countdown from "@/components/Misc/Countdown";

export default {
  name: "AdBonus",
  // components: {
  //   Countdown,
  // },
  computed: {
    ...mapState({
      user: (state) => state.user,
      bonus: (state) => state.bonus.adBonus,
      adBonusTime: (state) => state.config.ad_bonus_time ?? 0,
    }),
    ...mapGetters(["getRealPlatform"]),
    isReady() {
      return this.bonus.ready;
    },
    getAvailableTime() {
      return this.bonus.lastClaimAt + this.adBonusTime;
    },
  },
  data() {
    return {};
  },
  methods: {
    updateStatus() {
      this.$store.dispatch("bonus/getAdBonusStatus");
    },
    adBonusWindowOpen() {
      this.$store.dispatch("bonus/prepareRewardVideoAd");
      this.$store.commit("modals/show", "watchWinWindow");
    },
    onAdBonusClaiming() {},
    onAdBonusClaimed() {
      this.updateStatus();
      this.$store.state.bonus.adBonusClaiming = false;
    },
    onAdBonusClaimError() {
      this.$store.state.bonus.adBonusClaiming = false;
    },
  },
  beforeDestroy() {
    EventBus.$off(EventConstants.AD_BONUS_CLAIMING);
    EventBus.$off(EventConstants.AD_BONUS_CLAIMED);
    EventBus.$off(EventConstants.AD_BONUS_CLAIM_ERROR);
  },
  created() {
    EventBus.$on(
      EventConstants.AD_BONUS_CLAIMING,
      this.onAdBonusClaiming.bind(this)
    );
    EventBus.$on(
      EventConstants.AD_BONUS_CLAIMED,
      this.onAdBonusClaimed.bind(this)
    );
    EventBus.$on(
      EventConstants.AD_BONUS_CLAIM_ERROR,
      this.onAdBonusClaimError.bind(this)
    );
    this.$store.dispatch("bonus/getAdBonusStatus");
  },
};
</script>

<style scoped lang="scss">
.ad-bonus-outer {
  position: relative;

  .ad-bonus-button {
    background-color: transparent;
    margin-top: 18px;
    margin-right: -8px;
    &:disabled {
      opacity: 0.8;
    }
  }

  .bonus-countdown {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -30px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px 1px black, -2px -2px 1px black;
  }
}
</style>
