<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1450"
    height="950"
    :show="true"
    :stopClickOutside="true"
    :titleText="$t('tableQuickMenu.watchWin')"
  >
    <template>
      <div class="watch-win-window">
        <div class="watch-text">
          {{
            $t("adBonusWindow.content", { money: getChipFormat(adBonusAmount) })
          }}
        </div>
        <div
          class="watch-btn ui-button ui-button-small-orange"
          @click="collectADBonus"
        >
          {{ $t("adBonusWindow.watchButton") }}
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";
import { mapGetters } from "vuex";
export default {
  name: "WatchWinWindow",
  components: {
    BaseWindow,
  },
  computed: {
    ...mapGetters(["getChipFormat"]),
    adBonusAmount() {
      return this.$store.state.config.ad_bonus_amount;
    },
  },
  methods: {
    async collectADBonus() {
      this.$store.state.bonus.adBonusClaiming = true;
      await this.$store.dispatch("bonus/collectAdBonus");

      this.$store.commit("modals/hide", "watchWinWindow");
    },
    closeWindow() {
      this.$store.commit("modals/hide", "watchWinWindow");
      this.$store.state.bonus.oneClickBonus.ready = true;
    },
    initWindow() {},
  },
};
</script>

<style lang="scss" scoped>
.watch-win-window {
  position: relative;
  width: 99%;
  height: 86%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-image: url("~@/assets/images/lobby/watch-win-image.png");
  background-size: cover;
  .watch-text {
    color: white;
    font-size: 40px;
    margin-bottom: 364px;
  }
  .watch-btn {
    margin-top: 20px;
  }
}
</style>
