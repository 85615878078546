<template>
  <div
    class="gift-container sprite-gifts"
    v-if="$data.num !== ''"
    :class="[`gift-${$data.num}`, `seat-${$parent.getComputedIndex}`]"
  ></div>
</template>

<script>
import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";

export default {
  name: "Gift",
  props: {
    playerId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      num: "",
      timeout: null,
    };
  },
  methods: {
    onGiftSent({ from, id, to, type }) {
      if (!id) return;

      if (to.length < 1) {
        return;
      }

      let _to;
      if (to.length < 2) {
        _to = to[0];

        if (
          typeof _to === "undefined" ||
          this.playerId !== _to ||
          type !== "gift" ||
          this.$store.getters["preferences/getValue"]("animated_gifts") == 0
        ) {
          return false;
        }

        // this.setDefaultState();
        this.clearCurrentTimeout();
        this.$emit("incomingGift");

        setTimeout(
          () => {
            this.$data.num = id;
          },
          this.$data.num ? 200 : 0
        );
      } else {
        to.forEach((element) => {
          if (
            typeof element === "undefined" ||
            this.playerId !== element ||
            type !== "gift" ||
            this.$store.getters["preferences/getValue"]("animated_gifts") == 0
          ) {
            return false;
          }

          // this.setDefaultState();
          this.clearCurrentTimeout();
          this.$emit("incomingGift");

          setTimeout(
            () => {
              this.$data.num = id;
            },
            this.$data.num ? 200 : 0
          );
        });
      }
    },
    setDefaultState() {
      this.$data.num = "";
    },
    clearCurrentTimeout() {
      if (this.$data.timeout) clearTimeout(this.$data.timeout);
    },
  },
  beforeDestroy() {
    EventBus.$off(EventConstants.GIFT_SENT, this.onGiftSent.bind(this));
  },
  created() {
    EventBus.$on(EventConstants.GIFT_SENT, this.onGiftSent.bind(this));
  },
};
</script>

<style scoped lang="scss">
@keyframes giftIncomingAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.gift-container {
  position: absolute;

  z-index: 5;
  animation-name: giftIncomingAnimation;
  animation-timing-function: linear;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  pointer-events: none;

  &.seat-0,
  &.seat-1,
  &.seat-2,
  &.seat-8 {
    bottom: 0px;
  }
  &.seat-3,
  &.seat-4,
  &.seat-5,
  &.seat-6,
  &.seat-7 {
    bottom: 0px;
  }
}

.seat-me {
  .gift-container {
    top: -16px !important;
    left: -10px !important;
  }
}
</style>
