<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1282"
    height="780"
    :loading="loading"
    :show="true"
    innerBackground="linear-gradient(0deg, rgb(90, 18, 26) 0%, rgb(147, 38, 52) 100%)"
    class="transfer-line"
    :titleText="$t('chipTransferWindow.sendChip')"
  >
    <template>
      <div class="transfer-window">
        <span class="title-bg profile-sprite light"></span>
        <div class="transfer-window-content">
          <div class="avatar-side avatar-side-left">
            <Avatar
              class="player-window-avatar"
              :url="user.avatar"
              :crown="myCrownCondition(user.crown, user.vip.status)"
              size="202"
            />
            <div class="player-chip">
              <div class="lobby-sprite chip"></div>
              <span class="user-chip"> {{ getChipFormat(user.chip) }}</span>
            </div>
            <div class="player-detail">
              <div class="player-detail-username">
                {{ user.name }}
              </div>
              <div class="player-detail-playerId">ID: {{ user.id }}</div>
            </div>
          </div>
          <div class="inputs">
            <div class="form-group">
              <div class="form-input">
                <input
                  type="text"
                  v-model="amount"
                  :placeholder="$t('chipTransferWindow.chipAmount')"
                  class="chip-amount"
                />
              </div>
              <div class="form-input desc">
                <input
                  class="textarea"
                  v-model="desc"
                  :placeholder="$t('chipTransferWindow.chipMessage')"
                  maxlength="255"
                />
              </div>
              <!-- <div class="warning">{{ $t("chipTransferWindow.warning") }}</div> -->
            </div>
            <div class="sprite-profile-popup incoming-chip-tab">
              <span
                class="incoming-chip-tab-amount"
                v-if="sendChipAmountFormant"
              >
                {{ sendChipAmountFormant }}
              </span>
            </div>
          </div>
          <div class="avatar-side avatar-side-right">
            <Avatar
              class="player-window-avatar"
              :url="getSendChipWindowData.avatar"
              :crown="getSendChipWindowData.crown"
              size="202"
            />
            <div class="player-chip player-chip-right">
              <div class="lobby-sprite chip"></div>
              <span class="user-chip">
                {{ getChipFormat(getSendChipWindowData.chip) }}</span
              >
            </div>
            <div class="player-detail">
              <div class="player-detail-username">
                {{ getSendChipWindowData.name }}
              </div>
              <div class="player-detail-playerId">
                ID: {{ getSendChipWindowData.id }}
              </div>
            </div>
          </div>
        </div>
        <div class="transfer-window-footer">
          <div class="buttons">
            <div class="ui-confirm confirm-button" @click="chipSend">
              {{ $t("general.send") }}
            </div>
          </div>
          <div class="info-commission-text">
            {{ $t("general.commission") }}: {{ user.commission }}
          </div>
        </div>
        <ChipAreYouSureWindow
          :show="dialogValue"
          @closeAreYouSure="dialogValue = false"
          @confirm="userRequestSendChip"
          :amount="amount"
          :titleText="$t('friendDetail.sendChipText')"
          :content="
            $t('others.chipSendAreYouSure', [
              getSendChipWindowData.id,
              getSendChipWindowData.name,
            ])
          "
        />
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Avatar from "@/components/Misc/Avatar";
import BaseWindow from "./BaseWindow";
import Helpers from "@/mixin/Helpers";

export default {
  name: "SendChipWindow",
  mixins: [Helpers],
  components: {
    BaseWindow,
    Avatar,
    ChipAreYouSureWindow: () => import("./ChipAreYouSureWindow"),
  },
  data() {
    return {
      loading: false,
      amount: "",
      desc: "",
      dialogValue: false,
    };
  },
  watch: {
    amount(newValue) {
      this.formatAmount(newValue);
    },
  },
  computed: {
    ...mapGetters("chipTransfer", ["getSendChipWindowData"]),
    ...mapGetters(["getShortChipFormat", "getChipFormat"]),
    ...mapState(["user"]),
    sendChipAmountFormant() {
      return this.getShortChipFormat(this.amount.replaceAll(".", ""));
    },
  },

  methods: {
    async userRequestSendChip() {
      const res = await this.$store.dispatch("chipTransfer/sendChip", {
        amount: String(this.amount).replaceAll(".", ""),
        receiverId: this.getSendChipWindowData.id,
        description: this.desc ? this.desc : "No Message",
      });

      if (!res) {
        this.$plugins?.audio?.play("Button");
        return;
      }
      this.$plugins.toast.show({
        text: this.$t("playerActions.successTransfer"),
        duration: 2500,
        variant: "success",
      });
      this.$adjust.trackEvent("UserChipTransferToUser");
      this.$plugins?.audio?.play("friend_chip_send");
      this.closeWindow();
    },
    closeWindow() {
      this.amount = null;
      this.desc = "";
      this.$store.commit("modals/hide", "sendChip");
      this.$store.commit("chipTransfer/clearSendChipWindowData");
    },
    formatAmount(amount) {
      const result = String(amount)
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.$nextTick(() => (this.amount = result));
    },
    async chipSend() {
      if (isNaN(parseInt(String(this.amount).replaceAll(".", "")))) {
        this.$plugins.toast.show({
          text: this.$t("playerActions.validChip"),
          duration: 2500,
          variant: "danger",
        });
        this.$plugins?.audio?.play("Button");
        return;
      } else if (
        this.$store.state.user.chip <
        parseInt(String(this.amount).replaceAll(".", ""))
      ) {
        this.$plugins.toast.show({
          text: this.$t("playerActions.enoughChips"),
          duration: 2500,
          variant: "danger",
        });
        this.$plugins?.audio?.play("Button");
        return;
      } else if (
        parseInt(String(this.amount).replaceAll(".", "")) < 10000000000
      ) {
        this.$plugins.toast.show({
          text: this.$t("playerActions.minSendChipAmount"),
          duration: 2500,
          variant: "danger",
        });
        this.$plugins?.audio?.play("Button");
        return;
      }
      const defaultDescription = this.$t("chat.anyMessages");
      const res = await this.$store.dispatch("chipTransfer/sendChip", {
        amount: String(this.amount).replaceAll(".", ""),
        receiverId: this.getSendChipWindowData.id,
        description: this.desc ? this.desc : defaultDescription,
      });

      if (!res) {
        this.$plugins?.audio?.play("Button");
        return;
      }
      this.$plugins.toast.show({
        text: this.$t("playerActions.successTransfer"),
        duration: 2500,
        variant: "success",
      });
      this.$plugins?.audio?.play("friend_chip_send");
      this.closeWindow();
    },
    myCrownCondition(crownNumber = 0, vip = false) {
      const calcCrown = crownNumber < 1 && vip ? 0 : crownNumber;
      return vip ? calcCrown : -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.transfer-line {
  z-index: 13 !important;
}
.transfer-window {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  .title-bg {
    position: absolute;
    top: 0;
  }
  &-title,
  &-footer {
    width: 100%;
    text-align: center;
    position: relative;
    .buttons {
      .send-button {
        text-transform: uppercase;
      }
      .ui-confirm {
        position: relative;
        width: 350px;
        height: 80px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 38px;
        color: #fff;
        font-weight: bold;
        text-shadow: 0px 3px 2px black;
        text-transform: uppercase;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        transition: all 0s ease;
        background: transparent;
        &.confirm-button {
          background-image: url("~@/assets/images/ui_button/orange-button-small.png");
          width: 241px;
          height: 76px;
        }
      }
    }
    .info-commission-text {
      position: absolute;
      bottom: -20px;
      right: 20px;
      font-size: 26px;
    }
  }
  &-paragraph {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    height: 600px;
  }
  &-content {
    position: relative;
    width: 100%;
    height: 75%;
    display: flex;

    .inputs {
      width: 60%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .form-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 20px;
        .form-input {
          width: 100%;
          height: 30%;
          display: flex;
          justify-content: center;

          input[type="number"] {
            -moz-appearance: textfield;
          }

          input {
            width: 600px;
            height: 82px;
            background: #55121b;
            box-shadow: inset 1px 3px 8px black;
            font-size: 45px;
            text-align: center;
            border-radius: 45px;
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 10px;
            margin-top: 10px;

            &::placeholder {
              color: rgba(255, 255, 255, 0.8);
              opacity: 1;
              font-size: 36px;
            }
          }
          &.desc {
            margin-top: 40px;
            input {
              width: 600px;
              height: 82px;
              background: #55121b;
              box-shadow: inset 1px 3px 8px black;
              font-size: 45px;
              text-align: center;
              border-radius: 45px;
              color: rgba(255, 255, 255, 0.8);
              margin-bottom: 10px;
              margin-top: 10px;

              &::placeholder {
                color: rgba(255, 255, 255, 0.8);
                opacity: 1;
              }
            }
          }
        }
        .chip-amount {
          border: none;
          box-shadow: inset 1px 3px 8px black;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          &::placeholder {
            position: relative;
            bottom: 8px;
          }
        }
        .warning {
          font-size: 35px;
        }
      }
      .incoming-chip-tab {
        position: absolute;
        top: 265px;
        transform: scale(0.6) rotate(180deg);
        .incoming-chip-tab-amount {
          font-size: 32px;
          position: absolute;
          right: 200px;
          bottom: 32px;
          transform: rotate(-180deg);
          font-size: 46px;
        }
      }
    }
    .avatar-side {
      position: relative;
      width: 20%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .profile-image {
        .player-window-avatar {
          position: absolute;
          z-index: 5;
          left: 90px;
          top: 80px;
        }
      }
      .player-detail {
        position: relative;

        height: 20%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        position: relative;
        top: 50px;
        &-username {
          font-size: 45px;

          color: #f5a325;
          margin-top: -20px;
          position: relative;
        }
        &-playerId {
          font-size: 40px;
          color: #fff;
        }
      }
      .player-chip {
        position: relative;
        left: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .user-chip {
          font-size: 40px;
          margin: 20px;
          position: relative;
          top: 12px;
        }
      }
      .player-chip-right {
        left: unset;
        right: 0px;
      }
    }
    .avatar-side-right {
      align-items: flex-end;
      .avatar {
        position: relative;
        right: 60px;
      }
      .player-chip {
        position: relative;
        right: 10px;
      }
      .player-detail {
        position: relative;
        right: 40px;
      }
    }
    .avatar-side-left {
      align-items: flex-start;
      .avatar {
        position: relative;
        left: 60px;
      }
      .player-chip {
        position: relative;
        left: 10px;
      }
      .player-detail {
        position: relative;
        left: 40px;
      }
    }
  }
}

.chip {
  position: relative;
  left: 0px;
  top: 13px;
  transform: scale(1.2);
}
</style>
