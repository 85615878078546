<template>
  <div class="toast" :class="[...getVariantClasses, $data.show ? 'show' : '']">
    <div class="toast-inner">
      <div class="text" v-html="options.text"></div>
      <div class="buttons" v-if="getButtons.length > 0">
        <button
          class="ui-button"
          v-for="(button, buttonIndex) in getButtons"
          :key="'toast-button-' + buttonIndex"
          :class="[...button.classes]"
          type="button"
          @click="
            typeof button.onClick === 'undefined' ? () => {} : button.onClick
          "
        >
          {{ button.text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";

const DEFAULT_DURATION = 3000;
const DEFAULT_OPTIONS = {
  text: "",
  variant: "default",
  duration: DEFAULT_DURATION,
  buttons: [],
};

export default {
  name: "Toast",
  computed: {
    ...mapGetters(["getShortChipFormat"]),
    getVariantClasses() {
      const classes = [];
      if (this.$data.options?.variant) classes.push(`variant-default`);
      return classes;
    },
    getButtons() {
      if (typeof this.$data.options.buttons === "undefined") return [];

      return this.$data.options.buttons;
    },
  },
  data() {
    return {
      options: DEFAULT_OPTIONS,
      show: false,
      timeout: 0,
      innerTimeout: 0,
      slotInterval: null,
    };
  },
  methods: {
    onToastRequested(options) {
      this.$data.show = false;
      this.setDefaultOptions();

      this.$set(this.$data, "options", options);
      this.$data.show = true;

      if (this.$data.timeout) clearTimeout(this.$data.timeout);
      if (this.$data.innerTimeout) clearTimeout(this.$data.innerTimeout);

      this.$data.timeout = setTimeout(() => {
        this.$data.show = false;
        this.innerTimeout = setTimeout(this.setDefaultOptions.bind(this), 500);
      }, this.$data.options.duration ?? DEFAULT_DURATION);
    },
    setDefaultOptions() {
      this.$data.options = DEFAULT_OPTIONS;
    },
  },
  beforeDestroy() {
    EventBus.$off(EventConstants.TOAST_REQUESTED);
    if (this.slotInterval) {
      clearInterval(this.slotInterval);
    }
  },
  mounted() {
    this.slotInterval = setInterval(() => {
      const slotWinners = this.$store.state.service.slotBigWinners;
      if (
        slotWinners.length > 0 &&
        slotWinners[0].date + 90 > Math.round(Date.now() / 1000)
      ) {
        this.$plugins.toast.show({
          text: this.$t("slotWinnerToast.bigWinText")
            .replace("{amount}", this.getShortChipFormat(slotWinners[0].amount))
            .replace("{username}", slotWinners[0].name),
          duration: 4500,
        });
        this.$store.commit("service/spliceSlotBigWinner", 1);
      }
    }, 40000);
  },
  created() {
    EventBus.$on(
      EventConstants.TOAST_REQUESTED,
      this.onToastRequested.bind(this)
    );
  },
};
</script>

<style scoped lang="scss">
.toast {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100%;
  min-height: 60px;
  transform-origin: top;
  transform: scaleY(0);
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 1px;
  border: none;
  background: #86081e;
  transition: all 0.15s ease-in-out;
  &.variant-danger {
    background: linear-gradient(180deg, #ff3e3e, #d38058);
  }
  &.variant-success {
    background: linear-gradient(180deg, #95ff3e, #79d358);
  }
  &.variant-warning {
    background: linear-gradient(180deg, #ffc83e, #d3a258);
  }
  &.variant-default {
    box-shadow: inset 1px 3px 20px black;
    background: #86081e;
  }
  &.show {
    transform: scaleY(1);
  }
  &-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(61, 28, 23, 0.98),
      rgba(72, 28, 19, 0.6)
    );
    border-radius: 5px;
    color: rgb(255, 255, 255);

    .text + .buttons {
      margin-left: 25px;
    }
    .text {
      font-size: 22px;
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      .ui-button {
        width: 80px;
        height: 25px;
        font-size: 14px;
        margin-right: 10px;
        text-shadow: 0 1px 2px black;
        box-shadow: none !important;
        &::before {
          display: none;
        }
        &:last-child {
          margin-right: 0 !important;
        }
        &:active {
          transform: translateY(1px);
        }
      }
    }
  }
}
</style>
