/*
 * Italian language file
 */ /*
 * Italian language file
 */

export default {
  loading: "Caricamento in corso...",

  general: {
    no: "No",
    yes: "Sì",
    tableInformation: "Tabella informativa",
    avatar: "Avatar",
    vipIcon: "Icona VIP",
    cancel: "Annulla",
    save: "Salva",
    logout: "Disconnettersi",
    play: "Giocare",
    chat: "Chiacchierata",
    leaders: "Capo",
    social: "Sociale",
    case: "Caso",
    shop: "Negozio",
    okay: "Bene",
    refresh: "Ricaricare",
    send: "Inviare",
    username: "Nome utente",
    delete: "Elimina",
    reject: "Rifiuta",
    accept: "Accetta",
    confirm: "Confermare",
    registrationDate: "Reg. Data",
    name: "Nome",
    total: "Totale",
    copy: "Copia",
    commission: "Commissione",
    getGift: "Ottieni il tuo regalo",
    you: "Voi",
    transfer: "Trasferimento",
    menu: "Menù",
    all: "Tutto",
    update: "Aggiornare",
    invite: "Invitare",
    joinNow: "Iscriviti adesso",
    handHistory: "Storia della mano", //NEW***********
  },
  giftsWindow: {
    gift: "Regalo", //NEW***********
    getYourself: "Per te", //NEW***********
    getGift: "Acquista regalo", //NEW***********
    getEveryone: "Per tutti", //NEW***********
    expression: "Emoticon", //NEW***********
    selectGift: "Seleziona un regalo", //NEW***********
    noPlayersAtTable: "Nessun giocatore al tavolo", //NEW***********
    sitATable: "Per favore, siediti prima a tavola", //NEW***********
    selectUser: "Seleziona un utente", //NEW***********
  },
  dailyEntry: {
    dailyEntry: "Bonus giornaliero", //NEW***
    dailyDesc: "Accedi tutti i giorni e ricevi più chip!", //NEW***
    day: "Giorno", //NEW***
  },
  noficationWindow: {
    notificationInviteTable:
      "{name} ti ha invitato al tavolo in {gameType}{tableId} alle {date}", // NEW***
    notificationInviteChip: " {name} ti ha inviato {amount} chip.", // NEW***
    sendMessage: " Digita qui il tuo messaggio", // NEW***
    tableInvite: "Invito", // NEW***
    system: "Sistema", // NEW***
    messages: "Messaggio", // NEW***
    notifications: "Notifica", // NEW***
  },
  authentication: {
    signInToContinue: "Accedi per continuare",
    anErrorOccuredWhileLoggingIn:
      "Si è verificato un errore durante l'accesso!",
    contact: "Contatto",
    privacyPolicy: "Politica sulla riservatezza",
    provablyFair: " Provabilmente giusto",
    sign_guest: "Giocare come ospite",
    sign_apple: "Accedi con Apple",
    sign_google: "Accedi con Google",
    sign_facebook: "Accedi con facebook",
    loginBonusText:
      "<p class='login-info-text'> Ottieni $ di chip bonus!<span class='color-white'> {0} </span> o <span class='color-white'> {1} </span> </p>",
    loginBonusTextWeb:
      "<p class='login-info-text'> Ottieni $ di chip bonus!<span class='color-white'> {0} </span> </p>",
  },
  playerActions: {
    enoughChips: "Equilibrio insufficiente",
    minSendChipAmount: "Minimo 10B",
    failedTransfer: "Impossibile trasferire i chip",
    successTransfer: "Chip trasferiti con successo.",
  },
  tableList: {
    table: "Tavolo",
    tableId: "ID tabella",
    tableName: "Nome",
    blind: "Blind",
    minMax: "Min/Mass",
    filterByBlinds: "Filtra per Blind",
    hideEmpties: "Nascondi tabelle vuote",
    hideFullyFilled: "Nascondi tabelle complete",
    players: "Giocatori",
    full: "Completo",
    noRoomsMessage: "Niente stanze",
    tableList: "Elenco dei tavoli",
    tableInfo: {
      emptyRoomMessage: "La stanza è vuota.",
    },
  },
  message: {
    message: "Messaggio",
    sendMessage: "Invia messaggio",
    typeMessage: "Digitare qui",
    sentMessage: "ti ho inviato un messaggio",
  },

  lobby: {
    play: "Giocare",
    playNow: "Gioca subito",
    newSlotGame: "Nuovo gioco di slot",
    availableNow: "Disponibile ora",
    specialForYou: "Speciale per te",
    favouriteGameButton: {
      instructionMessage: "Tocca due volte per cambiare",
    },
  },

  bonusWindow: {
    congratulations: "Congratulazioni!",
    accept: "Accettare",
  },

  maintenance: {
    gameMaintenance: "Il gioco è in manutenzione",
    underMaintenance: "Sotto la manutenzione",
    tryAgainLAter:
      "Il gioco è attualmente in manutenzione. Per favore riprova più tardi!",
  },

  confirmWindow: {
    sure: "Sei sicuro?",
    userNotFound: "Utente non trovato",
    notAffiliate: "Utente non affiliato",
    DaysVip: "Acquistate un abbonamento VIP di 30 giorni",
  },
  deleteConfirmWindow: {
    sure: "Stai per eliminare il tuo account",
    content:
      "Se continui questo processo, le tue informazioni utente e i prodotti che hai acquistato verranno eliminati",
    delete: "Elimina",
    cancel: "Annulla",
    deleteSuccessfully: "Account eliminato con successo",
    notBeDeleted: "c'era un errore",
    areYouSure: "Sei sicuro di voler eliminare il tuo account?",
    deletefriends: "L'amico verrà rimosso dal tuo elenco di amici.",
    daysLimit:
      "Se il tuo account rimane inattivo per 30 giorni, verrà eliminato definitivamente.",
  },
  historyWindow: {
    players: "Giocatori",
    hand: "Mano",
    preFlop: "Pre-Flop",
    flop: "Flop",
    turn: "Turn",
    river: "River",
    winner: "Vincitore",
    speacial: "Speciale",
    team: "Squadra",
    anyHistory: "Nessuna storia da mostrare",
  },
  socialWindow: {
    gameCardDesc: "Ottieni bonus dai biglietti che hai vinto.",
    gameCard: "Carta da gioco",
    friends: "Gli amici",
    searchFriend: "Cerca amico",
    requests: "Richieste",
    chipTransferHistory: "Cronologia dei trasferimenti di chip",
    socialMedia: "Social media",
    socialMediaAndInviteCode: "Social media e codice di invito",
    noFriendRequest: "Nessuna richiesta di amicizia.",
    noFriends: "No amici.",
    noChipTransfer: "Nessuna cronologia dei trasferimenti di chip",
    extraChip:
      "Segui i nostri account sui social media e guadagna chip gratuiti",
    followUs: "Seguici sui Social!",
    inviteCode: "Codice invito",
    myCode: "Il mio codice",
    sharecode: "Condividi il codice", // NEW*******
    inviteCodeDescription:
      "- Condividi il codice di invito qui sopra con il tuo amico. <br><br>- Lascia che il tuo amico, come nuovo membro del gioco, giochi 10 mani ai tavoli da poker.. <br><br>- Entrambi ricevete 10 miliardi di fiches!<br>",
    shareWithFriends: "Condividi con i tuoi amici!",
    shareWithFriendsCode:
      "Ecco il nostro codice bonus! Ottieni 10 miliardi di chip bonus dopo che i tuoi amici hanno giocato 10 mani di poker. Codice:{code}",
    shareInviteCodeWithFriends: "Condividi il tuo codice di invito",
    removeFriend: "Rimuovi dai tuoi amici",
    block: "Bloccare",
    unBlock: "Sbloccare",
  },
  friendDetailsWindow: {
    failed: "Impossibile inviare la richiesta di amicizia",
  },
  chipHistoryDetail: {
    youSent: "tu hai inviato",
    chips: "chips",
    sentYou: "ti ho inviato",
  },
  createTableWindow: {
    bets: "Scommessa",
    createTable: "Crea tabella",
    seatsNumber: "Posti massimi",
    potTable: "Tavolo da pentola",
    pot: "Pentola",
    teamTable: "Tavolo della squadra",
    tellerEntrance: "Cassiere entrata",
    entryLimit: "Limite minimo",
    ipLimit: "Limite IP",
    create: "Creare",
  },
  chipTransferWindow: {
    sendChip: "Invia chip",
    chipAmount: "Inserisci l'importo della fiche",
    chipMessage: "Digita il tuo messaggio",
    warning: "Minimo 10B",
  },
  playerDetailsWindow: {
    profileNote: "Nota di profilo",
    statistics: "Statistiche",
    level: "Livello",
    recordChip: "Record di chip",
    friendsCount: "Gli amici",
    bestHand: "Mano migliore",
    copied: "Copiato!",
    failedChanges: "Impossibile salvare le modifiche",
    enterProfileNote: "Inserisci la nota del tuo profilo..", //NEW****
    gameStatistics: "Statistiche di gioco", //NEW****
    sameFrameWarning:
      "Scegli una cornice diversa da quella che stai attualmente utilizzando.", //NEW****
    sameAvatarWarning:
      "Scegli un avatar diverso da quello che stai attualmente utilizzando", //NEW****
    fillFields: "Si prega di compilare tutti i campi", //NEW****
    passwordsDoesNotMatch: "Le tue password non corrispondono", //NEW****
    passwordLength: "La password deve contenere da 6 a 30 caratteri",
  },
  shopWindow: {
    bronze: "Bronze",
    silver: "Silver",
    gold: "Gold",
    redeem: "Riscattare",
    keysField: "Inserisci le chiavi della carta di gioco nei campi.",
    withoutChip: " Ingresso al tavolo senza fiches",
    buyChip: "Acquista chips",
    redeemCode: "Riscatta il codice",
    noProduct: "Nessun prodotto trovato da visualizzare.",
    buyVip: "Acquista VIP",
    privateEmojis: "Emoji VIP, regalo, cornice del profilo",
    privateProfile: "Cornici speciali per profili VIP",
    connectionNeverLost: "Connessione persistente",
    unLimitedView: "Tempo di visualizzazione illimitato",
    privateTable: "Impostare tavolo speciale",
    unlimitedChipTransfer: "Trasferimento chip illimitato",
    usingPrivateCase: "Usa caso privato speciale",
    xp: "Regalo da 10.000XP",
    freeChat: "Chat globale gratuita",
    day: "Giorno",
    daysLeft: "Giorni rimanenti",
    alreadyVip: "Hai già un abbonamento VIP.",
    noChips: "Non hai abbastanza chip per acquistare l'abbonamento VIP",
    successfullyPurchased: "Hai acquistato con successo l'abbonamento VIP.",
    chipAdded: "Chip ricevuti con successo",
  },
  caseWindow: {
    onlyVip: "Questa funzione è disponibile solo per gli utenti VIP",
    buyVip: "Acquista VIP",
    chipInGame: "Chip nel gioco",
    chipInCase: "Chip nel caso",
    theretentation:
      "Nascondi le tue chips agli altri giocatori e alla classifica.",
    fromGame: "Da Gioco",
    fromCase: "Da Caso",
    amount: "Inserisci l'importo",
    transaction: "Azione completata.",
    transactionFailed: "Transazione fallita",
    enoughChip: "Non hai abbastanza chip da depositare nella custodia.",
    withdraw: "Non hai abbastanza chip per prelevare dal caso.",
    validAmount: "Inserisci un importo valido",
    transfer: "Trasferimento",
  },
  friendsRequestWindow: {
    friendsOffer: "HAI RICEVUTO UNA RICHIESTA DI AMICIZIA!",
    successRequest:
      "La tua richiesta di amicizia è stata inviata con successo.",
    acceptRequest: "ha accettato la tua richiesta di amicizia.",
    rejectedRequest: "rifiutato la tua richiesta di amicizia.",
    becomeFriends: "ti ha inviato una richiesta di amicizia! Accetti?",
    requestSent: "Richiesta già inviata",
    friendRequestToYou: "ti ha inviato una richiesta di amicizia.", //NEW***********
  },
  settingsWindow: {
    language: "Lingua",
    gameSettings: "Impostazioni di gioco",
    customize: "Personalizzare",
    security: "Sicurezza",
    successfullySaved: "Le modifiche sono state salvate con successo.",
    pinCode: {
      pinCode: "CODICE PIN",

      createPinDesc: "Crea un codice pin per la sicurezza dell'account",
      reEnterPin: "Reinserire il codice PIN",
      emailAddress: "E-mail",
      areYouSure: "Vuoi rimuovere il tuo codice PIN?",
      emailRequired: "Necessario per reimpostare la password",
      pinDesc:
        "La creazione di un codice pin è molto importante per la sicurezza dell'account. Turn Games non accetterà alcun rinvio a meno che tu non abbia un codice PIN..",
    },
    customizeWindow: {
      tableStyle: "Stile tavolo",
      tableColors: "Colori della tavola",
      roomBgColor: "Colore di sfondo della stanza",
    },
    gameSettingsWindow: {
      dontDistrub: "Non disturbare",
      blockNotification: "Notifiche",
      showAnimatedGifts: "Mostra emoticon",
    },
  },
  reportWindow: {
    report: "Rapporto",
    reportFilter: "Filtro di rapporto",
    audiences: "Pubblico",
    players: "Giocatori",
    successMessage: "rapporto inviato.",
    failedMessage: "Non è riuscito a inviare il rapporto.",
    swearing: "Imprecando",
    team: "Squadra",
    scissors: "Raise multiplo",
    noPlayersFound: "Nessun giocatore trovato",
  },
  inboxContact: {
    contactUs: "Contattaci",
    title: "Titolo",
    textHere: "Digitare qui",
    congratulations: "Congratulazioni",
    inbox: "Arrivo",
    desc: "Hai guadagnato 10.000 chip per l'ultimo pacchetto acquistato.",
    failedMessage: "Impossibile inviare il messaggio",
    successfullyMessage: "Il messaggio è stato inviato con successo",
    validEmail: "Inserisci un'e-mail valida",
    fillTabs: "Si prega di compilare tutti i moduli ",
  },
  errors: {
    connectionLost: "Collegamento perso",
    yourConnection: "La tua connessione è stata persa",
    errorOccured: "C'era un errore",
    gameServiceError:
      "Si è verificato un errore del server di gioco. Per favore riprova più tardi",
    slotDownloadError: "Si è verificato un errore durante il download",
    loginError: "Si è verificato un errore durante l'accesso.",
    insufficientBalance: "Equilibrio insufficiente.",
    tooManyRequests: "Non puoi chiederlo spesso.",
    haveToSitAtTable: "Devi sederti al tavolo.",
    youAreBlocked: "Sei bloccato.",
    youAreBlockedTheUser: "Hai bloccato l'utente.",
    youAreUnblockedTheUser: "Hai sbloccato l'utente.",
    chipTransferOneMonthWait:
      "Il trasferimento del chip sarà possibile dopo 30 giorni",
    userNotActiveGame: "L'utente non è attualmente nel gioco",
    notEnoughLevelPlayGame:
      "Livello {level} richiesto per unirsi al tavolo {game}.",
    tableNotFound: "Tabella non trovata",
    playerIdWrong: "ID di riferimento errato",
    searchedUserNotFound: "ID di riferimento non trovato",
  },
  searchWindow: {
    searchUser: "Cerca utente",
    idOfUser: "Inserisci l'ID",
    search: "Ricerca",
    ownId: "Questo è il tuo documento d'identità",
    validId: "Inserisci un ID valido",
  },
  adBonusWindow: {
    title: "GUADAGNA CHIP GRATUITO!",
    content: "Guarda un breve video e guadagna ${money} chip!",
    watchButton: "Guarda ora",
  },
  affiliate: {
    affiliateDesc: "Inserisci l'ID referral del tuo amico",
    affiliateOffDesc: "Il tuo ID di riferimento",
    affiliate: "Affiliato",
    addAffiliate: "Aggiungi un affiliato",
    affiliateId: "Inserisci l'ID affiliato",
    clear: "Elimina",
    searchBy: "Cercato da",
    name: "Nome",
    noData: "Nessun dato",
    startDate: "Data d'inizio",
    endDate: "Data di fine",
    warning: "Diventa nostro partner e assicurati una rendita costante!",
    info: "Scopri di più su come unirti a noi.",
    failedAffiliate: "Impossibile aggiungere affiliato",
    successfullyAdded: "L'ID affiliato è stato aggiunto correttamente.",
    date_wrong: "La data di inizio deve essere precedente alla data di fine",
    excelDownloaded: "Scaricato in Documenti",
  },
  chat: {
    worldChat: "Chat globale",
    directChat: "Chat Diretta",
    onlyVip: "Solo gli utenti VIP possono inviare messaggi sulla chat globale",
    showMore: "Mostra di più",
    anyMessages: "Nessun messaggio",
  },
  disconnectedWindow: {
    disconnected: "Disconnesso",
    disconnectedServer: "Spiacenti... Sei stato disconnesso dal server!",
    reconnect: "Riconnetti",
    inactive: "Scusa... Sei stato inattivo per troppo tempo!",
    reconnected: "RICONNETTI",
    banned: "Sei stato bandito dal gioco!",
    bannedDescription: "Puoi contattarci tramite support@turngs.com",
  },

  rulesWindow: {
    texasDesc:
      "Il Texas Hold'em Poker è il gioco di carte più popolare al mondo. Lo scopo del gioco è vincere con una mano più alta rispetto agli avversari. Ci sono 2 carte nella tua mano e 5 carte sul tavolo, vince la mano che fa la migliore combinazione di 5 carte su un totale di 7 carte. Ogni mano nell'elenco sottostante batte la mano successiva. Ad esempio, Straight Flush batte Four of Kind, Straight batte Pairs. Le mani sono valutate in base ai loro poteri.",
    omahaDesc:
      "Omaha è uno dei tipi di poker più popolari al mondo. Lo scopo del gioco è vincere il piatto. Ci sono 2 modi principali per guadagnare fiches: vincere una mano di poker più alta della mano dell'avversario o aspettare il passaggio dell'avversario o rifiutare il gioco. Una mano contiene 5 carte. C'è una regola che le mani devono contenere tre delle cinque carte comuni sul tavolo e due delle quattro carte distribuite dal giocatore. Tutte le mani in una categoria nell'elenco battono tutte le mani nelle categorie sottostanti. Ogni mano nell'elenco sottostante batte la mano successiva. Ad esempio, Straight Flush batte Four of Kind, Straight batte Pairs. Le mani sono valutate in base ai loro poteri.",
    blackJackDesc:
      "Lo scopo del Turn Blackjack è avere una mano con un punteggio più alto di quello del croupier senza superare 21. La mano migliore si chiama Blackjack - il valore totale delle prime due carte distribuite in questa mano è 21. Si gioca solo contro il croupier, non contro un altro giocatore. Il blackjack si gioca con otto mazzi. Il croupier si ferma sempre a 17. Può essere raddoppiato su due carte iniziali qualsiasi. Le carte iniziali dello stesso valore possono essere divise in due. La divisione può essere eseguita da ciascuna mano. Viene distribuita una carta per ogni doppio asso diviso. Il raddoppio non può essere fatto dopo aver diviso le carte. L'assicurazione è consigliata quando il croupier ha un asso. Il blackjack paga 3:2. L'assicurazione paga 1:2. Quando le mani sono uguali, è un pareggio.",
    handNames: {
      royalFlush: "Royal Flush",
      straightFlush: "Straight Flush",
      fourOfAKind: "Four Of A Kind",
      fullHouse: "Full House",
      flush: "Flush",
      straight: "Straight",
      threeOfAKind: "Three Of A Kind",
      twoPair: "Two Pair",
      pair: "One Pair",
      highCard: "High Card",
    },
    handDesc: {
      royalFlush: "The royal flush è la mano di poker più alta.",
      straightFlush:
        "Cinque carte consecutive dello stesso seme. Tra 2 mani di Scala Colore vince chi ha la carta più alta.",
      fourOfAKind:
        "Quattro carte dello stesso valore. La combinazione più forte è quella con le carte di maggior valore. Se i giocatori hanno le stesse mani, il valore della quinta carta (kicker) determina il vincitore.",
      fullHouse:
        "Combinazione tripla e coppia. Confrontando le mani di Full House, vince il giocatore con le tre carte migliori. Se sono uguali, vince il giocatore con la coppia migliores.",
      flush:
        "Cinque carte non ordinate dello stesso seme. Se due o più giocatori hanno lo stesso seme, vince il giocatore con la carta più alta.",
      straight:
        "Cinque carte consecutive di due o più semi diversi. Tra le due mani di Scala, il vincitore è colui che ha la carta di valore più alto.",
      threeOfAKind:
        "Combinazione di tre carte dello stesso valore. Dei due trii, il vincitore è quello con le carte di valore più alto. Se i tre hanno lo stesso valore, il più alto dei due kicker rimanenti determina il vincitore.",
      twoPair:
        "Due carte dello stesso valore più due carte di un altro valore. Quando si confrontano i doppi, la coppia più alta determina il vincitore. Se le coppie alte hanno lo stesso valore, vengono confrontate le coppie basse. Se le coppie più basse hanno lo stesso valore, vince il giocatore con la quinta carta (kicker) di valore più alto.",
      pair: "Due carte dello stesso valore. Se i giocatori hanno la stessa coppia, la più alta delle tre carte rimanenti (kicker) determina il vincitore.",
      highCard:
        "Una mano di poker che non corrisponde ai requisiti delle mani descritte in precedenza. Se i giocatori hanno la stessa carta alta, la seconda carta alta (ecc.) determina il vincitore.",
    },
    expressions: {
      blinds: {
        heading: "Blinds",
        desc: "Prima che le carte vengano distribuite, il giocatore che siede alla sinistra del mazziere deve piazzare una puntata chiamata small blind. Il prossimo giocatore seduto alla sinistra del piccolo buio deve piazzare un cosiddetto grande buio. L'importo dei big blind è il doppio dell'importo dello small blind",
      },
      ante: {
        heading: "Ante",
        desc: "Se un giocatore si unisce al gioco, è seduto al tavolo e non è nella posizione del grande buio, quel giocatore piazza una puntata forzata chiamata ante. Può essere fatto una volta e la puntata ante è uguale al grande buio.",
      },
      minimumRaise: {
        heading: "Raise minimo",
        desc: "Un giocatore non può rilanciare un importo inferiore al big blind.",
      },
      allIn: {
        heading: "All In",
        desc: "Se un giocatore non ha abbastanza fiches per vedere una scommessa, il giocatore può fare 'all-in'. Il giocatore scommette con tutto ciò che resta. Se un giocatore fa all-in, il giocatore non può vincere più fiches di quelle che riesce a vedere. Se il piatto sale dopo che il giocatore fa all-in e vince, il piatto verrà diviso tra quel giocatore e il giocatore con la mano migliore successiva che potrebbe rilanciare.",
      },
      potSplit: {
        heading: "Pentola divisa",
        desc: "Se due o più giocatori sono in parità, il piatto viene diviso equamente tra di loro.",
      },
      bjGameRules: {
        heading: "Regole del gioco",
        desc: "Il gioco è gestito da un croupier e può giocare un numero illimitato di giocatori per mano. Il gioco è presentato da un croupier e si gioca con otto mazzi standard da 52 carte. I valori delle 21 carte nel gioco sono i seguenti. Le carte da 2 a 10 hanno i valori scritti su di esse. Le carte illustrate (Jacks, Queens e Kings) valgono 10 punti. Gli assi valgono 1 o 11, il giocatore può scegliere il valore appropriato in base alla sua mano. Nota che la mano soft contiene 11 Assi di valore. Allo scadere del tempo di puntata, il croupier distribuisce una carta scoperta a tutti i giocatori, l'ultimo a se stesso. Successivamente, il croupier distribuisce una seconda carta scoperta a tutti i giocatori, ma la seconda carta del croupier sarà coperta. Il valore della tua prima mano è mostrato accanto alle tue carte. Blackjack (Twenty-One) Se le due carte che ti sono state distribuite all'inizio del gioco danno come risultato 21, hai Blackjack!",
        descInsurance:
          "Se la carta scoperta del croupier è un Asso, puoi acquistare un'assicurazione contro il rischio che il croupier abbia 21, anche se la tua mano totale è 21. L'importo dell'assicurazione sarà pari alla metà della tua puntata principale e la puntata dell'assicurazione sarà elaborato separatamente dalla puntata della mano. Dopodiché, il croupier controlla il valore della sua carta coperta per verificare se ha fatto un 21. Se il croupier non fa 21, il round continua. Se il croupier ha Blackjack e tu no, il gioco finisce e il croupier vince. Se sia tu che il croupier avete 21, il gioco terminerà con un pareggio e la vostra scommessa sarà rimborsata. Nota che se la carta scoperta del croupier è un 10 o una carta colorata, avrai la possibilità di acquistare l'assicurazione e il croupier non guarderà la sua carta coperta per 21.",
        headingInsurance: "Assicurazione",
        headingHit: "Doppio, Più o In piedi",
        descHit:
          "Se il croupier scopre che dopo aver controllato le sue prime due carte non ha fatto un 21, i giocatori hanno il diritto di scegliere più carte per migliorare il valore della loro mano, dando al croupier più carte ai giocatori. Se la tua prima mano non vale 21, hai il diritto di raddoppiare la puntata. In questo caso, la tua puntata verrà raddoppiata e ti verrà data solo una carta in più da aggiungere alla tua mano. In alternativa, puoi utilizzare l'opzione Hit card per ottenere carte aggiuntive da aggiungere al valore della tua mano. Puoi colpire più carte fino a quando non sei soddisfatto del valore della tua mano e decidi di stare. Nota che un giocatore con una mano soft pari a 21 non avrà un'opzione decisionale.",
        headingSplit: "Diviso in due",
        descSplit:
          "Se la tua prima mano è composta da due carte dello stesso valore, puoi dividere la coppia in due mani diverse, dove ognuna ha una puntata uguale alla tua puntata principale. Dopo che entrambe le mani hanno ricevuto una seconda carta, puoi aumentare il valore di queste due mani applicando l'opzione Hit card. Qui puoi scegliere l'opzione Stand quando sei soddisfatto del valore di entrambe le mani. Tuttavia, quando dividi la prima coppia di Ase, entrambe le mani riceveranno automaticamente una carta aggiuntiva e non avranno la possibilità di ottenere un'altra carta.",
      },
    },
    actions: {
      desc: "Durante un giro di puntate, il giocatore può compiere una delle seguenti azioni.",
      call: {
        heading: "Call",
        desc: "Se è stata fatta una scommessa, vederla significa che un giocatore deve coprire la scommessa. Ad esempio, se il giocatore A punta 10 e il giocatore B lo vede, il giocatore B deve coprire la sua puntata di 10 e piazzare un 10 nel piatto.",
      },
      check: {
        heading: "Check",
        desc: "Un giocatore può giocare un assegno se non sono state effettuate puntate nel round in corso. L'azione verrà spostata al giocatore successivo a sinistra. L'assegno può essere interpretato come una sorta di lasciapassare; il giocatore può rimanere in gioco ma decidere di non scommettere sul round in corso.",
      },
      raise: {
        heading: "Raise",
        desc: "Un giocatore può aumentare la dimensione attuale della scommessa. Per fare questo, la scommessa deve almeno raddoppiare la scommessa del giocatore precedente. Per continuare il pot fight, tutti i giocatori seguenti devono vedere questa puntata, rilanciarla di nuovo o passare.",
      },
      fold: {
        heading: "Fold",
        desc: "Un fold è un rifiuto di lottare per il piatto attuale. Se un giocatore passa, le sue carte non vengono distribuite e il giocatore non può vincere nel round in corso.",
      },
    },
    states: {
      winning: {
        heading: "Vincente",
        desc: "Uno o più giocatori con la mano vincente devono mostrare le proprie carte coperte.",
      },
      aggressivePlayer: {
        heading: "Giocatore aggressivo",
        desc: "Il giocatore aggressivo è l'ultimo giocatore a rilanciare durante la mano. Se la puntata viene chiamata e la mano finisce con una rivelazione, il giocatore aggressivo deve mostrare le sue carte coperte indipendentemente dall'esito della mano.",
      },
      allInPlayer: {
        heading: "ALL-In Player",
        desc: "Se i giocatori puntano su tutte le fiches, tutti hanno fatto all-in e la mano alla fine finisce con una carta rivelatrice. I giocatori aggressivi devono mostrare le loro carte coperte indipendentemente dall'esito della mano.",
      },
      desc: "Se uno o più giocatori giocano all-in e non sono state piazzate ulteriori puntate, tutti gli altri giocatori devono mostrare le proprie carte coperte. In caso contrario, i giocatori che continuano a rilanciare e generare il piatto devono mostrare le proprie carte al giocatore aggressivo e il vincitore sarà determinato dalle regole.",
      blackJackResult: {
        heading: "Vincente",
        desc: "Se il valore totale della tua mano supera 21, bruci e perderai la tua scommessa su quella mano. Dopo che il turno di tutti gli utenti è passato, il croupier mostra il valore della sua carta coperta. Se la mano del croupier è 16 o meno, il croupier dovrebbe scegliere l'opzione carta Hit, ma se quella mano è un 17 soft o superiore, il croupier deve fermarsi scegliendo l'opzione Stand (nota che la mano soft contiene un valore Ase di 11 ).Alla fine, vinci quando il valore della tua mano è più vicino a 21 rispetto alla mano del croupier, o quando il croupier brucia. Se la tua mano ha lo stesso valore di quella del croupier, il round di gioco termina con un pareggio e la tua scommessa viene rimborsata. Gioca con le opzioni Side Bet, vinci fino a '100x'.",
      },
    },
  },

  updateScene: {
    downloading: "Download della nuova versione...",
    installing: "Installazione...",
    installedVersion: "La nuova versione è installata",
    currentVersion: "Versione attuale",
    newVersion: "Nuova versione", //NEW************
  },

  actionTypes: {
    call: "Call",
    check: "Check",
    fold: "Fold",
    raise: "Raise",
    allin: "All-in",
  },

  tableQuickMenu: {
    standUp: "In piedi",
    goToLobby: "Vai nell'atrio",
    soundOn: "Suono attiva",
    soundOff: "Audio disattivato",
    vibrationOn: "Vibrazione attiva",
    vibrationOff: "Vibrazione disattivata",
    animatedGiftsOn: "Mostra emoticon",
    animatedGiftsOff: "Nascondi le emoticon",
    watchWin: "Annunci",
  },

  actionPanel: {
    check: "Check",
    call: "Call",
    fold: "Fold",
    raise: "Raise",
    allin: "All-In",
    callAny: "Call Any",
    checkFold: "Check/Fold",
  },

  selectPotWindow: {
    autoPurchase: "Compilazione automatica",
    autoPurchaseDescription:
      "Ricarica automaticamente quando finisci le chips.",
    autoCharging: "Ricarica automatica",
    autoChargingDescription:
      "Ricarica automaticamente quando il tuo stack è inferiore al 70%.",
    sit: "Sedersi",
    minEntry: "Ingresso minimo",
    maxEntry: "Ingresso massimo",
    transfer: "Trasferimento",
    setQuantity: "IMPOSTA IL TUO CAPITALE",
  },

  quickWindow: {
    quickChat: "Chat veloci.",
    readyMessage: "Messaggi pronti",
    messageAim: "Modo veloce per chattare con l'utente.",
    quickEmoji: "Emoji veloci",
  },

  tipButton: {
    thankYou: "Grazie",
  },

  fastChatMessages: [
    "Buona fortuna!",
    "Congratulazioni!",
    "Grazie",
    "Bluffare!",
    "Per favore, gioca più veloce!",
    "Novizio!",
    "Bel gioco, ben fatto!",
    "Così fortunato!",
    "Vacci piano campione!",
  ],

  chatWindow: {
    tabs: {
      chat: "Chat",
      dealer: "Dealer",
      mute: "Muto",
    },
    audiences: "Pubblico",
    players: "Giocatori",
    typeHere: "Digitare qui...",
    xSecondsLeft: "{seconds} secondi rimanenti.",
  },

  dealerChat: {
    call: "{username} called",
    check: "{username} checked",
    raise: "{username} raised {amount}",
    fold: "{username} folded",
    allin: "{username} is all-in",
    win: "{username} {amount} ha vinto",
    winWithCards: "{hand} {username}, con {amount} ha vinto",
    winWithCardIcons: "{username}, {cards}({hand}) con {amount} vinto",
  },

  systemMessages: {
    roomClosing:
      "Il server si riavvierà tra 2 minuti. Si giocano le ultime mani.",
    teamTableAlert:
      "Questo è un tavolo di squadra! Più account possono sedersi e rilanciare. Non vi è alcuna restrizione (divieto).",
    giftSentChatMessage: "{from} ha inviato un regalo {gift_name} a {to}.",
    inviteRequestSuccess: "Invito inviato con successo.",
  },
  blackJack: {
    hit: "Più",
    stand: "In piedi",
    InsufficientChips: "Chip insufficienti",
    undo: "ANNULLARE",
    double: "DOPPIO",
    repeat: "RIPETERE",
    xSecondsLeft: "{seconds} secondi rimanenti.",
    gameHistory: "Storia del gioco",
    croupier: "Dealer",
    you: "Voi",
    main: "Albero",
    anyPair: "Any Pair",
    result: "Risultato",
    previousGame: "Gioco precedente",
    returnToList: "Torna all'elenco",
    nextGame: "Gioco Prossima",
    type: "Genere",
    pay: "Paga",
    winningsHands: "Mani vincenti",
    rules: "Regole",
    blackjactSitInfo:
      "Il gioco si gioca contro il banco. Un numero illimitato di giocatori può giocare contro il banco.",
    bjRules: `
          * Lo scopo del Turn Blackjack è di avere una mano con un punteggio più alto del croupier senza superare il 21; <br>
           * La mano migliore si chiama Blackjack - il valore totale delle prime due carte distribuite in questa mano è 21;<br>
           * Si gioca solo contro il croupier, non contro altri giocatori;<br>
           * Il blackjack si gioca con otto mazzi;<br>
           * Il croupier si ferma sempre a Soft 17;<br>
           * Può essere raddoppiato su due carte iniziali qualsiasi;<br>
           * Le carte iniziali dello stesso valore possono essere divise in due;<br>
           * La divisione può essere eseguita da ciascuna mano;<br>
           * Viene distribuita una carta per ogni doppio asso diviso;<br>
           * Il raddoppio non può essere effettuato dopo la divisione delle carte;<br>
           * L'assicurazione è consigliata quando il croupier ha un asso;<br>
           * Il blackjack paga 3:2;<br>
           * Il Blackjack Bonus paga 3:2 alla fine della mano;<br>
           * L'assicurazione paga 1:2;<br>
           * Quando le mani sono uguali, è un pareggio.<br>
 
 
            <hr>
 
            <strong>Regole del gioco</strong><br>
 
            * Il gioco si gioca contro il banco. Un numero illimitato di giocatori può giocare contro il banco.<br>
             * Il gioco è presentato da un croupier e si gioca con otto mazzi standard da 52 carte. I valori delle 21 carte nel gioco sono i seguenti:<br>
             - Le carte da 2 a 10 hanno i valori scritti sopra.<br>
             - Le carte illustrate (jack, regine e re) valgono 10 punti.<br>
             - Gli assi valgono 1 o 11, il giocatore può scegliere il valore appropriato in base alla sua mano. Nota che la mano soft contiene 11 Assi di valore;<br>
             * Allo scadere del tempo di puntata, il croupier distribuisce una carta scoperta a tutti i giocatori, l'ultimo a se stesso. Successivamente, il croupier distribuisce una seconda carta scoperta a tutti i giocatori, ma la seconda carta del croupier sarà coperta. Il valore della tua prima mano è mostrato accanto alle tue carte.<br>
             * Blackjack (Ventuno) = Se le due carte che ti sono state distribuite all'inizio del gioco danno come somma 21, hai Blackjack!<br>
             <br>
 
            <strong>Assicurazione</strong>
             <br>
             * Se la carta scoperta del croupier è un Asso, puoi acquistare un'assicurazione contro il rischio che il croupier abbia 21, anche se la tua mano totale è 21. L'importo dell'assicurazione sarà pari alla metà della tua puntata principale e la puntata dell'assicurazione sarà essere elaborato separatamente dalla puntata della mano. Successivamente, il croupier controlla il valore della sua carta coperta per verificare se ha realizzato un 21.<br>
             - Se il croupier non fa 21, il giro continua. <br>
             - Se il croupier ha Blackjack e tu no, il gioco finisce e il croupier vince.<br>
             - Se sia tu che il croupier avete 21, il gioco finirà in parità e la tua scommessa sarà rimborsata.<br>
             <br>
             Nota che se la carta scoperta del croupier è un 10 o una carta colorata, avrai la possibilità di acquistare l'assicurazione e il croupier non guarderà la sua carta coperta per 21.
             <br>
 
            <strong>Doppio, Più o In piedi</strong>
            <br>
            Se il croupier scopre che dopo aver controllato le sue prime due carte non ha fatto un 21, i giocatori hanno il diritto di scegliere più carte per migliorare il valore della loro mano, dando al croupier più carte ai giocatori.
             <br>
             Se la tua prima mano non vale 21, hai il diritto di raddoppiare la puntata. In questo caso, la tua puntata verrà raddoppiata e ti verrà data solo una carta in più da aggiungere alla tua mano. In alternativa, puoi utilizzare l'opzione Hit card per ottenere carte aggiuntive da aggiungere al valore della tua mano. Puoi colpire più carte fino a quando non sei soddisfatto del valore della tua mano e decidi di stare.
             <br>
             Nota che un giocatore con una mano soft pari a 21 non avrà un'opzione decisionale.
             <br>
 
 
            <strong>Diviso in due</strong><br>
            <br>
           Se la tua prima mano è composta da due carte dello stesso valore, puoi dividere la coppia in due mani diverse, dove ognuna ha una puntata uguale alla tua puntata principale. Dopo che entrambe le mani hanno ricevuto una seconda carta, puoi aumentare il valore di queste due mani applicando l'opzione Hit card. Qui puoi scegliere l'opzione Stand quando sei soddisfatto del valore di entrambe le mani. Tuttavia, quando dividi la prima coppia di Ase, entrambe le mani riceveranno automaticamente una carta aggiuntiva e non avranno la possibilità di ottenere un'altra carta.
            <br>
            <strong>Risultato</strong>
            <br>
           Se il valore totale della tua mano supera 21, bruci e perderai la tua scommessa su quella mano.
            <br>
            Dopo che il turno di tutti gli utenti è passato, il croupier mostra il valore della sua carta coperta. Se la mano del croupier è 16 o meno, il croupier dovrebbe scegliere l'opzione carta Hit, ma se quella mano è un 17 soft o superiore, il croupier deve fermarsi scegliendo l'opzione Stand (nota che la mano soft contiene un valore Ase di 11 ).
            <br>
           Alla fine, vinci quando il valore della tua mano è più vicino a 21 della mano del croupier, o quando il croupier brucia. Se la tua mano ha lo stesso valore di quella del croupier, il round di gioco termina con un pareggio e la tua scommessa viene rimborsata.
             <br>Gioca con le opzioni Side Bet, vinci fino a "100x"
 .`,
    settings: "Impostazioni",
    sounds: "Suono",
    bet: "SCOMMESSA",
    waitOtherPlayers: "Si prega di attendere altri giocatori",
    placeYourBet: "Piazza la tua scommessa",
    choose: "Scegliere",
    wantInsurance: "Hai bisogno di un'assicurazione?",
    draw: "PARI",
    busted: "BRUCIATO",
    youLost: "Hai perso",
    sit: "SEDERSI",
    header: "Inserisci le chips dalla custodia principale",
    otoPurchase: "Compilazione automatica",
    otoPurchaseText:
      "Se effettui nuovamente l'accesso, il tuo stack verrà ripristinato",
    okay: "BENE",
    insufficientBalance: "Equilibrio insufficiente",
    ShufflingCards: "Mescola il mazzo di carte....",
    youWon: "Hai vinto",
    won: "HA VINTO",
    dealNow: "Distribuire ora",
  },
  useInviteCode: {
    heading: "Codice invito",
    description: "Inserisci il codice di invito del tuo amico.",
    unset: "Rimuovi il codice di invito",
    inviteCodeNotFound: "Codice di invito errato.",
  },
  progressiveJackpot: {
    join: "Giunto",
    desc1: "Paghi fiches pari allo small blind per mano.",
    desc2:
      "Quando vinci, riceverai un importo pari a SMALL BLIND x COEFFICIENTE.",
    desc3: "Chip raccolti dallo stesso livello di scommessa",
  },
  pinCode: {
    wrong: "Il codice pin è sbagliato!",
    pinCode: "Codice PIN",
    removePinCode: "Rimuovi il codice pin", //NEW************
    enterPin: "Inserisci il codice pin",
    reset: "Reimposta la mia password!",
    logOut: "DISCONNETTERSI",
    resetPin: "RESET CODICE PIN",
    enterEmail: "Inserisci il tuo indirizzo email",
    mail: "Email",
    newPass: "La nuova password è stata inviata alla tua email",
    wrongMail: "L'email è sbagliata",
    failRemove: "Impossibile rimuovere il codice PIN",
    failAdd: "Impossibile salvare il codice PIN",
  },
  redeem: {
    used: "Scheda di gioco già utilizzata!",
    expired: "Scheda di gioco scaduta!",
    failed: "Impossibile convalidare la scheda di gioco.",
    validGameCard: "Inserisci una scheda di gioco valida", // NEW*********
  },
  level: {
    levelUpToastText:
      "Congratulazioni! Hai raggiunto il livello {levelNumber}. <strong>Ricompensa: {reward}</strong>",
  },
  inviteFriend: {
    inviteFriendText:
      "Hai invitato il tuo amico ed entrambi avete ricevuto 10B!",
  },
  slotWinnerToast: {
    bigWinText: "{username} ha vinto {amount} allo slot!",
  },
  toast: {
    inviteRequestText: "{name} ti invita a giocare a {game}.",
  },
  updatedNotes: {
    title: "Aggiorna le note",
    notes: [
      "<div class='center'><span class='title'>Poker</span></div>",
      "Il suono di sottofondo è disattivato per impostazione predefinita",
      "50 giocatori nella lista dei leader",
      "Accedi alle funzioni 'Cerca' e 'Cronologia mani' sul tavolo",
      "Solo i giocatori che giocano al tavolo possono scrivere chat in-game",
      "La fantastica chat di gioco trasparente è sul lato sinistro",
      "Esegui un'azione (Call/Fold/Check/Raise) mentre la chat è aperta;",
      "Fantastico conto alla rovescia",
      "<div class='center'><span class='title'>Blackjack</span></div>",
      "Carte e testo ingranditi accoglienti",
      "Risoluzione del problema",
    ],
  },
  forceUpdateWindow: {
    title: "È disponibile una nuova versione!",
    text: "Si prega di aggiornare l'app all'ultima versione.",
  },
  table: {
    noHaveOnlineFriends: "Nessun amico online",
    onlineFriends: "Amici online",
  },
  profileSettings: {
    profileSettings: " Impostazioni del profilo ",
    registrationDate: "Data di registrazione",
    use: "USO",
    gift: "REGALO",
    buyVip: "Acquista VIP",
    onlyVip: " Solo i giocatori VIP possono acquistare questo regalo.",
    frame: "Cornice",
    buy: "ACQUISTARE",
    statistics: {
      statistics: "Statistiche",
      handPlayed: "Mano giocata",
      biggestPotWon: "Pentola più grande",
      biggestJackPotWon: "Il jackpot più grande",
      recordChip: "Record di chip",
      brokenPotCounter: "Contapentole",
      winPercentage: "Tasso di vincita",
      leadershipRanking: "Classifica",
      bestWinningHand: "Miglior mano vincente",
    },
  },
  friends: {
    online: "ONLINE",
    goToTable: "Giunto",
    chipTransfer: "Trasferimento di trucioli",
    friendRequests: " Richiesta di amicizia",
    incoming: "ARRIVO",
    sent: "SPEDITO",
    search: "RICERCA",
    addButtonText: "Aggiungi amico",
    removeFriendNotification: "Rimosso dall'elenco degli amici",
  },
  friendDetail: {
    sendChipText: "Invia chip",
  },
  settings: {
    lobbyMusic: "Musica lobby",
    soundEffects: "FX sonori",
    vibration: "Vibrare",
    blockInvites: "Inviti",
    giftHide: "Espressioni",
    handStrengthIndicator: "Indicatore di mano",
    graphicsPreferences: "Preferenze grafiche",
    tableColor: "Colore tavolo",
    roomBgColor: "Sfondo",
    languagePreference: "Lingua",
    termsOfUse: "Termini di utilizzo",
    accountInfo: "Informazioni sull'account",
    profileID: "ID giocatore",
    deviceNO: "Dispositivo",
    deleteAccount: "Elimina l'account",
    language: {
      turkish: "Turco",
      english: "Inglese",
      italian: "Italiano",
      indonesian: "Indonesiano",
      portuguese: "portoghese",
      russian: "Russo",
      malesian: "Malese",
    },
  },
  vip: {
    available: "VIP / {day} Giorni",
    noVip: "VIP non è disponibile",
  },
  notification: {
    noData: "Non hai alcuna notifica.",
  },
  userProfile: {
    noteUpdated: "La tua nota del profilo è stata aggiornata",
  },
  others: {
    friendRemovedInfo: "L'amico è stato rimosso",
    notFoundFriend: "Amico non è stato trovato",
    notFoundDisplay: "Utente non trovato",
    friendTableNotFound: "Tavolo dell'amico non trovato",
    tableInFriendNotFound: "Nessun giocatore sul tavolo",
    requiredSelectedGift: "Seleziona Regalo",
    currentLocationNotAvailableBuy:
      "L'acquisto non è disponibile per il tuo paese",
    requiredLevelFive: "È richiesto il livello 5",
    blackjackStatusInactive: "Il blackjack non è disponibile",
    slotStatusInactive: "Lo slot non è disponibile",
    muteAll: "Muto",
    changeNameSuccesInfo: "Nome utente cambiato Ti rimangono {0}",
    guestClose: "Non puoi accedere come ospite. Accedi con un altro account",
  },
};
