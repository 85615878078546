import {
  Adjust,
  AdjustConfig,
  AdjustEnvironment,
  AdjustLogLevel,
  AdjustEvent,
} from "@awesome-cordova-plugins/adjust";
import store from "@/store";

const AdjustManager = {
  init() {
    let adjustConfig = new AdjustConfig(
      `${
        store.getters.getRealPlatform === "ios"
          ? "9jbsiiqld91c"
          : "fp8vo89mu9z4"
      }`,
      process.env.NODE_ENV === "development"
        ? AdjustEnvironment.Sandbox
        : AdjustEnvironment.Production
    );
    adjustConfig.setLogLevel(AdjustLogLevel.Verbose);
    return Adjust.create(adjustConfig);
  },
  checkForNewAttStatus() {
    return Adjust.checkForNewAttStatus();
  },
  updateConversionValue(value) {
    Adjust.updateConversionValue(value);
  },
  trackEvent(eventName, price = 0) {
    if (store.getters.getRealPlatform === "web") {
      return;
    }

    if (price) {
      let adjustEvent = new AdjustEvent(
        `${eventCode[store.getters.getRealPlatform][eventName]}`
      );
      adjustEvent.setRevenue(price, "TRY");
      return Adjust.trackEvent(adjustEvent);
    } else {
      let adjustEvent = new AdjustEvent(
        `${eventCode[store.getters.getRealPlatform][eventName]}`
      );
      return Adjust.trackEvent(adjustEvent);
    }
  },
};

const eventCode = {
  android: {
    ClickBamboPanda: "obm4pg",
    ClickBlackjack: "ed08qk",
    ClickBlackjackStatistics: "p8u32o",
    ClickBuyVipAndInsufficientChips: "vtweme",
    ClickBuyVipAndSuccessful: "nq5zt7",
    ClickDailyBonus: "ywtm15",
    ClickDeleteAccount: "merbyn",
    ClickFafafa: "tc8doi",
    ClickGoToAvatar: "b4iuhz",
    ClickGoToCase: "20o4cx",
    ClickGoToChat: "v04lv2",
    ClickGoToFrame: "6n6yyc",
    ClickGoToFriends: "bl34ih",
    ClickGoToHandHistory: "19n382",
    ClickGoToReference: "j1f9sd",
    ClickGoToRules: "bn7ore",
    ClickGoToSearchPlayer: "i8fuz8",
    ClickGoToSecurity: "7glxrk",
    ClickGoToSettings: "s3i16c",
    ClickGoToSocial: "tjykxt",
    ClickGoToSpecialForYou: "858v4v",
    ClickIdCopy: "93k5yx",
    ClickLogout: "ha8vp5",
    ClickOmahaPlayNow: "7ng054",
    ClickOmahaTableList: "og9uym",
    ClickOpenProfile: "mn7iz2",
    ClickPlayerDetailShopWindowOn: "6pcgsr",
    ClickSlotsStatistics: "aepf85",
    ClickTexasHoldemPlayNow: "6p3l98",
    ClickTexasHoldemStatistics: "j8npgr",
    ClickTexasHoldemTableList: "vcl54b",
    ClickTurnLegends: "xq6q0b",
    ClickTurnOfOlympus: "f2rq7b",
    FacebookLogin: "ay6nmz",
    GoogleLogin: "34n4zu",
    GuestLogin: "8g9urs",
    HandHistoryGameChange: "ip5u54",
    LeadersWindowOn: "52k53x",
    LobbyScreenOn: "5tkb19",
    LoginScreenOn: "xgtdnj",
    NotificationWindowOn: "qrpeh6",
    PlayerChipAccountToCase: "2owszv",
    PlayerChipCaseToAccount: "fwk6g4",
    ShopWindowClickBuyChip: "lyerr8",
    ShopWindowClickBuyVip: "nvynpf",
    ShopWindowOn: "frqrlv",
    SlotWindowOn: "6x253l",
    UserChipTransferToUser: "fwoz35",
    PokerScreenOn: "5izrhk",
    ClickGoToStatistics: "rw33pg",
    SearchAndFindPlayer: "s3jdl2",
    OtherPlayerChatClick: "xjusrw",
    OtherPlayerAddFriendClick: "k1ygcm",
    OtherPlayerSendChipClick: "mzp5ay",
    OtherPlayerCopyIdClick: "nzx4w2",
    AffiliateConfirmSuccess: "asri8w",
    MyInviteCodeCopy: "wqiojm",
    RedeemCodeSuccess: "pyandi",
    SocialWindowFacebookClick: "w1wa9k",
    SocialWindowInstagramClick: "6wbbiq",
    SocialWindowTwitterClick: "jw1tdv",
    SocialWindowYoutubeClick: "dxaety",
    SocialWindowTelegramClick: "h48fl1",
    ADBonusWatchVideo: "f85ang",
    BlackjackSitButtonClick: "evxkka",
    BlackjackChatClick: "o4wyae",
    ClickBuyChip30B: "wmaags",
    ClickBuyChip75B: "t81lpu",
    ClickBuyChip250B: "p41fv7",
    ClickBuyChip500B: "v6owxd",
    ClickBuyChip1T: "1l1rwk",
    ClickBuyChip5T: "i9nddb",
  },
  ios: {
    AppleLogin: "54woag",
    ClickBamboPanda: "yzdl07",
    ClickBlackjack: "frraxn",
    ClickBlackjackStatistics: "8v2mp5",
    ClickBuyVipAndInsufficientChips: "yid25m",
    ClickBuyVipAndSuccessful: "r68jgw",
    ClickDailyBonus: "zewswt",
    ClickDeleteAccount: "5wmzn2",
    ClickFafafa: "ptwhap",
    ClickGoToAvatar: "k0it4l",
    ClickGoToCase: "8ijb8t",
    ClickGoToChat: "g94eim",
    ClickGoToFrame: "aml20c",
    ClickGoToFriends: "e1pq0n",
    ClickGoToHandHistory: "qprk5v",
    ClickGoToReference: "r894eg",
    ClickGoToRules: "270p7q",
    ClickGoToSearchPlayer: "ud15n3",
    ClickGoToSecurity: "pgvau5",
    ClickGoToSettings: "1brblo",
    ClickGoToSocial: "mj5nwk",
    ClickGoToSpecialForYou: "fn54lg",
    ClickIdCopy: "w12nmb",
    ClickLogout: "n337qx",
    ClickOmahaPlayNow: "qjwjjr",
    ClickOmahaTableList: "x9u831",
    ClickOpenProfile: "voyp72",
    ClickPlayerDetailShopWindowOn: "141hz6",
    ClickSlotsStatistics: "5ak9w6",
    ClickTexasHoldemPlayNow: "too33w",
    ClickTexasHoldemStatistics: "mqxnz8",
    ClickTexasHoldemTableList: "sxgdra",
    ClickTurnLegends: "7wc4zw",
    ClickTurnOfOlympus: "8q6b1v",
    FacebookLogin: "q9bfqd",
    GoogleLogin: "1ca4qq",
    GuestLogin: "al0fax",
    HandHistoryGameChange: "qa32ub",
    LeadersWindowOn: "gk3a6l",
    LobbyScreenOn: "yt2yr5",
    LoginScreenOn: "x8ljgn",
    NotificationWindowOn: "i5j9kc",
    PlayerChipAccountToCase: "dlf33f",
    PlayerChipCaseToAccount: "ao0e0s",
    ShopWindowClickBuyChip: "cmh1s5",
    ShopWindowClickBuyVip: "kk4qf2",
    ShopWindowOn: "16q1c5",
    SlotWindowOn: "n7gmxd",
    UserChipTransferToUser: "d5ipb0",
    PokerScreenOn: "munmc2",
    ClickGoToStatistics: "rmbalj",
    SearchAndFindPlayer: "lzy3bs",
    OtherPlayerChatClick: "nl6e64",
    OtherPlayerAddFriendClick: "a71pg9",
    OtherPlayerSendChipClick: "hjvj8g",
    OtherPlayerCopyIdClick: "q8acuo",
    AffiliateConfirmSuccess: "siqfw8",
    MyInviteCodeCopy: "uqqyr0",
    RedeemCodeSuccess: "wgbsjh",
    SocialWindowFacebookClick: "qzeneq",
    SocialWindowInstagramClick: "kj5wum",
    SocialWindowTwitterClick: "wf5od0",
    SocialWindowYoutubeClick: "2al54x",
    SocialWindowTelegramClick: "98vlvs",
    ADBonusWatchVideo: "4ag0v4",
    BlackjackSitButtonClick: "4mch5l",
    BlackjackChatClick: "axbr23",
    ClickBuyChip30B: "o6t9y2",
    ClickBuyChip75B: "pae65p",
    ClickBuyChip250B: "8lrzky",
    ClickBuyChip500B: "vdsmpc",
    ClickBuyChip1T: "9l5dh4",
    ClickBuyChip5T: "3lknsq",
  },
};

export default AdjustManager;
