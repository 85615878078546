<template>
  <div class="player-details">
    <div class="lobby-profile-panel">
      <div class="left-side">
        <Avatar
          class="player-details-avatar"
          :url="user.avatar"
          :crown="crownCondition(user.crown, user.vip.status)"
          size="132"
          classes="no-click-outside"
          @click.native="showUserDetails"
        />
      </div>

      <div class="right-side">
        <div class="player-details-name">{{ user.name }}</div>
        <div class="level-bar-container">
          <div class="level-bar lobby-level-bar">
            <div class="level-bar-info-text">
              {{ `${getCurrentLevelProgress} / ${getCurrentLevelMax}` }}
            </div>
            <div
              class="lobby-sprite level-bar-full level-bar-fill-progress"
              :style="getLevelBarFillStyles"
            ></div>
          </div>
          <div class="lobby-sprite profile-star"></div>
          <div class="level-text">Level {{ getLevelInfo.number }}</div>
        </div>
      </div>
    </div>

    <!-- <div class="lobby-sprite money-box">
      <div class="animation" v-show="$store.state.animations.moneyBox">
        <div class="animation-chip"></div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import AnalyticsEvents from "@/plugins/analytics/events";
import Helpers from "@/mixin/Helpers";
import { searchElementsSetProperty } from "@/helpers";
import Avatar from "@/components/Misc/Avatar";

export default {
  name: "PlayerDetails",
  mixins: [Helpers],
  data() {
    return {};
  },
  watch: {
    "$store.state.animations.moneyBox": {
      handler(newVal) {
        const el = document.querySelector(".animation-chip");
        if (!el) {
          return;
        }
        if (newVal) {
          this.moneyBoxAnimationPlay();
        } else {
          this.moneyBoxAnimationStop();
        }
      },
      immediate: true,
    },
  },
  components: {
    Avatar,
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["getShortChipFormat", "user/getLevel"]),
    getLevelInfo() {
      return this["user/getLevel"](this.user.xp);
    },
    getCurrentLevelProgress() {
      return (
        this.getLevelInfo.currentXp - this.getLevelInfo.prevLevelRequiredXp
      );
    },
    getCurrentLevelMax() {
      return (
        this.getLevelInfo.requiredXp - this.getLevelInfo.prevLevelRequiredXp
      );
    },
    getLevelBarFillStyles() {
      const percent = Math.floor(
        (this.getCurrentLevelProgress * 100) / this.getCurrentLevelMax
      );
      return { width: `${percent}%` };
    },
  },
  methods: {
    showUserDetails() {
      this.$store.commit("modals/show", "userDetails");
      this.$adjust.trackEvent("ClickOmahaTableList");
    },
    showShopWindow() {
      this.$store.commit("modals/show", "shop");
    },
    moneyBoxAnimationPlay() {
      searchElementsSetProperty(".animation-chip", [
        { property: "display", value: "block" },
        { property: "translateY", value: "0px" },
      ]);
      this.$animejs({
        targets: ".animation-chip",
        translateY: [
          { value: 0, duration: 0 },
          { value: 80, duration: 750 },
          { value: 80, duration: 1250 },
        ],
        loop: true,
        easing: "easeInOutQuad",
      });
    },
    moneyBoxAnimationStop() {
      searchElementsSetProperty(".animation-chip", [
        { property: "display", value: "none" },
      ]);
    },
  },
  mounted() {
    this.moneyBoxAnimationPlay();
  },
};
</script>

<style scoped lang="scss">
@keyframes chipIconFlipAnimation {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.15);
  }
  20% {
    transform: scale(1);
  }
}

.player-details {
  position: relative;
  transform: translate3d(0.1px, 0.1px, 0.1px);
  backface-visibility: hidden;
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .lobby-profile-panel {
    width: 556px;
    height: 188px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 17px;
    .left-side {
      width: 25%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .player-details-avatar {
        position: absolute;
        top: 30px;
        left: 30px;
      }
    }
    .right-side {
      width: 65%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .player-details-name {
        border-radius: 10px;
        width: 300px;
        display: flex;
        align-items: flex-start;
        max-width: 330px;
        height: 60px;
        line-height: 55px;
        margin-left: 2px;
        font-size: 38px;
        font-weight: bold;
        text-align: center;
        text-shadow: 0 1px 3px black;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-transform: uppercase;
      }
      .level-bar-container {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;
        .profile-star {
          position: absolute;
          left: -20px;
          top: -5px;
        }
        .level-text {
          font-size: 28px;
          margin-top: 10px;
          margin-left: -5px;
          text-shadow: 0 2px 2px black;
        }
        .level-bar {
          position: relative;
          width: 240px;
          height: 30px;
          background-color: rgba(black, 0.6);
          border-radius: 20px;
          .level-bar-info-text {
            position: absolute;
            top: -2px;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            font-size: 24px;
            text-shadow: 0 2px 3px black;
          }
          .level-bar-fill-progress {
            width: 1%;
            min-width: 1%;
            transition: all 0.25s ease;
          }
        }
      }
    }
  }

  // .money-box {
  //   position: absolute;
  //   top: 75px;
  //   left: 475px;
  //   overflow: visible;
  //   .animation {
  //     height: 80px;
  //     width: 80px;
  //     position: absolute;
  //     left: 0px;
  //     bottom: 47px;
  //     overflow: hidden;
  //     .animation-chip {
  //       background: url("~@/assets/images/animations/animation-chip.png")
  //           no-repeat 50% 50%,
  //         transparent;
  //       background-size: contain;
  //       height: 50px;
  //       width: 50px;
  //       margin: auto;
  //     }
  //   }
  // }
}
</style>
