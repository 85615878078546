<template>
  <div class="pot" :class="getClasses">
    <div class="pot-inner" v-show="$props.data.amount > 0">
      <div
        class="icon"
        :style="getSeatIconStyles"
        :class="[
          ...potIconClass,
          `icon-${
            chipIsMeAndRaise
              ? 'raise'
              : lastActionText == null && type !== 'seat'
              ? 'table'
              : type == 'table'
              ? 'table'
              : lastActionText
          }`,
        ]"
      >
        <CheckMark
          class="svg-check"
          v-if="
            (lastActionText == 'check' ||
              lastActionText == null ||
              lastActionText == undefined) &&
            lastActionText !== 'raise' &&
            lastActionText !== 'allin' &&
            type != 'table' &&
            !chipIsMeAndRaise
          "
        />
        <ChevronDown
          class="chevron-down"
          v-if="
            (type != 'table' &&
              (lastActionText === 'raise' || lastActionText === 'allin')) ||
            chipIsMeAndRaise
          "
        />

        <!-- <div
          v-for="(stack, i) in getStacks($props.data.amount)"
          :key="'stack-' + i"
          :class="'stack stack-' + i"
        >
          <div
            v-for="(pot, i) in stack"
            :key="'pot-icon-' + i"
            :class="['pot-icon pot-icon-' + i, getPotIconClass(pot)]"
          />
        </div> -->
      </div>
      <div
        class="text"
        :class="`text-${
          chipIsMeAndRaise
            ? 'raise'
            : lastActionText
            ? lastActionText
            : type === 'seat'
            ? 'check'
            : 'table'
        }`"
      >
        {{ getShortChipFormat($props.data.amount) }}
      </div>
    </div>
    <div class="pot-animations">
      <div
        class="pot-inner"
        v-for="(animation, animationIndex) in animations"
        :key="'animation-' + animation._id + '-' + animationIndex"
      >
        <div class="icon">
          <div
            v-for="(stack, i) in animation.stacks"
            :key="'stack-' + i"
            :class="['stack']"
          >
            <div
              v-for="(pot, i) in stack"
              :key="'pot-icon-' + i"
              :class="[
                'pot-icon sprite-table chip',
                `index-${$props.index}`,
                `type-${animation.type}`,
                `to-${animation.to}`,
                animation.animate ? `animate` : '',
              ]"
              :style="getAnimationStyle(i)"
            />
          </div>
        </div>
        <div class="text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import chipActions from "@/constants/game/poker/playerChipLastAction";
import EventBus from "@/event-bus";
import { mapGetters } from "vuex";

export default {
  name: "Pot",
  components: {
    CheckMark: () => import("@/components/Icons/CheckMark"),
    ChevronDown: () => import("@/components/Icons/ChevronDown"),
  },
  props: {
    index: {
      type: Number,
      default: 0,
      required: true,
    },
    realIndex: {
      type: Number,
      default: 0,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "seat",
      required: false,
    },
  },
  watch: {
    "$props.data.amount": function (currentAmount, oldAmount) {
      if (this.$props.type === "table") return false;

      if (currentAmount <= 0 && oldAmount > 0) {
        this.onPotGoingToTablePot(oldAmount);
        return;
      }

      if (oldAmount === 0 && currentAmount > 0)
        this.onPotComingFromSeat(currentAmount);
    },
    "$props.data.winners": function (winners, oldWinners) {
      if (typeof winners === "undefined" || typeof oldWinners === "undefined")
        return false;
      if (winners.length <= 0) return false;
      if (winners.length === oldWinners.length) return false;
      this.onWinnerAnnounced(winners);
    },
    // "nGetSeats.index.lastAction": {
    //   handler(val) {
    //     console.log("watch val", val);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  computed: {
    ...mapGetters({
      nGetSeats: "service/nGetSeats",
    }),
    ...mapGetters([
      "getShortChipFormat",
      "service/nGetMySeat",
      "service/nAmISitting",
    ]),
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
    }),
    chipIsMeAndRaise() {
      if (
        this.$store.state.service.userRaiseClick &&
        this["service/nGetMySeat"] &&
        this["service/nGetMySeat"].position === this.realIndex &&
        this.type === "seat"
      ) {
        return true;
      }
      return false;
    },
    seatChipTextColor() {
      return null;
    },
    lastActionText() {
      const potRealSeat = this.nGetSeats.filter(
        (item) => item.position == this.realIndex
      );
      if (potRealSeat && potRealSeat.length > 0) {
        return chipActions[potRealSeat[0].lastAction];
      }
      return null;
    },
    // lastActionText() {
    //   if (
    //     this.type === "seat" &&
    //     this.nGetSeats &&
    //     this.nGetSeats.length >= this.index &&
    //     this.nGetSeats[this.index] &&
    //     this.nGetSeats[this.index].hasOwnProperty("lastAction")
    //   ) {
    //     return chipActions[this.nGetSeats[this.index].lastAction];
    //   }
    //   return null;
    // },
    getClasses() {
      const classes = [];
      classes.push(`pot-index-${this.$props.index}`);
      classes.push(`pot-type-${this.$props.type}`);
      return classes;
    },
    potIconClass() {
      const classes = [];
      if (this.type === "seat") {
      } else {
        classes.push("sprite-table");
        classes.push("chip");
      }
      return classes;
    },
    getSeatIconStyles() {
      const styles = {};
      if (this.data.amount < 1) {
        return styles;
      }
      if (this.index < 3 || this.index > 6) {
        const rightValue =
          this.getShortChipFormat(this.data.amount).length > 5 ? "-20" : "-5";
        styles.right = rightValue + "px";
      } else {
        const leftValue =
          this.getShortChipFormat(this.data.amount).length > 5 ? "-20" : "-5";
        styles.left = leftValue + "px";
      }
      return styles;
    },
  },
  data() {
    return {
      animations: [],
    };
  },
  methods: {
    getStacks(_amount) {
      let chipValues = [
        1, 5, 10, 25, 50, 100, 500, 1000, 5000, 10000, 50000, 100000, 500000,
        1000000, 5000000, 10000000, 50000000, 100000000, 500000000, 1000000000,
        5000000000, 10000000000, 50000000000,
      ];
      let chipClasses = [
        1, 5, 10, 25, 5, 100, 5, 10, 25, 5, 100, 1, 5, 10, 25, 5, 100, 1, 5, 10,
        25, 5, 100,
      ];
      chipValues = chipValues.reverse();

      const potSize = 4;
      const stackSize = 1;

      let chips = [];
      let amount = Math.floor(Number(_amount));

      let count = 0;
      while (amount > 0) {
        if (count >= chipValues.length) break;
        for (let i = 0; i < chipValues.length; i++) {
          count += 1;
          if (Math.floor(amount / chipValues[i]) > 0) {
            chips.push(chipClasses[i]);
            amount -= chipValues[i];
            break;
          }
        }
      }

      return [].concat
        .apply(
          [],
          chips.map(function (elem, i) {
            return i % potSize ? [] : [chips.slice(i, i + potSize)];
          })
        )
        .slice(0, stackSize);
    },
    getAnimationStyle(iconIndex) {
      const styles = {};

      styles.transitionDelay = `${iconIndex * 0.05}s`;
      styles.transitionDuration = `1s`;

      return styles;
    },
    onPotComingFromSeat(amount) {},
    onPotGoingToTablePot(amount) {
      if (amount <= 0) return false;

      const id = Math.floor(Math.random() * 99999999);

      this.$data.animations.push({
        _id: id,
        animate: false,
        type: "to-table",
        amount,
        stacks: [0],
      });

      setTimeout(() => {
        const index = this.$data.animations.findIndex((a) => a._id === id);
        if (index < 0) return;
        this.$data.animations[index].animate = true;
        setTimeout(() => {
          const index = this.$data.animations.findIndex((a) => a._id === id);
          if (index < 0) return;
          this.$data.animations.splice(index, 1);
        }, 1000);
      }, 50);
    },
    onWinnerAnnounced(winners) {
      winners.map(async (winnerPosition, winnerPositionIndex) => {
        const id = Math.floor(Math.random() * 99999999);
        const amount = this.$props.data.amount / winners.length;
        const to = this.getShiftedValue(winnerPosition);

        this.$data.animations.push({
          _id: id,
          to,
          amount,
          animate: false,
          type: "collect",
          stacks: [1],
        });

        if (winnerPositionIndex === 0) this.$plugins.audio.play("Win");

        setTimeout(() => {
          const index = this.$data.animations.findIndex((a) => a._id === id);
          if (index < 0) return;

          this.$data.animations[index].animate = true;

          EventBus.$emit("onWinnerSeatAnimationTriggered", {
            position: winnerPosition,
            amount: this.$props.data.amount / winners.length,
          });
          setTimeout(() => {
            const index = this.$data.animations.findIndex((a) => a._id === id);
            if (index < 0) return;
            this.$data.animations.splice(index, 1);
          }, 1000);
        }, 50);
      });
    },
    getShiftedValue(originalIndex) {
      if (!this["service/nAmISitting"]) return originalIndex;

      const shiftValue = 7 - this["service/nGetMySeat"].position;

      return (originalIndex + shiftValue) % 9;
    },
  },
  mounted() {
    // if (this.data.start) {
    //   setInterval(() => {
    //     this.onPotGoingToTablePot(5000);
    //   }, 1200);
    // }
    // if (this.data.start) {
    //   setInterval(() => {
    //     this.onWinnerAnnounced([0]);
    //   }, 1100);
    // }
  },
};
</script>

<style lang="scss" scoped>
@keyframes potIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pot {
  position: absolute;
  z-index: 1;
  width: 190px;
  height: 50px;

  .pot-inner-element {
    position: relative;
  }

  .pot-inner {
    position: absolute;
    top: 1px;
    left: -20px;
    width: 170px;
    height: 45px;
    border-radius: 40px;
    background-color: rgba(black, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 2px;
    box-sizing: border-box;
    transition: all 0.5s linear;

    .text-call,
    .text-check {
      color: #01b3ae;
    }
    .text-null,
    .text-undefined {
      color: #01b3ae;
    }
    .icon-null,
    .icon-undefined {
      background-color: rgba(#01b3ae, 1);
    }
    .icon-null::before {
      font-weight: bold;
      color: rgb(0, 0, 0);
      font-size: 36px;
    }
    .icon-check,
    .icon-call {
      background-color: rgba(#01b3ae, 1);
    }
    // .icon-check::before,
    // .icon-null::before,
    // .icon-undefined::before {
    //   content: "\2713";
    //   font-weight: bold;
    //   color: #000000;
    //   font-family: arial;

    //   position: relative;
    //   top: 2px;
    //   right: 2px;
    //   font-size: 36px;
    // }
    .icon-call::before {
      font-weight: bold;
      color: rgb(0, 0, 0);
      content: "C";
      font-size: 36px;
    }
    .text-table {
      color: #ffffff;
    }
    .text-raise,
    .text-allin {
      color: #ffba3e;
    }
    .icon-raise,
    .icon-allin {
      background-color: rgba(#ffba3e, 1);
    }
    .icon-fold {
      background-color: rgba(#ff3b3d, 1);
    }
    .icon-fold::after {
      content: "X";
      font-size: 34px;
      font-weight: bold;
      font-family: arial;
      color: rgb(0, 0, 0);
    }
    .text-fold {
      color: #f9393c;
    }
    .icon {
      width: 42px;
      height: 42px;
      border-radius: 25px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      .svg-check {
        width: 34px;
        height: 34px;
        position: absolute;
        color: rgb(0, 0, 0);
      }
      .chevron-down {
        width: 36px;
        height: 36px;
        color: rgb(0, 0, 0);
        transform: rotate(180deg);
        position: absolute;
        top: 4px;
      }
    }
    .sprite-table {
      width: 46px;
      height: 47px;
    }
  }

  &.pot-type-table {
    .icon {
      left: -15px;
    }
  }
  // &.pot-type-seat {
  //   &.pot-index-0,
  //   &.pot-index-1,
  //   &.pot-index-2,
  //   &.pot-index-7,
  //   &.pot-index-8 {
  //     .icon {
  //       right: -5px;
  //     }
  //   }
  //   &.pot-index-3,
  //   &.pot-index-4,
  //   &.pot-index-5,
  //   &.pot-index-6 {
  //     .icon {
  //       left: -5px;
  //     }
  //   }
  // }

  // .icon {
  //   .stack {
  //     display: block;
  //     width: 40px;

  //     .pot-icon {
  //       position: absolute;
  //       display: block;
  //     }

  //     .pot-icon {
  //       transition-timing-function: cubic-bezier(0.25, 0.1, 0, 0.96);
  //       transition-property: transform;
  //       transition-duration: 3s;
  //       animation-name: potIncomingAnimation;
  //       animation-iteration-count: 1;
  //       animation-fill-mode: forwards;
  //       animation-duration: 0.15s;
  //       animation-timing-function: ease;
  //       &-0 {
  //         z-index: 0;
  //         bottom: 2px;
  //         animation-duration: 0s;
  //       }

  //       &-1 {
  //         z-index: 1;
  //         bottom: 7px;
  //         animation-duration: 0.2s;
  //       }

  //       &-2 {
  //         z-index: 2;
  //         bottom: 12px;
  //         animation-duration: 0.4s;
  //       }

  //       &-3 {
  //         z-index: 3;
  //         bottom: 17px;
  //         animation-duration: 0.6s;
  //       }
  //     }
  //   }
  // }

  .text {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
    font-weight: bold;
    text-shadow: 0 2px 0 black;
  }

  &.pot-type-table {
    &.pot-index-0 {
      top: 138px;
      left: 600px;
      z-index: 4;
    }
    &.pot-index-1 {
      top: 150px;
      left: 380px;
    }
    &.pot-index-2 {
      top: 150px;
      left: 810px;
    }
    &.pot-index-3 {
      top: 370px;
      left: 600px;
    }
    &.pot-index-4 {
      top: 370px;
      left: 380px;
    }
    &.pot-index-5 {
      top: 370px;
      left: 810px;
    }
    &.pot-index-6 {
      top: 370px;
      left: 600px;
      z-index: 3;
    }
    &.pot-index-7 {
      top: 370px;
      left: 600px;
      z-index: 3;
    }
  }

  &.pot-type-seat {
    &.pot-index-0,
    &.pot-index-1,
    &.pot-index-2,
    &.pot-index-7,
    &.pot-index-8 {
      .pot-inner {
        flex-direction: row-reverse;
      }
    }
    &.pot-index-0 {
      top: 270px;
      left: 80px;
    }
    &.pot-index-1 {
      top: 172px;
      left: 130px;
    }
    &.pot-index-2 {
      top: 100px;
      left: 300px;
    }
    &.pot-index-3 {
      top: 100px;
      left: 900px;
    }
    &.pot-index-4 {
      top: 172px;
      left: 1080px;
    }
    &.pot-index-5 {
      top: 275px;
      left: 1130px;
    }
    &.pot-index-6 {
      top: 342px;
      left: 1040px;
    }
    &.pot-index-7 {
      top: 410px;
      left: 600px;
    }
    &.pot-index-8 {
      top: 340px;
      left: 190px;
    }
  }

  .pot-animations {
    .pot-inner {
      border-color: transparent;
      background-color: transparent;

      .stack .pot-icon.animate {
        &.type-collect {
          &.index-0 {
            &.to-0 {
              transform: translateX(-640px) translateY(200px);
            }
            &.to-1 {
              transform: translateX(-550px) translateY(-75px);
            }
            &.to-2 {
              transform: translateX(-250px) translateY(-190px);
            }
            &.to-3 {
              transform: translateX(402px) translateY(-165px);
            }
            &.to-4 {
              transform: translateX(732px) translateY(-65px);
            }
            &.to-5 {
              transform: translateX(806px) translateY(190px);
            }
            &.to-6 {
              transform: translateX(455px) translateY(355px);
            }
            &.to-7 {
              transform: translateX(70px) translateY(385px);
            }
            &.to-8 {
              transform: translateX(-400px) translateY(355px);
            }
          }
          &.index-1 {
            &.to-0 {
              transform: translateX(-466px) translateY(170px);
            }
            &.to-1 {
              transform: translateX(-370px) translateY(-100px);
            }
            &.to-2 {
              transform: translateX(-68px) translateY(-215px);
            }
            &.to-3 {
              transform: translateX(590px) translateY(-215px);
            }
            &.to-4 {
              transform: translateX(950px) translateY(-100px);
            }
            &.to-5 {
              transform: translateX(995px) translateY(180px);
            }
            &.to-6 {
              transform: translateX(658px) translateY(356px);
            }
            &.to-7 {
              transform: translateX(265px) translateY(455px);
            }
            &.to-8 {
              transform: translateX(-215px) translateY(355px);
            }
          }
          &.index-2 {
            &.to-0 {
              transform: translateX(-892px) translateY(170px);
            }
            &.to-1 {
              transform: translateX(-790px) translateY(-100px);
            }
            &.to-2 {
              transform: translateX(-490px) translateY(-220px);
            }
            &.to-3 {
              transform: translateX(165px) translateY(-220px);
            }
            &.to-4 {
              transform: translateX(480px) translateY(-100px);
            }
            &.to-5 {
              transform: translateX(570px) translateY(170px);
            }
            &.to-6 {
              transform: translateX(280px) translateY(456px);
            }
            &.to-7 {
              transform: translateX(-156px) translateY(456px);
            }
            &.to-8 {
              transform: translateX(-540px) translateY(269px);
            }
          }
          &.index-3 {
            &.to-0 {
              transform: translateX(-680px) translateY(-70px);
            }
            &.to-1 {
              transform: translateX(-585px) translateY(-340px);
            }
            &.to-2 {
              transform: translateX(-280px) translateY(-460px);
            }
            &.to-3 {
              transform: translateX(380px) translateY(-460px);
            }
            &.to-4 {
              transform: translateX(700px) translateY(-340px);
            }
            &.to-5 {
              transform: translateX(780px) translateY(-50px);
            }
            &.to-6 {
              transform: translateX(530px) translateY(220px);
            }
            &.to-7 {
              transform: translateX(52px) translateY(220px);
            }
            &.to-8 {
              transform: translateX(-332px) translateY(70px);
            }
          }
          &.index-4 {
            &.to-0 {
              transform: translateX(-432px) translateY(-70px);
            }
            &.to-1 {
              transform: translateX(-334px) translateY(-340px);
            }
            &.to-2 {
              transform: translateX(-32px) translateY(-460px);
            }
            &.to-3 {
              transform: translateX(626px) translateY(-460px);
            }
            &.to-4 {
              transform: translateX(927px) translateY(-340px);
            }
            &.to-5 {
              transform: translateX(1028px) translateY(-50px);
            }
            &.to-6 {
              transform: translateX(760px) translateY(190px);
            }
            &.to-7 {
              transform: translateX(302px) translateY(220px);
            }
            &.to-8 {
              transform: translateX(-90px) translateY(59px);
            }
          }
          &.index-5 {
            &.to-0 {
              transform: translateX(-932px) translateY(-70px);
            }
            &.to-1 {
              transform: translateX(-834px) translateY(-340px);
            }
            &.to-2 {
              transform: translateX(-510px) translateY(-460px);
            }
            &.to-3 {
              transform: translateX(126px) translateY(-460px);
            }
            &.to-4 {
              transform: translateX(527px) translateY(-290px);
            }
            &.to-5 {
              transform: translateX(548px) translateY(-30px);
            }
            &.to-6 {
              transform: translateX(300px) translateY(220px);
            }
            &.to-7 {
              transform: translateX(-199px) translateY(220px);
            }
            &.to-8 {
              transform: translateX(-500px) translateY(110px);
            }
          }
          &.index-6 {
            &.to-0 {
              transform: translateX(-600px) translateY(40px);
            }
            &.to-1 {
              transform: translateX(-564px) translateY(-350px);
            }
            &.to-2 {
              transform: translateX(-172px) translateY(-400px);
            }
            &.to-3 {
              transform: translateX(400px) translateY(-390px);
            }
            &.to-4 {
              transform: translateX(699px) translateY(-280px);
            }
            &.to-5 {
              transform: translateX(750px) translateY(-30px);
            }
            &.to-6 {
              transform: translateX(450px) translateY(100px);
            }
            &.to-7 {
              transform: translateX(100px) translateY(130px);
            }
            &.to-8 {
              transform: translateX(-310px) translateY(125px);
            }
          }
          &.index-7 {
            &.to-0 {
              transform: translateX(-600px) translateY(40px);
            }
            &.to-1 {
              transform: translateX(-605px) translateY(-270px);
            }
            &.to-2 {
              transform: translateX(-200px) translateY(-370px);
            }
            &.to-3 {
              transform: translateX(450px) translateY(-430px);
            }
            &.to-4 {
              transform: translateX(690px) translateY(-300px);
            }
            &.to-5 {
              transform: translateX(745px) translateY(20px);
            }
            &.to-6 {
              transform: translateX(460px) translateY(70px);
            }
            &.to-7 {
              transform: translateX(70px) translateY(200px);
            }
            &.to-8 {
              transform: translateX(-300px) translateY(70px);
            }
          }
        }

        &.type-to-table {
          z-index: 2;
          &.index-0 {
            transform: translateX(357px) translateY(-123px);
          }
          &.index-1 {
            transform: translateX(307px) translateY(-23px);
          }
          &.index-2 {
            transform: translateX(136px) translateY(48px);
          }
          &.index-3 {
            transform: translateX(-300px) translateY(48px);
          }
          &.index-4 {
            transform: translateX(-470px) translateY(-25px);
          }
          &.index-5 {
            transform: translateX(-519px) translateY(-127px);
          }
          &.index-6 {
            transform: translateX(-440px) translateY(-204px);
          }
          &.index-7 {
            transform: translateX(-163px) translateY(-282px);
          }
          &.index-8 {
            transform: translateX(276px) translateY(-202px);
          }
        }
      }
    }
  }
}
.pot-type-table {
  .pot-inner {
    .text {
      color: #ffffff !important;
    }
  }
}
</style>
