<template>
  <div class="bet-selector">
    <button
      class="functional-button back-button"
      @click="back"
      :disabled="nGetMySeat.bet.main <= 0"
    >
      <span class="inner"></span>
      <span class="description">{{ $t("blackJack.undo") }}</span>
    </button>
    <div class="chips">
      <div
        v-for="(betAmount, betAmountIndex) in betAmounts"
        :key="'bet-amount-' + betAmountIndex"
        class="chip"
        :class="[
          getChipColorClass(betAmount),
          selectedBet === betAmount.amount ? 'active' : '',
        ]"
        @click="selectBet(betAmount.amount, betAmount.multiplier)"
      >
        {{ getShortChipFormat(betAmount.amount) }}
      </div>
    </div>
    <button
      class="functional-button x2-button"
      @click="twoX"
      v-show="nGetMySeat.bet.main > 0"
      :disabled="nGetMySeat.bet.main <= 0"
    >
      <span class="inner"></span>
      <span class="description">{{ $t("blackJack.double") }}</span>
    </button>
    <button
      class="functional-button repeat-button"
      @click="repeat"
      v-show="nGetMySeat.bet.main <= 0"
    >
      <span class="inner"></span>
      <span class="description">{{ $t("blackJack.repeat") }}</span>
    </button>
  </div>
</template>

<script>
const MULTIPLICATIONS = [1, 2, 5, 25, 100, 500, 1000];

import { mapGetters } from "vuex";
import EventBus from "@/event-bus";

export default {
  name: "BetSelector",
  watch: {
    selectedBet: function (amount) {
      if (amount > 0) this.$plugins.audio.play("BjClick");
    },
  },
  computed: {
    ...mapGetters(["getShortChipFormat"]),
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    betAmounts() {
      const betAmounts = [];

      MULTIPLICATIONS.forEach((item) => {
        betAmounts.push({
          amount: item * this.getActiveRoomState.minBuy,
          multiplier: item,
        });
      });

      return betAmounts;
    },
  },
  data() {
    return {
      selectedBet: 0,
    };
  },
  methods: {
    getChipColorClass(item) {
      let className = "";
      switch (item.multiplier) {
        case 1:
          className = "red";
          break;
        case 2:
          className = "purple";
          break;
        case 5:
          className = "green";
          break;
        case 25:
          className = "turquoise";
          break;
        case 100:
          className = "blue";
          break;
        case 500:
          className = "grey";
          break;
        case 1000:
          className = "black";
          break;
      }
      return className;
    },
    selectBet(amount, multiplier) {
      this.selectedBet = amount;
      EventBus.$emit("onBetSelected", {
        color: this.getChipColorClass({ amount, multiplier }),
        amount,
        multiplier,
      });
    },
    back() {
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("undo");
      this.selectedBet = 0;
      EventBus.$emit("onBetSelected", { amount: 0, multiplier: 0 });
    },
    async twoX() {
      if (this.nGetMySeat.stack < this.nGetMySeat.bet.main)
        return this.$plugins.toast.show({
          text: this.$t("blackJack.InsufficientChips"),
          variant: "danger",
        });

      if (this.nGetMySeat.bet.main === 0) return false;
      await this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send(
        "bet",
        {
          type: "main",
          amount: this.nGetMySeat.bet.main,
        }
      );

      if (this.nGetMySeat.bet.twentyOne > 0) {
        await this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send(
          "bet",
          {
            type: "twentyOne",
            amount: this.nGetMySeat.bet.twentyOne,
          }
        );
      }

      if (this.nGetMySeat.bet.anyPair > 0) {
        await this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send(
          "bet",
          {
            type: "anyPair",
            amount: this.nGetMySeat.bet.anyPair,
          }
        );
      }
    },
    repeat() {
      this.$plugins.audio.play("BjBet");
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("repeat");
    },
  },
  mounted() {
    setTimeout(() => {
      this.selectBet(this.betAmounts[0].amount, this.betAmounts[0].multiplier);
    }, 200);
  },
};
</script>

<style scoped lang="scss">
.bet-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 180px;
  flex-wrap: nowrap;
  will-change: auto;
  transform: scale(1.4);
  .functional-button {
    flex-shrink: 0;
    display: block;
    width: 54px;
    height: 54px;
    padding: 0;
    background-color: transparent;
    border: 0;
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: all 0.25s ease;
    &:disabled {
      opacity: 0.2;
    }
    .inner {
      display: block;
      background-color: transparentize(black, 0.2);
      width: 54px;
      height: 54px;
      margin-bottom: 7px;
      border-radius: 100%;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 0.25s ease;
    }
    .description {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: bold;
    }
    &:hover {
      .inner {
        background-color: rgb(255, 255, 255);
        background-blend-mode: difference;
      }
    }
    &.back-button {
      .inner {
        background-image: url("~@/assets/images/blackjack/icons/back.png");
      }
    }
    &.x2-button {
      .inner {
        background-image: url("~@/assets/images/blackjack/icons/x2.png");
      }
    }
    &.repeat-button {
      .inner {
        background-image: url("~@/assets/images/blackjack/icons/repeat.png");
      }
    }
  }
  .chips {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    .chip {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 14px;
      text-shadow: 0 1px 0 black;
      margin-right: 5px;
      background-repeat: no-repeat;
      background-size: 85%;
      background-position-x: center;
      background-position-y: 60%;
      background-image: url("~@/assets/images/blackjack/chips/chip-red.png");
      border: 3px solid transparent;
      border-radius: 100%;
      width: 80px;
      height: 80px;
      cursor: pointer;
      transition: all 0.25s ease;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        box-shadow: inset 0 0 0 4px rgb(255, 255, 255), 0 0 4px 2px black;
      }
      &.active {
        border-color: $yellowColor;
        box-shadow: 0 0 4px 2px black;
      }
      &.purple {
        background-image: url("~@/assets/images/blackjack/chips/chip-purple.png");
      }
      &.green {
        background-image: url("~@/assets/images/blackjack/chips/chip-green.png");
      }
      &.turquoise {
        background-image: url("~@/assets/images/blackjack/chips/chip-turquoise.png");
      }
      &.blue {
        background-image: url("~@/assets/images/blackjack/chips/chip-blue.png");
      }
      &.grey {
        background-image: url("~@/assets/images/blackjack/chips/chip-grey.png");
      }
      &.black {
        background-image: url("~@/assets/images/blackjack/chips/chip-black.png");
      }
    }
  }
}

.platform-mobile {
  .bet-selector {
    margin-top: 30px;
    transform: scale(1.5);
  }
}
</style>
