<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="700"
    height="1000"
    :show="show"
    :loading="loading"
    innerBackground="linear-gradient(0deg, rgba(152,25,35,1) 0%, rgba(95,16,23,1) 100%)"
  >
    <template>
      <div class="gateways-container">
        <div class="platform-product">
          <div class="product-header">
            <p>{{ `${getChipFormat(product.chip || 0)}` }}</p>
            <p>{{ `+ ${getChipFormat(product.gift || 0)}` }}</p>
          </div>
          <div class="product-image">
            <!-- <div class="atlas-5-sprite win-popup-chip-icon"></div> -->
          </div>
          <div class="product-price">{{ `Rp ${product.price}` }}</div>
        </div>
        <div class="payment-gateways">
          <button
            class="gateway ui-button ui-button-yellow"
            v-for="(gateway, index) in gateways"
            :key="index"
            @click="buyPlatformProduct(gateway.id)"
          >
            {{ gateway.description }}
          </button>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";
import { mapGetters } from "vuex";

import { Browser } from "@capacitor/browser";

export default {
  name: "SelectGatewayWindow",
  components: {
    BaseWindow,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    gateways: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getChipFormat"]),
  },
  methods: {
    closeWindow() {
      this.$emit("closeWindow");
    },
    async buyPlatformProduct(gatewayId) {
      this.loading = true;
      const res = await this.$store.dispatch("products/buyPlatformProduct", {
        productId: this.$props.product.id,
        gatewayId: gatewayId,
      });
      this.loading = false;
      await Browser.open({ url: res.redirectingUrl });
      this.closeWindow();
    },
  },
};
</script>

<style lang="scss" scoped>
.gateways-container {
  width: 100%;
  height: 100%;
  position: relative;
  .platform-product,
  .product {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    margin-top: 30px;
    width: 50%;
    height: 350px;
    background-color: black;
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.15s linear;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      height: 180px;
      padding: 8px;
      font-size: 40px;
      white-space: wrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: bold;
      text-align: center;
      background: linear-gradient(0deg, #ce8b00, #803402);
      p {
        display: block;
        height: 60px;
        background: -webkit-linear-gradient(#ffcc66, #ffffcc);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &-image {
      position: relative;
      background: linear-gradient(0deg, #ce8b00, #f8c341);
      height: 100%;
      .win-popup-chip-icon {
        position: absolute;
        top: 10px;
        left: 20px;
        transform: scale(0.65);
        transform-origin: top;
      }
    }
    &-price {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 120px;
      font-size: 40px;
      font-weight: bold;
      color: #f4e9e9;
      text-shadow: 0 2px 2px black;
      position: relative;
      z-index: 2;
      background: linear-gradient(
        rgb(236, 255, 172),
        rgb(216, 246, 100) 20%,
        rgb(130, 181, 24),
        rgb(100, 169, 16) 90%,
        rgb(203, 255, 140)
      );
      transition: all 0.25s ease;
    }
  }
  .payment-gateways {
    position: relative;
    width: 100%;
    height: 565px;
    top: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;
    .gateway {
      width: 80%;
      margin: 10px;
      padding: 18px;
    }
  }
}
</style>
