<template>
  <svg
    v-bind="$attrs"
    viewBox="0 0 400 150"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      id="SunCatcherStudio"
      fill="none"
      d="m 31.233725,31.233725 c 0,0 61.574343,63.73891 168.766275,63.73891 121.84084,0 168.76627,-63.73891 168.76627,-63.73891"
    />

    <text
      font-size="35"
      fill="white"
      letter-spacing="3"
      font-family="sans-serif"
      font-weight="bold"
    >
      <textPath xlink:href="#SunCatcherStudio" :startOffset="startOffset">
        {{ curvedText }}
      </textPath>
    </text>
  </svg>
</template>

<script>
export default {
  name: "CurvedTextSvg",
  props: {
    curvedText: {
      type: String,
      default: "",
    },
    startOffset: {
      type: Number,
      default: 105,
    },
  },
};
</script>

<style></style>
