var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slot-selection no-flick"},[_c('div',{ref:"slider",staticClass:"slot-selection-inner"},[_c('div',{staticClass:"slot-button olympus",class:[_vm.config['olympus_status'] == 1 ? 'active' : 'deactivated']},[(_vm.config['olympus_status'] == 1)?_c('button',{staticClass:"download-button",class:[..._vm.getDownloadButtonClasses('olympus')],attrs:{"type":"button"},on:{"click":function($event){return _vm.downloadOrPlay('olympus')}}},[(_vm.slots.olympus.status === _vm.getSlotsConstants.status.DOWNLOADING)?_c('span',{staticClass:"download-percent"},[_vm._v(" "+_vm._s(_vm.getAsPercent(_vm.slots.olympus.progress))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"slot-button fafafa",class:[_vm.config['fafafa_status'] == 1 ? 'active' : 'deactivated']},[(_vm.config['fafafa_status'] == 1)?_c('button',{staticClass:"download-button",class:[..._vm.getDownloadButtonClasses('fafafa')],attrs:{"type":"button"},on:{"click":function($event){return _vm.downloadOrPlay('fafafa')}}},[(_vm.slots.fafafa.status === _vm.getSlotsConstants.status.DOWNLOADING)?_c('span',{staticClass:"download-percent"},[_vm._v(" "+_vm._s(_vm.getAsPercent(_vm.slots.fafafa.progress))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"slot-button legends",class:[_vm.config['turn_legends_status'] == 1 ? 'active' : 'deactivated']},[(_vm.config['turn_legends_status'] == 1)?_c('button',{staticClass:"download-button",class:[..._vm.getDownloadButtonClasses('turnLegends')],attrs:{"type":"button"},on:{"click":function($event){return _vm.downloadOrPlay('turnLegends')}}},[(
            _vm.slots.turnLegends.status === _vm.getSlotsConstants.status.DOWNLOADING
          )?_c('span',{staticClass:"download-percent"},[_vm._v(" "+_vm._s(_vm.getAsPercent(_vm.slots.turnLegends.progress))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"slot-button bamboo",class:[
        this.config['bamboo_panda_status'] == 1 ? 'active' : 'deactivated',
      ]},[(_vm.config['bamboo_panda_status'] == 1)?_c('button',{staticClass:"download-button",class:[..._vm.getDownloadButtonClasses('bambooPanda')],attrs:{"type":"button"},on:{"click":function($event){return _vm.downloadOrPlay('bambooPanda')}}},[(
            _vm.slots.bambooPanda.status === _vm.getSlotsConstants.status.DOWNLOADING
          )?_c('span',{staticClass:"download-percent"},[_vm._v(" "+_vm._s(_vm.getAsPercent(_vm.slots.bambooPanda.progress))+" ")]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }