<template>
  <div class="game-history">
    <div class="players-container container">
      <div class="avatar-container" @click="getUser(player.id)">
        <Avatar size="100" :url="player.avatar" />
      </div>
      <div class="user-detail">
        <div class="user-name">{{ player.name }}</div>
        <div class="chip-amount">
          <div class="user-chip-amount">{{ getChipFormat(stack || 0) }}</div>
        </div>
      </div>
    </div>
    <div class="hand-container container">
      <div class="cards">
        <div
          class="card card-sprite"
          v-if="player.cards[0]"
          :class="getCardClasses(player.cards[0])"
        ></div>
        <div
          class="card card-sprite"
          v-if="player.cards[1]"
          :class="getCardClasses(player.cards[1])"
        ></div>
      </div>
    </div>
    <div class="pre-flop-container container">
      <div
        class="action"
        v-for="(item, index) in getUserActions('pre-flop')"
        :key="index"
      >
        <span class="action-part"></span>
        {{ `${item.payload.actionMethod.toUpperCase()}` }}
        <span class="action-amount"
          >{{
            `${
              item.payload.amount === 0
                ? ""
                : getShortChipFormat(item.payload.amount, 2)
            }`
          }}
        </span>
      </div>
    </div>
    <div class="flop-container container">
      <span
        class="action"
        v-for="(item, index) in getUserActions('flop')"
        :key="index"
      >
        {{
          `${item.payload.actionMethod.toUpperCase()} ${
            item.payload.amount === 0
              ? ""
              : getShortChipFormat(item.payload.amount, 2)
          }`
        }}
      </span>
    </div>
    <div class="turn-container container">
      <span
        class="action"
        v-for="(item, index) in getUserActions('turn')"
        :key="index"
      >
        {{
          `${item.payload.actionMethod.toUpperCase()} ${
            item.payload.amount === 0
              ? ""
              : getShortChipFormat(item.payload.amount, 2)
          }`
        }}
      </span>
    </div>
    <div class="river-container container">
      <span
        class="action"
        v-for="(item, index) in getUserActions('river')"
        :key="index"
      >
        {{
          `${item.payload.actionMethod.toUpperCase()} ${
            item.payload.amount === 0
              ? ""
              : getShortChipFormat(item.payload.amount, 2)
          }`
        }}
      </span>
    </div>
    <div class="winner-container container">
      <div class="winner-amount" v-show="winner">
        <div class="amount">
          <span class="chip-amount">{{ getChipFormat(winner) }}</span>
        </div>
        <span class="sprite-profile-popup star"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Avatar from "@/components/Misc/Avatar";
import _ from "lodash";
export default {
  name: "GameHistory",
  components: {
    Avatar,
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
    winner: {
      type: [Boolean, Number],
      default: false,
    },
    actions: {
      type: Array,
      required: true,
    },
    stack: {
      type: Number,
      required: true,
    },
    // isGameRiverStage: {
    //   type: Boolean,
    //   default: false,
    // },
    gameIsCompleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    ...mapGetters(["getChipFormat", "getShortChipFormat"]),
    ...mapState(["user"]),
    userActionContainFold() {
      const playerIsNotMe = this.player.id !== this.user.id;
      const doYouFold =
        this.actions.filter((action) => {
          if (!_.isEmpty(action)) {
            return (
              action.filter(
                (doubleAction) => doubleAction.payload.actionMethod === "fold"
              ).length > 0
            );
          }
          return false;
        }).length > 0;

      return playerIsNotMe && (!this.gameIsCompleted || doYouFold);
    },
  },
  methods: {
    getCardClasses(card) {
      if (this.userActionContainFold) {
        return "b0";
      }
      const value = String(card.value).replace("T", "10");
      return String(card.suit + value).toLowerCase();
    },
    getUserActions(stage) {
      if (this.actions.length < 1) {
        return [];
      }
      switch (stage) {
        case "pre-flop":
          if (this.actions[0].length > 0) {
            return [this.actions[0][this.actions[0].length - 1]];
          }
          // return this.actions[0];
          break;
        case "flop":
          if (this.actions[1].length > 0) {
            return [this.actions[1][this.actions[1].length - 1]];
          }
          // return this.actions[1];
          break;
        case "turn":
          if (this.actions[2].length > 0) {
            return [this.actions[2][this.actions[2].length - 1]];
          }
          // return this.actions[2];
          break;
        case "river":
          if (this.actions[3].length > 0) {
            return [this.actions[3][this.actions[3].length - 1]];
          }
          // return this.actions[3];
          break;
      }
    },
    async getUser(userid) {
      if (userid) {
        const res = await this.$store.dispatch("friends/getProfiles", [userid]);
        if (!res) {
          this.$plugins.toast.show({
            text: this.$t("confirmWindow.userNotFound"),
            duration: 2000,
            variant: "danger",
          });
          return;
        }
        this.$store.commit("friends/setSelectedUserModal", res[0]);

        this.$store.commit("modals/show", "friendDetails");
      } else {
        return this.$plugins.toast.show({
          text: this.$t("searchWindow.validId"),
          duration: 2000,
          variant: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-history {
  position: relative;
  width: 98%;
  height: 115px;
  background: rgba(#20080b, 0.8);
  border-radius: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;
    border-right: 2px solid #892231;
    line-height: 38px;
    font-weight: bold;
    font-size: 32px;
    text-transform: uppercase;
    &.players-container {
      width: 22%;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      border-right: 2px solid #892231;
      .user-detail {
        width: 60%;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: flex-start;
        justify-content: center;
        .chip-amount {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          font-weight: bold;
          font-size: 25px;
          .chip-icon {
            transform: scale(0.8);
          }
        }
      }
      .avatar-container {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &.hand-container {
      width: 10%;
      position: relative;
      .cards {
        display: flex;
        width: 220px;
        justify-content: space-between;
        align-items: center;
        transform: scale(0.7);
      }
    }
    &.pre-flop-container {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      .bet {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .action {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-weight: bold;
        font-size: 29px;
      }
    }
    &.flop-container {
      width: 10%;
    }
    &.turn-container {
      width: 10%;
    }
    &.river-container {
      width: 10%;
    }
    &.winner-container {
      width: 28%;
      border-right: none;
      .winner-amount {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .amount {
          width: 400px;
          height: 85px;
          background: #6f1c26;
          box-shadow: inset 1px 3px 8px black;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
        }
      }
    }
  }
}
</style>
