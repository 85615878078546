<template>
  <BaseWindow @closeWindow="closeWindow" :show="show" preventClickOutside>
    <template>
      <div class="select-pot-window">
        <div class="title-part">
          <div class="sprite-table-pop-up logo-image"></div>
          <div class="chip-container">
            <span class="lobby-sprite chip"></span>
            <span class="chip-amount">{{ getChipFormat(user.chip) }}</span>
          </div>
        </div>
        <!-- <div class="top">
          <input
            :placeholder="getChipFormat(currentAmount)"
            disabled="disabled"
            :value="getChipFormat(currentAmount)"
          />
        </div> -->
        <div class="middle">
          <div class="slider-desc">{{ $t("selectPotWindow.setQuantity") }}</div>
          <div class="slider-part">
            <div class="slider-part-top">
              <div class="info">
                <div class="entry">
                  {{ $t("selectPotWindow.minEntry") }}
                </div>
                <div class="text">
                  {{ getShortChipFormat(getMinBuy) }}
                </div>
              </div>
              <div class="current-chip">
                <input
                  :placeholder="getChipFormat(currentAmount)"
                  disabled="disabled"
                  :value="`$ ${getChipFormat(currentAmount)}`"
                  readonly
                />
              </div>
              <div class="info">
                <div class="entry">
                  {{ $t("selectPotWindow.maxEntry") }}
                </div>
                <div class="text">{{ getShortChipFormat(getMaxBuy) }}</div>
              </div>
            </div>
            <div class="slider-bg">
              <div class="circle-button" @click="decreaseSitAmount">
                <span class="sprite-table-pop-up minus-icon"></span>
              </div>
              <VueSlider
                width="1200px"
                height="25px"
                :silent="true"
                :disabled="getSitAmounts.length <= 1"
                :min="getMinBuy"
                :max="getMaxBuy"
                :data="getSitAmounts"
                :included="true"
                :zoom="getTotalScaleAmount"
                :dotOptions="{
                  style: {
                    width: '109px',
                    height: '109px',
                  },
                  focusStyle: {
                    boxShadow: '0 0 16px 0 #1f2025',
                    border: '1px solid #1f2025',
                  },
                  tooltipStyle: {
                    color: 'white',
                    backgroundColor: 'black',
                    borderColor: 'black',
                    fontSize: '12px',
                    padding: '3px 12px',
                  },
                }"
                :processStyle="{
                  borderRadius: '30px',
                  border: '2px solid #8a5f45',
                }"
                :railStyle="{
                  background: 'rgba(0,0,0,0.5)',
                  border: '1px solid rgba(255,255,255,0)',
                }"
                v-model="currentAmount"
                :adsorb="true"
                :drag-on-click="true"
                tooltip="none"
                ref="slider"
                @dragging="onSliderDragging"
                @drag-end="onSliderDragEnd"
              >
                <template v-slot:dot>
                  <div class="sprite-table-pop-up level-bar-button"></div>
                </template>
              </VueSlider>
              <div class="circle-button" @click="increaseSitAmount">
                <span class="sprite-table-pop-up plus-icon"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="options">
            <div class="auto auto-get" @click="selectOption(0)">
              <div class="auto-top">
                <div class="selector">
                  <span
                    :class="
                      selectedOption === 0
                        ? 'sprite-table-pop-up report-icon-orange'
                        : ''
                    "
                  ></span>
                </div>
                <div class="title">
                  {{ $t("selectPotWindow.autoPurchase") }}
                </div>
              </div>
              <div class="auto-description">
                {{ $t("selectPotWindow.autoPurchaseDescription") }}
              </div>
            </div>
            <div class="auto auto-fill">
              <div class="auto-top" @click="selectOption(1)">
                <div class="selector">
                  <span
                    :class="
                      selectedOption === 1
                        ? 'sprite-table-pop-up report-icon-orange'
                        : ''
                    "
                  ></span>
                </div>
                <div class="title">
                  {{ $t("selectPotWindow.autoCharging") }}
                </div>
              </div>
              <div class="auto-description">
                {{ $t("selectPotWindow.autoChargingDescription") }}
              </div>
            </div>
          </div>

          <!-- <div class="insufficient-balance" v-if="user.chip < getMinBuy">
            {{ $t("errors.insufficientBalance") }}
          </div> -->
        </div>
        <div class="button-container">
          <div
            class="sit-button ui-button ui-button-small-orange"
            v-if="user.chip >= getMinBuy"
            @click="onOkButtonClicked"
          >
            {{ $t("selectPotWindow.sit") }}
          </div>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import EventBus from "@/event-bus";
import BaseWindow from "@/components/Misc/GlobalWindows/BaseWindow";
import VueSlider from "vue-slider-component";
import AnalyticsEvents from "@/plugins/analytics/events";

export default {
  name: "SelectPotWindow",
  components: {
    BaseWindow,
    VueSlider,
  },
  watch: {
    show(currentState) {
      if (currentState) {
        this.currentAmount = this.getMinBuy;
        return false;
      }

      this.currentAmount = this.getMinBuy;
      this.position = -1;
      this.onCompleted = () => {};
    },
    currentAmount(newValue) {
      if (this.getMinBuy === newValue) return;
      this.$plugins.audio.play("SliderSwipe");
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
      getTotalScaleAmount: "getTotalScaleAmount",
    }),
    ...mapGetters(["getChipFormat", "getShortChipFormat"]),
    getSitAmounts() {
      const maxBuy = Math.min(this.getActiveRoomState.maxBuy, this.user.chip);
      const stepCount = Math.ceil(maxBuy / (this.getActiveRoomState.bet * 2));

      const amounts = [];
      for (let i = 1; i < stepCount; i++) {
        let amount = i * this.getActiveRoomState.bet * 2;
        if (amount >= this.getMinBuy) {
          if (amount > this.getActiveRoomState.maxBuy)
            amount = this.getActiveRoomState.maxBuy;
          amounts.push(amount);
        }
      }

      amounts.push(maxBuy);

      return amounts;
    },
    getMinBuy() {
      const me = this.getActiveRoomState.players.find(
        (p) => p.id === this.user.id
      );
      if (typeof me === "undefined") return this.getActiveRoomState.minBuy;
      return Math.max(me.minSitAmount, this.getActiveRoomState.minBuy);
    },
    getMaxBuy() {
      if (
        typeof this.getSitAmounts[this.getSitAmounts.length - 1] === "undefined"
      )
        return this.getActiveRoomState.minBuy;
      return this.getSitAmounts[this.getSitAmounts.length - 1];
    },
  },
  data() {
    return {
      show: false,
      clickOutsideBlocked: false,
      currentAmount: 0,
      position: -1,
      selectedOption: -1,
      onCompleted: () => {},
    };
  },
  methods: {
    onSliderDragEnd() {
      this.clickOutsideBlocked = false;
    },
    onSliderDragging() {
      this.clickOutsideBlocked = true;
    },
    onClickedOutside($event) {
      if (this.clickOutsideBlocked) return false;
      if (!$event?.target?.className.includes("sit-window")) return false;
      this.close();
    },
    close() {
      this.show = false;
      this.selectedOption = -1;
      this.clickOutsideBlocked = false;
      this.position = -1;
      this.currentAmount = 0;
      this.onCompleted = () => {};
    },
    selectOption(value) {
      if (this.selectedOption === value) {
        this.selectedOption = -1;
        return;
      }
      this.selectedOption = value;
    },
    async onOkButtonClicked() {
      if (typeof this.onCompleted === "function") {
        this.onCompleted(this.currentAmount, this.selectedOption);
      }

      this.close();
    },
    onSitWindowRequested({ position, onCompleted }) {
      if (position < 0) return false;

      this.position = position;
      this.onCompleted = onCompleted;

      setTimeout(() => {
        this.show = true;
      }, 50);
    },
    increaseSitAmount() {
      const amount = this.getActiveRoomState.bet * 2;

      if (this.$data.currentAmount + amount >= this.getMaxBuy) {
        this.$data.currentAmount = this.getMaxBuy;
        return;
      }
      this.$data.currentAmount += amount;
    },
    decreaseSitAmount() {
      const amount = this.getActiveRoomState.bet * 2;

      if (this.$data.currentAmount - amount <= this.getMinBuy) {
        this.$data.currentAmount = this.getMinBuy;
        return;
      }
      this.$data.currentAmount -= amount;
    },
    closeWindow() {
      if (this.$data.clickOutsideBlocked) return false;
      this.$data.show = false;

      this.$plugins.audio.play("Button");
    },
  },
  beforeDestroy() {
    EventBus.$off("onSitWindowRequested", this.onSitWindowRequested.bind(this));
  },
  created() {
    EventBus.$on("onSitWindowRequested", this.onSitWindowRequested.bind(this));
  },
};
</script>

<style lang="scss">
.select-pot-window {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  .title-part {
    width: 100%;
    height: 13%;
    border-bottom: 2px solid #811925;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .chip-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 60%;
      height: 100%;
      pointer-events: none;
      .chip {
        transform: scale(1.5);
        will-change: auto;
      }
      .chip-amount {
        font-size: 60px;
        color: #9f5560;
        text-shadow: 0px 3px 2px black;
        margin-left: 30px;
      }
    }
  }
  .middle {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    .slider-desc {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 30%;
      font-size: 50px;
      margin-right: 17px;
    }
    .slider-part {
      width: 100%;
      height: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      &-top {
        width: 80%;
        height: 30%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .current-chip {
          position: relative;

          input {
            text-align: center;
            background: transparent;
            border: none;
            font-size: 80px;
            color: white;
            text-shadow: 0px 3px 2px black;
          }
        }

        .info {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          text-align: center;
          height: 110px;
          .entry {
            color: #fee899;
            font-size: 55px;
            text-shadow: 0px 3px 2px black;
          }
          .text {
            color: white;
            font-size: 45px;
            text-shadow: 0px 3px 2px black;
          }
        }
      }
      .slider-bg {
        width: 85%;
        height: 70%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .circle-button {
          width: 100px;
          height: 100px;
          background: rgba(black, 0.6);
          border-radius: 100%;
          border: 3px solid #ecd98e;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:active {
            transform: scale(1.1);
          }
        }
        .level-bar-button {
          position: absolute;
          top: -50px;
          left: -50px;
        }
        .vue-slider {
          .vue-slider-rail {
            .vue-slider-process {
              background-image: url("~@/assets/images/table/level-bar.png");
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }
  .bottom {
    width: 80%;
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .options {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      height: 60%;
      .auto {
        height: 100%;
        width: 50%;
        font-size: 36px;
        text-align: center;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        .auto-top {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .selector {
            width: 50px;
            height: 50px;
            flex-shrink: 0;
            border-radius: 100%;
            background: rgba(black, 0.6);
            border: 2px solid #a92132;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 1px;
          }

          .title {
            color: #fee899;
            font-size: 55px;
          }
        }
        .auto-description {
          width: 80%;
          line-height: 45px;
        }
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15%;
    width: 100%;
  }
}
.logo-image {
  margin-left: 50px;
}
</style>
