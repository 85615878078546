import { FacebookLogin } from "@capacitor-community/facebook-login";
import StorageManager from "@/plugins/storage-manager";
import packageJson from "@/../package.json";

import Logger from "@/logger";
import axios from "@/services/api/axios";
import EventBus from "@/event-bus";
import toast from "@/plugins/toast";
import i18n from "@/plugins/i18n";

const NOTIFY_ONLINE_TIME = 1000 * 60 * 2;

export default {
  /*
   *   Basic user details endpoint
   *   id, name, avatar, chip etc.
   */
  async getCurrentUserDetails({ commit }) {
    try {
      const response = await axios.get(`/user/me`);

      if (response?.data?.user) {
        const {
          id,
          name,
          pictureUrl,
          chip,
          xp,
          vip,
          recordChip,
          profileNote,
          bestPokerHand,
          bestPokerHandAsNumber,
          jackpotExplosionCount,
          placementInPlayers,
          blockedUsers,
          commission,
          friendCount,
          rightToChangeName,
          avatarNum,
          crown,
          inviteCode,
          isPinCodeActive,
          country,
        } = response.data.user;

        const userCase = response.data.user.case;
        commit(
          "user/setUserData",
          {
            id,
            name,
            chip,
            xp,
            vip,
            userCase,
            recordChip,
            profileNote,
            isPinCodeActive,
            bestPokerHand,
            bestPokerHandAsNumber,
            jackpotExplosionCount,
            inviteCode,
            commission,
            placementInPlayers,
            friendCount,
            rightToChangeName,
            avatarNum,
            crown,
            country,
            blockedUsers,
            avatar: pictureUrl,
          },
          { root: true }
        );

        return response.data.user;
      }

      return Promise.reject();
    } catch (error) {
      if (error?.response?.status === 403) {
        EventBus.$emit(EventConstants.LOGIN_BANNED_ERROR);
        return;
      }
      Logger.print("error", error);
    }
  },

  async friendInviteRoom({}, { game = "", room = 0, friendId = 0 }) {
    if (game && room > 0 && friendId > 0) {
      return await axios
        .post("/user/invite-friend-room", { game, room, friendId })
        .catch((error) => {
          console.error(error);
          toast.instance.show({
            text: i18n.t("errors.errorOccured"),
            duration: 3000,
            variant: "danger",
          });
        });
    } else {
      toast.instance.show({
        text: i18n.t("errors.errorOccured"),
        duration: 3000,
        variant: "danger",
      });
      return false;
    }
  },

  async updateGameUserInfo({}, { game = "", room = -1 }) {
    await axios.put("/user/update-game-user-info", { game, room });
  },

  async userOffline() {
    await axios.put("/user/offline");
  },

  async userOnlinePost() {
    try {
      const response = await axios.post(`/user/online`, {
        appVersion: parseInt(packageJson.version.replaceAll(".", "")),
      });
      if (response && response.data && response.data.info) {
        return response.data.info;
      }
    } catch (error) {
      Logger.print("user online error", error);
    }
  },

  /*
   * Notify online
   */
  // async startNotifyOnlineInterval() {
  //   window.__notifyOnlineInterval = 0;
  //   const setOnline = async () => {
  //     try {
  //       await axios.get(`/user/online`);
  //     } catch (error) {
  //       Logger.print("notifyOnline error", error);
  //     }
  //   };
  //   window.__notifyOnlineInterval = setInterval(setOnline, NOTIFY_ONLINE_TIME);
  //   setOnline();
  // },

  /*
   * Search and confirm user by ID
   */
  async confirmUser(store, { userId, type }) {
    try {
      const response = await axios.get(
        `/user/confirm/${parseInt(userId)}/?type=${type}`
      );
      if (response && response.data) {
        return response.data;
      }
      return false;
    } catch (error) {
      if (error.response.status) {
        return error.response.status;
      }
      Logger.print("error", error);
      throw new Error("An error occured");
    }
  },

  async getAffiliateStatus({ state, dispatch }) {
    try {
      const response = await axios.get(`/affiliates/me`);
      if (!response.data.isAffiliate) {
        state.affiliateStatus.isAffiliate = false;
        if (response.data.affiliate) {
          state.affiliateStatus.affiliateId = response.data.affiliate.id;
        }
      } else {
        state.affiliateStatus.isAffiliate = true;
        // await dispatch("getAffiliateReferences", {
        //   from: 0,
        //   to: 0,
        //   limit: 25,
        //   offSet: 0,
        // });
      }
    } catch (error) {
      Logger.print("error", error);
      return false;
    }
  },

  async getAffiliatePlayer({ commit }, id) {
    try {
      const affiliatePlayerRes = await axios.get(
        `/affiliates/my-reference/${id}`
      );

      if (
        affiliatePlayerRes &&
        affiliatePlayerRes.data &&
        affiliatePlayerRes.data.player
      ) {
        commit("setMyReferences", [affiliatePlayerRes.data.player]);
        return true;
      }
      return false;
    } catch (error) {
      // console.log("getAffiliatePlayer error:", error);
      console.error;
      if (error?.response?.status === 400) {
        toast.instance.show({
          text: i18n.t("errors.errorOccured"),
          duration: 3000,
          variant: "danger",
        });
        return false;
      }
      return false;
    }
  },

  async getAffiliateReferences({ commit }, { from, to, limit, offSet }) {
    try {
      let res;
      if (from > 0 && to > 0) {
        res = await axios.get(
          `/affiliates/my-references?from=${from}&to=${to}&limit=${limit}&offset=${offSet}`
        );
      } else {
        const params = new URLSearchParams({
          limit: limit,
          offset: offSet,
        });

        res = await axios.get(`/affiliates/my-references?${params}`);
      }

      if (res.status > 400) return;

      commit("setMyReferences", res.data.players);
      commit("setAffiliateTotal", res.data.total);

      if (res.data && typeof res.data == "object") {
        const slotsAmount =
          res.data["BAMBOO_PANDA_TOTAL_AMOUNTS"] +
          res.data["FAFAFA_TOTAL_AMOUNTS"] +
          res.data["OLYMPUS_TOTAL_AMOUNTS"] +
          res.data["TURN_LEGENDS_AMOUNTS"];

        commit("setAffiliateGameTotals", {
          poker: res.data["TURN_POKER_TOTAL_AMOUNTS"],
          blackjack: res.data["BLACK_JACK_TOTAL_AMOUNTS"],
          qq: res.data["QIU_QIU_TOTAL_AMOUNTS"],
          slots: slotsAmount,
        });
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  },

  async getAllAffiliateReferences({ commit }, { from, to, limit, offSet }) {
    try {
      let res;
      if (from > 0 && to > 0) {
        res = await axios.get(
          `/affiliates/my-references?from=${from}&to=${to}&limit=${limit}&offset=${offSet}`
        );
      } else {
        const params = new URLSearchParams({
          limit: limit,
          offset: offSet,
        });

        res = await axios.get(`/affiliates/my-references?${params}`);
      }

      if (res.status > 400) return;

      commit("setAllMyReferences", res.data.players);
      commit("setAffiliateTotal", res.data.total);

      if (res.data && typeof res.data == "object") {
        const slotsAmount =
          res.data["BAMBOO_PANDA_TOTAL_AMOUNTS"] +
          res.data["FAFAFA_TOTAL_AMOUNTS"] +
          res.data["OLYMPUS_TOTAL_AMOUNTS"] +
          res.data["TURN_LEGENDS_AMOUNTS"];

        commit("setAllAffiliateGameTotals", {
          poker: res.data["TURN_POKER_TOTAL_AMOUNTS"],
          blackjack: res.data["BLACK_JACK_TOTAL_AMOUNTS"],
          qq: res.data["QIU_QIU_TOTAL_AMOUNTS"],
          slots: slotsAmount,
        });
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  },

  async addAffiliate(store, id) {
    try {
      const response = await axios.get(`/affiliates/${id}/register`);
      return response.data;
    } catch (error) {
      Logger.print("error", error);
      return false;
    }
  },

  async changeProfileName(store, name) {
    try {
      const response = await axios.post(`/user/change-name`, {
        name: name,
      });
      return response.data;
    } catch (error) {
      Logger.print("error", error);
      return false;
    }
  },

  async changeProfileNote(store, note) {
    try {
      const response = await axios.post(`/user/set-profile-note`, {
        text: note,
      });
      return response.data;
    } catch (error) {
      Logger.print("error", error);
      return false;
    }
  },

  async changeProfileCrown(store, crownCode) {
    try {
      const response = await axios.post(`/user/change-crown`, {
        crown: crownCode,
      });
      return response.data;
    } catch (error) {
      Logger.print("error", error);
      return false;
    }
  },

  async changeProfileAvatar(store, avatar) {
    try {
      const response = await axios.post(`/user/edit-profile`, {
        avatar: avatar,
      });
      return response.data;
    } catch (error) {
      Logger.print("error", error);
      return false;
    }
  },

  async getUserGameHistory({ commit }, { game, limit = 10 }) {
    try {
      const response = await axios.get(
        `/games/user-games?game=${game}&limit=${limit}`
      );
      if (response && response.data) {
        commit("user/setUserGameHistory", response.data, { root: true });
        return response.data;
      }
      return [];
    } catch (error) {
      Logger.print("error", error);
    }
  },

  async deleteAccount({ commit }, { accountType = "" }) {
    try {
      const response = await axios.delete(`/user/delete-account`);

      if (response.status === 200 && response.data.response) {
        if (accountType === "facebook") {
          await FacebookLogin.logout();
        }
        await StorageManager.instance.clear();

        setTimeout(() => {
          location.reload();
        }, 2200);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Logger.print("error", error);
    }
  },
};
