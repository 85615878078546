// server-side messages

export default {
  // its coming when user data updated
  SET_USER_DATA: "setUserData",

  // its opening poker player sit window (for example: when u sitting with zero stack)
  OPEN_SIT_WINDOW: "openSitWindow",

  // room list
  ROOMS: "rooms",
  ROOM_PLUS: "+",
  ROOM_MINUS: "-",
  PONG: "pong",
};
