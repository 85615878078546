import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";
import store from "@/store";

const ToastManager = {
  show(options) {
    const currentNotificationPreference =
      store.getters["preferences/getValue"]("block_notification");

    if (
      store.state.preferences.list.length > 0 &&
      currentNotificationPreference == "0"
    ) {
      return;
    }
    EventBus.$emit(EventConstants.TOAST_REQUESTED, options);
  },
};

export default {
  instance: ToastManager,

  install(Vue) {
    Vue.prototype.$plugins.toast = ToastManager;
  },
};
