<template>
  <div class="lose-indicator" v-if="$data.show">
    <div class="lose-indicator-inner">
      <div class="top-glow-border"></div>
      <div class="lose-text">{{ $t("blackJack.youLost") }}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "LoseIndicator",
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["getChipFormat"]),
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
      nGetMySeat: "service/nGetMySeat",
    }),
    player() {
      return this.user;
    },
  },
  watch: {
    "nGetMySeat.lose": function (newState, oldState) {
      if (this.nGetMySeat.id !== this.player.id) return false;
      if (newState === true && oldState === false) {
        this.$plugins.audio.play("BjSit");
        this.show = true;
        return;
      }
      this.show = false;
    },
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style scoped lang="scss">
@-webkit-keyframes LoseIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes LoseIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes LoseInnerGlowAnimation {
  0% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(-2px);
  }
}
@keyframes LoseInnerGlowAnimation {
  0% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(-2px);
  }
}

.lose-indicator {
  position: absolute;
  z-index: 9;
  top: 100px;
  width: 530px;
  height: 107px;
  background-image: linear-gradient(
    90deg,
    transparent 0%,
    rgba(0, 0, 0, 0.5) 20%,
    rgba(0, 0, 0, 0.7) 50%,
    rgba(0, 0, 0, 0.5) 80%,
    transparent 100%
  );
  color: rgb(255, 255, 255);
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: center;
  animation-name: LoseIncomingAnimation;
  animation-iteration-count: 1;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  &-inner {
    position: relative;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      top: -40%;
      left: 0;
      width: 100%;
      height: 90%;
      background-image: radial-gradient(rgba($redColor, 0.8), transparent 65%);
    }
    .top-glow-border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-image: linear-gradient(
        90deg,
        transparent 20%,
        rgba($redColor, 0.8),
        transparent 80%
      );
      animation-name: LoseInnerGlowAnimation;
      animation-iteration-count: 1;
      animation-duration: 0.3s;
      animation-delay: 0.3s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }
  }
  .lose-text {
    color: rgb(255, 255, 255);
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0 2px 0 rgba(black, 0.8);
    text-align: center;
  }
}
</style>
