<template>
  <div class="outer">
    <div class="daily">
      <div class="daily-title">{{ $t("dailyEntry.dailyEntry") }}</div>
      <div class="daily-text">
        {{ $t("dailyEntr.dailyDesc") }}
      </div>
      <div class="daily-content">
        <DailyItem
          v-for="number in 7"
          :key="number"
          :index="number"
          :activeIndex="3"
        />
      </div>
      <div
        class="daily-btn ui-button ui-button-small-orange"
        @click="closeWindow"
      >
        TAMAM
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DailyLoginBonus",
  components: {
    DailyItem: () => import("./components/DailyItem.vue"),
  },
  methods: {
    closeWindow() {
      this.$store.commit("modals/hide", "dailyLoginBonus");
    },
  },
};
</script>

<style lang="scss" scoped>
.outer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  backface-visibility: hidden;
  .daily {
    width: 1600px;
    height: 900px;
    background: url("~@/assets/images/backgrounds/daily-login-bg.png") no-repeat
      50% 50%;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      height: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 50px;
      text-shadow: 0 3px 5px #000000;
      color: #ffffff;
      background: rgb(141, 37, 37);
      background: radial-gradient(
        circle,
        rgba(141, 37, 37, 1) 25%,
        rgba(89, 18, 26, 1) 75%
      );
    }
    &-text {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      color: #ffffff;
      margin-top: 80px;
    }
    &-content {
      width: 100%;
      height: 500px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    &-btn {
      cursor: pointer;
      margin-top: 30px;
    }
  }
}
</style>
