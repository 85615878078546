import axios from "@/services/api/axios";
import toast from "@/plugins/toast";
import i18n from "@/plugins/i18n";

export default {
  async sendGift(store, { type, id, to, price, roomId = "" }) {
    try {
      const payload = {
        type,
        id: String(id),
        to,
        price,
      };

      if (roomId !== "") payload.room = String(roomId);

      const response = await axios.post(`/gift/purchase`, payload);

      return response.status === 200;
    } catch (error) {
      if (error?.response?.status === 429)
        toast.instance.show({
          text: i18n.t("errors.tooManyRequests"),
          variant: "danger",
        });
      return false;
    }
  },

  async sendEmote(store, { roomId, id }) {
    try {
      const response = await axios.post(`/gift/emote`, { id, room: roomId });

      return response.status === 200;
    } catch (error) {
      if (error?.response?.status === 429)
        toast.instance.show({
          text: i18n.t("errors.tooManyRequests"),
          variant: "danger",
        });
      return false;
    }
  },

  async sendAnimation(
    store,
    { type = "animation", id, to, price, roomId = "" }
  ) {
    try {
      const payload = {
        type,
        id: String(id),
        to,
        price,
      };

      if (roomId !== "") payload.room = String(roomId);

      const response = await axios.post(`/gift/animation`, payload);

      return response.status === 200;
    } catch (error) {
      if (error?.response?.status === 429)
        toast.instance.show({
          text: i18n.t("errors.tooManyRequests"),
          variant: "danger",
        });
      return false;
    }
  },
};
