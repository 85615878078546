<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1000"
    height="600"
    :loading="loading"
    :show="true"
    :titleText="$t('searchWindow.searchUser')"
  >
    <template>
      <div class="search-user-modal">
        <div class="content">
          <div class="id-input">
            <div class="search-icon-container">
              <div class="sprite-profile-popup search-icon"></div>
            </div>
            <input
              type="number"
              :maxlength="10"
              @keyup.enter="searchUser"
              v-model="selectedId"
              :placeholder="$t('searchWindow.idOfUser')"
            />
          </div>
          <div
            class="ui-button ui-button-small-orange search-button"
            @click="searchUser"
          >
            <span> {{ $t("searchWindow.search") }}</span>
          </div>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";
import AnalyticsEvents from "@/plugins/analytics/events";

export default {
  name: "SearchUserWindow",
  components: {
    BaseWindow,
  },
  data() {
    return {
      loading: false,
      selectedId: null,
    };
  },
  methods: {
    closeWindow() {
      this.$store.commit("modals/hide", "search");
      this.selectedId = null;
    },
    async searchUser() {
      if (this.selectedId) {
        if (parseInt(this.selectedId) === this.$store.state.user.id) {
          return this.$plugins.toast.show({
            text: this.$t("searchWindow.ownId"),
            duration: 2000,
            variant: "danger",
          });
        }
        this.loading = true;
        const res = await this.$store.dispatch("friends/getProfiles", [
          this.selectedId,
        ]);
        if (!res) {
          this.$plugins.toast.show({
            text: this.$t("confirmWindow.userNotFound"),
            duration: 2000,
            variant: "danger",
          });
          this.loading = false;
          return;
        }
        this.$store.commit("friends/setSelectedUserModal", res[0]);
        this.loading = false;
        this.closeWindow();
        this.$store.commit("modals/show", "friendDetails");
        this.$adjust.trackEvent("SearchAndFindPlayer");
      } else {
        return this.$plugins.toast.show({
          text: this.$t("searchWindow.validId"),
          duration: 2000,
          variant: "danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-user-modal {
  position: relative;
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #83212d;
  border-radius: 20px;
  .content {
    margin-top: 10px;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 40px;
    }
    .id-input {
      width: 65%;
      display: flex;
      align-items: center;
      justify-content: center;
      input[type="number"] {
        -moz-appearance: textfield;
      }
      input {
        margin-right: 40px;
        margin-top: 20px;
        width: 570px;
        height: 65px;
        font-size: 36px;
        font-weight: bold;
        border-radius: 50px;
        background: #55121b;
        box-shadow: inset 1px 3px 8px black;
        border: none;
        color: rgba(rgb(255, 255, 255), 1);
        padding-left: 20px;
        display: flex;
        box-shadow: inset 1px 3px 8px black;
        align-items: center;
        justify-content: center;
        &::placeholder {
          font-size: 32px;
          color: rgba(rgb(255, 255, 255), 0.8);
          font-weight: normal;
          padding: 0px 30px;
          margin-bottom: 30px;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    .search-icon-container {
      margin-right: 10px;
      margin-top: 30px;
    }

    .search-button {
      width: 240px;
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:active {
        transform: scale(1.1);
      }
    }
  }
}
</style>
