<template>
  <div class="character-animation">
    <SpritesheetAnimation
      id="character-sprite"
      :spritesheet="require('@/assets/images/animations/dealer.png')"
      :json="require('@/assets/images/animations/dealer.json')"
      :yoyo="false"
      :fps="16"
      :required-height="325"
      :autoplay="false"
      ref="characterSprite"
    />
  </div>
</template>

<script>
import SpritesheetAnimation from "../../Utils/SpritesheetAnimation";

export default {
  name: "CharacterAnimation",
  components: { SpritesheetAnimation },
};
</script>

<style scoped lang="scss"></style>
