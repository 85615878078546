export default {
  id: -1,
  name: "",
  avatar: "",
  chip: 0,
  inviteCode: "",
  vip: {
    status: false,
    expiresAt: 0,
  },
  userCase: {
    total: 0,
  },
  xp: 0,
  affiliateStatus: {
    isAffiliate: false,
    affiliateId: -1,
    references: [],
    referencesTotal: {
      poker: 0,
      blackjack: 0,
      slots: 0,
      qq: 0,
    },
    total: 0,
  },
  allAffiliatePlayer: [],
  allAffiliateTotal: {
    poker: 0,
    blackjack: 0,
    slots: 0,
    qq: 0,
  },
  userGameHistory: [],
  blockedUsers: [],
  invitation: true,
};
