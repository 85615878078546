<template>
  <BaseWindow
    @closeWindow="closeWindow"
    :show="true"
    :showCloseButton="true"
    :preventClickOutside="true"
    :titleText="$t('socialWindow.friends')"
  >
    <div class="window">
      <div class="window-header">
        <div
          class="window-header-item"
          :class="currentPage === 'friends' ? 'profile-sprite tab1' : ''"
          @click="currentPage = 'friends'"
        >
          <span class="text">{{ $t("socialWindow.friends") }}</span>
          <span
            class="tab-line"
            :class="currentPage === 'friends' ? 'profile-sprite tabline' : ''"
          ></span>
        </div>
        <div
          class="window-header-item"
          :class="currentPage === 'chip-transfer' ? 'profile-sprite tab2' : ''"
          @click="currentPage = 'chip-transfer'"
        >
          <span class="text">{{ $t("friends.chipTransfer") }}</span>
          <span
            class="tab-line"
            :class="
              currentPage === 'chip-transfer' ? 'profile-sprite tabline' : ''
            "
          ></span>
        </div>
        <div
          class="window-header-item"
          :class="
            currentPage === 'friend-requests' ? 'profile-sprite tab2' : ''
          "
          @click="currentPage = 'friend-requests'"
        >
          <span class="text request">{{ $t("friends.friendRequests") }}</span>
          <span
            class="tab-line"
            :class="
              currentPage === 'friend-requests' ? 'profile-sprite tabline' : ''
            "
          ></span>
        </div>
      </div>
      <div class="window-friends" v-if="currentPage === 'friends'">
        <div class="window-top">
          <input
            class="friends-search"
            type="text"
            :placeholder="$t('socialWindow.searchFriend')"
            v-model="friendSearchText"
          />
          <div class="online">
            <div class="online-check" @click="onlineSelect">
              <CheckMark class="ok-icon" v-show="onlineValue" />
            </div>
            <span class="online-text" @click="onlineSelect">{{
              $t("friends.online")
            }}</span>
          </div>
          <div class="friend-count">
            <span class="friend-count-text">
              {{
                `${$t("socialWindow.friends")}: ${
                  $store.state.friends.friendsList.total
                }`
              }}
            </span>
          </div>
        </div>
        <div class="window-content">
          <template v-if="friendList.length > 0">
            <FriendsItem
              :friend="friend"
              v-for="(friend, index) in friendList"
              :key="index"
              @goTable="joinGameFunc"
              @sendMessage="sendNewMessage"
            />
            <div class="window-content-more" v-if="showMoreStatus">
              <div
                class="ui-button ui-button-orange window-content-more-text"
                @click="getMoreFriends"
              >
                {{ $t("chat.showMore") }}
              </div>
            </div>
          </template>
          <div class="no-data" v-else>
            {{
              onlineValue
                ? $t("table.noHaveOnlineFriends")
                : $t("table.noHaveFriends")
            }}
          </div>
        </div>
      </div>
      <div class="window-chip-transfer" v-if="currentPage === 'chip-transfer'">
        <div
          class="window-chip-transfer-top"
          v-if="innerGetHistoryList.length > 0"
        >
          <div class="window-chip-transfer-top-action">
            <div
              class="window-chip-transfer-top-action-item"
              :class="chipTransferHeaderValue === 'all' ? 'active' : ''"
              @click="chipTransferHeaderValue = 'all'"
            >
              {{ $t("tableList.all") }}
            </div>
            <div
              class="window-chip-transfer-top-action-item"
              :class="chipTransferHeaderValue === 'incoming' ? 'active' : ''"
              @click="chipTransferHeaderValue = 'incoming'"
            >
              {{ $t("friends.incoming") }}
            </div>
            <div
              class="window-chip-transfer-top-action-item"
              :class="chipTransferHeaderValue === 'outgoing' ? 'active' : ''"
              @click="chipTransferHeaderValue = 'outgoing'"
            >
              {{ $t("friends.sent") }}
            </div>
          </div>
          <datepicker
            v-model="startDate"
            :placeholder="$t('affiliate.startDate')"
            wrapper-class="date-picker-wrapper"
            input-class="window-chip-transfer-top-start-date"
            class="window-chip-transfer-top-start-date"
            calendar-class="date-picker-calendar"
          ></datepicker>
          <datepicker
            v-model="endDate"
            :placeholder="$t('affiliate.endDate')"
            wrapper-class="date-picker-wrapper"
            input-class="window-chip-transfer-top-end-date"
            class="window-chip-transfer-top-end-date"
            calendar-class="date-picker-calendar"
          ></datepicker>

          <input
            type="text"
            class="window-chip-transfer-id"
            placeholder="ID"
            v-model="searchId"
          />

          <div
            class="window-chip-transfer-top-search profile-sprite transfer-button"
            @click="getFilterHistory(startDate, endDate, searchId)"
          >
            {{ $t("friends.search") }}
          </div>
        </div>
        <div class="window-chip-transfer-content">
          <template v-if="innerGetHistoryList.length > 0">
            <ChipTransferItem
              :index="index"
              v-for="(chipHistory, index) in innerGetHistoryList"
              :key="index"
              :transferItem="chipHistory"
            />
          </template>
          <div class="history-no-data" v-else>
            {{ $t("socialWindow.noChipTransfer") }}
          </div>
        </div>
      </div>
      <div
        class="window-friend-requests"
        v-if="currentPage === 'friend-requests'"
      >
        <div
          class="window-friend-requests-line"
          v-if="getFriendRequests.length > 0"
        >
          <FriendsRequestItem
            :index="index"
            v-for="(friendRequest, index) in getFriendRequests"
            :player="friendRequest"
            :key="index"
          />
        </div>
        <div class="window-friend-requests-no-data" v-else>
          {{ $t("socialWindow.noFriendRequest") }}
        </div>
      </div>

      <SendMessage
        :show="showSendMessagePopUp"
        :receiverId="messageSendUserId"
        @closeWindowSendMessage="closeSendMessage"
      />
    </div>
  </BaseWindow>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Datepicker from "vuejs-datepicker";

import Helpers from "@/mixin/Helpers";

export default {
  name: "FriendsWindow",
  mixins: [Helpers],
  components: {
    Datepicker,
    BaseWindow: () => import("../BaseWindow"),
    FriendsItem: () => import("./components/FriendsItem"),
    ChipTransferItem: () => import("./components/ChipTransferItem"),
    FriendsRequestItem: () => import("./components/FriendsRequestItem"),
    SendMessage: () =>
      import("@/components/Misc/GlobalWindows/ChatComponents/SendMessage.vue"),
    CheckMark: () => import("@/components/Icons/CheckMark"),
  },
  watch: {
    currentPage(val) {
      if (val === "friend-requests") {
        this.$store.dispatch("friends/getRequests");
      } else if (val === "chip-transfer") {
        this.$store.dispatch("chipTransfer/getHistory", 0);
      } else if (val === "friends") {
        this.$store.dispatch("friends/getFriends", 0);
      }
      this.$plugins?.audio?.play("Button");
    },
    // "$store.state.friends.friendsList.total": {
    //   handler(val) {

    //   },
    //   deep: true,
    // },
  },
  computed: {
    ...mapState(["user", "jackpots", "scene"]),
    ...mapGetters({
      getFriendRequests: "friends/getFriendRequests",
      getHistoryList: "chipTransfer/getChipHistoryList",
      texasRooms: `service/getTexasHoldemRooms`,
      omahaRooms: `service/getOmahaRooms`,
    }),
    showMoreStatus() {
      if (this.offSet === 0) {
        return this.$store.state.friends.friendsList.total > 25;
      } else if (this.offSet > 0) {
        return this.$store.state.friends.friendsList.total > this.offSet * 25;
      }
    },
    friendList() {
      let innerFriends = this.$store.state.friends.friendsList.list;
      if (this.onlineValue) {
        innerFriends = innerFriends.filter((friend) => friend.user.online);
      }

      if (this.friendSearchText !== "") {
        if (this.friendSearchText.length > 0) {
          const firstAlpha = this.friendSearchText[0];

          innerFriends = innerFriends.filter((i) => {
            if (!this.isNumeric(firstAlpha)) {
              if (this.friendSearchText.length > 2) {
                return i.user.name
                  .toLowerCase()
                  .includes(this.friendSearchText.toLowerCase());
              }
              return i.user.name
                .toLowerCase()
                .startsWith(this.friendSearchText.toLowerCase());
            } else {
              return i.user.id
                .toString()
                .includes(this.friendSearchText.toLowerCase());
            }
          });
        }
      }
      return innerFriends;
    },
    innerGetHistoryList() {
      if (this.chipTransferHeaderValue === "incoming") {
        return this.getHistoryList.filter(
          (item) => item.to === this.$store.state.user.id
        );
      } else if (this.chipTransferHeaderValue === "outgoing") {
        return this.getHistoryList.filter(
          (item) => item.from === this.$store.state.user.id
        );
      }
      return this.getHistoryList;
    },
  },
  data() {
    return {
      chipTransferHeaderValue: "all",
      currentPage: "friends",
      onlineValue: false,
      showSendMessagePopUp: false,
      messageSendUserId: -1,
      startDate: null,
      endDate: null,
      searchId: null,
      offSet: 0,
      friendSearchText: "",
      defaultStartDate: null,
      defaultEndDate: null,
    };
  },
  methods: {
    getMoreFriends() {
      if (this.$store.state.friends.friendsList.total > this.offSet + 1 * 25) {
        this.offSet += 1;
        this.$store.dispatch("friends/getFriends", this.offSet);
      }
    },
    joinGameFunc(friend) {
      if (
        this.$constant.gameType.TEXAS === friend.user.game ||
        this.$constant.gameType.OMAHA === friend.user.game
      ) {
        const inviteRoom = this.getInviteRoom({
          game: friend.user.game,
          room: friend.user.room,
        });

        if (
          inviteRoom &&
          this.user.chip < inviteRoom.metadata.minBuy &&
          !this.user.vip.status
        ) {
          if (this.getRealPlatform !== "web" && this.scene === "Lobby") {
            //shop açılacak
            this.$store.commit("modals/show", "shop");
          }
          //Bu Item silinebilir
          this.$plugins.toast.show({
            text: this.$t("playerActions.enoughChips"),
            duration: 2500,
          });
          return;
        }
      }

      this.$store.commit("modals/hideModals", [
        "updateNotesWindow",
        "forceUpdateWindow",
      ]);

      switch (friend.user.game) {
        case this.$constant.gameType.TEXAS:
          this.$store.dispatch("service/joinPokerRoom", {
            roomId: friend.user.room,
            directedGame: friend.user.game,
          });
          break;
        case this.$constant.gameType.OMAHA:
          this.$store.dispatch("service/joinPokerRoom", {
            roomId: friend.user.room,
            directedGame: friend.user.game,
          });
          break;
        case this.$constant.gameType.BLACKJACK:
          this.$store.dispatch("service/joinToBlackjackRoom");
          break;
        case this.$constant.gameType.TURN_LEGENDS:
          this.$emit("slotPlay", "turnLegends");
          break;
        case this.$constant.gameType.BAMBOO_PANDA:
          this.$emit("slotPlay", "bambooPanda");
          break;
        case this.$constant.gameType.FAFAFA:
          this.$emit("slotPlay", "fafafa");
          break;
        case this.$constant.gameType.OLYMPUS:
          this.$emit("slotPlay", "olympus");
          break;

        default:
          console.log("Game not found !");
          break;
      }
    },
    getInviteRoom(invite) {
      if (invite.game && invite.room) {
        if (this.$constant.gameType.TEXAS === invite.game) {
          return this.texasRooms.find((room) => room.id == invite.room);
        } else if (this.$constant.gameType.OMAHA === invite.game) {
          return this.omahaRooms.find((room) => room.id == invite.room);
        }
      }
      return false;
    },
    sendNewMessage(id) {
      if (typeof id === "number" && id > 0) {
        this.messageSendUserId = id;
        this.showSendMessagePopUp = true;
        return;
      }

      this.$plugins.toast.show({
        text: $t("confirmWindow.userNotFound"),
        duration: 2500,
      });
    },
    async getFilterHistory(startDate, endDate, searchId) {
      var sDate = Number;
      var eDate = Number;
      if (startDate != null && endDate != null) {
        sDate = startDate.getTime() / 1000;
        eDate = endDate.getTime() / 1000;
      } else {
        sDate = this.defaultStartDate.getTime() / 1000;
        eDate = this.defaultEndDate.getTime() / 1000;
      }

      const params = {
        userId: searchId,
        startDate: sDate,
        endDate: eDate,
        offSet: 0,
      };
      const filter = await this.$store.dispatch(
        "chipTransfer/getFilterHistory",
        params
      );

      this.startDate = null;
      this.endDate = null;
      this.searchId = null;
    },
    onlineSelect() {
      this.onlineValue = !this.onlineValue;
    },
    closeWindow() {
      this.$store.commit("modals/hide", "friendsWindow");
    },
    closeSendMessage() {
      this.showSendMessagePopUp = false;
    },
  },
  mounted() {
    const start = new Date();
    const last = new Date();
    start.setFullYear(start.getFullYear() - 1);
    this.defaultStartDate = start;
    this.defaultEndDate = last;
  },
};
</script>

<style lang="scss" scoped>
.window {
  width: 100%;
  height: 92%;
  z-index: 1;
  &-header {
    height: 9%;
    display: flex;
    flex-direction: row;
    width: 99%;
    position: relative;
    left: 10px;
    &-item {
      position: relative;
      min-width: 271px;
      color: #cf707f;
      font-size: 37px;
      font-weight: bold;
      text-transform: uppercase;
      text-shadow: 3px 3px 3px black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: start;
      text-transform: uppercase;
      line-height: 44px;
      min-height: 97px;
      .request {
        top: -1px;
      }
      .text {
        position: absolute;
        left: 30px;
        min-width: 260px;
        text-align: center;
      }
      &:nth-child(2) {
        min-width: 300px;
        .tab-line {
          position: absolute;
          bottom: 10px;
          left: 30px;
        }
      }
      &:nth-child(3) {
        min-width: 300px;
        .tab-line {
          position: absolute;
          bottom: 10px;
          left: 30px;
        }
        .text {
          position: absolute;
          left: 30px;
        }
      }
      .tab-line {
        position: absolute;
        bottom: 10px;
        left: 0px;
      }
    }
    &-item:nth-child(1) {
      .text {
        position: absolute;
        left: 0px;
      }
    }

    .profile-sprite {
      color: #ffffff;
    }
  }
  .window-friends {
    background: rgb(92, 18, 26);
    background: linear-gradient(
      0deg,
      rgba(92, 18, 26, 1) 0%,
      rgba(147, 38, 52, 1) 100%
    );
    height: 90%;
    width: 99%;
    position: relative;
    left: 10px;
    border-end-end-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .window-top {
      height: 10%;
      width: 97%;
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      align-items: center;
      border-bottom: 1px solid #000000;
      margin: 0 30px;
      padding-bottom: 10px;
      z-index: 10;
      .friends-search {
        width: 350px;
        height: 70px;
        margin-left: 20px;
        border-radius: 35px;
        padding-left: 20px;
        font-size: 34px;
        background: #55121b;
        box-shadow: inset 1px 3px 8px black;
        color: #ffffff;
      }
      .friends-search::placeholder {
        font-size: 32px;
        color: #ffffff;
      }
      .online {
        width: 300px;
        margin-left: 30px;
        display: flex;
        flex-direction: row;
        z-index: 20;
        &-check {
          height: 60px;
          width: 60px;

          border-radius: 10px;
          background: #55121b;
          box-shadow: inset 1px 3px 8px black;
          .ok-icon {
            position: relative;
            top: 5px;
            left: 5px;
            width: 50px;
            height: 50px;
            fill: #ffffff;
          }
        }
        &-text {
          font-size: 36px;
          text-shadow: 0px 2px 3px #3b3a3a;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 20px;
        }
      }
      .friend-count {
        position: absolute;
        right: 50px;
        &-text {
          font-size: 42px;
          color: #ffffff;
        }
      }
    }
    .window-content {
      width: 100%;
      height: 86%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      overflow-y: scroll;
      &-more {
        width: 100%;
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        &-text {
          font-size: 36px;
          text-shadow: 0px 3px 2px black;
          cursor: pointer;
          transition-property: width;
          transition-duration: 2s;
          transition-timing-function: linear;
          transition-delay: 1s;
        }
        &-text:active {
          transform: scale(1.2);
        }
      }
      .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        height: 80%;
      }
    }
  }
  .window-chip-transfer {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: rgb(89, 18, 26);
    background: linear-gradient(
      0deg,
      rgba(89, 18, 26, 1) 0%,
      rgba(141, 37, 50, 1) 100%
    );
    .window-chip-transfer-id {
      background-color: #3b0b14;
      width: 210px;
      height: 65px;
      border-radius: 15px;
      margin-left: 40px;
      font-size: 24px !important;
      color: #fff;
      text-align: center;
    }
    .window-chip-transfer-id::placeholder {
      color: #fff;
      font-size: 24px;
      text-align: center;
    }
    &-content {
      .history-no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90%;
        font-size: 36px;
      }
    }
    &-top {
      width: 100%;
      height: 10%;
      display: flex;
      flex-direction: row;
      align-items: center;
      &-action {
        width: 900px;
        height: 70px;
        background-color: #3b0b14;

        border-radius: 10px;
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        font-size: 30px;
        &-item {
          width: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 5px 0px;
        }
        &-item:nth-child(1),
        &-item:nth-child(2) {
          border-right: 2px solid #8b2332;
        }
        .active {
          border-radius: 10px;
          background: rgb(233, 106, 44);
          background: linear-gradient(
            0deg,
            rgba(233, 106, 44, 1) 0%,
            rgba(245, 140, 48, 1) 100%
          );
        }
      }

      &-end-date,
      &-start-date,
      &-id {
        font-size: 28px;
        width: 180px;
        height: 70px;
        border-radius: 15px;
        background-color: rgba($color: #3b0b14, $alpha: 1);
        margin-left: 40px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
      .transfer-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        color: #ffffff;
        cursor: pointer;
        margin-left: 35px;
      }
    }
    &-content {
      width: 100%;
      height: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;
    }
  }
  .window-friend-requests {
    height: 88%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: rgb(89, 18, 26);
    background: linear-gradient(
      0deg,
      rgba(89, 18, 26, 1) 0%,
      rgba(141, 37, 50, 1) 100%
    );
    overflow-y: scroll;
    padding-top: 20px;
    &-line {
      width: 99%;
      min-height: 1px;
      position: relative;
      top: -10px;
      background-color: #000000;
      border-radius: 10px;
    }
    &-no-data {
      min-height: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
    }
  }
}
</style>
<style>
.window-chip-transfer-top-start-date {
  font-size: 22px;
  background-color: #3b0b14;
  outline: none;
  border: none;
  color: #fff !important;
  width: 100%;
  text-align: center;
}
.window-chip-transfer-top-start-date::placeholder {
  color: #fff !important;
}
.window-chip-transfer-top-end-date {
  font-size: 22px;
  background-color: #3b0b14;
  outline: none;
  border: none;
  color: #fff !important;
  width: 100%;
  text-align: center;
}
.window-chip-transfer-top-end-date::placeholder {
  color: #fff !important;
}
.window-chip-transfer .vdp-datepicker__calendar {
  background: black !important;
}
.window-chip-transfer .today {
  border: 1px solid orange !important;
}

.window-chip-transfer .up:hover {
  background: #3b0b14 !important;
}
.window-chip-transfer .date-picker-calendar {
  margin-top: 500px !important;
  height: 600px !important;
  width: 800px !important;
}
.window-chip-transfer .day-header {
  font-size: 30px !important;
}
.window-chip-transfer .day {
  margin-top: 30px !important;
  font-size: 45px !important;
}
.window-chip-transfer .cell {
  height: 55px !important;
  line-height: 50px !important;
}
.window-chip-transfer .vdp-datepicker__calendar {
  background: #3b0b13 !important;
}
</style>
