var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"seat",class:_vm.getClasses},[(!_vm.isAnyoneSitting)?_c('div',{staticClass:"seat-sit-button",on:{"click":_vm.openSitWindow}},[(!_vm.nAmISitting)?_c('span',{staticClass:"sprite-table sit-icon"}):_vm._e(),(_vm.nAmISitting)?_c('span',{staticClass:"sprite-table invite-icon"}):_vm._e()]):_c('div',{staticClass:"seat-inner"},[(_vm.getActiveRoomState.currentTurn === _vm.data.position)?_c('Timer',{on:{"avatarClick":_vm.showPlayerDetails}}):_vm._e(),_c('Emote',{on:{"emoteClick":_vm.showPlayerDetails}}),(_vm.data.lastAction === 5)?_c('AllinAnimation',{on:{"avatarClick":_vm.showPlayerDetails}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.scroolingTextWinChip.length > 0),expression:"scroolingTextWinChip.length > 0"}]},_vm._l((_vm.scroolingTextWinChip),function(item,index){return _c('ScroolingText',{key:index,attrs:{"amount":item}})}),1),(
        _vm.data.lastAction &&
        !_vm.data.bet &&
        _vm.lastActionTurn === _vm.getActiveRoomState.stage
      )?_c('div',{staticClass:"seat-action",class:[..._vm.getIconClasses, `${_vm.seatIsMe ? 'action-me' : ''}`]},[_c('div',{staticClass:"seat-action-icon flex-center",class:`seat-action-${
          _vm.chipIsMeAndRaise
            ? 'raise'
            : _vm.data.id === _vm.user.id
            ? 'none'
            : _vm.lastActionText
        }`},[(
            (_vm.lastActionText == 'check' ||
              _vm.lastActionText == null ||
              _vm.lastActionText == undefined) &&
            _vm.lastActionText !== 'raise' &&
            _vm.lastActionText !== 'allin'
          )?_c('CheckMark',{staticClass:"svg-check"}):_vm._e(),(
            _vm.chipIsMeAndRaise ||
            _vm.lastActionText === 'raise' ||
            _vm.lastActionText === 'allin'
          )?_c('ChevronDown',{staticClass:"chevron-down"}):_vm._e()],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.messageOn),expression:"messageOn"}],staticClass:"talk-bubble tri-right btm-right-in border tip-text-popup"},[_c('div',{staticClass:"talktext"},[_c('span',{staticClass:"scroll-text"},[_vm._v(" "+_vm._s(_vm.messageText)+" ")])])]),_c('Avatar',{attrs:{"url":_vm.data.avatar,"crown":_vm.crownCondition(_vm.data.crown, _vm.data.vip),"size":205,"lazy":true},nativeOn:{"click":function($event){return _vm.showPlayerDetails.apply(null, arguments)}}}),(_vm.nAmISitting && !_vm.isSeatGift)?_c('div',{staticClass:"seat-inner-gift flex-center",class:[
        _vm.giftArrived ? '' : 'seat-inner-gift-bg',
        _vm.getActiveRoomState.gameType === 'omaha_poker' ? 'omaha-gift' : '',
      ],on:{"click":_vm.giftWindowOpen}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.giftArrived),expression:"!giftArrived"}],staticClass:"sprite-table gift-box"}),_c('Gift',{directives:[{name:"show",rawName:"v-show",value:(_vm.giftArrived),expression:"giftArrived"}],attrs:{"playerId":_vm.data.id},on:{"incomingGift":_vm.incomingGift}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.in || _vm.data.id === _vm.user.id),expression:"data.in || data.id === user.id"}],staticClass:"seat-cards",class:[_vm.data.cards.length > 2 ? 'four-cards' : ''],on:{"click":_vm.showPlayerDetails}},[_vm._l((_vm.data.cards),function(seatCard,seatCardIndex){return [(seatCard.suit !== '' && _vm.cardFlipped)?_c('Card',{key:'seat-card-' + seatCardIndex,staticClass:"seat-card",attrs:{"suit":seatCard.suit,"value":seatCard.value}}):_vm._e(),(seatCardIndex === 0 && !(seatCard.suit !== '' && _vm.cardFlipped))?_c('div',{key:`card-close-${seatCardIndex}`,staticClass:"sprite-table closed-cards"}):_vm._e()]})],2),_c('div',{staticClass:"player-details",class:[_vm.data.cards.length > 2 ? 'details-four' : ''],on:{"click":_vm.showPlayerDetails}},[(_vm.data.id !== _vm.$store.state.user.id)?_c('div',{staticClass:"player-details-user-name"},[_vm._v(" "+_vm._s(_vm.data.name)+" ")]):_vm._e(),_c('div',{staticClass:"player-details-user-stack"},[_c('span',{staticClass:"player-details-user-stack-dollar"}),_vm._v(" "+_vm._s(_vm.getShortChipFormat(_vm.getDataStack))+" ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }