var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickedOutsideWindow),expression:"onClickedOutsideWindow"}],staticClass:"invite-window-bg"},[_c('div',{staticClass:"invite-window-inner-bg"},[_c('div',{staticClass:"invite-container"},[_c('div',{staticClass:"tab-selectors"},[_c('div',{staticClass:"selector active"},[_c('span',[_vm._v(_vm._s(_vm.$t("table.onlineFriends")))])]),_c('div',{staticClass:"close-container",on:{"click":_vm.closeWindow}},[_vm._m(0)])]),_c('div',{staticClass:"invite-item-container no-click-outside"},[(_vm.innerFriends.length < 1)?_c('div',{staticClass:"no-data"},[_vm._v(" "+_vm._s(_vm.$t("table.noHaveOnlineFriends"))+" ")]):_vm._e(),_vm._l((_vm.innerFriends),function(friend,index){return [_c('div',{key:index,staticClass:"invite-item",style:({
              minHeight: `${
                _vm.friendSameGameAndTable(friend.user.game, friend.user.room)
                  ? '90px'
                  : '124px'
              }`,
            })},[_c('div',{staticClass:"avatar no-click-outside"},[_c('Avatar',{attrs:{"size":123,"url":friend.user.avatar}})],1),_c('div',{staticClass:"player-info no-click-outside"},[_c('span',{staticClass:"player-info-name"},[_vm._v(" "+_vm._s(friend.user.name)+" ")]),_c('span',{staticClass:"player-info-game"},[_vm._v(_vm._s(_vm.gameTextComputed(friend.user.game)))]),(
                  !_vm.friendSameGameAndTable(friend.user.game, friend.user.room)
                )?_c('div',{staticClass:"buttons no-click-outside"},[(_vm.joinIsDisable(friend.user.game))?_c('div',{staticClass:"join sprite-table-pop-up go-button",on:{"click":function($event){return _vm.joinFriendGame(friend.user.game, friend.user.room)}}},[_c('span',{staticClass:"join-text"},[_vm._v(_vm._s(_vm.$t("progressiveJackpot.join")))])]):_vm._e(),_c('div',{staticClass:"invite sprite-table-pop-up invite-button",on:{"click":function($event){return _vm.inviteFriend(
                      friend.user.id ? friend.user.id : friend.user.userId
                    )}}},[_c('span',{staticClass:"invite-text"},[_vm._v(" "+_vm._s(_vm.$t("general.invite"))+" ")])])]):_vm._e()])])]})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"close-button flex-center"},[_c('span',{staticClass:"sprite-table-pop-up exit-icon"})])
}]

export { render, staticRenderFns }