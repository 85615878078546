<template>
  <div class="history-container">
    <template v-if="getUserGameHistory.length < 1">
      <div class="no-log">
        {{ $t("historyWindow.anyHistory") }}
      </div>
    </template>
    <template v-else>
      <div class="history-top">
        <!-- <div class="hand-history-header" v-if="$store.state.modals.userDetails">
          <div class="hand-history-header-tab">
            <div class="hand-history-header-tabs">
              <div
                class="hand-history-header-tabs-item"
                :class="handHistoryHeaderValue === 'holdem' ? 'active' : ''"
                @click="handHistoryHeaderValue = 'holdem'"
              >
                Texas Hold'em
              </div>
              <div
                class="hand-history-header-tabs-item"
                :class="handHistoryHeaderValue === 'omaha' ? 'active' : ''"
                @click="handHistoryHeaderValue = 'omaha'"
              >
                Omaha
              </div>
              <div
                class="hand-history-header-tabs-item"
                :class="handHistoryHeaderValue === 'blackjack' ? 'active' : ''"
                @click="handHistoryHeaderValue = 'blackjack'"
              >
                Blackjack
              </div>
            </div>
          </div>
        </div> -->
        <div class="selector">
          <div class="arrow sprite-profile-popup language-ok"></div>
          <select v-model="selectedLog" class="source-selector">
            <option
              v-for="(log, logIndex) in innerGetUserGameHistory"
              :key="logIndex"
              :value="log"
            >
              {{
                `${log.game.toUpperCase()} Table ${
                  log.room
                } Date ${getFormattedDate(log.createdAt)}`
              }}
            </option>
          </select>
        </div>
      </div>
      <div class="history-header">
        <div class="head players">{{ $t("tableList.players") }}</div>
        <div class="head hand">{{ $t("historyWindow.hand") }}</div>
        <div class="head pre-flop">{{ $t("historyWindow.preFlop") }}</div>
        <div class="head flop">{{ $t("historyWindow.flop") }}</div>
        <div class="head turn">{{ $t("historyWindow.turn") }}</div>
        <div class="head river">{{ $t("historyWindow.river") }}</div>
        <div class="head winner">{{ $t("historyWindow.winner") }}</div>
        <span class="sprite-profile-popup line"></span>
      </div>
      <div class="card-container" v-if="selectedLog">
        <div class="cards flop">
          <div
            class="card-sprite"
            :class="getCardClasses(selectedLog.data.cards[0])"
          ></div>
          <div
            class="card-sprite"
            :class="getCardClasses(selectedLog.data.cards[1])"
          ></div>
          <div
            class="card-sprite"
            :class="getCardClasses(selectedLog.data.cards[2])"
          ></div>
        </div>
        <div
          class="card turn card-sprite"
          :class="getCardClasses(selectedLog.data.cards[3])"
        ></div>
        <div
          class="card river card-sprite"
          :class="getCardClasses(selectedLog.data.cards[4])"
        ></div>
      </div>
      <div class="history-content">
        <GameHistory
          v-for="(player, playerIndex) in getPlayers"
          :key="playerIndex"
          :player="player"
          :actions="getPlayerActions(player.id)"
          :winner="getWinnerStatus(player.id)"
          :stack="getStartingStack(player.id)"
          :gameIsCompleted="gameIsCompleted"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BaseWindow from "../BaseWindow";
import GameHistory from "./components/GameHistory";

export default {
  name: "HandHistory",
  components: {
    BaseWindow,
    GameHistory,
  },
  data() {
    return {
      selectedLog: null,
      loading: false,
      gameIsCompleted: false,
      handHistoryHeaderValue: "holdem",
      innerLogs: [],
    };
  },
  watch: {
    "$store.state.modals.userDetails": {
      handler(val) {
        if (val) {
          this.innerLogs = this.$store.dispatch("user/getUserGameHistory", {
            game: "poker",
          });
        }
      },
    },
    async handHistoryHeaderValue(val) {
      if (val == "texas") {
        this.innerLogs = await this.$store.dispatch("user/getUserGameHistory", {
          game: "poker",
        });
      } else if ((val = "blackjack")) {
        // this.innerLogs = await this.$store.dispatch("user/getUserGameHistory", {
        //   game: "blackjack",
        // });
      }
    },
    "$store.state.modals.userDetails": {
      handler(val) {
        if (val) {
          this.onShowHistoryWindow();
        }
      },
    },
    "$store.state.modals.history": {
      handler(val) {
        if (val) {
          this.onShowHistoryWindow();
        }
      },
    },
    selectedLog() {
      if (
        !this.selectedLog ||
        !this.selectedLog.data ||
        !this.selectedLog.data.actions
      ) {
        return;
      }
      let myPlayerActions = this.selectedLog.data.actions.filter(
        (action) => action.type === "player_action"
      );

      const pNotFoldStageFour = myPlayerActions.filter(
        (playerAct) =>
          // playerAct.payload.action !== 4 && playerAct.payload.stage === 4
          playerAct.payload.action !== 4
      );
      let differentPlayers = [];
      pNotFoldStageFour.forEach((action) => {
        const notFind =
          differentPlayers.filter(
            (diffP) => diffP.payload.playerId === action.payload.playerId
          ).length < 1;
        const userDidSayFold =
          myPlayerActions.filter(
            (act) =>
              act.payload.playerId === action.payload.playerId &&
              act.payload.action === 4
          ).length > 0;
        if (notFind && !userDidSayFold) {
          differentPlayers.push(action);
        }
      });
      this.gameIsCompleted = differentPlayers.length > 1;
      this.$adjust.trackEvent("HandHistoryGameChange");
    },
  },
  computed: {
    ...mapGetters("user", ["getUserGameHistory"]),
    getFormattedDate() {
      return (value) => {
        return this.$plugins.moment.unix(value).format("YYYY/MM/DD HH:mm");
      };
    },
    innerGetUserGameHistory() {
      if (this.innerLogs.length > 0) {
        return this.innerLogs;
      }
      return this.getUserGameHistory;
    },
    getPlayers() {
      if (
        !this.selectedLog ||
        !this.selectedLog.data ||
        !this.selectedLog.data.actions
      ) {
        return [];
      }

      return this.selectedLog.data.actions
        .find((a) => a.type === "game_start")
        .payload.players.map((a) => {
          const inPlayers = this.selectedLog.data.players.find(
            (p) => p.id === a.id
          );
          if (!inPlayers) return null;

          return {
            ...a,
            ...inPlayers,
          };
        })
        .filter((f) => f != null);
    },
    // isGameRiverStage() {
    //   if (
    //     this.selectedLog &&
    //     this.selectedLog.data &&
    //     this.selectedLog.data.cards &&
    //     this.selectedLog.data.cards.length > 4
    //   ) {
    //     return true;
    //   }
    //   return false;
    // },
  },
  methods: {
    closeWindow() {
      this.$store.commit("modals/hide", "history");
    },
    getCardClasses(card) {
      if (
        typeof card === "undefined" ||
        typeof card.value === "undefined" ||
        typeof card.suit === "undefined"
      )
        return "";
      const value = String(card?.value || "").replace("T", "10");
      return String(card.suit + value).toLowerCase();
    },
    getPlayerActions(id) {
      let actions = [[], [], [], []];
      let playerActions = this.selectedLog.data.actions.filter(
        (action) =>
          action.type === "player_action" && action.payload.playerId === id
      );
      playerActions.forEach((action) => {
        if (action.payload.stage) {
          actions[action.payload.stage - 1].push(action);
        }
      });
      return actions;
    },
    getWinnerStatus(id) {
      let isWinner = false;
      let amount = 0;
      this.selectedLog.data.winners.forEach((winner) => {
        if (winner.playerId === id) {
          isWinner = true;
          amount = winner.amount;
        }
      });
      if (!isWinner) return false;
      return amount;
    },
    getStartingStack(id) {
      let player = this.selectedLog.data.actions
        .find((action) => action.type === "game_start")
        .payload.players.find((player) => player.id === id);
      return player?.stack || 0;
    },
    async onShowHistoryWindow() {
      this.loading = true;
      const res = await this.$store.dispatch("user/getUserGameHistory", {
        game: "poker",
        limit: 15,
      });

      this.selectedLog = this.getUserGameHistory[0];

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.history-container {
  position: relative;
  width: 98%;
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #8f2532;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  bottom: 20px;
  .history-top {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
    .hand-history-header {
      width: 800px;
      height: 100px;
      display: flex;
      justify-content: center;
      flex-direction: row;
      z-index: 2;
      &-tab {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        &s {
          width: 950px;
          height: 70px;
          background-color: rgba(#000000, 0.5);
          border-radius: 10px;
          display: flex;
          flex-direction: row;
          &-item {
            width: 33.3333%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 36px;
            text-shadow: 0 2px 3px #000000;
          }
          .active {
            background: rgb(233, 107, 44);
            background: linear-gradient(
              0deg,
              rgba(233, 107, 44, 1) 0%,
              rgba(244, 138, 48, 1) 100%
            );
            border-radius: 10px;
          }
        }
      }
    }

    .selector {
      width: 650px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .arrow {
        position: absolute;
        right: 30px;
        top: 50px;
        transform: scale(0.7);
      }
      .source-selector {
        width: 650px;
        height: 90px;
        font-size: 30px;
        font-weight: bold;
        border-radius: 10px;
        background: rgba(black, 0.6);
        color: rgb(255, 255, 255);
        padding-left: 20px;
        border: none;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        margin-top: 20px;
        option {
          font-size: 15px;
          line-height: 20px;
          font-weight: normal;
        }
      }
    }
  }
  .history-header {
    position: relative;
    width: 90%;
    height: 80px;
    display: flex;
    font-size: 40px;
    justify-content: center;
    align-items: center;
    .line {
      position: absolute;
      bottom: 0;
      transform: scale(1.1);
    }
    .head {
      text-align: center;
      &.players {
        width: 15%;
      }
      &.hand {
        width: 12%;
      }
      &.pre-flop {
        width: 12%;
      }
      &.flop {
        width: 10%;
      }
      &.turn {
        width: 10%;
      }
      &.river {
        width: 10%;
      }
      &.winner {
        width: 25%;
      }
    }
  }
  .card-container {
    position: relative;
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    .cards {
      position: absolute;
      width: 585px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 590px;
      transform: scale(0.55);
      .card-sprite {
        margin-left: 10px;
      }
    }
    .card {
      position: absolute;
      transform: scale(0.55);
      &.turn {
        left: 1020px;
      }
      &.river {
        left: 1200px;
      }
    }
  }
  .history-content {
    position: relative;
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    will-change: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .no-log {
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 60px;
    top: 200px;
  }
}
</style>
