<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1000"
    height="600"
    :loading="loading"
    :show="true"
    :showCloseButton="false"
    preventClickOutside
    :titleText="$t('pinCode.pinCode')"
  >
    <template>
      <div class="enter-pin-window" v-if="currentTab === 'pin'">
        <div class="content">
          <div class="pincode-input">
            <p class="enter-pin">{{ $t("pinCode.enterPin") }}</p>
            <input
              @keyup.enter="authenticate"
              type="password"
              :maxlength="30"
              v-model="pinCode"
            />
          </div>
          <p class="reset-pin" @click="currentTab = 'email'">
            {{ $t("pinCode.reset") }}
          </p>
        </div>
        <div class="footer">
          <div
            class="ui-button ui-button-small-red"
            @click="showConfirm = true"
          >
            {{ $t("pinCode.logOut") }}
          </div>
          <div
            class="ui-button ui-button-small-green search-button"
            @click="authenticate"
          >
            {{ $t("general.okay") }}
          </div>
        </div>
      </div>
      <div class="enter-email-window" v-if="currentTab === 'email'">
        <div class="content">
          <div class="reset-pin-container">
            <p>{{ $t("pinCode.enterEmail") }}</p>
            <div class="email-input">
              <input type="email" v-model.trim="enteredEmail" />
            </div>
          </div>
        </div>
        <div class="footer">
          <div
            class="ui-button ui-button-small-red"
            @click="currentTab = 'pin'"
          >
            CANCEL
          </div>
          <div
            class="ui-button ui-button-small-green search-button"
            @click="resetPin"
          >
            RESET
          </div>
        </div>
      </div>
    </template>
    <ConfirmWindow
      :show="showConfirm"
      @closeConfirm="showConfirm = false"
      @confirm="logOut"
      :titleText="''"
    />
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";
import ConfirmWindow from "./ConfirmWindow";

import StorageManager from "@/plugins/storage-manager";

export default {
  name: "EnterPinCodeWindow",
  components: {
    BaseWindow,
    ConfirmWindow,
  },
  data() {
    return {
      pinCode: "",
      loading: false,
      showConfirm: false,
      currentTab: "pin",
      enteredEmail: "",
    };
  },
  methods: {
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    closeWindow() {
      this.$store.commit("modals/hide", "pinCode");
      this.pinCode = "";
    },
    async logOut() {
      await StorageManager.instance.remove({ key: "tokens" });
      this.closeWindow();
      if (window.__notifyOnlineInterval)
        clearInterval(window.__notifyOnlineInterval);
      location.reload();
    },
    async resetPin() {
      if (
        !this.enteredEmail ||
        this.validateEmail(this.$data.enteredEmail) == null
      ) {
        return this.$plugins.toast.show({
          text: this.$t("inboxContact.validEmail"),
          duration: 2500,
          variant: "danger",
        });
      }

      await this.$store.dispatch("pin/resetPinCode", this.enteredEmail);
      this.enteredEmail = "";
      this.currentTab = "pin";
    },
    authenticate() {
      const provider = this.$store.state.authentication.requestedAuthMethod;
      this.$store.commit("modals/hide", "pinCode");
      if (this.$store.state.authentication.isTryingAutoLogin) {
        this.$store.dispatch("authentication/tryAutoLogin", this.pinCode);
      } else {
        this.$store.dispatch(`authentication/${provider}`, this.pinCode);
      }
      this.pinCode = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.enter-pin-window,
.enter-email-window {
  position: relative;
  width: 100%;
  height: 87%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #83212d;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  .heading {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 55px;
    font-weight: bold;
    line-height: 60px;
    background: -webkit-linear-gradient(#ffcc66, #ffffcc);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .content {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    p {
      font-size: 40px;
      margin-top: 30px;
    }
    .pincode-input,
    .email-input {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      input {
        width: 500px;
        height: 65px;
        font-size: 36px;
        font-weight: bold;
        border-radius: 50px;
        background: #d2b2b6;
        border: none;
        color: rgba(black, 0.8);
        padding-left: 20px;
        display: flex;
        box-shadow: inset 1px 3px 8px black;
        align-items: center;
        justify-content: center;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    .reset-pin {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      text-decoration: underline;
      color: white;
      cursor: pointer;
    }
    .reset-pin-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
  }
  .footer {
    width: 50%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
.enter-pin  {
  margin-top: 10px;
}
</style>
