<template>
  <div
    class="product"
    :class="[product.id === 'product_203' ? 'purple' : 'red']"
  >
    <div
      :class="`${
        product.id === 'product_203' ? 'product-purple' : 'product-red'
      }`"
    >
      <!-- <div
      class="header"
      v-if="product.headerText"
      :class="[
        product.id === 'product_103'
          ? 'sprite-profile-popup flama-orange'
          : 'sprite-profile-popup flama-green',
      ]"
    >
      <curved-text-svg :curvedText="product.headerText" class="header-text" />
    </div> -->
      <!-- <div class="sprite-profile-popup xp-flama">
      <span class="xp-amount"
        ><span>+ {{ product.xpAmount }} XP</span></span
      >
    </div> -->
      <div
        class="product-header"
        :class="product.id === 'product_203' ? 'bar1' : 'bar2'"
        v-if="product.id === 'product_203' || product.id === 'product_206'"
      >
        <span class="product-header-text">
          {{ product.description }}
        </span>
      </div>
      <div class="product-image" :class="`packet${calcProdIndex}`"></div>
      <div class="product-price">
        <div class="sprite-profile-popup chip2"></div>
        <span class="price">{{ product.title }}</span>
      </div>
      <div
        class="buy-button ui-button ui-button-green"
        @click="$emit('buy', { ...product, platform })"
      >
        <span> {{ product.price }} {{ product.currency }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Products",
  components: {
    CurvedTextSvg: () => import("./component/CurvedTextSvg.vue"),
  },
  computed: {
    ...mapGetters(["getChipFormat"]),
    // getProductImageClass() {
    //   return this.product.background === 0
    //     ? "sprite-profile-popup window1-image"
    //     : this.product.background === 1
    //     ? "sprite-profile-popup window2-image"
    //     : this.product.background === 2
    //     ? "sprite-profile-popup window3-image"
    //     : this.product.background === 3
    //     ? "sprite-profile-popup window4-image"
    //     : this.product.background === 4
    //     ? "sprite-profile-popup window5-image"
    //     : "sprite-profile-popup window6-image";
    // },
    calcProdIndex() {
      if (typeof this.prodIndex == "number" && this.prodIndex > 0) {
        return this.prodIndex;
      }
      return 1;
    },
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    platform: {
      type: String,
      default: "",
    },
    prodIndex: {
      type: Number,
      default: -1,
    },
  },
};
</script>

<style  lang="scss">
.product {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 33%;
  height: 40%;

  .product-purple,
  .product-red {
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 369px;
    width: 373px;
    // .xp-flama {
    //   position: absolute;
    //   right: 0;
    //   top: 40px;
    //   display: flex;
    //   justify-content: right;
    //   align-items: center;
    //   .xp-amount {
    //     width: 120px;
    //     font-size: 36px;
    //     font-weight: bold;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     text-align: center;
    //     text-shadow: 3px 3px 3px black;
    //   }
    // }
    .product-header {
      position: relative;
      top: -35px;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 380px;
      height: 80px;
      .product-header-text {
        font-size: 34px;
        position: relative;
        text-transform: uppercase;
        text-shadow: 3px 3px 3px black;
        bottom: 5px;
        left: 10px;
      }
    }
    .bar1 {
      background: url("~@/assets/images/shop-window/bar1.png");
      background-size: cover;
    }
    .bar2 {
      background: url("~@/assets/images/shop-window/bar2.png");
      background-size: cover;
    }
    .product-image {
      position: relative;
    }
    .packet1 {
      top: 40px;
      left: -10px;
      width: 215px;
      height: 190px;
      background: url("~@/assets/images/shop-window/packet1.png");
      background-size: cover;
    }
    .packet2 {
      top: 70px;
      width: 189px;
      height: 149px;
      background: url("~@/assets/images/shop-window/packet2.png");
      background-size: cover;
    }
    .packet3 {
      top: -30px;
      width: 292px;
      height: 178px;
      background: url("~@/assets/images/shop-window/packet3.png");
      background-size: cover;
    }
    .packet4 {
      top: 70px;
      width: 323px;
      height: 134px;
      background: url("~@/assets/images/shop-window/packet4.png");
      background-size: cover;
    }
    .packet5 {
      top: 10px;
      width: 240px;
      height: 239px;
      background: url("~@/assets/images/shop-window/packet5.png");
      background-size: cover;
    }
    .packet6 {
      top: -10px;
      width: 270px;
      height: 142px;
      background: url("~@/assets/images/shop-window/packet6.png");
      background-size: cover;
    }
    .product-price {
      position: absolute;
      bottom: 60px;
      width: 400px;
      height: 100px;
      border-radius: 10px;
      background: rgba(black, 0.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price {
        text-align: center;
        width: 82%;
        font-size: 40px;
        text-transform: capitalize;
        color: #d3c353;
      }
    }
    .buy-button {
      position: absolute;
      bottom: -40px;
    }
  }
  .product-purple {
    background: url("~@/assets/images/shop-window/slot1.png");
  }
  .product-red {
    background: url("~@/assets/images/shop-window/slot2.png");
  }
}
</style>
