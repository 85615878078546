<template>
  <div class="maintenance-scene">
    <div class="broken-card-container">
      <div class="undermaintenance-text">
        {{ $t("maintenance.tryAgainLAter") }}
      </div>
      <div class="broken-card-image sprite-list-pop-up care-image"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnderMaintenance",
};
</script>

<style scoped lang="scss">
.maintenance-scene {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  color: rgb(255, 255, 255);
  .undermaintenance-text {
    font-size: 50px;
    font-weight: bold;
    text-shadow: 0px 3px 2px black;
  }
  .broken-card-container {
    width: 100%;
    height: 65%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    .broken-card-image {
      transform: scale(1.5);
    }
  }
}
</style>
