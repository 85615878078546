function numberWithDots(x) {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  else return 0;
}

function searchElementsSetProperty(elName, setArr) {
  const els = document.querySelectorAll(`${elName}`);
  if (els.length > 0) {
    els.forEach((item) => {
      setArr.forEach((set) => {
        item.style[set.property] = set.value;
      });
    });
  }
}

export { numberWithDots, searchElementsSetProperty };
