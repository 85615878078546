<template>
  <div class="dealer-container">
    <div
      class="dealer-message"
      v-for="(message, messageIndex) in messages"
      :key="'dealer-chat-message-' + messageIndex"
      :class="[message.text.includes('&bull;') ? 'text-center' : '']"
      v-html="message.text"
    ></div>
  </div>
</template>

<script>
import EventBus from "@/event-bus";
import { mapGetters } from "vuex";

export default {
  name: "Dealer",
  watch: {
    "getActiveRoomState.stage": function (currentStage) {
      if (currentStage === 0) {
        this.messages.push({
          text: "&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;",
        });
      }
    },
  },
  computed: {
    ...mapGetters(["getShortChipFormat", "getChipFormat"]),
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
    }),
    getSuitProperties() {
      return {
        c: {
          icon: "&clubs;",
          color: "white",
        },
        s: {
          icon: "&spades;",
          color: "white",
        },
        h: {
          icon: "&hearts;",
          color: "var(--redColor)",
        },
        d: {
          icon: "&diams;",
          color: "var(#f68b2f)",
        },
      };
    },
  },
  data() {
    return {
      messages: [],
      isScrollLocked: false,
    };
  },
  methods: {
    onScrolled(element) {
      if (
        element.target.offsetHeight + element.target.scrollTop + 25 >=
        element.target.scrollHeight
      ) {
        this.isScrollLocked = false;
        return;
      }
      this.isScrollLocked = true;
    },
    onPlayerSpoke({ action, amount, player }) {
      const langKey = this.getLangKey(action);
      if (langKey === null) return false;

      let text = this.$t(langKey).replaceAll(
        "{username}",
        `<span style="color:var(--yellowColor)">${player.name}</span>`
      );

      if (amount > 0)
        text = text.replaceAll(
          "{amount}",
          `<span style="color:var(--greenColor)">${this.getChipFormat(
            amount
          )}</span>`
        );

      this.messages.push({ text });
    },
    onChipsFlyingToWinner({ player, hand, amount, winnerCards }) {
      if (typeof hand === "undefined") {
        this.messages.push({
          text: this.$t("dealerChat.win")
            .replaceAll(
              "{username}",
              `<span style="color:var(--yellowColor)">${player.name}</span>`
            )
            .replaceAll(
              "{amount}",
              `<span style="color:var(--greenColor)">${this.getChipFormat(
                amount
              )}</span>`
            ),
        });
      } else {
        const cardsAsString = winnerCards
          .map((card) => {
            return `<span style="font-weight:bold;color:${
              this.getSuitProperties[card.suit].color
            }">
            ${
              this.getSuitProperties[card.suit].icon + card.value == "1"
                ? "A"
                : card.value
            }</span>`;
          })
          .join(" ");

        this.messages.push({
          text: this.$t("dealerChat.winWithCardIcons")
            .replaceAll(
              "{username}",
              `<span style="color:var(--yellowColor)">${player.name}</span>`
            )
            .replaceAll(
              "{amount}",
              `<span style="color:var(--greenColor)">${this.getChipFormat(
                amount
              )}</span>`
            )
            .replaceAll("{cards}", cardsAsString)
            .replaceAll("{hand}", hand.descr),
        });
      }
    },
    onWinnerReceived(winners) {
      const onePlayerLeft =
        this.getActiveRoomState.players.filter((p) => p.in).length === 1;
      for (const winner of winners) {
        if (winner.handName === "" || onePlayerLeft) {
          this.messages.push({
            text: this.$t("dealerChat.win")
              .replaceAll(
                "{username}",
                `<span style="color:var(--yellowColor)">${winner.playerName}</span>`
              )
              .replaceAll(
                "{amount}",
                `<span style="color:var(--greenColor)">${this.getChipFormat(
                  winner.amount
                )}</span>`
              ),
          });
        } else {
          const cardsAsString = winner.cards
            .map((card) => {
              return `<span style="font-weight:bold;color:${
                this.getSuitProperties[card[1]].color
              }">
              ${this.getSuitProperties[card[1]].icon + card[0]}</span>`;
            })
            .join(" ");

          const cardsAsReplaceString = cardsAsString.replaceAll(
            "T</span>",
            "10</span>"
          );

          this.messages.push({
            text: this.$t("dealerChat.winWithCardIcons")
              .replaceAll(
                "{username}",
                `<span style="color:var(--yellowColor)">${winner.playerName}</span>`
              )
              .replaceAll(
                "{amount}",
                `<span style="color:var(--greenColor)">${this.getChipFormat(
                  winner.amount
                )}</span>`
              )
              .replaceAll("{cards}", cardsAsReplaceString)
              .replaceAll("{hand}", winner.handName),
          });
        }
      }
    },
    getLangKey(actionCode) {
      const keys = {
        1: "dealerChat.call",
        2: "dealerChat.check",
        3: "dealerChat.raise",
        4: "dealerChat.fold",
        5: "dealerChat.allin",
      };
      if (typeof keys[actionCode] === "undefined") return null;
      return keys[actionCode];
    },
  },
  updated() {
    if (this.messages.length > 100) this.messages.splice(0, 1);

    if (this.isScrollLocked) return;

    const dealerChatScrollingBox = this.$el;
    if (dealerChatScrollingBox) {
      dealerChatScrollingBox.scrollTop = dealerChatScrollingBox.scrollHeight;
    }
  },
  mounted() {
    const dealerChatScrollingBox = this.$el;
    if (dealerChatScrollingBox) {
      dealerChatScrollingBox.scrollTop = dealerChatScrollingBox.scrollHeight;
      dealerChatScrollingBox.addEventListener(
        "scroll",
        this.onScrolled.bind(this)
      );
    }
  },
  beforeDestroy() {
    EventBus.$off("onPlayerSpoke", this.onPlayerSpoke.bind(this));
    EventBus.$off("onWinnerReceived", this.onWinnerReceived.bind(this));

    const dealerChatScrollingBox = this.$el;
    dealerChatScrollingBox.removeEventListener(
      "scroll",
      this.onScrolled.bind(this),
      true
    );
  },
  created() {
    EventBus.$on("onPlayerSpoke", this.onPlayerSpoke.bind(this));
    EventBus.$on("onWinnerReceived", this.onWinnerReceived.bind(this));
  },
};
</script>

<style lang="scss" scoped>
.dealer-container {
  margin-top: 10px;
  height: 98%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .dealer-message {
    position: relative;
    width: 90%;
    padding: 20px;
    font-size: 36px;
    border-bottom: 3px solid rgba(#7f1e2a, 0.5);
    &.text-center {
      text-align: center;
    }
    &:first-child {
      border-top: none;
    }
    .line-red {
      position: absolute;
      bottom: 0;
    }
  }
}
</style>
