import AudioManager from "@/plugins/audio";

export default {
  removeAll(state) {
    state.list.splice(0, state.list.length);
  },

  updateOne(state, { key, value }) {
    if (key === "language")
      this.commit("preferences/updatePageLangAttribute", { lang: value });

    if (key === "sounds") {
      state.soundValue = value != 0;
      //   AudioManager.instance.muted = value == 0
    }

    const currentIndex = state.list.findIndex((item) => item.key == key);

    if (currentIndex < 0) {
      state.list.push({ key, value });
      return;
    }

    state.list[currentIndex].key = key;
    state.list[currentIndex].value = value;
  },

  updatePageLangAttribute(state, { lang }) {
    document.documentElement.setAttribute("lang", lang);
  },
};
