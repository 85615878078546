<template>
  <!-- <div
    class="chat-window-container"
    :class="getRealPlatform === 'android' ? 'no-flick' : ''"
  > -->
  <div class="chat-window-bg" v-show="showChatPopup">
    <div class="chat-window-inner-bg">
      <div class="close-container" @click="closeWindow">
        <div class="close">
          <span class="sprite-table-pop-up exit-icon"></span>
        </div>
      </div>

      <div class="tab-selectors">
        <div
          class="selector"
          @click="currentTab = 'Chat'"
          :class="[currentTab === 'Chat' ? 'profile-sprite tab1 active' : '']"
        >
          <span class="text">{{ $t("chatWindow.tabs.chat") }}</span>
          <span
            :class="[currentTab === 'Chat' ? 'profile-sprite tab-line' : '']"
          ></span>
        </div>
        <div
          class="selector"
          @click="currentTab = 'Dealer'"
          :class="[currentTab === 'Dealer' ? 'profile-sprite tab2 active' : '']"
        >
          <span class="text">{{ $t("chatWindow.tabs.dealer") }}</span>
          <span
            :class="[currentTab === 'Dealer' ? 'profile-sprite tab-line' : '']"
          ></span>
        </div>
        <div
          class="selector"
          @click="currentTab = 'Mute'"
          :class="[currentTab === 'Mute' ? 'profile-sprite tab3 active' : '']"
        >
          <span class="text">{{ $t("chatWindow.tabs.mute") }}</span>
          <span
            :class="[currentTab === 'Mute' ? 'profile-sprite tab-line' : '']"
          ></span>
        </div>
      </div>
      <div class="tab-container">
        <Chat v-show="currentTab === 'Chat'" />
        <Dealer v-show="currentTab === 'Dealer'" />
        <Mute v-show="currentTab === 'Mute'" />
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";

import Chat from "./ChatWindow/Chat";
import Dealer from "./ChatWindow/Dealer";
import Mute from "./ChatWindow/Mute";
import AnalyticsEvents from "@/plugins/analytics/events";

export default {
  name: "TableChatWindow",
  components: {
    Chat: Chat,
    Dealer: Dealer,
    Mute: Mute,
  },
  props: {
    showChatPopup: {
      type: Boolean,
      default: false,
    },
  },
  directives: { ClickOutside },
  computed: {
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
      nGetMySeat: "service/nGetMySeat",
      nAmISitting: "service/nAmISitting",
      getRealPlatform: "getRealPlatform",
    }),
  },
  data: () => ({
    currentTab: "Chat",
    mutedPlayers: [],
  }),
  methods: {
    onClickedOutside($event) {
      const excludedClassNames = ["dropdown-item", "emoji-button"];
      if (
        excludedClassNames.filter((excludedClassName) => {
          if (typeof $event.target.className === "object") {
            return false;
          }
          return $event?.target?.className.includes(excludedClassName);
        }).length > 0
      )
        return false;
      if (this.$parent.$parent.$data.showChatPopup === false) return false;
      this.$parent.$parent.$data.showChatPopup = false;
    },
    closeWindow() {
      this.$plugins.audio.play("Button");

      this.$parent.$parent.$data.showChatPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// .chat-window-container {
//   position: absolute;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   width: 100%;
//   height: 100%;
//   left: 0;
//   top: 0;
//   z-index: 99;

.chat-window-bg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33%;
  height: 100%;
  background: rgba(#64161e, 0.95);
  z-index: 99;
  left: 0px;
  top: 0px;
  .chat-window-inner-bg {
    position: relative;
    border-radius: 22px;
    width: 100%;
    height: 100%;
    .tab-selectors {
      cursor: pointer;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: rgba(59, 11, 20, 0.9);
      .selector {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 187px;
        height: 99px;
        border-radius: 10px;
        border: none;
        .text {
          color: #cf707f;
          font-size: 35px;
          font-weight: bold;
          text-transform: uppercase;
        }
        .tab-line {
          position: absolute;
          bottom: 0;
          left: 15px;
          transform: scale(0.9);
        }
        &.active {
          .text {
            color: white;
          }
          .tab1 {
            border-top-right-radius: 50px;
          }
        }
      }
    }
    .tab-container {
      height: 980px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .close-container {
      position: absolute;
      top: 5px;
      right: 2px;
      height: 89px;
      width: 76px;
      cursor: pointer;
      .close {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
        top: 11px;
        right: 5px;
        width: 65px;
        height: 65px;
        border-radius: 100%;
        border: 3px solid #cf707f;
        cursor: pointer;
        transition: all 0.25s ease;
        &:active {
          filter: brightness(60%);
          -webkit-filter: brightness(60%);
        }
        .exit-icon {
          transform: scale(0.7);
        }
      }
    }
  }
}
// }
</style>
