import { Facebook } from "./providers/facebook";
import { Firebase } from "./providers/firebase";

export default class Analytics {
  constructor({ facebook, firebase }) {
    const providers = [];

    if (typeof firebase !== "undefined") providers.push(new Firebase(firebase));

    if (typeof facebook !== "undefined") providers.push(new Facebook(facebook));

    providers.forEach((p) => p.init());

    this.providers = providers;
  }

  fireEvent(eventName, data) {
    this.providers.forEach((p) => p.fireEvent(eventName, data));
  }
}
