import Logger from "@/logger";
import AppConstants from "@/constants/app";
import SlotsConstants from "@/constants/slots";
import Unzipper from "@/plugins/unzipper";
import ToastManager from "@/plugins/toast";
import StorageManager from "@/plugins/storage-manager";
import AudioManager from "@/plugins/audio";
import { Capacitor } from "@capacitor/core";
import i18n from "@/plugins/i18n";

export default {
  async initialize({ state, rootState, rootGetters }) {
    state.turnLegends.zip = rootState.config?.turn_legends_url ?? "";
    state.bambooPanda.zip = rootState.config?.bamboo_panda_url ?? "";
    state.fafafa.zip = rootState.config?.fafafa_url ?? "";
    state.olympus.zip = rootState.config?.olympus_url ?? "";

    Object.keys(AppConstants.storageKeys.slots).forEach((slotDownloadedKey) => {
      StorageManager.instance
        .get({
          key: AppConstants.storageKeys.slots[slotDownloadedKey],
        })
        .then((res) => {
          if (res.value == null) return;
          const parsed = JSON.parse(res.value);

          state[slotDownloadedKey].status = SlotsConstants.status.DOWNLOADED;
          state[slotDownloadedKey].version = parsed.version;
          state[slotDownloadedKey].playableLink = Capacitor.convertFileSrc(
            `${parsed.location}/index.html`
          );
        });
    });

    if (rootGetters.getRealPlatform === "web") {
      Object.keys(state).forEach((key) => {
        if (key === "playing") return false;

        state[key].status = SlotsConstants.status.DOWNLOADED;
      });
    }
  },

  async download({ state }, { name }) {
    state[name].status = SlotsConstants.status.DOWNLOADING;

    const onCompleted = async (loc) => {
      await StorageManager.instance.set({
        key: AppConstants.storageKeys.slots[name],
        value: JSON.stringify({
          version: state[name].version,
          location: loc,
        }),
      });
      state[name].playableLink = Capacitor.convertFileSrc(`${loc}/index.html`);
      state[name].status = SlotsConstants.status.DOWNLOADED;
    };
    const onError = async () => {
      await StorageManager.instance.remove({
        key: AppConstants.storageKeys.slots[name],
      });
      ToastManager.instance.show({
        text: i18n.t("errors.slotDownloadError"),
        variant: "danger",
      });
      state[name].status = SlotsConstants.status.AVAILABLE;
    };
    const onProgress = (progress) => {
      state[name].progress = progress;
      state[name].status = SlotsConstants.status.DOWNLOADING;
    };

    await Unzipper.instance.unzip({
      file: state[name].zip,
      onCompleted,
      onError,
      onProgress,
    });
  },

  async play({ state, rootState, rootGetters, commit }, { name }) {
    if (rootGetters["user/getLevel"](rootState.user.xp).number < 5) {
      ToastManager.instance.show({
        text: i18n.t("others.requiredLevelFive"),
        variant: "danger",
      });
      return;
    }
    const GAME = state[name];

    if (typeof GAME === "undefined") {
      Logger.print("error", "Game not defined");
    }

    rootState.service.colyseusConnection.ACTIVE_ROOM?.leave();
    rootState.service.colyseusConnection.LOBBY?.leave();
    await AudioManager.instance.muteAll();

    state.playing = name;
    commit("changeScene", "SlotGame", { root: true });
  },
};
