<template>
  <div class="report-container">
    <div class="close-button flex-center" @click="closeWindow">
      <span class="sprite-table-pop-up exit-icon"></span>
    </div>
    <div class="report-bg" v-click-outside="onClickedOutside">
      <div class="report-inner-bg">
        <div class="title">{{ $t("reportWindow.report") }}</div>
        <div class="player-lists">
          <div
            v-if="
              getPlayersWithoutMe.length === 0 &&
              getAudiencesWithoutMe.length === 0
            "
            class="info-message"
          >
            {{ $t("reportWindow.noPlayersFound") }}
          </div>
          <div class="player-list" v-show="getPlayersWithoutMe.length > 0">
            <div class="list-title">{{ $t("reportWindow.players") }}</div>
            <div class="player-list-inner">
              <div
                class="player"
                v-for="player in getPlayersWithoutMe"
                :key="'player-' + player.id"
                @click="select(player.id)"
              >
                <div class="player-name">{{ player.name }}</div>
                <div class="checkmark-container">
                  <span
                    v-if="selectedPlayers.includes(player.id)"
                    class="sprite-table-pop-up report-icon-orange"
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div class="player-list" v-show="getAudiencesWithoutMe.length > 0">
            <div class="list-title">{{ $t("reportWindow.audiences") }}</div>
            <div class="player-list-inner">
              <div
                class="player"
                v-for="player in getAudiencesWithoutMe"
                :key="'player-' + player.id"
                @click="select(player.id)"
              >
                <div class="player-name">{{ player.name }}</div>
                <div class="checkmark-container">
                  <span
                    v-if="selectedPlayers.includes(player.id)"
                    class="sprite-table-pop-up report-icon-orange"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="report-filter-window" v-show="showReportFilterSelection">
          <div
            class="report-filter-window-item"
            :class="[reportType === 0 ? 'active' : '']"
            @click="setReportType(0)"
          >
            {{ $t("reportWindow.scissors") }}
          </div>
          <div
            class="report-filter-window-item"
            :class="[reportType === 1 ? 'active' : '']"
            @click="setReportType(1)"
          >
            {{ $t("reportWindow.swearing") }}
          </div>
          <div
            class="report-filter-window-item"
            :class="[reportType === 2 ? 'active' : '']"
            @click="setReportType(2)"
          >
            {{ $t("reportWindow.team") }}
          </div>
          <div
            class="report-filter-window-item"
            :class="[reportType === 3 ? 'active' : '']"
            @click="setReportType(3)"
          >
            Bot
          </div>
        </div>
        <div class="report-bottom">
          <button
            class="report-filter"
            @click="showReportFilterSelection = !showReportFilterSelection"
          >
            {{ buttonText }}
            <span class="sprite-profile-popup language-ok"></span>
          </button>
          <div class="ui-button ui-button-orange send" @click="send">
            {{ $t("general.send") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
import AnalyticsEvents from "@/plugins/analytics/events";

export default {
  name: "ReportWindow",
  directives: { ClickOutside },
  computed: {
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
      nGetMySeat: "service/nGetMySeat",
      nAmISitting: "service/nAmISitting",
    }),
    player() {
      return this.$store.state.user;
    },
    isSubmitButtonActive() {
      return this.selectedPlayers.length > 0 && this.reportType !== "";
    },
    getPlayersWithoutMe() {
      return this.getActiveRoomState.players.filter(
        (player) =>
          player.id !== this.player.id && player.position > -1 && !player.ghost
      );
    },
    getAudiencesWithoutMe() {
      return this.getActiveRoomState.players.filter(
        (player) =>
          player.id !== this.player.id && player.position < 0 && !player.ghost
      );
    },
    buttonText() {
      return this.reportType < 0
        ? this.$t("reportWindow.reportFilter")
        : this.reportType === 0
        ? this.$t("reportWindow.scissors")
        : this.reportType === 1
        ? this.$t("reportWindow.swearing")
        : this.reportType === 2
        ? this.$t("reportWindow.team")
        : this.reportType === 3
        ? "Bot"
        : "";
    },
  },
  data: () => ({
    selectedPlayers: [],
    reportType: -1,
    submitted: false,
    showReportFilterSelection: false,
  }),
  methods: {
    async send() {
      if (
        this.submitted ||
        this.selectedPlayers.length === 0 ||
        this.reportType === ""
      )
        return false;

      this.submitted = true;

      const last30Messages = [];

      const response = await this.$store.dispatch("report/send", {
        playerIds: this.selectedPlayers,
        allPlayers: this.getActiveRoomState.players.map(
          ({ id, name, avatar, position }) => ({ id, name, avatar, position })
        ),
        currentChat: last30Messages,
        type: Number(this.reportType),
      });

      if (response === true) {
        this.$plugins.toast.show({
          text: this.$t("reportWindow.successMessage"),
          variant: "success",
        });
        this.selectedPlayers.splice(0, this.selectedPlayers.length);
        this.reportType = "";
        this.showReportFilterSelection = false;
        this.submitted = false;
        this.$parent.$parent.$data.showReportPopup = false;
        return true;
      }

      this.$plugins.toast.show({
        text: this.$t("reportWindow.failedMessage"),
        variant: "danger",
      });
      this.submitted = false;
      return false;
    },
    closeWindow() {
      this.$parent.$parent.$data.showReportPopup = false;
    },
    select(playerId) {
      const index = this.selectedPlayers.findIndex((p) => p === playerId);
      if (index > -1) {
        this.selectedPlayers.splice(index, 1);
        return;
      }
      this.selectedPlayers.push(playerId);
    },
    setReportType(reportType = 0) {
      this.$data.reportType = reportType;
      this.showReportFilterSelection = false;
    },
    onClickedOutside($event) {
      const excludedClassNames = ["dropdown-item"];
      if (
        excludedClassNames.filter((excludedClassName) =>
          $event?.target?.className.includes(excludedClassName)
        ).length > 0
      )
        return false;
      if (this.$parent.$parent.$data.showReportPopup === false) return false;
      this.$parent.$parent.$data.showReportPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.report-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  .close-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    top: 5px;
    right: 15px;
    width: 65px;
    height: 65px;
    border-radius: 100%;
    border: 3px solid #cf707f;
    cursor: pointer;
    transition: all 0.25s ease;
    &:active {
      filter: brightness(60%);
      -webkit-filter: brightness(60%);
    }
    .exit-icon {
      transform: scale(0.7);
    }
  }

  .report-bg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32%;
    height: 100%;
    background: rgba(#3b0b14, 0.95);
    .report-inner-bg {
      position: relative;
      border-radius: 22px;
      width: 98%;
      height: 98%;
      .title {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 45px;
        color: #cf707f;
        text-shadow: 0px 3px 2px black;
        text-transform: uppercase;
        border-bottom: 2px solid #691822;
        margin-bottom: 20px;
      }

      .player-list {
        display: block;
        .list-title {
          color: white;
          font-size: 36px;
          margin: 10px;
          text-shadow: 0px 3px 2px black;
          padding-bottom: 30px;
          font-weight: bold;
          text-align: center;
          text-transform: uppercase;
        }
      }

      .player-lists {
        height: 720px;
        width: 98%;
        margin-left: 6px;
        overflow-x: hidden;
        overflow-y: auto;
        background: #64161e;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .player-list-inner {
        width: 100%;
        height: 75%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        .player {
          width: 90%;
          height: 100px;
          display: flex;
          justify-content: space-between;
          padding: 0px 20px;
          align-items: center;
          margin-bottom: 10px;
          background: #3b0b14;
          border-radius: 10px;
          .checkmark-container {
            position: relative;
            width: 60px;
            height: 60px;
            background-color: rgba(black, 0.3);
            border: 4px solid #a62132;
            border-radius: 100%;
            span {
              position: absolute;
              transform: scale(1.2);
              top: 7px;
              left: 7px;
            }
          }

          .player-name {
            font-size: 32px;
            text-shadow: 0px 3px 2px black;
            color: white;
            text-transform: uppercase;
          }
        }
      }

      .report-bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 98%;
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background: #64161e;
        margin-left: 6px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        .report-filter {
          position: relative;
          width: 500px;
          height: 90px;
          font-size: 35px;
          border-radius: 10px;
          text-transform: uppercase;
          background: rgba(black, 0.5);
          box-shadow: inset 1px 3px 8px black;
          color: white;
          font-weight: bold;
          span {
            position: absolute;
            opacity: 0.9;
            right: 20px;
            top: 30px;
            transform: rotate(180deg);
          }
        }
      }

      .report-filter-window {
        position: absolute;
        left: 100px;
        bottom: 200px;
        width: 400px;
        height: 500px;
        border-radius: 30px;
        background-color: #3b0b14;
        border: 3px solid rgba(black, 0.5);
        padding: 20px 0;
        box-sizing: border-box;
        z-index: 2;
        &-item {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 36px;
          height: 120px;
          border-bottom: 3px solid rgba(black, 0.4);
          &:last-child {
            border-bottom: 0;
          }
          &.active {
            background-color: rgba(#64161e, 0.3);
          }
        }
      }
    }
  }
}

.info-message {
  font-size: 40px;
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 20px;
}
</style>
