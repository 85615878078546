import gameType from './gameType';
import stage from './stage';

export default {
    id: 0,
    name: '',
    maxPlayers: 9,
    players: [],
    currentTurn: -1,
    dealer: 0,
    stage: stage.Nothing,
    bet: 0,
    callAmount: 0,
    minRaise: 0,
    maxRaise: 0,
    minBuy: 0,
    maxBuy: 0,
    turnTimeout: 0,
    stopped: false,
    cards: [],
    pots: [],
    gameType: gameType.TexasHoldem,
    winners: [],
    gameCount: 0,
    teamTable: false,
    specialTable: false,
    ownerPlayerId: 0,
    progressiveJackpot: {
      status: false,
      royalFlushX: 0,
      straightFlushX: 0,
      fourOfAKindX: 0,
      fullHouseASX: 0,
    }
};
