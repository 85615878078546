<template>
  <div class="loading-scene">
    <Spinner radius="0%" size="100px" />
  </div>
</template>

<script>
import Spinner from "@/components/Utils/Spinner";

export default {
  name: "Loading",
  components: {
    Spinner,
  },
};
</script>

<style scoped lang="scss">
.loading-scene {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgb(255, 255, 255);
  top: 470px;
}
</style>
