export default {
  getGlobalMessages(state) {
    return state.globalMessages;
  },
  getTableMessages(state) {
    return state.tableMessages;
  },
  getUserConversations(state) {
    return state.playerConversations;
  },
  getSelectedConversation(state) {
    return state.selectedConversation;
  },
  isAnyUnread(state) {
    if (state.playerConversations.length === 0) return false;
    let indicator = false;
    state.playerConversations.forEach((item) => {
      if (item.unread === true) {
        indicator = true;
      }
    });
    return indicator;
  },
};
