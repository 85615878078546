<template>
  <SafeArea>
    <div class="poker-table-scene">
      <TableQuickMenu @tableMenuClick="showChatPopup = false" />
      <Table />
      <ActionPanel v-if="nAmISitting && nGetMySeat.in" />
      <ReportWindow v-if="showReportPopup" />
      <TableChatWindow :showChatPopup="showChatPopup" />
      <TableInformationWindow v-if="showTableInformation" />
      <TableInviteWindow
        v-if="$store.state.modals.showTableInviteWindow"
        @slotPlay="(slotName) => $emit('slotPlay', slotName)"
      />
      <SelectPotWindow />
      <JackpotWindow />
      <ProgressiveJackPotWindow />

      <div class="jackpot-notifications">
        <JackPotNotification />
        <ProgressiveJackpotNotification />
      </div>

      <JackpotTableIndicator
        v-if="getSystemJackpots.jackpots.length > 0"
        class="jackpot jackpot-system"
        identity="system"
        :amount="getSystemJackpots.total"
        @click.native.stop="showJackpotDetailWindow('system')"
      />
      <JackpotTableIndicator
        v-if="getSpecialJackpots.jackpots.length > 0"
        class="jackpot jackpot-special"
        identity="special"
        :amount="getSpecialJackpots.total"
      />
      <ProgressiveJackpotTableIndicator
        @click.native.stop="showProgressiveJackpotDetailWindow"
      />

      <div
        class="intive-button-container no-click-outside table-button"
        :class="[getRealPlatform === 'android' ? 'no-flick' : '']"
        @click.stop="toggleInvitePopup"
      >
        <div
          class="intive-button-container-icon sprite-table invite-icon2"
        ></div>
      </div>
      <div
        class="chat-button-container no-click-outside table-button"
        :class="[getRealPlatform === 'android' ? 'no-flick' : '']"
        @click.stop="toggleChatPopup"
      >
        <span :class="unreadChatMessages ? 'unread-dot' : ''"></span>
        <div class="chat-button-container-icon sprite-table chat-icon"></div>
      </div>
      <div class="sprite-table shop shop-icon" @click="showShopWindow"></div>
      <div
        class="info-container table-button no-click-outside"
        @click="showTableInformation = true"
      >
        <div
          class="info-container-icon sprite-table player-info-icon no-click-outside"
        ></div>
      </div>
    </div>
  </SafeArea>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import EventBus from "@/event-bus";

import SafeArea from "@/components/Screen/SafeArea";

import Table from "@/components/Misc/PokerTable/Table";
import TableQuickMenu from "@/components/Misc/PokerTable/TableQuickMenu";
import ActionPanel from "@/components/Misc/PokerTable/ActionPanel";
import ReportWindow from "@/components/Misc/PokerTable/ReportWindow";
import TableChatWindow from "@/components/Misc/PokerTable/TableChatWindow";
import TableInformationWindow from "@/components/Misc/PokerTable/TableInformationWindow";
import TableInviteWindow from "@/components/Misc/PokerTable/TableInviteWindow";
import SelectPotWindow from "@/components/Misc/PokerTable/SelectPotWindow";
import ProgressiveJackPotWindow from "@/components/Misc/PokerTable/ProgressiveJackpotWindow";
import JackpotWindow from "@/components/Misc/PokerTable/JackpotWindow";
import ProgressiveJackpotNotification from "@/components/Misc/PokerTable/ProgressiveJackpotNotification";
import JackPotNotification from "@/components/Misc/PokerTable/JackPotNotification";
import JackpotTableIndicator from "@/components/Misc/PokerTable/JackpotTableIndicator";
import ProgressiveJackpotTableIndicator from "@/components/Misc/PokerTable/ProgressiveJackpotTableIndicator";

import AppConstants from "@/constants/app";

import StorageManager from "@/plugins/storage-manager";

export default {
  name: "PokerTable",
  components: {
    SelectPotWindow,
    SafeArea,
    Table,
    TableQuickMenu,
    ActionPanel,
    ReportWindow,
    ProgressiveJackPotWindow,
    JackpotWindow,
    TableChatWindow,
    TableInviteWindow,
    ProgressiveJackpotNotification,
    JackPotNotification,
    JackpotTableIndicator,
    ProgressiveJackpotTableIndicator,
    TableInformationWindow,
  },
  watch: {
    showChatPopup(newValue) {
      if (newValue && this.$data.unreadChatMessages) {
        this.$data.unreadChatMessages = false;
      }
      if (newValue) {
        this.$store.commit("modals/hide", "sendGiftWindow");
      }
    },
    nAmISitting(newValue) {
      if (newValue) {
        StorageManager.instance.set({
          key: AppConstants.storageKeys.reconnect,
          value: this.getActiveRoomState.id,
        });
      }
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      nAmISitting: "service/nAmISitting",
      nGetMySeat: "service/nGetMySeat",
      getActiveRoomState: "service/getActiveRoomState",
      getSystemJackpots: "jackpots/getSystemJackpots",
      getSpecialJackpots: "jackpots/getSpecialJackpots",
      getRealPlatform: "getRealPlatform",
    }),
  },
  data() {
    return {
      showReportPopup: false,
      showEmojiPopup: false,
      showChatPopup: false,
      showTableInformation: false,
      unreadChatMessages: false,
    };
  },
  methods: {
    toggleInvitePopup() {
      this.$store.state.modals.showTableInviteWindow =
        !this.$store.state.modals.showTableInviteWindow;
    },
    toggleEmojiPopup() {
      this.$data.showEmojiPopup = !this.$data.showEmojiPopup;
      this.$data.showChatPopup = false;
    },
    toggleChatPopup() {
      this.$data.showChatPopup = !this.$data.showChatPopup;
    },
    showJackpotDetailWindow(identity) {
      this.showChatPopup = false;
      EventBus.$emit("onJackpotDetailRequested", {
        id: identity,
      });
    },
    showProgressiveJackpotDetailWindow() {
      EventBus.$emit("onProgressiveJackpotDetailRequested");
    },
    showShopWindow() {
      this.$store.commit("modals/show", "shop");
    },
  },
  // created() {
  //   this.$plugins?.audio?.stop("Lobby1");
  // },
  async mounted() {
    this.$adjust.trackEvent("PokerScreenOn");
  },
};
</script>

<style scoped lang="scss">
.poker-table-scene {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .jackpot-notifications {
    position: absolute;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    width: 50%;
    max-height: 330px;
  }
  .table-button {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: rgba($color: #000000, $alpha: 0.8);
    border: 3px solid rgba(#892632, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .intive-button-container {
    top: 25px;
    right: 170px;
    z-index: 5;
  }
  .chat-button-container {
    left: 30px;
    top: 25px;
    z-index: 6;
    .unread-dot {
      position: absolute;
      top: 20px;
      right: 7px;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background: #16fa03;
      border: 3px solid #0dae01;
    }
  }
  .info-container {
    top: 25px;
    left: 170px;
    z-index: 6;
  }
  .shop-icon {
    position: absolute;
    top: 25px;
    right: 310px;
    cursor: pointer;
    z-index: 5;
    &:active {
      transform: scale(1.1);
    }
  }
  .jackpot {
    &-special {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    &-system {
      position: absolute;
      left: 35px;
      bottom: 50px;
      cursor: default;
    }
  }
}
</style>
