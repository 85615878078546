<template>
  <div class="slot-game-scene">
    <iframe :src="getSlotSrc" id="slot-iframe" frameborder="0"></iframe>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "SlotGame",
  computed: {
    ...mapState(["slots", "authentication"]),
    ...mapGetters(["getRealPlatform"]),
    getSlotSrc() {
      if (this.slots.playing === "") return "";

      let link = this.slots[this.slots.playing].playableLink;
      if (this.getRealPlatform === "web")
        link = this.slots[this.slots.playing].url;

      link += `?access_token=${this.authentication.token.access}&refresh_token=${this.authentication.token.refresh}`;
      return link;
    },
  },
  methods: {
    onMessageCame(e) {
      if (e.data === "closeSlotsLegends") {
        this.$store.commit("modals/hide", "disconnect");
        this.$store.commit("changeScene", "Lobby");
      } else if (e.data === "openBuyChipsModal") {
        this.$store.commit("modals/hide", "disconnect");
        this.$store.commit("modals/show", "shop");
        this.$store.commit("changeScene", "Lobby");
      } else if (e.data === "openBuyChipsModalPanda") {
        this.$store.commit("modals/hide", "disconnect");
        this.$store.commit("modals/show", "shop");
        this.$store.commit("changeScene", "Lobby");
      } else if (e.data === "closeSlotsPanda") {
        this.$store.commit("modals/hide", "disconnect");
        this.$store.commit("changeScene", "Lobby");
      } else if (e.data === "openBuyChipsModalFafafa") {
        this.$store.commit("modals/hide", "disconnect");
        this.$store.commit("modals/show", "shop");
        this.$store.commit("changeScene", "Lobby");
      } else if (e.data === "closeSlotsFafafa") {
        this.$store.commit("modals/hide", "disconnect");
        this.$store.commit("changeScene", "Lobby");
      } else if (e.data === "closeSlotsOlympus") {
        this.$store.commit("modals/hide", "disconnect");
        this.$store.commit("changeScene", "Lobby");
      } else if (e.data === "openBuyChipsModalOlympus") {
        this.$store.commit("modals/hide", "disconnect");
        this.$store.commit("modals/show", "shop");
        this.$store.commit("changeScene", "Lobby");
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessageCame.bind(this));
  },
  mounted() {
    window.addEventListener("message", this.onMessageCame.bind(this), false);
  },
};
</script>

<style scoped>
.slot-game-scene {
  position: relative;
  width: 100vw;
  height: 100vh;
}
#slot-iframe {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  border: 0;
}
</style>
