<template>
  <div class="table-quick-menu">
    <div class="table-quick-menu-inner">
      <div
        class="table-quick-menu-button lobby-simple-menu no-click-outside"
        @click="toggleShow"
      >
        <div class="sprite-table menu-icon icon no-click-outside"></div>
      </div>

      <div
        class="table-quick-menu-dropdown"
        v-if="show"
        v-click-outside="onClickedOutsideWindow"
      >
        <div class="table-quick-menu-dropdown-items">
          <div
            class="dropdown-item"
            @click="standUpOrGoToLobby"
            :class="[nAmISitting && nGetMySeat.leaving ? 'leaving' : '']"
          >
            <div class="icon">
              <div
                class="sprite-table-pop-up get-up-icon"
                v-if="nAmISitting"
              ></div>
              <div
                class="sprite-table-pop-up go-lobby-icon"
                v-if="!nAmISitting"
              ></div>
            </div>
            <div class="text">{{ getStandUpOrGoToLobbyText }}</div>
            <span class="sprite-table-pop-up red-line"></span>
          </div>
          <!-- <div
            class="dropdown-item"
            :class="[nAmISitting ? 'disabled' : '']"
            @click="openTableList"
          >
            <div class="icon">
              <div class="sprite-table-pop-up table-list-icon"></div>
            </div>
            <div class="text">{{ $t("tableList.tableList") }}</div>
            <span class="sprite-table-pop-up red-line"></span>
          </div> -->

          <div
            class="dropdown-item no-click-outside"
            @click.stop="searchBtnClick"
          >
            <div class="icon no-click-outside">
              <div
                class="sprite-table-pop-up player-search-icon no-click-outside"
              ></div>
            </div>
            <div class="text no-click-outside">
              {{ $t("affiliate.searchBy") }}
            </div>
            <span class="sprite-table-pop-up red-line"></span>
          </div>
          <div class="dropdown-item" @click="toggleSoundsPreference">
            <div class="icon">
              <div
                class="sprite-table-pop-up"
                :class="[
                  getPreferenceValue('sounds') == 1
                    ? 'sound-on-icon2'
                    : 'sound-off-icon2',
                ]"
              ></div>
            </div>
            <div class="text">
              {{
                getPreferenceValue("sounds") == 1
                  ? $t("tableQuickMenu.soundOn")
                  : $t("tableQuickMenu.soundOff")
              }}
            </div>
            <div class="slider-part">
              <div class="on-part" v-if="getPreferenceValue('sounds') == 1">
                <div class="sprite-profile-popup button-green-s"></div>
                <span class="sprite-profile-popup circle-button"></span>
              </div>
              <div class="off-part" v-else>
                <div class="sprite-profile-popup button-red-s"></div>
                <span class="sprite-profile-popup circle-button left"></span>
              </div>
            </div>
            <span class="sprite-table-pop-up red-line"></span>
          </div>

          <div class="dropdown-item" @click="toggleEmotePreference">
            <div class="icon">
              <div
                :class="[
                  getPreferenceValue('animated_gifts') == 1
                    ? 'sprite-table-pop-up expression-icon'
                    : 'sprite-table-pop-up expression-off-icon',
                ]"
              ></div>
            </div>
            <div class="text">
              {{
                getPreferenceValue("animated_gifts") == 1
                  ? $t("tableQuickMenu.animatedGiftsOff")
                  : $t("tableQuickMenu.animatedGiftsOn")
              }}
            </div>
            <div class="slider-part">
              <div
                class="on-part"
                v-if="getPreferenceValue('animated_gifts') == 1"
              >
                <div class="sprite-profile-popup button-green-s"></div>
                <span class="sprite-profile-popup circle-button"></span>
              </div>
              <div class="off-part" v-else>
                <div class="sprite-profile-popup button-red-s"></div>
                <span class="sprite-profile-popup circle-button left"></span>
              </div>
            </div>
            <span class="sprite-table-pop-up red-line"></span>
          </div>
          <div
            class="dropdown-item no-click-outside"
            @click.stop="toggleInviteBlock"
          >
            <div class="icon no-click-outside">
              <div class="player-invite-icon no-click-outside"></div>
            </div>
            <div class="text no-click-outside">
              {{ $t("settings.blockInvites") }}
            </div>
            <div class="slider-part">
              <div class="on-part" v-if="$store.state.user.invitation">
                <div class="sprite-profile-popup button-green-s"></div>
                <span class="sprite-profile-popup circle-button"></span>
              </div>
              <div class="off-part" v-else>
                <div class="sprite-profile-popup button-red-s"></div>
                <span class="sprite-profile-popup circle-button left"></span>
              </div>
            </div>
            <span class="sprite-table-pop-up red-line"></span>
          </div>
          <!-- <div class="dropdown-item" @click="showButton = !showButton">
            <div class="icon">
              <div
                :class="[
                  showButton
                    ? 'sprite-table-pop-up button-on-icon'
                    : 'sprite-table-pop-up button-off-icon',
                ]"
              ></div>
            </div>
            <div class="text">
              {{ showButton ? "Butonları Göster" : "Butonları Gizle" }}
            </div>
            <div class="slider-part">
              <div class="on-part" v-if="showButton">
                <div class="sprite-profile-popup button-green-s"></div>
                <span class="sprite-profile-popup circle-button"></span>
              </div>
              <div class="off-part" v-else>
                <div class="sprite-profile-popup button-red-s"></div>
                <span class="sprite-profile-popup circle-button left"></span>
              </div>
            </div>
            <span class="sprite-table-pop-up red-line"></span>
          </div> -->
          <!-- <div class="dropdown-item" @click="chatBaloon = !chatBaloon">
            <div class="icon">
              <div
                :class="[
                  chatBaloon
                    ? 'sprite-table-pop-up chat-icon2'
                    : 'sprite-table-pop-up chat-off-icon2',
                ]"
              ></div>
            </div>
            <div class="text">
              {{ chatBaloon ? "Sohbet Balonu Göster" : "Sohbet Balonu Gizle" }}
            </div>
            <div class="slider-part">
              <div class="on-part" v-if="chatBaloon">
                <div class="sprite-profile-popup button-green-s"></div>
                <span class="sprite-profile-popup circle-button"></span>
              </div>
              <div class="off-part" v-else>
                <div class="sprite-profile-popup button-red-s"></div>
                <span class="sprite-profile-popup circle-button left"></span>
              </div>
            </div>
            <span class="sprite-table-pop-up red-line"></span>
          </div> -->
          <div
            class="dropdown-item"
            @click="toggleVibrationPreference"
            v-if="getRealPlatform !== 'web'"
          >
            <div class="icon">
              <div
                class="sprite-table-pop-up"
                :class="[
                  getPreferenceValue('vibration') == 1
                    ? 'vibration-icon'
                    : 'vibration-off-icon ',
                ]"
              ></div>
            </div>
            <div class="text">
              {{
                getPreferenceValue("vibration") == 1
                  ? $t("tableQuickMenu.vibrationOn")
                  : $t("tableQuickMenu.vibrationOff")
              }}
            </div>
            <div class="slider-part">
              <div class="on-part" v-if="getPreferenceValue('vibration') == 1">
                <div class="sprite-profile-popup button-green-s"></div>
                <span class="sprite-profile-popup circle-button"></span>
              </div>
              <div class="off-part" v-else>
                <div class="sprite-profile-popup button-red-s"></div>
                <span class="sprite-profile-popup circle-button left"></span>
              </div>
            </div>
            <span class="sprite-table-pop-up red-line"></span>
          </div>
          <!-- <div class="dropdown-item no-click-outside">
            <div class="icon no-click-outside">
              <div
                class="sprite-table-pop-up different-icon no-click-outside"
              ></div>
            </div>
            <div class="text no-click-outside">Benzer Masa</div>
            <span class="sprite-table-pop-up red-line"></span>
          </div> -->
          <div
            class="dropdown-item no-click-outside"
            @click.stop="historyBtnClick"
          >
            <div class="icon no-click-outside">
              <div
                class="sprite-table-pop-up hand-history-icon no-click-outside"
              ></div>
            </div>
            <div class="text no-click-outside">
              {{ $t("blackJack.gameHistory") }}
            </div>
            <span class="sprite-table-pop-up red-line"></span>
          </div>
          <div
            class="dropdown-item no-click-outside"
            @click.stop="toggleReportPopup"
          >
            <div class="icon no-click-outside">
              <div
                class="sprite-table-pop-up report-icon no-click-outside"
              ></div>
            </div>
            <div class="text no-click-outside">
              {{ $t("reportWindow.report") }}
            </div>
            <!-- <span class="sprite-table-pop-up red-line"></span> -->
          </div>
          <!-- <div class="dropdown-item text-center active">
            <div class="text no-click-outside">
              TABLE ID: {{ getActiveRoomState.id }}
            </div>
          </div>
          <div class="dropdown-item text-center active">
            <div class="text no-click-outside">
              {{
                getShortChipFormat(getActiveRoomState.bet) +
                "/" +
                getShortChipFormat(getActiveRoomState.bet * 2)
              }}
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import clickOutside from "vue-click-outside";

import StorageManager from "@/plugins/storage-manager";
import AppConstants from "@/constants/app";

export default {
  name: "TableQuickMenu",
  directives: { clickOutside },
  computed: {
    ...mapGetters({
      getShortChipFormat: "getShortChipFormat",
      nAmISitting: "service/nAmISitting",
      nGetMySeat: "service/nGetMySeat",
      getActiveRoomState: "service/getActiveRoomState",
      getPreferenceValue: "preferences/getValue",
    }),
    ...mapGetters(["getRealPlatform"]),
    getStandUpOrGoToLobbyText() {
      if (this.nAmISitting) {
        return this.$t("tableQuickMenu.standUp");
      }
      return this.$t("tableQuickMenu.goToLobby");
    },
  },
  data() {
    return {
      show: false,
      clicked: false,
      showButton: false,
      chatBaloon: false,
    };
  },
  methods: {
    toggleInviteBlock() {
      this.$store.state.user.invitation = !this.$store.state.user.invitation;
    },
    searchBtnClick() {
      this.$store.commit("modals/show", "search");
      this.$plugins.audio.play("Button");
      this.$data.show = false;
    },
    historyBtnClick() {
      this.$store.commit("modals/show", "history");
      this.$plugins.audio.play("Button");
      this.$data.show = false;
    },
    toggleShow() {
      this.$emit("tableMenuClick");
      this.$data.show = !this.$data.show;
      this.$plugins.audio.play("Button");
    },
    async toggleEmotePreference() {
      this.$plugins.audio.play("Button");

      await this.$store.dispatch("preferences/set", {
        key: "animated_gifts",
        value: this.getPreferenceValue("animated_gifts") == 1 ? "0" : "1",
      });
    },
    async toggleSoundsPreference() {
      this.$plugins.audio.play("Button");
      await this.$store.dispatch("preferences/set", {
        key: "sounds",
        value: this.getPreferenceValue("sounds") == 1 ? "0" : "1",
      });
      // this.$plugins.haptics.selectionChanged();
    },
    async toggleVibrationPreference() {
      this.$plugins.audio.play("Button");

      await this.$store.dispatch("preferences/set", {
        key: "vibration",
        value: this.getPreferenceValue("vibration") == 1 ? "0" : "1",
      });
    },
    onClickedOutsideWindow($event) {
      const isButtonClicked =
        $event?.target &&
        $event?.target?.className.includes("no-click-outside");
      if (!this.$data.show || isButtonClicked) {
        return;
      }

      this.$data.show = false;
    },
    standUpOrGoToLobby() {
      this.$data.clicked = true;
      this.$plugins.audio.play("Button");

      if (this.nAmISitting) {
        if (this.nGetMySeat.leaving) return;
        StorageManager.instance.remove({
          key: AppConstants.storageKeys.reconnect,
        });
        this.$store.state.service.colyseusConnection.ACTIVE_ROOM?.send("leave");
        return;
      }

      this.$store.state.service.colyseusConnection.ACTIVE_ROOM?.leave();
      this.$store.dispatch("user/getCurrentUserDetails");

      setTimeout(() => {
        if (
          this.$data.clicked &&
          this.$store.state.service.colyseusConnection.ACTIVE_ROOM?.hasJoined
        ) {
          this.$store.state.service.colyseusConnection.ACTIVE_ROOM.connection.transport.ws.close();
        }
      }, 2000);

      this.$store.commit("gift/allDeleteAnimation");
      this.$store.commit("gift/giftAllRemove");
    },
    async openTableList() {
      if (this.nAmISitting) return false;
      this.$plugins.audio.play("Button");

      const gameType = String(this.$store.state.service.ACTIVE_ROOM.gameType);
      await this.$store.state.service.colyseusConnection.ACTIVE_ROOM?.leave();

      if (gameType === "omaha_poker") {
        this.$store.commit("changeScene", "TexasHoldemTableList");
        this.$store.state.service.lastOpenedTableList = "Omaha";
      } else {
        this.$store.commit("changeScene", "TexasHoldemTableList");
        this.$store.state.service.lastOpenedTableList = "TexasHoldem";
      }
    },
    toggleReportPopup() {
      this.$plugins.audio.play("Button");

      this.$parent.$parent.$data.showReportPopup =
        !this.$parent.$parent.$data.showReportPopup;
    },
  },
};
</script>

<style scoped lang="scss">
.table-quick-menu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
  padding-top: 10px;
  padding-right: 10px;
  cursor: pointer;
  &-inner {
    position: relative;
  }
  &-button {
    transition: all 0.25s ease;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: rgba(black, 0.8);
    border: 3px solid rgba(#892632, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: 15px;
    top: 15px;
    &:active {
      filter: brightness(50%);
    }
  }
  &-dropdown {
    position: absolute;
    top: -9px;
    right: -8px;
    width: 580px;
    max-height: 844px;
    background: rgba(#3b0b14, 0.95);
    overflow-x: hidden;
    overflow-y: auto;
    &-items {
      display: block;
      margin-top: 12px;

      .dropdown-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 15px;
        transition: background-color 0.25s ease;
        .red-line {
          position: absolute;
          bottom: 0;
          left: 0;
        }
        .slider-part {
          position: absolute;
          cursor: pointer;
          right: 40px;
          .circle-button {
            position: absolute;
            right: -20px;
            top: -8px;
            &.left {
              left: -20px;
            }
          }
        }
        .chat-icon2 {
          margin-bottom: -5px;
        }
        &:disabled,
        &.disabled,
        &.leaving {
          opacity: 0.5;
        }
        &.active,
        &:not(:disabled):active {
          background-color: rgba(black, 0.2);
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 64px;
          .menu-gifts-show-hide-icon.hide {
            filter: brightness(50%);
          }
          .lobby-hand-history-icon {
            position: relative;
            right: 12px;
            background-position: -1930px -815px;
          }
          .lobby-search-user-button {
            transform: scale(0.75);
            position: relative;
            right: 5px;
            background-position: -1952px -301px;
          }
          .player-invite-icon {
            background: url("~@/assets/images/lobby/invite-icon.png");
            width: 50px;
            height: 50px;
          }
        }
        .text {
          margin-left: 20px;
          font-size: 36px;
          text-shadow: 0 2px 0 black;
          line-height: 32px;
        }
        &.text-center {
          justify-content: center;
          .text {
            margin-left: 0;
            font-size: 20px;
          }
        }
      }
      .text-center {
        padding-bottom: 20px;
        padding-top: 10px;
        .text {
          font-size: 34px !important;
        }
      }
    }
  }
}
</style>
