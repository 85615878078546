export class Facebook {
  constructor(opts) {
    this.config = opts.config;
  }

  init() {
    console.log("Facebook.init() not implemented");
  }

  fireEvent() {
    console.log("Facebook.fireEvent() not implemented");
  }
}
