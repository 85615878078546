import Logger from "@/logger";
import colyseusEndpoints from "@/constants/colyseusEndpoints";
import * as Colyseus from "colyseus.js";

export default {
  /*
   * Its creating new Colyseus.js Client
   * */
  createClient(endpoint = "poker") {
    return new Colyseus.Client(
      colyseusEndpoints[String(endpoint).toUpperCase()]
    );
  },

  /*
   * Its connection of colyseus.js lobby
   * */
  async connectToLobby(client, accessToken = "") {
    try {
      return client.joinOrCreate("lobby", { accessToken });
    } catch (error) {
      console.log("colyseus error", error);
      return Promise.reject(error);
    }
  },
};
