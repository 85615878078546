<template>
  <div
    type="button"
    class="auth-button"
    :class="[`auth-button-${$props.provider}`]"
    @click="onAuthButtonClicked"
  >
    <span class="auth-button-text">
      {{ providerText }}
    </span>
  </div>
</template>

<script>
import guest from "@/components/Misc/Svg/UserIcon";

export default {
  name: "AuthButton",
  components: {
    guest,
  },
  computed: {
    providerText() {
      return this.$t(`authentication.sign_${this.provider}`);
    },
  },
  props: {
    provider: {
      type: String,
      default: "",
      required: true,
      validator: (value) =>
        ["facebook", "google", "apple", "guest"].includes(value),
    },
    text: {
      type: String,
      default: "",
      required: false,
    },
    variant: {
      type: String,
      default: "",
      required: false,
    },
  },
  methods: {
    onAuthButtonClicked() {
      this.$emit("loginStart");
      this.$store.dispatch(`authentication/${this.$props.provider}`);
    },
  },
};
</script>

<style scoped lang="scss">
.auth-button {
  width: 400px;
  height: 120px;
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  &-text {
    position: relative;
    left: 90px;
    font-size: 36px;
    color: #ffffff;
  }
  &-facebook {
    background-image: url("~@/assets/images/authentication/facebook.png");
  }
  &-google {
    background-image: url("~@/assets/images/authentication/google.png");
  }
  &-apple {
    background-image: url("~@/assets/images/authentication/apple.png");
  }
  &-guest {
    background-image: url("~@/assets/images/authentication/guest.png");
  }

  &:active {
    transform: scale(1.1);
  }
}
</style>
