<template>
  <div
    class="rules"
    :class="[$parent.showRules ? 'show' : '']"
    v-click-outside="onClickedOutside"
  >
    <div class="rules-header">{{ $t("blackJack.rules") }}</div>
    <div class="rules-close" @click="$parent.showRules = false">
      <XmarkIcon />
    </div>

    <div class="rules-body">
      <div v-html="$t('blackJack.bjRules')"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";

export default {
  name: "Rules",
  components: {
    XmarkIcon: () => import("@/components/Icons/Xmark"),
  },
  directives: { ClickOutside },
  computed: {
    ...mapGetters(["getShortChipFormat", "getChipFormat"]),
  },
  methods: {
    onClickedOutside($event) {
      if (typeof $event?.target?.className.includes === "undefined")
        return false;
      if (
        $event?.target?.className.includes("rules-button") ||
        $event?.target?.className.includes("info-button") ||
        $event?.target?.className.includes("text") ||
        $event?.target?.className.includes("icon")
      )
        return false;
      this.$parent.showRules = false;
    },
  },
};
</script>

<style scoped lang="scss">
.rules {
  position: absolute;
  top: 0;
  z-index: 99;
  overflow: hidden;
  opacity: 0;
  transition: all 0.25s ease;
  left: 0;
  width: 800px;
  height: 1080px;
  border-radius: 0;
  background-color: rgba(lighten(black, 10%), 0.95);
  transform: translateX(-100%);
  &.show {
    transform: translateY(0);
    opacity: 1;
  }
  &-header {
    width: 100%;
    height: 30px;
    line-height: 30px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px 10px 10px calc(10px + env(safe-area-inset-left));
    background-color: rgba(black, 0.7);
    &-th {
      font-weight: bold;
    }
  }
  &-body {
    height: 480px;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 10px;
    font-size: 14px;
    color: rgba(rgb(255, 255, 255), 0.65);
    strong {
      font-weight: bold;
    }
  }
  &-close {
    height: 50px;
    width: 50px;
    position: absolute;
    left: 735px;
    top: 0px;
    svg {
      height: 80px;
      fill: rgb(255, 255, 255);
    }
  }
}

.rules {
  position: absolute;
  top: 0;
  left: 0;
  width: 800px;
  height: 1080px;
  border-radius: 0;
  background-color: rgba(lighten(black, 10%), 0.95);
  transform: translateX(-100%);
  &.show {
    display: block;
    transform: translateX(0);
  }
  &-header {
    font-size: 64px;
    height: 86px;
    padding: 30px;
  }
  &-body {
    height: calc(100% - 30px);
    font-size: 32px;
    padding: 30px;
    box-sizing: border-box;
  }
}
</style>
