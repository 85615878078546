<template>
  <div
    class="action-panel-outer"
    v-show="getActiveRoomState.countdown > 0 || getActiveRoomState.stage === 1"
  >
    <div class="action-panel-inner">
      <div
        v-if="[1, 3, 5].includes(getActiveRoomState.stage)"
        class="action-panel-timer"
        :class="getTimerClasses"
        :style="{
          visibility: getActiveRoomState.countdown > 0 ? 'visible' : 'hidden',
        }"
      >
        <span class="text">{{ getActiveRoomState.countdown }}</span>
      </div>
      <div class="action-panel-info-text" v-if="nGetMySeat.talked">
        {{ $t("blackJack.waitOtherPlayers") }}
      </div>
      <template v-if="getActiveRoomState.stage === 1">
        <div class="action-panel-info-text">
          {{ $t("blackJack.placeYourBet") }}
        </div>
        <BetSelector />
      </template>
      <template v-if="ableToShowActionButtons">
        <div
          class="action-panel-info-text"
          v-if="!nGetMySeat.talked || !nGetMySeat.split.talked"
        >
          {{ $t("blackJack.choose") }}
        </div>
        <ActionButtons />
      </template>
      <template
        v-if="
          getActiveRoomState.stage === 5 &&
          nGetMySeat.bet.main > 0 &&
          !nGetMySeat.talked
        "
      >
        <div class="action-panel-info-text">
          {{ $t("blackJack.wantInsurance") }}
        </div>
        <InsuranceButtons />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import BetSelector from "./ActionPanel/BetSelector";
import ActionButtons from "./ActionPanel/ActionButtons";
import InsuranceButtons from "./ActionPanel/InsuranceButtons";

export default {
  name: "ActionPanel",
  watch: {
    "getActiveRoomState.countdown": function (currentValue) {
      if (currentValue === 3) this.$plugins.audio.play("BjReminder2");
    },
  },
  components: {
    BetSelector,
    ActionButtons,
    InsuranceButtons,
  },
  computed: {
    ...mapState(["activeRoom"]),
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    getTimerClasses() {
      const currentValue = this.getActiveRoomState.countdown * 1000;
      const percent =
        (currentValue / this.getActiveRoomState.countdownStartValue) * 100;

      if (percent > 58) return ["green"];
      if (percent < 58 && percent > 25) return ["yellow"];
      return ["red"];
    },
    ableToShowActionButtons() {
      return (
        this.getActiveRoomState.stage === 3 &&
        this.nGetMySeat.bet.main > 0 &&
        !this.nGetMySeat.busted &&
        !this.nGetMySeat.talked &&
        this.nGetMySeat.handStrength < 21
      );
    },
  },
};
</script>

<style scoped lang="scss">
@-webkit-keyframes infoTextIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes infoTextIncomingAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.action-panel-outer {
  position: absolute;
  top: -260px;
  left: 0;
  width: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  .action-panel-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 200px;

    .action-panel-timer {
      position: relative;
      width: 110px;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      border: 10px solid red;
      font-weight: bold;
      text-shadow: 0 0 8px black;
      border-radius: 100%;
      overflow: hidden;
      box-shadow: inset 0 0 30px 25px black, 0 0 30px 15px black;
      transition: border-color 0.25s linear;
      .text {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(255, 255, 255);
      }
      &.green {
        border-color: green;
      }
      &.yellow {
        border-color: yellow;
      }
      &.red {
        border-color: red;
      }
    }

    .action-panel-info-text {
      background-image: linear-gradient(
        90deg,
        transparent 0%,
        black 30%,
        black 70%,
        transparent 100%
      );
      height: 40px;
      line-height: 40px;
      width: 100%;
      margin-top: 40px;
      text-align: center;
      font-weight: bold;
      font-size: 32px;
      color: rgb(255, 255, 255);
      text-transform: uppercase;
      text-shadow: 0 1px 0 black;
      overflow: hidden;
      animation-name: infoTextIncomingAnimation;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
    }
  }
}

.platform-mobile {
  .action-panel-outer {
    top: 30px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0.1px);
    z-index: 1;

    .action-panel-inner {
      .action-panel-timer {
        position: fixed;
        top: 80px;
        left: 1%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(0.1px);
        z-index: 1;
      }
      .action-panel-info-text {
        margin-top: 100px;
        margin-bottom: 50px;
      }
    }
  }
}
</style>
