<template>
  <BaseWindow
    width="1320"
    height="830"
    :show="true"
    :showCloseButton="false"
    :titleText="$t('disconnectedWindow.banned')"
  >
    <template>
      <div class="disconnect-window">
        <div class="disconnect-window-paragraph">
          <div>{{ $t("disconnectedWindow.banned") }}</div>
          <div class="small">
            {{ $t("disconnectedWindow.bannedDescription") }}
          </div>

          <div v-if="user.id > 0">ID: {{ user.id }}</div>
        </div>
        <div class="disconnect-window-footer">
          <button
            type="button"
            class="ui-button ui-button-green"
            @click="refreshApp"
          >
            {{ $t("general.okay") }}
          </button>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import { mapState } from "vuex";
import AppConstants from "@/constants/app";
import StorageManager from "@/plugins/storage-manager";
import BaseWindow from "./BaseWindow";

export default {
  name: "BannedWindow",
  components: {
    BaseWindow,
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async refreshApp() {
      this.$plugins.audio.play("Button");
      this.$plugins?.audio?.stop("Lobby1");
      await StorageManager.instance.remove({
        key: AppConstants.storageKeys.tokens,
      });
      if (window.__notifyOnlineInterval)
        clearInterval(window.__notifyOnlineInterval);
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.disconnect-window {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  height: 85%;
  width: 98%;
  border-radius: 20px;
  background: #83212d;
  &-footer {
    width: 100%;
    text-align: center;
  }
  &-paragraph {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 48px;
    height: 300px;
    div {
      margin-bottom: 50px;
      &.small {
        font-size: 36px;
      }
    }
  }
}
</style>
