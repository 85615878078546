import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import languageFile from "../../lang";

const i18n = new VueI18n({
  locale: "en",
  silentTranslationWarn: true,
  fallbackLocale: "en",
  messages: languageFile,
});

i18n.changeLocale = (value) => {
  i18n.locale = value;
  document.documentElement.setAttribute("lang", value);
};

const browserLanguage = (navigator.language || navigator.userLanguage).split(
  "-"
)[0];

if (browserLanguage && String(browserLanguage).length > 0) {
  i18n.changeLocale(browserLanguage);
}

export default i18n;
