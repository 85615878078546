export default {
  setGlobalMessage(state, data) {
    // state.globalMessages.push(data)
    this._vm.$set(state.globalMessages, state.globalMessages.length, data);
  },
  setUserConversations(state, data) {
    data.sort((a, b) => {
      return b.updatedAt - a.updatedAt;
    });
    this._vm.$set(state, "playerConversations", data);
  },
  setSelectedConversation(state, { data, offSet }) {
    if (offSet === 0) {
      data.messages.sort((a, b) => {
        return a.serverTime - b.serverTime;
      });
      this._vm.$set(state, "selectedConversation", data);
    } else {
      state.selectedConversation.total = data.total;
      data.messages.sort((a, b) => {
        return b.serverTime - a.serverTime;
      });
      data.messages.forEach((message) => {
        state.selectedConversation.messages.unshift(message);
      });
    }
  },
  setSelectedChat(state, data) {
    this._vm.$set(state, "selectedChat", data);
  },
  clearSelectedChat(state) {
    this._vm.$set(state, "selectedChat", {});
    this._vm.$set(state, "selectedConversation", []);
  },
  setTableMessages(state, message) {
    this._vm.$set(state, "tableMessages", [...state.tableMessages, message]);
  },
  spliceTableMessages(state, index) {
    const newMessages = state.tableMessages.splice(index, 1);
    this._vm.$set(state, "tableMessages", newMessages);
  },
};
