import { render, staticRenderFns } from "./SlotGame.vue?vue&type=template&id=634614c0&scoped=true&"
import script from "./SlotGame.vue?vue&type=script&lang=js&"
export * from "./SlotGame.vue?vue&type=script&lang=js&"
import style0 from "./SlotGame.vue?vue&type=style&index=0&id=634614c0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "634614c0",
  null
  
)

export default component.exports