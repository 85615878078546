<template>
  <div class="auto-action-buttons" v-if="isAbleToShowAutoActionButtons">
    <button
      type="button"
      class="action-button red auto-action-button"
      :class="[selectedAutoButton === 'fold' ? 'active' : '']"
      @click="setAutoButton('fold')"
    >
      <div class="action-button-x"></div>
      <span class="action-button-text">{{ $t("actionPanel.fold") }}</span>
    </button>
    <button
      type="button"
      class="action-button blue auto-action-button"
      :class="[selectedAutoButton === 'check_fold' ? 'active' : '']"
      @click="setAutoButton('check_fold')"
    >
      <div class="action-button-circle"></div>
      <span class="action-button-text">{{ $t("actionPanel.checkFold") }}</span>
    </button>
    <button
      type="button"
      class="action-button green auto-action-button"
      :class="[selectedAutoButton === 'call' ? 'active' : '']"
      @click="setAutoButton('call')"
    >
      <div class="action-button-tick"></div>
      <span class="action-button-text">{{
        $props.callAmount > 0
          ? `${$t("actionPanel.call")}: ${getShortChipFormat(
              $props.callAmount
            )}`
          : $t("actionPanel.check")
      }}</span>
    </button>
    <button
      type="button"
      class="action-button yellow auto-action-button"
      :class="[selectedAutoButton === 'call_any' ? 'active' : '']"
      @click="setAutoButton('call_any')"
    >
      <div class="action-button-c"></div>
      <span class="action-button-text">{{ $t("actionPanel.callAny") }}</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AutoActionButtons",
  props: {
    callAmount: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  watch: {
    "$props.callAmount": function (newValue, oldValue) {
      if (
        this.selectedAutoButton === "call" &&
        newValue > oldValue &&
        this.getActiveRoomState.currentTurn !== -1
      ) {
        this.selectedAutoButton = "";
      }
    },
    "getActiveRoomState.currentTurn": function (currentTurn) {
      if (this.selectedAutoButton === "" || currentTurn === -1) return false;

      if (this.nGetMySeat?.position === currentTurn)
        setTimeout(() => {
          this.takeAutoAction();
        }, 500);
    },
    "getActiveRoomState.stage": function (newValue, oldValue) {
      if (newValue === 1 && oldValue === 5) {
        this.selectedAutoButton = "";
      }
    },
    selectedAutoButton: function (newValue) {
      setTimeout(
        () => {
          this.$parent.isAnyAutoButtonSelected = newValue !== "";
        },
        newValue !== "" ? 0 : 200
      );
    },
  },
  computed: {
    ...mapGetters({
      getShortChipFormat: "getShortChipFormat",
      nAmISitting: "service/nAmISitting",
      nGetMySeat: "service/nGetMySeat",
      getActiveRoomState: "service/getActiveRoomState",
    }),
    isAbleToShowAutoActionButtons() {
      return (
        this.nAmISitting &&
        this.getActiveRoomState.currentTurn > -1 &&
        this.getActiveRoomState.currentTurn !== this.nGetMySeat.position &&
        this.nGetMySeat?.in &&
        this.getActiveRoomState.stage < 5 &&
        this.nGetMySeat?.stack > 0
      );
    },
  },
  data() {
    return {
      selectedAutoButton: "",
    };
  },
  methods: {
    setAutoButton(name) {
      if (this.selectedAutoButton === name) {
        this.selectedAutoButton = "";
        return;
      }
      this.selectedAutoButton = name;
      this.$plugins?.audio?.play("Button");
    },
    takeAutoAction() {
      switch (this.selectedAutoButton) {
        case "call":
        case "call_any":
          if (this.$props.callAmount > 0) this.$emit("onAction", "call");
          else this.$emit("onAction", "check");
          break;
        case "fold":
          this.$emit("onAction", "fold");
          break;
        case "check_fold":
          if (this.$props.callAmount === 0) this.$emit("onAction", "check");
          else this.$emit("onAction", "fold");
          break;
      }

      this.selectedAutoButton = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.auto-action-buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: 843px;
  position: relative;
  bottom: 5px;
  right: 18px;
  .action-button {
    width: 250px;
    padding-left: 0;
    padding-right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;

    &-text {
      position: absolute;
      bottom: 85px;
      font-size: 46px;
      color: #ffffff;
    }
    div {
      position: relative;
    }
    &-x {
      background: url("~@/assets/images/table/auto-action-buttons/x.png")
        no-repeat 50% 50%;
      width: 34px;
      height: 36px;
    }
    &-circle {
      background: url("~@/assets/images/table/auto-action-buttons/circle.png")
        no-repeat 50% 50%;
      width: 36px;
      height: 36px;
    }
    &-tick {
      background: url("~@/assets/images/table/auto-action-buttons/tick.png")
        no-repeat 50% 50%;
      width: 46px;
      height: 36px;
    }
    &-c {
      background: url("~@/assets/images/table/auto-action-buttons/c.png")
        no-repeat 50% 50%;
      width: 32px;
      height: 37px;
    }
  }
  .auto-action-button {
    height: 80px;
    width: 80px;
    font-size: 42px;
    background-color: transparent;
    border: 5px solid rgba(#892632, 1);
    border-radius: 40px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    margin-right: 30px;
    transition: all 0.25s ease;

    &:last-child {
      margin-right: 0;
    }
    &:active {
      border-color: rgb(255, 255, 255);
      box-shadow: 0 0 6px 0 rgba(rgb(255, 255, 255), 0.5);
      opacity: 0.8;
    }
    &.active {
      border: 8px solid rgba(#892632, 1);
      text-shadow: 0 3px 5px #000000;
      &.green {
        color: $greenColor;
        border-color: $greenColor;
        box-shadow: 0 0 6px 0 $greenColor;
      }
      &.red {
        color: red;
        border-color: red;
        box-shadow: 0 0 6px 0 red;
      }
      &.blue {
        color: #3181f9;
        border-color: #3181f9;
        box-shadow: 0 0 6px 0 #3181f9;
      }
      &.yellow {
        color: $yellowColor;
        border-color: $yellowColor;
        box-shadow: 0 0 6px 0 $yellowColor;
      }
      opacity: 0.8;
    }
  }
}
</style>
