<template>
  <BaseWindow
    width="1320"
    height="830"
    :show="true"
    @closeWindow="close"
    :titleText="$t('disconnectedWindow.inactive')"
  >
    <template>
      <div class="disconnect-window">
        <div class="disconnect-icon"></div>
        <div class="disconnect-window-paragraph">
          {{ $t("disconnectedWindow.inactive") }}
        </div>
        <div class="disconnect-window-footer">
          <button
            type="button"
            class="ui-button ui-button-small-orange"
            @click="refreshApp"
          >
            {{ $t("disconnectedWindow.reconnect") }}
          </button>
        </div>
      </div>
    </template>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";

export default {
  name: "InactivityWindow",
  components: {
    BaseWindow,
  },
  methods: {
    close() {
      this.$store.commit("modals/hide", "disconnectInactivity");
    },
    refreshApp() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.disconnect-window {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
  box-sizing: border-box;
  background: #83212d;
  height: 83%;
  width: 98%;
  border-radius: 20px;
  .disconnect-icon {
    width: 400px;
    height: 400px;
    background-image: url("~@/assets/images/lobby/wifi.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  &-footer {
    width: 100%;
    text-align: center;
  }
  &-paragraph {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    height: 300px;
  }
}
</style>
