import AppConstants from "@/constants/app";
import Analytics from "./analytics";

const analytics = new Analytics(
  AppConstants.externalLibrary.analytics.providers
);

export default {
  instance: analytics,
  install(Vue) {
    const callbacks = [];

    Vue.directive("analytics-fire-onclick", function (el, binding) {
      const callbackId = btoa(el.toString() + binding.value.eventName);
      if (callbacks[callbackId]) {
        el.removeEventListener("click", callbacks[callbackId]);
      }
      callbacks[callbackId] = () => {
        analytics.fireEvent(binding.value.eventName, binding.value.params);
      };
      el.addEventListener("click", callbacks[callbackId]);
    });

    Vue.directive("analytics-fire-onload", function (el, binding) {
      analytics.fireEvent(binding.value.eventName, binding.value.params);
    });

    Vue.prototype.$plugins.analytics = analytics;
  },
};
