<template>
  <SafeArea showBatteryInfo showNetworkInfo>
    <div id="authentication-scene">
      <div
        class="black-overlay no-flick"
        v-if="
          $store.state.authentication.showOverlay && getRealPlatform !== 'web'
        "
      ></div>
      <div class="logo-outer" v-show="!loggingIn">
        <div class="turn-games-logo"></div>
      </div>
      <div
        class="information-container"
        v-show="!loggingIn && !autoLoginProcessing"
      >
        <div class="info-bg">
          <div
            class="texts"
            v-html="
              $t(`authentication.loginBonusText${webOrPhone}`, platformTexts)
            "
          ></div>
        </div>
      </div>
      <div class="authentication-wrapper not-logged-in">
        <!-- NOT LOGGED IN START -->
        <div class="auth-buttons">
          <div class="auth-buttons-background" v-show="!loggingIn">
            <template v-if="!autoLoginProcessing">
              <AuthButton
                provider="facebook"
                variant="blue"
                @loginStart="loggingIn = true"
              />
              <AuthButton
                provider="apple"
                variant="black"
                v-if="getRealPlatform === 'ios'"
                @loginStart="loggingIn = true"
              />
              <AuthButton
                provider="google"
                variant="red"
                v-if="
                  getRealPlatform === 'android' || getRealPlatform === 'ios'
                "
                @loginStart="loggingIn = true"
              />
              <AuthButton
                provider="guest"
                variant="white"
                v-if="!isInFacebook"
              />
            </template>
            <template v-if="autoLoginProcessing">
              <Spinner size="48px" radius="0%" />
            </template>
          </div>
        </div>
        <div
          class="use-invite-code-container"
          v-if="!autoLoginProcessing && !loggingIn"
        >
          <div
            type="button"
            class="use-invite-code-button"
            :class="[
              authentication.inviteCode === null
                ? 'green-type'
                : 'ui-button ui-button-red',
            ]"
            @click="onInviteCodeButtonClicked"
          >
            <span class="use-invite-code-button-text">
              {{
                authentication.inviteCode === null
                  ? $t("useInviteCode.heading")
                  : $t("useInviteCode.unset")
              }}
            </span>
          </div>
        </div>
        <!-- NOT LOGGED IN END -->
      </div>
    </div>
    <div
      class="authentication-scene-information-text"
      v-if="getAuthInformationText.length > 0"
    >
      <div class="authentication-scene-information-text-inner">
        {{ getAuthInformationText }}
      </div>
    </div>
  </SafeArea>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import AppConstants from "@/constants/app";
import EventConstants from "@/constants/events";
import EventBus from "@/event-bus";
import AuthButton from "@/components/Misc/Authentication/AuthButton";
import Spinner from "../Utils/Spinner";
import SafeArea from "../Screen/SafeArea";

export default {
  name: "Authentication",
  components: {
    SafeArea,
    Spinner,
    AuthButton,
  },
  computed: {
    webOrPhone() {
      if (this.getRealPlatform === "web") {
        return `Web`;
      }
      return ``;
    },
    platformTexts() {
      if (this.getRealPlatform === "ios") {
        return ["Facebook Apple", "Google"];
      } else if (this.getRealPlatform === "android") {
        return ["Facebook", "Google"];
      } else if (this.getRealPlatform === "web") {
        return ["Facebook"];
      }
      return [];
    },
    ...mapState(["authentication"]),
    ...mapGetters(["getPlatform", "getRealPlatform", "isInFacebook"]),
    getAuthInformationText() {
      if (this.$data.loggingIn) return this.$t("loading");
      return "";
    },
    getApplicationVersion() {
      return `v${AppConstants.applicationVersion}`;
    },
  },

  data() {
    return {
      loggingIn: false,
      autoLoginProcessing: true,
      errorMessage: this.$t("errors.loginError"),
    };
  },
  methods: {
    onLoggingIn() {
      this.$data.autoLoginProcessing = false;
    },
    onLoginError() {
      this.$data.loggingIn = false;
      this.$data.autoLoginProcessing = false;
    },
    onAutoLoginProcessing() {
      this.$data.autoLoginProcessing = true;
    },
    onAutoLoginFailed() {
      this.$data.autoLoginProcessing = false;
    },
    cancelLoginProcess() {
      this.$store.commit("authentication/clearAuthenticationState");
      this.$store.commit("changeScene", "Authentication");
      this.$data.autoLoginProcessing = false;
      this.$data.loggingIn = false;
    },
    onInviteCodeButtonClicked() {
      if (this.authentication.inviteCode === null) {
        this.$store.commit("modals/show", "useInviteCode");
        return;
      }

      this.$store.commit("authentication/setInviteCode", { code: null });
    },
  },
  beforeDestroy() {
    EventBus.$off(EventConstants.LOGGING_IN, this.onLoggingIn.bind(this));
    EventBus.$off(EventConstants.LOGIN_ERROR, this.onLoginError.bind(this));
    EventBus.$off(
      EventConstants.AUTO_LOGIN_PROCESSING,
      this.onAutoLoginProcessing.bind(this)
    );
    EventBus.$off(
      EventConstants.AUTO_LOGIN_FAILED,
      this.onAutoLoginFailed.bind(this)
    );
  },
  created() {
    EventBus.$on(EventConstants.LOGGING_IN, this.onLoggingIn.bind(this));
    EventBus.$on(EventConstants.LOGIN_ERROR, this.onLoginError.bind(this));
    EventBus.$on(
      EventConstants.AUTO_LOGIN_PROCESSING,
      this.onAutoLoginProcessing.bind(this)
    );
    EventBus.$on(
      EventConstants.AUTO_LOGIN_FAILED,
      this.onAutoLoginFailed.bind(this)
    );
    this.loggingIn = false;

    this.$adjust.trackEvent("LoginScreenOn");
  },
};
</script>

<style lang="scss">
@keyframes errorAlertIncomingAnimation {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

#authentication-scene {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .black-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 999999;
  }
}
.authentication-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  height: 30%;
  box-sizing: border-box;
}

.logo-outer {
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  .turn-games-logo {
    width: 900px;
    height: 150px;
    background-image: url("~@/assets/images/backgrounds/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.information-container {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .info-bg {
    width: 925px;
    height: 292px;
    background-image: url("~@/assets/images/backgrounds/info-bar.png");
    display: flex;
    justify-content: flex-end;
  }
  .texts {
    color: #eebb67;
    font-size: 45px;
    height: 100%;
    width: 75%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .login-info-text {
      line-height: 70px;
      .color-white {
        color: white;
      }
    }
  }
}
.authentication-scene-information-text {
  position: absolute;
  left: 0;
  bottom: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &-inner {
    width: 1200px;
    height: 100px;
    font-size: 60px;
    font-weight: bold;
    text-shadow: 0 0 6px black;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, transparent, black, transparent);
    text-transform: uppercase;
  }
}
.auth-buttons {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .loading-parrot {
    margin-right: -35px;
  }
  .auth-buttons-background {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    .auth-button:not(:last-child) {
      margin-right: 70px;
    }
  }
  .auth-loading-bar {
    margin-bottom: 16px;
  }
}

.cancel-button {
  text-transform: uppercase;
}

.use-invite-code-container {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  .use-invite-code-button {
    width: 350px;
    height: 100px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    &.green-type {
      background-image: url("~@/assets/images/authentication/invite.png");
    }
    &:active {
      transform: scale(1.1);
    }
    &-text {
      position: relative;
      left: 30px;
      font-size: 36px;
      color: #ffffff;
    }
  }
}
</style>
