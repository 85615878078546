import Logger from "@/logger";
import axios from "@/services/api/axios";
import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";
import AppConstants from "@/constants/app";
import StorageManager from "@/plugins/storage-manager";
import DeviceManager from "@/plugins/device";
import Toast from "@/plugins/toast";
import i18n from "@/plugins/i18n";
import adjust from "@/plugins/adjust";

import { FacebookLogin } from "@capacitor-community/facebook-login";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";

export default {
  async facebook({ state, rootState, dispatch, commit }, pin = null) {
    try {
      state.requestedAuthMethod = "facebook";
      EventBus.$emit(EventConstants.LOGGING_IN, { provider: "facebook" });

      const result = await FacebookLogin.login({
        permissions: ["email", "public_profile"],
      });

      if (!result.accessToken || result.accessToken.token == null) {
        EventBus.$emit(EventConstants.LOGIN_ERROR, { provider: "facebook" });
        return false;
      }

      const params = {};
      if (state.inviteCode !== null) params.inviteCode = state.inviteCode;

      const response = await axios.post(
        `/auth/facebook`,
        {
          token: result.accessToken.token,
          pin: pin,
        },
        { params }
      );

      state.token.access = response.data.access.token;
      state.token.refresh = response.data.refresh.token;
      state.authType = "facebook";

      StorageManager.instance.set({
        key: AppConstants.storageKeys.tokens,
        value: JSON.stringify({
          access: state.token.access,
          refresh: state.token.refresh,
          authType: state.authType,
        }),
      });

      adjust.trackEvent("FacebookLogin");

      EventBus.$emit(EventConstants.LOADING_PERCENT_INCREASED, { value: 15 });
      EventBus.$emit(EventConstants.LOGGED_IN_SUCCESSFULLY, {
        provider: "facebook",
      });

      await dispatch("onAuthenticated");
    } catch (error) {
      Logger.print("error", error);
      if (error?.response?.status === 403) {
        rootState.user.id = error.response.data.userId;
        commit("modals/show", "banned", { root: true });
        return;
      } else if (error?.response?.status === 400) {
        Toast.instance.show({
          text: i18n.t("useInviteCode.inviteCodeNotFound"),
          variant: "danger",
        });
      } else if (error?.response?.status === 423) {
        if (error?.response?.data?.message === "Wrong pin code") {
          Toast.instance.show({
            text: i18n.t("pinCode.wrong"),
            variant: "danger",
          });
        }
        commit("modals/show", "pinCode", { root: true });
      } else {
        Toast.instance.show({
          text: i18n.t("errors.errorOccured"),
          duration: 3500,
          variant: "danger",
        });
      }
      EventBus.$emit(EventConstants.LOGIN_ERROR, { provider: "facebook" });
    }
  },

  async apple({ state, rootState, dispatch, commit }, pin = null) {
    try {
      state.requestedAuthMethod = "apple";
      EventBus.$emit(EventConstants.LOGGING_IN, { provider: "apple" });

      const appleSiginResult = await SignInWithApple.authorize({
        clientId: AppConstants.externalLibrary.APPLE.clientId,
        redirectURI: AppConstants.externalLibrary.APPLE.redirectURI,
        scopes: "email name",
        state: Math.random(),
        nonce: "nonce",
      });

      const params = {};
      if (state.inviteCode !== null) params.inviteCode = state.inviteCode;

      const response = await axios.post(
        `/auth/apple`,
        {
          token: appleSiginResult.response.identityToken,
          pin: pin,
        },
        { params }
      );

      state.token.access = response.data.access.token;
      state.token.refresh = response.data.refresh.token;
      state.authType = "apple";

      adjust.trackEvent("AppleLogin");

      StorageManager.instance.set({
        key: AppConstants.storageKeys.tokens,
        value: JSON.stringify({
          access: state.token.access,
          refresh: state.token.refresh,
          authType: state.authType,
        }),
      });

      EventBus.$emit(EventConstants.LOADING_PERCENT_INCREASED, { value: 15 });
      EventBus.$emit(EventConstants.LOGGED_IN_SUCCESSFULLY, {
        provider: "apple",
      });

      await dispatch("onAuthenticated");
    } catch (error) {
      Logger.print("error", error);
      if (error?.response?.status === 403) {
        rootState.user.id = error.response.data.userId;
        commit("modals/show", "banned", { root: true });
        return;
      } else if (error?.response?.status === 400) {
        Toast.instance.show({
          text: i18n.t("useInviteCode.inviteCodeNotFound"),
          variant: "danger",
        });
      } else if (error?.response?.status === 423) {
        if (error?.response?.data?.message === "Wrong pin code") {
          Toast.instance.show({
            text: i18n.t("pinCode.wrong"),
            variant: "danger",
          });
        }
        commit("modals/show", "pinCode", { root: true });
      } else {
        Toast.instance.show({
          text: i18n.t("errors.errorOccured"),
          duration: 3500,
          variant: "danger",
        });
      }
      EventBus.$emit(EventConstants.LOGIN_ERROR, { provider: "apple" });
    }
  },

  async google({ state, rootState, dispatch, commit }, pin = null) {
    try {
      state.requestedAuthMethod = "google";
      EventBus.$emit(EventConstants.LOGGING_IN, { provider: "google" });

      const googleSigninResult = await GoogleAuth.signIn();

      if (typeof googleSigninResult.authentication.idToken === "undefined") {
        EventBus.$emit(EventConstants.LOGIN_ERROR, { provider: "google" });
        return;
      }

      const params = {};
      if (state.inviteCode !== null) params.inviteCode = state.inviteCode;

      const response = await axios.post(
        `/auth/google`,
        {
          token: googleSigninResult.authentication.idToken,
          profileImageUrl: googleSigninResult.imageUrl,
          pin: pin,
        },
        { params }
      );

      state.token.access = response.data.access.token;
      state.token.refresh = response.data.refresh.token;
      state.authType = "google";

      adjust.trackEvent("GoogleLogin");

      StorageManager.instance.set({
        key: AppConstants.storageKeys.tokens,
        value: JSON.stringify({
          access: state.token.access,
          refresh: state.token.refresh,
          authType: state.authType,
        }),
      });

      EventBus.$emit(EventConstants.LOADING_PERCENT_INCREASED, { value: 15 });
      EventBus.$emit(EventConstants.LOGGED_IN_SUCCESSFULLY, {
        provider: "google",
      });

      await dispatch("onAuthenticated");
    } catch (error) {
      Logger.print("error", error);
      if (error?.response?.status === 403) {
        rootState.user.id = error.response.data.userId;
        commit("modals/show", "banned", { root: true });
        return;
      } else if (error?.response?.status === 400) {
        Toast.instance.show({
          text: i18n.t("useInviteCode.inviteCodeNotFound"),
          variant: "danger",
        });
      } else if (error?.response?.status === 423) {
        if (error?.response?.data?.message === "Wrong pin code") {
          Toast.instance.show({
            text: i18n.t("pinCode.wrong"),
            variant: "danger",
          });
        }
        commit("modals/show", "pinCode", { root: true });
      } else {
        Toast.instance.show({
          text: i18n.t("errors.errorOccured"),
          duration: 3500,
          variant: "danger",
        });
      }
      EventBus.$emit(EventConstants.LOGIN_ERROR, { provider: "google" });
    }
  },

  async guest({ state, rootState, dispatch, commit }, pin = null) {
    try {
      const uuid = (await DeviceManager.instance.getId()).uuid;
      const response = await axios.post(`/auth/guest`, {
        uuid: uuid,
        pin: pin,
      });

      if (response.data.newUserNotRegistered) {
        Toast.instance.show({
          text: i18n.t("others.guestClose"),
          variant: "danger",
        });
        return;
      } else {
        state.newUserNotRegistered = false;
      }

      state.requestedAuthMethod = "guest";
      EventBus.$emit(EventConstants.LOGGING_IN, { provider: "guest" });

      state.token.access = response.data.access.token;
      state.token.refresh = response.data.refresh.token;
      state.authType = "guest";

      StorageManager.instance.set({
        key: AppConstants.storageKeys.tokens,
        value: JSON.stringify({
          access: state.token.access,
          refresh: state.token.refresh,
          authType: state.authType,
        }),
      });

      adjust.trackEvent("GuestLogin");

      EventBus.$emit(EventConstants.LOADING_PERCENT_INCREASED, { value: 15 });
      EventBus.$emit(EventConstants.LOGGED_IN_SUCCESSFULLY, {
        provider: "guest",
      });

      await dispatch("onAuthenticated");
    } catch (error) {
      Logger.print("error", error);
      if (error?.response?.status === 423) {
        if (error?.response?.data?.message === "Wrong pin code") {
          Toast.instance.show({
            text: i18n.t("pinCode.wrong"),
            variant: "danger",
          });
        }
        commit("modals/show", "pinCode", { root: true });
      } else if (error.response.status === 403) {
        rootState.user.id = error.response.data.userId;
        commit("modals/show", "banned", { root: true });
        return;
      } else if (error.response.status === 400) {
        Toast.instance.show({
          text: "Blocked due to IP address limit.",
          variant: "danger",
        });
      }
      EventBus.$emit(EventConstants.LOGIN_ERROR, { provider: "guest" });
    }
  },

  async refreshToken({ state }) {
    try {
      const response = await axios.post(`/auth/refresh-tokens`, {
        refreshToken: state.token.refresh,
      });
      // .catch(async (error) => {
      //   await StorageManager.instance.remove({
      //     key: AppConstants.storageKeys.tokens,
      //   });
      //   return false;
      // });

      if (response && response.status === 400) {
        await StorageManager.instance.remove({
          key: AppConstants.storageKeys.tokens,
        });
        return false;
      }

      if (
        response &&
        response.data &&
        typeof response.data.code !== "undefined"
      ) {
        Logger.print("error", "An API login error.");
        return false;
      }
      if (response.status !== 200) {
        await StorageManager.instance.remove({
          key: AppConstants.storageKeys.tokens,
        });
        return false;
      }

      state.token.access = response.data.access.token;
      state.token.refresh = response.data.refresh.token;

      await StorageManager.instance.set({
        key: AppConstants.storageKeys.tokens,
        value: JSON.stringify({
          access: state.token.access,
          refresh: state.token.refresh,
          authType: state.authType,
        }),
      });

      return response.data.access.token;
    } catch (error) {
      await StorageManager.instance.remove({
        key: AppConstants.storageKeys.tokens,
      });
      console.log(error);
      return false;
    }
  },

  async onAuthenticated({ rootState, dispatch, commit }) {
    rootState.scene = "Loading";
    commit("modals/hide", "disconnect", { root: true });
    await dispatch("refreshToken");
    await dispatch("user/getCurrentUserDetails", null, { root: true });
    dispatch("products/fetch", null, { root: true });
    dispatch("friends/getFriends", 0, { root: true });
    dispatch("friends/getRequests", 0, { root: true });
    dispatch("preferences/fetch", null, { root: true });
    dispatch("user/getAffiliateStatus", null, { root: true });
    dispatch("chat/getUserConversations", null, { root: true });
    // await dispatch("user/startNotifyOnlineInterval", null, { root: true });
    dispatch("bonus/getOneClickBonusStatus", null, { root: true });
    dispatch("bonus/getAdBonusStatus", null, { root: true });

    await dispatch("initializeServices", null, { root: true });

    console.log("lobbyyy11");
    if (rootState.service.lastTableId < 1) {
      rootState.scene = "Lobby";
    }

    // if (reconnect) {
    //   const lastTableId = rootState.service.lastTableId;

    //   if (lastTableId && lastTableId > 0) {
    //     await dispatch(
    //       "service/joinPokerRoom",
    //       { roomId: lastTableId },
    //       { root: true }
    //     );
    //   }
    // }
  },

  /*async reAuthenticated({ rootState, dispatch, commit }) {
    await dispatch("user/getCurrentUserDetails", null, { root: true });
    await dispatch("initializeServices", { reConnect: true }, { root: true });

    const lastTableId = rootState.service.lastTableId;

    if (lastTableId && lastTableId > 0) {
      await dispatch(
        "service/joinPokerRoom",
        { roomId: lastTableId },
        { root: true }
      );
    }
  }, */

  async tryAutoLogin({ state, rootState, dispatch }, pin = null) {
    rootState.scene = "Authentication";
    EventBus.$emit(EventConstants.AUTO_LOGIN_PROCESSING);
    Logger.print("info", "Auto login trying...");

    if (
      new URLSearchParams(window.location.search).get("auth_type") ===
      "facebook"
    ) {
      await dispatch("facebook");
      return;
    }

    try {
      const tokensFromStorage = await StorageManager.instance.get({
        key: AppConstants.storageKeys.tokens,
      });

      if (tokensFromStorage.value == null) {
        Logger.print("info", "Tokens not saved before");
        EventBus.$emit(EventConstants.AUTO_LOGIN_FAILED);
        return;
      }

      const tokens = JSON.parse(tokensFromStorage.value);

      state.token.access = tokens.access;
      state.token.refresh = tokens.refresh;
      state.authType = tokens.authType;
      state.requestedAuthMethod = tokens.authType;

      const verify = await dispatch("verifyUser", pin);
      if (verify) {
        await dispatch("onAuthenticated");
      }
    } catch (error) {
      Logger.print("error", ["tryAutoLogin", error]);
      EventBus.$emit(EventConstants.AUTO_LOGIN_FAILED);
    }
  },

  async logout({ rootState, state, commit, dispatch }) {
    await StorageManager.instance.remove({ key: "tokens" });
    if (state.authType == "facebook") {
      await FacebookLogin.logout();
    }

    state.token.access = null;
    state.token.refresh = null;
    state.authType = null;

    await dispatch("destroyServices", null, { root: true });
  },

  async verifyUser({ state, rootState, commit }, pin = null) {
    try {
      state.isTryingAutoLogin = true;
      await axios.post(`/auth/verify`, {
        accessToken: state.token.access,
        pin: pin,
      });
      return true;
    } catch (error) {
      if (error?.response?.status === 401) {
        await StorageManager.instance.remove({ key: "tokens" });
        location.reload();
      }
      if (error?.response?.status === 403) {
        await StorageManager.instance.remove({ key: "tokens" });
        rootState.user.id = error.response.data.userId;
        commit("modals/show", "banned", { root: true });
      }
      if (error?.response?.status === 423) {
        if (error?.response?.data?.message === "Wrong pin code") {
          Toast.instance.show({
            text: i18n.t("pinCode.wrong"),
            variant: "danger",
          });
        }
        commit("modals/show", "pinCode", { root: true });
      }
      return;
    }
  },
};
