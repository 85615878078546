var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pot",class:_vm.getClasses},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$props.data.amount > 0),expression:"$props.data.amount > 0"}],staticClass:"pot-inner"},[_c('div',{staticClass:"icon",class:[
        ..._vm.potIconClass,
        `icon-${
          _vm.chipIsMeAndRaise
            ? 'raise'
            : _vm.lastActionText == null && _vm.type !== 'seat'
            ? 'table'
            : _vm.type == 'table'
            ? 'table'
            : _vm.lastActionText
        }`,
      ],style:(_vm.getSeatIconStyles)},[(
          (_vm.lastActionText == 'check' ||
            _vm.lastActionText == null ||
            _vm.lastActionText == undefined) &&
          _vm.lastActionText !== 'raise' &&
          _vm.lastActionText !== 'allin' &&
          _vm.type != 'table' &&
          !_vm.chipIsMeAndRaise
        )?_c('CheckMark',{staticClass:"svg-check"}):_vm._e(),(
          (_vm.type != 'table' &&
            (_vm.lastActionText === 'raise' || _vm.lastActionText === 'allin')) ||
          _vm.chipIsMeAndRaise
        )?_c('ChevronDown',{staticClass:"chevron-down"}):_vm._e()],1),_c('div',{staticClass:"text",class:`text-${
        _vm.chipIsMeAndRaise
          ? 'raise'
          : _vm.lastActionText
          ? _vm.lastActionText
          : _vm.type === 'seat'
          ? 'check'
          : 'table'
      }`},[_vm._v(" "+_vm._s(_vm.getShortChipFormat(_vm.$props.data.amount))+" ")])]),_c('div',{staticClass:"pot-animations"},_vm._l((_vm.animations),function(animation,animationIndex){return _c('div',{key:'animation-' + animation._id + '-' + animationIndex,staticClass:"pot-inner"},[_c('div',{staticClass:"icon"},_vm._l((animation.stacks),function(stack,i){return _c('div',{key:'stack-' + i,class:['stack']},_vm._l((stack),function(pot,i){return _c('div',{key:'pot-icon-' + i,class:[
              'pot-icon sprite-table chip',
              `index-${_vm.$props.index}`,
              `type-${animation.type}`,
              `to-${animation.to}`,
              animation.animate ? `animate` : '',
            ],style:(_vm.getAnimationStyle(i))})}),0)}),0),_c('div',{staticClass:"text"})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }