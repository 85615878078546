import io from 'socket.io-client';

const communicationWebsocketUrl = process.env.VUE_APP_COMMUNICATION_WS_URL || 'localhost';

const init = () => io.connect(communicationWebsocketUrl, {
    reconnect: true,
    transports: ['websocket'],
    upgrade: false,
    agent: false,
    rejectUnauthorized: false
});

export default {
    init
}