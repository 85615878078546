import axios from "@/services/api/axios";
import toast from "@/plugins/toast";
import i18n from "@/plugins/i18n";

export default {
  async getFriends({ commit }, offSet = 0) {
    try {
      const response = await axios.get(`/friends?offset=${offSet}&limit=25`);
      if (
        response &&
        response.data &&
        response.data.list &&
        response.data.list.length > 0
      ) {
        commit("setUserFriends", { data: response.data, offSet });
      }
    } catch (error) {
      return false;
    }
  },

  async getRequests({ commit }, offSet = 0) {
    try {
      const response = await axios.get(`/friends/requests?offset=${offSet}`);
      if (response.data) {
        commit("setUserRequests", { data: response.data, offSet: offSet });
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async sendFriendRequest(store, userId) {
    try {
      await axios.post(`/friends/request`, {
        userId: userId,
      });
      return true;
    } catch (error) {
      console.log(error);
      if (error.response.status === 423) {
        toast.instance.show({
          text: i18n.t("friendsRequestWindow.requestSent"),
          duration: 3000,
          variant: "danger",
        });
      } else if (error.response.status === 403) {
        toast.instance.show({
          text: "You are already friends with each other",
          duration: 3000,
          variant: "danger",
        });
        return false;
      } else if (error.response.status === 406) {
        toast.instance.show({
          text: i18n.t("errors.youAreBlocked"),
          duration: 3000,
          variant: "danger",
        });
      }
      return false;
    }
  },
  async respondFriendRequest({ commit }, { requestId, accept, senderId }) {
    try {
      const response = await axios.post(`/friends/respond`, {
        requestId: requestId,
        accepted: accept ? 1 : 0,
      });
      if (response && response.status === 204) {
        commit("friendRequestRemoveItem", senderId);
      } else {
        toast.instance.show({
          text: i18n.t("errors.errorOccured"),
          duration: 3000,
          variant: "danger",
        });
      }
      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async removeFriend(store, userId) {
    try {
      await axios.post(`/friends/remove`, {
        userId: userId,
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  async getProfiles(store, userIds) {
    try {
      const response = await axios.post(`/user/profiles`, {
        players: userIds,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};
