import _ from "lodash";
import Logger from "@/logger";
import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";
import toast from "@/plugins/toast";
import i18n from "@/plugins/i18n";
import AnalyticsPlugin from "@/plugins/analytics";
import AnalyticsEvents from "@/plugins/analytics/events";
import AudioPlugin from "@/plugins/audio";
import store from "@/store";
import AppConstant from "@/constants/app";
import adjust from "@/plugins/adjust";

const getCWSEventName = (constant) => `CWS_${constant}`;
import CWSService from "@/services/websocket/communication";
import CWSEvents from "@/constants/communicationSocketEvents";

import axios from "@/services/api/axios";

import ColyseusService from "@/services/colyseus";
import ColyseusEvents from "@/constants/colyseusEvents";
import ColyseusRequests from "@/constants/colyseusRequests";

import PokerRoomConstants from "@/constants/game/poker/room";
import PokerRoomCardConstants from "@/constants/game/poker/card";
import PokerRoomPlayerConstants from "@/constants/game/poker/player";
import PokerRoomProgressiveJackpotConstants from "@/constants/game/poker/progressiveJackpot";

import BlackjackRoomConstants from "@/constants/game/blackjack/room";
import BlackjackRoomPlayerConstants from "@/constants/game/blackjack/player";
import BlackjackRoomCardConstants from "@/constants/game/blackjack/card";

export default {
  async initCWS({ state, rootState, dispatch }) {
    return new Promise((resolve) => {
      state.communicationWebsocket = CWSService.init();

      state.communicationWebsocket.on("connect", () => {
        state.communicationWebsocket.emit("auth", {
          token: rootState.authentication.token.access,
        });
        EventBus.$emit(EventConstants.COMMUNICATION_WEBSOCKET_AUTHENTICATED);
        resolve(true);
      });

      Object.entries(CWSEvents).forEach((entry) => {
        state.communicationWebsocket.on(entry[1], (data) => {
          dispatch(`CWS_${entry[1]}`, data);
        });
      });
    });
  },

  async destroyCWS({ state }) {
    try {
      if (
        state.communicationWebsocket &&
        state.communicationWebsocket.connected
      ) {
        state.communicationWebsocket.disconnect();
      }

      if (state.colyseusConnection.ACTIVE_ROOM?.leave) {
        state.colyseusConnection.ACTIVE_ROOM.leave();
      }

      // const games = ["BLACKJACK", "POKER"];
      // games.forEach((item) => {
      //   console.log("item", item);
      //   console.log("state.colyseusClients[item]", state.colyseusClients[item]);
      //   if (state.colyseusClients && state.colyseusClients[item] !== null) {
      //     console.log("disconnect item", item);
      //     state.colyseusClients[item].disconnect();
      //   }
      // });

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  async initColyseusClient(
    { state, rootState, commit, dispatch },
    { type = "POKER" }
  ) {
    try {
      state.colyseusClients[type] = ColyseusService.createClient(type);
      EventBus.$emit(EventConstants.COLYSEUS_CLIENT_INITIALIZED, { type });

      if (type === "POKER") {
        await dispatch("connectToLobby");
      }
    } catch (error) {
      Logger.print("error", error);
      console.log("initColyseusClient modals/show disconnect");
      commit("modals/show", "disconnect", { root: true });
      // dispatch("user/userOffline", {}, { root: true });
    }
  },

  async connectToLobby({ state, rootState, commit, dispatch }) {
    await dispatch("getRoomListByServerId", { serverId: 13 });
    return new Promise(async (resolve, reject) => {
      try {
        const connection = await ColyseusService.connectToLobby(
          state.colyseusClients.POKER,
          rootState.authentication.token.access
        );

        commit("modals/hide", "disconnect", { root: true });
        state.isLobbyConnected = true;

        const pingIntervalId = setInterval(() => {
          connection.send("ping");
        }, 5000);

        connection.onError((code, message) => {
          clearInterval(pingIntervalId);
          state.isLobbyConnected = false;
          Logger.print("warn", `[COLYSEUS] ${code} - ${message}`);
          if (code === 4001) {
            console.log(
              "ColyseusService.connectToLobby.onError modals/show disconnect"
            );
            commit("modals/show", "disconnect", { root: true });
            // dispatch("user/userOffline", {}, { root: true });
          }
        });

        connection.onMessage("reconnect", (data) =>
          dispatch("joinPokerRoom", { roomId: data.roomId })
        );

        connection.onMessage(ColyseusEvents.ROOMS, (rooms) => {
          rooms.forEach((room) => {
            if (room.name === "blackjack") return;

            const currentRoomIndex = state.roomList.findIndex(
              (r) => Number(r.id) === Number(room.metadata.id)
            );
            if (currentRoomIndex > -1) {
              this._vm.$set(state.roomList[currentRoomIndex], "metadata", {
                ...state.roomList[currentRoomIndex].metadata,
                ...room.metadata,
              });
              this._vm.$set(state.roomList[currentRoomIndex], "serverInfo", {
                players: [...room.metadata.players],
              });
            }
          });
        });

        connection.onMessage(ColyseusEvents.ROOM_PLUS, ([roomId, room]) => {
          const currentRoomIndex = state.roomList.findIndex(
            (r) => Number(r.id) === Number(room.metadata.id)
          );
          if (currentRoomIndex > -1) {
            this._vm.$set(state.roomList[currentRoomIndex], "metadata", {
              ...state.roomList[currentRoomIndex].metadata,
              ...room.metadata,
            });
            this._vm.$set(state.roomList[currentRoomIndex], "serverInfo", {
              players: [...room.metadata.players],
            });
          } else {
            dispatch("getRoomListByServerId", { serverId: 13 });
          }
        });
        connection.onMessage(ColyseusEvents.ROOM_MINUS, (roomId) => {
          const currentRoomIndex = state.roomList.findIndex(
            (r) => Number(r.id) === Number(roomId)
          );
          if (currentRoomIndex > -1) {
            this._vm.$set(state.roomList[currentRoomIndex], "serverInfo", {
              players: [],
            });
            if (state.roomList[currentRoomIndex].metadata?.special === true)
              state.roomList.splice(currentRoomIndex, 1);
          }
        });

        connection.onMessage(ColyseusEvents.SET_USER_DATA, (userData) =>
          commit("user/setUserData", userData, { root: true })
        );

        connection.onMessage(ColyseusEvents.PONG, () => {});

        connection.onLeave(() => {
          Logger.print("warn", "disconnected from the lobby");
          clearInterval(pingIntervalId);
          state.isLobbyConnected = false;
          if (state.ACTIVE_ROOM?.id < 1) {
            console.log("state.ACTIVE_ROOM?.id < 1 modals/show disconnect");
            commit("modals/show", "disconnect", { root: true });
            // dispatch("user/userOffline", {}, { root: true });
            if (window.__notifyOnlineInterval)
              clearInterval(window.__notifyOnlineInterval);
          }
        });

        this._vm.$set(state.colyseusConnection, "LOBBY", connection);

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  },

  async getRoomListByServerId({ state }, { serverId = 13, limit = 1000 }) {
    try {
      const response = await axios.get(
        `/game-server/${serverId}/rooms?limit=${limit}`
      );

      state.roomList.splice(0, state.roomList.length);

      if (response.data.docs.length <= 0) return [];

      response.data.docs.forEach((room) => {
        state.roomList.push({
          ...room,
          serverInfo: {
            players: [],
          },
        });
      });

      return response.data.docs;
    } catch (error) {
      console.log("error while fetching room list", error);
    }
  },

  async createPokerRoom(store, payload) {
    try {
      const response = await axios.post("/user/special/create", payload);

      if (response.status !== 200) {
        return false;
      }

      if (typeof response.data.roomId === "undefined") return false;

      return response.data;
    } catch (err) {
      if (err.response.status === 403)
        toast.instance.show({
          text: i18n.t("caseWindow.onlyVip"),
          variant: "danger",
        });
      if (err.response.status === 402)
        toast.instance.show({
          text: i18n.t("errors.insufficientBalance"),
          variant: "danger",
        });
      return false;
    }
  },

  async joinToBlackjackRoom({ state, rootState, rootGetters, dispatch }) {
    if (rootGetters["user/getLevel"](rootState.user.xp).number < 5) {
      toast.instance.show({
        text: i18n.t("errors.notEnoughLevelPlayGame", {
          game: "Blackjack",
          level: "5",
        }),
        variant: "danger",
      });
      return;
    }

    if (state.colyseusClients.BLACKJACK === null)
      await dispatch("initColyseusClient", {
        type: "BLACKJACK",
      });

    rootState.scene = "Loading";
    state.isBlackjackLoading = true;

    if (state.colyseusConnection.ACTIVE_ROOM?.leave)
      state.colyseusConnection.ACTIVE_ROOM.leave();

    state.colyseusConnection.ACTIVE_ROOM =
      await state.colyseusClients.BLACKJACK.joinById("3013", {
        accessToken: rootState.authentication.token.access,
      });

    try {
      state.ACTIVE_ROOM = BlackjackRoomConstants;
      dispatch("onJoinedToBlackjackRoom", { roomId: "3013" });
    } catch (err) {
      console.log("rootState.scene = Lobby;");
      rootState.scene = "Lobby";
      console.error(err);
    }

    dispatch(
      "user/updateGameUserInfo",
      {
        game: AppConstant.gameType.BLACKJACK,
        room: 3013,
      },
      { root: true }
    );
  },

  async contactUsPost(state, data) {
    const contactUsResponse = await axios.post("report/add", data);
  },

  onJoinedToBlackjackRoom({ state, commit }, { roomId }) {
    const parseRoomData = (roomState) => {
      const data = _.pick(roomState, Object.keys(BlackjackRoomConstants));
      for (const key in data) {
        let value = roomState[key];
        if (key === "deck") {
          value = Array.from(value.cards).map((card) => {
            if (typeof card.index === "undefined") {
              card.index = -2;
              card.suit = "";
              card.value = "";
            }
            return _.pick(
              card,
              Object.keys({
                ...BlackjackRoomCardConstants,
                cutter: false,
              })
            );
          });
        }
        if (key === "players") {
          value = Array.from(value);
          const players = [];
          value.forEach((p) => {
            let _player = p[1];
            const cards = Array.from(_player.cards).map((card) => {
              if (typeof card.index === "undefined") {
                card.index = -1;
                card.suit = "";
                card.value = "";
              }
              return _.pick(card, Object.keys(BlackjackRoomCardConstants));
            });
            const splitCards = Array.from(_player.split.cards).map((card) => {
              if (typeof card.index === "undefined") {
                card.index = -1;
                card.suit = "";
                card.value = "";
              }
              return _.pick(card, Object.keys(BlackjackRoomCardConstants));
            });
            const split = _.pick(
              {
                ..._player.split,
                cards: splitCards,
              },
              Object.keys(BlackjackRoomPlayerConstants.split)
            );
            const playerBets = _.pick(
              _player.bet,
              Object.keys(BlackjackRoomPlayerConstants.bet)
            );
            const playerWonWithTypes = _.pick(
              _player.wonWithTypes,
              Object.keys(BlackjackRoomPlayerConstants.wonWithTypes)
            );
            players.push(
              _.pick(
                {
                  ..._player,
                  split,
                  cards,
                  bet: playerBets,
                  wonWithTypes: playerWonWithTypes,
                },
                Object.keys(BlackjackRoomPlayerConstants)
              )
            );
          });
          value = players;
        }
        if (key === "dealer") {
          const cards = Array.from(value.cards).map((card) => {
            if (typeof card.index === "undefined") {
              card.index = -2;
              card.suit = "";
              card.value = "";
            }
            return _.pick(card, Object.keys(BlackjackRoomCardConstants));
          });
          value = _.pick(
            {
              ...value,
              cards,
            },
            Object.keys(BlackjackRoomConstants.dealer)
          );
        }
        if (key === "winners") {
          const winners = [];
          Array.from(value).forEach((winner) => {
            winners.push({
              playerId: winner.playerId,
              amount: winner.amount,
            });
          });
          value = winners;
        }
        this._vm.$set(state.ACTIVE_ROOM, key, value);
      }
    };

    this._vm.$set(state, "ACTIVE_ROOM", BlackjackRoomConstants);
    state.communicationWebsocket.emit("join-room", { roomId });

    state.colyseusConnection.ACTIVE_ROOM.onStateChange.once((roomState) =>
      parseRoomData(roomState)
    );
    state.colyseusConnection.ACTIVE_ROOM.onStateChange((roomState) =>
      parseRoomData(roomState)
    );

    state.colyseusConnection.ACTIVE_ROOM.onMessage("deckShuffle", () =>
      EventBus.$emit("onShuffleRequested")
    );
    state.colyseusConnection.ACTIVE_ROOM.onMessage("cutterDrawn", () =>
      EventBus.$emit("onCutterDrawn")
    );

    state.colyseusConnection.ACTIVE_ROOM.onLeave(() => {
      state.colyseusConnection.ACTIVE_ROOM?.removeAllListeners();
      state.colyseusConnection.ACTIVE_ROOM = null;
      this._vm.$set(state, "ACTIVE_ROOM", null);
      state.communicationWebsocket.emit("leave-room", { roomId });
      console.log("changeScene lobbyy");
      commit("changeScene", "Lobby", { root: true });
      state.colyseusConnection.LOBBY.send("updateUserData");
      AudioPlugin.instance.play("ToLobby");
    });

    commit("changeScene", "Blackjack", { root: true });
    // setTimeout(() => {
    state.isBlackjackLoading = false;
    // }, 2000);
  },

  async joinPokerRoom(
    { state, rootState, dispatch },
    { roomId, directedGame = false, reconnecting = false }
  ) {
    return new Promise(async (resolve, reject) => {
      if (state.colyseusConnection.ACTIVE_ROOM?.leave) {
        state.colyseusConnection.ACTIVE_ROOM.leave();
      }

      if (!reconnecting) {
        rootState.scene = "Loading";
      }

      try {
        const colyseusJOR = await state.colyseusClients.POKER.joinOrCreate(
          roomId,
          {
            accessToken: rootState.authentication.token.access,
          }
        );
        state.colyseusConnection.ACTIVE_ROOM = colyseusJOR;

        dispatch("onJoinedToPokerRoom", { roomId });

        let gameType;
        if (!directedGame) {
          gameType =
            state.lastOpenedTableList === "TexasHoldem"
              ? AppConstant.gameType.TEXAS
              : AppConstant.gameType.OMAHA;
        } else {
          gameType = directedGame;
        }
        dispatch(
          "user/updateGameUserInfo",
          {
            game: gameType,
            room: roomId,
          },
          { root: true }
        );
        state.lastTableId = roomId;
        return resolve(true);
      } catch (err) {
        if (err?.code === 402 || err?.code === 403) {
          toast.instance.show({
            text: i18n.t("errors.insufficientBalance"),
            duration: 3000,
            variant: "danger",
          });
          const roomData = rootState.service.roomList.find(
            (room) => String(room.id) === String(roomId)
          );
          if (typeof roomData !== "undefined") {
            if (roomData.metadata.gameType === "texas_holdem")
              rootState.scene = "TexasHoldemTableList";
            else rootState.scene = "OmahaTableList";
          }
          return reject(false);
        }
        console.log("rootState.scene = lobbyyyy");
        rootState.scene = "Lobby";
        return reject(false);
      }
    });
  },

  onJoinedToPokerRoom(
    { state, rootState, dispatch, commit, getters },
    { roomId }
  ) {
    this._vm.$set(state, "ACTIVE_ROOM", PokerRoomConstants);

    dispatch("jackpots/fetch", { serverId: 13, roomId }, { root: true });

    state.communicationWebsocket.emit("join-room", { roomId });

    state.colyseusConnection.ACTIVE_ROOM.onMessage("roomClosing", () =>
      toast.instance.show({
        text: i18n.t("systemMessages.roomClosing"),
        duration: 5000,
      })
    );

    state.colyseusConnection.ACTIVE_ROOM.onMessage("pong", () => {});

    state.colyseusConnection.ACTIVE_ROOM.onMessage(
      "progressiveJackpotWinners",
      (data) => {
        if (!Array.isArray(data) || data.length === 0) return;

        EventBus.$emit("onProgressiveJackpotExplosion", data[0]);
      }
    );

    state.colyseusConnection.ACTIVE_ROOM.onMessage(
      "openSitWindow",
      ({ position }) => {
        state.colyseusConnection.LOBBY.send(ColyseusRequests.UPDATE_USER_DATA);

        if (rootState.user.chip < state.ACTIVE_ROOM.minBuy) {
          toast.instance.show({
            text: i18n.t("errors.insufficientBalance"),
            variant: "danger",
          });
          state.colyseusConnection.ACTIVE_ROOM.send("leave");
          return;
        }

        EventBus.$emit("onSitWindowRequested", {
          position,
          onCompleted: (amount, selectedOption) => {
            state.colyseusConnection.ACTIVE_ROOM.send("sit", {
              amount,
              position,
              autoPurchase: selectedOption === 0 ? 1 : 0,
              autoCharging: selectedOption === 1 ? 1 : 0,
            });
          },
        });
        state.tableSitRequest = true;
      }
    );
    state.colyseusConnection.ACTIVE_ROOM.onMessage("tip", (data) =>
      EventBus.$emit("onTipCame", data)
    );

    const parseRemoteState = (roomState) => {
      const data = _.pick(roomState, Object.keys(PokerRoomConstants));
      for (const key in data) {
        let value = roomState[key];
        if (key === "players") {
          value = Array.from(value.values());
          const players = [];
          value.forEach((p) => {
            const cards = Array.from(p.cards.values()).map((card) => {
              if (typeof card.index === "undefined") {
                card.index = -1;
                card.suit = "";
                card.value = "";
              }
              return _.pick(card, Object.keys(PokerRoomCardConstants));
            });
            players.push(
              _.pick(
                {
                  ...p,
                  cards,
                },
                Object.keys(PokerRoomPlayerConstants)
              )
            );
          });
          value = players;
        }
        if (key === "pots") {
          value = Array.from(value.values());
          const pots = [];
          value.forEach((pot) => {
            pots.push({
              amount: pot.amount,
              eligiblePlayers: Array.from(pot.eligiblePlayers),
              winners: Array.from(pot.winners),
            });
          });
          value = pots;
        }
        if (key === "cards") {
          value = Array.from(value.values()).map((card) => {
            if (typeof card.index === "undefined") {
              card.index = -1;
              card.suit = "";
              card.value = "";
            }
            return _.pick(card, Object.keys(PokerRoomCardConstants));
          });
        }
        if (key === "winners") {
          const winners = [];
          Array.from(value.values()).forEach((winner) => {
            winners.push({
              playerId: winner.playerId,
              playerName: winner.playerName,
              handName: winner.handName,
              amount: winner.amount,
              cards: Array.from(winner.cards),
            });
          });
          value = winners;
        }
        if (key === "progressiveJackpot") {
          value = _.pick(
            value,
            Object.keys(PokerRoomProgressiveJackpotConstants)
          );
        }

        this._vm.$set(state.ACTIVE_ROOM, key, value);
      }
    };

    state.colyseusConnection.ACTIVE_ROOM.onStateChange.once((roomState) => {
      if (!!roomState.teamTable) {
        toast.instance.show({
          text: i18n.t("systemMessages.teamTableAlert"),
          duration: 5000,
        });
      }

      rootState.scene = "PokerTable";
      parseRemoteState(roomState);
    });

    state.colyseusConnection.ACTIVE_ROOM.onStateChange((roomState) => {
      parseRemoteState(roomState);
    });

    const pingIntervalId = setInterval(() => {
      state.colyseusConnection.ACTIVE_ROOM.send("ping");
    }, 5000);

    window._test1006or1001code = (code) => {
      console.log("colyseusConnection onLeave code:", code);
      clearInterval(pingIntervalId);

      // reconnect block start
      if ([1001, 1006].includes(code) && state.reConnectCount < 3) {
        //rootState.scene = "Loading";
        state.colyseusConnection.ACTIVE_ROOM?.removeAllListeners();
        state.reConnectCount++;
        dispatch("joinPokerRoom", { roomId, reconnecting: true });
        return;
      }
      // reconnect block end

      if ([1001, 1006].includes(code)) {
        commit("modals/show", "disconnect", { root: true });
        // dispatch("user/userOffline", {}, { root: true });
      } else if (code === 1005) {
      } else if (code === 4001) {
        commit("modals/show", "disconnectInactivity", { root: true });
        // dispatch("user/userOffline", {}, { root: true });
      } else if (code === 4002) {
        commit("modals/show", "banned", { root: true });
      }

      console.log("lobbyyyy");
      rootState.scene = "Lobby";

      state.communicationWebsocket.emit("leave-room", { roomId });
      state.colyseusConnection.ACTIVE_ROOM?.removeAllListeners();
      state.colyseusConnection.ACTIVE_ROOM = null;
      this._vm.$set(state, "ACTIVE_ROOM", null);
      commit("setActiveRoomWinnerCards", []);
      AudioPlugin.instance.play("ToLobby");
    };

    state.colyseusConnection.ACTIVE_ROOM.onLeave((code) => {
      console.log("colyseusConnection onLeave code:", code);
      clearInterval(pingIntervalId);

      // reconnect block start
      if ([1001, 1006].includes(code) && state.reConnectCount < 4) {
        //rootState.scene = "Loading";
        state.colyseusConnection.ACTIVE_ROOM?.removeAllListeners();
        state.reConnectCount++;
        dispatch("joinPokerRoom", { roomId, reconnecting: true });
        return;
      }
      // reconnect block end

      if ([1001, 1006].includes(code)) {
        commit("modals/show", "disconnect", { root: true });
        // dispatch("user/userOffline", {}, { root: true });
      } else if (code === 1005) {
      } else if (code === 4001) {
        commit("modals/show", "disconnectInactivity", { root: true });
        // dispatch("user/userOffline", {}, { root: true });
      } else if (code === 4002) {
        commit("modals/show", "banned", { root: true });
      }

      console.log("lobbyyy");
      rootState.scene = "Lobby";

      state.communicationWebsocket.emit("leave-room", { roomId });
      state.colyseusConnection.ACTIVE_ROOM?.removeAllListeners();
      state.colyseusConnection.ACTIVE_ROOM = null;
      this._vm.$set(state, "ACTIVE_ROOM", null);
      commit("setActiveRoomWinnerCards", []);
      AudioPlugin.instance.play("ToLobby");
    });

    state.colyseusConnection.ACTIVE_ROOM.onError((code, message) => {
      clearInterval(pingIntervalId);
      if (code === 402) {
        toast.instance.show({
          text: i18n.t("errors.insufficientBalance"),
          duration: 3000,
          variant: "danger",
        });
      }
      console.log(
        "state.colyseusConnection.ACTIVE_ROOM.onError",
        code,
        message
      );
      commit("modals/show", "disconnect", { root: true });
      // dispatch("user/userOffline", {}, { root: true });
    });
  },

  async requestUserDataFromLobby({ state }) {
    if (!state.colyseusConnection.LOBBY) return false;
    return await state.colyseusConnection.LOBBY.send(
      ColyseusRequests.UPDATE_USER_DATA
    );
  },

  // CWS ACTIONS
  // ************

  // CONFIGURATION CHANGED
  async [getCWSEventName(CWSEvents.CONFIGURATION_CHANGED)](
    { rootState },
    data
  ) {
    if (Object.values(data).length === 0) return false;

    for (const configKey in data) {
      this._vm.$set(rootState.config, configKey, data[configKey]);
    }
  },

  // AD BONUS GAINED
  async [getCWSEventName(CWSEvents.AD_BONUS_GAINED)](
    { rootState, dispatch, commit },
    { amount, lastClaimAt }
  ) {
    if (isNaN(amount) || !lastClaimAt || amount <= 0) return false;

    commit(
      "user/setUserData",
      {
        chip: rootState.user.chip,
      },
      { root: true }
    );

    commit(
      "bonus/setAdBonusProperties",
      {
        ready: false,
        lastPrize: Number(amount),
        lastClaimAt: Number(lastClaimAt),
      },
      { root: true }
    );

    // EventBus.$emit(EventConstants.AD_BONUS_CLAIMED, { amount });
  },

  // USER DATA UPDATED
  async [getCWSEventName(CWSEvents.USER_DATA_UPDATED)]({ commit }, data) {
    commit("user/setUserData", data, { root: true });
  },
  // GLOBAL MESSAGE
  async [getCWSEventName(CWSEvents.CHAT_MSG_SENT_GLOBAL)]({ commit }, data) {
    commit("chat/setGlobalMessage", data, { root: true });
  },
  // USER MESSAGE
  async [getCWSEventName(CWSEvents.CHAT_MSG_SENT_USER)](
    { rootState, dispatch },
    data
  ) {
    if (!rootState.modals.chat) {
      toast.instance.show({
        text: `${data.sender.name} ${i18n.t("message.sentMessage")}`,
        duration: 3000,
        variant: "success",
      });
      dispatch("chat/getUserConversations", null, { root: true });
    } else {
      if (
        rootState.chat.selectedChat &&
        rootState.chat.selectedChat.user.id === data.sender.id
      ) {
        dispatch(
          "chat/getUserConversation",
          { conversationId: rootState.chat.selectedChat.id, offSet: 0 },
          { root: true }
        );
      } else {
        dispatch("chat/getUserConversations", null, { root: true });
      }
    }
  },
  // FRIEND REQUEST SENT
  async [getCWSEventName(CWSEvents.FRIEND_REQUEST_SENT)](store, data) {
    toast.instance.show({
      text: i18n.t("friendsRequestWindow.successRequest"),
      duration: 3000,
      variant: "success",
    });
  },
  // FRIEND REQUEST RECEIVED
  async [getCWSEventName(CWSEvents.FRIEND_REQUEST)]({ commit }, data) {
    if (!store.state.user.invitation) {
      return;
    }
    commit("friends/setReceivedFriendRequest", data, { root: true });

    commit("modals/show", "friendRequest", { root: true });
  },
  // FRIEND REQUEST ACCEPTED
  async [getCWSEventName(CWSEvents.FRIEND_REQUEST_ACCEPTED)](
    { dispatch },
    data
  ) {
    toast.instance.show({
      text: `${data.user.name} ${i18n.t("friendsRequestWindow.acceptRequest")}`,
      duration: 3000,
      variant: "success",
    });

    dispatch("friends/getFriends", 0, { root: true });
  },
  // FRIEND REQUEST REJECTED
  async [getCWSEventName(CWSEvents.FRIEND_REQUEST_REJECTED)](store, data) {
    toast.instance.show({
      text: `${data.user.name} ${i18n.t(
        "friendsRequestWindow.rejectedRequest"
      )}`,
      duration: 3000,
      variant: "danger",
    });
  },
  // CHIP RECEIVE
  async [getCWSEventName(CWSEvents.CHIP_TRANSFER_RECEIVED)](store, data) {
    toast.instance.show({
      text: `${data.user.name} ${i18n.t("chipHistoryDetail.sentYou")} ${
        data.amount
      } ${i18n.t("chipHistoryDetail.chips")}.`,
      duration: 3000,
      variant: "success",
    });

    AudioPlugin.instance.play("friend_chip_send");
  },
  // PLAYER EMOTE
  async [getCWSEventName(CWSEvents.EMOTE)](store, data) {
    EventBus.$emit(EventConstants.EMOTE, data);
  },
  // CHAT MSG RECEIVED
  async [getCWSEventName(CWSEvents.CHAT_MSG_SENT_ROOM)](store, data) {
    EventBus.$emit(EventConstants.CHAT_MSG_SENT_ROOM, data);
  },
  // GIFT
  async [getCWSEventName(CWSEvents.GIFT_SENT)](store, data) {
    EventBus.$emit(EventConstants.GIFT_SENT, data);
  },
  async [getCWSEventName(CWSEvents.ANIMATION_SENT)](
    { commit, rootState },
    data
  ) {
    if (data && data.id.includes("gift")) {
      commit("gift/newGift", data, { root: true });
      return;
    }

    const animatedGiftValue = rootState.preferences.list.filter(
      (item) => item.key === "animated_gifts"
    );

    if (animatedGiftValue.length > 0 && animatedGiftValue[0].value == "0") {
      return;
    }

    commit("gift/newAnimation", data, { root: true });
    // EventBus.$emit(EventConstants.ANIMATION_SENT, data);
  },
  // JACKPOT EXPLOSION
  async [getCWSEventName(CWSEvents.JACKPOT_EXPLOSION)](store, data) {
    EventBus.$emit(EventConstants.JACKPOT_EXPLOSION, data);
  },
  // JACKPOT AMOUNT CHANGED
  async [getCWSEventName(CWSEvents.JACKPOT_AMOUNT_CHANGED)](
    { rootState },
    data
  ) {
    const jackpotIndex = rootState.jackpots.list.findIndex(
      (j) => j.id === data.id
    );
    if (jackpotIndex < 0) return false;

    rootState.jackpots.list[jackpotIndex].amount = data.amount;
  },
  // PROGRESSIVE JACKPOT AMOUNT CHANGED
  async [getCWSEventName(CWSEvents.PROGRESSIVE_JACKPOT_AMOUNT_CHANGED)](
    { rootState },
    data
  ) {
    const jackpotIndex = rootState.jackpots.list.findIndex(
      (j) => j.id === data.id
    );
    if (jackpotIndex < 0) return false;

    rootState.jackpots.list[jackpotIndex].amount = data.amount;
  },
  // USER LEVEL UP
  async [getCWSEventName(CWSEvents.USER_LEVEL_UP)](
    { rootGetters },
    { level, xp, prize }
  ) {
    toast.instance.show({
      text: i18n
        .t("level.levelUpToastText")
        .replace("{levelNumber}", level)
        .replace("{reward}", rootGetters.getShortChipFormat(prize)),
      duration: 4500,
    });
  },
  // USER LEVEL UP
  async [getCWSEventName(CWSEvents.INVITE_FRIENDS_REWARDS)](
    { rootGetters },
    { level, xp, prize }
  ) {
    toast.instance.show({
      text: i18n.t("inviteFriend.inviteFriendText"),
      duration: 4500,
    });
  },
  //
  async [getCWSEventName(CWSEvents.SLOT_BIG_WINNER)](
    { rootGetters, state },
    { user, amount }
  ) {
    state.slotBigWinners.push({
      name: user.name,
      amount,
      date: Math.round(Date.now() / 1000),
    });

    // toast.instance.show({
    //   text: i18n
    //     .t("slotWinnerToast.bigWinText")
    //     .replace("{amount}", rootGetters.getShortChipFormat(amount))
    //     .replace("{username}", user.name),
    //   duration: 4500,
    // });
  },
  async [getCWSEventName(CWSEvents.FRIEND_ONLINE)](
    { commit, rootState },
    data
  ) {
    const index = rootState.friends.friendsList.list.findIndex(
      (f) => f.user.id === data.userId
    );
    if (index < 0) return;
    commit("friends/setFriendIndex", { index, data }, { root: true });
  },
  async [getCWSEventName(CWSEvents.FRIEND_OFFLINE)](
    { commit, rootState },
    data
  ) {
    const index = rootState.friends.friendsList.list.findIndex(
      (f) => f.user.id === data.userId
    );
    if (index < 0) return;
    commit("friends/setFriendIndex", { index, data }, { root: true });
  },
  async [getCWSEventName(CWSEvents.INVITE_FRIEND_ROOM)](
    { rootGetters, commit },
    data
  ) {
    if (!store.state.user.invitation) {
      return;
    }
    commit("friends/setInviteRequest", data, { root: true });
  },

  async [getCWSEventName(CWSEvents.PLAYER_PRODUCT_PURCHASE_SUCCESSFUL)](
    { rootGetters, commit },
    { sku, chip, price }
  ) {
    console.log("PLAYER_PRODUCT_PURCHASE_SUCCESSFUL", { sku, chip, price });
    if (!(price > 0)) {
      return;
    }
    if (chip === 30000000000) {
      adjust.trackEvent("ClickBuyChip30B", price);
    } else if (chip === 75000000000) {
      adjust.trackEvent("ClickBuyChip75B", price);
    } else if (chip === 250000000000) {
      adjust.trackEvent("ClickBuyChip250B", price);
    } else if (chip === 500000000000) {
      adjust.trackEvent("ClickBuyChip500B", price);
    } else if (chip === 1000000000000) {
      adjust.trackEvent("ClickBuyChip1T", price);
    } else if (chip === 5000000000000) {
      adjust.trackEvent("ClickBuyChip5T", price);
    }
  },
  // ************
  // CWS ACTIONS END
};
