<template>
  <div
    class="emote-container flex-center"
    :class="[`seat-index-${$parent.$props.index}`, `emote-${$data.type}`]"
    v-if="$data.num > -1"
    @click="$emit('emoteClick')"
  >
    <div
      class="emote-inner"
      :class="`emote-${$data.type} emote-${$data.type}-${$data.num}`"
    >
      <SpritesheetAnimation
        class="general-emote"
        v-if="$data.num > -1 && type"
        :id="`emote-${$data.type}-${$data.num}`"
        :spritesheet="require(`@/assets/images/emotes/${type}/${num}.png`)"
        :json="require(`@/assets/images/emotes/${type}/${num}.json`)"
        :yoyo="false"
        :fps="14"
        :required-height="300"
        :autoplay="true"
      />
      <!-- <div :class="[`emote-${$data.type}-sprite`, `emote-${$data.num}`]"></div> -->
    </div>
  </div>
</template>

<script>
import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";

export default {
  name: "Emote",
  components: {
    SpritesheetAnimation: () =>
      import("@/components/Utils/SpritesheetAnimation"),
  },
  data() {
    return {
      num: 1,
      type: "",
      timeout: null,
    };
  },
  methods: {
    onEmoteCame({ userId, id }) {
      if (
        this.$parent.data?.id !== userId ||
        this.$store.getters["preferences/getValue"]("animated_gifts") == 0
      ) {
        return false;
      }

      this.setDefaultState();
      this.clearCurrentTimeout();

      const split = String(id).split("-");

      this.$data.type = split[0];
      this.$data.num = split[1];

      this.$data.timeout = setTimeout(this.setDefaultState.bind(this), 2600);
    },
    clearCurrentTimeout() {
      if (this.$data.timeout) {
        clearTimeout(this.$data.timeout);
      }
    },
    setDefaultState() {
      this.$data.type = "";
      this.$data.num = -1;
    },
  },
  beforeDestroy() {
    EventBus.$off(EventConstants.EMOTE, this.onEmoteCame.bind(this));
  },
  created() {
    EventBus.$on(EventConstants.EMOTE, this.onEmoteCame.bind(this));
  },
};
</script>

<style lang="scss">
@keyframes emoteIncomingAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1.3);
  }
}

.emote-container {
  position: absolute;
  z-index: 5;
  animation-name: emoteIncomingAnimation;
  animation-timing-function: ease-in-out;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  width: 100%;
  height: 100%;
  // &.emote-emojis-4,
  // &.emote-emojis-10,
  // &.emote-emojis-15 {
  //   top: -6px !important;
  // }
  // &.emote-emojis-7 {
  //   top: 5px !important;
  // }
  // &.emote-emojis-8 {
  //   margin-left: -34px !important;
  // }
  // &.emote-emojis-9 {
  //   margin-left: -10px !important;
  // }
  .emote-inner {
    position: relative;
    .general-emote {
      position: relative;
      top: 10px;
    }
  }
  .emote-emojis-1 {
    top: 30px;
  }
  .emote-emojis-2 {
    top: 40px;
  }
  .emote-emojis-3 {
    top: 30px;
  }
  .emote-emojis-4 {
    top: 5px;
  }
  .emote-emojis-5 {
    top: 40px;
  }
  .emote-emojis-6 {
    top: 30px;
    left: 10px;
  }
  .emote-emojis-7 {
    top: 30px;
    left: 10px;
  }
  .emote-emojis-8 {
    top: 40px;
    left: -20px;
  }
  .emote-emojis-9 {
    top: -30px;
  }
  .emote-emojis-11 {
    top: 30px;
  }
  .emote-emojis-12 {
    top: 30px;
  }
  .emote-emojis-13 {
    top: 30px;
  }
  .emote-emojis-14 {
    top: 0px;
  }
  .emote-emojis-16 {
    top: 30px;
  }
  .emote-emojis-17 {
    top: 30px;
    left: 20px;
  }
  .emote-emojis-18 {
    top: 30px;
  }
  .emote-emojis-19 {
    top: 30px;
  }
  .emote-emojis-20 {
    top: 30px;
  }
  .emote-dogs {
    top: 30px;
  }
  .emote-cats {
    top: 30px;
  }
  .emote-cats-4 {
    top: 10px;
  }
  .emote-cats-7 {
    left: 15px;
  }
  .emote-cats-8 {
    right: 10px;
  }
  .emote-birds {
    top: 30px;
  }
  .emote-birds-8 {
    right: 10px;
  }
}

.seat-index-3,
.seat-index-4,
.seat-index-5,
.seat-index-6,
.seat-index-7 {
  .emote-container {
  }
}

.seat-me {
  .emote-container {
  }
}
.general-emote {
  position: relative;
}
</style>
