const MODE = process.env.NODE_ENV || "development";

const addLog = (type, batchMessage = []) => {
  if (typeof console[type] === "undefined") return false;

  if (MODE === "production") return false;

  console[type](...batchMessage);
};

const logger = {
  print(type = "log", message = "") {
    if (
      ![
        "error",
        "log",
        "dir",
        "trace",
        "warn",
        "debug",
        "info",
        "table",
        "assert",
      ].includes(type)
    )
      return false;

    addLog(type, [`[LOG:${String(type).toUpperCase()}]`, message]);
  },
};

export default logger;
