/*
 * English language file
 */

export default {
  loading: "Carregando...",

  general: {
    no: "Não",
    yes: "Sim",
    avatar: "Avatar",
    tableInformation: "Table Information",
    vipIcon: "Ícone Vip",
    cancel: "Cancelar",
    save: "Guardar",
    logout: "Terminar sessão",
    play: "Reproduzir",
    chat: "Chat",
    leaders: "Líderes",
    social: "Social",
    case: "Caso",
    shop: "Loja",
    okay: "Okay",
    refresh: "Refrescar",
    send: "Enviar",
    username: "Nome de utilizador",
    delete: "Eliminar",
    reject: "Rejeitar",
    accept: "Aceitar",
    confirm: "Confirmar",
    registrationDate: "Data de Registo",
    name: "Nome",
    total: "Total",
    copy: "cópia de",
    commission: "Commission",
    getGift: "Get Your Gift",
    transfer: "Transferir",
    menu: "Cardápio",
    update: "Atualizar",
    invite: "Convidar",
    joinNow: "Entrar",
    handHistory: "Hand History", //NEW***
  },
  giftsWindow: {
    gift: "Gift", //NEW***********
    getYourself: "For You", //NEW***********
    getGift: "Buy Gift", //NEW***********
    getEveryone: "For Everyone", //NEW***********
    expression: "Emoji", //NEW***********
    selectGift: "Please select a gift", //NEW***********
    noPlayersAtTable: "No players at the table", //NEW***********
    sitATable: "Please sit at the table first", //NEW***********
    selectUser: "Please select a user", //NEW***********
  },
  dailyEntry: {
    dailyEntry: "Daily Bonus", //NEW***
    dailyDesc: "Log in every day and receive more chips!", //NEW***
    day: "Day", //NEW***
  },
  noficationWindow: {
    notificationInviteTable:
      "{name} menjemput anda ke meja {gameType}{tableId} pada {date}", // NEW**
    notificationInviteChip: " {name} menghantar anda {amount} cip.", // NEW***
    sendMessage: " Taip mesej disini", // NEW***
    tableInvite: "Meja Jemputan", // NEW***
    system: "Sistem", // NEW***
    messages: "Mesej", // NEW***
    notifications: "Pemberitahuan", // NEW***
  },
  authentication: {
    signInToContinue: "Iniciar sessão para continuar",
    anErrorOccuredWhileLoggingIn:
      "Ocorreu um erro enquanto se iniciava sessão!",
    contact: "Contactar",
    privacyPolicy: "Política de Privacidade",
    provablyFair: " Provably Fair",
    sign_guest: "Play as Guest",
    sign_apple: "Login with Apple",
    sign_google: "Login with Google",
    sign_facebook: "Login with Facebook",
    loginBonusText:
      "<p class='login-info-text'> Get extra bonus chips! <span class='color-white'> {0} </span> or <span class='color-white'> {1} </span> </p>",
    loginBonusTextWeb:
      "<p class='login-info-text'> Get extra bonus chips! <span class='color-white'> {0} </span> </p>",
  },
  playerActions: {
    enoughChips: "Não tem fichas suficientes",
    minSendChipAmount: "The chip amount less than 10B cannot be sent",
    failedTransfer: "Falha ao transferir fichas",
    successTransfer: "As fichas são transferidas com sucesso.",
    validChip: "Please enter a valid amount of chips",
  },
  tableList: {
    table: "Tabela",
    tableId: "ID de Tabela",
    tableName: "Nome",
    blind: "Blind",
    minMax: "Min/Max",
    filterByBlinds: "Filtrar por Blinds",
    hideEmpties: "Esconder Vazios",
    hideFullyFilled: "Esconder Preenchidos",
    all: "Tudo",
    players: "Jogadores",
    full: "Completo",
    noRoomsMessage: "Não existem salas.",
    tableList: "Lista de Mesas",
    tableInfo: {
      emptyRoomMessage: "Sala vazia.",
    },
  },
  message: {
    message: "Mensagem",
    sendMessage: "Enviar mensagem",
    typeMessage: "Escreva a sua mensagem",
    sentMessage: "Enviou-lhe uma mensagem",
  },

  lobby: {
    playNow: "Jogue Agora",
    newSlotGame: "Novo jogo de caça-níqueis",
    availableNow: "Disponível agora",
    specialForYou: "Especialmente <br/> para você",
    play: "Jogar",
    favouriteGameButton: {
      instructionMessage: "Clicar duas vezes para mudar",
    },
  },

  bonusWindow: {
    congratulations: "Parabéns!",
    accept: "Aceitar",
  },

  maintenance: {
    gameMaintenance: "Jogo em manutenção",
    underMaintenance: "Sob Manutenção",
    tryAgainLAter:
      "Game is currently under maintenance. Please try again later!",
  },

  confirmWindow: {
    sure: "Tem a certeza?",
    userNotFound: "Utilizador não encontrado",
    notAffiliate: "Utilizador não é afiliado",
  },
  deleteConfirmWindow: {
    sure: "Você está prestes a excluir sua conta",
    content:
      "Se você continuar com este processo, suas informações de usuário e os produtos que você comprou serão excluídos",
    delete: "excluir",
    cancel: "Cancelar",
    deleteSuccessfully: "Exclusão da conta bem-sucedida",
    notBeDeleted: "Ocorreu um erro ao excluir",
    daysLimit:
      "If your account is not active during 30 days, it will be permanently deleted.",
    areYouSure: "Are you sure you want to delete your account?",
  },
  historyWindow: {
    hand: "Mão",
    preFlop: "Pre-Flop",
    flop: "Flop",
    turn: "Virar",
    river: "Rio",
    winner: "Vencedor",
    speacial: "Especial",
    team: "Equipa",
    anyHistory: "Não tem nenhum histórico para mostrar",
  },
  socialWindow: {
    gameCardDesc:
      "Você pode obter seu prêmio inserindo o código nos bilhetes que você ganhou.",
    sharecode: "Share Code", // NEW*******
    friends: "Amigos",
    searchFriend: "Search Friend",
    gameCard: "Game Card",
    requests: "Pedidos",
    chipTransferHistory: "Histórico de Transferência de Fichas",
    socialMedia: "Redes Sociais",
    socialMediaAndInviteCode: "Redes Sociais e Código de Convite",
    noFriendRequest: "Não Tem Nenhum Pedido de Amizade.",
    noFriends: "Não Tem Nenhum Amigo.",
    noChipTransfer: "Não Tem Nenhum Histórico de Transferência de Fichas.",
    extraChip: "Siga nossas contas de mídia social e ganhe fichas grátis.",
    followUs: "Siga-nos nas Redes Sociais!",
    inviteCode: "Código de Convite",
    removeFriend: "Remover dos amigos",
    block: "Block",
    unBlock: "Unblock",
  },
  friendDetailsWindow: {
    failed: "Falha ao enviar pedido de amizade",
  },
  chipHistoryDetail: {
    youSent: "Você enviou",
    chips: "Fichas",
    sentYou: "Enviou-lhe",
  },
  createTableWindow: {
    bets: "Apostas",
    createTable: "Criar Tabela",
    seatsNumber: "Número de Assento",
    potTable: "Mesa do Pote",
    teamTable: "Mesa da Equipa",
    tellerEntrance: "Entrada da Caixa",
  },
  chipTransferWindow: {
    sendChip: "Enviar Ficha",
    chipAmount: "Inserir Quantidade de Fichas",
    chipMessage: "Insira a sua mensagem aqui",
    warning: "Pode enviar qualquer quantidade de fichas! Não há limites!",
  },
  playerDetailsWindow: {
    profileNote: "Nota de Perfil",
    statistics: "Estatísticas",
    level: "Nível",
    recordChip: "Ficha Recorde",
    friendsCount: "Contagem de Amigos",
    bestHand: "Melhor Mão",
    copied: "Copiado!",
    failedChanges: "Falha ao guardar alterações",
    enterProfileNote: "Enter your profile note..", //NEW****
    gameStatistics: "Game Statistics", //NEW****
    sameFrameWarning:
      "Please choose a frame other than the one you are currently using.", //NEW****
    sameAvatarWarning:
      "Please choose an avatar other than the one you are currently using.", //NEW****
    fillFields: "Please fill out all the fields", //NEW****
    passwordsDoesNotMatch: "Your passwords don't match each other", //NEW****
    passwordLength:
      "Password length should be min of 6 and max of 30 characters",
  },
  shopWindow: {
    bronze: "Bronze",
    silver: "Silver",
    gold: "Gold",
    redeem: "Redimir",
    keysField: "Por favor insira as chaves do cartão do jogo nos campos.",
    withoutChip: "Entrar na Mesa sem Ficha",
    buyChip: "Comprar Ficha",
    redeemCode: "Redimir Código",
    noProduct: "Não foram encontrados produtos para exibir.",
    buyVip: "Comprar Vip",
    privateEmojis: "Emoji e Presentes Especiais VIP",
    privateProfile: "Perfil Vip Especial",
    connectionNeverLost: "Conexão Persistente",
    unLimitedView: "Tempo de Espectador Ilimitado",
    privateTable: "Implementar Mesa Especial",
    unlimitedChipTransfer: "Unlimited Chip Transfer",
    usingPrivateCase: "Usar a Caixa Especial para Guardar a Ficha",
    xp: "Presente 10.000XP",
    freeChat: "Conversa Gratuita",
    day: "Dia",
    daysLeft: "Dias Restantes",
    alreadyVip: "Você já tem uma subscrição VIP.",
    noChips: "Você não tem fichas suficientes para comprar a subscrição VIP",
    successfullyPurchased: "Você comprou uma subscrição VIP com sucesso.",
    chipAdded: "Chips received successfully",
  },
  caseWindow: {
    onlyVip: "Este recurso só está disponível para utilizadores VIP",
    buyVip: "Comprar VIP",
    chipInGame: "Ficha no Jogo",
    chipInCase: "Ficha em Caso",
    theretentation:
      "Isto trabalha a quantidade de fichas no jogo para a retenção de aporção.",
    transactionFailed: "Transaction failed",
    fromGame: "Do Jogo",
    fromCase: "Do Caso",
    amount: "Quantia",
    transaction: "Transação completada com sucesso.",
    enoughChip: "Você não tem fichas suficientes para depositar no cofre.",
    withdraw: "Você não tem fichas suficientes para retirar do cofre.",
    validAmount: "Por favor insira uma quantia válida",
    transfer: "Transfer",
  },
  friendsRequestWindow: {
    friendsOffer: "RECEBEU UM PEDIDO DE AMIZADE!",
    successRequest: "O pedido de amizade foi enviado com sucesso.",
    acceptRequest: "aceitou o seu pedido de amizade.",
    rejectedRequest: "rejeitou o seu pedido de amizade.",
    becomeFriends:
      "enviou-lhe um pedido de amizade! Quer ser amigo desta pessoa?",
    requestSent: "Pedido já enviado",
    friendRequestToYou: "sent you a friend request.",
  },
  settingsWindow: {
    language: "Idioma",
    gameSettings: "Configurações do Jogo",
    customize: "Personalizar",
    security: "Segurança",
    successfullySaved: "As mudanças foram guardadas com sucesso.",
    pinCode: {
      pinCode: "CÓDIGO PIN",
      createPinDesc: "Criar um Código PIN por motivos de segurança de conta",
      reEnterPin: "Voltar a inserir o Código PIN",
      emailAddress: "Endereço de Email",
      areYouSure: "Are you sure you want to remove your pin code?",
      emailRequired: "É obrigatório redefinir a palavra-passe",
      pinDesc:
        "A criação do Código PIN é muito importante para a segurança da sua conta. O Turn Games não irá aceitar nenhuma queixa caso a sua conta seja hackeada, a não ser que tenha um código PIN.",
    },
    customizeWindow: {
      tableStyle: "Estilo da Mesa",
      tableColors: "Cores da Mesa",
      roomBgColor: "Cor de Fundo da Sala",
    },
    gameSettingsWindow: {
      dontDistrub: "Não perturbar",
      blockNotification: "Notificações",
      showAnimatedGifts: "Mostrar Presentes Animados",
      newVersion: "New Version",
    },
  },
  reportWindow: {
    report: "Reportar",
    reportFilter: "Filtro Reportar",
    audiences: "Público",
    players: "Jogadores",
    successMessage: "O relatório foi enviado com sucesso.",
    failedMessage: "O relatório não foi enviado com sucesso.",
    swearing: "Swearing",
    team: "Equipa",
    scissors: "Tesouras",
    noPlayersFound: "Não existe outro jogador ou público.",
  },
  inboxContact: {
    contactUs: "Contacte-nos",
    title: "Título",
    textHere: "Texto Aqui",
    congratulations: "Parabéns",
    inbox: "Caixa de entrada",
    desc: "Ganhou 10,000 fichas pela última embalagem comprada.",
    failedMessage: "Falha ao enviar mensagem",
    successfullyMessage: "Mensagem enviada com sucesso",
    validEmail: "Por favor insira um email válido",
    fillTabs: "Por favor preencha todos os Separadores",
  },
  errors: {
    connectionLost: "Conexão Perdida",
    yourConnection: "A sua conexão foi perdida.",
    errorOccured: "Ocorreu um erro",
    gameServiceError:
      "Ocorreu um erro com o serviço do jogo. Por favor tente novamente mais tarde",
    slotDownloadError: "Ocorreu um erro enquanto descarregava o jogo.",
    loginError: "Ocorreu um erro enquanto iniciava sessão.",
    insufficientBalance: "Saldo insuficiente.",
    tooManyRequests: "Não pode publicar isso frequentemente.",
    haveToSitAtTable: "Tem de se sentar à mesa.",
    youAreBlocked: "You are blocked.",
    youAreBlockedTheUser: "You have blocked the user.",
    youAreUnblockedTheUser: "You have unblocked the user.",
    userNotActiveGame: "O usuário não está no jogo no momento",
    notEnoughLevelPlayGame:
      "{level} nível necessário para entrar na mesa {game}.",
    tableNotFound: "tableNotFound",
    playerIdWrong: "ID de referência está errado",
    searchedUserNotFound: "ID de referência não encontrado",
  },
  searchWindow: {
    searchUser: "Procurar Utilizador",
    idOfUser:
      "Por favor insira o ID do Utilizador que está a tentar encontrar.",
    search: "Procurar",
    ownId: "Este é o seu próprio ID",
    validId: "Por favor insira um ID válido",
  },
  adBonusWindow: {
    title: "GANHE FICHAS GRÁTIS!",
    content: "Assista a um vídeo curto e ganhe ${money} fichas!",
    watchButton: "Assista agora",
  },
  affiliate: {
    affiliateDesc: "Digite o número de ID de referência do seu amigo.",
    affiliateOffDesc: "Seu número de ID de referência",
    affiliate: "Afiliado",
    addAffiliate: "Adicionar um afiliado",
    affiliateId: "Inserir ID do Afiliado",
    clear: "Limpar",
    searchBy: "Procurar Por",
    name: "Nome",
    noData: "Sem Dados",
    startDate: "Data de Início",
    endDate: "Data Final",
    warning: "Torne-se nosso parceiro e ganhe um rendimento consistente!",
    info: "Saiba mais sobre como se juntar a nós.",
    failedAffiliate: "Falha ao adicionar afiliado",
    successfullyAdded: "ID do Afiliado adicionado com sucesso.",
    date_wrong: "A data de início deve ser anterior à data de término",
    affiliateIdTitle: "Affiliate Id",
    excelDownloaded: "Transferido para Documentos",
  },
  chat: {
    worldChat: "Conversa Mundial",
    directChat: "Conversa Direta",
    onlyVip: "Apenas um Utilizador VIP Pode Enviar Mensagens Globais",
    showMore: "Mostrar Mais",
    anyMessages: "Você não tem nenhumas mensagens.",
  },
  disconnectedWindow: {
    disconnected: "Desconectado",
    disconnectedServer: "Oops… Você foi desconectado do servidor!",
    reconnect: "Reconectar",
    inactive: "Oops…Ficou inativo durante demasiado tempo!",
    reconnected: "RECONECTAR",
  },

  rulesWindow: {
    texasDesc:
      "O Texas Hold'em Poker é o jogo de cartas mais popular no mundo. O objetivo do jogo consiste em ganhar com uma mão mais elevada que a dos oponentes. Existem 2 cartas na sua mão e 5 na mesa, a mão que fizer a melhor combinação de 5 cartas de um total de 7 cartas vence. Cada mão na lista abaixo derrota a seguinte mão. Por exemplo, a Sequência do mesmo Naipe bate a Quadra, e a Sequência bate os Pares. As mãos são valorizadas de acordo com os seus poderes.",
    omahaDesc:
      "O Omaha é um dos tipos mais populares de poker no mundo. O objetivo do jogo consiste em ganhar o pote. Existem 2 formas principais de ganhar fichas: ganhar uma mão poker que seja mais elevada do que a mão do oponente ou esperar pelo passe do oponente ou recusar o jogo. Uma mão contém 5 cartas. Existe uma regra que diz que as mãos devem conter três das cinco cartas da comunidade na mesa e duas das quatro cartas dadas ao jogador. Todas as mãos numa categoria na lista batem todas as mãos em categorias abaixo da mesma. Cada mão na lista abaixo vence a mão seguinte. Por exemplo, a Sequência do mesmo Naipe bate a Quadra, e a Sequência bate os Pares. As mãos são valorizadas de acordo com os seus poderes.",

    blackJackDesc:
      " O propósito do Turn Blackjack é conter uma mão com pontos mais elevados do que o croupier sem exceder 21. A melhor mão é chamada Blackjack – o valor total das primeiras duas cartas jogadas nesta mão é 21. Você joga só contra o croupier, não contra outro jogador. O Blackjack é jogado com oito baralhos. O croupier para sempre no 17. Pode ser duplicado em quaisquer duas cartas iniciais. As cartas iniciais do mesmo valor podem ser divididas em dois. A separação pode ser feita em cada mão. Uma carta é dada para cada Ás duplo. O duplicado não pode ser realizado após a separação das cartas. O seguro é recomendado quando o croupier tiver um Ás. Blackjack paga 3.2. Seguro paga 1:2. Quando as mãos são iguais, dá-se um empate.",

    handNames: {
      royalFlush: "Sequência Real",
      straightFlush: "Sequência do Mesmo Naipe",
      fourOfAKind: "Quadra",
      fullHouse: "Full House",
      flush: "Flush",
      straight: "Sequência",
      threeOfAKind: "Trio",
      twoPair: "Dois Pares",
      pair: "Um Par",
      highCard: "Carta Alta",
    },
    handDesc: {
      royalFlush: "A sequência real é a mão de poker mais elevada.",
      straightFlush:
        "Cinco cartas consecutivas do mesmo naipe. Entre 2 mãos da Sequência do Mesmo Naipe, o vencedor é aquele com a carta mais elevada.",
      fourOfAKind:
        "Quatro cartas do mesmo rank. A combinação mais forte é aquela com o valor mais elevado de cartas. Se os jogadores tiverem as mesmas mãos, o valor da quinta carta (kicker) determina o vencedor.",
      fullHouse:
        "Combinação tripla e de pares. Enquanto compara mãos Full House, o jogador com as melhores três cartas vence. Se forem as mesmas, o jogador com o melhor par vence.",
      flush:
        "Cinco cartas desordenadas do mesmo naipe. Se dois ou mais jogadores tiverem o mesmo naipe, o jogador com a carta mais elevada vence.",
      straight:
        "Cinco cartas consecutivas de dois ou mais naipes diferentes. Entre as duas mãos Straight, o vencedor é aquele que tem a carta com o valor mais elevado.",
      threeOfAKind:
        "Combinação de três cartas do mesmo rank. Dos dois trios, o vencedor é aquele com as cartas de valor mais elevado. Se os três tiverem o mesmo valor, o mais alto dos dois kickers restantes determina o vencedor.",
      twoPair:
        "Duas cartas do mesmo rank mais duas cartas de outro rank. Quando se compara duplos, o par mais elevado determina o vencedor. Se os pares altos tiverem o mesmo valor, os pares baixos são comparados. Se os pares mais baixos tiverem o mesmo valor, o jogador com a quinta carta de valor mais elevado (kicker) vence.",
      pair: "Duas cartas do mesmo rank. Se os jogadores tiverem o mesmo par, a mais elevada das três cartas restantes (kicker) determina o vencedor.",
      highCard:
        "Uma mão poker que não corresponde aos requisitos das mãos descritas antes. Se os jogadores tiverem a mesma carta alta, a segunda carta mais alta (etc.) determina o vencedor.",
    },
    expressions: {
      blinds: {
        heading: "Blinds",
        desc: "Antes de as cartas serem dadas, o jogador que se senta do lado esquerdo do distribuidor faz uma aposta chamada small blind. O próximo jogador que se senta à esquerda da small blind deve fazer a chamada big blind. A quantidade de big blinds é o dobro da quantidade da small blind.",
      },
      ante: {
        heading: "Ante",
        desc: "Se um jogador estiver a juntar-se ao jogo, estiver sentado à mesa e não na posição big blind, o jogador faz uma aposta forçada chamada ante. Pode ser feita uma vez e a aposta ante é igual à big blind.",
      },
      minimumRaise: {
        heading: "Aumento Mínimo",
        desc: "Um jogador não pode aumentar uma quantia inferir à big blind.",
      },
      allIn: {
        heading: "All In",
        desc: "Se um jogador não tiver fichas suficientes para ver uma aposta, este poderá fazer 'all-in'. O jogador aposta com tudo o que tem. Se um jogador fizer all-in e vencer, o pote será dividido entre esse jogador e o jogador com a segunda melhor mão que pode realizar aumentos",
      },
      potSplit: {
        heading: "Divisão do Pote",
        desc: "Se dois ou mais jogadores estiverem empatados, o pote é dividido igualmente entre eles.",
      },
      bjGameRules: {
        heading: "Regras do jogo",
        desc: "O jogo é gerido por um croupier e um número ilimitado de jogadores pode jogar por mão. O jogo é apresentado por um croupier e jogado com oito baralhos de 52 cartas. Os 21 valores de cartas no jogo são os seguintes. Cartas entre 2 e 10 têm os valores escritos nas mesmas. As cartas com fotos (Valetes, Damas e Reis) valem 10 pontos. Os Azes valem 1 ou 11, sendo que o jogador pode escolher o valor apropriado de acordo com a sua mão. Note que a mão suave contém Azes de 11 valores. Quando o tempo de aposta expirar, o croupier entrega uma carta virada para cima a todos os jogadores, guardando a última para si mesmo. De seguida, o croupier entrega uma segunda carta virada para cima a todos os jogadores, mas a segunda carta do croupier ficará virada para baixo. O valor da sua primeira mão é mostrado junto às suas cartas. O blackjack (vinte e um) acontece se duas cartas dadas no início do jogo somarem 21.",
        descInsurance:
          "Se a carta virada para cima do croupier for um Ás, pode comprar seguro contra o risco de o croupier possuir um 21, mesmo se a sua mão total corresponder a 21. A quantia do seguro será igual a metade da sua aposta principal e a aposta de seguro será processada separadamente da sua aposta de mão. Depois, o croupier olha para o valor da sua carta virada para baixo para verificar se ele chegou ao número 21. Se não tiver chegado, a ronda continua. Se o croupier tiver Blackjack e você não, o jogo termina e o croupier vence. Se tanto você como o croupier chegaram ao 21, o jogo termina num empate e a sua aposta será reembolsada. Note que se a carta virada para cima do croupier for um 10 ou uma carta com cor, terá a opção de comprar o seguro e o croupier não poderá ver se a sua carta virada para baixo contém o 21.",

        headingInsurance: "Seguro",

        headingSplit: "Dividir em Dois",
        descSplit:
          "Se a sua primeira mão consistir em duas cartas do mesmo rank, poderá separar o par em duas mãos diferentes, onde cada uma tem uma aposta igual à sua aposta principal. Após as duas mãos serem entregues com uma segunda carta, poderá aumentar o valor das duas mãos ao aplicar a opção de carta Hit. Aqui, poderá escolher a opção Stand quando estiver satisfeito com o valor de ambas as mãos. No entanto, quando separar o primeiro par de Áses, ambas as mãos irão automaticamente receber uma carta adicional e não terá a chance de obter outra carta.",
      },
    },
    actions: {
      desc: "Durante a ronda de apostas, o jogador pode fazer uma das seguintes ações.",
      call: {
        heading: "Chamar",
        desc: "Se uma aposta for feita, se o jogador a vir, então terá de cobrir a aposta. Por exemplo, se o Jogador A apostar 10 e o jogador B vir, o jogador B tem de cobrir a aposta dele de 10 e colocar um 10 no pote.",
      },
      check: {
        heading: "Check",
        desc: "Um jogador pode jogar um check se nenhumas apostas forem realizadas na ronda atual. A ação será movida para o próximo jogador à esquerda. O check pode ser interpretado como um tipo de passe; o jogador pode ficar no jogo, mas decidir não apostar na ronda atual.",
      },
      raise: {
        heading: "Aumentar",
        desc: "Um jogador pode aumentar o tamanho atual da aposta. Para tal, a aposta deve pelo menos duplicar a aposta do jogador anterior. Para continuar a luta pelo pote, todos os seguintes jogadores podem ver esta aposta, aumentar novamente ou passar.",
      },
      fold: {
        heading: "Fold",
        desc: "O fold é uma recusa para ter dificuldades no pote atual. Se um jogador passar, as cartas do mesmo não são dadas e ele não pode ganhar na ronda atual.",
      },
    },
    states: {
      winning: {
        heading: "Vencendo",
        desc: "Um ou mais jogadores com a mão vencedora devem mostrar as suas cartas viradas para baixo.",
      },
      aggressivePlayer: {
        heading: "Jogador Agressivo",
        desc: "O jogador agressivo é o último jogador a aumentar a aposta durante a mão. Se a aposta for vista e a mão terminar com uma renovação, o jogador agressivo deve mostrar as suas cartas para baixo independentemente do resultado da mão.",
      },
      allInPlayer: {
        heading: "Jogador All-In",
        desc: "Se os jogadores apostarem em todas as fichas, eles fizeram todos all ins e a mão eventualmente termina com uma carta reveladora. Os jogadores agressivos devem mostrar as suas cartas viradas para baixo independentemente do resultado da mão.",
      },
      desc: "Se um ou mais jogadores fizerem all-in e mais nenhumas apostas forem realizadas, todos os outros jogadores devem mostrar as suas cartas viradas para baixo. Caso contrário, os jogadores que continuam a aumentar e a gerar o pote devem mostrar as suas cartas ao jogador agressivo e o vencedor será determinado pelas regras.",
      blackJackResult: {
        heading: "Vencendo",
        desc: "Se o valor total da sua mão exceder 21, você queima e perderá a sua aposta nessa mão. Depois de passar a vez de todos os utilizadores, o croupier mostra o valor da sua carta virada para baixo. Se a mão do croupier for igual a 16 ou menos, o croupier deve escolher a opção de carta Hit, mas se essa mão for um suave 17 ou mais alto, o croupier deve parar ao escolher a opção Stand (note que a mão suave contém um Ás com valor 11). No final, você vence quando o valor da sua mão estiver mais perto de 21 do que da mão do croupier, ou quando o croupier queimar. Se a sua mão for do mesmo valor que a do croupier, a ronda do jogo termina num empate e a sua aposta é reembolsada. Jogue com opções Side Bet, ganhe até '100x'.",
      },
    },
  },

  updateScene: {
    downloading: "Descarregando Nova Versão...",
    installing: "Instalando...",
    installedVersion: "Versão Instalada",
    currentVersion: "Versão Atual",
  },

  actionTypes: {
    call: "Call",
    check: "Check",
    fold: "Fold",
    raise: "Aumentar",
    allin: "All-in",
  },

  tableQuickMenu: {
    standUp: "Levantar",
    goToLobby: "Ir Para o Lobby",
    soundOn: "Som Ativado",
    soundOff: "Som Desativado",
    vibrationOn: "Vibração Ativada",
    vibrationOff: "Vibração Desativada",
    animatedGiftsOn: "Mostrar Emotas",
    animatedGiftsOff: "Esconder Emotas",
    watchWin: "Watch Win",
  },
  notification: {
    noData: "You have no notification.",
  },
  actionPanel: {
    check: "Check",
    call: "Call",
    fold: "Fold",
    raise: "Aumentar",
    allin: "All-in",
    callAny: "Chamar Qualquer",
    checkFold: "Check/Fold",
  },

  selectPotWindow: {
    autoPurchase: "Compra Auto",
    autoPurchaseDescription:
      "Compra automática quando o stack for igual a zero.",
    autoCharging: "Carregamento Auto",
    autoChargingDescription:
      "Carregamento automático quando o stack for inferior a 70%.",
    sit: "Sentar",
    minEntry: "Entrada Min",
    maxEntry: "Entrada Max",
  },

  quickWindow: {
    quickChat: "Esta é a área de conversa rápida.",
    readyMessage: "Aqui estão mensagens já criadas",
    messageAim:
      "Este local tem o objetivo de permitir conversar mais rápido com o utilizador.",
    quickEmoji: "Emoji Rápido",
  },

  tipButton: {
    thankYou: "Obrigado",
  },

  fastChatMessages: [
    "Good luck!",
    "Congrats!",
    "Thanks",
    "Bluff!",
    "Please, play faster!",
    "Newbie!",
    "Good game, bravo!",
    "What luck!",
    "Take it easy champ!",
  ],

  chatWindow: {
    tabs: {
      chat: "Conversar",
      dealer: "Negociante",
      mute: "Silenciar",
    },
    audiences: "Público",
    players: "Jogadores",
    typeHere: "Escrever aqui...",
    xSecondsLeft: "{seconds} segundos restantes.",
  },

  dealerChat: {
    call: "{username} disse chamar",
    check: "{username} disse check",
    raise: "{username} aumentou {amount} quantia",
    fold: "{username} disse fold",
    allin: "{username} disse allin",
    win: "{username} ganhar {amount}",
    winWithCards: "{username} ganhou {amount} com {hand}",
    winWithCardIcons: "{username} ganhou {amount} com {cards} ({hand})",
  },

  systemMessages: {
    roomClosing:
      "O servidor irá reiniciar em 2 minutos. As últimas mãos são jogadas.",
    teamTableAlert:
      "Esta mesa é uma mesa de equipe. Várias contas podem sentar e aumentar na mesa. Não existe regra (ban) no jogo!",
    giftSentChatMessage: "{from} enviou um presente de {gift_name} para {to}.",
    inviteRequestSuccess: "Davet başarı ile gönderildi",
  },
  blackJack: {
    hit: "Acertar",
    stand: "Levantar",
    InsufficientChips: "Fichas Insuficientes",
    undo: "DESFAZER",
    double: "DUPLICAR",
    repeat: "REPETIR",
    xSecondsLeft: "{seconds} segundos restantes.",
    gameHistory: "Histórico do Jogo",
    croupier: "Croupier",
    you: "Você",
    main: "Principal",
    anyPair: "Qualquer Par",
    result: "Resultado",
    previousGame: "Jogo Anterior",
    returnToList: "Voltar à Lista",
    nextGame: "Próximo Jogo",
    type: "Escrever",
    pay: "Pagar",
    winningsHands: "Mãos Vencedoras",
    rules: "Regras",
    bjRules: `
            * O propósito do Turn Blackjack é ter uma mão com pontos mais elevados do que o croupier sem exceder 21; <br>
            * A melhor mão é chamada Blackjack – o valor total das primeiras duas cartas dadas nesta mão é 21;<br>
            * Você joga apenas contra o croupier, não contra outros jogadores;<br>
            * O Blackjack é jogado com oito baralhos;<br>
            * O croupier para sempre no 17;<br>
            * Pode ser duplicado em quaisquer duas cartas iniciais;<br>
            * As cartas iniciais do mesmo valor podem ser divididas em dois;<br>
            * A divisão pode ser feita em cada mão;<br>
            * Uma carta é dada para cada Ás duplo dividido;<br>
            * O duplicado não pode ser realizado depois da divisão das cartas;<br>
            * O seguro é recomendado quando o croupier tem um Ás;<br>
            * Blackjack paga 3:2;<br>
            * Seguro paga 1:2;<br>
            * Quando as mãos são iguais, dá-se um empate.<br>

            <hr>

            <strong>Regras do Jogo</strong><br>

            * O jogo é gerido por um croupier e um número ilimitado de jogadores pode jogar por mão;<br>
            * O jogo é apresentado por um croupier e jogado com oito baralhos de 52 cartas cada. As 21 cartas de valor são as seguintes:<br>
            - Cartas do 2 ao 10 têm os valores escritos nas mesmas.<br>
            - As cartas com fotos (Valetes, Damas e Reis) valem 10 pontos.<br>
            - Os Ases valem 1 ou 11, e o jogador pode escolher o valor apropriado de acordo com a sua mão. Note que a mão suave contém Ases de 11 valores;<br>
            * Quando o tempo de aposta tiver expirado, o croupier entrega uma carta virada para cima a todos os jogadores, sendo a última para ele mesmo. Depois, o croupier dá uma segunda carta virada para cima a todos os jogadores, mas a segunda carta do croupier ficará virada para baixo. O valor da sua primeira mão é mostrado junto às suas cartas.<br>
            * Blackjack (Vinte e um) = Se as duas cartas que lhe entregaram no início do jogo chegarem a 21, então tem um Blackjack!<br>
            <br>
            <strong>Seguro</strong>
            <br>
            * Se a carta virada para cima do croupier for um Ás, poderá comprar seguro contra o risco de o croupier ter um 21, mesmo se a mão total for 21. A quantia de seguro será igual a metade da sua aposta principal e a aposta de seguro será processada separadamente da sua aposta de mão. Depois, o croupier olha para o valor da sua carta virada para baixo para verificar se chegou ao 21.<br>
            - Se o croupier não chegar ao 21, a ronda continua. <br>
            - Se o croupier tiver Blackjack e você não, o jogo acaba e o croupier ganha.<br>
            - Se tanto você como o croupier tiverem 21, o jogo terminará num empate e a sua aposta será reembolsada. <br>
            <br>
            Note que se a carta virada para cima do croupier for um 10 ou uma carta com cor, terá a opção de comprar o seguro e o croupier não olhará para a sua carta de baixo para encontrar o 21.
            <br>
            <strong>Duplo, Hit ou Stand</strong>
            <br>
Se o croupier descobrir que depois de ver as suas primeiras duas cartas não conseguiu chegar ao 21, então os jogadores têm o direito de escolher mais cartas para melhorarem o valor da sua mão, ao darem ao croupier mais cartas para os jogadores.
            <br>
Se a sua primeira mão não valer 21, tem o direito de duplicar a aposta. Nesse caso, a sua aposta será duplicada e receberá apenas uma carta adicional para acrescentar à sua mão. Alternativamente, pode escolher usar a opção Hit para obter cartas adicionais para acrescentar ao valor da sua mão. Pode fazer Hit de muitas cartas até estar satisfeito com o valor da sua mão e decidir fazer Stand.
            <br>
            Note que um jogador com uma mão suave igual a 21 não terá uma opção para decisão.
            <br>
            <strong>Dividir em Dois</strong><br>
            <br>
Se a sua primeira mão for composta por duas cartas do mesmo rank, poderá dividir o par em duas mãos diferentes, onde cada uma tem uma aposta igual à sua aposta principal. Assim que ambas as mãos forem dadas com uma segunda carta, poderá aumentar o valor dessas duas mãos ao aplicar a opção da carta Hit. Aqui, poderá escolher a opção Stand, quando estiver satisfeito com o valor de ambas as mãos. No entanto, quando divide o primeiro par de Áses, ambas as mãos irão automaticamente receber uma carta adicional e não terá a chance de obter outra carta.


            <br>
            <strong>Resultado</strong>
            <br>
          Se o valor total da sua mão exceder o 21, você queima e perderá a sua aposta na mão em questão.
            <br>
            Depois da vez de todos os utilizadores passar, o croupier mostra o valor da sua carta virada para baixo. Se a mão do croupier for 16 ou menos, este deve escolher a opção da carta Hit, mas se a mão for um 17 suave ou mais elevado, então o croupier deve parar ao escolher a opção Stand (note que a mão suave contém um Ás de valor 11).
            <br>
No final, ganha quando o valor da sua mão estiver mais perto de 21 do que a mão do croupier ou quando o croupier queimar. Se a sua mão tiver o mesmo valor que a do croupier, a ronda do jogo termina num empate e a sua aposta é reembolsada.

            <br>Jogue com as opções Side Bets, ganhe até "100x".`,
    settings: "Configurações",
    sounds: "Sons",
    bet: "APOSTAR",
    waitOtherPlayers: "Por favor espere por outros jogadores",
    placeYourBet: "Faça a sua aposta",
    choose: "Escolher",
    wantInsurance: "Quer um seguro?",
    draw: "EMPATE",
    busted: "BUSTED",
    youLost: "Você Perdeu",
    sit: "SENTAR",
    otoPurchase: "Compra automática",
    otoPurchaseText:
      "Se voltar a iniciar sessão imediatamente, a contagem desce para zero fichas na mesa",
    okay: "OKAY",
    insufficientBalance: "Não tem dinheiro suficiente para sentar.",
    ShufflingCards: "Misturando o baralho de cartas...",
    youWon: "Você Ganhou",
    won: "GANHOU",
    dealNow: "Dar Agora",
  },
  useInviteCode: {
    heading: "Código de Convite",
    description: "Por favor insira o código de convite dos seus amigos.",
    unset: "Remover Código de Convite",
    inviteCodeNotFound: "Código de Convite errado.",
  },
  profileSettings: {
    profileSettings: " Profile Settings ",
    registrationDate: "Registration Date",
    use: "USE",
    gift: "GIFT",
    buyVip: "Buy VIP",
    onlyVip: " Only VIP members can purchase this gift.",
    frame: "Frame",
    buy: "BUY",
    statistics: {
      statistics: "Statistics",
      handPlayed: "Spin counter",
      biggestPotWon: "Biggest Pot",
      biggestJackPotWon: "Biggest JackPot",
      recordChip: "Chip Record",
      brokenPotCounter: "Won Pot Counter",
      winPercentage: "Win Percentage",
      leadershipRanking: "Leadership Ranking",
      bestWinningHand: "Best Winning Hand",
      biggestWin: "Biggest Win",
    },
  },
  settings: {
    lobbyMusic: "Lobby Music",
    soundEffects: "Sound FX",
    vibration: "Vibrate",
    blockInvites: "Convites",
    giftHide: "Hide Gift",
    handStrengthIndicator: "Hand Strength Meter",
    graphicsPreferences: "Graphics Preferences",
    tableColor: "Table Color",
    roomBgColor: "Background",
    languagePreference: "Language Preference",
    termsOfUse: "Terms Of Use",
    accountInfo: "Account Info",
    profileID: "Player ID",
    deviceNO: "Device",
    deleteAccount: "Delete Account",
    language: {
      turkish: "Turkish",
      english: "English",
      italian: "Italian",
      indonesian: "Indonesian",
      portuguese: "Portuguese",
      russian: "Russian",
      malesian: "Malesian",
    },
  },
  vip: {
    available: "VIP / {day} Days",
    noVip: "VIP is not available",
  },
  friends: {
    online: "ONLINE",
    goToTable: "Junte-se",
    chipTransfer: "Chip Transfer",
    friendRequests: "Friend Requests",
    incoming: "INCOMING",
    sent: "SENT",
    search: "SEARCH",
    removeFriendNotification: "Removed from friend list",
    userProfile: "User Profile",
    sendChipText: "Send Chips",
    deletefriends: "Delete Friend",
  },
  progressiveJackpot: {
    join: "Aderir",
    desc1: "Anda membayar cip sama dengan blind kecil setiap tangan.",
    desc2:
      "Apabila anda menang, anda akan menerima hadiah yang sama dengan PERTARUHAN BLIND KECIL x PENGGANDA.",
    desc3: "É a tigela comum de fichas coletadas no mesmo nível de aposta",
  },
  pinCode: {
    wrong: "O PIN está errado!",
    pinCode: "CÓDIGO PIN",
    removePinCode: "Remove Pin Code",
    enterPin: "Por favor insira o código PIN da sua conta",
    reset: "Redefinir a minha palavra-passe!",
    logOut: "TERMINAR SESSÃO",
    resetPin: "REDEFINIR CÓDIGO PIN",
    enterEmail: "Por favor insira o endereço de email da sua conta",
    mail: "Email",
    newPass:
      "A sua nova palavra-passe foi enviada para o seu endereço de email",
    wrongMail: "o endereço de email que inseriu está incorreto",
    failRemove: "Falha ao remover código PIN",
    failAdd: "Falha ao guardar código PIN",
  },
  redeem: {
    used: "Cartão de jogo já utilizado!",
    expired: "Cartão de jogo expirado!",
    failed: "Falha ao validar Cartão de Jogo.",
    validGameCard: "Please Enter a valid game card", // NEW*********
  },
  toast: {
    inviteRequestText: "{name} convida você para jogar {game}.",
  },
  updatedNotes: {
    title: "Atualizar Notas",
    notes: [
      "<div class='center'><span class='title'>Poker</span></div>",
      "O som de fundo está desativado nas configurações padrão",
      "50 Jogadores na lista de Líderes",
      "Acesse os recursos de 'Pesquisa' e 'Histórico de Mãos' na mesa",
      "Apenas os jogadores que jogam na mesa podem escrever no chat do jogo",
      "Impressionante jogo Transparente O bate-papo está no lado esquerdo",
      "Faça uma ação (Call/Fold/Check/Raise) enquanto o Chat estiver aberto",
      "Temporizador de contagem regressiva legal",
      "<div class='center'><span class='title'>Blackjack</span></div>",
      "Cartões e texto ampliados e aconchegantes",
      "Defeitos consertados",
    ],
  },
  forceUpdateWindow: {
    title: "Uma nova versão está disponível!",
    text: "Por favor, atualize o aplicativo para a versão mais recente.",
  },
  table: {
    noHaveOnlineFriends: "Você não tem amigos online.",
    onlineFriends: "Amigos Online",
    noHaveFriends: "You don't have any friends",
  },
  others: {
    friendRemovedInfo: "Friend was removed",
    notFoundFriend: "Friend is not found",
    notFoundDisplay: "User is not found",
    friendTableNotFound: "Friend’s table is not found",
    tableInFriendNotFound: "No player on the table",
    requiredSelectedGift: "Select Gift",
    currentLocationNotAvailableBuy:
      "Purchasing is unavailable for your country",
    requiredLevelFive: "Level 5 is required",
    blackjackStatusInactive: "Blackjack is not available",
    slotStatusInactive: "Slot is not avalable",
    muteAll: "Mute",
    changeNameSuccesInfo: "Nome de usuário alterado Você tem {0} restantes",
    guestClose:
      "Você não pode fazer login como convidado. Por favor, faça login com outra conta",
  },
};
