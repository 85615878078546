<template>
  <div
    class="game-history"
    :class="[$parent.showGameHistory ? 'show' : '']"
    v-click-outside="onClickedOutside"
  >
    <div class="game-history-header">{{ $t("blackJack.gameHistory") }}</div>
    <div class="game-history-body">
      <div class="loading-container" v-if="loading">
        {{ $t("loading") }}
      </div>
      <template v-if="!loading">
        <div class="game-list" v-if="selectedGame.id === -1">
          <div
            v-for="(game, gameIndex) in logs"
            :key="'game-log-' + gameIndex"
            class="game-row"
            @click="setSelectedGame(game)"
          >
            <div class="inner-row">
              <div class="game-id">#{{ game.id }}</div>
              <div class="room-id">
                {{ game.room }} -
                {{ new Date(game.createdAt * 1000).toLocaleString() }}
              </div>
            </div>
            <div class="inner-row">
              <div class="cards">
                <span
                  v-for="(card, cardIndex) in game.data.players[0].cards"
                  :key="'game-' + game.id + '-card-' + cardIndex"
                  :class="['card', ['d', 'h'].includes(card.suit) ? 'red' : '']"
                >
                  <span>{{ card.value }}</span>
                  <span v-html="getCardSymbols[card.suit] + ' &nbsp;'"></span>
                </span>
                <span class="small"
                  >({{ game.data.players[0].handStrength }})</span
                >
              </div>
              <div
                class="win-amount"
                :class="[
                  getGameGainedAmount(game.data.players[0]) > 0
                    ? 'green'
                    : 'red',
                ]"
              >
                {{
                  getGameGainedAmount(game.data.players[0]) >= 0
                    ? getShortChipFormat(
                        getGameGainedAmount(game.data.players[0])
                      )
                    : "-" +
                      getShortChipFormat(
                        getGameGainedAmount(game.data.players[0]) * -1
                      )
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="game-details" v-if="selectedGame.id > 0">
          <div class="game-details-header">
            <div>ID: {{ selectedGame.id }}</div>
            <div>
              {{ new Date(selectedGame.createdAt * 1000).toLocaleString() }}
            </div>
          </div>
          <div class="game-details-body">
            <div class="croupier">
              <div class="croupier-heading">{{ $t("blackJack.croupier") }}</div>
              <div class="croupier-cards">
                <Card
                  v-for="(card, cardIndex) in selectedGame.data.dealer.cards"
                  :key="'dealer-card-' + cardIndex"
                  :index="card.index"
                  :suit="card.suit"
                  :value="card.value"
                  :playSounds="false"
                />
                <div class="hand-strength">
                  {{ selectedGame.data.dealer.handStrength }}
                </div>
              </div>
            </div>
            <div class="player">
              <div class="player-heading">{{ $t("blackJack.you") }}</div>
              <div class="player-cards-outer">
                <div class="cards-flex">
                  <div class="player-cards">
                    <Card
                      v-for="(card, cardIndex) in selectedGame.data.players[0]
                        .cards"
                      :key="'player-card-' + cardIndex"
                      :index="card.index"
                      :suit="card.suit"
                      :value="card.value"
                      :playSounds="false"
                    />
                  </div>
                  <div class="hand-strength">
                    {{ selectedGame.data.players[0].handStrength }}
                  </div>
                </div>
                <div
                  class="cards-flex"
                  v-if="selectedGame.data.players[0].split.cards.length > 0"
                >
                  <div class="player-cards">
                    <Card
                      v-for="(splitCard, splitCardIndex) in selectedGame.data
                        .players[0].split.cards"
                      :key="'player-split-card-' + splitCardIndex"
                      :index="splitCard.index"
                      :suit="splitCard.suit"
                      :value="splitCard.value"
                      :playSounds="false"
                    />
                  </div>
                  <div class="hand-strength">
                    {{ selectedGame.data.players[0].split.handStrength }}
                  </div>
                </div>
              </div>
              <div class="player-bets">
                <div class="player-bet-column">
                  <div class="bet-heading">{{ $t("blackJack.main") }}</div>
                  <div class="bet-value">
                    <div>
                      {{
                        getShortChipFormat(
                          selectedGame.data.players[0].bet.main
                        )
                      }}
                    </div>
                    <div
                      v-if="
                        typeof selectedGame.data.players[0].wonWithTypes !==
                          'undefined' &&
                        selectedGame.data.players[0].wonWithTypes.main > 0
                      "
                      class="gained-x"
                    >
                      {{
                        `+ ${selectedGame.data.players[0].wonWithTypes.main}X`
                      }}
                    </div>
                  </div>
                </div>
                <div class="player-bet-column">
                  <div class="bet-heading">21+3</div>
                  <div class="bet-value">
                    <div>
                      {{
                        getShortChipFormat(
                          selectedGame.data.players[0].bet.twentyOne
                        )
                      }}
                    </div>
                    <div
                      v-if="
                        typeof selectedGame.data.players[0].wonWithTypes !==
                          'undefined' &&
                        selectedGame.data.players[0].wonWithTypes.twentyOne > 0
                      "
                      class="gained-x"
                    >
                      {{
                        `+ ${selectedGame.data.players[0].wonWithTypes.twentyOne}X`
                      }}
                    </div>
                  </div>
                </div>
                <div class="player-bet-column">
                  <div class="bet-heading">{{ $t("blackJack.anyPair") }}</div>
                  <div class="bet-value">
                    <div>
                      {{
                        getShortChipFormat(
                          selectedGame.data.players[0].bet.anyPair
                        )
                      }}
                    </div>
                    <div
                      v-if="
                        typeof selectedGame.data.players[0].wonWithTypes !==
                          'undefined' &&
                        selectedGame.data.players[0].wonWithTypes.anyPair > 0
                      "
                      class="gained-x"
                    >
                      {{
                        `+ ${selectedGame.data.players[0].wonWithTypes.anyPair}X`
                      }}
                    </div>
                  </div>
                </div>
                <div class="player-bet-column">
                  <div class="bet-heading">Split</div>
                  <div class="bet-value">
                    {{
                      getShortChipFormat(selectedGame.data.players[0].split.bet)
                    }}
                  </div>
                </div>
              </div>
              <div class="total-wins">
                <div class="total-win-heading">
                  {{ $t("blackJack.result") }}
                </div>
                <div
                  class="total-win-value"
                  :class="[
                    getGameGainedAmount(selectedGame.data.players[0]) > 0
                      ? 'green'
                      : 'red',
                  ]"
                >
                  {{
                    getGameGainedAmount(selectedGame.data.players[0]) >= 0
                      ? getChipFormat(
                          getGameGainedAmount(selectedGame.data.players[0])
                        )
                      : "-" +
                        getChipFormat(
                          getGameGainedAmount(selectedGame.data.players[0]) * -1
                        )
                  }}
                </div>
              </div>
            </div>
            <div class="game-detail-footer">
              <div class="column">
                <button
                  type="button"
                  class="prev-next-button prev-button"
                  :disabled="!isPageAvailable(-1)"
                  @click="go(-1)"
                >
                  {{ $t("blackJack.previousGame") }}
                </button>
              </div>
              <div class="column">
                <button
                  type="button"
                  class="return-to-history-button"
                  @click="setSelectedGame({ id: -1, game: -1, data: {} })"
                >
                  {{ $t("blackJack.returnToList") }}
                </button>
              </div>
              <div class="column">
                <button
                  type="button"
                  class="prev-next-button next-button"
                  :disabled="!isPageAvailable(1)"
                  @click="go(1)"
                >
                  {{ $t("blackJack.nextGame") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";

import Card from "../Card";

export default {
  name: "GameHistory",
  components: { Card },
  directives: { ClickOutside },
  watch: {
    "$parent.showGameHistory": function (newValue, oldValue) {
      if (newValue === oldValue) return false;

      if (newValue === true) this.fetchData();
    },
  },
  computed: {
    ...mapGetters(["getShortChipFormat", "getChipFormat"]),
    getCardSymbols() {
      return {
        c: "&clubs;",
        h: "&hearts;",
        s: "&spades;",
        d: "&diamond;",
      };
    },
  },
  data() {
    return {
      currentPage: 1,
      logs: [],
      selectedGame: {
        id: -1,
        game: -1,
        data: {},
      },
      loading: true,
    };
  },
  methods: {
    onClickedOutside($event) {
      if (typeof $event?.target?.className.includes === "undefined")
        return false;
      if (
        $event?.target?.className.includes("past-button") ||
        $event?.target?.className.includes("prev-next-button") ||
        $event?.target?.className.includes("return-to-history") ||
        $event?.target?.className.includes("game-row") ||
        $event?.target?.className.includes("inner-row") ||
        $event?.target?.className.includes("room-id") ||
        $event?.target?.className.includes("game-id") ||
        $event?.target?.className.includes("win-amount")
      )
        return false;
      this.$parent.showGameHistory = false;
    },
    isPageAvailable(page = -1) {
      const currentGameIndex = this.logs.findIndex(
        (game) => game.id === this.selectedGame.id
      );
      if (currentGameIndex < 0) return false;

      let to = currentGameIndex - page;
      if (to < 0) return false;
      return to <= this.logs.length - 1;
    },
    go(page = -1) {
      const currentGameIndex = this.logs.findIndex(
        (game) => game.id === this.selectedGame.id
      );
      if (currentGameIndex < 0) return false;

      let to = currentGameIndex - page;
      if (to < 0) to = 0;
      if (to > this.logs.length - 1) to = this.logs.length - 1;

      this.setSelectedGame(this.logs[to]);
    },
    setSelectedGame(game) {
      this.$set(this.$data, "selectedGame", game);
    },
    getGameGainedAmount(player) {
      const totalBets = this.getTotalBets(player.bet);
      return player.totalWinAmount - totalBets;
    },
    getTotalBets(bets) {
      return Object.values(bets).reduce((acc, current) => acc + current, 0);
    },
    async fetchData() {
      this.loading = true;
      this.$data.logs.splice(0, this.$data.logs.length);
      const games = await this.$store.dispatch("user/getUserGameHistory", {
        game: "blackjack",
      });
      games.forEach((game) => this.$data.logs.push(game));
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.game-history {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 12;
  width: 600px;
  height: 1080px;
  background-color: rgba(lighten(black, 2%), 0.8);
  border-radius: 10px;
  overflow: hidden;
  transform: translateY(calc(-100% - 60px));
  opacity: 0;
  transition: all 0.25s ease;
  &.show {
    transform: translateY(0);
    opacity: 1;
  }
  &-header {
    width: 100%;
    height: 90px;
    line-height: 90px;
    font-size: 48px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
    background-color: rgba(black, 0.7);
    &-th {
      font-weight: bold;
    }
  }
  &-body {
    height: 990px;
    overflow-x: hidden;
    overflow-y: auto;
    .loading-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 200px;
      font-size: 36px;
    }
    .game-list {
      width: 100%;
      padding-bottom: 30px;
      .game-row {
        box-sizing: border-box;
        padding: 30px;
        font-size: 32px;
        border-bottom: 3px solid rgba(rgb(255, 255, 255), 0.3);
        cursor: pointer;
        transition: all 0.25s ease;
        &:hover {
          background-color: rgba(black, 0.7);
        }
        &:last-child {
          border-bottom: 0;
        }
        .inner-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          .game-id,
          .room-id {
            font-size: 24px;
            color: rgba(rgb(255, 255, 255), 0.8);
          }
          .cards {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .card {
              &.red {
                color: $redColor;
              }
            }
            .small {
              font-size: 13px;
              color: rgba(rgb(255, 255, 255), 0.5);
            }
          }
          .win-amount {
            color: $greenColor;
            &.red {
              color: $redColor;
            }
          }
        }
      }
    }

    .game-details {
      &-header {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
        background-color: rgba(rgb(255, 255, 255), 0.1);
      }
      &-body {
        .croupier {
          background-color: rgba(rgb(255, 255, 255), 0.05);
          .croupier-heading {
            text-align: center;
            padding: 25px 0;
            font-size: 32px;
          }
          .croupier-cards {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 30px;
            .card {
              transform: scale(1);
              &:not(:first-child) {
                margin-left: -4%;
              }
            }
            .hand-strength {
              color: $yellowColor;
              margin-left: 20px;
              font-size: 36px;
            }
          }
        }
        .player {
          .player-heading {
            background-color: black;
            text-align: center;
            font-size: 32px;
            padding: 15px 0;
          }
          .player-cards-outer {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .cards-flex {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-right: 15px;
            &:last-child {
              margin-right: 0;
            }
          }
          .player-cards {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            margin: 20px 0;
            transform: scale(1);
            .card {
              &:not(:first-child) {
                margin-left: -25px;
              }
            }
          }
          .hand-strength {
            color: $yellowColor;
            margin-left: 30px;
            font-size: 36px;
          }
          .player-bets {
            display: flex;
            align-items: stretch;
            justify-content: center;
            border-top: 3px solid rgba(rgb(255, 255, 255), 0.15);
            background-color: rgba(black, 0.1);
            .player-bet-column {
              width: 33.333%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-direction: column;
              text-align: center;
              border-right: 1px solid rgba(rgb(255, 255, 255), 0.15);
              .bet-heading {
                font-weight: bold;
                font-size: 20px;
                width: 100%;
                padding: 7px 0;
                box-sizing: border-box;
                text-align: center;
                background-color: rgba(black, 0.2);
                border-bottom: 3px solid rgba(rgb(255, 255, 255), 0.15);
              }
              .bet-value {
                padding: 10px;
                font-size: 24px;
                .gained-x {
                  color: $greenColor;
                }
              }
              &:last-child {
                border-right: 0;
              }
            }
          }
          .total-wins {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: rgba(black, 0.1);
            .total-win-heading {
              font-weight: bold;
              font-size: 25px;
              width: 100%;
              padding: 5px 0;
              box-sizing: border-box;
              text-align: center;
              background-color: rgba(black, 0.2);
              border-top: 3px solid rgba(rgb(255, 255, 255), 0.15);
              border-bottom: 3px solid rgba(rgb(255, 255, 255), 0.15);
            }
            .total-win-value {
              padding: 10px;
              font-size: 32px;
              &.green {
                color: $greenColor;
              }
              &.red {
                color: $redColor;
              }
            }
          }
        }
        .game-detail-footer {
          border-top: 3px solid rgba(rgb(255, 255, 255), 0.2);
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          .column {
            width: 33.333%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:first-child {
              justify-content: flex-start;
            }
            &:last-child {
              justify-content: flex-end;
            }
          }
          button {
            padding: 0 25px;
            color: rgb(255, 255, 255);
            cursor: pointer;
            background-color: transparent;
            border: 0;
            font-size: 22px;
            font-weight: bold;
            transition: all 0.25s ease;
            &:disabled {
              opacity: 0.2;
            }
            &:not(:disabled):hover {
              opacity: 0.8;
            }
          }
          .return-to-history-button {
            background-color: black;
            padding: 15px 25px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
</style>
