import Logger from "@/logger";
import AppConstants from "@/constants/app";
import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";
import axios from "@/services/api/axios";
import adjust from "@/plugins/adjust";

import { Capacitor } from "@capacitor/core";
import {
  AdMob,
  BannerAdPluginEvents,
  RewardAdPluginEvents,
} from "@capacitor-community/admob";

const AdmobManager = {
  async setup() {
    if (Capacitor.getPlatform() === "web") {
      Logger.print("info", "ADMOB not implemented on web");
      return;
    }

    const tranckResponse = await AdMob.trackingAuthorizationStatus();

    if (tranckResponse.status === "notDetermined")
      Logger.print("info", "Admob not determined before.");

    return await AdMob.initialize({
      requestTrackingAuthorization: true,
      testingDevices: [...AppConstants.externalLibrary.ADMOB.testingDevices],
      initializeForTesting: false,
    });
  },

  async showBanner() {
    AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
      EventBus.$emit(EventConstants.ADMOB_BANNER_LOADED);
    });
    AdMob.addListener(BannerAdPluginEvents.FailedToLoad, () => {
      EventBus.$emit(EventConstants.ADMOB_BANNER_FAILED_TO_LOAD);
    });
    AdMob.addListener(BannerAdPluginEvents.Closed, () => {
      EventBus.$emit(EventConstants.ADMOB_BANNER_CLOSED);
    });
    AdMob.addListener(BannerAdPluginEvents.SizeChanged, () => {
      EventBus.$emit(EventConstants.ADMOB_BANNER_SIZE_CHANGED);
    });

    await AdMob.showBanner(AppConstants.externalLibrary.ADMOB.bannerOptions);
  },

  async rewardVideo(userId = 0) {
    const adBonusCallbackFunc = async () => {
      return await axios.get("/bonus/ad-bonus/callback", {
        params: {
          ad_network: "5450213213286189855",
          ad_unit: "1234567890",
          reward_amount: "100000",
          reward_item: "Ad Bonus Reward",
          timestamp: "1643894986584",
          transaction_id: Math.floor(Math.random() * 9999999999),
          user_id: userId,
          signature:
            "MEUCIBL3ei1TeOaUzvDD0dopCgwnynqN4yKTgG6czImLas6PAiEArzeRxMaxdIjpurXNNJuaJmxCdiJ258ukLC_sGWiX_SA",
          key_id: "3335741209",
        },
      });
    };
    const options = AppConstants.externalLibrary.ADMOB.rewardAdOptions;
    options.ssv.userId = userId;

    AdMob.addListener(RewardAdPluginEvents.Showed, () => {});
    AdMob.addListener(RewardAdPluginEvents.Loaded, (info) => {
      EventBus.$emit(EventConstants.ADMOB_REWARD_AD_LOADED, info);
    });
    AdMob.addListener(RewardAdPluginEvents.Rewarded, async (rewardItem) => {
      Logger.print("log", ["RewardAdRewarded trigger", rewardItem]);

      try {
        if (Capacitor.getPlatform() !== "ios") {
          const response = adBonusCallbackFunc();
        }
      } catch (err) {
        Logger.print("error", ["AdMobVideoReward", err]);
      }
    });
    await AdMob.prepareRewardVideoAd(options).catch((err) =>
      console.log("prepareRewardVideoAd error", err)
    );
    const admobShowReward = await AdMob.showRewardVideoAd();
    if (Capacitor.getPlatform() === "ios") {
      setTimeout(() => {
        adBonusCallbackFunc()();
      }, 2000);
    }
    return admobShowReward;
  },

  async prepareRewardVideoAd() {
    const options = AppConstants.externalLibrary.ADMOB.rewardAdOptions;
    await AdMob.prepareRewardVideoAd(options).catch((err) =>
      console.log("prepareRewardVideoAd error", err)
    );
    adjust.trackEvent("ADBonusWatchVideo");
    //kaldik
  },
};

export default {
  instance: AdmobManager,

  async init() {
    await AdmobManager.setup();
  },

  install(Vue) {
    Vue.prototype.$plugins.admob = AdmobManager;
  },
};
