import store from "@/store";
export default {
  /*
   *   Get preference by key
   */
  getAnimations(state) {
    return state.animations;
  },
  getGifts(state) {
    return state.gifts;
  },
};
