import axios from "@/services/api/axios";
import Logger from "@/logger";

export default {
  async getTop50Players({ commit }) {
    try {
      const response = await axios.get(`/top-players/top-50`);
      commit("setTop50", response.data);
    } catch (error) {
      Logger.print("error", error);
    }
  },
};
