<template>
  <div class="affiliate-panel">
    <!-- <div class="filter-area-affiliate">
      <div class="date-filter">
        <Calendar />
        <datepicker
          v-model="startDate"
          :placeholder="$t('affiliate.startDate')"
          wrapper-class="date-picker-wrapper"
          input-class="date-picker-input"
          calendar-class="date-picker-calendar"
        ></datepicker>
        <datepicker
          v-model="endDate"
          :placeholder="$t('affiliate.endDate')"
          wrapper-class="date-picker-wrapper"
          input-class="date-picker-input"
          calendar-class="date-picker-calendar"
        ></datepicker>
        <button class="calendar-clear" @click="clearCalendar">
          {{ $t("affiliate.clear") }}
        </button>
      </div>
      <div class="search-area">
        <label class="search-label">{{ $t("affiliate.searchBy") }}</label>
        <select class="search-param-selectbox" v-model="searchParam">
          <option value="name">{{ $t("affiliate.name") }}</option>
          <option value="id">ID</option>
        </select>

        <button
          class="refresh-button ui-button ui-button-orange"
          @click="refresh"
        >
          {{ $t("general.refresh") }}
        </button>
      </div>
    </div> -->
    <!-- <div class="affiliate-tab-selectors">
      <div class="selector active">
        <span> {{ $t("affiliate.affiliate") }} </span>
      </div>
    </div> -->
    <!-- <button class="refresh-button ui-button ui-button-orange" @click="refresh">
      <span
        class="refresh-button-icon sprite-list-pop-up refresh-icon"
        :class="affiliateRefreshing ? 'rotating' : ''"
      ></span>
      <span class="refresh-button-text">{{ $t("general.refresh") }}</span>
    </button> -->
    <div class="affiliate-filter">
      <div class="affiliate-filter-search">
        <input
          type="number"
          v-model="searchId"
          class="affiliate-search-input"
          :placeholder="$t('affiliate.searchBy')"
          @keyup.enter="searchAffiliateId"
        />
      </div>
      <button
        type="button"
        class="search-button flex-center"
        @click="searchAffiliateId"
      >
        <span
          class="navigation-menu-button-icon lobby-sprite search-icon"
        ></span>
      </button>

      <div
        class="affiliate-filter-download"
        @click="affiliateDataDownload"
        v-if="getAllMyReferences.length > 0"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
          <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
          <path
            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-167l80 80c9.4 9.4 24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-39 39V184c0-13.3-10.7-24-24-24s-24 10.7-24 24V318.1l-39-39c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9z"
          />
        </svg>
      </div>

      <div class="affiliate-download-info flex-center" v-if="downloadInfoValue">
        <span class="affiliate-download-info-text">
          {{ $t("affiliate.excelDownloaded") }}
        </span>
      </div>
    </div>
    <div class="affiliate-loading" v-if="loading">
      <DualRing />
    </div>
    <div class="affiliate-panel-table" v-show="!loading">
      <ve-table
        :columns="columns"
        :table-data="getFormattedData"
        :sort-option="sortOption"
        :footer-data="[footerData]"
        :fixed-footer="false"
        :cell-selection-option="cellSelectionOption"
        :row-style-option="rowStyleOption"
        rowKeyFieldName="id"
        :max-height="785"
        :fixed-header="true"
        :event-custom-option="eventCustomOption"
        v-show="getMyReferences.length > 0"
      />
    </div>
    <div
      class="affiliate-panel-pagination flex-center"
      v-if="getMyReferences.length > 0"
    >
      <div class="affiliate-copy-info flex-center" v-if="copyInfoValue">
        <span class="affiliate-copy-info-text">
          {{ $t("playerDetailsWindow.copied") }}
        </span>
      </div>
      <template>
        <ve-pagination
          :total="paginationTotal"
          :page-size="limit"
          :page-size-option="[5, 10, 20, 50]"
          :paging-count="5"
          @on-page-number-change="pageNumberChange"
          @on-page-size-change="pageSizeChange"
        />
      </template>
    </div>
    <!-- <div class="content-more" v-if="showMoreStatus">
      <div
        class="ui-button ui-button-orange window-content-more-text"
        @click="getMoreAffiliate"
      >
        {{ $t("chat.showMore") }}
      </div>
    </div> -->
    <div class="empty-data" v-if="getMyReferences.length == 0">
      {{ $t("affiliate.noData") }}
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import "vue-easytable/libs/theme-dark/index.css";
import Datepicker from "vuejs-datepicker";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { FileOpener } from "@ionic-native/file-opener";

export default {
  name: "AffiliatePanel",
  components: {
    Datepicker,
    Calendar: () => import("@/components/Icons/Calendar"),
    DualRing: () => import("@/components/Utils/DualRing"),
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters([
      "getChipFormat",
      "user/getMyReferences",
      "user/getAllMyReferences",
      "getRealPlatform",
    ]),
    getMyReferences() {
      return this["user/getMyReferences"];
    },
    getAllMyReferences() {
      return this["user/getAllMyReferences"];
    },
    footerData() {
      return {
        rowkey: 0,
        name: this.$t("general.total"),
        poker: this.getChipFormat(
          this.$store.state.user.affiliateStatus.referencesTotal.poker
        ),
        slot: this.getChipFormat(
          this.$store.state.user.affiliateStatus.referencesTotal.slots
        ),
        qq: this.getChipFormat(
          this.$store.state.user.affiliateStatus.referencesTotal.qq
        ),
        blackjack: this.getChipFormat(
          this.$store.state.user.affiliateStatus.referencesTotal.blackjack
        ),
        total: this.getChipFormat(
          this.$store.state.user.affiliateStatus.referencesTotal.poker +
            this.$store.state.user.affiliateStatus.referencesTotal.slots +
            this.$store.state.user.affiliateStatus.referencesTotal.qq +
            this.$store.state.user.affiliateStatus.referencesTotal.blackjack
        ),
      };
    },
    getData() {
      let _arr = this.getMyReferences;

      if (this.searchText !== "") {
        if (this.searchText.length > 0) {
          const firstAlpha = this.searchText[0];

          _arr = _arr.filter((i) => {
            if (!this.isNumeric(firstAlpha)) {
              if (this.searchText.length > 2) {
                return i.name
                  .toLowerCase()
                  .includes(this.searchText.toLowerCase());
              }
              return i.name
                .toLowerCase()
                .startsWith(this.searchText.toLowerCase());
            } else {
              return i.id.toString().includes(this.searchText.toLowerCase());
            }
          });
        }
      }

      return _arr;
    },
    getFormattedData() {
      let temp = this.getData;

      temp.forEach((item) => {
        item.poker = this.getChipFormat(item.poker);
        item.qq = this.getChipFormat(item.qq);
        item.slot = this.getChipFormat(item.slot);
        item.total = this.getChipFormat(item.total);
        item.blackjack = this.getChipFormat(item.blackjack);
      });
      return temp;
    },
    showMoreStatus() {
      if (this.offSet === 0) {
        return this.$store.state.user.affiliateStatus.total > 25;
      } else if (this.offSet > 0) {
        return this.$store.state.user.affiliateStatus.total > this.offSet * 25;
      }
    },
    paginationTotal() {
      if (this.$store.state.user?.affiliateStatus?.total) {
        return this.$store.state.user?.affiliateStatus?.total;
      }
      return 0;
    },
  },
  watch: {
    searchId(val) {
      if (val == "" && this.searchedPlayer) {
        this.getAffiliateData();
      }
    },
    offSet: {
      handler() {
        this.getAffiliateData();
      },
    },
    limit: {
      handler() {
        this.getAffiliateData();
      },
    },
    // getData() {
    //   if (this.showMoreStatus) {
    //     return [];
    //   }
    //   (this.footerData[0].poker = this.getChipFormat(this.getTotal("poker"))),
    //     (this.footerData[0].slot = this.getChipFormat(this.getTotal("slot"))),
    //     (this.footerData[0].qq = this.getChipFormat(this.getTotal("qq"))),
    //     (this.footerData[0].blackjack = this.getChipFormat(
    //       this.getTotal("blackjack")
    //     )),
    //     (this.footerData[0].total = this.getChipFormat(
    //       this.getTotal("poker") +
    //         this.getTotal("slot") +
    //         this.getTotal("qq") +
    //         this.getTotal("blackjack")
    //     ));
    //   // (this.footerData[0].total = this.getChipFormat(this.getTotal("total")));
    // },
    endDate() {
      let from = new Date(this.startDate) / 1000;
      let to = new Date(this.endDate) / 1000;
      if (this.startDate && this.endDate && to > from) {
        this.onDateChange(from, to);
      }
    },
    startDate() {
      let from = new Date(this.startDate) / 1000;
      let to = new Date(this.endDate) / 1000;
      if (this.startDate && this.endDate && to > from) {
        this.onDateChange(from, to);
      }
    },
  },
  data() {
    return {
      searchedPlayer: false,
      copyInfoValue: false,
      downloadInfoValue: false,
      searchId: null,
      startDate: null,
      endDate: null,
      searchParam: "id",
      searchText: "",
      affiliateRefreshing: false,
      loading: false,
      limit: 25,
      offSet: 0,
      pageIndex: 0,
      pageSize: 25,
      disabledDates: {
        from: new Date(),
      },
      cellSelectionOption: {
        enable: false,
      },
      rowStyleOption: {
        stripe: false,
        clickHighlight: true,
      },
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },

      columns: [
        { field: "id", key: "a", title: "ID", align: "center" },
        {
          field: "name",
          key: "b",
          title: this.$t("general.name"),
          align: "center",
        },
        {
          field: "date",
          key: "c",
          title: this.$t("general.registrationDate"),
          align: "center",
        },
        {
          field: "poker",
          key: "e",
          title: "Poker",
          align: "center",
        },
        {
          field: "slot",
          key: "f",
          title: "Slot",
          align: "center",
        },
        {
          field: "blackjack",
          key: "g",
          title: "Blackjack",
          align: "center",
        },
        {
          field: "total",
          key: "d",
          title: this.$t("general.total"),
          align: "center",
        },
      ],
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              if (row && row.id) {
                this.$plugins.clipboard.write({
                  type: "string",
                  value: String(row.id),
                });
                if (this.copyInfoValue) {
                  return;
                }
                this.copyInfoValue = true;
                setTimeout(() => {
                  this.copyInfoValue = false;
                }, 1500);
              }
            },
          };
        },
      },
    };
  },
  methods: {
    async affiliateDataDownload() {
      if (this["user/getAllMyReferences"].length < 0) {
        this.$plugins.toast.show({
          text: this.$t("errors.errorOccured"),
          duration: 2500,
        });
        return;
      }
      let temp = this["user/getAllMyReferences"];

      temp.forEach((item) => {
        item.poker = this.getChipFormat(item.poker);
        item.qq = this.getChipFormat(item.qq);
        item.slot = this.getChipFormat(item.slot);
        item.total = this.getChipFormat(item.total);
        item.blackjack = this.getChipFormat(item.blackjack);
      });

      const allFooterData = {
        name: this.$t("general.total"),
        poker: this.getChipFormat(
          this.$store.state.user.allAffiliateTotal.poker
        ),
        slot: this.getChipFormat(
          this.$store.state.user.allAffiliateTotal.slots
        ),
        blackjack: this.getChipFormat(
          this.$store.state.user.allAffiliateTotal.blackjack
        ),
        total: this.getChipFormat(
          this.$store.state.user.allAffiliateTotal.poker +
            this.$store.state.user.allAffiliateTotal.slots +
            this.$store.state.user.allAffiliateTotal.blackjack
        ),
      };

      const workbook = new this.$excelJS.Workbook();

      const excelAffiliate = workbook.addWorksheet("Affiliate");
      excelAffiliate.columns = [
        { header: "Id", key: "id", width: 14, style: { font: { size: 16 } } },
        {
          header: this.$t("affiliate.name"),
          key: "name",
          width: 20,
          style: { font: { size: 16 } },
        },
        {
          header: this.$t("profileSettings.registrationDate"),
          key: "date",
          width: 22,
          style: { font: { size: 16 } },
        },
        {
          header: "Poker",
          key: "poker",
          width: 28,
          style: { font: { size: 16 } },
        },
        {
          header: "Blackjack",
          key: "blackjack",
          width: 28,
          style: { font: { size: 16 } },
        },
        {
          header: "Slot",
          key: "slot",
          width: 28,
          style: { font: { size: 16 } },
        },
        {
          header: this.$t("general.total"),
          key: "total",
          width: 28,
          style: { font: { size: 16 } },
        },
      ];

      const excelFooter = [allFooterData];

      excelAffiliate.addRows(temp);
      excelAffiliate.addRows(excelFooter);

      for (let index = 0; index < temp.length + 2; index++) {
        if (temp.length + 1 == index) {
          excelAffiliate.getRow(`${index + 1}`).fill = {
            type: "gradient",
            gradient: "angle",
            degree: 90,
            stops: [
              {
                position: 0,
                color: { argb: `9bdd68` },
              },
              {
                position: 1,
                color: { argb: `9bdd68` },
              },
            ],
          };
        } else {
          excelAffiliate.getRow(`${index + 1}`).fill = {
            type: "gradient",
            gradient: "angle",
            degree: 90,
            stops: [
              {
                position: 0,
                color: { argb: `${index % 2 == 0 ? "dbdbdb" : "bcbcbc"}` },
              },
              {
                position: 1,
                color: { argb: `${index % 2 == 0 ? "dbdbdb" : "bcbcbc"}` },
              },
            ],
          };
        }

        excelAffiliate.getRow(index + 1).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
      }

      if (this.getRealPlatform === "web") {
        workbook.xlsx
          .writeBuffer({
            base64: true,
          })
          .then(function (xls64) {
            // build anchor tag and attach file (works in chrome)
            var a = document.createElement("a");
            var data = new Blob([xls64], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const currentTimeStamp = Date.now();

            var url = URL.createObjectURL(data);
            a.href = url;
            a.download = `my-affiliates-${currentTimeStamp}.xlsx`;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
            }, 0);
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } else {
        const buffer = await workbook.xlsx.writeBuffer();
        var encodedBytes = Buffer.from(buffer).toString("base64");
        var base64 =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          encodedBytes;

        const currentTimeStamp = Date.now();

        const r = await Filesystem.writeFile({
          path: `my-affiliates-${currentTimeStamp}.xlsx`,
          data: base64,
          directory: Directory.Documents,
        }).then((writeFileResult) => {
          Filesystem.getUri({
            directory: Directory.Documents,
            path: `my-affiliates-${currentTimeStamp}.xlsx`,
          }).then(
            (getUriResult) => {
              // readFilePath(getUriResult.uri);
              if (this.getRealPlatform === "android") {
                if (this.downloadInfoValue) {
                  return;
                }
                this.downloadInfoValue = true;
                setTimeout(() => {
                  this.downloadInfoValue = false;
                }, 1500);
                return;
              }

              FileOpener.open(
                getUriResult.uri,
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              )
                .then(() => console.log("File is opened"))
                .catch((e) => console.log("Error opening file", e));
            },
            (error) => {
              console.log(error);
            }
          );
        });
      }
    },
    async getAffiliateData() {
      this.loading = true;
      await this.$store.dispatch("user/getAffiliateReferences", {
        from: 0,
        to: 0,
        limit: this.limit,
        offSet: this.offSet,
      });
      this.loading = false;
    },
    async searchAffiliateId() {
      if (this.searchId && this.searchId > 0) {
        const affiliatePlayerRes = await this.$store.dispatch(
          "user/getAffiliatePlayer",
          this.searchId
        );

        if (affiliatePlayerRes) {
          this.searchedPlayer = true;
        }
      } else {
        this.$plugins.toast.show({
          text: this.$t("errors.playerIdWrong"),
          duration: 2500,
        });
      }
    },
    pageNumberChange(pageIndex) {
      if (this.loading) {
        return;
      }
      this.offSet = pageIndex - 1;
      this.pageIndex = pageIndex - 1;
    },
    pageSizeChange(pageSize) {
      if (this.loading) {
        return;
      }
      this.limit = pageSize;
      this.pageSize = pageSize;
    },
    getMoreAffiliate() {
      if (this.$store.state.user.affiliateStatus.total > this.offSet + 1 * 25) {
        this.offSet += 1;
        this.$store.dispatch("user/getAffiliateReferences", {
          from: 0,
          to: 0,
          limit: this.limit,
          offSet: this.offSet,
        });
      }
    },
    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    },
    sortMyFunc(a, b) {
      let numberA = 0,
        numberB = 0;
      if (typeof a === "string") {
        numberA = parseInt(a.replaceAll(".", ""));
      } else {
        numberA = parseInt(a);
      }
      if (typeof b === "string") {
        numberB = parseInt(b.replaceAll(".", ""));
      } else {
        numberB = parseInt(b);
      }
      return { numberA, numberB };
    },
    closeModal() {
      this.$emit("update:showModal", false);
    },
    async refresh() {
      let from = new Date(this.startDate) / 1000;
      let to = new Date(this.endDate) / 1000;

      if (this.startDate && this.endDate && to > from) {
        await this.onDateChange(from, to);
      } else {
        this.$plugins.toast.show({
          text: this.$t("affiliate.date_wrong"),
          duration: 3000,
          variant: "danger",
        });
      }
      this.sortChange({});

      this.affiliateRefreshing = true;
      setTimeout(() => {
        this.affiliateRefreshing = false;
      }, 5000);
    },
    async onDateChange(from, to) {
      await this.$store.dispatch("user/getAffiliateReferences", {
        from,
        to,
        limit: this.limit,
        offSet: this.offSet,
      });
    },
    async clearCalendar() {
      this.startDate = null;
      this.endDate = null;

      await this.$store.dispatch("user/getAffiliateReferences", {
        from: 0,
        to: 0,
        limit: this.limit,
        offSet: this.offSet,
      });
    },
    getTotal(param) {
      if (typeof this.getData === "undefined") return 0;
      let _total = 0;
      for (let i = 0; i < this.getData.length; i++) {
        let item = this.getData[i][param];
        if (typeof item === "string") {
          _total = _total + parseInt(item.replaceAll(".", ""));
        } else {
          _total = _total + item;
        }
      }
      return _total;
    },
    sortChange(params) {
      this.getData.sort((a, b) => {
        if (params.id) {
          if (params.id === "asc") {
            return a.id - b.id;
          } else if (params.id === "desc") {
            return b.id - a.id;
          } else {
            return 0;
          }
        } else if (params.total) {
          if (params.total === "asc") {
            const { numberA, numberB } = this.sortMyFunc(a.total, b.total);

            return parseInt(numberA) - parseInt(numberB);
          } else if (params.total === "desc") {
            const { numberA, numberB } = this.sortMyFunc(a.total, b.total);

            return parseInt(numberB) - parseInt(numberA);
          } else {
            return 0;
          }
        } else if (params.qq) {
          if (params.qq === "asc") {
            const { numberA, numberB } = this.sortMyFunc(a.qq, b.qq);

            return parseInt(numberA) - parseInt(numberB);
          } else if (params.qq === "desc") {
            const { numberA, numberB } = this.sortMyFunc(a.qq, b.qq);

            return parseInt(numberB) - parseInt(numberA);
          } else {
            return 0;
          }
        } else if (params.slot) {
          const { aSlot, bSlot } = {
            aSlot: parseInt(a.slot),
            bSlot: parseInt(b.slot),
          };
          if (params.slot === "asc") {
            const { numberA, numberB } = this.sortMyFunc(aSlot, bSlot);

            return parseInt(numberA) - parseInt(numberB);
          } else if (params.slot === "desc") {
            const { numberA, numberB } = this.sortMyFunc(aSlot, bSlot);

            return parseInt(numberB) - parseInt(numberA);
          }
        } else if (params.blackjack) {
          if (params.blackjack === "asc") {
            const { numberA, numberB } = this.sortMyFunc(
              a.blackjack,
              b.blackjack
            );
            return parseInt(numberA) - parseInt(numberB);
          } else if (params.blackjack === "desc") {
            const { numberA, numberB } = this.sortMyFunc(
              a.blackjack,
              b.blackjack
            );
            return parseInt(numberB) - parseInt(numberA);
          }
        } else if (params.poker) {
          if (params.poker === "asc") {
            const { numberA, numberB } = this.sortMyFunc(a.poker, b.poker);

            return parseInt(numberA) - parseInt(numberB);
          } else if (params.poker === "desc") {
            const { numberA, numberB } = this.sortMyFunc(a.poker, b.poker);

            return parseInt(numberB) - parseInt(numberA);
          } else {
            return 0;
          }
        } else if (params.date) {
          if (params.date === "asc") {
            return a.dateUnix - b.dateUnix;
          } else if (params.date === "desc") {
            return b.dateUnix - a.dateUnix;
          } else {
            return 0;
          }
        } else if (params == {}) {
          return this.getData;
        }
      });
    },
    // affiliateTotalFooter() {

    //   (this.footerData.qq = this.getChipFormat(
    //     this.$store.state.user.affiliateStatus.referencesTotal.qq
    //   )),
    //     (this.footerData.poker = this.getChipFormat(
    //       this.$store.state.user.affiliateStatus.referencesTotal.poker
    //     ))(
    //       (this.footerData.blackjack = this.getChipFormat(
    //         this.getTotal("blackjack")
    //       ))
    //     ),
    //     (this.footerData.slot = this.getChipFormat(
    //       this.$store.state.user.affiliateStatus.referencesTotal.slots
    //     )),
    //     (this.footerData.total = this.getChipFormat(
    //       this.getTotal("poker") +
    //         this.getTotal("slot") +
    //         this.getTotal("qq") +
    //         this.getTotal("blackjack")
    //     ));
    // },
  },
  async mounted() {
    if (!this.$store.state.user.affiliateStatus.isAffiliate) return;
    await this.$store.dispatch("user/getAffiliateReferences", {
      from: 0,
      to: 0,
      limit: this.limit,
      offSet: this.offSet,
    });

    String.prototype.replaceAll = function (search, replacement) {
      var target = this;
      return target.split(search).join(replacement);
    };

    this.$store.dispatch("user/getAllAffiliateReferences", {
      from: 0,
      to: 0,
      limit: 1000,
      offSet: 0,
    });
  },
};
</script>

<style lang="scss">
.affiliate-panel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  // .refresh-button {
  //   position: relative;
  //   left: 1100px;
  //   bottom: 5px;
  //   transform: scale(0.7);
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   &-icon {
  //     position: relative;
  //     right: 20px;
  //     top: 5px;
  //   }

  //   .rotating {
  //     animation-name: rotatinganimation;
  //     animation-iteration-count: 1;
  //     animation-duration: 3s;
  //     animation-delay: 0s;
  //     animation-fill-mode: forwards;
  //     animation-timing-function: ease;
  //   }
  // }
  .affiliate-filter {
    width: 99%;
    height: 9%;
    background-color: #000000;
    border-radius: 20px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    .affiliate-download-info {
      position: relative;
      left: 280px;
      height: 60px;
      border: 2px #ffffff solid;
      z-index: 20;
      &-text {
        font-size: 32px;
        margin: 0 30px;
      }
      transition: all 0.25s ease;
    }
    .affiliate-filter-search {
      width: 300px;
      margin: 0 20px;
      z-index: 10;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input {
        background: #55121b;
        box-shadow: inset 1px 3px 8px black;
        width: 350px;
        height: 70px;
        font-size: 32px;
        padding: 5px;
        box-sizing: border-box;
        color: #ffffff;
        border: none;
        text-indent: 10px;
        -moz-appearance: textfield;
        border-radius: 30px;
        &::placeholder {
          color: #ffffff;
        }
      }
    }
    .affiliate-filter-download {
      position: relative;
      left: 20px;
      width: 65px;
      height: 65px;
      border-radius: 45px;
      background-color: #eb762c;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        width: 50px;
        height: 50px;
        fill: #ffffff;
      }
    }
    .search-button {
      position: relative;
      right: 40px;
      width: 60px;
      height: 60px;
      background-color: #f2812e;
      justify-content: space-around;
      border-radius: 30px;
      z-index: 10;
      transition-property: transform;
      transition-duration: 0.2s;
      transition-timing-function: ease;
      .search-icon {
        transform: scale(0.8);
      }

      &:active {
        transform: scale(1.2);
      }
    }
  }
  .affiliate-loading {
    position: relative;
    top: 300px;
  }
  .affiliate-panel-table {
    overflow-y: scroll;
    height: 80%;
    width: 1880px;
    z-index: 2;
    .ve-table {
      .ve-table-last-column {
        color: #ffffff !important;
        background-color: rgba(#3b0b14, $alpha: 1) !important;
      }
      .ve-table-body-td:last-child {
        color: #ffffff !important;
        background-color: rgba(#3b0b14, $alpha: 1) !important;
      }
      .ve-table-footer-td:last-child {
        color: black !important;
        background-color: rgba(#3b0b14, $alpha: 1) !important;
      }

      .ve-table-body-td:nth-child(1) {
        text-align: start;
      }
      .ve-table-body-td {
        padding: 5px !important;
      }
      .ve-table-container {
        // min-width: 1400px;
        // width: 100%;
        // min-height: 710px;
        // height: 830px;
        // min-height: 700px; Feature
        // max-height: 880px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .ve-table-content-wrapper {
          width: 99%;
          .ve-table-content {
            .ve-table-header {
              .ve-table-header-tr {
                th {
                  text-shadow: 0px 3px 2px black;
                  font-size: 32px !important;
                  color: #cf707f !important;
                  background-color: rgba($color: #3b0b14, $alpha: 1) !important;
                  height: 80px !important;
                  line-height: 80px !important;
                  font-weight: bold !important;
                }
              }
            }
            .ve-table-body {
              border-radius: 20px !important;
              width: 98% !important;
              .ve-table-body-tr {
                td {
                  font-size: 30px !important;
                  height: 60px !important;
                  background-color: #20080b !important;
                  line-height: 60px !important;
                  &:last-child {
                    background-color: #275f26 !important;
                  }
                }
              }
            }
            .ve-table-footer {
              .ve-table-footer-tr {
                .ve-table-footer-td {
                  color: #ffffff !important;
                  background-color: #1f4b20 !important;
                  height: 60px !important;
                  line-height: 60px !important;
                  font-size: 36px !important;
                  padding: 0 !important;
                }
                .ve-table-footer-td:last-child {
                  color: rgb(255, 255, 255) !important;
                  background-color: #070707 !important;
                }
              }
            }
          }
        }
      }
      .ve-table-header-tr {
        width: 100%;
        position: sticky;
        .ve-table-header-th {
          padding: 0 !important;
        }
      }
    }
    .ve-table-border-around {
      border-radius: 20px;
      border: none;
    }
  }
  .affiliate-panel-pagination {
    height: 10%;
    width: 1880px;
    position: fixed;
    bottom: 158px;
    .affiliate-copy-info {
      position: absolute;
      left: 50px;
      height: 60px;
      border: 2px #ffffff solid;
      z-index: 20;
      &-text {
        font-size: 32px;
        margin: 0 30px;
      }
      transition: all 0.25s ease;
    }
    .ve-pagination {
      position: absolute;
      width: 99%;
      height: 80px;
      display: flex;
      justify-content: center;
      font-size: 42px;
      border-radius: 20px;
      align-items: center;
      .ve-pagination-select {
        display: none;
      }
      .ve-pagination-total {
        min-width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ve-pagination-li {
        min-width: 60px;
        height: 60px;
        margin-right: 20px;
        a {
          display: block;
          width: 100%;
          height: 100%;

          .ve-icon {
            width: 60px;
            height: 60px;
            font-size: 60px;
          }
          .ve-icon::before {
            position: relative;
            top: 14px;
          }
        }
      }
      .ve-pagination-pager {
        .ve-pagination-li {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .ve-dropdown {
        .ve-dropdown-dt-selected {
          height: 60px;
          min-width: 200px;
          span {
            width: 170px;
            height: 100%;
            font-size: 28px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .ve-select-selected-span {
              display: flex !important;
              align-items: center;
              justify-content: center;
              font-size: 32px !important;
            }
          }
          .ve-icon {
            position: relative;
            right: -25px !important;
            font-size: 50px;
          }
        }
      }
      .ve-pagination-goto {
        .ve-pagination-goto-input {
          width: 100px;
          height: 60px;
          font-size: 36px;
        }
      }
    }
  }
}
.ve-dropdown-popper {
  top: 650px !important;
  .ve-dropdown-items {
    padding: 0 0 !important;
    .ve-dropdown-items-li {
      font-size: 26px !important;
      height: 40px !important;
      width: 120px !important;
      margin-bottom: 10px;
      .ve-dropdown-items-li-a {
        height: 100%;
        padding-left: 5px !important;
        padding-right: 5px !important;
        text-align: center !important;
      }
    }
  }
}
.affiliate-tab-selectors {
  margin-top: 15px;
  margin-bottom: 5px;
  width: 400px;
  height: 85px;
  background: linear-gradient(
    180deg,
    rgba(254, 189, 94, 1) 0%,
    rgba(148, 95, 31, 1) 100%
  );
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #ffc272;
  box-shadow: 0 0 15px black, inset 1px 1px 20px black;
  -moz-box-shadow: 0 0 15px black, inset 1px 1px 20px black;
  -webkit-box-shadow: 0 0 15px black, inset 1px 1px 20px black;
  .selector {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: #2e0004;
      font-size: 35px;
      font-weight: bold;
      text-transform: uppercase;
    }
    &.active {
      background: rgba(#2e0004, 0.5);
      border-radius: 45px;
      -moz-box-shadow: inset 0 6px 12px #000000;
      -webkit-box-shadow: inset 0 6px 12px #000000;
      box-shadow: inset 0 6px 12px #000000;
      span {
        background: -webkit-linear-gradient(#ffcc66, #ffffcc);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
.datepicker {
  input {
    user-select: none;
    -webkit-user-select: none; /* Safari 3.1+ */
  }
}
.content-more {
  width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  &-text {
    font-size: 36px;
    text-shadow: 0px 3px 2px black;
    cursor: pointer;
    transition-property: width;
    transition-duration: 2s;
    transition-timing-function: linear;
    transition-delay: 1s;
  }
  &-text:active {
    transform: scale(1.2);
  }
}
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  color: rgb(255, 255, 255);
  font-size: 46px;
  border-top: 0;
}
// .filter-area-affiliate {
//   width: 100%;
//   height: 80px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   .date-filter {
//     width: 54%;
//     display: flex;
//     align-items: center;
//     justify-content: space-evenly;
//     .calendar-clear {
//       height: 50px;
//       font-size: 25px;
//       border-radius: 5px;
//       background: $backgroundColor;
//       color: rgb(255, 255, 255);
//       cursor: pointer;
//       background-color: black;
//       border: 2px solid rgb(169, 169, 170);
//       background-size: 90%;
//       background-repeat: no-repeat;
//       background-position: center;
//       transition: all 0.1s ease;
//       &:hover {
//         background-color: rgb(169, 169, 170);
//         border: 2px solid black;
//       }
//       &:active {
//         transform: scale(0.9);
//       }
//     }
//     .date-picker-wrapper {
//       width: 20%;
//       margin-left: 15px;
//       .date-picker-input {
//         width: 220px;
//         height: 60px;
//         background: $backgroundColor;
//         border: 0;
//         font-size: 32px;
//         color: rgb(255, 255, 255);

//         border-radius: 5px;
//         padding: 5px;
//         text-align: center;
//       }
//       .date-picker-input::placeholder {
//         color: #ffffff;
//       }
//       .date-picker-input:-ms-input-placeholder {
//         /* Internet Explorer 10-11 */
//         color: rgb(255, 255, 255);
//       }
//       .date-picker-input::-ms-input-placeholder {
//         /* Microsoft Edge */
//         color: rgb(255, 255, 255);
//       }
//       .date-picker-calendar {
//         background: $backgroundColor;
//         border-radius: 10px;
//         border: 0;
//         width: 370px;
//         height: 410px;
//         .cell {
//           font-size: 32px;
//           margin: 3px;
//         }
//         .day__month_btn {
//           font-size: 30px;
//         }
//       }
//     }
//     svg {
//       height: 40px;
//       width: 40px;
//       fill: rgb(255, 255, 255);
//       margin-right: 20px;
//     }
//     .date-picker-wrapper {
//       margin-right: 20px;
//     }
//   }
//   .search-area {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 35%;
//     height: 100%;
//     .refresh-button {
//       border: 2px solid rgb(169, 169, 170);
//       height: 50px;
//       font-size: 25px;
//       border-radius: 5px;
//       background: $backgroundColor;
//       color: rgb(255, 255, 255);
//       background-color: black;
//       background-size: 90%;
//       background-repeat: no-repeat;
//       background-position: center;
//       cursor: pointer;
//       margin-left: 10px;
//       transition: all 0.1s ease;
//       &:hover {
//         background-color: rgb(169, 169, 170);
//         border: 2px solid black;
//       }
//       &:active {
//         transform: scale(0.9);
//       }
//     }
//     .search-param-selectbox {
//       margin-left: 10px;
//       appearance: none;
//       background: $backgroundColor;
//       border: 0;
//       width: 130px;
//       box-sizing: border-box;
//       height: 50px;
//       font-size: 25px;
//       color: rgb(255, 255, 255);
//       border-radius: 5px;
//       text-indent: 5px;
//       select {
//         font-size: 18px;
//       }
//     }

//     .search-label {
//       font-size: 28px;
//     }
//   }
// }
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes rotatinganimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(365deg);
  }
}
</style>
