import { Network } from "@capacitor/network";
import store from "@/store";

const NetworkManager = {
  init() {
    let currentNetworkStatus = false;
    Network.addListener("networkStatusChange", (status) => {
      if (!status) {
        console.log("networkStatusChange modals/show disconnect");
        store.commit("modals/show", "disconnect");
        // store.dispatch("user/userOffline");
      } else {
        if (!currentNetworkStatus && !!status)
          store.dispatch("getApplicationConfig");
        store.commit("modals/hide", "disconnect");
      }
      currentNetworkStatus = status;
    });
  },

  async getStatus() {
    return Network.getStatus();
  },
};

export default {
  instance: NetworkManager,

  init() {
    NetworkManager.init();
  },

  install(Vue) {
    Vue.prototype.$plugins.network = NetworkManager;
  },
};
