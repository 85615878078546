import Logger from "@/logger";
import axios from "@/services/api/axios";
import AppConstants from "@/constants/app";
import toast from "@/plugins/toast";
import i18n from "@/plugins/i18n";
import inAppPurchase from "@/plugins/in-app-purchase";
import { Capacitor } from "@capacitor/core";

const PLATFORM = Capacitor.getPlatform() === "ios" ? "apple" : "google";

export default {
  /*
   * Its fetching product list from API then merging with apple or google
   */
  async fetch({ rootState, rootGetters, state }) {
    if (rootGetters.getRealPlatform === "web") return false;

    inAppPurchase.instance.validator = `${AppConstants.apiUrl}/sales/verify/${rootGetters.getRealPlatform}`;
    inAppPurchase.instance.applicationUsername = String(rootState.user.id);

    try {
      const response = await axios.get("/products");

      response.data
        .filter((product) => product.type === PLATFORM)
        .forEach((product) => {
          inAppPurchase.instance.register({
            type: inAppPurchase.instance.CONSUMABLE,
            id: product.sku,
          });
          inAppPurchase.instance
            .when(product.sku)
            .loaded((product) => {
              const index = state.list.findIndex((p) => p.id === product.id);
              if (index > -1) {
                this._vm.$set(state.list, index, product);
                return;
              }
              state.list.push(product);
            })
            .approved((product) => {
              product.verify().error((error) => {
                Logger.print("error", "verify error " + error);
              });
            })
            .verified((product) => {
              product.finish();
            })
            .cancelled((product) => {
              Logger.print("warn", ["IAP CANCELLED", product]);
              inAppPurchase.instance.refresh();
            });
        });

      inAppPurchase.instance.refresh();

      console.log("products store response.data", response.data);

      return response.data;
    } catch (error) {
      Logger.print("error", ["products/fetch", error]);
      return false;
    }
  },

  async getPaymentProducts() {
    try {
      const response = await axios.get(`/product-admin/get-all`);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console, log(error);
      return false;
    }
  },

  async getProductGateWays(store, id) {
    try {
      const response = await axios.get(
        `/payment/payment-method/gatway-amount/${id}`
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  async getFacebookProducts() {
    try {
      const response = await axios.get(`/products?type=facebook`);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  async buyPlatformProduct(store, { productId, gatewayId }) {
    try {
      const response = await axios.post(`/payment/buy_admin_product`, {
        productId: productId,
        paymentMethodId: gatewayId,
      });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  async buyVip(store) {
    try {
      const response = await axios.post(`/vip/purchase`);
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  },

  async redeemCode(store, code) {
    try {
      const response = await axios.post(`/game-cards/use`, { code: code });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      if (error?.response?.data?.message === "Game card used") {
        toast.instance.show({
          text: i18n.t("redeem.used"),
          duration: 3000,
          variant: "danger",
        });
      } else if (error?.response?.data?.message === "Game card expired") {
        toast.instance.show({
          text: i18n.t("redeem.expired"),
          duration: 3000,
          variant: "danger",
        });
      } else {
        toast.instance.show({
          text: i18n.t("redeem.failed"),
          duration: 3000,
          variant: "danger",
        });
      }
      return false;
    }
  },
};
