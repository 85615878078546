var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SafeArea',{attrs:{"showBatteryInfo":"","showNetworkInfo":""}},[_c('div',{attrs:{"id":"authentication-scene"}},[(
        _vm.$store.state.authentication.showOverlay && _vm.getRealPlatform !== 'web'
      )?_c('div',{staticClass:"black-overlay no-flick"}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loggingIn),expression:"!loggingIn"}],staticClass:"logo-outer"},[_c('div',{staticClass:"turn-games-logo"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loggingIn && !_vm.autoLoginProcessing),expression:"!loggingIn && !autoLoginProcessing"}],staticClass:"information-container"},[_c('div',{staticClass:"info-bg"},[_c('div',{staticClass:"texts",domProps:{"innerHTML":_vm._s(
            _vm.$t(`authentication.loginBonusText${_vm.webOrPhone}`, _vm.platformTexts)
          )}})])]),_c('div',{staticClass:"authentication-wrapper not-logged-in"},[_c('div',{staticClass:"auth-buttons"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loggingIn),expression:"!loggingIn"}],staticClass:"auth-buttons-background"},[(!_vm.autoLoginProcessing)?[_c('AuthButton',{attrs:{"provider":"facebook","variant":"blue"},on:{"loginStart":function($event){_vm.loggingIn = true}}}),(_vm.getRealPlatform === 'ios')?_c('AuthButton',{attrs:{"provider":"apple","variant":"black"},on:{"loginStart":function($event){_vm.loggingIn = true}}}):_vm._e(),(
                _vm.getRealPlatform === 'android' || _vm.getRealPlatform === 'ios'
              )?_c('AuthButton',{attrs:{"provider":"google","variant":"red"},on:{"loginStart":function($event){_vm.loggingIn = true}}}):_vm._e(),(!_vm.isInFacebook)?_c('AuthButton',{attrs:{"provider":"guest","variant":"white"}}):_vm._e()]:_vm._e(),(_vm.autoLoginProcessing)?[_c('Spinner',{attrs:{"size":"48px","radius":"0%"}})]:_vm._e()],2)]),(!_vm.autoLoginProcessing && !_vm.loggingIn)?_c('div',{staticClass:"use-invite-code-container"},[_c('div',{staticClass:"use-invite-code-button",class:[
            _vm.authentication.inviteCode === null
              ? 'green-type'
              : 'ui-button ui-button-red',
          ],attrs:{"type":"button"},on:{"click":_vm.onInviteCodeButtonClicked}},[_c('span',{staticClass:"use-invite-code-button-text"},[_vm._v(" "+_vm._s(_vm.authentication.inviteCode === null ? _vm.$t("useInviteCode.heading") : _vm.$t("useInviteCode.unset"))+" ")])])]):_vm._e()])]),(_vm.getAuthInformationText.length > 0)?_c('div',{staticClass:"authentication-scene-information-text"},[_c('div',{staticClass:"authentication-scene-information-text-inner"},[_vm._v(" "+_vm._s(_vm.getAuthInformationText)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }