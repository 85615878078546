export default {
  id: 0,
  players: [],
  stage: 0,
  deck: [],
  dealer: {
    busted: false,
    handStrength: 0,
    handStrengthTS: '',
    cards: []
  },
  minBuy: 0,
  maxBuy: 0,
  winners: [],
  countdown: 0,
  countdownStartValue: 0,
  bettingTimeout: 0,
  actionTimeout: 0,
  cardColor: 'red'
};
