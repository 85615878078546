<template>
  <div class="seat-expression" :class="getClasses">
    <div class="seat-expression">
      <template v-for="(item, itemIndex) in getAnimations">
        <template v-for="(goTo, goToIndex) in item.to">
          <div
            class="seat-expression-item"
            v-if="item.from === data.id"
            :key="`animation${itemIndex}-to${goToIndex}`"
          >
            <GlobalAnimation
              :sendPlayerId="item.from"
              :type="item.id"
              :from="item.from"
              :to="goTo"
              :index="index"
            />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "SeatExpression",
  components: {
    GlobalAnimation: () =>
      import("@/components/Misc/Animations/GlobalAnimation"),
  },
  props: {
    index: {
      type: [Number, String],
      default: 0,
      required: true,
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      nGetSeats: "service/nGetSeats",
      getAnimations: "gift/getAnimations",
      nAmISitting: "service/nAmISitting",
      nGetMySeat: "service/nGetMySeat",
    }),
    getClasses() {
      return [
        `seat-expression-r-${this.$props.index}`,
        `seat-expression-${this.getComputedIndex}`,
        `expression-${this.data.id}`,
      ];
    },
    getComputedIndex() {
      if (!this.nAmISitting) return this.$props.index;

      let shiftValue = 7 - this.nGetMySeat.position;
      if (shiftValue < 0) shiftValue = 8;

      return (this.$props.index + shiftValue) % 9;
    },
    data() {
      return this.nGetSeats[this.$props.index];
    },
  },
};
</script>

<style scoped lang="scss">
.seat-expression {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 6;
}

.seat-expression-0 {
  left: -120px;
  top: 320px;
}
.seat-expression-1 {
  left: -65px;
  top: 8px;
}
.seat-expression-2 {
  left: 314px;
  top: -135px;
}
.seat-expression-3 {
  left: 915px;
  top: -143px;
}
.seat-expression-4 {
  left: 1310px;
  top: 7px;
}
.seat-expression-5 {
  left: 1350px;
  top: 310px;
}
.seat-expression-6 {
  left: 1040px;
  top: 440px;
}
.seat-expression-7 {
  left: 620px;
  top: 520px;
}
.seat-expression-8 {
  left: 180px;
  top: 435px;
}
</style>
