<template>
  <div class="bj-header no-flick">
    <div class="left-side">
      <div class="chip-indicator">
        <div class="icon"></div>
        <div class="text">
          <AnimatedNumber :number="myStack" :format="true" />
        </div>
      </div>
      <button
        type="button"
        class="header-button pin-button"
        :class="[pinned ? 'active' : '']"
        @click="pinned = !pinned"
      ></button>
    </div>
    <div class="right-side">
      <div class="right-side-buttons">
        <button
          type="button"
          class="header-button chat-button"
          :class="[showChat ? 'active' : '']"
          @click="show('showChat')"
        >
          <span class="unread-indicator" v-show="unreadMessages"></span>
        </button>
        <button
          type="button"
          class="header-button past-button"
          :class="[showGameHistory ? 'active' : '']"
          @click="show('showGameHistory')"
        ></button>
        <button
          type="button"
          class="header-button info-button"
          :class="[showRules ? 'active' : '']"
          @click="show('showRules')"
        ></button>
        <button
          type="button"
          class="header-button settings-button"
          :class="[showSettings ? 'active' : '']"
          @click="show('showSettings')"
        ></button>
        <button
          type="button"
          class="header-button home-button"
          :disabled="nGetMySeat.leaving"
          @click="leave"
        ></button>
      </div>
    </div>

    <Chat />
    <Payout />
    <GameHistory />
    <Rules />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import AnimatedNumber from "../../Utils/AnimatedNumber";
import Chat from "./Header/Chat";
import Payout from "./Header/Payout";
import GameHistory from "./Header/GameHistory";
import Rules from "./Header/Rules";

export default {
  name: "Header",
  components: {
    Chat,
    Payout,
    GameHistory,
    Rules,
    AnimatedNumber,
  },
  watch: {
    showChat(newStatus, oldStatus) {
      if (newStatus === true && oldStatus === false)
        this.unreadMessages = false;
    },
    myStack() {
      this.$store.dispatch("service/requestUserDataFromLobby");
    },
  },
  computed: {
    ...mapState(["platform"]),
    ...mapGetters(["getChipFormat"]),
    ...mapGetters({
      nGetMySeat: "service/nGetMySeat",
    }),
    myStack() {
      return Math.max(this.nGetMySeat?.stack, 0);
    },
  },
  data() {
    return {
      pinned: false,
      showChat: false,
      unreadMessages: false,
      showSettings: false,
      showGameHistory: false,
      showRules: false,
      showBuyChipsModal: false,
    };
  },
  methods: {
    leave() {
      // if (this.nGetMySeat.bet.main > 0 || this.nGetMySeat.in) {
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.send("leave");
      // return;
      // }
      this.$store.state.service.colyseusConnection.ACTIVE_ROOM.leave();
    },
    show(target = "") {
      if (target === "showChat") {
        this.$adjust.trackEvent("BlackjackChatClick");
      }
      if (target === "") return false;

      if (this.$data[target]) {
        this.$data[target] = false;
        return true;
      }

      this.pinned = false;
      this.showChat = false;
      this.showSettings = false;
      this.showGameHistory = false;
      this.showRules = false;

      this.$data[target] = true;
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes unreadIndicatorAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.bj-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-button {
    display: block;
    width: 72px;
    height: 72px;
    border: 0;
    border-radius: 100%;
    background-color: $blackjackTransparentBlack;
    cursor: pointer;
    transition: all 0.25s ease;
  }
  .left-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .chip-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      height: 72px;
      border-radius: 20px;
      background-color: $blackjackTransparentBlack;
      margin-right: 15px;
      .icon {
        width: 36px;
        height: 36px;
        background-image: url("~@/assets/images/blackjack/icons/chipbar-icon.png");
        background-repeat: no-repeat;
        background-size: 36px 37px;
        margin-right: 10px;
      }
      .text {
        font-weight: bold;
        font-size: 36px;
      }
    }
    .buy-chips-button {
      color: rgb(255, 255, 255);
      margin-left: 10px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        display: block;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%237f7e84' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
      }
      .text {
        text-transform: uppercase;
      }
      &:hover,
      &.active {
        color: black;
        background-color: rgb(255, 255, 255);
        .icon {
          background-image: url("data:image/svg+xml,%3Csvg fill='none' stroke='black' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z'%3E%3C/path%3E%3C/svg%3E");
        }
      }
    }
    .pin-button {
      background-image: url("~@/assets/images/blackjack/icons/pin-deactive.png");
      background-size: 30px 30px;
      background-repeat: no-repeat;
      background-position: center;
      &:hover,
      &.active {
        background-color: rgb(255, 255, 255);
      }
      &.active {
        background-image: url("~@/assets/images/blackjack/icons/pin-active.png");
        background-size: 38px 34px;
      }
    }
  }
  .right-side {
    .right-side-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .header-button {
        position: relative;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-position: center;
        &:disabled {
          opacity: 0.4;
        }
        &:last-child {
          margin-right: 0;
        }
        .unread-indicator {
          display: block;
          width: 8px;
          height: 8px;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: $redColor;
          border-radius: 100%;
          animation-name: unreadIndicatorAnimation;
          animation-iteration-count: infinite;
          animation-duration: 0.5s;
          animation-timing-function: linear;
        }
      }
      .info-button {
        background-image: url("data:image/svg+xml,%3Csvg fill='none' stroke='%237f7e84' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 39px;
        &.active,
        &:hover {
          background-color: rgb(255, 255, 255) !important;
          background-image: url("data:image/svg+xml,%3Csvg fill='none' stroke='black' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'%3E%3C/path%3E%3C/svg%3E");
        }
      }
      .chat-button {
        background-image: url("~@/assets/images/blackjack/icons/chat-deactive.png");
        background-size: 32px 30px;

        &.active,
        &:hover {
          background-color: rgb(255, 255, 255) !important;
          background-image: url("~@/assets/images/blackjack/icons/chat-active.png");
        }
      }
      .past-button {
        background-image: url("~@/assets/images/blackjack/icons/past-deactive.png");
        background-size: 36px 32px;

        &.active,
        &:hover {
          background-color: rgb(255, 255, 255) !important;
          background-image: url("~@/assets/images/blackjack/icons/past-active.png");
        }
      }
      .settings-button {
        background-image: url("~@/assets/images/blackjack/icons/settings-deactive.png");
        background-size: 32px 34px;
        &.active,
        &:hover {
          background-color: rgb(255, 255, 255) !important;
          background-image: url("~@/assets/images/blackjack/icons/settings-active.png");
        }
      }
      .full-screen-button {
        background-image: url("~@/assets/images/blackjack/icons/fullscreen-deactive.png");
        background-size: 38px 38px;
        &:hover {
          background-color: rgb(255, 255, 255) !important;
          background-image: url("~@/assets/images/blackjack/icons/fullscreen-active.png");
        }
      }
      .home-button {
        background-image: url("~@/assets/images/blackjack/icons/home-deactive.png");
        background-size: 34px 32px;

        &:not(:disabled):hover {
          background-color: rgb(255, 255, 255) !important;
          background-image: url("~@/assets/images/blackjack/icons/home-active.png");
        }
        &.stand-up {
          background-image: url("~@/assets/images/blackjack/icons/standup-deactive.png");
          &:not(:disabled):hover {
            background-color: rgb(255, 255, 255) !important;
            background-image: url("~@/assets/images/blackjack/icons/standup-active.png");
          }
        }
      }
    }
  }
}

.platform-mobile {
  .bj-header {
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    z-index: 11;
    padding: 0 15px;
    box-sizing: border-box;
    .left-side {
      .chip-indicator {
        padding: 0 20px;
        border-radius: 50px;
        height: 100px;
        background-color: rgba(black, 0.7);
      }
      .header-button {
        width: 100px;
        height: 100px;
        background-color: rgba(black, 0.7);
        &:hover,
        &.active {
          color: black;
          background-color: rgb(255, 255, 255);
          .icon {
            background-image: url("data:image/svg+xml,%3Csvg fill='none' stroke='black' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z'%3E%3C/path%3E%3C/svg%3E");
          }
        }
      }
    }
    .right-side {
      .right-side-buttons {
        .header-button {
          width: 100px;
          height: 100px;
          background-color: rgba(black, 0.7);
          &.chat-button {
            background-size: 42px 40px;
          }
          &.past-button {
            background-size: 46px 42px;
          }
          &.info-button {
            background-size: 50px;
          }
          &.settings-button {
            background-size: 42px 44px;
          }
          &.home-button {
            background-size: 44px 42px;
          }
        }
      }
    }
  }
}
</style>
