<template>
  <div
    class="safe-area-view"
    :class="getSafeAreaClasses"
    :style="getSafeAreaStyles"
  >
    <slot />
  </div>
</template>

<script>
import AppConstants from "@/constants/app";

export default {
  name: "SafeArea",
  computed: {
    getSafeAreaClasses() {
      const classes = [];
      if (AppConstants.mode === "staging") classes.push("debug");

      return classes;
    },
    getSafeAreaStyles() {
      const styles = {};

      styles.width = `${AppConstants.game.safe.width}px`;
      styles.height = `${AppConstants.game.safe.height}px`;

      return styles;
    },
  },
  data() {
    return {
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };
  },
  methods: {
    onResize($event) {
      this.$data.window.width = Number($event.target.innerWidth);
      this.$data.window.height = Number($event.target.innerHeight);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize.bind(this));
  },
  created() {
    window.addEventListener("resize", this.onResize.bind(this));
  },
};
</script>

<style scoped lang="scss">
.safe-area-view {
  position: relative;
  &.debug {
    box-shadow: inset 0 0 0 5px lightgreen;
  }
}
</style>
