import Logger from "@/logger";
import { Capacitor } from "@capacitor/core";
import { NativeAudio } from "@capacitor-community/native-audio";
import sources from "./sources";
import store from "../../store";
import { Howl } from "howler";

class AudioManager {
  audios = [];
  webAudios = [];
  _muted = false;

  constructor() {
    if (Capacitor.getPlatform() === "web") {
      Logger.print("warn", "Audio Manager not working on web");
    }
  }

  set muted(value) {
    if (!!value) {
      this.muteAll();
      this._muted = true;
      Logger.print("info", "Audios muted");
      return;
    }

    this.unmuteAll();
    this._muted = false;
    Logger.print("info", "Audios resumed");
  }

  toggleMute() {
    this._muted = !this._muted;
  }

  getAssetConfig(assetId) {
    const config = sources.find((s) => s.name === assetId);
    return config || false;
  }

  _addAudio(name) {
    const index = this.audios.findIndex((audio) => audio == name);
    if (index > -1) {
      this.audios[index] = name;
      return;
    }

    this.audios.push(name);
  }

  async preload() {
    return sources.map(async (sound) => {
      if (Capacitor.getPlatform() === "web") {
        if (false) {
          if (this.webAudios.filter((w) => w.name == sound.name).length > 0) {
            return;
          }
          const audioInstance = new Audio(sound.url);
          if (typeof sound.volume !== "undefined")
            audioInstance.volume = sound.volume || 1;
          this.webAudios.push({
            name: sound.name,
            audio: audioInstance,
          });
        } else {
          // const newAudio = new Audio(require(`@/audios/${sound.name}.mp3`));

          var newAudio = new Howl({
            src: [require(`@/audios/${sound.name}.mp3`)],
          });

          if (sound.hasOwnProperty("volume")) {
            newAudio.volume = sound.volume;
          }
          this.webAudios.push({
            name: sound.name,
            audio: newAudio,
          });
        }
      } else {
        await NativeAudio.preload({
          assetId: sound.name,
          assetPath:
            (Capacitor.getPlatform() === "ios" ? "sounds/" : "") +
            sound.file_name,
          audioChannelNum: 1,
          isUrl: false,
        });
        await this.setVolume(sound.name, sound.volume || 1);
        this._addAudio(sound.name);
      }
    });
  }

  setVolume(assetId, volume) {
    if (Capacitor.getPlatform() === "web") {
      const audio = this.webAudios.find((a) => a.name == assetId);
      if (!audio) return;
      audio.audio.volume = Number(volume);
      return;
    }
    return NativeAudio.setVolume({
      assetId,
      volume,
    });
  }

  stop(assetId) {
    if (Capacitor.getPlatform() === "web") {
      const audio = this.webAudios.find((a) => a.name == assetId);
      if (!audio) return;
      audio.audio.pause();
      return;
    }
    return NativeAudio.stop({ assetId });
  }

  loop(assetId) {
    if (this._muted && assetId !== "Lobby1") {
      return;
    }
    if (Capacitor.getPlatform() === "web") {
      const audio = this.webAudios.find((a) => a.name == assetId);
      if (!audio) return;
      audio.audio.loop = true;
      // if (audio.name === "Lobby1") {
      //   audio.audio.volume = 0.2;
      // }

      if (audio && audio.audio && audio.audio.hasOwnProperty("play"))
        audio.audio.play();
      return;
    }
    return NativeAudio.loop({ assetId });
  }

  async play(assetId, startTime = 0) {
    if (!store.state.preferences.soundValue) {
      return;
    }
    // if (this._muted && assetId !== "Lobby1") return;
    if (Capacitor.getPlatform() === "web") {
      const audio = this.webAudios.find((a) => a.name == assetId);

      if (!audio) {
        return;
      }

      const audioLoad = await audio.audio.load();
      // if (audio.name === "Lobby1") {
      // audio.audio.volume = 0.2;
      // }
      // if (audio && audio.audio && audio.audio.hasOwnProperty("play")) {
      //   console.log("web audio play !!!");
      //   audio?.audio?.play();
      // }
      const audioPlayRes = await audio?.audio?.play();
    } else {
      return NativeAudio.play({ assetId, time: startTime });
    }
  }

  getDuration(assetId) {
    if (Capacitor.getPlatform() === "web") {
      const audio = this.webAudios.find((a) => a.name == assetId);
      if (!audio) return 0;
      return audio.audio.duration;
    }
    return NativeAudio.getDuration({ assetId });
  }

  muteAll() {
    if (Capacitor.getPlatform() === "web") {
      this.webAudios.forEach((a) => {
        // if (a.name !== "Lobby1") {
        a.audio.volume = 0;
        // }
      });
      return;
    }

    this.audios.forEach((name) => {
      // if (name !== "Lobby1") {
      NativeAudio.setVolume({ assetId: name, volume: 0 });
      // }
    });
  }

  unmuteAll() {
    if (Capacitor.getPlatform() === "web") {
      this.webAudios.forEach((a) => {
        const config = this.getAssetConfig(name);
        let volume = 1;
        if (config) volume = config.volume;
        a.audio.volume = volume;
      });
      return;
    }
    this.audios.forEach((name) => {
      let volume = 1;

      const config = this.getAssetConfig(name);
      if (config) volume = config.volume;

      this.setVolume(name, volume);
    });
  }
}

const instance = new AudioManager();

export default {
  instance,

  init() {
    document.body.addEventListener("click", (event) => {
      if (typeof event?.target?.className === "object") {
        return;
      }
      if (["Blackjack", "SlotGame"].includes(store.state.scene)) {
        return;
      }
      if (
        typeof event?.target?.className === "undefined" &&
        typeof event?.target?.className.includes !== "function"
      ) {
        return;
      }
      if (
        event?.target?.className?.includes("dont-play-sound") ||
        event?.target?.parentElement?.className?.includes("dont-play-sound")
      ) {
        return;
      }
      const targetClasses = ["ui-button", "selector"];
      targetClasses.forEach((tc) => {
        if (
          event?.target?.className.includes(tc) ||
          event.target.parentElement?.className?.includes(tc)
        ) {
          instance.play("Button");
        }
      });
    });
  },

  install(Vue) {
    Vue.prototype.$plugins.audio = instance;
  },
};
