<template>
  <div
    class="window-outer"
    :class="[getRealPlatform === 'android' ? 'no-flick' : '', globalClassName]"
    :style="outerStyle"
    v-show="show"
  >
    <div
      class="window-outer-bg"
      :class="outerWindowClass"
      :style="{
        width: width + 'px',
        height: height + 'px',
        left: `${outerLeftPx ? outerLeftPx + 'px' : ''}`,
      }"
    >
      <div
        class="close-container"
        @click="emitCloseWindow"
        v-if="$props.showCloseButton"
      >
        <div class="close">
          <span class="sprite-table-pop-up exit-icon"></span>
        </div>
      </div>

      <div
        class="window-inner-bg"
        :style="{
          background: $props.innerBackground,
          width: width + 'px',
          height: height + 'px',
          borderRadius: $props.borderRadius,
        }"
      >
        <div
          class="window-title"
          v-if="titleText"
          :style="{
            ...outerTitleStyle,
            height: width == 1920 && height == 1080 ? '8%' : '13%',
          }"
          :class="titleClasses"
        >
          <slot name="title" />
          <span class="title-text"> {{ titleText }} </span>
          <div class="profile-sprite light"></div>
        </div>
        <slot v-show="!loading"></slot>
        <div class="loading-spinner" v-show="loading">
          <Spinner :size="'50px'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Spinner from "@/components/Utils/Spinner";

export default {
  name: "BaseWindow",
  components: {
    Spinner,
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vnode) {
        el.eventSetDrag = function () {
          el.setAttribute("data-dragging", "yes");
        };
        el.eventClearDrag = function () {
          el.removeAttribute("data-dragging");
        };
        el.eventOnClick = function (event) {
          var dragging = el.getAttribute("data-dragging");
          // Check that the click was outside the el and its children, and wasn't a drag
          if (!(el == event.target || el.contains(event.target)) && !dragging) {
            // call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.addEventListener("touchstart", el.eventClearDrag);
        document.addEventListener("touchmove", el.eventSetDrag);
        document.addEventListener("click", el.eventOnClick);
        document.addEventListener("touchend", el.eventOnClick);
      },
      unbind: function (el) {
        document.removeEventListener("touchstart", el.eventClearDrag);
        document.removeEventListener("touchmove", el.eventSetDrag);
        document.removeEventListener("click", el.eventOnClick);
        document.removeEventListener("touchend", el.eventOnClick);
        el.removeAttribute("data-dragging");
      },
    },
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    borderRadius: {
      type: [String, Number],
      required: false,
      default: 15,
    },
    outerStyle: {
      type: [Object, String],
      default: "",
    },
    width: {
      type: [String, Number],
      required: false,
      default: 1920,
    },
    height: {
      type: [String, Number],
      required: false,
      default: 1080,
    },
    innerBackground: {
      type: String,
      default: "",
      required: false,
    },

    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    titleClasses: {
      type: String,
      default: "",
      required: false,
    },
    titleText: {
      type: [String, Boolean],
      default: "",
      required: false,
    },
    outerTitleStyle: {
      type: Object,
      default: () => {
        return {};
      },
      required: false,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    preventClickOutside: {
      type: Boolean,
      default: false,
      required: false,
    },
    stopClickOutside: {
      type: Boolean,
      default: false,
      required: false,
    },
    outerWindowStyle: {
      type: Object,
      default: () => {
        return {};
      },
      required: false,
    },
    outerWindowClass: {
      type: String,
      default: () => {
        return "";
      },
      required: false,
    },
    globalClassName: {
      type: String,
      default: "",
    },
    outerLeftPx: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      getRealPlatform: "getRealPlatform",
    }),
    getOuterComputedStyle() {
      let style = {};
      style.top = "100px";
    },
    // computedOuterWindowStyle() {
    //   const style = {
    //     background: "rgb(178, 137, 47) 100%",
    //   };
    //   if (Object.keys(this.outerWindowStyle).length > 0) {
    //     return { ...this.outerWindowStyle };
    //   } else {
    //     return { ...style };
    //   }
    // },
  },
  methods: {
    onClickedOutsideWindow($event) {
      if (this.stopClickOutside) {
        return;
      }
      $event.preventDefault();
      if (
        !this.$props.show ||
        this.$props.preventClickOutside ||
        $event.target?.className?.includes("no-click-outside") ||
        !$event.target?.className?.includes("window-outer")
      )
        return false;
      this.$plugins.audio.play("button_close_new");
      this.$emit("closeWindow");
    },
    emitCloseWindow() {
      this.$plugins.audio.play("button_close_new");
      this.$emit("closeWindow");
    },
  },
};
</script>

<style lang="scss" scoped>
.window-outer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.5);
  backface-visibility: hidden;
  .window-outer-bg {
    border-radius: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    flex-direction: column;
    .close-container {
      position: absolute;
      top: 5px;
      right: 15px;
      z-index: 101;
      height: 90px;
      width: 98px;
      cursor: pointer;
      .close {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
        top: 5px;
        right: 15px;
        width: 65px;
        height: 65px;
        border-radius: 100%;
        border: 3px solid #cf707f;
        cursor: pointer;
        transition: all 0.25s ease;
        &:active {
          filter: brightness(60%);
          -webkit-filter: brightness(60%);
        }
        .exit-icon {
          transform: scale(0.7);
        }
      }
    }

    .window-inner-bg {
      border-radius: 15px;
      position: relative;
      background: #3b0b14;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .window-title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        color: #ffffff;
        text-transform: uppercase;
        background-color: #3d0b14;
        border-radius: 15px 15px 0px 0px;

        .light {
          position: absolute;
          top: 0px;
          z-index: -5;
          pointer-events: none;
        }
        .title-text {
          text-shadow: 3px 5px 3px black;
          font-size: 56px;
          z-index: 1;
        }
      }

      .loading-spinner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
