import axios from "@/services/api/axios";
import Logger from "@/logger";
import EventBus from "@/event-bus";
import EventConstants from "@/constants/events";
import { Capacitor } from "@capacitor/core";

import Moment from "@/plugins/moment";

export default {
  async start({ state, dispatch }) {
    await dispatch("getApplicationConfig");

    await dispatch("slots/initialize");

    if (
      typeof state.config.game_status !== "undefined" &&
      !state.config.game_status
    ) {
      state.scene = "UnderMaintenance";
      return false;
    }

    await dispatch("authentication/tryAutoLogin");
  },

  async getApplicationConfig({ state }) {
    state.platform = Capacitor.getPlatform() === "web" ? "web" : "mobile";

    try {
      const response = await axios.get(`/config/application`);

      // application configs
      const keys = Object.keys(response.data.config);
      keys.forEach((key) => {
        this._vm.$set(state.config, key, response.data.config[key]);
      });

      // levels
      const levels = response.data.levels.sort(
        (l1, l2) => l1.number - l2.number
      );
      levels.forEach((level) => {
        state.levels.push(level);
      });

      // game ads
      const gameAds = response.data.gameAds;
      gameAds.forEach((item) => {
        state.gameAds.push(item);
      });

      // set default timezone to servers
      Moment.instance.tz.setDefault(response.data.serverTime.timezone);
    } catch (error) {
      Logger.print("error", "getApplicationConfig " + error);
    }
  },

  async initializeServices({ dispatch, state }) {
    try {
      await dispatch("service/initCWS");
      EventBus.$emit(EventConstants.LOADING_PERCENT_INCREASED, { value: 50 });

      await dispatch("service/initColyseusClient", {
        type: "POKER",
      });
      EventBus.$emit(EventConstants.LOADING_PERCENT_INCREASED, { value: 100 });
    } catch (error) {
      Logger.print("error", error);
      state.scene = "ConnectionError";
    }
  },

  async destroyServices({ dispatch, state }) {
    const destroyRes = await dispatch("service/destroyCWS");
    state.scene = "Authentication";
    if (!destroyRes) {
      console.log("socket disconnect error");
    }
  },
};
