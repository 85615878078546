export default {
  id: 0,
  name: '',
  stack: -1,
  talked: false,
  in: false,
  bet: {
    main: 0,
    anyPair: 0,
    twentyOne: 0
  },
  split: {
    active: false,
    bet: 0,
    busted: false,
    cards: [],
    handStrength: 0,
    handStrengthTS: '',
  },
  disconnected: false,
  leaving: false,
  lastAction: 0,
  cards: [],
  busted: false,
  doubledown: false,
  handStrength: 0,
  handStrengthTS: '',
  insured: false,
  lose: false,
  vip: false,
  wonWithTypes: {
    main: 0,
    anyPair: 0,
    twentyOne: 0,
  }
};
