import Logger from '@/logger'

if (typeof window.AndroidFullScreen !== "undefined") {
    const tryImmersiveMode = () => {
        window.AndroidFullScreen.isImmersiveModeSupported(() => {
            Logger.print('info', 'Immersive mode supported')
            window.AndroidFullScreen.immersiveMode(() => {
                Logger.print('info', 'Entered immersive mode')
            });
        });
    }

    Logger.print('info', 'Android fullscren plugin working...')
    window.AndroidFullScreen.showUnderSystemUI();
    tryImmersiveMode();

    document.body.addEventListener("click", tryImmersiveMode, false);
}