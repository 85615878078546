<template>
  <div class="v-spinner" v-show="loading">
    <div class="v-beat v-beat-odd one" v-bind:style="spinnerStyle"></div>
    <div class="v-beat v-beat-even two" v-bind:style="spinnerStyle"></div>
    <div class="v-beat v-beat-odd three" v-bind:style="spinnerStyle"></div>
    <div class="v-beat v-beat-odd four" v-bind:style="spinnerStyle"></div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: String,
      default: "15px",
    },
    margin: {
      type: String,
      default: "2px",
    },
    radius: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      spinnerStyle: {
        // backgroundColor: this.color,
        height: this.size,
        width: this.size,
        margin: this.margin,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.v-spinner .v-beat {
  background-repeat: no-repeat;
  background-size: contain;
  animation: vBeatStretchDelay 1s linear;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
  &.one {
    animation-delay: 0s;
    background-image: url("~@/assets/images/backgrounds/loading-1.png");
  }
  &.two {
    animation-delay: 0.25s;
    background-image: url("~@/assets/images/backgrounds/loading-2.png");
  }
  &.three {
    animation-delay: 0.5s;
    background-image: url("~@/assets/images/backgrounds/loading-3.png");
  }
  &.four {
    animation-delay: 0.75s;
    background-image: url("~@/assets/images/backgrounds/loading-4.png");
  }
}

@-webkit-keyframes vBeatStretchDelay {
  50% {
    transform: scale(0.75);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes vBeatStretchDelay {
  50% {
    transform: scale(0.75);
    opacity: 0.05;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
