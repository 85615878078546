<template>
  <BaseWindow
    @closeWindow="closeWindow"
    width="1000"
    height="600"
    :show="show"
    :titleText="titleText"
    :globalClassName="'are-you-sure-outer'"
    :stopClickOutside="true"
  >
    <div class="chip-sure">
      <div class="chip-sure-name" v-if="name != false">
        {{ name }}
      </div>
      <div class="chip-sure-heading" v-html="$t(content)"></div>
      <div class="chip-sure-chip" v-if="amount">
        <div class="lobby-sprite chip"></div>
        <div class="chip-sure-chip-amount">
          {{ amount }}
        </div>
      </div>
      <div class="chip-sure-buttons">
        <div class="ui-button ui-button-small-red" @click="closeWindow">
          {{ $t("deleteConfirmWindow.cancel") }}
        </div>
        <div class="ui-button ui-button-small-green" @click="confirm">
          {{ $t("general.send") }}
        </div>
      </div>
    </div>
  </BaseWindow>
</template>

<script>
import BaseWindow from "./BaseWindow";
export default {
  name: "AreYouSureWindow",
  components: {
    BaseWindow,
  },
  data() {
    return {};
  },
  props: {
    cancelBtnText: {
      type: String,
      default: "deleteConfirmWindow.delete",
    },
    show: {
      type: Boolean,
      required: true,
      default: true,
    },
    title: {
      type: [String, Boolean],
      default: false,
    },
    content: {
      type: [String, Boolean],
      default: false,
    },
    titleText: {
      type: String,
      default: "",
    },
    name: {
      type: [String, Boolean],
      default: false,
    },
    amount: {
      type: [String],
      default: "",
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    closeWindow() {
      this.$emit("closeAreYouSure");
    },
  },
};
</script>

<style lang="scss">
.chip-sure {
  width: 99%;
  height: 86%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: #83212d;
  z-index: 21;
  &-name {
    font-size: 46px;
    font-weight: bold;
    margin-bottom: 50px;
    text-shadow: 3px 5px 3px black;
    text-align: center;
  }
  &-heading {
    font-size: 46px;

    height: 30%;
    text-shadow: 3px 5px 3px black;
    text-align: center;
    width: 98%;
    line-height: 60px;
    p {
      .yellow {
        color: #ec702b;
      }
    }
  }
  &-chip {
    display: flex;
    flex-direction: row;
    align-items: center;
    .chip {
      transform: scale(1.2);
      position: relative;
      top: 3px;
      right: 25px;
    }
    &-amount {
      font-size: 46px;
      color: #ffffff;
      text-shadow: 3px 5px 3px black;
    }
  }
  &-buttons {
    width: 55%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    top: 20px;
  }
}
</style>
